<template>
  <PageWrapper>
    <SettingsWindowWrapper :mode="SettingsWindowMode.AlertOverview" :allowHide="false">
      <AlertOverview />
    </SettingsWindowWrapper>
  </PageWrapper>
</template>

<script lang="ts">
import AlertOverview from "@/views/alerts/AlertOverview.vue";
import PageWrapper from "@/views/PageWrapper.vue";
import { defineComponent } from "vue";
import SettingsWindowWrapper from "@/views/settings/SettingsWindowWrapper.vue";
import { SettingsWindowMode } from "@/stores/ui-state-store";

export default defineComponent({
  name: "AlertOverviewMobile",

  components: { SettingsWindowWrapper, PageWrapper, AlertOverview },

  expose: [],

  data() {
    return {
      SettingsWindowMode
    };
  }
});
</script>

<style scoped>
</style>

import { ColorOption } from "@/anfin-chart/options/option";
import type { ChartOptionManager } from "@/anfin-chart/options/option-manager";

export enum AnalysisToolColorOptionName {
  AutoChannelAscendingLine = "autoChannelAscendingLine",
  AutoChannelAscendingArea = "autoChannelAscendingArea",
  AutoChannelDescendingLine = "autoChannelDescendingLine",
  AutoChannelDescendingArea = "autoChannelDescendingArea",
  AutoChannelTargetLine = "autoChannelTargetLine",
  AutoChannelBrokenAtLine = "autoChannelBrokenAtLine",
  AutoDoubleExtremeTop = "autoDoubleExtremeTop",
  AutoDoubleExtremeBottom = "autoDoubleExtremeBottom",
  AutoFibonacciBasic = "autoFibonacciBasic",
  AutoFibonacciAscending = "autoFibonacciAscending",
  AutoFibonacciDescending = "autoFibonacciDescending",
  AutoHeadAndShouldersNeck = "autoHeadAndShouldersNeck",
  AutoHeadAndShouldersText = "autoHeadAndShouldersText",
  AutoExtremeHigh = "autoExtremeHigh",
  AutoExtremeLow = "autoExtremeLow",
  AutoHorizontalSupport = "autoHorizontalSupport",
  AutoHorizontalResistance = "autoHorizontalResistance",
  AutoPriceGap = "autoPriceGap",
  AutoTrendLineAscending = "autoTrendLineAscending",
  AutoTrendLineDescending = "autoTrendLineDescending",
  AutoTrendLineText = "autoTrendLineText",
  AutoTrendLineBox = "autoTrendLineBox",
  AutoTrendLineMarker = "autoTrendLineMarker"
}

export class AnalysisToolColorOptions implements Record<AnalysisToolColorOptionName, ColorOption> {

  public readonly autoChannelAscendingLine: ColorOption;
  public readonly autoChannelAscendingArea: ColorOption;
  public readonly autoChannelDescendingLine: ColorOption;
  public readonly autoChannelDescendingArea: ColorOption;
  public readonly autoChannelTargetLine: ColorOption;
  public readonly autoChannelBrokenAtLine: ColorOption;
  public readonly autoDoubleExtremeTop: ColorOption;
  public readonly autoDoubleExtremeBottom: ColorOption;
  public readonly autoFibonacciBasic: ColorOption;
  public readonly autoFibonacciAscending: ColorOption;
  public readonly autoFibonacciDescending: ColorOption;
  public readonly autoHeadAndShouldersNeck: ColorOption;
  public readonly autoHeadAndShouldersText: ColorOption;
  public readonly autoExtremeHigh: ColorOption;
  public readonly autoExtremeLow: ColorOption;
  public readonly autoHorizontalSupport: ColorOption;
  public readonly autoHorizontalResistance: ColorOption;
  public readonly autoPriceGap: ColorOption;
  public readonly autoTrendLineAscending: ColorOption;
  public readonly autoTrendLineDescending: ColorOption;
  public readonly autoTrendLineText: ColorOption;
  public readonly autoTrendLineBox: ColorOption;
  public readonly autoTrendLineMarker: ColorOption;

  constructor(optionManager: ChartOptionManager) {
    this.autoChannelAscendingLine = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoChannelAscendingLine);
    this.autoChannelAscendingArea = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoChannelAscendingArea);
    this.autoChannelDescendingLine = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoChannelDescendingLine);
    this.autoChannelDescendingArea = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoChannelDescendingArea);
    this.autoChannelTargetLine = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoChannelTargetLine);
    this.autoChannelBrokenAtLine = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoChannelBrokenAtLine);
    this.autoDoubleExtremeTop = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoDoubleExtremeTop);
    this.autoDoubleExtremeBottom = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoDoubleExtremeBottom);
    this.autoFibonacciBasic = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoFibonacciBasic);
    this.autoFibonacciAscending = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoFibonacciAscending);
    this.autoFibonacciDescending = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoFibonacciDescending);
    this.autoHeadAndShouldersNeck = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoHeadAndShouldersNeck);
    this.autoHeadAndShouldersText = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoHeadAndShouldersText);
    this.autoExtremeHigh = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoExtremeHigh);
    this.autoExtremeLow = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoExtremeLow);
    this.autoHorizontalSupport = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoHorizontalSupport);
    this.autoHorizontalResistance = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoHorizontalResistance);
    this.autoPriceGap = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoPriceGap);
    this.autoTrendLineAscending = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoTrendLineAscending);
    this.autoTrendLineDescending = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoTrendLineDescending);
    this.autoTrendLineText = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoTrendLineText);
    this.autoTrendLineBox = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoTrendLineBox);
    this.autoTrendLineMarker = new ColorOption(optionManager, AnalysisToolColorOptionName.AutoTrendLineMarker);
  }
}

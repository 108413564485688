import type { ChartColor } from "@/anfin-chart/draw/chart-color";
import { ColorProvider, convertToRgbaCss, GradientColor, RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { PlotData } from "@/anfin-chart/draw/plot-data";
import { PlotDrawer } from "@/anfin-chart/draw/plot-drawer";
import { ChartError } from "@/anfin-chart/error";
import type { SVG } from "@/anfin-chart/icon-store";
import { SvgDrawMethod, SVGPath } from "@/anfin-chart/icon-store";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { addAngleOffset, getAngle, Point, Polygon, Rectangle, Size, Vector } from "../geometry";
import type { Consumer } from "@/anfin-chart/utils";

export class TextAlignment {

  public readonly vector: Vector;

  constructor(vector = new Vector(0, 0),
              public readonly offset = 0) {
    const divisor = Math.max(Math.abs(vector.x), Math.abs(vector.y));
    this.vector = divisor === 0 ? vector : new Vector(vector.x / divisor, vector.y / divisor);
  }
}

export class FontInfo {

  constructor(public name: string,
              public size: number,
              public isBold = false,
              public isItalic = false) {
  }

  public get font() {
    const italicPrefix = this.isItalic ? "italic " : "";
    const weightPrefix = this.isBold ? "bold " : "normal ";
    return italicPrefix + weightPrefix + this.size + "px " + this.name;
  }
}

export enum LineStyle {
  Solid = 0,
  Dashed = 1,
  Dotted = 2,
  DashedWide = 3
}

export enum LineExpansionMode {
  None = 0,
  Fixed = 1,
  Unlimited = 2,
  Max = 3
}

export class LineOptions {

  constructor(public width = 1,
              public style = LineStyle.Solid,
              public arrowStart = 0,
              public arrowEnd = 0) {
  }
}

type ColorStyle = CanvasGradient | string;

export class ChartDrawer {

  private readonly context: CanvasRenderingContext2D;
  private readonly clippedRects: Rectangle[] = [];

  constructor(private readonly canvas: HTMLCanvasElement) {
    const context = canvas.getContext("2d");
    if (context == null) {
      throw new ChartError("Could not initialize 2d context");
    }
    if (!context.roundRect) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      context.roundRect = context.rect;
    }
    this.context = context;
  }

  public initialize() {
    this.context.translate(0.5, 0.5);
    this.clippedRects.push(new Rectangle(0, 0, this.canvas.width, this.canvas.height));
  }

  public getStyle(color: ChartColor | null | undefined): ColorStyle {
    if (color == null) {
      return "transparent";
    }
    if (color instanceof RGBAColor) {
      return convertToRgbaCss(color);
    }
    if (color instanceof GradientColor) {
      const rect = this.getCurrentRect();
      const gradient = this.context.createLinearGradient(rect.xStart, rect.yStart, rect.xStart, rect.yEnd);
      for (const stop of color.stops) {
        const stopColor = convertToRgbaCss(stop.color);
        gradient.addColorStop(stop.percentage, stopColor);
      }
      return gradient;
    }
    console.error("Unknown color type");
    return "#000000";
  }

  public withGlobalAlpha(alpha: number, action: Consumer<void>) {
    this.context.globalAlpha = alpha;
    action();
    this.context.globalAlpha = 1;
  }

  public drawLine(start: Point, end: Point, color: ChartColor, options = new LineOptions()) {
    this.setLineOptions(options);
    const style = this.getStyle(color);
    if (options.arrowStart > 0) {
      this.drawArrowHead(end, start, options.arrowStart, style);
    }
    if (options.arrowEnd > 0) {
      this.drawArrowHead(start, end, options.arrowEnd, style);
    }
    this.context.beginPath();
    this.context.moveTo(start.x, start.y);
    this.context.lineTo(end.x, end.y);
    this.stroke(style);
  }

  public clearRect(rect: Rectangle) {
    const width = rect.xEnd - rect.xStart;
    const height = rect.yEnd - rect.yStart;
    this.context.clearRect(rect.xStart, rect.yStart, width, height);
  }

  public drawRect(rect: Rectangle, lineColor: ChartColor | null, areaColor: ChartColor | null,
                  lineOptions = new LineOptions()) {
    this.setLineOptions(lineOptions);
    const strokeStyle = this.getStyle(lineColor);
    const fillStyle = this.getStyle(areaColor);
    this.context.beginPath();
    this.context.rect(rect.xStart, rect.yStart, rect.width, rect.height);
    this.stroke(strokeStyle);
    this.fill(fillStyle);
  }

  public drawRoundRect(rect: Rectangle, borderRadius: number, lineColor: ChartColor | null,
                       areaColor: ChartColor | null, lineOptions = new LineOptions()) {
    this.setLineOptions(lineOptions);
    const lineWidth = lineOptions.width * window.devicePixelRatio;
    this.context.beginPath();
    this.context.roundRect(
      rect.xStart + lineWidth,
      rect.yStart + lineWidth,
      rect.width - lineWidth * 2,
      rect.height - lineWidth * 2,
        borderRadius
      );
    this.context.fillStyle = this.getStyle(areaColor);
    this.context.fill();
    this.context.strokeStyle = this.getStyle(lineColor);
    this.context.stroke();
  }

  public drawPolygon(polygon: Polygon, lineColor: ChartColor | null, areaColor: ChartColor | null,
                     lineOptions = new LineOptions()) {
    this.setLineOptions(lineOptions);
    const strokeStyle = this.getStyle(lineColor);
    const fillStyle = this.getStyle(areaColor);

    this.context.beginPath();
    for (let i = 0; i < polygon.points.length; i++) {
      const point = polygon.points[i];
      if (i === 0) {
        this.context.moveTo(point.x, point.y);
      } else {
        this.context.lineTo(point.x, point.y);
      }
    }
    this.context.closePath();
    this.stroke(strokeStyle);
    this.fill(fillStyle);
  }

  public drawCircle(center: Point, radius: number, lineColor: ChartColor | null, areaColor: ChartColor | null,
                    lineOptions = new LineOptions()) {
    this.setLineOptions(lineOptions);
    const strokeStyle = this.getStyle(lineColor);
    const fillStyle = this.getStyle(areaColor);
    this.context.beginPath();
    this.context.arc(center.x, center.y, radius, 0, 2 * Math.PI);
    this.stroke(strokeStyle);
    this.fill(fillStyle);
  }

  public drawEllipse(center: Point, mainAxis: number, offAxis: number, angle: number, lineColor: ChartColor | null,
                     areaColor: ChartColor | null, lineOptions = new LineOptions()) {
    this.setLineOptions(lineOptions);
    const strokeStyle = this.getStyle(lineColor);
    const fillStyle = this.getStyle(areaColor);
    this.context.beginPath();
    this.context.ellipse(center.x, center.y, mainAxis, offAxis, angle, 0, 2 * Math.PI);
    this.stroke(strokeStyle);
    this.fill(fillStyle);
  }

  public measureText(text: string, fontInfo: FontInfo) {
    this.context.font = fontInfo.font;
    this.context.textAlign = "center";
    this.context.textBaseline = "middle";
    const metrics = this.context.measureText(text);
    const boundingBoxWidth = metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight;
    const width = Math.ceil(Math.max(metrics.width, boundingBoxWidth));
    const height = metrics.fontBoundingBoxAscent != null && metrics.fontBoundingBoxDescent != null
      ? metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent
      : (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent) * 1.5;
    return new Size(width, height);
  }

  public printText(position: Point, text: string, color: ChartColor, alignment: TextAlignment, fontInfo: FontInfo) {
    const textSize = this.measureText(text, fontInfo);
    const alignedPosition = this.getAlignedPosition(position, textSize, alignment);
    this.printTextAligned(alignedPosition, text, color, fontInfo);
  }

  public printTextAligned(rectangle: Rectangle, text: string, color: ChartColor, fontInfo: FontInfo) {
    this.context.font = fontInfo.font;
    this.context.textAlign = "center";
    this.context.textBaseline = "middle";
    const centerX = Math.round(rectangle.xStart + rectangle.width / 2);
    const centerY = Math.round(rectangle.yStart + rectangle.height / 2);
    const fillStyle = this.getStyle(color);
    this.context.font = fontInfo.font;
    this.context.fillStyle = fillStyle;
    this.context.fillText(text, centerX, centerY);
  }

  public drawSvg(svg: SVG, rectangle: Rectangle, overrideColor: ChartColor | null = null) {
    this.context.save();
    this.context.translate(rectangle.xStart - svg.viewPort.xStart,
      rectangle.yStart - svg.viewPort.yStart);
    this.context.scale(rectangle.width / svg.viewPort.width,
      rectangle.height / svg.viewPort.height);
    for (const path of svg.paths) {
      this.drawSvgPath(path, overrideColor);
    }
    this.context.restore();
  }

  public clipped(rect: Rectangle, callback: () => void) {
    const width = rect.xEnd - rect.xStart;
    const height = rect.yEnd - rect.yStart;
    this.context.save();
    this.context.beginPath();
    this.context.rect(rect.xStart, rect.yStart, width, height);
    this.context.clip();
    this.clippedRects.push(rect);
    callback();
    this.context.restore();
    this.clippedRects.pop();
  }

  public getContext() {
    return this.context;
  }

  public setLineOptions(lineOptions: LineOptions) {
    let dash: number[];
    switch (lineOptions.style) {
      case LineStyle.Solid:
        dash = [];
        break;
      case LineStyle.Dashed:
        dash = [5];
        break;
      case LineStyle.Dotted:
        dash = [2];
        break;
      case LineStyle.DashedWide:
        dash = [5, 8];
        break;
      default:
        throw new ChartError("Unknown line style");
    }
    this.context.lineWidth = lineOptions.width * window.devicePixelRatio;
    this.context.setLineDash(dash);
  }

  public getAlignedPosition(position: Point, size: Size, alignment: TextAlignment) {
    const vector = alignment.vector;
    const xOffset = size.width / 2;
    const yOffset = size.height / 2;
    let x = position.x + vector.x * xOffset;
    let y = position.y + vector.y * yOffset;
    if (alignment.offset > 0) {
      const scalingFactor = alignment.offset / vector.length;
      x += vector.x * scalingFactor;
      y += vector.y * scalingFactor;
    }
    return new Rectangle(x - xOffset, y - yOffset, x + xOffset, y + yOffset);
  }

  public drawPlot(subChart: SubChart, plotData: PlotData, colorProvider: ColorProvider) {
    const plotDrawer = new PlotDrawer(this, subChart, plotData, colorProvider);
    plotDrawer.draw();
  }

  private stroke(style: ColorStyle) {
    this.context.strokeStyle = style;
    this.context.stroke();
  }

  private fill(style: ColorStyle) {
    this.context.fillStyle = style;
    this.context.fill();
  }

  private getCurrentRect() {
    return this.clippedRects[this.clippedRects.length - 1];
  }

  private drawArrowHead(start: Point, end: Point, size: number, style: ColorStyle) {
    const angle = getAngle(start, end);
    this.context.beginPath();
    const startAngle = angle - Math.PI * 3 / 4;
    const endAngle = startAngle - Math.PI / 2;
    const left = addAngleOffset(end, startAngle, size);
    const right = addAngleOffset(end, endAngle, size);
    this.context.moveTo(left.x, left.y);
    this.context.lineTo(end.x, end.y);
    this.context.lineTo(right.x, right.y);
    this.context.setLineDash([]);
    this.stroke(style);
  }

  private drawSvgPath(svgPath: SVGPath, overrideColor: ChartColor | null) {
    const path = new Path2D(svgPath.path);
    const color = overrideColor ?? svgPath.color;
    switch (svgPath.drawMethod) {
      case SvgDrawMethod.Stroke:
        this.context.strokeStyle = this.getStyle(color);
        this.context.lineCap = svgPath.lineCap;
        this.context.lineWidth = svgPath.lineWidth;
        this.context.stroke(path);
        break;
      case SvgDrawMethod.Fill:
        this.context.fillStyle = this.getStyle(color);
        this.context.fill(path);
        break;
      default:
        console.error("Unknown SVG draw method: " + svgPath.drawMethod);
        break;
    }
  }
}

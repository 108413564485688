import { defineStore } from "pinia";

export const cookieStore = defineStore({
  id: "cookie",

  state() {
    return {
      ccm: CCM
    };
  },

  actions: {
    openCookieBanner(includeDetails = false) {
      CCM?.openWidget();
      if (includeDetails) {
        CCM?.openControlPanel();
      }
    },

    setLanguage(language: string) {
      const locales = CCM?.availableLocales();
      const matchingLocale = locales.find(l => l.startsWith(language));
      if (matchingLocale != null) {
        CCM?.switchLocale(matchingLocale);
      }
    }
  }
});

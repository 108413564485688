import type { CaptionArea } from "@/anfin-chart/area/caption-area";
import {
  CaptionDelete,
  CaptionExpand,
  CaptionInstrumentIcon,
  CaptionItem,
  CaptionSettings,
  CaptionText
} from "@/anfin-chart/area/caption-item";
import { StackAlignment, StackDirection, StackPanel } from "@/anfin-chart/area/stack-panel";
import type { IndicatorData } from "@/anfin-chart/indicator-data";
import type { InstrumentData } from "@/anfin-chart/instrument";
import { OHLCItem, Plot } from "@/anfin-chart/plot";
import { combineLatest } from "rxjs";
import type { Clickable } from "@/anfin-chart/interactions";
import { StackItem } from "@/anfin-chart/area/stack-item";
import { Rectangle, Size } from "@/anfin-chart/geometry";
import { formatPercentage } from "@/anfin-chart/utils";

export class CaptionLineStackPanel extends StackPanel {

  constructor(private readonly captionLine: CaptionLine) {
    super(captionLine.layer, StackDirection.Horizontal, StackAlignment.Center, captionLine.chart.styleOptions.captionItemMargin);
  }

  public override initializeEvents() {
    this.subscribeOn(this.captionLine.getPositionObservable(), () => this.resize());
  }

  protected override drawInternal() {
    // do nothing
  }

  protected override resizeInternal() {
    return this.captionLine.getStackPanelPosition();
  }
}

export abstract class CaptionLine extends StackItem {

  public readonly stackPanel: CaptionLineStackPanel;
  private readonly items: CaptionItem[] = [];

  protected constructor(private readonly captionArea: CaptionArea) {
    super(captionArea);
    this.stackPanel = new CaptionLineStackPanel(this);
  }

  protected get subChart() {
    return this.captionArea.subChart;
  }

  public override initializeEvents() {
    super.initializeEvents();
    this.subscribeOn(this.captionArea.getPositionObservable(), () => this.resize());
    this.subscribeOn(this.stackPanel.getSizeObservable(), size => this.setSize(size));
    this.stackPanel.initializeEvents();
  }

  public override onDelete() {
    super.onDelete();
    this.stackPanel.onDelete();
  }

  public addItem(item: CaptionItem) {
    item.initializeEvents();
    this.items.push(item);
    this.stackPanel.addStackItem(item);
  }

  public removeItem(item: CaptionItem) {
    const index = this.items.indexOf(item);
    this.items.splice(index, 1);
    this.stackPanel.removeStackItem(item);
  }

  public getStackPanelPosition() {
    const position = this.getPosition();
    return new Rectangle(position.xStart, position.yStart, position.xEnd, position.yEnd);
  }

  protected override drawInternal() {
    // do nothing
  }
}

export class InstrumentCaptionLine extends CaptionLine {

  private readonly iconItem: CaptionInstrumentIcon;
  private readonly nameItem: CaptionText;
  private readonly captionSeparator: CaptionText;
  private readonly timeframeItem: CaptionText;
  private readonly previousDayDiffItem: CaptionText;
  private readonly openLabelItem: CaptionText;
  private readonly openValueItem: CaptionText;
  private readonly highLabelItem: CaptionText;
  private readonly highValueItem: CaptionText;
  private readonly lowLabelItem: CaptionText;
  private readonly lowValueItem: CaptionText;
  private readonly closeLabelItem: CaptionText;
  private readonly closeValueItem: CaptionText;
  private readonly previousBarDiffLabelItem: CaptionText;
  private readonly previousBarDiffValueItem: CaptionText;

  constructor(captionArea: CaptionArea,
              public readonly instrumentData: InstrumentData,
              isDeletable: boolean) {
    super(captionArea);
    const primaryFontSize = this.chart.styleOptions.primaryCaptionFontSize;
    const secondaryFontSize = this.chart.styleOptions.secondaryCaptionFontSize;
    const primaryColor = this.chart.optionManager.chartColor.captionPrimary;
    const secondaryColor = this.chart.optionManager.chartColor.captionSecondary;
    this.iconItem = new CaptionInstrumentIcon(this, this.instrumentData.instrument);
    this.nameItem = new CaptionText(this, primaryFontSize, primaryColor);
    this.captionSeparator = new CaptionText(this, primaryFontSize, primaryColor);
    this.timeframeItem = new CaptionText(this, primaryFontSize, primaryColor);
    if (isDeletable) {
      new CaptionDelete(this, () => this.chart.removeInstrument(instrumentData));
    }
    this.previousDayDiffItem = new CaptionText(this, secondaryFontSize, secondaryColor);
    this.openLabelItem = new CaptionText(this, secondaryFontSize, primaryColor);
    this.openValueItem = new CaptionText(this, secondaryFontSize, secondaryColor);
    this.highLabelItem = new CaptionText(this, secondaryFontSize, primaryColor);
    this.highValueItem = new CaptionText(this, secondaryFontSize, secondaryColor);
    this.lowLabelItem = new CaptionText(this, secondaryFontSize, primaryColor);
    this.lowValueItem = new CaptionText(this, secondaryFontSize, secondaryColor);
    this.closeLabelItem = new CaptionText(this, secondaryFontSize, primaryColor);
    this.closeValueItem = new CaptionText(this, secondaryFontSize, secondaryColor);
    this.previousBarDiffLabelItem = new CaptionText(this, secondaryFontSize, primaryColor);
    this.previousBarDiffValueItem = new CaptionText(this, secondaryFontSize, secondaryColor);
  }

  public override initializeEvents() {
    super.initializeEvents();
    this.initializeItems();
  }

  private initializeItems() {
    const timeframe = this.instrumentData.timeframe.toShortNotation();
    const nameProvider = () => this.chart.callbacks.getInstrumentName(this.instrumentData.instrument);
    this.nameItem.setTextProvider(nameProvider);
    this.captionSeparator.setText("*");
    this.timeframeItem.setText(timeframe);
    this.initializeOHLC();
    this.initializePreviousClose();
  }

  private initializeOHLC() {
    this.openLabelItem.setText("O");
    this.highLabelItem.setText("H");
    this.lowLabelItem.setText("L");
    this.closeLabelItem.setText("C");
    this.previousBarDiffLabelItem.setText("D");
    this.subscribeOn(this.chart.styleOptions.showInstrumentCaptionPriceData.getValueObservable(), s => {
      this.openLabelItem.setIsVisible(s);
      this.openValueItem.setIsVisible(s);
      this.highLabelItem.setIsVisible(s);
      this.highValueItem.setIsVisible(s);
      this.lowLabelItem.setIsVisible(s);
      this.lowValueItem.setIsVisible(s);
      this.closeLabelItem.setIsVisible(s);
      this.closeValueItem.setIsVisible(s);
      this.previousBarDiffLabelItem.setIsVisible(s);
      this.previousBarDiffValueItem.setIsVisible(s);
    });
    this.subscribeOn(this.chart.mouseData.getBarTimeObservable(), time => this.updateValues(time));
  }

  private initializePreviousClose() {
    const combinedObservable = combineLatest([
      this.instrumentData.mainPlot.store.getLastItemObservable(),
      this.instrumentData.getPreviousCloseObservable()
    ]);
    const positiveColor = this.chart.optionManager.chartColor.priceDiffPositive;
    const negativeColor = this.chart.optionManager.chartColor.priceDiffNegative;
    this.subscribeOn(combinedObservable, ([lastItem, previousClose]) => {
      let difference: number;
      let percentage: number;
      if (lastItem == null || previousClose == null || previousClose === 0) {
        difference = 0;
        percentage = 0;
      } else {
        difference = lastItem.main - previousClose;
        percentage = difference / previousClose;
      }
      const isPositive = difference >= 0;
      const sign = isPositive ? "+" : "";
      const priceFormatted = this.chart.formatPrice(difference);
      const text = sign + priceFormatted + " (" + sign + formatPercentage(percentage) + ")";
      this.previousDayDiffItem.setText(text);
      this.previousDayDiffItem.setColor(isPositive ? positiveColor : negativeColor);
    });
  }

  private updateValues(time: number | null) {
    const bar = time == null ? null : this.instrumentData.mainPlot.store.getByTime(time);
    const prevBar = time == null ? null : this.instrumentData.mainPlot.store.getByTimeOffset(time, -1);
    let text = this.chart.callbacks.getTranslation("not_available");

    if (bar instanceof OHLCItem) {
      this.openValueItem.setText(this.chart.formatPrice(bar.open));
      this.highValueItem.setText(this.chart.formatPrice(bar.high));
      this.lowValueItem.setText(this.chart.formatPrice(bar.low));
      this.closeValueItem.setText(this.chart.formatPrice(bar.close));

      if (prevBar instanceof OHLCItem && prevBar.close > 0) {
        const difference = bar.close - prevBar.close;
        const percentage = difference / prevBar.close;
        const sign = difference >= 0 ? "+" : "";
        const priceFormatted = this.chart.formatPrice(difference);
        text = sign + priceFormatted + " (" + sign + formatPercentage(percentage) + ")";
        this.previousBarDiffValueItem.setText(text);
        // TODO discuss with color or without
        // const positiveColor = this.chart.optionManager.chartColor.priceDiffPositive;
        // const negativeColor = this.chart.optionManager.chartColor.priceDiffNegative;
        // this.previousBarDiffValueItem.setColor(isPositive ? positiveColor : negativeColor);
      }
    } else {
      this.openValueItem.setText(text);
      this.highValueItem.setText(text);
      this.lowValueItem.setText(text);
      this.closeValueItem.setText(text);
    }
    this.previousBarDiffValueItem.setText(text);
  }
}

export class IndicatorCaptionLine extends CaptionLine {

  private readonly nameItem: CaptionText;
  private readonly settingsItem: CaptionSettings;
  private readonly deleteItem: CaptionDelete;
  private readonly plotItems = new Map<Plot, CaptionText>();

  constructor(captionArea: CaptionArea,
              public readonly indicatorData: IndicatorData) {
    super(captionArea);
    const color = this.chart.optionManager.chartColor.captionPrimary;
    const fontSize = this.chart.styleOptions.secondaryCaptionFontSize;
    this.nameItem = new CaptionText(this, fontSize, color);
    this.settingsItem = new CaptionSettings(this, () => this.chart.editChartObject(indicatorData.indicator));
    this.deleteItem = new CaptionDelete(this, () => this.chart.removeChartObject(indicatorData.indicator));
  }

  public override initializeEvents() {
    super.initializeEvents();
    this.subscribeOn(this.indicatorData.indicator.resetSubject, () => this.createPlotItems());
    this.subscribeOn(this.chart.mouseData.getBarTimeObservable(), time => this.updateValues(time));
  }

  private createPlotItems() {
    const indicator = this.indicatorData.indicator;
    for (const item of this.plotItems.values()) {
      this.removeItem(item);
    }
    this.plotItems.clear();
    for (const plot of indicator.plots) {
      const color = plot.colorProvider.getPrimaryColor();
      const item = new CaptionText(this, this.chart.styleOptions.secondaryCaptionFontSize, color);
      this.plotItems.set(plot, item);
    }
    this.updateValues(this.chart.mouseData.getBarTime());
    this.nameItem.setText(indicator.getCaption());
  }

  private updateValues(time: number | null) {
    for (const [plot, item] of this.plotItems.entries()) {
      const bar = time == null ? null : plot.store.getByTime(time);
      const text = bar == null ? this.chart.callbacks.getTranslation("not_available") : this.chart.formatPrice(bar.main);
      item.setText(text);
    }
  }
}

export class IndicatorExpanderLine extends CaptionLine implements Clickable {

  private readonly expandItem: CaptionExpand;
  private readonly textItem: CaptionText;

  constructor(captionArea: CaptionArea) {
    super(captionArea);
    const fontSize = this.chart.styleOptions.secondaryCaptionFontSize;
    this.expandItem = new CaptionExpand(this, fontSize, this.chart.optionManager.chartColor.captionPrimary);
    this.textItem = new CaptionText(this, fontSize, this.chart.optionManager.chartColor.captionPrimary);
  }

  public override initializeEvents() {
    super.initializeEvents();
    const observable = this.subChart.getIsIndicatorsExpandedObservable();
    this.subscribeOn(observable, isExpanded => this.update(isExpanded));
  }

  public onClick() {
    const isExpanded = this.subChart.getIsIndicatorsExpanded();
    this.subChart.setIsIndicatorsExpanded(!isExpanded);
  }

  public override setSize(size: Size) {
    const paddingVertical = this.chart.styleOptions.captionExpanderPaddingVertical.getValue();
    const paddingHorizontal = this.chart.styleOptions.captionExpanderPaddingHorizontal.getValue();
    super.setSize(new Size(size.width + paddingHorizontal * 2, size.height + paddingVertical * 2));
  }

  public override getStackPanelPosition() {
    const position = this.getPosition();
    const paddingHorizontal = this.chart.styleOptions.captionExpanderPaddingHorizontal.getValue();
    const paddingVertical = this.chart.styleOptions.captionExpanderPaddingVertical.getValue();
    return new Rectangle(
      position.xStart + paddingHorizontal,
      position.yStart + paddingVertical + window.devicePixelRatio,
      position.xEnd - paddingHorizontal,
      position.yEnd - paddingVertical + window.devicePixelRatio
    );
  }

  protected override drawInternal() {
    super.drawInternal();
    const position = this.getPosition();
    const borderRadius = this.chart.styleOptions.textBoxBorderRadius.getValue();
    const color = this.chart.optionManager.chartColor.captionButtonBackground.getValue();
    const backgroundColor = this.chart.optionManager.chartColor.background.getValue();
    this.drawer.drawRoundRect(position, borderRadius, color, backgroundColor);
  }

  private update(isExpanded: boolean) {
    this.expandItem.setIsExpanded(isExpanded);
    let text: string;
    if (isExpanded) {
      text = "";
    } else {
      const indicatorCount = this.subChart.getIndicatorDatas().length;
      const qualifier = indicatorCount > 1 ? "plural" : "singular";
      const translation = this.chart.callbacks.getTranslation("chart#caption_indicator#" + qualifier);
      text = indicatorCount + " " + translation;
    }
    this.textItem.setText(text);
  }
}

import { Instrument } from "@/anfin-chart/instrument";
import { ApiModelConverter } from "@/api/messages/converter";
import type {
  AlertConditionResponse,
  AlertResponse,
  AlertRuleDefinitionResponse,
  AlertRuleResponse
} from "@/api/messages/alert";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import type { Chart } from "@/anfin-chart/chart";

export enum AlertState {
  New = 0,
  Pending = 1,
  Active = 2,
  Triggered = 3,
  Inactive = 4,
  Deleted = 5
}

export enum AlertRuleDirection {
  Above = 0,
  Below = 1,
  Between = 2,
  Outside = 3
}

export enum AlertRuleType {
  FixedValue = 0,
  UserTool = 1,
  AutoTool = 2,
  Indicator = 3
}

export enum AlertConditionOperator {
  And = 0,
  Or = 1
}

export class Alert {

  public state: AlertState = AlertState.New;
  public createdAt = Date.now();
  public updatedAt = Date.now();

  constructor(public id: string | null,
              public readonly name: string,
              public readonly rules: AlertRule[],
              public condition: AlertCondition) {
  }
}

export class AlertRule {

  constructor(public direction: AlertRuleDirection,
              public instrument: Instrument,
              public timeframe: Timeframe,
              public readonly definitions: AlertRuleDefinition[]) {
  }

  public static createDefault(chart: Chart, definition: AlertRuleDefinition = new AlertFixedValueDefinition(0)) {
    const instrumentData = chart.getMainInstrumentData();
    return new AlertRule(AlertRuleDirection.Above, instrumentData.instrument, instrumentData.timeframe, [definition]);
  }
}

export abstract class AlertRuleDefinition {

  protected constructor(public readonly type: AlertRuleType) {
  }
}

export class AlertFixedValueDefinition extends AlertRuleDefinition {

  constructor(public fixedValue: number) {
    super(AlertRuleType.FixedValue);
  }
}

export class AlertUserToolDefinition extends AlertRuleDefinition {

  public slope: AlertUserToolSlope | null = null;

  constructor(public toolId: number,
              public basePointIndex: number,
              public targetPointIndex: number | null = null,
              public percentageOffset: number | null = null) {
    super(AlertRuleType.UserTool);
  }
}

export class AlertUserToolSlope {

  constructor(public readonly startIndex: number,
              public readonly endIndex: number) {
  }
}

export class AlertAutoToolDefinition extends AlertRuleDefinition {

  constructor(public readonly toolId: string,
              public percentageOffset: number | null = null) {
    super(AlertRuleType.AutoTool);
  }
}

export class AlertIndicatorDefinition extends AlertRuleDefinition {

  constructor(public readonly indicatorId: number,
              public readonly plotIndex: number) {
    super(AlertRuleType.Indicator);
  }
}

export type AlertCondition = SimpleAlertCondition | ComplexAlertCondition;

export class SimpleAlertCondition {

  constructor(public readonly rule: number) {
  }
}

export class ComplexAlertCondition {

  constructor(public readonly operator: AlertConditionOperator,
              public readonly left: AlertCondition,
              public readonly right: AlertCondition) {
  }
}

export class AlertConverter extends ApiModelConverter<Alert, AlertResponse> {

  private readonly ruleConverter = new AlertRuleConverter();
  private readonly conditionConverter = new AlertConditionConverter();

  public toApiObject(alert: Alert) {
    return {
      id: alert.id,
      name: alert.name,
      rules: alert.rules.map(r => this.ruleConverter.toApiObject(r)),
      condition: this.conditionConverter.toApiObject(alert.condition),
      duration: { type: 0 },
      event_outputs: []
    };
  }

  public toModelObject(response: AlertResponse) {
    const rules = response.rules.map(r => this.ruleConverter.toModelObject(r));
    const condition = this.conditionConverter.toModelObject(response.condition);
    const alert = new Alert(response.id, response.name ?? "", rules, condition);
    if (response.state != null) {
      alert.state = response.state.type;
      alert.createdAt = response.state.created_time;
      alert.updatedAt = response.state.change_time;
    }
    return alert;
  }

  public override clone(alert: Alert) {
    const clonedAlert = super.clone(alert);
    clonedAlert.state = alert.state;
    return clonedAlert;
  }
}

export class AlertRuleConverter extends ApiModelConverter<AlertRule, AlertRuleResponse> {

  private readonly definitionConverter = new AlertRuleDefinitionConverter();

  public toApiObject(rule: AlertRule) {
    const instrument = {
      symbol: rule.instrument.getSymbol(),
      tf: rule.timeframe.toShortNotation()
    };
    const definitions = rule.definitions.map(d => this.definitionConverter.toApiObject(d));
    return {
      direction: rule.direction,
      instrument,
      definition: definitions
    };
  }

  public toModelObject(response: AlertRuleResponse) {
    const instrument = Instrument.fromSymbol(response.instrument.symbol);
    const timeframe = Timeframe.fromShortNotation(response.instrument.tf);
    const definitions = response.definition.map(d => this.definitionConverter.toModelObject(d));
    return new AlertRule(response.direction, instrument, timeframe, definitions);
  }
}

export class AlertRuleDefinitionConverter extends ApiModelConverter<AlertRuleDefinition, AlertRuleDefinitionResponse> {

  public toApiObject(definition: AlertRuleDefinition) {
    const obj: AlertRuleDefinitionResponse = {
      type: definition.type
    };
    if (definition instanceof AlertFixedValueDefinition) {
      obj.value = definition.fixedValue;
    }
    if (definition instanceof AlertUserToolDefinition) {
      obj.id = definition.toolId.toString();
      obj.basePoint = definition.basePointIndex;
      if (definition.targetPointIndex != null) {
        obj.targetPoint = definition.targetPointIndex;
      }
      if (definition.percentageOffset != null) {
        obj.percentageOffset = definition.percentageOffset;
      }
      if (definition.slope != null) {
        obj.slope = { start: definition.slope.startIndex, end: definition.slope.endIndex };
      }
    }
    if (definition instanceof AlertAutoToolDefinition) {
      obj.id = definition.toolId;
      if (definition.percentageOffset != null) {
        obj.percentageOffset = definition.percentageOffset;
      }
    }
    if (definition instanceof AlertIndicatorDefinition) {
      obj.id = definition.indicatorId.toString();
      obj.plotIndex = definition.plotIndex;
    }
    return obj;
  }

  public toModelObject(response: AlertRuleDefinitionResponse) {
    if (response.type === AlertRuleType.FixedValue && response.value != null) {
      return new AlertFixedValueDefinition(response.value);
    }
    if (response.type === AlertRuleType.UserTool) {
      return this.createUserToolDefinition(response);
    }
    if (response.type === AlertRuleType.AutoTool) {
      return this.createAutoToolDefinition(response);
    }
    if (response.type === AlertRuleType.Indicator) {
      return this.createIndicatorDefinition(response);
    }
    console.error("Unknown rule type: " + response.type);
    return new AlertFixedValueDefinition(0);
  }

  private createUserToolDefinition(response: AlertRuleDefinitionResponse) {
    const id = this.getFallbackValue(response.id, "0");
    const basePoint = this.getFallbackValue(response.basePoint, 0);
    const definition = new AlertUserToolDefinition(Number(id), basePoint, response.targetPoint, response.percentageOffset);
    if (response.slope != null) {
      definition.slope = new AlertUserToolSlope(response.slope.start, response.slope.end);
    }
    return definition;
  }

  private createAutoToolDefinition(response: AlertRuleDefinitionResponse) {
    const id = this.getFallbackValue(response.id, "0");
    return new AlertAutoToolDefinition(id, response.percentageOffset);
  }

  private createIndicatorDefinition(response: AlertRuleDefinitionResponse) {
    const id = this.getFallbackValue(response.id, "0");
    const plotIndex = this.getFallbackValue(response.plotIndex, 0);
    return new AlertIndicatorDefinition(Number(id), plotIndex);
  }

  private getFallbackValue<T>(value: T | undefined, fallback: T): T {
    if (value == null) {
      console.error("Invalid rule definition");
      return fallback;
    }
    return value;
  }
}

export class AlertConditionConverter extends ApiModelConverter<AlertCondition, AlertConditionResponse> {

  public toApiObject(condition: AlertCondition): AlertConditionResponse {
    if (condition instanceof SimpleAlertCondition) {
      return condition.rule;
    }
    return {
      type: condition.operator,
      left: this.toApiObject(condition.left),
      right: this.toApiObject(condition.right)
    };
  }

  public toModelObject(response: AlertConditionResponse): AlertCondition {
    if (typeof response === "object") {
      const left = this.toModelObject(response.left);
      const right = this.toModelObject(response.right);
      return new ComplexAlertCondition(response.type, left, right);
    }
    return new SimpleAlertCondition(response);
  }
}

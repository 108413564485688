import { ChartArea } from "@/anfin-chart/area/chart-area";
import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { Point, Rectangle } from "@/anfin-chart/geometry";

class MouseTimeData {

  constructor(public readonly time: number,
              public readonly text: string) {
  }
}

export class MouseTimeMarker extends ChartArea {

  private readonly textAlignment = new TextAlignment();
  private data: MouseTimeData | null = null;

  public constructor(layer: ChartLayer) {
    super(layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.chart.timeAxis.getPositionObservable(), () => this.resize());
    this.subscribeOn(this.chart.mouseData.getBarTimeObservable(), time => this.updateText(time));
    this.subscribeOn(this.chart.crosshair.getIsVisibleObservable(), isVisible => this.setIsVisible(isVisible));
  }

  protected override drawInternal() {
    if (this.data == null) {
      return;
    }
    const position = this.getPosition();
    const middleX = Math.round((position.xStart + position.xEnd) / 2);
    if (middleX > 1) {
      const chartColors = this.chart.optionManager.chartColor;
      const middleY = Math.round((position.yStart + position.yEnd) / 2);
      const backgroundColor = chartColors.timeMarker.getValue();
      this.drawer.drawRect(position, null, backgroundColor);
      const fontInfo = this.chart.getFontInfo();
      const textPosition = new Point(middleX, middleY);
      const textColor = chartColors.timeMarkerText.getValue();
      this.drawer.printText(textPosition, this.data.text, textColor, this.textAlignment, fontInfo);
    }
  }

  protected override resizeInternal() {
    if (this.data == null) {
      return ChartArea.defaultPosition;
    }
    const timeAxisPosition = this.chart.timeAxis.getPosition();
    const fontInfo = this.chart.getFontInfo();
    const textSize = this.drawer.measureText(this.data.text, fontInfo);
    const markerWidth = textSize.width + this.chart.styleOptions.timeMarkerPadding.getValue();
    const x = this.chart.timeAxis.getXForTime(this.data.time);
    const xStart = x - markerWidth / 2;
    const xEnd = x + markerWidth / 2;
    const yStart = timeAxisPosition.yStart;
    const yEnd = timeAxisPosition.yEnd;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private updateText(time: number | null) {
    if (time == null) {
      this.data = null;
    } else {
      const text = this.chart.formatDate(new Date(time));
      this.data = new MouseTimeData(time, text);
      this.resize();
    }
  }
}

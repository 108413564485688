import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import { BooleanOption, ColorOption } from "@/anfin-chart/options/option";
import { LineElement } from "@/anfin-chart/drawable";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint, getLeftRight } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { projectLineRect } from "@/anfin-chart/geometry";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class LineTool extends UserToolDefinition {

  public static readonly type = "line";

  private readonly color = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(255, 0, 0));
  private readonly expandLeft = new BooleanOption(this, OptionName.ExpandLeft, true);
  private readonly expandRight = new BooleanOption(this, OptionName.ExpandRight, true);

  private readonly leftExpanded = new ChartToolPoint();
  private readonly rightExpanded = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(LineTool.type, instrument, timeframe, 2, 2);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
  }

  public override updatePosition(tool: UserTool) {
    const [first, second] = this.fixedPoints.map(p => tool.getPosition(p));
    if (this.fixedPoints.length >= 2) {
      const [left, right] = getLeftRight([first, second]);
      const rect = tool.subChart.drawingArea.getPosition();
      const leftProjected = this.expandLeft.getValue() ? projectLineRect(right, left, rect) : left;
      const rightProjected = this.expandRight.getValue() ? projectLineRect(left, right, rect) : right;
      tool.updatePoint(this.leftExpanded, leftProjected);
      tool.updatePoint(this.rightExpanded, rightProjected);
    }
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const line = new LineElement(tool, this.leftExpanded, this.rightExpanded);
    tool.onUpdate(() => {
      line.color = this.color.getValue();
      line.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, this.fixedPoints[1]);
  }
}

import { defineComponent } from "vue";

export const InputUtilsMixin = defineComponent({
  methods: {
    processNumeric(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        const value = event.target.value;
        return value === "" ? null : Number(value);
      }
      return null;
    },

    processCheck(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        return event.target.checked;
      }
      return false;
    }
  }
});

import { Size } from "@/anfin-chart/geometry";
import type { NumericOption } from "@/anfin-chart/options/option";
import { BehaviorSubject, combineLatest, distinctUntilChanged, startWith } from "rxjs";
import type { StackItem } from "@/anfin-chart/area/stack-item";
import { ChartArea } from "@/anfin-chart/area/chart-area";
import type { ChartLayer } from "@/anfin-chart/chart-layer";

export enum StackDirection {
  Horizontal = 0,
  Vertical = 1
}

export enum StackAlignment {
  Start = 0,
  End = 1,
  Center = 2,
  Stretch = 3
}

export abstract class StackPanel extends ChartArea {

  public items: StackItem[] = [];
  private readonly size = new BehaviorSubject(new Size(0, 0));

  protected constructor(layer: ChartLayer,
                        public readonly direction: StackDirection,
                        public readonly alignment: StackAlignment,
                        private readonly itemMargin: NumericOption) {
    super(layer);
  }

  public override onDelete() {
    super.onDelete();
    this.clear();
  }

  public getSizeObservable() {
    return this.size.pipe(
      distinctUntilChanged((last, current) => last.width === current.width &&
        last.height === current.height),
      startWith(this.size.value)
    );
  }

  public getSize() {
    return this.size.value;
  }

  public clear() {
    for (const item of this.items) {
      this.unregisterItem(item);
    }
    this.items = [];
  }

  public addStackItem(item: StackItem, index: number = this.items.length) {
    this.items.splice(index, 0, item);
    this.registerItem(item);
    this.calculateItems();
  }

  public removeStackItem(item: StackItem) {
    const index = this.items.indexOf(item);
    if (index === -1) {
      return;
    }
    this.items.splice(index, 1);
    this.unregisterItem(item);
    this.calculateItems();
  }

  public setStackItems(items: StackItem[]) {
    const currentItems = new Set(this.items);
    for (const item of items) {
      if (currentItems.has(item)) {
        currentItems.delete(item);
      } else {
        this.registerItem(item);
      }
    }
    currentItems.forEach(i => this.unregisterItem(i));
    this.items = items;
    this.calculateItems();
  }

  private registerItem(item: StackItem) {
    const combinedObservable = combineLatest([item.getPositionObservable(), item.getIsVisibleObservable()]);
    this.subscriptionManager.subscribe(combinedObservable, () => this.calculateItems(), item);
  }

  private unregisterItem(item: StackItem) {
    this.subscriptionManager.unsubscribe(item);
    item.onDelete();
  }

  private calculateItems() {
    let crossAxis = 0;
    let offset = 0;
    const margin = this.itemMargin.getValue();
    for (const item of this.items) {
      if (!item.getIsVisible()) {
        continue;
      }
      item.offset = offset;
      item.resize();
      const itemSize = item.getSize();
      const mainAxis = this.getMainAxis(itemSize);
      if (mainAxis > 0) {
        offset += mainAxis + margin;
      }
      crossAxis = Math.max(crossAxis, this.getCrossAxis(itemSize));
    }
    offset -= margin;
    this.setCurrentSize(offset, crossAxis);
  }

  private setCurrentSize(mainAxis: number, crossAxis: number) {
    let width: number;
    let height: number;
    if (this.direction === StackDirection.Horizontal) {
      width = mainAxis;
      height = crossAxis;
    } else {
      width = crossAxis;
      height = mainAxis;
    }
    const size = new Size(width, height);
    this.size.next(size);
    this.resize();
  }

  private getMainAxis(size: Size) {
    switch (this.direction) {
      case StackDirection.Horizontal:
        return size.width;
      case StackDirection.Vertical:
        return size.height;
      default:
        return 0;
    }
  }

  private getCrossAxis(size: Size) {
    switch (this.direction) {
      case StackDirection.Horizontal:
        return size.height;
      case StackDirection.Vertical:
        return size.width;
      default:
        return 0;
    }
  }
}

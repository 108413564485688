import type { Directive } from "vue";

type ClickOutsideListener = (e: MouseEvent) => void;

const listenerMap = new Map<HTMLElement, ClickOutsideListener>();

export const ClickOutside: Directive<HTMLElement, () => boolean> = {
  mounted(element, binding) {
    const listener = (e: MouseEvent) => {
      const target = e.target as Node;
      if (!element.contains(target)) {
        const isConsumed = binding.value();
        if (isConsumed) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    };
    listenerMap.set(element, listener);
    document.body.addEventListener("mousedown", listener);
  },

  unmounted(element) {
    const listener = listenerMap.get(element);
    if (listener != null) {
      document.body.removeEventListener("mousedown", listener);
    }
  }
};

import { RestApiController } from "@/api/rest-api-controller";
import type { WatchlistItemResponse, WatchlistListResponse } from "@/api/messages/watchlist";
import type { Watchlist, WatchlistItem } from "@/api/models/watchlist";

export class WatchlistController extends RestApiController {

  private static instance: WatchlistController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new WatchlistController();
    }
    return this.instance;
  }

  public async requestWatchlists() {
    const data = await this.sendGetRequest<WatchlistListResponse>("watchlists");
    return data.watchlists;
  }

  public async requestWatchlistItems(watchlist: Watchlist) {
    const data = await this.sendGetRequest<WatchlistItemResponse>("watchlists/" + watchlist.id + "/items");
    return data.items;
  }

  public async createWatchlist(watchlistName: string) {
    const request = {
      name: watchlistName
    };
    return this.sendPostRequest<number>("watchlists", request);
  }

  public async saveWatchlistItems(watchlistId: number, items: WatchlistItem[]) {
    const request = {
      items: items.map((item, index) => {
        return { symbol: item.symbol, sortIndex: index };
      })
    };
    this.sendPostRequest<number>("watchlists/" + watchlistId + "/items", request);
  }

  public async deleteWatchlistItem(watchlistId: number, symbol: string) {
    this.sendDeleteRequest<number>("watchlists/" + watchlistId + "/items/" + symbol);
  }

  public async deleteWatchlist(watchlistId: number) {
    this.sendDeleteRequest<number>("watchlists/" + watchlistId);
  }

  public async saveWatchlist(watchlist: Watchlist) {
    const request = {
      name: watchlist.name
    };
    return this.sendPostRequest<number>("watchlists", request);
  }

  public async renameWatchlist(watchlist: Watchlist) {
    const request = {
      name: watchlist.name
    };
    return this.sendPostRequest<number>("watchlists/" + watchlist.id, request);
  }

  protected override getBaseUrl() {
    return this.environment.configurationUrl;
  }
}

<template>
  <MultiSelect :items="items" :disabled="disabled" @change="onChange" />
</template>

<script lang="ts">
import { getEnumValues } from "@/anfin-chart/utils";
import { TrendLineType } from "@/api/models/analysis/auto-trend-line";
import MultiSelect, { MultiSelectItem } from "@/views/components/MultiSelect.vue";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "SelectTrendLineType",

  components: { MultiSelect },

  props: {
    value: {
      type: Array as PropType<TrendLineType[]>,
      required: false,
      default: null
    },
    disabled: Boolean
  },

  emits: {
    change: (types: TrendLineType[]) => types || true
  },

  expose: [],

  data() {
    return {
      trendLineTypes: getEnumValues(TrendLineType)
    };
  },

  computed: {
    items() {
      return this.trendLineTypes.map(t => new MultiSelectItem("trend_line_type#" + t, t, this.value.includes(t)));
    }
  },

  methods: {
    onChange(values: unknown[]) {
      this.$emit("change", values as TrendLineType[]);
    }
  }
});
</script>

<style scoped>
</style>

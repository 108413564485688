<template>
  <div class="alert-rule py-2 px-2">
    <div class="alert-rule-header">
      <div>
        {{ typeLabel }}
      </div>
      <div class="alert-rule-instrument ms-auto" @click="loadInstrument">
        <span>
          {{ instrumentName }}
        </span>
        <span v-if="showTimeframe" class="ms-2">
          {{ rule.timeframe.toShortNotation() }}
        </span>
      </div>
    </div>
    <div class="alert-rule-definition mt-1">
      {{ definitionLabel }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { processPlaceholders, translationStore } from "@/stores/translation-store";
import { AlertAutoToolDefinition, AlertRule, AlertRuleType, AlertUserToolDefinition } from "@/api/models/alert";
import { multiChartStore } from "@/stores/multi-chart-store";
import { chartObjectStore, getAlertDefinitionLabel } from "@/stores/chart-object-store";
import { instrumentStore } from "@/stores/instrument-store";
import { analysisStore } from "@/stores/analysis-store";

export default defineComponent({
  name: "AlertOverviewItemRule",

  props: {
    rule: {
      type: AlertRule,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    showTimeframe() {
      const types = new Set(this.rule.definitions.map(d => d.type));
      return types.has(AlertRuleType.UserTool) || types.has(AlertRuleType.AutoTool) || types.has(AlertRuleType.Indicator);
    },

    typeLabel() {
      const translations = new Set();
      for (const definition of this.rule.definitions) {
        let translationKey = "alert_rule_type#" + definition.type;
        if (definition instanceof AlertUserToolDefinition) {
          const userTool = chartObjectStore().getOrRequestUserTool(definition.toolId);
          if (userTool != null) {
            translationKey = "user_tool#" + userTool.type;
          }
        } else if (definition instanceof AlertAutoToolDefinition) {
          const analysis = analysisStore().getAnalysis(definition.toolId);
          if (analysis != null) {
            translationKey = "analysis#" + analysis.type;
          }
        }
        const translation = this.translationStore.getTranslation(translationKey);
        translations.add(translation);
      }
      return Array.from(translations).join(" | ");
    },

    definitionLabel() {
      const translation = this.translationStore.getTranslation("alert_rule_direction#" + this.rule.direction + "#long");
      const data: Record<string, string> = {};
      for (let i = 0; i < this.rule.definitions.length; i++) {
        const definition = this.rule.definitions[i];
        data["definition_" + i] = getAlertDefinitionLabel(definition);
      }
      return processPlaceholders(translation, data);
    },

    instrumentName() {
      const symbol = this.rule.instrument.getSymbol();
      const instrumentDetail = instrumentStore().getDetail(symbol);
      return instrumentDetail?.printName ?? symbol;
    }
  },

  methods: {
    loadInstrument() {
      multiChartStore().changeInstrument(this.rule.instrument);
      multiChartStore().setTimeframe(this.rule.timeframe);
    }
  }
});
</script>

<style scoped>
.alert-rule {
  width: 100%;
}

.alert-rule:not(:last-child) {
  border-bottom: 1px solid var(--border-neutral);
}

.alert-rule-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

.alert-rule-instrument {
  font-weight: 500;
  color: var(--interactive-accent);
  cursor: pointer;
}

.alert-rule-instrument:hover {
  color: var(--interactive-accent-hover);
}
</style>
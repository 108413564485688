<template>
  <div class="toast-item" :class="{ 'toast-error': toast.type === ToastType.Error }" @mousemove.stop="refresh" @click.stop>
    <IconElement class="close-button ms-auto" iconName="Cross" :size="10" @click.stop="remove" />
    <div>{{ getText() }}</div>
    <button v-if="toast.action != null" type="button" class="btn btn-link p-0 mt-1" @click="runAction">
      {{ translationStore.getTranslation("toast#action_" + toast.type) }}
    </button>
    <div v-if="allowHide" class="form-check mt-1">
      <input :checked="isHiddenType" class="form-check-input" type="checkbox" @change="onHideTypeChanged" />
      <label class="form-check-label">{{ translationStore.getTranslation("toast#do_not_show_again") }}</label>
    </div>
  </div>
</template>

<script lang="ts">
import { Toast, toastStore, ToastType } from "@/stores/toast-store";
import { processPlaceholders, translationStore } from "@/stores/translation-store";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToastItem",

  components: { IconElement },

  props: {
    toast: {
      type: Toast,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      toastStore: toastStore(),
      ToastType
    };
  },

  computed: {
    isHiddenType() {
      return this.toastStore.isTypeHidden(this.toast.type);
    },

    allowHide() {
      return this.toast.type !== ToastType.Info && this.toast.type !== ToastType.Error;
    }
  },

  methods: {
    refresh() {
      this.toastStore.refreshToast(this.toast);
    },

    remove() {
      this.toastStore.removeToast(this.toast.id);
    },

    getText() {
      const translation = this.translationStore.getTranslation(this.toast.key);
      return processPlaceholders(translation, this.toast.data);
    },

    runAction() {
      this.toast.action?.();
      this.remove();
    },

    onHideTypeChanged(event: Event) {
      const target = event.target as HTMLInputElement;
      const value = target.checked;
      this.toastStore.setTypeHidden(this.toast.type, value);
    }
  }
});
</script>

<style scoped>
.toast-item {
  position: relative;
  background-color: var(--background-elevated);
  border: 1px solid var(--border-overlay);
  border-radius: var(--border-radius-single);
  padding: 10px 15px 5px 10px;
}

.toast-error {
  background-color: var(--background-error);
}

.toast-item .close-button {
  position: absolute;
  top: 7px;
  right: 7px;
}
</style>

<template>
  <div class="product-table-item">
    <template v-if="feature.type === ProductFeatureOptionType.Text">
      {{ feature.value }}
    </template>
    <template v-else-if="feature.type === ProductFeatureOptionType.Infinity">
      <IconElement v-if="feature.value" :size="36" iconName="Infinity" class="product-table-infinity" />
      <IconElement v-else :size="24" iconName="Cross" class="product-table-uncheck" />
    </template>
    <template v-else-if="feature.type === ProductFeatureOptionType.Check">
      <IconElement v-if="feature.value" :size="36" iconName="Check" class="product-table-check" />
      <IconElement v-else :size="24" iconName="Cross" class="product-table-uncheck" />
    </template>
    <template v-else-if="feature.type === ProductFeatureOptionType.ComingSoon">
      <div v-if="feature.value">
        {{ translationStore.getTranslation("product_feature#coming_soon") }}
      </div>
      <IconElement v-else :size="24" iconName="Cross" class="product-table-uncheck" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ProductFeatureOption, ProductFeatureOptionType } from "@/api/models/product-comparison";
import IconElement from "@/views/icons/IconElement.vue";
import { translationStore } from "@/stores/translation-store";

export default defineComponent({
  name: "ProductTableItem",

  components: { IconElement },

  props: {
    feature: {
      type: ProductFeatureOption,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      ProductFeatureOptionType
    };
  }
});
</script>

<style scoped>
.product-table-item {
  text-align: center;
  align-self: center;
}

.product-table-infinity {
  color: var(--content-secondary);
}

.product-table-uncheck {
  color: var(--content-tertiary);
}

.product-table-check {
  color: var(--interactive-primary);
}
</style>
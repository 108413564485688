<template>
  <div>
    <label class="form-label">
      {{ translationStore.getTranslation("indicator_definition#label_dependent_indicators") }}
    </label>
    <div class="card-set-container">
      <div
        v-for="(indicator, indicatorIndex) in indicatorDefinition.indicators" :key="indicatorIndex"
        class="card card-sm card-transparent dependent-indicator"
      >
        <IconElement class="close-button" :size="14" iconName="Cross" @click="removeIndicator(indicatorIndex)" />
        <div>
          <label class="form-label">
            {{ translationStore.getTranslation("indicator_definition#label_type") }}
          </label>
          <input v-model="indicator.type" type="text" class="form-control form-control-sm" />
        </div>
        <div class="mt-2">
          <label class="form-label">
            {{ translationStore.getTranslation("indicator_definition#label_name") }}
          </label>
          <input v-model="indicator.name" type="text" class="form-control form-control-sm" />
        </div>
      </div>
    </div>
    <button class="btn btn-primary text-nowrap mt-2" type="button" @click="createIndicator">
      <span>
        <IconElement :size="14" iconName="Plus" />
      </span>
      {{ translationStore.getTranslation("indicator_definition#button_create_dependent_indicator") }}
    </button>
    <div>
      <div>
        <label class="form-label mt-3">
          {{ translationStore.getTranslation("indicator_definition#label_utility_functions") }}
        </label>
      </div>
      <div v-for="(utility, utilityIndex) in indicatorDefinition.utils" :key="utilityIndex">
        <AutoSizeTextarea v-model="indicatorDefinition.utils[utilityIndex]" />
        <IconElement class="close-button" :size="14" iconName="Cross" @click="removeFunction(utilityIndex)" />
      </div>
      <button class="btn btn-primary text-nowrap mt-2" type="button" @click="createFunction">
        <span>
          <IconElement :size="14" iconName="Plus" />
        </span>
        {{ translationStore.getTranslation("indicator_definition#button_create_utility_function") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import type { IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import { translationStore } from "@/stores/translation-store";
import AutoSizeTextarea from "@/views/components/AutoSizeTextarea.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "IndicatorDefinitionDependencies",

  components: { AutoSizeTextarea, IconElement },

  props: {
    indicatorDefinition: {
      type: Object as PropType<IndicatorDefinition>,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  methods: {
    createIndicator() {
      this.indicatorDefinition.indicators.push({ type: "", name: "" });
    },

    removeIndicator(index: number) {
      this.indicatorDefinition.indicators.splice(index, 1);
    },

    createFunction() {
      this.indicatorDefinition.utils.push("");
    },

    removeFunction(index: number) {
      this.indicatorDefinition.utils.splice(index, 1);
    }
  }
});
</script>

<style scoped>
</style>

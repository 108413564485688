import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import type { IndicatorTemplate } from "@/anfin-chart/converter/indicator-converter";

export class ChartExport {

  constructor(public readonly isLinkedSplitChart: boolean,
              public readonly subCharts: SubChartExport[]) {
  }
}

export class SubChartExport {

  constructor(public readonly height: number,
              public instruments: InstrumentExport[],
              public readonly indicators: IndicatorTemplate[],
              public readonly isIndicatorsExpanded: boolean) {
  }
}

export class InstrumentExport {

  constructor(public instrument: Instrument,
              public timeframe: Timeframe) {
  }
}

import { ChartArea } from "@/anfin-chart/area/chart-area";
import { LineOptions } from "@/anfin-chart/draw/chart-drawer";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { Point, Rectangle } from "@/anfin-chart/geometry";

export class PriceAxisWrapper extends ChartArea {

  public constructor(layer: ChartLayer) {
    super(layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.chart.coreLayerArea.getPositionObservable(), () => this.resize());
  }

  protected override drawInternal() {
    const position = this.getPosition();
    const x = Math.round(position.xStart);
    const start = new Point(x, position.yStart);
    const end = new Point(x, position.yEnd);
    const color = this.chart.optionManager.chartColor.axisLine.getValue();
    this.drawer.drawLine(start, end, color);
  }

  protected override resizeInternal() {
    const generalAreaPosition = this.chart.coreLayerArea.getPosition();
    const xEnd = generalAreaPosition.xEnd;
    const labelWidth = this.getLabelWidth();
    const paddingRight = this.chart.styleOptions.priceAxisPaddingRight.getValue();
    const paddingLeft = this.chart.styleOptions.priceAxisPaddingLeft.getValue();
    const xStart = xEnd - labelWidth - paddingRight - paddingLeft;
    const yStart = generalAreaPosition.yStart;
    const yEnd = generalAreaPosition.yEnd - this.chart.styleOptions.timeAxisHeight.getValue();
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private getLabelWidth() {
    let width = 0;
    for (const subChart of this.chart.getSubCharts()) {
      const subWidth = subChart.priceAxis.measureLabelWidth();
      width = Math.max(width, subWidth);
    }
    return width;
  }
}

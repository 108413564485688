import { LineOptions, LineStyle } from "@/anfin-chart/draw/chart-drawer";
import { LineElement } from "@/anfin-chart/drawable";
import { getSlope, Point, projectLineToX } from "@/anfin-chart/geometry";
import type { InstrumentData } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { ChartToolPoint, FixedPoint } from "@/anfin-chart/tools/tool-point";
import type { AutoChannel } from "@/api/models/analysis/auto-channel";
import type { ColorOption } from "@/anfin-chart/options/option";
import { AnalysisTool } from "@/anfin-chart/tools/analysis-tool";

export class AutoChannelTool extends AnalysisTool {

  private readonly lineColorOption: ColorOption;

  private readonly mainStart: FixedPoint;
  private readonly mainEnd: FixedPoint;
  private readonly copyStart: FixedPoint;
  private readonly targetStart: FixedPoint;

  private readonly mainStartProjected = new ChartToolPoint();
  private readonly mainEndProjected = new ChartToolPoint();
  private readonly copyStartProjected = new ChartToolPoint();
  private readonly copyEndProjected = new ChartToolPoint();
  private readonly targetEnd = new ChartToolPoint();
  private readonly brokenLower = new ChartToolPoint();
  private readonly brokenUpper = new ChartToolPoint();

  constructor(public readonly definition: AutoChannel,
              instrumentData: InstrumentData,
              subChart: SubChart) {
    super(definition, instrumentData, subChart);
    this.lineColorOption = this.getColorOption();
    this.mainStart = new FixedPoint(definition.mainLine.startTime, definition.mainLine.startPrice);
    this.mainEnd = new FixedPoint(definition.mainLine.endTime, definition.mainLine.endPrice);
    this.copyStart = new FixedPoint(definition.copyLine.startTime, definition.copyLine.startPrice);
    this.targetStart = new FixedPoint(this.definition.startTime, definition.targetPrice ?? 0);
  }

  public override getIsVisible() {
    return super.getIsVisible() && !this.definition.deleted && this.optionManager.showChannels.getValue();
  }

  protected override createDrawables() {
    const lineOptions = new LineOptions(2);
    const mainLine = new LineElement(this, this.mainStartProjected, this.mainEndProjected, lineOptions);
    const copyLine = new LineElement(this, this.copyStartProjected, this.copyEndProjected, lineOptions);
    if (this.definition.targetPrice != null) {
      const targetLine = new LineElement(this, this.targetStart, this.targetEnd, new LineOptions(3, LineStyle.Dashed));
      this.onUpdate(() => {
        targetLine.color = this.colorOptions.autoChannelTargetLine.getValue();
      });
    }
    if (this.definition.brokenAt != null) {
      const brokenAtLine = new LineElement(this, this.brokenLower, this.brokenUpper);
      this.onUpdate(() => {
        brokenAtLine.color = this.colorOptions.autoChannelBrokenAtLine.getValue();
      });
    }
    this.onUpdate(() => {
      const lineColor = this.lineColorOption.getValue();
      mainLine.color = lineColor;
      copyLine.color = lineColor;
    });
  }

  protected override updatePositionInternal() {
    this.updateFixedPoint(this.mainStart, this.mainEnd, this.copyStart, this.targetStart);
    const xStart = this.chart.timeAxis.getXForTime(this.definition.startTime);
    const mainStartPosition = this.getPosition(this.mainStart);
    const mainEndPosition = this.getPosition(this.mainEnd);
    const mainStart = projectLineToX(mainStartPosition, mainEndPosition, xStart);
    this.updatePoint(this.mainStartProjected, mainStart);
    const endX = this.getFutureProjectionX();
    const mainEnd = projectLineToX(mainStartPosition, mainEndPosition, endX);
    this.updatePoint(this.mainEndProjected, mainEnd);
    const slope = getSlope(mainStartPosition, mainEndPosition);
    const copyStartPosition = this.getPosition(this.copyStart);
    const copyStartY = copyStartPosition.y + slope * (mainStart.x - copyStartPosition.x);
    const copyStart = new Point(mainStart.x, copyStartY);
    this.updatePoint(this.copyStartProjected, copyStart);
    const copyEndY = copyStartPosition.y + slope * (endX - copyStartPosition.x);
    const copyEnd = new Point(endX, copyEndY);
    this.updatePoint(this.copyEndProjected, copyEnd);
    const targetStartPosition = this.getPosition(this.targetStart);
    const timeAxis = this.chart.timeAxis;
    const lastBarX = timeAxis.getXForTime(timeAxis.getLastTime() ?? 0);
    const targetEnd = new Point(lastBarX, targetStartPosition.y);
    this.updatePoint(this.targetEnd, targetEnd);
    if (this.definition.brokenAt != null) {
      const brokenX = this.chart.timeAxis.getXForTime(this.definition.brokenAt);
      const brokenLower = projectLineToX(mainStart, mainEnd, brokenX);
      this.updatePoint(this.brokenLower, brokenLower);
      const brokenUpper = new Point(brokenX, brokenLower.y + copyStartY - mainStart.y);
      this.updatePoint(this.brokenUpper, brokenUpper);
    }
  }

  private getColorOption() {
    if (this.definition.mainLine.endPrice > this.definition.mainLine.startPrice) {
      return this.colorOptions.autoChannelAscendingLine;
    }
    return this.colorOptions.autoChannelDescendingLine;
  }
}

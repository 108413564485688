<template>
  <div>
    <div v-for="(stop, stopIndex) in stops" class="card card-xs card-transparent gradient-stop mt-2">
      <RgbaColorPickerButton
        :modelValue="stop.color" :size="20" :allowGradient="false" @update:modelValue="setColor(stop, $event)"
      />
      <input
        :value="stop.percentage" class="form-control form-control-sm ms-2"
        @input="setPercentage(stop, processNumeric($event))"
      />
      <IconElement iconName="Cross" :size="14" class="ms-2" @click="removeStop(stopIndex)" />
    </div>
    <button class="btn btn-primary btn-sm text-nowrap mt-2" type="button" @click="createStop">
      <span>
        <IconElement :size="14" iconName="Plus" />
      </span>
      {{ translationStore.getTranslation("color_picker#button_create_gradient_stop") }}
    </button>
  </div>
</template>

<script lang="ts">
import { ColorDefinition, ColorType, GradientStopDefinition } from "@/anfin-chart/indicator-definition";
import { InputUtilsMixin } from "@/mixins/input-utils";
import { translationStore } from "@/stores/translation-store";
import RgbaColorPickerButton from "@/views/color-picker/RgbaColorPickerButton.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GradientColorPicker",

  components: { RgbaColorPickerButton, IconElement },

  mixins: [InputUtilsMixin],

  props: {
    modelValue: {
      type: ColorDefinition,
      required: true
    }
  },

  emits: {
    "update:modelValue": (color: ColorDefinition) => color && true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    stops() {
      return this.modelValue.stops;
    }
  },

  methods: {
    setPercentage(stop: GradientStopDefinition, percentage: number | null) {
      if (percentage != null) {
        stop.percentage = percentage;
        this.$emit("update:modelValue", this.modelValue);
      }
    },

    setColor(stop: GradientStopDefinition, color: ColorDefinition) {
      stop.color = color;
      this.$emit("update:modelValue", this.modelValue);
    },

    createStop() {
      const lastColor = this.modelValue.stops[this.modelValue.stops.length - 1].color;
      const defaultColor = new ColorDefinition(ColorType.Rgba, lastColor.r, lastColor.g, lastColor.b, lastColor.a);
      this.modelValue.stops.push(new GradientStopDefinition(1, defaultColor));
      this.$emit("update:modelValue", this.modelValue);
    },

    removeStop(index: number) {
      this.modelValue.stops.splice(index, 1);
      this.$emit("update:modelValue", this.modelValue);
    }
  }
});
</script>

<style scoped>
.gradient-stop {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>

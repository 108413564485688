<template>
  <div class="icon-button" :class="{ disabled: disabled }">
    <IconElement :size="size" :iconName="iconName" />
  </div>
</template>

<script lang="ts">
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconButton",

  components: { IconElement },

  props: {
    size: {
      type: Number,
      required: true
    },
    iconName: {
      type: String,
      required: true
    },
    disabled: Boolean
  },

  expose: []
});
</script>

<style scoped>
.icon-button {
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.icon-button.disabled {
  opacity: 0.5;
}

.icon-button:hover svg, .icon-button path {
  fill: var(--content-secondary-top);
  stroke: var(--content-secondary-top);
}

.icon-button:not(.disabled):hover {
  border: 1px solid var(--border-primary-overlay);
  background-color: var(--background-primary-overlay);
}
</style>

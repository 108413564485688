import type { Consumer } from "@/anfin-chart/utils";
import { userRightStore } from "@/stores/user-right-store";
import { generalStore } from "@/stores/general-store";
import { versionStore } from "@/stores/version-store";

export class WebSocketMessage {

  public requestId: number | null = null;
  
  constructor(public readonly action: string,
              public readonly data: any,
              public readonly callback: Consumer<any> | null = null) {
  }
}

export abstract class WebSocketApiController {

  protected readonly requestCallbackMap = new Map();

  private connected = false;
  private client: WebSocket | null = null;
  private token: string | null = null;
  private hasTimeout = false;
  private requestCounter = 0;
  private heartbeatTimer: number | null = null;
  private lastHeartbeat = Date.now();
  private readonly pendingMessages: WebSocketMessage[] = [];
  private readonly quietActions = new Set(["heartbeat", "resHeartbeat"]);

  constructor() {
    this.connect();
  }

  public setToken(token: string) {
    this.token = token;
    if (this.connected) {
      this.onOpen();
    }
  }

  public sendMessage(message: WebSocketMessage) {
    this.logAction(message, "WS SEND");
    if (this.client != null && this.connected) {
      const msg = { action: message.action, data: message.data, requestId: this.requestCounter++ };
      this.client.send(JSON.stringify(msg));
      if (message.callback != null) {
        this.requestCallbackMap.set(msg.requestId, message);
      }
    } else if (message.action !== "heartbeat" && message.action !== "login") {
      this.pendingMessages.push(message);
    }
  }

  protected logAction(message: WebSocketMessage, prefix: string) {
    if (this.quietActions.has(message.action)) {
      return;
    }
    let action = message.action;
    if (message.data.action != null) {
      action += " " + message.data.action;
    }
    if (message.data.mode != null) {
      action += " " + message.data.mode;
    }
    generalStore().logDebug(prefix + " " + action);
  }

  protected onHeartbeatResponse() {
    this.lastHeartbeat = Date.now();
  }

  protected onLoginResponse(data: any): boolean {
    if (this.heartbeatTimer != null) {
      window.clearInterval(this.heartbeatTimer);
    }
    if (data.result === "ok") {
      this.heartbeatTimer = window.setInterval(() => {
        this.sendMessage(new WebSocketMessage("heartbeat", { app: "www", version: versionStore().version?.toString() }));
      }, 15000);
      const pendingMessages = this.pendingMessages.splice(0);
      for (const pendingMessage of pendingMessages) {
        this.sendMessage(pendingMessage);
      }
    } else {
      userRightStore().logout();
      return false;
    }
    return true;
  }

  private connect() {
    const url = this.getBaseUrl();
    console.log("ws connect", url);
    this.hasTimeout = false;
    this.client = new WebSocket(url);
    this.client.onopen = this.onOpen.bind(this);
    this.client.onclose = this.onClose.bind(this);
    this.client.onerror = this.onError.bind(this);
    this.client.onmessage = this.onMessage.bind(this);
  }

  private reconnect(waitTime: number) {
    if (!this.hasTimeout && this.client != null) {
      console.log("ws reconnect", waitTime);
      this.hasTimeout = true;
      try {
        this.client.close();
      } catch (e) {
        // ignore
      }
      this.client = null;
      window.setTimeout(this.connect.bind(this), waitTime);
    }
  }

  private onOpen() {
    console.log("ws connect success");
    this.connected = true;
    if (this.token) {
      this.sendMessage(new WebSocketMessage("login", { token: this.token }));
    }
  }

  private onClose(error: CloseEvent) {
    this.connected = false;
    console.error("ws close", error);
    this.reconnect(10000);
  }

  private onError(error: Event) {
    console.error("ws error", error);
    this.reconnect(10000);
  }

  public abstract onMessage(event: MessageEvent): void;

  protected abstract getBaseUrl(): string;
}

import { AnalysisToolDefinition, AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";

export class AutoChannelLine {

  constructor(public readonly startTime: number,
              public readonly startPrice: number,
              public readonly endTime: number,
              public readonly endPrice: number,
              public readonly exactHitDates: number[],
              public readonly crossingShadowDates: number[],
              public readonly nearShadowDates: number[]) {
  }
}

export class AutoChannel extends AnalysisToolDefinition {

  constructor(id: string,
              instrument: Instrument,
              timeframe: Timeframe,
              startTime: number,
              public readonly mainLine: AutoChannelLine,
              public readonly copyLine: AutoChannelLine,
              public readonly brokenAt: number | null,
              public readonly targetPrice: number | null,
              public readonly deleted: boolean) {
    super(id, AnalysisType.Channel, instrument, timeframe, startTime);
  }
}

<template>
  <div>
    <SettingsWindowOptionWrapper v-for="option in chartOptions" :key="option.name" :option="option" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsWindowOptionWrapper from "@/views/settings/SettingsWindowOptionWrapper.vue";
import type { ChartOption } from "@/anfin-chart/options/option";
import { storeToRefs } from "pinia";
import { chartOptionStore, MultiChartOptionManager } from "@/stores/chart-option-store";
import { userRightStore } from "@/stores/user-right-store";

export default defineComponent({
  name: "ChartSettings",

  components: { SettingsWindowOptionWrapper },

  expose: [],

  data() {
    const { optionManager } = storeToRefs(chartOptionStore());
    const { isMobile } = storeToRefs(userRightStore());
    return {
      optionManager: optionManager as unknown as MultiChartOptionManager,
      isMobile: isMobile as unknown as boolean
    };
  },

  computed: {
    chartOptions(): ChartOption<any>[] {
      const optionManager = this.optionManager;
      const options = [];
      if (!this.isMobile) {
        options.push(optionManager.theme);
      }
      options.push(
        optionManager.useToolSnapPrice,
        optionManager.isChainToolMode,
        optionManager.showUserTools,
        optionManager.showAlertTools,
        optionManager.timeRangeCache,
        optionManager.gridDisplay,
        optionManager.priceScale,
        optionManager.toolSynchronization,
        optionManager.synchronizeInstrument,
        optionManager.synchronizeMousePosition,
        optionManager.isEmailNotificationEnabled,
        optionManager.isDesktopNotificationEnabled,
        optionManager.notificationSound
      );
      return options as ChartOption<any>[];
    }
  }
});
</script>

<style scoped>

</style>
<template>
  <CustomDropdown ref="dropdown" :disabled="disabled">
    <template #header>
      {{ selectedItem == null ? "" : getItemText(selectedItem) }}
    </template>
    <template #items>
      <div
        v-for="item in items" :key="item.key" class="custom-dropdown-item" :class="{ disabled: item.isDisabled }"
        @click.stop="onItemClick(item)"
      >
        {{ getItemText(item) }}
      </div>
    </template>
  </CustomDropdown>
</template>

<script lang="ts">
import CustomDropdown from "@/views/components/CustomDropdown.vue";
import { defineComponent, type PropType } from "vue";
import { translationStore } from "@/stores/translation-store";

export class SingleSelectItem {

  public isDisabled = false;

  constructor(public readonly key: string,
              public readonly name: string | null,
              public readonly value: any) {
  }
}

export default defineComponent({
  name: "SingleSelect",

  components: { CustomDropdown },

  props: {
    items: {
      type: Array as PropType<SingleSelectItem[]>,
      required: true
    },
    modelValue: {
      type: [Object, Number, String, Boolean, null] as PropType<any>,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },

  emits: {
    "update:modelValue": (value: any) => value || true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    selectedItem() {
      return this.items.find(i => i.value === this.modelValue) ?? null;
    }
  },

  methods: {
    onItemClick(item: SingleSelectItem) {
      if (!item.isDisabled) {
        const dropdown = this.$refs.dropdown as typeof CustomDropdown;
        dropdown.hide();
        this.$emit("update:modelValue", item.value);
      }
    },

    getItemText(item: SingleSelectItem) {
      return item.name == null ? this.translationStore.getTranslation(item.key) : item.name;
    }
  }
});
</script>

<style scoped>
</style>

<template>
  <div class="message px-2 py-1 mb-3">
    <div class="message-header my-1">
      <CircleElement :color="stateColor" :size="10" />
      <div class="message-header-title font-bold px-1">
        {{ message.chat.title }} - {{ message.user }}
      </div>
      <span class="ms-auto pe-2 message-header-title">{{ formatTime(message.time) }}</span>
      <IconElement
        v-if="message.chat.isPublic && isAdmin" iconName="Pencil" :size="12" class="pe-2 close-button"
        @click.stop="editMessage"
      />
      <IconElement
        v-if="isAdmin || !message.chat.isPublic" iconName="Cross" :size="12" class="close-button"
        @click.stop="deleteMessage"
      />
    </div>
    <div class="px-1 pb-1 mb-1">
      <div v-html="message.title" class="font-bold"></div>
      <div v-html="message.text" class="message-text mt-2"></div>
    </div>
    <ChatMessageAttachment
      v-for="attachment in message.attachments" :key="attachment.id ?? 0" :message="message" :attachment="attachment"
    />
    <ChatVideo v-if="message.video != null" :video="message.video" />
    <ConfirmationModalDialog
      ref="deleteMessageDialog" question="chat#delete_message_dialog#question" labelOk="button_delete"
      titleDialog="chat#delete_message_dialog#title" @pressedOk="confirmDeleteMessage"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ChatMessage } from "@/api/models/chat";
import IconElement from "@/views/icons/IconElement.vue";
import { chatStore } from "@/stores/chat-store";
import { storeToRefs } from "pinia";
import { userRightStore } from "@/stores/user-right-store";
import { DateMixin } from "@/mixins/date-mixin";
import ChatMessageAttachment from "@/views/chat/ChatMessageAttachment.vue";
import ChatVideo from "@/views/chat/ChatMessageVideo.vue";
import { uiStateStore } from "@/stores/ui-state-store";
import CircleElement from "@/views/components/CircleElement.vue";
import { getColorByCSSDefine } from "@/anfin-chart/utils";
import ConfirmationModalDialog from "@/views/dialogs/ConfirmationModalDialog.vue";

export default defineComponent({
  name: "ChatMessageItem",

  components: { ConfirmationModalDialog, CircleElement, ChatVideo, ChatMessageAttachment, IconElement },

  mixins: [DateMixin],

  props: {
    message: {
      type: ChatMessage,
      required: true
    }
  },

  expose: [],

  data() {
    const { isAdmin } = storeToRefs(userRightStore());
    return {
      isAdmin: isAdmin as unknown as boolean,
      isNew: false
    };
  },

  computed: {
    stateColor() {
      const colorName = this.isNew ? "--system-red" : "--disabled-primary";
      return getColorByCSSDefine(colorName);
    }
  },

  mounted() {
    this.checkIsNew();
  },

  methods: {
    getType(attachment: string) {
      const pos = attachment.indexOf(";") >= 0 ? attachment.indexOf(";") : attachment.length;
      const type = attachment.substring(0, pos);
      return type.replace("data:application/", "").replace("application/", "");
    },

    editMessage() {
      uiStateStore().editedMessage = this.message;
    },

    deleteMessage() {
      const dialog = this.$refs.deleteMessageDialog as typeof ConfirmationModalDialog;
      dialog.show();
    },

    confirmDeleteMessage() {
      chatStore().deleteMessage(this.message);
    },

    checkIsNew() {
      const newUntil = this.message.chat.getSeenAt(this.message) + 120000;
      this.isNew = this.message.id != null && this.message.id > this.message.chat.getLastSeenMessageId() ||
        newUntil > Date.now();
      if (this.isNew) {
        window.setTimeout(() => this.checkIsNew(), 30000);
      }
    }
  }
});
</script>

<style scoped>
.message {
  background-color: var(--background-overlay);
  border-radius: 5px;
  border: var(--border-overlay) solid 1px;
}

.message-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 4px;
  padding-bottom: 4px;
  border-bottom: var(--border-overlay) solid 1px;
}

.message-header-title {
  margin-top: 0.05rem;
}

.message-text {
  white-space: pre-wrap;
}

li {
  margin: 1rem;
  list-style: square;
}
</style>
import { defineComponent } from "vue";
import type { Consumer } from "@/anfin-chart/utils";

export const DynamicScriptMixin = defineComponent({
  methods: {
    addDynamicScript(id: string, url: string, onLoaded: Consumer<void> | null = null) {
      let scriptElement = document.getElementById(id);
      if (scriptElement == null) {
        scriptElement = document.createElement("script");
        scriptElement.id = id;
        document.head.appendChild(scriptElement);
      }
      scriptElement.setAttribute("src", url);
      scriptElement.addEventListener("load", () => onLoaded?.());
    }
  }
});

import { ChartArea } from "@/anfin-chart/area/chart-area";
import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { Point, Rectangle, Vector } from "@/anfin-chart/geometry";
import type { ChartLayer } from "@/anfin-chart/chart-layer";

export class MousePriceMarker extends ChartArea {

  private readonly textAlignment = new TextAlignment(new Vector(1, 0));
  private text: string | null = null;

  public constructor(layer: ChartLayer) {
    super(layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.chart.priceAxisWrapper.getPositionObservable(), () => this.resize());
    this.subscribeOn(this.chart.mouseData.getPriceObservable(), price => this.update(price));
    this.subscribeOn(this.chart.crosshair.getIsVisibleObservable(), isVisible => this.setIsVisible(isVisible));
  }

  protected override drawInternal() {
    if (this.text == null) {
      return;
    }
    const position = this.getPosition();
    const fontInfo = this.chart.getFontInfo();
    const textSize = this.drawer.measureText(this.text, fontInfo);
    const padding = this.chart.styleOptions.priceMarkerPadding.getValue();
    const height = textSize.height + padding;
    const middleY = Math.round((position.yStart + position.yEnd) / 2);
    const rect = new Rectangle(
      position.xStart,
      middleY - height / 2,
      position.xEnd,
      middleY + height / 2
    );
    const chartColors = this.chart.optionManager.chartColor;
    const backgroundColor = chartColors.priceMarkerMouse.getValue();
    this.drawer.drawRect(rect, null, backgroundColor);
    const paddingLeft = this.chart.styleOptions.priceAxisPaddingLeft.getValue();
    const textPosition = new Point(
      Math.round(position.xStart + paddingLeft),
      middleY + padding
    );
    const textColor = chartColors.priceMarkerText.getValue();
    this.drawer.printText(textPosition, this.text, textColor, this.textAlignment, fontInfo);
  }

  protected override resizeInternal() {
    if (this.text == null) {
      return ChartArea.defaultPosition;
    }
    const y = this.chart.mouseData.getPosition().y;
    const priceAxisWrapperPosition = this.chart.priceAxisWrapper.getPosition();
    const fontInfo = this.chart.getFontInfo();
    const height = this.drawer.measureText(this.text, fontInfo).height;
    const xStart = priceAxisWrapperPosition.xStart;
    const xEnd = priceAxisWrapperPosition.xEnd;
    const offset = height / 2 + this.chart.styleOptions.priceMarkerPadding.getValue();
    const yStart = y - offset;
    const yEnd = y + offset;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private update(price: number | null) {
    this.text = price == null || isNaN(price) ? null : this.chart.formatPrice(price);
    this.resize();
  }
}

import { RestApiController } from "@/api/rest-api-controller";
import type { ShortcutListResponse } from "@/api/messages/shortcut";

export class ShortcutsController extends RestApiController {

  private static instance: ShortcutsController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new ShortcutsController();
    }
    return this.instance;
  }

  public async requestShortcuts() {
    const response = await this.sendGetRequest<ShortcutListResponse>("shortcuts");
    return response.shortcuts;
  }

  public async saveShortcut(name: string, key: string) {
    const request = {
      name: name,
      keys: key
    };
    return this.sendPostRequest<number>("shortcut", request);
  }

  protected override getBaseUrl() {
    return this.environment.configurationUrl;
  }
}

import type { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { LineStrip, TextBox } from "@/anfin-chart/drawable";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { alignAvoid } from "@/anfin-chart/tools/user-tools/alignment";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class ElliotWave extends UserToolDefinition {

  private readonly color: ColorOption;

  constructor(type: string,
              instrument: Instrument,
              timeframe: Timeframe,
              count: number,
              private readonly labels: string[],
              color: RGBAColor) {
    super(type, instrument, timeframe, count, count);
    this.color = new ColorOption(this, OptionName.Color + "_0", color);
  }

  public override createDrawables(tool: UserTool) {
    const lineStrip = new LineStrip(tool, this.fixedPoints);
    tool.onUpdate(() => {
      lineStrip.color = this.color.getValue();
      lineStrip.options.width = tool.getLineWidth(false);
    });
    for (let i = 0; i < this.fixedPoints.length; i++) {
      tool.withAtLeastPoints(i + 1, () => this.createForIndex(tool, i));
    }
  }

  public override updatePosition(tool: UserTool) {
    // do nothing
  }

  private createForIndex(tool: UserTool, index: number) {
    const point = this.fixedPoints[index];
    const lastPoint = this.fixedPoints[index - 1];
    const label = this.labels[index] ?? "";
    const text = new TextBox(tool, point);
    text.setText("(" + label + ")");
    tool.onUpdate(() => {
      text.color = this.color.getValue();
      text.alignment = alignAvoid(tool, point, lastPoint, this.fixedPoints[index + 1]);
    });
    new FixedSelectablePoint(tool, point);
  }
}

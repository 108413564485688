<template>
  <div v-if="toasts.length > 0" class="toast-container">
    <TransitionGroup name="list-toasts">
      <ToastItem v-for="toast in toasts" :key="toast.id" :toast="toast" />
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { Toast, toastStore } from "@/stores/toast-store";
import ToastItem from "@/views/components/ToastItem.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToastContainer",

  components: { ToastItem },

  expose: [],

  data() {
    const { toasts } = storeToRefs(toastStore());
    return {
      toasts: toasts as unknown as Toast[]
    };
  }
});
</script>

<style scoped>
.toast-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;
  width: 290px;
  min-height: 20px;
  opacity: 0.9;
  z-index: 999999;
  pointer-events: auto;
}

.list-toasts-leave-active {
  transition: all 0.5s ease;
}

.list-toasts-leave-to {
  opacity: 0;
}
</style>

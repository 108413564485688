import { IndicatorData } from "@/anfin-chart/indicator-data";
import { applyOptions, getOptionDefinitions } from "@/anfin-chart/options/option";
import type { Chart } from "@/anfin-chart/chart";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { IndicatorRegistry } from "@/anfin-chart/registry";
import type { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";

export class IndicatorTemplate {

  constructor(public readonly type: string,
              public readonly subChartIndex: number | null,
              public readonly options: ChartOptionDefinition[]) {
  }
}

export class ChartIndicatorConverter {

  public static export(indicatorData: IndicatorData) {
    const indicator = indicatorData.indicator;
    const subChartIndex = indicatorData.subChart.getIndex();
    const options = getOptionDefinitions(indicator);
    return new IndicatorTemplate(indicator.type, subChartIndex, options);
  }

  public static import(indicatorTemplate: IndicatorTemplate, chart: Chart, subChart: SubChart | null) {
    const indicator = IndicatorRegistry.create(indicatorTemplate.type);
    if (indicator == null) {
      return null;
    }
    let subChartResolved: SubChart;
    if (subChart != null) {
      subChartResolved = subChart;
    } else if (indicator.isNewSubChart) {
      subChartResolved = chart.createSubChart();
    } else {
      subChartResolved = chart.primarySubChart;
    }
    const indicatorData = new IndicatorData(subChartResolved, indicator);
    indicatorData.setBasePlot(chart.getMainInstrumentData().mainPlot);
    subChartResolved.addIndicator(indicatorData);
    applyOptions(indicator, indicatorTemplate.options);
    indicator.enableSaving();
    return indicator;
  }
}

import type { StyleValue } from "vue";

export class ScientificNotation {
  constructor(public exponent: number,
              public value: number) {
  }

  public static from(x: number) {
    if (x === 0 || !isFinite(x)) {
      return new ScientificNotation(0, 0);
    }
    let exponent = 0;
    let value = Math.abs(x);
    while (value >= 10) {
      value /= 10;
      exponent++;
    }
    while (value < 1) {
      value *= 10;
      exponent--;
    }
    return new ScientificNotation(exponent, value);
  }

  public toNumber() {
    return this.value * 10 ** this.exponent;
  }
}

export class StopWatch {

  public static run(callback: () => void) {
    const startTime = performance.now();
    callback();
    const endTime = performance.now();
    return endTime - startTime;
  }
}

export function padLeft(text: string, padding: string, length: number) {
  let paddedText = text;
  while (paddedText.length < length) {
    paddedText = padding + paddedText;
  }
  return paddedText;
}

export function truncTo(value: number, to: number) {
  if (to === 1) {
    return value;
  }
  return value - value % to;
}

export function getColorByCSSDefine(category: string) {
  if (category) {
    let r = document.querySelector("#multi-chart");
    if (r) {
      const rs = window.getComputedStyle(r);
      return rs.getPropertyValue(category);
    }
    r = document.querySelector(".main-content");
    if (r) {
      const rs = window.getComputedStyle(r);
      return rs.getPropertyValue(category);
    }
  }
  return "";
}

export function getSizeByCSSDefine(cssName: string, defaultValue: number): number {
  if (cssName) {
    const r = document.querySelector("#multi-chart");
    if (r) {
      const rs = window.getComputedStyle(r);
      return parseInt(rs.getPropertyValue(cssName));
    }
  }
  return defaultValue;
}

export class DelayedAction {

  private readonly executor: DelayedExecutor;

  constructor(private readonly callback: () => void, delay = 0) {
    this.executor = new DelayedExecutor(delay);
  }

  public run() {
    this.executor.post(this.callback);
  }
}

export class DelayedExecutor {

  private timeout: number | null = null;

  constructor(private readonly delay = 0) {
  }

  public post(action: () => void) {
    if (this.timeout != null) {
      return;
    }
    this.timeout = window.setTimeout(() => {
      this.timeout = null;
      action();
    }, this.delay);
  }

  public cancel() {
    if (this.timeout != null) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}

export class LimitSizeArray<T> extends Array<T> {

  constructor(private readonly size: number) {
    super();
  }

  public get startIndex() {
    return Math.max(0, this.length - this.size);
  }

  public override push(...items: T[]): number {
    const returnValue = super.push(...items);
    const count = this.length;
    if (count >= 5 * this.size) {
      this.splice(0, count - this.size);
    }
    return returnValue;
  }
}

export function formatNumber(value: number, decimalPlaces: number) {
  return value.toFixed(decimalPlaces).replace(/\.?0+$/, "");
}

export function formatPercentage(percentage: number, decimalPlaces = 2) {
  return formatNumber(percentage * 100, decimalPlaces) + "%";
}

export class LinkedListNode<T> {

  public previous: LinkedListNode<T> | null = null;
  public next: LinkedListNode<T> | null = null;

  constructor(public readonly data: T) {
  }

  public setNext(node: LinkedListNode<T> | null) {
    this.next = node;
    if (node != null) {
      node.previous = this;
    }
  }

  public setPrevious(node: LinkedListNode<T> | null) {
    this.previous = node;
    if (node != null) {
      node.next = this;
    }
  }
}

export class LinkedList<T> {

  private head: LinkedListNode<T> | null = null;
  private tail: LinkedListNode<T> | null = null;
  private itemCount = 0;

  constructor(...items: T[]) {
    for (const item of items) {
      this.add(item);
    }
  }

  public get size() {
    return this.itemCount;
  }

  public add(item: T) {
    const node = new LinkedListNode(item);
    this.tail?.setNext(node);
    if (this.head == null) {
      this.head = node;
    }
    this.tail = node;
    this.itemCount++;
  }

  public popHead() {
    const head = this.head;
    if (head == null) {
      return null;
    }
    this.head = head.next;
    this.itemCount--;
    return head;
  }

  public popTail() {
    const tail = this.tail;
    if (tail == null) {
      return null;
    }
    this.tail = tail.previous;
    if (this.tail == null) {
      this.head = null;
    }
    this.itemCount--;
    return tail;
  }
}

export class TwoWayMap<T, U> {

  private readonly map = new Map<T, U>();
  private readonly reverseMap = new Map<U, T>();

  public set(key: T, value: U) {
    this.map.set(key, value);
    this.reverseMap.set(value, key);
  }

  public getValue(key: T) {
    return this.map.get(key);
  }

  public getKey(value: U) {
    return this.reverseMap.get(value);
  }

  public clear() {
    this.map.clear();
    this.reverseMap.clear();
  }
}

type SimpleComparable = number | string | null;

export function simpleCompare(first: SimpleComparable, second: SimpleComparable) {
  if (first === second) {
    return 0;
  }
  if (first == null) {
    return -1;
  }
  if (second == null) {
    return 1;
  }
  return first > second ? 1 : -1;
}

export function simpleMapCompare<T>(...mappers: ((item: T) => SimpleComparable)[]) {
  return (first: T, second: T) => {
    for (const mapper of mappers) {
      const value = simpleCompare(mapper(first), mapper(second));
      if (value !== 0) {
        return value;
      }
    }
    return 0;
  };
}

export type Consumer<T> = (value: T) => void;

export type Producer<T, U> = (value: T) => U;

export type Predicate<T> = Producer<T, boolean>;

export class Debouncer {

  private timer: number | null = null;
  private action: Consumer<void> | null = null;

  constructor(private readonly delay: number) {
  }

  public execute(action: Consumer<void>) {
    this.action = action;
    if (this.timer == null) {
      this.timer = window.setTimeout(() => this.runAction(), this.delay);
    }
  }

  private runAction() {
    if (this.action != null) {
      this.action();
      this.action = null;
    }
    this.timer = null;
  }
}

const enumKeyFilter = /^[0-9]+$/;

export function getEnumValues<T extends object>(enumObj: T) {
  const keys = Object.keys(enumObj) as (keyof T)[];
  const values = [];
  for (const key of keys) {
    if (typeof key !== "string" || enumKeyFilter.test(key)) {
      continue;
    }
    const value = enumObj[key];
    values.push(value);
  }
  return values;
}

export function adjustArraySize<T>(arr: T[], count: number, generator: () => T) {
  if (arr.length > count) {
    arr.splice(count, arr.length - count);
  } else {
    while (arr.length < count) {
      arr.push(generator());
    }
  }
}

export function exportDataToFile(filename: string, csvData: string) {
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export class CssPosition {

  constructor(public top: number | null,
              public left: number | null,
              public bottom: number | null,
              public right: number | null) {
  }
  
  public toCssObject() {
    return {
      top: this.top == null ? null : this.top + "px",
      left: this.left == null ? null : this.left + "px",
      bottom: this.bottom == null ? null : this.bottom + "px",
      right: this.right == null ? null : this.right + "px"
    } as StyleValue;
  }
}

export function roundDecimal(value: number, decimalPlaces: number) {
  const multiplier = 10 ** decimalPlaces;
  return Math.round(value * multiplier) / multiplier;
}

<template>
  <div id="searchbar" class="searchbar">
    <div class="searchbar-main-content">
      <InstrumentFilterList
        id="searchbar-filter-list"
        ref="suggestion"
        v-click-outside="hideSearchResult"
        :useHistory="true"
        :showAsset="true"
        :showCurrency="true"
        :itemProjection="itemProjectionFunction"
        :placeholder="placeholder"
        @selectItem="itemSelected"
      />
      <TimeframeSelector />
      <IconButton
        :size="18" iconName="Undo" :disabled="!canUndo" class="ms-3 hint--bottom redo-undo-button"
        :aria-label="translationStore.getTranslation('toolbar#undo')"
        @click="undoAction"
      />
      <IconButton
        :size="18" iconName="Redo" :disabled="!canRedo" class="ms-1 hint--bottom redo-undo-button"
        :aria-label="translationStore.getTranslation('toolbar#redo')"
        @click="redoAction"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Instrument } from "@/anfin-chart/instrument";
import { HistoryDataController } from "@/api/history-data-controller";
import { ClickOutside } from "@/directives/click-outside";
import { actionHistoryStore } from "@/stores/action-history-store";
import { instrumentStore } from "@/stores/instrument-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { ChartHandler } from "@/utils/ChartHandler";
import TimeframeSelector from "@/views/components/TimeframeSelector.vue";
import IconButton from "@/views/icons/IconButton.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import type AutoFilterList from "../components/AutoFilterList.vue";
import { translationStore } from "@/stores/translation-store";
import InstrumentFilterList from "@/views/components/InstrumentFilterList.vue";

export default defineComponent({
  name: "TopSearchbar",

  components: { InstrumentFilterList, IconButton, TimeframeSelector },

  directives: { ClickOutside },

  expose: [],

  data() {
    const chartHandler = ChartHandler.getInstance();
    const { instrument } = storeToRefs(multiChartStore());
    const { canUndo, canRedo } = storeToRefs(actionHistoryStore());
    return {
      chartHandler,
      translationStore: translationStore(),
      options: {},
      instrument: instrument as unknown as Instrument,
      searchTerm: "",
      query: "Search",
      controller: HistoryDataController.getInstance(),
      canUndo: canUndo as unknown as boolean,
      canRedo: canRedo as unknown as boolean
    };
  },

  computed: {
    placeholder() {
      const symbol = this.instrument.getSymbol();
      const instrument = instrumentStore().getDetail(symbol);
      return instrument?.printName ?? symbol;
    }
  },

  methods: {
    escapeRegExp(text: string) {
      return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },

    boldMatchText(text: string, input: string) {
      const regexp = new RegExp(`(${this.escapeRegExp(input)})`, "ig");
      return text.replace(regexp, "<strong>$1</strong>");
    },

    itemSelected(item: any) {
      if (item != null) {
        const instrument = Instrument.fromSymbol(item.symbol);
        multiChartStore().changeInstrument(instrument);
      }
      this.searchTerm = "";
      (this.$refs.suggestion as typeof AutoFilterList).clearInput();
    },

    itemProjectionFunction(item: any) {
      return item.printName ?? item.name ?? "";
    },

    hideSearchResult() {
      this.searchTerm = "";
    },

    undoAction() {
      actionHistoryStore().undo();
    },

    redoAction() {
      actionHistoryStore().redo();
    }
  }
});
</script>

<style scoped>
.searchbar {
  display: flex;
  width: 100%;
  background-color: var(--background-screen);
  border-bottom: 1px solid var(--border-neutral);
}

#searchbar-filter-list {
  width: 200px;
  margin: 8px 16px;
}

@media (max-width: 767px) {
  #searchbar-filter-list {
    margin: 8px 5px;
  }
}

.searchbar-main-content {
  display: flex;
  width: 100%;
  align-items: center;
}

@media (max-width: 940px) {
  .searchbar-main-content {
    margin-right: 7px;
  }
}

input[type="search"] {
  color: #1b1f2c;
  border: none;
  background: #eaeaea;
  padding-left: 1px;
}

.menu-item:hover {
  background-color: #114f29;
}
</style>

<template>
  <RouterLink to="/privacy-policy" class="link">
    {{ translationStore.getTranslation("privacy_policy") }}
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "PrivacyPolicyLink",

  components: { RouterLink },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  }
});
</script>

<style scoped>

</style>
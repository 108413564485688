<template>
  <div class="chat-message-editor">
    <div>
      <div class="font-bold">
        {{ header }}
      </div>
      <label class="font-bold mt-3">
        {{ translationStore.getTranslation("chat#create_message_dialog#label_title") }}
      </label>
      <textarea
        v-model="message.title" rows="2" cols="50" class="form-control form-control-sm mt-2"
      ></textarea>
      <label class="font-bold mt-2">
        {{ translationStore.getTranslation("chat#create_message_dialog#label_text") }}
      </label>
      <AutoSizeTextarea v-model="message.text" class="chat-message-editor-content form-control mt-2" />
      <label class="font-bold mt-3">
        {{ translationStore.getTranslation("chat#create_message_dialog#label_attachment") }}
      </label>
      <div v-for="(attachment, attachmentIndex) in message.attachments">
        <hr />
        <div class="chat-message-editor-attachment">
          <div>{{ attachment.filename }}</div>
          <IconElement
            iconName="Cross" :size="14" class="close-button ms-auto" @click="deleteAttachment(attachmentIndex)"
          />
        </div>
      </div>
      <div class="d-flex mt-2">
        <AddButton @click="selectAttachmentFile">
          {{ translationStore.getTranslation("chat#create_message_dialog#button_add_attachment") }}
        </AddButton>
        <button type="button" class="btn btn-primary btn-sm ms-auto" @click="clearAttachments">
          {{ translationStore.getTranslation("chat#create_message_dialog#label_attachment_clear") }}
        </button>
      </div>
      <input id="attachment-file-input" type="file" class="d-none" @change="onAttachmentFileSelected" />

      <label class="font-bold mt-3">
        {{ translationStore.getTranslation("chat#create_message_dialog#label_video_information") }}
      </label>
      <div class="video-content mt-2">
        <label class="w-25">
          {{ translationStore.getTranslation("chat#create_message_dialog#label_video_id") }}
        </label>
        <input v-model="videoId" type="text" class="form-control form-control-sm" />
      </div>
      <div class="video-content mt-2">
        <label class="w-25">
          {{ translationStore.getTranslation("chat#create_message_dialog#label_video_ref") }}
        </label>
        <input v-model="videoRef" type="text" class="form-control form-control-sm" />
      </div>
      <div class="video-content mt-2">
        <label class="w-25">
          {{ translationStore.getTranslation("chat#create_message_dialog#label_app_id") }}
        </label>
        <input v-model="appId" type="text" class="form-control form-control-sm" />
      </div>
      <div class="mt-3 font-sm">
        <label>https://vimeo.com/</label>
        <label class="font-bold">977988820</label>
        <label>/</label>
        <label class="font-bold">e65abca9ac</label>
        <label>?share=copy</label>
      </div>
      <div v-if="message.video != null">
        <label class="font-bold mt-2">
          {{ translationStore.getTranslation("chat#create_message_dialog#label_chapters") }}
        </label>
        <textarea
          v-model="chapterText" rows="6" cols="50" class="form-control form-control-sm mt-2" @input="checkChapterInput"
        ></textarea>
      </div>
    </div>
    <div class="message-preview">
      <div class="font-bold">
        {{ translationStore.getTranslation("chat#create_message_dialog#label_preview") }}
      </div>
      <ChatMessageItem :message="message" class="mt-2" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ChatMessage, ChatMessageAttachment, ChatVideo, ChatVideoChapter } from "@/api/models/chat";
import { storeToRefs } from "pinia";
import { userRightStore } from "@/stores/user-right-store";
import { DateMixin } from "@/mixins/date-mixin";
import ChatMessageItem from "@/views/chat/ChatMessageItem.vue";
import { processPlaceholders, translationStore } from "@/stores/translation-store";
import IconElement from "@/views/icons/IconElement.vue";
import AddButton from "@/views/components/AddButton.vue";
import { padLeft } from "@/anfin-chart/utils";
import AutoSizeTextarea from "@/views/components/AutoSizeTextarea.vue";

export default defineComponent({
  name: "ChatMessageEditor",

  components: { AutoSizeTextarea, AddButton, IconElement, ChatMessageItem },

  mixins: [DateMixin],

  props: {
    message: {
      type: ChatMessage,
      required: true
    }
  },

  expose: [],

  data() {
    const { isAdmin } = storeToRefs(userRightStore());
    const chapterLines = [];
    const chapters = this.message.video?.chapters ?? [];
    for (const chapter of chapters) {
      if (chapter.header != null) {
        chapterLines.push(chapter.header);
      }
      const minutes = Math.floor(chapter.time / 60);
      const seconds = chapter.time % 60;
      chapterLines.push(minutes + ":" + padLeft(seconds.toString(), "0", 2) + " " + chapter.title);
    }
    return {
      isAdmin,
      videoId: "",
      videoRef: "",
      appId: "58479",
      chapterText: chapterLines.join("\n"),
      translationStore: translationStore()
    };
  },

  computed: {
    header(): string {
      const translation = this.translationStore.getTranslation("chat#create_message_dialog#header");
      const data = { chatName: this.message.chat.title };
      return processPlaceholders(translation, data);
    },

    video(): ChatVideo | null {
      if (this.videoId.length > 0 && this.videoRef.length > 0 && this.appId.length > 0) {
        return new ChatVideo(this.videoId, this.appId, this.videoRef, []);
      }
      return null;
    }
  },

  watch: {
    video(video: ChatVideo | null) {
      this.message.video = video;
    },

    videoId(newVideoId: string) {
      const parse = new RegExp("^((https?|ftp):\\/)?\\/?([^:\\/\\s]+)((\\/\\w+)*\\/)([\\w\\-.]+[^#?\\s]+)(.*)?(#[\\w\\-]+)?$", "ig");
      const match = parse.exec(newVideoId);
      const isVimeoVideo = match != null && match.filter(part => part === "vimeo.com").length === 1;
      if (isVimeoVideo) {
        const vimeoIds = match.filter(part => part != null && part !== "vimeo.com" && part.indexOf("https") < 0 && part.indexOf("share=") < 0 && part[part.length - 1] !== "/");
        if (vimeoIds.length === 2) {
          this.videoId = vimeoIds[0].replace("/", "");
          this.videoRef = vimeoIds[1].replace("/", "");
        }
      }
    }
  },

  methods: {
    selectAttachmentFile() {
      const inputElement = document.getElementById("attachment-file-input");
      if (inputElement instanceof HTMLInputElement) {
        inputElement.click();
      }
    },

    onAttachmentFileSelected(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      const file = inputElement.files?.[0];
      if (file != null) {
        const reader = new FileReader();
        reader.onload = () => {
          if (typeof reader.result === "string") {
            const imageData = reader.result as string;
            this.addAttachment(imageData, file.name);
          }
        };
        reader.readAsDataURL(file);
      }
      inputElement.value = "";
    },

    addAttachment(data: string, filename: string) {
      const attachmentParts = data.split(";base64,");
      if (attachmentParts.length === 2) {
        const type = attachmentParts[0].substring(5);
        const attachment = new ChatMessageAttachment(null, type, filename, attachmentParts[1]);
        this.message.attachments.push(attachment);
      } else {
        console.error("Could not determine image attachment parts: " + data);
      }
    },

    deleteAttachment(index: number) {
      this.message.attachments.splice(index, 1);
    },

    clearAttachments() {
      this.message.attachments = [];
    },

    checkChapterInput(event: Event) {
      if (this.message.video == null) {
        return;
      }
      const target = event.target as HTMLTextAreaElement;
      const value = target.value;
      const chapterRegex = /^(?<minute>[0-9]{1,2}):(?<second>[0-9]{2})(?:\s(?<title>.*))?$/;
      let currentHeader = "";
      const lines = value.split("\n");
      const chapters: ChatVideoChapter[] = [];
      for (const line of lines) {
        const result = chapterRegex.exec(line);
        if (result?.groups == null) {
          currentHeader += line;
        } else {
          const header = currentHeader === "" ? null : currentHeader;
          const minute = Number(result.groups["minute"] ?? 0);
          const second = Number(result.groups["second"] ?? 0);
          const time = minute * 60 + second;
          const title = result.groups["title"] ?? "";
          const chapter = new ChatVideoChapter(time, title.trim(), header);
          chapters.push(chapter);
          currentHeader = "";
        }
      }
      this.message.video.chapters = chapters;
    },

    addVideoChapter() {
      if (this.message.video != null) {
        const chapter = new ChatVideoChapter(0, "", null);
        this.message.video.chapters.push(chapter);
      }
    },

    removeChapter(index: number) {
      if (this.message.video != null) {
        this.message.video.chapters.splice(index, 1);
      }
    }
  }
});
</script>

<style scoped>
.chat-message-editor {
  min-height: 400px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.chat-message-editor > * {
  flex-basis: 0;
  flex-grow: 1;
}

.chat-message-editor-content {
  min-height: 6rem;
}

.chat-message-editor-attachment {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message-preview {
  min-width: 450px;
}

.video-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
<template>
  <TopToolbar v-if="!isMobile" id="multi-chart-top" class="topbar">
    <template #content-main>
      <IndicatorSelector />
      <ChartLayoutSelector v-if="!isMobile" />
      <ChartTemplateSelector v-if="!isMobile" />
      <!-- <ChartViewSelector /> -->
    </template>
    <template #content-right>
      <IconButton
        v-if="!isMobile" id="topbar-icon-fullscreen" iconName="Fullscreen" :size="24" class="hint--bottom"
        :aria-label="translationStore.getTranslation('toolbar#button_fullscreen')"
        @click="fullScreen"
      />
      <!--
      <IconButton iconName="ScreenerList" :size="24" @click="openScreeners" />
      <IconButton v-if="allowMultiScreen" iconName="MarketOverview" :size="24" @click="detachMarketListView" />
      -->
      <IconButton v-if="!isMobile" class="multi-screen-button" iconName="Multiscreen" :size="24" @click="startMultiScreen" />
      <IconButton
        iconName="Settings" :size="24" class="hint--bottom"
        :aria-label="translationStore.getTranslation('toolbar#button_settings')"
        @click="toggleChartSettings"
      />
      <IconButton
        v-if="!isMobile" id="topbar-icon-account" iconName="Account" :size="24" class="hint--bottom"
        :aria-label="translationStore.getTranslation('settings_mode#' + SettingsWindowMode.Account)"
        @click="toggleAccountSettings"
      />
      <IconButton
        v-if="!isMobile" iconName="Logout" :size="24" class="hint--bottom"
        :aria-label="translationStore.getTranslation('toolbar#button_logout')"
        @click="logout"
      />
    </template>
  </TopToolbar>
</template>

<script lang="ts">
import { UserRightKey } from "@/api/models/user-right";
import { userRightStore } from "@/stores/user-right-store";
import { ChartHandler } from "@/utils/ChartHandler";
import IconButton from "@/views/icons/IconButton.vue";
import ChartLayoutSelector from "@/views/toolbar/ChartLayoutSelector.vue";
import ChartTemplateSelector from "@/views/toolbar/ChartTemplateSelector.vue";
import IndicatorSelector from "@/views/toolbar/IndicatorSelector.vue";
import TopToolbar from "@/views/toolbar/TopToolbar.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import { SettingsWindowMode, uiStateStore } from "@/stores/ui-state-store";

export default defineComponent({
  name: "ChartTopToolbar",

  components: { IconButton, ChartTemplateSelector, TopToolbar, ChartLayoutSelector, IndicatorSelector },

  expose: [],

  data() {
    const { isMobile } = storeToRefs(userRightStore());
    return {
      chartHandler: ChartHandler.getInstance(),
      translationStore: translationStore(),
      isMobile: isMobile as unknown as boolean,
      SettingsWindowMode
    };
  },

  methods: {
    toggleChartSettings() {
      uiStateStore().toggleSettingsWindowMode(SettingsWindowMode.Chart);
    },

    toggleAccountSettings() {
      uiStateStore().toggleSettingsWindowMode(SettingsWindowMode.Account);
    },

    logout() {
      userRightStore().logout("/home");
    },

    fullScreen() {
      this.chartHandler.fullScreen();
    },

    startMultiScreen() {
      const route = this.$router.resolve({ name: "Multichart" });
      const newHeight = window.outerHeight * 0.8;
      const newWidth = window.outerWidth * 0.8;
      const properties = `location=yes,height=${newHeight},width=${newWidth},scrollbars=yes,status=yes`
      window.open(route.href, "_blank", properties);
    },

    openScreeners() {
      const route = this.$router.resolve({ name: "Screener" });
      window.open(route.href);
    }
  }
});
</script>

<style scoped>
.topbar {
  z-index: 1001;
}

@media screen and (max-width: 650px) {
  #topbar-icon-account {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  #topbar-icon-fullscreen {
    display: none;
  }
}

#topbar-icon-account {
  margin-right: 10px;
}
</style>

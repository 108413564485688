import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { LineOptions, LineStyle, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption, NumericArrayOption } from "@/anfin-chart/options/option";
import { LineElement, TextBox } from "@/anfin-chart/drawable";
import { Point, Vector } from "@/anfin-chart/geometry";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { formatPercentage } from "@/anfin-chart/utils";
import { UserToolAlertHook } from "@/anfin-chart/tools/alert-hook";
import type { AlertToolData } from "@/anfin-chart/tools/alert-tool";
import type { AlertUserToolDefinition } from "@/api/models/alert";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";

export class FibonacciLevelData {

  public readonly start = new ChartToolPoint();
  public readonly end = new ChartToolPoint();
  public readonly hook: UserToolAlertHook;

  constructor(tool: FibonacciRetracement,
              public readonly percentage: number) {
    this.hook = new UserToolAlertHook(tool, "level_" + percentage, this.end, 0, 1);
    this.hook.percentageOffset = percentage;
  }
}

export class FibonacciRetracement extends UserToolDefinition {

  public static readonly type = "fibo";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(200, 200, 200));
  private readonly levels = new NumericArrayOption(this, OptionName.FibonacciLevels, [-1.618, -0.618, 0, 0.236, 0.382, 0.5, 0.618, 0.786, 1, 1.618, 2]);

  private readonly levelDatas: FibonacciLevelData[] = [];

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(FibonacciRetracement.type, instrument, timeframe, 2, 2);
    for (const level of this.levels.getValue()) {
      const levelData = new FibonacciLevelData(this, level);
      this.levelDatas.push(levelData);
    }
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
  }

  public override updatePosition(tool: UserTool) {
    if (this.fixedPoints.length >= 2) {
      const [xStart, xEnd] = this.getLevelXRange(tool);
      for (const levelData of this.levelDatas) {
        const price = this.getLevelPrice(levelData.percentage);
        const y = tool.subChart.priceAxis.getY(price);
        const startPosition = new Point(xStart, y);
        tool.updatePoint(levelData.start, startPosition);
        const endPosition = new Point(xEnd, y);
        tool.updatePoint(levelData.end, endPosition);
      }
    }
  }

  public override createAlertDrawables(data: AlertToolData) {
    const definition = data.data.definition as AlertUserToolDefinition;
    data.priceChangeConsumer = (price: number) => {
      const basePoint = this.fixedPoints[0];
      const targetPoint = this.fixedPoints[1];
      definition.percentageOffset = (price - targetPoint.price) / (basePoint.price - targetPoint.price);
    };
  }

  public override updateAlertPosition(data: AlertToolData) {
    const definition = data.data.definition as AlertUserToolDefinition;
    if (definition.percentageOffset == null) {
      return;
    }
    const [xStart, xEnd] = this.getLevelXRange(data.tool);
    const price = this.getLevelPrice(definition.percentageOffset);
    data.updatePosition(xStart, xEnd, price);
    if (data.icon != null) {
      const y = data.tool.getPosition(data.start).y;
      const iconSize = data.icon.size / 2;
      const offset = data.icon.size / 2 + data.tool.chart.styleOptions.alertIconMargin.getValue() * 2;
      const iconCenter = new Point(xStart + iconSize, y + offset);
      data.tool.updatePoint(data.iconCenter, iconCenter);
    }
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const [first, second] = this.fixedPoints;
    const lineOptions = new LineOptions(1, LineStyle.Dashed);
    const line = new LineElement(tool, first, second, lineOptions);
    tool.onUpdate(() => {
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(true);
    });
    for (const level of this.levelDatas) {
      this.createLevel(tool, level);
    }
    new FixedSelectablePoint(tool, second);
  }

  private createLevel(tool: UserTool, levelData: FibonacciLevelData) {
    const line = new LineElement(tool, levelData.start, levelData.end);
    line.hook = levelData.hook;
    const alignment = new TextAlignment(new Vector(1, -1));
    const textBox = new TextBox(tool, levelData.start, alignment);
    textBox.hook = levelData.hook;
    tool.onUpdate(() => {
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(false);
      textBox.color = tool.chart.optionManager.chartColor.toolText.getValue();
      textBox.setText(this.getLevelLabel(tool, levelData.percentage));
    });
  }

  private getLevelXRange(tool: ChartTool) {
    const firstPosition = tool.getPosition(this.fixedPoints[0]);
    const xStart = firstPosition.x;
    const length = tool.chart.styleOptions.fibonacciLineLength.getValue();
    const xEnd = xStart + length;
    return [xStart, xEnd];
  }

  private getLevelLabel(tool: UserTool, level: number) {
    const displayLevel = level < 0 ? (level - 1) * -1 : level;
    const percentageFormatted = formatPercentage(displayLevel);
    const priceFormatted = tool.chart.formatPrice(this.getLevelPrice(level));
    if (level < 0) {
      const extensionLabel = tool.chart.callbacks.getTranslation("user_tool#fibo#label_extension");
      return `${extensionLabel} ${percentageFormatted} (${priceFormatted})`;
    }
    return `${percentageFormatted} (${priceFormatted})`;
  }

  private getLevelPrice(level: number) {
    const [first, second] = this.fixedPoints;
    return second.price + (first.price - second.price) * level;
  }
}

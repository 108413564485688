import type { Instrument } from "@/anfin-chart/instrument";
import type { TickData } from "@/api/messages/tick-update";
import type { SymbolCategory } from "@/api/models/symbol-category";
import { simpleMapCompare } from "@/anfin-chart/utils";

export const countVirtualWatchlist = 6;

export enum WatchlistType {
  User = 0,
  Public = 1,
  Restricted,
  Virtual = 3
}

export class WatchlistImportInfo {

  public readonly symbols: string[] = [];
  public symbolsNotOk = 0;
  public symbolsOkButExists = 0;

  public clear() {
    this.symbolsNotOk = -1;
    this.symbolsOkButExists = 0;
    this.symbols.splice(0);
  }

  public addSymbols(symbols: string[]) {
    symbols.forEach(symbol => {
      this.symbols.push(symbol);
    });
  }
}

export class Watchlist {

  private readonly itemMap = new Map<string, WatchlistItem>();

  constructor(public readonly id: number,
              public name: string,
              public readonly type: WatchlistType) {
  }

  public getItems() {
    return Array.from(this.itemMap.values()).sort(simpleMapCompare(i => i.sortIndex));
  }

  public getItem(symbol: string) {
    return this.itemMap.get(symbol) ?? null;
  }

  public addItem(item: WatchlistItem) {
    const existingItem = this.itemMap.get(item.symbol);
    if (existingItem == null) {
      this.itemMap.set(item.symbol, item);
    } else {
      existingItem.sortIndex = item.sortIndex;
    }
  }

  public removeItem(symbol: string) {
    if (!this.itemMap.has(symbol)) {
      return;
    }
    this.itemMap.delete(symbol);
  }

  public containsInstrument(instrument: Instrument) {
    return this.itemMap.has(instrument.getSymbol());
  }
}

export class WatchlistItem {

  constructor(public readonly symbol: string,
              public readonly data: WatchlistItemData,
              public sortIndex: number) {
  }
}

export class WatchlistItemData {

  public price = 0;
  public time = 0;
  public previousClose = 0;

  constructor(public category: SymbolCategory) {
  }

  public get percent() {
    return this.previousClose > 0 ? this.price / this.previousClose * 100 - 100 : 0;
  }

  public get diff() {
    return this.previousClose > 0 ? this.price - this.previousClose : 0;
  }

  public update(tickData: TickData) {
    this.time = tickData.timestamp;
    this.price = tickData.close;
    this.previousClose = tickData.prev ?? 0;
  }
}

import { ApiModelConverter } from "@/api/messages/converter";
import { CustomerData } from "@/api/models/customer-data";

export interface CustomerDataWrapperResponse {
  address: CustomerDataResponse;
}

export interface CustomerDataResponse {
  firstName: string;
  lastName: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  emailAddress: string;
  emailAddressConfirm: string;
  country: string;
}

export class CustomerDataConverter extends ApiModelConverter<CustomerData, CustomerDataResponse> {

  public override toApiObject(customerData: CustomerData) {
    return {
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      street: customerData.street,
      houseNumber: customerData.houseNumber,
      postalCode: customerData.postalCode,
      city: customerData.city,
      emailAddress: customerData.emailAddress,
      emailAddressConfirm: customerData.emailAddressConfirm,
      country: customerData.country
    };
  }

  public override toModelObject(response: CustomerDataResponse) {
    const customerData = new CustomerData();
    customerData.firstName = response.firstName;
    customerData.lastName = response.lastName;
    customerData.street = response.street;
    customerData.houseNumber = response.houseNumber;
    customerData.postalCode = response.postalCode;
    customerData.city = response.city;
    customerData.emailAddress = response.emailAddress;
    customerData.emailAddressConfirm = response.emailAddress;
    customerData.country = response.country;
    return customerData;
  }
}

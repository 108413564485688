<template>
  <div class="alert-item">
    <div class="alert-item-main px-2 py-2">
      <CircleElement
        :size="18" :color="stateColor" class="hint--bottom-right"
        :aria-label="translationStore.getTranslation('alert_state#' + alert.state)"
      />
      <div v-if="alert.name !== ''" class="font-bold ms-2">
        {{ alert.name }}
      </div>
      <div class="ms-2">
        {{ updateDateFormatted }}
      </div>
      <AlertButtons :alert="alert" class="ms-auto" />
    </div>
    <AlertOverviewItemRule v-for="rule in alert.rules" :rule="rule" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Alert, AlertState } from "@/api/models/alert";
import { getColorByCSSDefine } from "@/anfin-chart/utils";
import { translationStore } from "@/stores/translation-store";
import CircleElement from "@/views/components/CircleElement.vue";
import AlertOverviewItemRule from "@/views/alerts/AlertOverviewItemRule.vue";
import AlertButtons from "@/views/alerts/AlertButtons.vue";
import { DateMixin } from "@/mixins/date-mixin";

export default defineComponent({
  name: "AlertOverviewItem",

  components: { AlertButtons, AlertOverviewItemRule, CircleElement },

  mixins: [DateMixin],

  props: {
    alert: {
      type: Alert,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      AlertState
    };
  },

  computed: {
    stateColor() {
      switch (this.alert.state) {
        case AlertState.Triggered:
          return getColorByCSSDefine("--system-blue");
        case AlertState.Inactive:
          return getColorByCSSDefine("--disabled-primary");
        case AlertState.Active:
          return getColorByCSSDefine("--system-green");
        default:
          return getColorByCSSDefine("--system-yellow");
      }
    },

    updateDateFormatted() {
      const date = this.alert.updatedAt;
      return this.isToday(date) ? this.formatTime(date) : this.formatDateTime(date);
    }
  }
});
</script>

<style scoped>
.alert-item {
  border: 1px solid var(--border-overlay);
  background: var(--background-elevated);
  user-select: none;
}

.alert-item-main {
  display: flex;
  align-items: center;
  color: var(--content-primary);
  background: var(--background-neutral);
}
</style>

import { Timeframe } from "@/anfin-chart/time/timeframe";

export enum ToolSynchronization {
  None = 0,
  Higher = 1,
  Lower = 2,
  All = 3
}

export function matchesToolSynchronization(first: Timeframe, second: Timeframe, synchronization: ToolSynchronization) {
  if (synchronization === ToolSynchronization.All) {
    return true;
  }
  const compareResult = Timeframe.compare(first, second);
  return compareResult === 0 ||
    synchronization === ToolSynchronization.Lower && compareResult > 0 ||
    synchronization === ToolSynchronization.Higher && compareResult < 0;
}

import { type Alert, AlertFixedValueDefinition, type AlertState } from "@/api/models/alert";
import { defineStore } from "pinia";
import { AlertController } from "@/api/alert-controller";
import { multiChartStore } from "@/stores/multi-chart-store";
import { instrumentStore } from "@/stores/instrument-store";
import { roundDecimal } from "@/anfin-chart/utils";

export const alertStore = defineStore({
  id: "alert",

  state() {
    setTimeout(() => initializeStore());
    return {
      alertsMap: new Map<string, Alert>()
    };
  },

  getters: {
    alerts(): Alert[] {
      return Array.from(this.alertsMap.values());
    }
  },

  actions: {
    async requestAlerts() {
      const alerts = await AlertController.getInstance().requestAlerts();
      for (const alert of alerts) {
        this.addAlert(alert);
      }
    },

    addAlert(alert: Alert) {
      if (alert.id != null) {
        this.alertsMap.set(alert.id, alert);
        multiChartStore().asExternalUpdate(() => {
          for (const chart of multiChartStore().charts) {
            chart.addAlert(alert);
          }
        });
      }
    },

    async saveAlert(alert: Alert) {
      for (const rule of alert.rules) {
        const symbol = rule.instrument.getSymbol();
        for (const definition of rule.definitions) {
          if (definition instanceof AlertFixedValueDefinition) {
            const decimalPlaces = instrumentStore().getDecimalPlaces(symbol, definition.fixedValue);
            definition.fixedValue = roundDecimal(definition.fixedValue, decimalPlaces);
          }
        }
      }
      const id = await AlertController.getInstance().saveAlert(alert);
      alert.id = id;
      this.alertsMap.set(id, alert);
    },

    async deleteAlert(alert: Alert) {
      if (alert.id != null) {
        await AlertController.getInstance().deleteAlert(alert);
        this.onAlertDeleted(alert);
      }
    },

    onAlertDeleted(alert: Alert) {
      if (alert.id != null) {
        this.alertsMap.delete(alert.id);
        for (const chart of multiChartStore().charts) {
          chart.removeAlert(alert);
        }
      }
    },

    async setAlertState(alert: Alert, state: AlertState) {
      alert.state = state;
      await AlertController.getInstance().saveAlertState(alert);
    }
  }
});

function initializeStore() {
  const store = alertStore();
  store.requestAlerts();
}

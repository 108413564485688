<template>
  <div>
    <LandingPageSection
      v-if="isShowDetails"
      :class="{ 'landing-text-border-top': isShowDetails, 'landing-text-border-bottom': !isExpandable }"
    >
      <slot></slot>
    </LandingPageSection>
    <div v-if="isExpandable" class="landing-text-expander" @click="toggleExpand">
      <IconElement :iconName="isExpanded ? 'Collapse' : 'Expand'" :size="16" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import IconElement from "@/views/icons/IconElement.vue";

export default defineComponent({
  name: "LandingTextSection",

  components: { IconElement, LandingPageSection },

  props: {
    isExpandable: Boolean
  },

  expose: [],

  data() {
    return {
      isExpanded: false
    };
  },

  computed: {
    isShowDetails() {
      return this.isExpanded || !this.isExpandable;
    }
  },

  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    }
  }
});
</script>

<style scoped>
.landing-text-border-top {
  border-top: 5px solid var(--interactive-accent);
}

.landing-text-border-bottom {
  border-bottom: 5px solid var(--interactive-accent);
}

.landing-text-expander {
  background-color: var(--interactive-accent);
  color: var(--content-primary-top);
  text-align: center;
  cursor: pointer;
  line-height: 0;
}

.landing-text-expander:hover {
  background-color: var(--interactive-accent-hover);
}
</style>
import type { Chart } from "@/anfin-chart/chart";
import { SimpleColorProvider } from "@/anfin-chart/draw/chart-color";
import type { ChartOption } from "@/anfin-chart/options/option";
import { EnumOption, type OptionProvider } from "@/anfin-chart/options/option";
import type { PlotProvider } from "@/anfin-chart/plot";
import { OHLCItem, Plot } from "@/anfin-chart/plot";
import { truncateDate } from "@/anfin-chart/time/time-utils";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import type { TickData } from "@/api/messages/tick-update";
import { BehaviorSubject, distinctUntilChanged, startWith } from "rxjs";
import { OptionName } from "@/anfin-chart/options/option-manager";
import { PriceAxisMarker } from "@/anfin-chart/area/price-axis-marker";
import type { Deletable } from "@/anfin-chart/interactions";
import { SubscriptionManager } from "@/anfin-chart/subscription";

export class Instrument {

  public static readonly default = new Instrument("DAXEUR", "CFD");

  constructor(public readonly isin: string,
              public readonly exchange: string) {
  }

  public static isSame(first: Instrument, second: Instrument) {
    return first.isin === second.isin && first.exchange === second.exchange;
  }

  public static fromSymbol(symbol: string) {
    if (symbol?.includes(":")) {
      const [isin, exchange] = symbol.split(":");
      return new Instrument(isin, exchange);
    }
    return new Instrument(symbol, "");
  }

  public getSymbol() {
    if (this.exchange !== "") {
      return this.isin + ":" + this.exchange;
    }
    return this.isin;
  }

  public isValid() {
    return this.isin !== "" && this.exchange !== "";
  }
}

export interface InstrumentTimeframe {
  instrument: Instrument;
  timeframe: Timeframe;
}

export enum InstrumentLoadingState {
  LoadingInitial = 0,
  NoData = 1,
  Loaded = 2
}

export class InstrumentData implements PlotProvider, OptionProvider, InstrumentTimeframe, Deletable {

  public readonly optionMap = new Map<string, ChartOption<any>>();
  public requestedBefore: number | null = null;

  public readonly plots: Plot[] = [];

  public readonly plotType: EnumOption;

  private readonly subscriptionManager = new SubscriptionManager();
  private readonly previousClose = new BehaviorSubject<number | null>(null);
  private readonly loadingState = new BehaviorSubject<InstrumentLoadingState>(InstrumentLoadingState.LoadingInitial);
  private readonly mainMarker: PriceAxisMarker;

  constructor(private readonly chart: Chart,
              public readonly instrument: Instrument,
              public readonly timeframe: Timeframe) {
    const plotType = chart.styleOptions.initialPlotType.getValue();
    this.plotType = new EnumOption(this, OptionName.PlotType, plotType);
    new Plot(this, "main", plotType, new SimpleColorProvider([]), true);
    this.updatePlotColors();
    const chartColors = chart.optionManager.chartColor;
    this.mainMarker = new PriceAxisMarker(chart.primarySubChart.priceAxis, chartColors.priceMarkerMain);
    // this.preTradingMarker = new PriceAxisMarker(this, chartColors.priceMarkerPreTrading);
    this.subscriptionManager.subscribe(this.chart.timeAxis.getRangeObservable(), () => this.updateMarker());
    if (instrument.isin !== "") {
      this.chart.requestHistory(this);
    }
  }

  public get mainPlot() {
    return this.plots[0];
  }

  public onDelete() {
    this.subscriptionManager.unsubscribeAll();
    this.chart.primarySubChart.priceAxis.removeMarker(this.mainMarker);
  }

  public getSubChart() {
    return this.chart.primarySubChart;
  }

  public getPreviousCloseObservable() {
    return this.previousClose.pipe(distinctUntilChanged(), startWith(this.previousClose.value));
  }

  public getLoadingStateObservable() {
    return this.loadingState.pipe(distinctUntilChanged(), startWith(this.loadingState.value));
  }

  public onOptionChange(option: ChartOption<unknown>) {
    if (option.name === OptionName.PlotType) {
      this.mainPlot.type = this.plotType.getValue();
      this.updatePlotColors();
    }
    this.chart.coreLayer.requireDraw();
  }

  public updatePlotColors() {
    const plotType = this.plotType.getValue();
    const options = this.chart.optionManager.plotColor.getPlotColors(plotType);
    const colors = [];
    this.optionMap.clear();
    this.optionMap.set(this.plotType.name, this.plotType);
    for (const option of options) {
      colors.push(option.getValue());
      this.optionMap.set(option.name, option);
    }
    this.mainPlot.colorProvider = new SimpleColorProvider(options);
  }

  public checkHistoryDataRequired() {
    const firstItem = this.mainPlot.store.get(0);
    if (firstItem == null || this.requestedBefore != null && this.requestedBefore <= firstItem.time) {
      return;
    }
    const timeRange = this.chart.timeAxis.getRange();
    const firstIndex = this.chart.timeAxis.getIndexForTime(firstItem.time);
    if (firstIndex > timeRange.start) {
      this.loadMoreHistory();
    }
  }

  public matches(instrumentTimeframe: InstrumentTimeframe) {
    return Instrument.isSame(this.instrument, instrumentTimeframe.instrument) &&
      Timeframe.isSame(this.timeframe, instrumentTimeframe.timeframe);
  }

  public extend(...items: OHLCItem[]) {
    this.mainPlot.store.insert(...items);
    this.updateLoadingState();
  }

  public handleTickUpdate(tickData: TickData) {
    this.previousClose.next(tickData.prev);
    const currentItem = this.mainPlot.getCurrentItem() as OHLCItem;
    if (currentItem == null) {
      return;
    }
    const exchangeInfo = this.chart.exchangeInfos.get(this.instrument.exchange);
    const offset = exchangeInfo == null ? 0 : (exchangeInfo.offsetTimezone - exchangeInfo.startTradingTime) * 1000;
    const truncatedTime = truncateDate(tickData.timestamp, this.timeframe, offset).getTime();
    const truncatedItem = new OHLCItem(truncatedTime, tickData.open, tickData.high, tickData.low, tickData.close, tickData.volume);
    if (currentItem.time >= truncatedTime) {
      this.mainPlot.store.updateLast(truncatedItem);
    } else {
      this.extend(truncatedItem);
    }
    for (const subChart of this.chart.getSubCharts()) {
      subChart.priceAxis.updateLabels();
      this.updateMarker();
    }
  }

  private loadMoreHistory() {
    const beforeTime = this.mainPlot.store.get(0).time;
    this.requestedBefore = beforeTime;
    this.chart.requestHistory(this, beforeTime);
  }

  private updateLoadingState() {
    if (this.mainPlot.store.length === 0) {
      this.loadingState.next(InstrumentLoadingState.NoData);
    } else {
      this.loadingState.next(InstrumentLoadingState.Loaded);
    }
  }

  private updateMarker() {
    const store = this.mainPlot.store;
    if (store.length === 0) {
      return;
    }
    const timeRange = this.chart.timeAxis.getTimeRange();
    const lastItem = timeRange == null ? null : store.getByTime(timeRange.end);
    let lastPrice = 0;
    if (lastItem != null) {
      lastPrice = lastItem.main;
    }
    this.mainMarker.setPrice(lastPrice);
    this.chart.primarySubChart.priceAxis.layer.requireDraw();
  }
}

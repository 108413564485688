<template>
  <LandingPageSection :paddingTop="0" :paddingBottom="0" class="main-section">
    <div class="landing-section-split main-section-content">
      <div class="main-section-text">
        <div class="main-section-header">
          Revolutionieren Sie Ihre Analysen
        </div>
        <div class="main-section-sub-header mt-lg-4 mt-3">
          Automatisch - Präzise - Innovativ
        </div>
      </div>
    </div>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import LetsGoButton from "@/views/pages-landing/LetsGoButton.vue";

export default defineComponent({
  name: "TestMainSection",

  components: { LandingPageSection },

  expose: []
});
</script>

<style scoped>
.main-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  background-image: url("/src/assets/chart-candlesticks.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
}

.main-section-content {
  align-items: center;
}

.main-section-header {
  font-size: 380%;
  font-weight: bold;
}

.main-section-sub-header {
  font-size: 360%;
}

.main-section-text {
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 767px) {
  .main-section-header {
    font-size: 280%;
  }

  .main-section-sub-header {
    font-size: 200%;
  }
}

@media (max-width: 539px) {
  .main-section {
    padding-top: 20px;
  }

  .main-section-text {
    margin-bottom: 0;
  }

  .main-section-header {
    font-size: 220%;
  }

  .main-section-sub-header {
    font-size: 160%;
  }
}
</style>
import type { Indicator } from "@/anfin-chart/indicator";
import type { Deletable } from "@/anfin-chart/interactions";
import type { DataItem, Plot } from "@/anfin-chart/plot";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { SubscriptionManager } from "@/anfin-chart/subscription";

export class IndicatorData implements Deletable {

  private readonly subscriptionManager = new SubscriptionManager();

  constructor(public readonly subChart: SubChart,
              public readonly indicator: Indicator) {
    indicator.indicatorData = this;
    this.subscriptionManager.subscribe(subChart.chart.getInstrumentDatasObservable(), datas => {
      this.setBasePlot(datas[0].mainPlot);
    });
  }

  public setBasePlot(plot: Plot) {
    const basePlot = this.indicator.basePlot;
    if (plot === basePlot) {
      return;
    }
    if (basePlot != null) {
      this.subscriptionManager.unsubscribe(basePlot);
    }
    this.indicator.basePlot = plot;
    this.subscriptionManager.subscribe(plot.store.getItemsObservable(), () => this.calculate(), plot);
    this.subscriptionManager.subscribe(plot.store.getLastItemObservable(), i => this.updateTick(i), plot);
  }

  public onDelete() {
    this.subscriptionManager.unsubscribeAll();
    this.subChart.chart.callbacks.saveExport();
  }

  public calculate() {
    this.indicator.calculate();
    this.subChart.priceAxis.updateAutoRange();
    this.subChart.chart.coreLayer.requireDraw();
  }

  private updateTick(item: DataItem | null) {
    if (item != null) {
      this.indicator.updateTick(item);
    }
  }
}

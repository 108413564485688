<template>
  <div class="product-table-footer">
    <div></div>
    <button
      v-for="productData in productDatas" class="btn" type="button"
      :class="selectedProduct === productData.product ? 'btn-primary' : 'btn-outline-primary'"
      @click="showProductDetails(productData.product)"
    >
      Produktbeschreibung
    </button>
    <div></div>
    <div :style="columnStyleContent">
      <div v-if="selectedProduct != null" class="mt-4">
        <div class="landing-header">
          {{ selectedProduct.name }}
        </div>
        <ProductDetail :product="selectedProduct" class="mt-4" />
      </div>
      <div class="product-table-legal mt-5">
        <div>
          Die Mindestlaufzeit des Vertrages ist unterhalb der Preisangabe zu finden.
          Der Vertrag kann von beiden Seiten jeweils mit einer Kündigungsfrist von einem Monat zum Ende der angegebenen Mindestlaufzeit schriftlich gekündigt werden (Nähere Informationen zu den Kündigungsbedingungen finden Sie unter Ziffer 5 unserer AGB).
        </div>
        <div class="mt-3">
          Nach Ablauf der Mindestlaufzeit verlängert sich der Vertrag auf unbestimmte Zeit, falls er nicht mit einer Frist von einem Monat zum Ende der Mindestlaufzeit gekündigt wird. Ebenso führt eine nicht fristgerechte Kündigung innerhalb der Mindestlaufzeit zur Verlängerung des Vertrags auf unbestimmte Zeit. Im Falle einer Verlängerung hat der Kunde ein Kündigungsrecht mit einer Frist von einem Monat zum Ende des Vertragsmonats.
        </div>
        <div class="mt-3">
          Der Begriff "Vertragsmonat" definiert sich wie folgt:
          Vertragsmonat bezeichnet einen Zeitraum, der an einem Tag in einem Kalendermonat beginnt, der numerisch dem Wirksamkeitsdatum der Vereinbarung entspricht und am numerisch identischen Tag im folgenden Kalendermonat endet, es sei denn, ein numerisch identischer Tag in dem Kalendermonat, in dem der Zeitraum enden soll, existiert nicht; in diesem Fall endet dieser Zeitraum am letzten Tag des betreffenden Kalendermonats.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import ProductDetail from "@/views/pages-landing/product/ProductDetail.vue";
import type { ShoppingProduct } from "@/api/models/shopping-cart";
import type { ProductFeatureData } from "@/api/models/product-comparison";

export default defineComponent({
  name: "ProductTableFooter",

  components: { ProductDetail },

  props: {
    productDatas: {
      type: Array as PropType<ProductFeatureData[]>,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      selectedProduct: null as ShoppingProduct | null
    };
  },

  computed: {
    columnStyleContent() {
      return {
        gridColumn: "2 / span " + this.productDatas.length
      };
    }
  },

  methods: {
    showProductDetails(product: ShoppingProduct) {
      if (product === this.selectedProduct) {
        this.selectedProduct = null;
      } else {
        this.selectedProduct = product;
      }
    }
  }
});
</script>

<style scoped>
.product-table-footer {
  display: contents;
}

.product-table-legal {
  font-size: 90%;
  color: var(--content-secondary);
}
</style>
import type { ChartOption, OptionProvider } from "@/anfin-chart/options/option";
import { NumericOption } from "@/anfin-chart/options/option";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { FixedPoint } from "@/anfin-chart/tools/tool-point";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { OptionName } from "@/anfin-chart/options/option-manager";
import { Subject } from "rxjs";
import { ToolAlertHookProvider, UserToolAlertHook } from "@/anfin-chart/tools/alert-hook";
import type { AlertToolData } from "@/anfin-chart/tools/alert-tool";

export class UserToolRelatedIndicator {

  constructor(public readonly type: string) {
  }
}

export abstract class UserToolDefinition implements OptionProvider {

  public readonly optionMap = new Map<string, ChartOption<any>>();
  public readonly lineWidth: NumericOption | null;

  public id: number | null = null;
  public changeDate = 0;
  public isPublic = false;

  public fixedPoints: FixedPoint[] = [];
  public readonly hookProvider = new ToolAlertHookProvider<UserToolAlertHook>();
  public readonly relatedIndicators: UserToolRelatedIndicator[] = [];

  private readonly optionChangeSubject = new Subject<ChartOption<unknown>>();

  protected constructor(public readonly type: string,
                        public readonly instrument: Instrument,
                        public readonly timeframe: Timeframe,
                        public readonly minCount: number,
                        public readonly maxCount: number | null,
                        hasLineWidth = true) {
    this.lineWidth = hasLineWidth ? new NumericOption(this, OptionName.LineWidth, 1) : null;
  }

  public onOptionChange(option: ChartOption<unknown>) {
    this.optionChangeSubject.next(option);
  }

  public getOptionChangeObservable() {
    return this.optionChangeSubject.asObservable();
  }

  public createPoint(time: number, price: number) {
    const fixedPoint = new FixedPoint(time, price);
    this.fixedPoints.push(fixedPoint);
    return fixedPoint;
  }

  public createAlertDrawables(data: AlertToolData) {
    // override in subclasses when necessary
  }

  public updateAlertPosition(data: AlertToolData) {
    // override in subclasses when necessary
  }

  public abstract createDrawables(tool: UserTool): void;

  public abstract updatePosition(tool: UserTool): void;
}

import {
  ConditionalColorProvider,
  GradientColor,
  MultiKeyColorProvider,
  RGBAColor,
  SimpleColorProvider,
  ThresholdColorProvider
} from "@/anfin-chart/draw/chart-color";
import { ChartError } from "@/anfin-chart/error";
import { Indicator, PriceRange } from "@/anfin-chart/indicator";
import type { IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import {
  ChartOptionDefinition,
  ColorDefinition,
  ColorProviderDefinition,
  ColorProviderType,
  ColorType,
  DependentIndicatorDefinition,
  PlotDefinition,
  PriceRangeDefinition
} from "@/anfin-chart/indicator-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import {
  BooleanOption,
  ColorOption,
  NumericArrayOption,
  NumericOption,
  OptionValueType
} from "@/anfin-chart/options/option";
import { Plot, PlotType, ValueBaseItem } from "@/anfin-chart/plot";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ABCDPattern } from "@/anfin-chart/tools/user-tools/abcd";
import { ArrowTool } from "@/anfin-chart/tools/user-tools/arrow";
import { Elliot12345 } from "@/anfin-chart/tools/user-tools/ell12345";
import { ElliotABC } from "@/anfin-chart/tools/user-tools/ellabc";
import { ElliotABCDE } from "@/anfin-chart/tools/user-tools/ellabcde";
import { EllipseTool } from "@/anfin-chart/tools/user-tools/ellipse";
import { ElliotWXY } from "@/anfin-chart/tools/user-tools/ellwxy";
import { ElliotWXYXZ } from "@/anfin-chart/tools/user-tools/ellwxyxz";
import { FreehandDrawTool } from "@/anfin-chart/tools/user-tools/fhdraw";
import { FibonacciRetracement } from "@/anfin-chart/tools/user-tools/fibo";
import { HeadAndShoulders } from "@/anfin-chart/tools/user-tools/has";
import { HorizontalChannel } from "@/anfin-chart/tools/user-tools/hchannel";
import { HorizontalLine } from "@/anfin-chart/tools/user-tools/hline";
import { HorizontalSegment } from "@/anfin-chart/tools/user-tools/hsegment";
import { LineTool } from "@/anfin-chart/tools/user-tools/line";
import { LineStripTool } from "@/anfin-chart/tools/user-tools/lstrip";
import { PriceEarningsRatio } from "@/anfin-chart/tools/user-tools/per";
import { PitchforkChannel } from "@/anfin-chart/tools/user-tools/pitch";
import { PriceRangeTool } from "@/anfin-chart/tools/user-tools/prange";
import { RectangleTool } from "@/anfin-chart/tools/user-tools/rect";
import { TextTool } from "@/anfin-chart/tools/user-tools/text";
import { TrendChannel } from "@/anfin-chart/tools/user-tools/trchannel";
import { TiltedRectangleTool } from "@/anfin-chart/tools/user-tools/trect";
import { TriangleTool } from "@/anfin-chart/tools/user-tools/triangle";
import { VerticalLine } from "@/anfin-chart/tools/user-tools/vline";
import { XABCDPattern } from "@/anfin-chart/tools/user-tools/xabcd";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

function getOptionClassName(type: OptionValueType) {
  switch (type) {
    case OptionValueType.Numeric:
      return "NumericOption";
    case OptionValueType.Boolean:
      return "BooleanOption";
    case OptionValueType.NumericArray:
      return "NumericArrayOption";
    default:
      throw new Error("Cannot resolve option class");
  }
}

export class IndicatorRegistry {

  private static readonly indicatorMap = new Map<string, new () => Indicator>();

  public static create(type: string) {
    const IndicatorClass = this.indicatorMap.get(type);
    if (IndicatorClass == null) {
      console.error("Unknown indicator: " + type);
      return null;
    }
    return new IndicatorClass();
  }

  public static importDefinition(definition: IndicatorDefinition) {
    try {
      const options = this.importOptions(definition.options).join("\n");
      const plots = this.importPlots(definition.plots).join("\n");
      const dependentIndicators = this.importDependentIndicators(definition.indicators).join("\n");
      const priceRanges = this.importPriceRanges(definition.priceRanges).join("\n");
      const dynamicConfiguration = definition.dynamicConfiguration == null
        ? "this.dynamicConfiguration = null;"
        : `this.dynamicConfiguration = (() => {
          ${definition.dynamicConfiguration}
        })();`;
      const parameters = [Indicator, BooleanOption, NumericOption, NumericArrayOption, Plot, PlotType,
        RGBAColor, GradientColor, SimpleColorProvider, ThresholdColorProvider, MultiKeyColorProvider,
        ConditionalColorProvider, ColorOption, OptionName, IndicatorRegistry, ValueBaseItem, PriceRange];
      const parameterNames = ["Indicator", "BooleanOption", "NumericOption", "NumericArrayOption", "Plot", "PlotType",
        "RGBAColor", "GradientColor", "SimpleColorProvider", "ThresholdColorProvider", "MultiKeyColorProvider",
        "ConditionalColorProvider", "ColorOption", "OptionName", "IndicatorRegistry", "ValueBaseItem", "PriceRange"];
      const code = `return class extends Indicator {
  
        static type = "${definition.type}";
        
        colorCounter = 0;
  
        constructor() {
          super("${definition.type}", "${definition.name}", ${definition.isNewSubChart});
          ${options}
          ${plots}
          ${dependentIndicators}
          ${priceRanges}
          this.afterConstruct();
        }
  
        initialize() {
          ${dynamicConfiguration}
          ${definition.initialize}
        }
  
        updateInternal(item) {
          ${definition.updateInternal}
        }
  
        getDependentIndicators() {
          const dynamicIndicators = this.dynamicConfiguration == null ? [] : this.dynamicConfiguration.indicators;
          return [
            ${definition.indicators.length > 0 ? definition.indicators.map(i => "this." + i.name).join(", ") + ", " : ""}
            ...dynamicIndicators
          ];
        }
        
        ${definition.utils.join("\n\n")}
      };`;
      const wrapper: any = new Function(...parameterNames, code);
      this.indicatorMap.set(definition.type, wrapper(...parameters));
    } catch (e) {
      console.error(e);
    }
  }

  private static importOptions(optionDefinitions: ChartOptionDefinition[]) {
    const options = [];
    for (const option of optionDefinitions) {
      const optionClassName = getOptionClassName(option.type);
      const value = this.importOptionValue(option);
      options.push(`this.${option.name} = new ${optionClassName}(this, "${option.name}", ${value});`);
    }
    return options;
  }

  private static importOptionValue(option: ChartOptionDefinition) {
    switch (option.type) {
      case OptionValueType.Boolean:
      case OptionValueType.Numeric:
      case OptionValueType.Enum:
        return String(option.value);
      case OptionValueType.NumericArray:
        return "[" + String(option.value) + "]";
      case OptionValueType.StringArray:
        return "[" + option.value + "]";
      case OptionValueType.String:
        return "\"" + option.value + "\"";
      default:
        throw new ChartError("Unknown option type");
    }
  }

  private static importPlots(plotDefinitions: PlotDefinition[]) {
    const plots = [];
    for (const plot of plotDefinitions) {
      const colorProvider = this.importColorProvider(plot.colorProvider);
      plots.push(`this.${plot.name}Plot = new Plot(this, "${plot.name}", ${plot.type}, ${colorProvider}, ${plot.useAxis});`);
    }
    return plots;
  }

  private static importColorProvider(colorProvider: ColorProviderDefinition) {
    if (colorProvider.type === ColorProviderType.Simple) {
      return `new SimpleColorProvider(${this.importColorOptions(colorProvider.colorSets[0])})`;
    } else if (colorProvider.type === ColorProviderType.Threshold) {
      const upper = this.importColorOptions(colorProvider.colorSets[0]);
      const lower = this.importColorOptions(colorProvider.colorSets[1]);
      return `new ThresholdColorProvider(${colorProvider.threshold}, ${upper}, ${lower})`;
    } else if (colorProvider.type === ColorProviderType.MultiKey && colorProvider.keys != null) {
      const keys = colorProvider.keys.map(k => "\"" + k + "\"").join(", ");
      const keyColors = colorProvider.colorSets.map(c => this.importColorOptions(c)).join(", ");
      return `new MultiKeyColorProvider([${keys}], [${keyColors}])`;
    } else if (colorProvider.type === ColorProviderType.Conditional && colorProvider.conditions != null) {
      const conditions = colorProvider.conditions.map(c => "\"" + c + "\"").join(", ");
      const conditionColors = colorProvider.colorSets.map(c => this.importColorOptions(c)).join(", ");
      return `new ConditionalColorProvider([${conditions}], [${conditionColors}])`;
    }
    throw new Error("Unknown color provider type");
  }

  private static importColorOptions(colors: ColorDefinition[]) {
    const colorOptions = [];
    for (const color of colors) {
      const importedColor = this.importColor(color);
      const colorOption = `new ColorOption(this, OptionName.Color + "_" + this.colorCounter++, ${importedColor})`;
      colorOptions.push(colorOption);
    }
    return `[${colorOptions.join(", ")}]`;
  }

  private static importColor(color: ColorDefinition) {
    if (color.type === ColorType.Rgba) {
      return `new RGBAColor(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    } else if (color.type === ColorType.Gradient) {
      const stops: string[] = color.stops.map(s => `.addStop(${s.percentage}, ${this.importColor(s.color)})`);
      return `new GradientColor()${stops.join("\n")}`;
    }
    throw new Error("Unknown color type");
  }

  private static importDependentIndicators(indicatorDefinitions: DependentIndicatorDefinition[]) {
    const dependentIndicators = [];
    for (const dependentIndicator of indicatorDefinitions) {
      dependentIndicators.push(`this.${dependentIndicator.name} = IndicatorRegistry.create("${dependentIndicator.type}");`);
    }
    return dependentIndicators;
  }

  private static importPriceRanges(priceRangeDefinitions: PriceRangeDefinition[]) {
    const priceRanges = [];
    for (const priceRange of priceRangeDefinitions) {
      const lineColor = this.importColor(priceRange.lineColor);
      const areaColor = this.importColor(priceRange.areaColor);
      priceRanges.push(`this.priceRanges.push(new PriceRange(${priceRange.from}, ${priceRange.to}, ${lineColor}, ${areaColor}))`);
    }
    return priceRanges;
  }
}

export class UserToolRegistry {

  private static readonly toolMap = new Map<string, (i: Instrument, t: Timeframe) => UserToolDefinition>();

  static {
    this.add(HorizontalLine.type, HorizontalLine);
    this.add(HorizontalSegment.type, HorizontalSegment);
    this.add(VerticalLine.type, VerticalLine);
    this.add(LineTool.type, LineTool);
    this.add(ArrowTool.type, ArrowTool);
    this.add(LineStripTool.type, LineStripTool);
    this.add(ABCDPattern.type, ABCDPattern);
    this.add(XABCDPattern.type, XABCDPattern);
    this.add(HeadAndShoulders.type, HeadAndShoulders);
    this.add(Elliot12345.type, Elliot12345);
    this.add(ElliotABC.type, ElliotABC);
    this.add(ElliotABCDE.type, ElliotABCDE);
    this.add(ElliotWXY.type, ElliotWXY);
    this.add(ElliotWXYXZ.type, ElliotWXYXZ);
    this.add(RectangleTool.type, RectangleTool);
    this.add(TiltedRectangleTool.type, TiltedRectangleTool);
    this.add(TrendChannel.type, TrendChannel);
    this.add(PriceEarningsRatio.type, PriceEarningsRatio);
    this.add(PriceRangeTool.type, PriceRangeTool);
    this.add(FibonacciRetracement.type, FibonacciRetracement);
    this.add(FreehandDrawTool.type, FreehandDrawTool);
    this.add(HorizontalChannel.type, HorizontalChannel);
    this.add(EllipseTool.type, EllipseTool);
    this.add(TriangleTool.type, TriangleTool);
    this.add(PitchforkChannel.type, PitchforkChannel);
    this.add(TextTool.type, TextTool);
  }

  public static create(type: string, instrument: Instrument, timeframe: Timeframe): UserToolDefinition {
    const creator = this.toolMap.get(type);
    if (creator == null) {
      throw new ChartError("Unknown user tool: " + type);
    }
    return creator(instrument, timeframe);
  }

  private static add<T extends UserToolDefinition>(type: string, creator: new (i: Instrument, t: Timeframe) => T) {
    this.toolMap.set(type, (i, t) => new creator(i, t) as UserToolDefinition);
  }
}

import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { EllipseElement, LineElement } from "@/anfin-chart/drawable";
import { getAngle, getAngleVector, getCenterPoint, getLineDistance } from "@/anfin-chart/geometry";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { FixedSelectablePoint, ValueSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class EllipseTool extends UserToolDefinition {

  public static readonly type = "ellipse";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(18, 173, 196));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(69, 103, 138, 0.25));

  private readonly center = new ChartToolPoint();
  private readonly upper = new ChartToolPoint();
  private readonly lower = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(EllipseTool.type, instrument, timeframe, 3, 3);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withExactPoints(2, () => this.createSecond(tool));
    tool.withAtLeastPoints(3, () => this.createThird(tool));
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const [first, second] = this.fixedPoints;
    const line = new LineElement(tool, first, second);
    tool.onUpdate(() => {
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, second);
  }

  private createThird(tool: UserTool) {
    const [first, second, third] = this.fixedPoints;
    const ellipse = new EllipseElement(tool, this.center, second, this.upper);
    tool.onUpdate(() => {
      ellipse.lineColor = this.lineColor.getValue();
      ellipse.areaColor = this.areaColor.getValue();
      ellipse.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, second);
    new ValueSelectablePoint(tool, this.upper, (time, price) => {
      third.update(time, price);
    });
    new ValueSelectablePoint(tool, this.lower, (time, price) => {
      third.update(time, price);
    });
  }

  public override updatePosition(tool: UserTool) {
    if (this.fixedPoints.length >= 3) {
      const [first, second, third] = this.fixedPoints.map(p => tool.getPosition(p));
      const center = getCenterPoint(first, second);
      tool.updatePoint(this.center, center);
      const angle = getAngle(first, second) + Math.PI / 2;
      const distance = getLineDistance(first, second, third);
      const offVector = getAngleVector(angle, distance);
      const upper = offVector.addTo(center);
      tool.updatePoint(this.upper, upper);
      const lower = offVector.asScaled(-1).addTo(center);
      tool.updatePoint(this.lower, lower);
    }
  }
}

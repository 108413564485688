<template>
  <textarea
    ref="textarea" :value="modelValue" class="form-control form-control-sm auto-resize-text"
    @input="onChange"
  ></textarea>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AutoSizeTextarea",

  props: {
    modelValue: {
      type: String,
      required: true
    }
  },

  emits: {
    "update:modelValue": (value: string) => value || true
  },

  expose: [],

  watch: {
    modelValue() {
      this.resize();
    }
  },

  mounted() {
    this.resize();
  },

  methods: {
    onChange(event: Event) {
      const target = event.target as HTMLTextAreaElement;
      const value = target.value;
      this.$emit("update:modelValue", value);
      this.resize();
    },

    resize() {
      setTimeout(() => {
        const textArea = this.$refs.textarea as HTMLTextAreaElement;
        textArea.style.height = "18px";
        textArea.style.height = textArea.scrollHeight + "px";
      });
    }
  }
});
</script>

<style scoped>
.auto-resize-text {
  overflow-y: hidden;
}
</style>

import { defineStore } from "pinia";
import type { ChartObjectPreset } from "@/api/models/user-settings/chart";
import { UserSettingsController } from "@/api/user-settings-controller";

export const presetStore = defineStore({
  id: "preset",

  state() {
    return {
      controller: UserSettingsController.getInstance(),
      toolPresetMap: new Map<number, ChartObjectPreset>(),
      indicatorPresetMap: new Map<number, ChartObjectPreset>()
    };
  },

  getters: {
    toolPresets(): ChartObjectPreset[] {
      return Array.from(this.toolPresetMap.values());
    },

    indicatorPresets(): ChartObjectPreset[] {
      return Array.from(this.indicatorPresetMap.values());
    }
  },

  actions: {
    async fetchPresets() {
      await Promise.all([this.fetchToolPresets(), this.fetchIndicatorPresets()]);
    },

    async fetchToolPresets() {
      const presets = await this.controller.getToolPresetList();
      this.toolPresetMap.clear();
      for (const preset of presets) {
        if (preset.id != null) {
          this.toolPresetMap.set(preset.id, preset);
        }
      }
    },

    async saveToolPreset(preset: ChartObjectPreset) {
      const id = await this.controller.saveToolPreset(preset);
      preset.id = id;
      this.toolPresetMap.set(id, preset);
    },

    async deleteToolPreset(preset: ChartObjectPreset) {
      if (preset.id != null) {
        this.toolPresetMap.delete(preset.id);
        await this.controller.deleteToolPreset(preset);
      }
    },

    async fetchIndicatorPresets() {
      const presets = await this.controller.getIndicatorPresetList();
      this.indicatorPresetMap.clear();
      for (const preset of presets) {
        if (preset.id != null) {
          this.indicatorPresetMap.set(preset.id, preset);
        }
      }
    },

    async saveIndicatorPreset(preset: ChartObjectPreset) {
      const id = await this.controller.saveIndicatorPreset(preset);
      preset.id = id;
      this.indicatorPresetMap.set(id, preset);
    },

    async deleteIndicatorPreset(preset: ChartObjectPreset) {
      if (preset.id != null) {
        this.indicatorPresetMap.delete(preset.id);
        await this.controller.deleteIndicatorPreset(preset);
      }
    }
  }
});

import type { AbstractConstructor, Constructor } from "@/anfin-chart/mixins/constructor";

export interface Hoverable {
  isHovered: boolean;
  isHoverPrevented: boolean;
  onStartHover(): void;
  onStopHover(): void;
}

export function asHoverable<TBase extends Constructor | AbstractConstructor>(base: TBase) {
  return class HoverableBase extends base implements Hoverable {

    public isHovered = false;
    public isHoverPrevented = false;

    public onStartHover() {
      this.isHovered = !this.isHoverPrevented;
    }

    public onStopHover() {
      this.isHovered = false;
    }

    public preventHover() {
      this.isHoverPrevented = true;
    }
  };
}

export function isHoverable(obj: unknown): obj is Hoverable {
  const hoverable = obj as Hoverable;
  return hoverable.isHovered != null && hoverable.isHoverPrevented != null && hoverable.onStartHover != null &&
    hoverable.onStopHover != null && !hoverable.isHoverPrevented;
}

import { InstrumentController } from "@/api/instrument-controller";
import type { InstrumentDetail } from "@/api/models/instrument-detail";
import { ChartHandler } from "@/utils/ChartHandler";
import { defineStore } from "pinia";
import { simpleMapCompare } from "@/anfin-chart/utils";

export const instrumentStore = defineStore({
  id: "instrument",

  state() {
    setTimeout(() => initializeStore());
    return {
      instrumentDetailsMap: new Map<string, InstrumentDetail>(),
      instrumentHistory: [] as string[]
    };
  },

  getters: {
    instrumentDetails(): InstrumentDetail[] {
      const details = Array.from(this.instrumentDetailsMap.values());
      return details.sort(simpleMapCompare(d => d.prio, d => d.name));
    },

    getDetail() {
      return (symbol: string) => this.instrumentDetailsMap.get(symbol) ?? null;
    },

    getDecimalPlaces() {
      return (symbol: string, price: number) => {
        const instrumentDetail = instrumentStore().getDetail(symbol);
        if (instrumentDetail?.pricePrecision != null) {
          return instrumentDetail?.pricePrecision;
        }
        if (instrumentDetail?.asset === "FX") {
          return 4;
        }
        if (price < 0.000001) {
          return 2;
        } else if (price < 0.001) {
          return 6;
        } else if (price < 0.01) {
          return 5;
        } else if (price < 10.00) {
          return 4;
        } else if (price < 99.00) {
          return 3;
        }
        return 2;
      };
    },

    symbolExists() {
      return (symbol: string) => this.instrumentDetailsMap.has(symbol);
    }
  },

  actions: {
    async requestInstrumentDetails() {
      const ignoredExchanges = new Set(["CHID", "BATD", "BATP", "CEUD", "LISZ", "LISX", "BEUP", "CEUO", "TWLV"]);
      const instrumentDetails = await InstrumentController.getInstance().getInstrumentDetails();
      for (const instrumentDetail of instrumentDetails) {
        if (!ignoredExchanges.has(instrumentDetail.exchange)) {
          this.instrumentDetailsMap.set(instrumentDetail.symbol, instrumentDetail);
        }
      }
    },

    requestLastChartViews() {
      ChartHandler.getInstance().chartServerInterface.requestLastChartView();
    },

    updateHistory(symbol: string) {
      let i = 0;
      while (i < this.instrumentHistory.length) {
        if (this.instrumentHistory[i] === symbol) {
          this.instrumentHistory.splice(i, 1);
        } else {
          i++;
        }
      }
      this.instrumentHistory.push(symbol);
      if (this.instrumentHistory.length > 10) {
        this.instrumentHistory.splice(0, 1);
      }
    }
  }
});

function initializeStore() {
  const store = instrumentStore();
  store.requestInstrumentDetails();
  store.requestLastChartViews();
}

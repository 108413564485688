<template>
  <div>
    <!--
    <div>
      <label class="form-label">
        {{ translationStore.getTranslation("alert_editor#label_name") }}
      </label>
      <input v-model="editedAlert.name" type="text" class="form-control form-control-sm" />
    </div>
    -->
    <AlertRuleEditor
      v-for="(rule, ruleIndex) in editedAlert.rules" :rule="rule" :index="ruleIndex + 1" class="mt-2"
      @remove="removeRule(ruleIndex)"
    />
    <ComingSoon featureKey="alert_auto_tool_indicator" class="mt-2" />
    <!--
    <div class="text-center">
      <button type="button" class="btn btn-secondary btn-sm mt-2" @click="createRule">
        {{ translationStore.getTranslation("alert_editor#add_rule_button") }}
        <IconElement iconName="Plus" :size="14" />
      </button>
    </div>
    -->
    <div class="button-stack justify-content-end mt-3">
      <button type="button" class="btn btn-secondary" @click="close">
        {{ translationStore.getTranslation("button_abort") }}
      </button>
      <button type="button" class="btn btn-primary" @click="saveEditedAlert">
        {{ translationStore.getTranslation("button_save") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import { alertStore } from "@/stores/alert-store";
import {
  Alert,
  type AlertCondition,
  AlertConditionOperator,
  AlertConverter,
  AlertRule,
  ComplexAlertCondition,
  SimpleAlertCondition
} from "@/api/models/alert";
import AlertRuleEditor from "@/views/alerts/AlertRuleEditor.vue";
import { multiChartStore } from "@/stores/multi-chart-store";
import type { Chart } from "@/anfin-chart/chart";
import ComingSoon from "@/views/components/ComingSoon.vue";

export default defineComponent({
  name: "AlertEditor",

  components: { ComingSoon, AlertRuleEditor },

  props: {
    alert: {
      type: Alert,
      required: true
    }
  },

  emits: {
    close: () => true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      editedAlert: new AlertConverter().clone(this.alert)
    };
  },

  watch: {
    alert(alert: Alert) {
      this.editedAlert = new AlertConverter().clone(alert);
    }
  },

  methods: {
    async saveEditedAlert() {
      await alertStore().saveAlert(this.editedAlert);
      this.close();
    },

    close() {
      this.$emit("close");
    },

    createRule() {
      const activeChart = multiChartStore().activeChart;
      const rule = AlertRule.createDefault(activeChart as Chart);
      this.editedAlert.rules.push(rule);
      this.updateCondition();
    },

    removeRule(index: number) {
      if (this.editedAlert.rules.length > 1) {
        this.editedAlert.rules.splice(index, 1);
        this.updateCondition();
      }
    },

    updateCondition() {
      let condition: AlertCondition = new SimpleAlertCondition(0);
      for (let i = 1; i < this.editedAlert.rules.length; i++) {
        condition = new ComplexAlertCondition(AlertConditionOperator.And, condition, new SimpleAlertCondition(i));
      }
      this.editedAlert.condition = condition;
    }
  }
});
</script>

<style scoped>
</style>
<template>
  <div class="m-1">
    <a
      v-if="attachment.type.indexOf('application/') === 0" :href="url" :download="downloadFileName"
      target="_blank" rel="noopener noreferrer" class="link"
    >
      {{ downloadLabel }}
    </a>
    <div v-else class="text-center m-2">
      <a :href="url" target="_blank" rel="noopener noreferrer">
        <img :src="url" alt="Attachment" class="mw-100" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ChatMessage, ChatMessageAttachment } from "@/api/models/chat";
import { getEnvironment } from "@/api/environment";
import { processPlaceholders, translationStore } from "@/stores/translation-store";
import { userRightStore } from "@/stores/user-right-store";

export default defineComponent({
  name: "ChatMessageAttachment",

  props: {
    message: {
      type: ChatMessage,
      required: true
    },
    attachment: {
      type: ChatMessageAttachment,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    url() {
      if (this.attachment.data != null) {
        return `data:${this.attachment.type};base64,${this.attachment.data}`;
      }
      const token = userRightStore().userInfo?.token;
      return `${getEnvironment().chatUrl}/attachment?id=${this.attachment.id}&chatId=${this.message.chat.id}&token=${token}`;
    },

    fileType() {
      const type = this.attachment.type;
      const index = type.indexOf(";");
      const fileType = index >= 0 ? type.substring(0, index) : type;
      return fileType.replace(/(?:data:)?application\//, "");
    },

    downloadFileName() {
      if (this.attachment.filename !== "") {
        return this.attachment.filename;
      }
      const id = this.attachment.id ?? 0;
      return "attachment_" + id + "." + this.fileType;
    },

    downloadLabel() {
      const translation = this.translationStore.getTranslation("chat#download_link");
      const data = { fileType: this.fileType.toUpperCase(), fileName: this.attachment.filename };
      return processPlaceholders(translation, data);
    }
  }
});
</script>

<style scoped>
</style>
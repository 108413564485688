<template>
  <ModalDialog
    ref="createMessageDialog" :title="translationStore.getTranslation('chat#create_message_dialog#title')"
    labelSave="chat#create_message_dialog#button_send" @save="saveMessage" @close="onClose"
  >
    <template #body>
      <ChatMessageEditor v-if="message != null" :message="message as ChatMessage" />
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ConfirmationModalDialog from "@/views/dialogs/ConfirmationModalDialog.vue";
import ChatMessageEditor from "@/views/chat/ChatMessageEditor.vue";
import { ChatMessage, ChatMessageConverter } from "@/api/models/chat";
import { chatStore } from "@/stores/chat-store";
import ModalDialog from "@/views/components/ModalDialog.vue";
import { translationStore } from "@/stores/translation-store";
import { uiStateStore } from "@/stores/ui-state-store";

export default defineComponent({
  name: "ChatMessageEditDialog",

  components: { ModalDialog, ChatMessageEditor },

  expose: ["show"],

  data() {
    return {
      message: null as ChatMessage | null,
      translationStore: translationStore(),
      ChatMessage
    };
  },

  methods: {
    show(message: ChatMessage) {
      this.message = new ChatMessageConverter(message.chat).clone(message);
      const dialog = this.$refs.createMessageDialog as typeof ConfirmationModalDialog;
      dialog.show();
    },

    onClose() {
      uiStateStore().editedMessage = null;
      this.message = null;
    },

    async saveMessage() {
      if (this.message != null) {
        await chatStore().saveMessage(this.message as ChatMessage);
        this.message = null;
      }
    }
  }
});
</script>

<style scoped>
</style>
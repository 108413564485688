<template>
  <div class="market-overview-container">
    <ConfirmationModalDialog
      ref="clearWatchlistDialog" question="marketoverview#clear#question"
      labelOk="marketoverview#button_clear" titleDialog="marketoverview#clear#title" @pressedOk="clearWatchlist"
    />
    <ConfirmationModalDialog
      ref="deleteWatchlistDialog" question="marketoverview#delete#question"
      labelOk="button_delete" titleDialog="marketoverview#delete#title" @pressedOk="deleteWatchlist"
    />
    <ConfirmationModalDialog
      ref="createWatchlistDialog" question="marketoverview#newlist#question"
      labelOk="button_create" titleDialog="marketoverview#newlist#title"
      autoFocus="market-overview-dialog-newlist-input" @pressedOk="addNewWatchlist"
    >
      <template #default>
        <div class="label-group mt-2">
          <input id="market-overview-dialog-newlist-input" v-model="newList" class="form-control" />
        </div>
      </template>
    </ConfirmationModalDialog>
    <ConfirmationModalDialog
      ref="cloneWatchlistDialog" question="marketoverview#newlist#question"
      labelOk="button_create" titleDialog="marketoverview#newlist#title"
      autoFocus="market-overview-dialog-copielist-input" @pressedOk="cloneNewWatchlist"
    >
      <template #default>
        <input id="market-overview-dialog-copielist-input" v-model="newList" class="form-control mt-2" />
      </template>
    </ConfirmationModalDialog>
    <ConfirmationModalDialog
      ref="importWatchlistDialog" question="marketoverview#import#question"
      labelOk="marketoverview#import#ok" titleDialog="marketoverview#import#title" @pressedOk="importWatchlist"
    >
      <template #default>
        <div class="mt-3">
          <input ref="importFileInput" type="file" @change="onImportFileChanged" />
        </div>
        <div class="mt-3">
          <div v-if="importInfo.symbolsNotOk >= 0" class="mb-2">
            <span class="col-9"> {{ translationStore.getTranslation("marketoverview#import#newsymbols") }}</span>
            <span class="col-2"> {{ importInfo.symbols.length }} </span>
          </div>
          <div v-if="importInfo.symbolsOkButExists >= 0" class="mb-2">
            <span class="col-9"> {{ translationStore.getTranslation("marketoverview#import#existssymbols") }}</span>
            <span class="col-2"> {{ importInfo.symbolsOkButExists }} </span>
          </div>
          <div v-if="importInfo.symbolsNotOk >= 0">
            <span class="col-9"> {{ translationStore.getTranslation("marketoverview#import#notoksymbols") }}</span>
            <span class="col-2"> {{ importInfo.symbolsNotOk }} </span>
          </div>
        </div>
      </template>
    </ConfirmationModalDialog>
    <SettingsWindowHeader class="market-overview-header">
      <ToolbarDropdown ref="dropdownWatchlist" :items="items" :position="watchlistDropdownPosition" class="me-2">
        <template #header>
          <WatchlistCategoryIcon v-if="selectedWatchlistId < 0" :category="-selectedWatchlistId" />
          <template v-else>
            {{ selectedWatchlist?.name ?? "" }}
          </template>
        </template>
        <template #footer>
          <WatchlistCategoryIcons :includeUnset="false" class="watchlist-dropdown-categories" @selectCategory="setCategory" />
        </template>
      </ToolbarDropdown>
      <div class="market-overview-header-icons ms-auto">
        <InstrumentFilterList
          v-if="isAddingItem"
          id="watchlist-symbol-search"
          icon="Plus"
          :showIconRight="true"
          @selectItem="itemMarketListSelected"
          @onBlur="hideAppendSymbol"
        />
        <IconElement v-else iconName="Plus" :color="addButtonColor" :size="22" @click="setAppendMode" />
        <IconElement
          iconName="Star" :size="22" :color="favoriteButtonColor"
          :fillcolor="isFavorite() ? favoriteButtonColor : 'transparent'"
          @click.stop="toggleFavorite"
        />
        <ToolbarDropdown
          class="market-overview-header-icon-more" :items="moreActions" titleIcon="More"
          :arrowHidden="true" :position="moreActionsDropdownPosition"
        />
      </div>
    </SettingsWindowHeader>
    <!--
    <SingleSelect
      :selectItems="watchlists" :selectedValue="selectedWatchlistId" itemKey="id"
      itemCaption="name" :listOffset="6" :selectWidth="268" class="mt-2"
      @changeListItem="setSelectedWatchlist"
    />
    -->
    <WatchlistTable
      v-if="selectedWatchlist != null" :watchlist="selectedWatchlist"
      :allowDelete="canModifyWatchlist(true)" class="flex-grow-1"
    />
  </div>
</template>

<script lang="ts">
import { type Consumer, CssPosition, getColorByCSSDefine } from "@/anfin-chart/utils";
import { SymbolCategory } from "@/api/models/symbol-category";
import { Watchlist, WatchlistImportInfo, WatchlistType } from "@/api/models/watchlist";
import { chartOptionStore, MultiChartOptionManager } from "@/stores/chart-option-store";
import { favoriteStore } from "@/stores/favorite-store";
import { translationStore } from "@/stores/translation-store";
import { userRightStore } from "@/stores/user-right-store";
import { watchlistStore } from "@/stores/watchlist-store";
import WatchlistCategoryIcon from "@/views/watchlist/WatchlistCategoryIcon.vue";
import WatchlistCategoryIcons from "@/views/watchlist/WatchlistCategoryIcons.vue";
import WatchlistTable from "@/views/watchlist/WatchlistTable.vue";
import ConfirmationModalDialog from "@/views/dialogs/ConfirmationModalDialog.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { ToolbarItemData } from "@/views/toolbar/toolbar-item-data";
import ToolbarDropdown from "@/views/toolbar/ToolbarDropdown.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import SettingsWindowHeader from "@/views/settings/SettingsWindowHeader.vue";
import InstrumentFilterList from "@/views/components/InstrumentFilterList.vue";

export default defineComponent({
  name: "WatchlistOverview",

  components: {
    InstrumentFilterList, SettingsWindowHeader, WatchlistCategoryIcon, WatchlistCategoryIcons,
    ConfirmationModalDialog, ToolbarDropdown, IconElement, WatchlistTable
  },

  expose: [],

  data() {
    const { watchlists } = storeToRefs(watchlistStore());
    const { optionManager } = storeToRefs(chartOptionStore());
    return {
      optionManager: optionManager as unknown as MultiChartOptionManager,
      watchlists: watchlists as unknown as Watchlist[],
      WatchlistType,
      showList: false,
      isAddingItem: false,
      importInfo: new WatchlistImportInfo(),
      newList: "",
      watchlistDropdownPosition: new CssPosition(31, -10, null, null),
      moreActionsDropdownPosition: new CssPosition(31, null, null, -15),
      favoriteStore: favoriteStore(),
      watchlistStore: watchlistStore(),
      translationStore: translationStore(),
      SymbolCategory
    };
  },

  computed: {
    moreActions() {
      const items = [];
      if (!userRightStore().isMobile) {
        items.push(this.getToolItem("watchlist_overview#more_import", "Import", this.canModifyWatchlist(true), this.showDialog.bind(this, "import")));
        items.push(this.getToolItem("watchlist_overview#more_export", "Export", true, this.exportWatchlist.bind(this)));
      }
      items.push(this.getToolItem("watchlist_overview#more_copy", "Clone", true, this.showDialog.bind(this, "clone")));
      items.push(this.getToolItem("watchlist_overview#more_clear", "Clear", this.canModifyWatchlist(true), this.showDialog.bind(this, "clear")));
      items.push(this.getToolItem("watchlist_overview#more_remove", "Delete", this.canModifyWatchlist(false), this.showDialog.bind(this, "delete")));
      return items;
    },

    items() {
      const items = [];
      items.push(new ToolbarItemData("watchlist_overview#item_new_watchlist", null, "Plus", this.showDialog.bind(this, "newList"), null, null));
      items.push(new ToolbarItemData("-1", "-", null, null, null, null));

      const favItem = new ToolbarItemData("watchlist_overview#item_favorites", null, "Star", null, null, null);
      favItem.iconColor = this.getColor("--interactive-accent");
      favItem.noSelection = true;
      favItem.textColor = this.getColor("--content-tertiary");
      items.push(favItem);

      for (const watchlist of this.watchlists) {
        if (this.favoriteStore.isFavorite("marketlist#" + watchlist.id)) {
          const key = "watchlist-" + watchlist.id;
          const onSelect = () => this.setSelectedWatchlist(watchlist.id);
          const watchlistItem = new ToolbarItemData(key, watchlist.name, null, onSelect);
          watchlistItem.iconSize = 18;
          watchlistItem.iconRight = this.getWatchlistIcon(watchlist as Watchlist);
          items.push(watchlistItem);
        }
      }
      items.push(new ToolbarItemData("-2", "-", null, null, null, null));
      const savedAssets = new ToolbarItemData("watchlist_overview#item_saved_assets", null, null, null, null, null);
      savedAssets.noSelection = true;
      savedAssets.textColor = this.getColor("--content-tertiary");
      items.push(savedAssets);
      return items;
    },

    selectedWatchlist() {
      const id = chartOptionStore().optionManager.selectedWatchlistMainView.getValue();
      return watchlistStore().getWatchlist(id);
    },

    selectedWatchlistId() {
      return this.selectedWatchlist == null ? 0 : this.selectedWatchlist.id;
    },

    addButtonColor() {
      return this.canModifyWatchlist(true) ? null : this.getColor("--interactive-secondary");
    },

    favoriteButtonColor() {
      if (this.isFavorite()) {
        return this.getColor("--interactive-accent");
      }
      if (this.selectedWatchlist?.type === WatchlistType.Virtual) {
        return this.getColor("--interactive-secondary");
      }
      return null;
    }
  },

  watch: {
    watchlists(watchlists: Watchlist[]) {
      if (this.selectedWatchlistId === 0 && watchlists.length > 0) {
        this.setSelectedWatchlist(watchlists[0].id);
      }
    }
  },

  methods: {
    itemMarketListSelected(item: any) {
      this.hideAppendSymbol();
      if (this.selectedWatchlistId > 0) {
        this.watchlistStore.createWatchlistItems(this.selectedWatchlistId, [item.symbol]);
      } else {
        this.watchlistStore.setCategory(item.symbol, Math.abs(this.selectedWatchlistId));
      }
    },

    hideAppendSymbol() {
      this.isAddingItem = false;
    },

    setCategory(category: SymbolCategory) {
      this.setSelectedWatchlist(-category);
      (this.$refs.dropdownWatchlist as typeof ToolbarDropdown).hide();
    },

    isFavorite() {
      return this.favoriteStore.isFavorite("marketlist#" + this.selectedWatchlistId);
    },

    toggleFavorite() {
      if (this.selectedWatchlistId > 0) {
        this.favoriteStore.toggle("marketlist#" + this.selectedWatchlistId);
      }
    },

    getWatchlistIcon(watchlist: Watchlist) {
      switch (watchlist.type) {
        case WatchlistType.Public:
          return "World";
        case WatchlistType.User:
          return "Account";
        default:
          return "Lock";
      }
    },

    getColor(cssDefine: string) {
      return getColorByCSSDefine(cssDefine);
    },

    setSelectedWatchlist(value: number) {
      this.optionManager.selectedWatchlistMainView.setValue(value);
    },

    exportWatchlist() {
      if (this.selectedWatchlist != null) {
        this.watchlistStore.exportToCsv(this.selectedWatchlist);
      }
    },

    showDialog(dialogType: string) {
      let dialog: typeof ConfirmationModalDialog;
      if (dialogType === "clear") {
        dialog = this.$refs.clearWatchlistDialog as typeof ConfirmationModalDialog;
      } else if (dialogType === "delete") {
        dialog = this.$refs.deleteWatchlistDialog as typeof ConfirmationModalDialog;
      } else if (dialogType === "clone") {
        dialog = this.$refs.cloneWatchlistDialog as typeof ConfirmationModalDialog;
      } else if (dialogType === "import") {
        this.importInfo.clear();
        dialog = this.$refs.importWatchlistDialog as typeof ConfirmationModalDialog;
        (this.$refs.importFileInput as HTMLInputElement).value = "";
      } else {
        dialog = this.$refs.createWatchlistDialog as typeof ConfirmationModalDialog;
      }
      dialog.show();
    },

    clearWatchlist()  {
      if (this.selectedWatchlist != null) {
        this.watchlistStore.clearWatchlist(this.selectedWatchlist);
      }
    },

    async cloneNewWatchlist() {
      const watchlist = await this.watchlistStore.cloneWatchlist(this.selectedWatchlistId, this.newList);
      this.newList = "";
      if (watchlist != null) {
        this.setSelectedWatchlist(watchlist.id);
      }
    },

    onImportFileChanged(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      const files = inputElement.files;
      if (files == null || files.length === 0) {
        return;
      }
      const file = files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const csvData = e.target?.result;
        if (csvData) {
          if (typeof csvData === "string") {
            const importInfo = this.watchlistStore.parseImportData(this.selectedWatchlistId, csvData);
            this.importInfo.symbolsNotOk = importInfo.symbolsNotOk;
            this.importInfo.symbolsOkButExists = importInfo.symbolsOkButExists;
            this.importInfo.addSymbols(importInfo.symbols);
          }
        }
      };
      reader.onerror = () => {
        // TODO: add toast instead of popup alert
        alert("Unable to read " + file);
      };
      this.importInfo.clear();
      reader.readAsText(file);
    },

    importWatchlist() {
      if (this.selectedWatchlistId > 0) {
        this.watchlistStore.createWatchlistItems(this.selectedWatchlistId, this.importInfo.symbols);
      } else if (this.selectedWatchlistId < 0) {
        for (const symbol of this.importInfo.symbols) {
          this.watchlistStore.setCategory(symbol, Math.abs(this.selectedWatchlistId));
        }
      }
    },

    async addNewWatchlist() {
      const watchlist = await this.watchlistStore.createWatchlist(this.newList);
      this.newList = "";
      this.setSelectedWatchlist(watchlist.id);
    },

    async deleteWatchlist() {
      if (this.selectedWatchlistId > 0) {
        await this.watchlistStore.deleteWatchlist(this.selectedWatchlistId);
        if (this.watchlists.length > 0) {
          this.setSelectedWatchlist(this.watchlists[0].id);
        }
      }
    },

    canModifyWatchlist(withVirtualWatchlist: boolean) {
      return this.selectedWatchlist !== null && (userRightStore().isAdmin || withVirtualWatchlist && (this.selectedWatchlist.type === WatchlistType.Virtual || this.selectedWatchlist.type === WatchlistType.User) || !withVirtualWatchlist && this.selectedWatchlist.type === WatchlistType.User);
    },

    getToolItem(key: string, icon: string, selectable: boolean, onSelect: Consumer<void> | null = null): ToolbarItemData {
      const toolItem = new ToolbarItemData(key, null, icon, selectable ? onSelect : null, null, null);
      if (!selectable) {
        toolItem.textColor = this.getColor("--interactive-secondary");
        toolItem.iconColor = toolItem.textColor;
        toolItem.noSelection = true;
      }
      toolItem.iconSize = 22;
      return toolItem;
    },

    setAppendMode() {
      this.isAddingItem = !this.isAddingItem && this.canModifyWatchlist(true);
      if (this.isAddingItem) {
        setTimeout(() => {
          const element = document.getElementById("watchlist-symbol-search");
          if (element?.lastElementChild && element?.lastElementChild.firstElementChild) {
            (element?.lastElementChild.firstElementChild as HTMLElement)?.focus();
          }
        }, 100);
      }
    }
  }
});
</script>

<style scoped>
.market-overview-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--content-primary);
}

.market-overview-header {
  display: flex;
  align-items: center;
}

.market-overview-header-icons {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--content-secondary);
  stroke: var(--content-secondary);
}

.market-overview-header-icons > svg {
  fill: var(--content-secondary);
  stroke: none;
}

#watchlist-symbol-search {
  width: 115px;
  height: 28px;
  margin-right: -4px;
}

.watchlist-dropdown-categories {
  padding: 0 5px;
}
</style>

import { ChartArea } from "@/anfin-chart/area/chart-area";
import { Rectangle, Size } from "@/anfin-chart/geometry";
import { ChartError } from "@/anfin-chart/error";
import { StackAlignment, StackDirection, StackPanel } from "@/anfin-chart/area/stack-panel";

export abstract class StackItem extends ChartArea {

  public offset = 0;
  private size = new Size(0, 0);

  protected constructor(private readonly parentPanel: StackPanel) {
    super(parentPanel.layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.parentPanel.getPositionObservable(), () => this.resize());
  }

  public getSize() {
    return this.size;
  }

  public setSize(size: Size) {
    if (size.width === this.size.width && size.height === this.size.height) {
      return;
    }
    this.size = size;
    this.resize();
  }

  protected override resizeInternal() {
    const parentPosition = this.parentPanel.getPosition();
    const size = this.getSize();
    const stackDirection = this.parentPanel.direction;
    let xStart: number;
    let xEnd: number;
    let yStart: number;
    let yEnd: number;
    switch (stackDirection) {
      case StackDirection.Horizontal:
        xStart = parentPosition.xStart + this.offset;
        xEnd = xStart + size.width;
        [yStart, yEnd] = this.getCrossAxisPosition(parentPosition.yStart, parentPosition.yEnd, size.height);
        break;
      case StackDirection.Vertical:
        yStart = parentPosition.yStart + this.offset;
        yEnd = yStart + size.height;
        [xStart, xEnd] = this.getCrossAxisPosition(parentPosition.xStart, parentPosition.xEnd, size.width);
        break;
      default:
        throw new ChartError("Unknown stack panel direction: " + stackDirection);
    }
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private getCrossAxisPosition(start: number, end: number, size: number) {
    const stackAlignment = this.parentPanel.alignment;
    switch (stackAlignment) {
      case StackAlignment.Start:
        return [start, start + size];
      case StackAlignment.End:
        return [end - size, end];
      case StackAlignment.Center:
        const center = (start + end) / 2;
        const offset = size / 2;
        return [center - offset, center + offset];
      case StackAlignment.Stretch:
        return [start, end];
      default:
        throw new ChartError("Unknown stack panel alignment: " + stackAlignment);
    }
  }
}

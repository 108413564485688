<template>
  <LoginFormTemplate buttonLabel="login#button_reset_new_password" @submit="handleSubmit">
    <div>
      <label class="form-label">
        {{ translationStore.getTranslation("login#label_new_password") }}
      </label>
      <input
        v-model="newPassword" type="password" name="newPassword" class="form-control" autocomplete="new-password"
        :placeholder="translationStore.getTranslation('login#label_new_password')"
      />
    </div>
    <div class="mt-2">
      <label class="form-label">
        {{ translationStore.getTranslation("login#label_new_password_confirm") }}
      </label>
      <input
        v-model="newPasswordConfirm" type="password" name="newPasswordConfirm" class="form-control" autocomplete="new-password"
        :placeholder="translationStore.getTranslation('login#label_new_password_confirm')"
      />
    </div>
    <div v-if="state === NewPasswordState.MissingData" class="text-error mt-2">
      {{ translationStore.getTranslation("login#new_password_missing_data") }}
    </div>
    <div v-else-if="state === NewPasswordState.PasswordNotEqual" class="text-error mt-2">
      {{ translationStore.getTranslation("login#new_password_not_equal") }}
    </div>
    <div v-else-if="state === NewPasswordState.PasswordChangeFailed" class="text-error mt-2">
      {{ translationStore.getTranslation("login#new_password_change_failed") }}
    </div>
    <div v-else-if="state === NewPasswordState.PasswordChanged" class="text-highlighted mt-2">
      {{ translationStore.getTranslation("login#new_password_changed") }}
    </div>
    <div class="mt-2">
      <router-link class="link" to="/login">
        {{ translationStore.getTranslation("login#link_login") }}
      </router-link>
    </div>
  </LoginFormTemplate>
</template>

<script lang="ts">
import { AuthenticationController } from "@/api/authentication-controller";
import { translationStore } from "@/stores/translation-store";
import LoginFormTemplate from "@/views/login/LoginFormTemplate.vue";
import { defineComponent } from "vue";

export enum NewPasswordState {
  Initial = 0,
  MissingData = 1,
  PasswordNotEqual = 2,
  PasswordChangeFailed = 3,
  PasswordChanged = 4
}

export default defineComponent({
  name: "ResetNewPasswordForm",

  components: { LoginFormTemplate },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      newPassword: "",
      newPasswordConfirm: "",
      state: NewPasswordState.Initial as NewPasswordState,
      NewPasswordState
    };
  },

  methods: {
    async handleSubmit() {
      this.state = NewPasswordState.Initial;
      if (this.newPassword === "") {
        this.state = NewPasswordState.MissingData;
        return;
      }
      if (this.newPassword !== this.newPasswordConfirm) {
        this.state = NewPasswordState.PasswordNotEqual;
        return;
      }
      try {
        const token = this.$route.query.token as string;
        await AuthenticationController.getInstance().sendNewPassword(token, this.newPassword);
        this.state = NewPasswordState.PasswordChanged;
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3000);
      } catch (e) {
        this.state = NewPasswordState.PasswordChangeFailed;
      }
    }
  }
});
</script>

<style scoped>

</style>

import { ApiError } from "@/api/error";
import { AnalysisToolDefinition, AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";

export class AutoTrendLine extends AnalysisToolDefinition {

  constructor(id: string,
              instrument: Instrument,
              timeframe: Timeframe,
              public sequenceIndex: number,
              startTime: number,
              public readonly startPrice: number,
              public readonly endTime: number,
              public readonly endPrice: number,
              public readonly trendType: TrendLineType,
              public readonly deletedAt: number | null,
              public readonly parent: number | null,
              public readonly extensions: TrendExtension[]) {
    super(id, AnalysisType.TrendLine, instrument, timeframe, startTime);
  }
}

export enum TrendLineType {
  Main = 0,
  SubMain = 1,
  Sub = 2,
  Candidate = 3
}

export function getTrendType(type: string) {
  switch (type) {
    case "MAIN":
      return TrendLineType.Main;
    case "SUBMAIN":
      return TrendLineType.SubMain;
    case "SUB":
      return TrendLineType.Sub;
    case "CANDIDATE":
      return TrendLineType.Candidate;
    default:
      throw new ApiError("Cannot resolve trend line type: " + type);
  }
}

export class TrendExtension {

  constructor(public readonly time: number,
              public readonly ruleName: string,
              public readonly ruleDate: number | null,
              public readonly breakoutPrice: number | null,
              public readonly consolidationPrice: number | null) {
  }
}

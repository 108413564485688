<template>
  <div class="alert-editor-rule">
    <!--
    <div class="alert-editor-rule-header pb-1 mb-2">
      <div>
        {{ translationStore.getTranslation("alert_editor#rule_header") + " " + index }}
      </div>
      <IconElement iconName="Cross" :size="14" class="ms-auto" @click="deleteRule" />
    </div>
    -->
    <div>
      <label class="form-label">
        {{ translationStore.getTranslation("alert_editor#label_instrument") }}
      </label>
      <InstrumentFilterList
        id="alert-symbol-search"
        :placeholder="instrumentName"
        :useHistory="true"
        @selectItem="setInstrument"
      />
    </div>
    <div class="mt-2">
      <label class="form-label">
        {{ translationStore.getTranslation("alert_editor#label_rule_direction") }}
      </label>
      <SingleSelect v-model="rule.direction" :items="directionItems" @update:modelValue="onDirectionChange" />
    </div>
    <AlertRuleDefinitionEditor
      v-for="(definition, definitionIndex) in rule.definitions" :rule="rule" :definition="definition"
      @change="changeDefinition(definitionIndex, $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import { translationStore } from "@/stores/translation-store";
import {
  AlertAutoToolDefinition,
  AlertFixedValueDefinition,
  AlertRule,
  AlertRuleDefinition,
  AlertRuleDirection,
  AlertRuleType, AlertUserToolDefinition
} from "@/api/models/alert";
import { getEnumValues } from "@/anfin-chart/utils";
import { Instrument } from "@/anfin-chart/instrument";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import AlertRuleDefinitionEditor from "@/views/alerts/AlertRuleDefinitionEditor.vue";
import { chartObjectStore } from "@/stores/chart-object-store";
import InstrumentFilterList from "@/views/components/InstrumentFilterList.vue";
import { instrumentStore } from "@/stores/instrument-store";
import { analysisStore } from "@/stores/analysis-store";

export default defineComponent({
  name: "AlertRuleEditor",

  components: { InstrumentFilterList, AlertRuleDefinitionEditor, SingleSelect },

  props: {
    rule: {
      type: AlertRule,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  emits: {
    remove: () => true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      directionItems: getEnumValues(AlertRuleDirection).map(d => new SingleSelectItem("alert_rule_direction#" + d, null, d)),
      AlertRuleType
    };
  },

  computed: {
    instrumentName() {
      const symbol = this.rule.instrument.getSymbol();
      const instrumentDetail = instrumentStore().getDetail(symbol);
      return instrumentDetail?.printName ?? symbol;
    }
  },

  methods: {
    setInstrument(item: any) {
      const instrument = Instrument.fromSymbol(item.symbol);
      if (instrument.isValid()) {
        this.rule.instrument = instrument;
      }
    },

    setTimeframe(event: Event) {
      const target = event.target as HTMLSelectElement;
      try {
        this.rule.timeframe = Timeframe.fromShortNotation(target.value);
      } catch (e) {
        console.error(e);
      }
    },

    onDirectionChange() {
      const definitionCount = this.getDefinitionCount();
      const definitions = this.rule.definitions;
      while (definitions.length > definitionCount) {
        definitions.pop();
      }
      while (definitions.length < definitionCount) {
        definitions.push(this.createDefinition());
      }
    },

    getDefinitionCount() {
      switch (this.rule.direction) {
        case AlertRuleDirection.Above:
        case AlertRuleDirection.Below:
          return 1;
        case AlertRuleDirection.Between:
        case AlertRuleDirection.Outside:
          return 2;
        default:
          return 1;
      }
    },

    createDefinition() {
      const existingDefinition = this.rule.definitions[this.rule.definitions.length - 1];
      if (existingDefinition instanceof AlertFixedValueDefinition) {
        return new AlertFixedValueDefinition(existingDefinition.fixedValue);
      }
      if (existingDefinition instanceof AlertUserToolDefinition) {
        const toolId = existingDefinition.toolId;
        const tool = chartObjectStore().getOrRequestUserTool(toolId);
        const existingHook = tool?.hookProvider.getHook(existingDefinition);
        const otherHook = tool?.hookProvider.alertHooks.find(h => h !== existingHook);
        if (otherHook == null) {
          return new AlertUserToolDefinition(toolId, existingDefinition.basePointIndex);
        }
        return new AlertUserToolDefinition(toolId, otherHook.basePointIndex);
      }
      if (existingDefinition instanceof AlertAutoToolDefinition) {
        const toolId = existingDefinition.toolId;
        const analysis = analysisStore().getAnalysis(toolId);
        const existingHook = analysis?.hookProvider.getHook(existingDefinition);
        const otherHook = analysis?.hookProvider.alertHooks.find(h => h !== existingHook);
        if (otherHook == null) {
          return new AlertAutoToolDefinition(toolId, existingDefinition.percentageOffset);
        }
        return new AlertAutoToolDefinition(toolId, otherHook.percentageOffset);
      }
      return new AlertFixedValueDefinition(0);
    },

    changeDefinition(index: number, definition: AlertRuleDefinition) {
      this.rule.definitions[index] = definition;
    },

    deleteRule() {
      this.$emit("remove");
    }
  }
});
</script>

<style scoped>
.alert-editor-rule {
  border: 1px solid var(--border-overlay);
  background-color: var(--background-elevated);
  border-radius: var(--border-radius-single);
  padding: 6px 10px;
}

.alert-editor-rule-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  border-bottom: 1px solid var(--border-neutral);
}
</style>
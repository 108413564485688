import { RestApiController } from "@/api/rest-api-controller";
import { ChartLayoutConverter } from "@/api/messages/layout";
import type { ChartLayoutResponse } from "@/api/messages/layout";
import type { ChartLayout } from "@/api/models/chart-layout";
import { userRightStore } from "@/stores/user-right-store";

export class LayoutController extends RestApiController {

  private static instance: LayoutController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new LayoutController();
    }
    return this.instance;
  }

  public async getLayout() {
    const parameterMap = new Map<string, string>();
    parameterMap.set("name", userRightStore().deviceType);
    const response = await this.sendGetRequest<ChartLayoutResponse>("chart-layouts/current", parameterMap);
    return new ChartLayoutConverter().toModelObject(response);
  }

  public async saveLayout(layout: ChartLayout) {
    const data = new ChartLayoutConverter().toApiObject(layout);
    return await this.sendPostRequest<number>("chart-layouts/current", data);
  }

  protected getBaseUrl() {
    return this.environment.configurationUrl;
  }
}

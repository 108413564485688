import { UserToolConverter } from "@/api/messages/user-tool";
import { Watchlist, WatchlistType } from "@/api/models/watchlist";
import { chartObjectStore } from "@/stores/chart-object-store";
import { favoriteStore } from "@/stores/favorite-store";
import { userRightStore } from "@/stores/user-right-store";
import { watchlistStore } from "@/stores/watchlist-store";
import { WebSocketApiController } from "@/api/web-socket-api-controller";
import type { ChartServerInterface } from "@/utils/ChartServerInterface";
import { getEnvironment } from "@/api/environment";
import { alertStore } from "@/stores/alert-store";
import { AlertConverter, AlertState } from "@/api/models/alert";
import { chatStore } from "@/stores/chat-store";
import { notificationStore } from "@/stores/notification-store";
import { UserRightKey } from "@/api/models/user-right";
import { ChatMessageConverter } from "@/api/models/chat";
import { chartOptionStore } from "@/stores/chart-option-store";
import { OptionName } from "@/anfin-chart/options/option-manager";

export class ChartWebSocketController extends WebSocketApiController {

  constructor(public readonly owner: ChartServerInterface) {
    super();
  }

  public override onMessage(event: MessageEvent) {
    const message = JSON.parse(event.data);
    const action = message.action;
    if (action == null) {
      console.error("Cannot resolve web socket action");
      return;
    }
    super.logAction(message, "WS RECEIVE " + action);
    const data = message.data;
    if (action === "login") {
      super.onLoginResponse(data);
      this.owner.handleUserApiLogin(data);
    } else if (action === "getLastView") {
      this.owner.owner.handleLastViewResponse(data);
    } else if (action === "user_update") {
      if (data.action === "updateUserRights") {
        userRightStore().setRights(data.rights);
      } else if (data.action === "deleteMarketList") {
        watchlistStore().removeWatchlist(data.id);
      } else if (data.action === "getUserViews") {
        // TODO: reimplement if needed
      } else if (data.action === "getUserFavorites") {
        favoriteStore().loadFavorites(data.data);
      } else if (data.action === "updateUserTool") {
        const userTools = [];
        const store = userRightStore();
        const showPublishTools = store.isAdmin || store.hasRight(UserRightKey.ShowPublicUserTool);
        for (const update of data.data.userTools) {
          if (showPublishTools || !update.userTool.isPublic) {
            const userTool = new UserToolConverter().toModelObject(update.userTool);
            userTools.push(userTool);
          }
        }
        chartObjectStore().updateUserTool(userTools);
      } else if (data.action === "updateNewChatMessage") {
        const chat = chatStore().getChat(data.data.chatId);
        if (chat != null) {
          const chatMessage = new ChatMessageConverter(chat).toModelObject(data.data.message);
          chatStore().addMessage(chatMessage);
        }
      } else if (data.action === "updateSeenChatMessage") {
        const chat = chatStore().getChat(data.data.chatId);
        if (chat != null) {
          chat.setLastSeenMessageId(data.data.messageId);
        }
      } else if (data.action === "updateDeleteChatMessage") {
        const chat = chatStore().getChat(data.data.chatId);
        const chatMessage = chat?.getMessage(data.data.messageId);
        if (chat != null && chatMessage != null) {
          chat.removeMessage(chatMessage);
        }
      } else if (data.action === "deleteUserTool") {
        chartObjectStore().onUserToolDelete(data.id);
      } else if (data.action === "updateSymbolCategories") {
        watchlistStore().setCategories(data.data);
      } else if (data.action === "updateNotification") {
        notificationStore().showNotification(data.data.title, data.data.message);
      } else if (data.action === "updateAlerts") {
        for (const alertResponse of data.data.alerts) {
          const alert = new AlertConverter().toModelObject(alertResponse);
          if (alert.state === AlertState.Deleted) {
            alertStore().onAlertDeleted(alert);
          } else {
            alertStore().addAlert(alert);
          }
        }
      } else if (data.action === "setMarketListItems") {
        for (const item of data.data.items) {
          watchlistStore().addItem(data.data.id, item.symbol, item.sortIndex);
        }
      } else if (data.action === "updateSettings") {
        const excludedOptions = new Set([OptionName.SettingsMode]);
        if (!excludedOptions.has(data.data.name)) {
          chartOptionStore().applyOption(data.data.name, data.data.value);
        }
      } else {
        console.error("Unhandled action: " + action + " -> " + data.action);
      }
    } else if (action === "setMarketList") {
      const watchlist = new Watchlist(data.id, data.description, WatchlistType.User);
      watchlistStore().addWatchlist(watchlist);
    } else if (action === "updateMarketListDetail") {
      watchlistStore().handleUpdateMarketListDetail(data);
    } else if (action === "deleteMarketListDetail") {
      watchlistStore().removeItem(data.id, data.symbol);
    } else if (action === "resHeartbeat") {
      super.onHeartbeatResponse();
    } else {
      console.error("Unhandled action: " + action);
    }
    const requestId = message.requestId;
    const request = requestId == null ? null : this.requestCallbackMap.get(requestId);
    if (request != null) {
      request.callback(data);
      this.requestCallbackMap.delete(requestId);
    }
  }

  protected override getBaseUrl() {
    return getEnvironment().websocketUrl;
  }
}

import { Timeframe } from "@/anfin-chart/time/timeframe";
import { ApiModelConverter } from "@/api/messages/converter";
import type {
  CartProductResponse,
  CartResponse,
  ProductResponse,
  ShoppingOrderResponse,
  SubscriptionResponse
} from "@/api/messages/payments";

export class ShoppingCart {

  constructor(public readonly id: string,
              public readonly items: ShoppingCartItem[]) {
  }
}

export class ShoppingCartItem {

  constructor(public readonly productId: string,
              public variantId: string) {
  }
}

export class ShoppingProduct {

  public static readonly anfinBasic = "anfin#basic";
  public static readonly anfinPro = "anfin#pro";
  public static readonly anfinUltra = "anfin#ultra";

  public readonly offerValidUntil = 0;

  constructor(public readonly id: string,
              public readonly key: string,
              public readonly name: string,
              public readonly description: string,
              public readonly isActive: boolean,
              public readonly variants: ShoppingProductVariant[],
              public readonly images: string[],
              public readonly defaultVariant: ShoppingProductVariant) {
  }

  public get activeVariants() {
    return this.variants.filter(v => v.isActive);
  }
}

export class ShoppingProductVariant {

  public offerData: ShoppingProductOffer | null = null;

  constructor(public readonly id: string,
              public readonly description: string,
              public readonly isActive: boolean,
              public readonly fixedPrice: number,
              public readonly recurringPrice: number,
              public readonly feePeriod: Timeframe,
              public readonly contractPeriod: Timeframe) {
  }
}

export class ShoppingProductOffer {

  constructor(public readonly nextVariant: ShoppingProductVariant,
              public readonly validUntil: Date,
              public readonly autoNextStep: boolean) {
  }

}

export class ShoppingProductAbo {

  constructor(public readonly product: ShoppingProduct,
              public subscription: ShoppingSubscription | null,
              public amount = 0) {
  }

  public get isActive() {
    const cancelAt = this.subscription?.cancelAt;
    const active = this.subscription?.active;
    return this.subscription != null && active && (cancelAt == null || cancelAt === 0 || cancelAt * 1000 > Date.now());
  }
}

export class ShoppingSubscription {

  constructor(public readonly id: string,
              public readonly planVariantId: string,
              public readonly productId: string,
              public readonly amount: number,
              public readonly amountOff: number,
              public readonly currentPeriodStart: number,
              public readonly currentPeriodEnd: number,
              public readonly cancelAt: number,
              public readonly trialEnd: number,
              public readonly pendingDowngrade: number,
              public readonly active: boolean) {
  }
}

export class ShoppingOrderResult {

  constructor(public readonly response: ShoppingOrderResponse | null,
              public readonly error: string | null) {
  }
}

interface OfferDataUnresolved {
  nextVariantId: string;
  validUntil: number;
  autoNextStep: string;
}

export class ShoppingProductConverter extends ApiModelConverter<ShoppingProduct, ProductResponse> {

  public override toApiObject(product: ShoppingProduct): ProductResponse {
    throw new Error("Not implemented");
  }

  public override toModelObject(response: ProductResponse) {
    const variantMap = new Map<string, ShoppingProductVariant>();
    const nextVariantMap = new Map<ShoppingProductVariant, OfferDataUnresolved>();
    for (const price of response.prices) {
      const feePeriod = Timeframe.fromShortNotation(price.FeePeriod.Key);
      const contractPeriod = Timeframe.fromShortNotation(price.ContractPeriod.Key);
      const variant = new ShoppingProductVariant(
        price.Id, price.Description._c, price.active, price.OneOffPrice.PricePerUnit,
        price.RecurringPrice.PricePerUnit, feePeriod, contractPeriod
      );
      variantMap.set(variant.id, variant);
      const nextVariantId = price.NextPlanVariantId === "" ? null : price.NextPlanVariantId;
      if (nextVariantId != null) {
        if (price.offerValidUntil < Date.now()) {
          console.error("Offer needs validity date in future: " + variant.id);
        } else if (price.NextPlanId === price.PlanId) {
          nextVariantMap.set(variant, { nextVariantId, validUntil: price.offerValidUntil, autoNextStep: price.AfterFirstContractPeriodNextStep });
        } else {
          console.error("Next product must be identical with current product");
        }
      }
    }
    for (const [variant, offerData] of nextVariantMap.entries()) {
      const nextVariant = variantMap.get(offerData.nextVariantId);
      if (nextVariant == null) {
        console.error("Next variant could not be found: " + offerData.nextVariantId);
      } else {
        const validUntil = new Date(offerData.validUntil);
        variant.offerData = new ShoppingProductOffer(nextVariant, validUntil, offerData.autoNextStep === "ChangeAfterFirstPeriod");
      }
    }
    const variants = Array.from(variantMap.values());
    const defaultVariant = variantMap.get(response.defaultPrice) ?? variants[0];
    return new ShoppingProduct(response.id, response.key, response.name, response.description, response.active,
      variants, response.images, defaultVariant);
  }
}

export class ShoppingSubscriptionConverter extends ApiModelConverter<ShoppingSubscription, SubscriptionResponse> {

  public override toApiObject(subscription: ShoppingSubscription): SubscriptionResponse {
    throw new Error("Not implemented");
  }

  public override toModelObject(response: SubscriptionResponse) {
    const currentPeriodStart = new Date(response.StartDate);
    const currentPeriodEnd = new Date(response.BilledUntil);
    let cancelAt = 0;
    const active = response.LifecycleStatus === "Active";
    const now = new Date().getTime();
    for (const phase of response.Phases) {
      const date = new Date(phase.StartDate);
      if (date.getTime() > now && phase.Quantity === 0) {
        cancelAt = date.getTime();
      }
    }
    return new ShoppingSubscription(
      response.Id, response.PlanVariantId, response.PlanId, response.Balance, 0,
      currentPeriodStart.getTime(), currentPeriodEnd.getTime(), cancelAt, 0, 0, active
    );
  }
}

export class ShoppingCartConverter extends ApiModelConverter<ShoppingCart, CartResponse> {

  private readonly itemConverter = new ShoppingCartItemConverter();

  public override toApiObject(cart: ShoppingCart) {
    return {
      pToken: cart.id,
      products: cart.items.map(i => this.itemConverter.toApiObject(i))
    };
  }

  public override toModelObject(response: CartResponse) {
    const items = response.products.map(p => this.itemConverter.toModelObject(p));
    return new ShoppingCart(response.pToken, items);
  }
}

export class ShoppingCartItemConverter extends ApiModelConverter<ShoppingCartItem, CartProductResponse> {

  public override toApiObject(item: ShoppingCartItem) {
    return {
      planId: item.productId,
      planVariantId: item.variantId,
      planVariantsId: item.variantId
    };
  }

  public override toModelObject(response: CartProductResponse) {
    return new ShoppingCartItem(response.planId, response.planVariantId);
  }
}

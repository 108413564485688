import { ChartOptionConverter, type ChartOptionResponse } from "@/api/messages/chart-option";
import { ApiModelConverter } from "@/api/messages/converter";
import { IndicatorTemplate } from "@/anfin-chart/converter/indicator-converter";

export interface IndicatorResponse {
  type: string;
  subChartIndex: number | null;
  options: ChartOptionResponse[];
}

export class IndicatorConverter extends ApiModelConverter<IndicatorTemplate, IndicatorResponse> {

  private readonly optionConverter = new ChartOptionConverter();

  public override toApiObject(indicator: IndicatorTemplate) {
    return {
      type: indicator.type,
      subChartIndex: indicator.subChartIndex,
      options: indicator.options.map(o => this.optionConverter.toApiObject(o))
    };
  }

  public override toModelObject(indicator: IndicatorResponse) {
    return new IndicatorTemplate(
      indicator.type,
      indicator.subChartIndex,
      indicator.options.map(o => this.optionConverter.toModelObject(o))
    );
  }
}

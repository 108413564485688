<template>
  <IconElement
    :iconName="isAscending ? 'SortAscending' : 'SortDescending'" :size="16" class="table-sort-marker"
    :class="{ ascending: isAscending }"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconElement from "@/views/icons/IconElement.vue";

export default defineComponent({
  name: "TableSortMarker",

  components: { IconElement },

  props: {
    isAscending: {
      type: Boolean,
      required: true
    }
  },

  expose: []
});
</script>

<style scoped>
</style>
import { LineOptions, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { LineElement, TextBox } from "@/anfin-chart/drawable";
import { projectLineToX, Vector } from "@/anfin-chart/geometry";
import type { InstrumentData } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { ChartToolPoint, FixedPoint } from "@/anfin-chart/tools/tool-point";
import type { AutoHeadAndShoulders } from "@/api/models/analysis/auto-head-and-shoulders";
import { AnalysisTool } from "@/anfin-chart/tools/analysis-tool";

export class AutoHeadAndShouldersTool extends AnalysisTool {

  private readonly neckStart: FixedPoint;
  private readonly neckEnd: FixedPoint;
  private readonly leftShoulder = new ChartToolPoint();
  private readonly head = new ChartToolPoint();
  private readonly rightShoulder = new ChartToolPoint();
  private readonly neckEndProjected = new ChartToolPoint();
  private readonly isInverse: boolean;

  constructor(public readonly definition: AutoHeadAndShoulders,
              instrumentData: InstrumentData,
              subChart: SubChart) {
    super(definition, instrumentData, subChart);
    this.neckStart = new FixedPoint(definition.neckStartTime, definition.neckStartPrice);
    this.neckEnd = new FixedPoint(definition.neckEndTime, definition.neckEndPrice);
    const headItem = instrumentData.mainPlot.store.getByTime(this.definition.headTime);
    this.isInverse = headItem != null && headItem.max < (this.definition.neckStartPrice + this.definition.neckEndPrice) / 2;
  }

  public getIsVisible() {
    return super.getIsVisible() && this.optionManager.showHeadAndShoulders.getValue();
  }

  protected override createDrawables() {
    const neckLine = new LineElement(this, this.neckStart, this.neckEndProjected, new LineOptions(3));
    let textAlignment: TextAlignment;
    if (this.isInverse) {
      textAlignment = new TextAlignment(new Vector(0, 1), -5);
    } else {
      textAlignment = new TextAlignment(new Vector(0, -1), 5);
    }
    const leftShoulderText = new TextBox(this, this.leftShoulder, textAlignment);
    const headText = new TextBox(this, this.head, textAlignment);
    const rightShoulderText = new TextBox(this, this.rightShoulder, textAlignment);
    this.onUpdate(() => {
      const textColor = this.colorOptions.autoHeadAndShouldersText.getValue();
      const shoulderText = this.chart.callbacks.getTranslation("auto_tool#head_and_shoulders#label_shoulder");
      neckLine.color = this.colorOptions.autoHeadAndShouldersNeck.getValue();
      leftShoulderText.color = textColor;
      leftShoulderText.setText(shoulderText);
      headText.color = textColor;
      headText.setText(this.chart.callbacks.getTranslation("auto_tool#head_and_shoulders#label_head"));
      rightShoulderText.color = textColor;
      rightShoulderText.setText(shoulderText);
    });
  }

  protected override updatePositionInternal() {
    this.updateFixedPoint(this.neckStart, this.neckEnd);
    const leftShoulder = this.calculatePoint(this.definition.leftShoulderTime, this.isInverse);
    this.updatePoint(this.leftShoulder, leftShoulder);
    const head = this.calculatePoint(this.definition.headTime, this.isInverse);
    this.updatePoint(this.head, head);
    const rightShoulder = this.calculatePoint(this.definition.rightShoulderTime, this.isInverse);
    this.updatePoint(this.rightShoulder, rightShoulder);
    const neckStart = this.getPosition(this.neckStart);
    const neckEnd = this.getPosition(this.neckEnd);
    const endX = this.getFutureProjectionX();
    const neckEndProjected = projectLineToX(neckStart, neckEnd, endX);
    this.updatePoint(this.neckEndProjected, neckEndProjected);
  }
}

<template>
  <a href="mailto:kontakt@anfin.com" class="link">
    kontakt@anfin.com
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContactLink",

  expose: []
});
</script>

<style scoped>
</style>
import { PolygonElement } from "@/anfin-chart/drawable";
import type { InstrumentData } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { ChartToolPoint, FixedPoint } from "@/anfin-chart/tools/tool-point";
import type { AutoPriceGap } from "@/api/models/analysis/auto-price-gap";
import { AnalysisTool } from "@/anfin-chart/tools/analysis-tool";
import { Point } from "@/anfin-chart/geometry";

export class AutoPriceGapTool extends AnalysisTool {

  private readonly startHigh: FixedPoint;
  private readonly startLow: FixedPoint;
  private readonly endHigh: FixedPoint;
  private readonly endLow: FixedPoint;

  private readonly lastHigh = new ChartToolPoint();
  private readonly lastLow = new ChartToolPoint();

  constructor(public readonly definition: AutoPriceGap,
              instrumentData: InstrumentData,
              subChart: SubChart) {
    super(definition, instrumentData, subChart);
    this.startHigh = new FixedPoint(this.definition.startTime, this.definition.high);
    this.startLow = new FixedPoint(this.definition.startTime, this.definition.low);
    this.endHigh = new FixedPoint(this.definition.until ?? 0, this.definition.high);
    this.endLow = new FixedPoint(this.definition.until ?? 0, this.definition.low);
  }

  public override getIsVisible() {
    return super.getIsVisible() && this.optionManager.showPriceGaps.getValue();
  }

  protected override createDrawables() {
    const endHigh = this.definition.until == null ? this.lastHigh : this.endHigh;
    const endLow = this.definition.until == null ? this.lastLow : this.endLow;
    const polygon = new PolygonElement(this, [this.startHigh, endHigh, endLow, this.startLow]);
    this.onUpdate(() => {
      polygon.areaColor = this.colorOptions.autoPriceGap.getValue();
    });
  }

  protected override updatePositionInternal() {
    this.updateFixedPoint(this.startHigh, this.startLow, this.endHigh, this.endLow);
    const timeAxis = this.chart.timeAxis;
    const lastBarX = timeAxis.getXForTime(timeAxis.getLastTime() ?? 0);
    const yHigh = this.getPosition(this.startHigh).y;
    const yLow = this.getPosition(this.startLow).y;
    const lastHigh = new Point(lastBarX, yHigh);
    const lastLow = new Point(lastBarX, yLow);
    this.updatePoint(this.lastHigh, lastHigh);
    this.updatePoint(this.lastLow, lastLow);
  }
}

import type { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import type { ChartLayout } from "@/api/models/chart-layout";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class MultiWindowTemplate {

  constructor(public id: number | null,
              public readonly name: string,
              public windows: ChartLayout[]) {
  }
}

export class ChartView {

  constructor(public id: number | null,
              public readonly name: string | null,
              public readonly instrument: Instrument,
              public readonly timeframe: Timeframe,
              public readonly tools: UserToolDefinition[]) {
  }
}

export class ChartObjectPreset {

  constructor(public id: number | null,
              public readonly name: string,
              public readonly type: string,
              public options: ChartOptionDefinition[]) {
  }
}

export class ToolPointTemplate {

  constructor(public readonly time: number,
              public readonly price: number) {
  }
}

<template>
  <PageWrapper>
    <div class="chat-mobile-wrapper">
      <ChatWindow :isShown="true" />
    </div>
  </PageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PageWrapper from "@/views/PageWrapper.vue";
import ChatWindow from "@/views/chat/ChatWindow.vue";

export default defineComponent({
  name: "ChatMobile",

  components: { ChatWindow, PageWrapper },

  expose: []
});
</script>

<style scoped>
.chat-mobile-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
<template>
  <div>
    <div class="chat-video-container">
      <iframe
        v-if="isVideoAllowed" ref="videoIframe" :src="video.getUrl()" class="chat-video"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
      ></iframe>
      <div v-else class="chat-video-disabled">
        <div class="chat-video-disabled-text">
          <div>
            {{ translationStore.getTranslation("chat#video_disabled") }}
          </div>
          <div class="mt-2">
            <a href="#" class="link font-bold" @click="openCookieBanner">
              {{ translationStore.getTranslation("open_cookie_banner") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="video.chapters" class="my-2 mx-1">
      <ChatMessageVideoChapter
        v-for="chapter in video.chapters" :chapter="chapter" @setVideoTime="setChapter(chapter)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ChatMessageVideoChapter from "@/views/chat/ChatMessageVideoChapter.vue";
import { ChatVideo, ChatVideoChapter } from "@/api/models/chat";
import { DynamicScriptMixin } from "@/mixins/DynamicScriptMixin";
import { translationStore } from "@/stores/translation-store";
import { cookieStore } from "@/stores/cookie-store";

export default defineComponent({
  name: "ChatMessageVideo",

  components: { ChatMessageVideoChapter },

  mixins: [DynamicScriptMixin],

  props: {
    video: {
      type: ChatVideo,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      isVideoAllowed: false,
      currentChapter: null as ChatVideoChapter | null,
      translationStore: translationStore()
    };
  },

  mounted() {
    window.addEventListener("ccm19EmbeddingAccepted", () => this.checkConsent());
    window.addEventListener("ccm19WidgetClosed", () => this.checkConsent());
    this.checkConsent();
  },

  methods: {
    checkConsent() {
      this.isVideoAllowed = CCM?.acceptedEmbeddings.some(e => e.name === "Vimeo");
      if (this.isVideoAllowed) {
        this.addDynamicScript("vimeo-script", "https://player.vimeo.com/api/player.js");
      }
    },

    openCookieBanner() {
      cookieStore().openCookieBanner(true);
    },

    setChapter(chapter: ChatVideoChapter) {
      if (chapter.time != null) {
        const iframe = this.$refs.videoIframe as HTMLIFrameElement;
        const player = new Vimeo.Player(iframe);
        player.setCurrentTime(chapter.time);
      }
    }
  }
});
</script>

<style scoped>
.chat-video-container {
  position: relative;
  padding: 56.25% 0 0 0;
}

.chat-video, .chat-video-disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chat-video-disabled {
  background-color: var(--background-neutral);
  border-radius: var(--border-radius-single);
}

.chat-video-disabled-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
</style>
import type { DataItem, PlotType } from "@/anfin-chart/plot";

export class PlotDataItem {

  constructor(public readonly baseItem: DataItem,
              public readonly x: number,
              public readonly values: number[]) {
  }
}

export class PlotData {

  public readonly items: PlotDataItem[] = [];

  constructor(public readonly plotType: PlotType) {
  }

  public addItem(baseItem: DataItem, x: number, values: number[]) {
    this.items.push(new PlotDataItem(baseItem, x, values));
  }
}

import { MarketDataWebSocketController } from "@/api/market-data-web-socket-controller";
import type { TickData } from "@/api/messages/tick-update";
import { WebSocketMessage } from "@/api/web-socket-api-controller";
import { generalStore } from "@/stores/general-store";

export class PriceAboHandler {

  private static readonly currentAbos = new Map<string, Set<PriceAboHandler>>();
  private static readonly currentPrices = new Map<string, TickData>();
  private static readonly marketDataWebSocketController = MarketDataWebSocketController.getInstance();

  private readonly clientAbos = new Set<string>();

  static {
    this.marketDataWebSocketController.setOnPriceCallback(this.handlePriceUpdate.bind(this));
    this.marketDataWebSocketController.setOnReconnectCallback(this.handleReconnect.bind(this));
  }

  constructor(private readonly onUpdate: (symbol: string, tickData: TickData) => void) {
  }

  private static handleReconnect() {
    const symbolsToRequest: string[] = [];
    this.currentAbos.forEach((value, key) => {
      if (value.size > 0) {
        symbolsToRequest.push(key);
      }
    });
    this.sendAboSymbols(symbolsToRequest);
  }

  private static addClientAbo(client: PriceAboHandler, symbols: Set<string>) {
    const symbolsToRequest: string[] = [];
    for (const symbol of symbols) {
      let clients = this.currentAbos.get(symbol);
      if (clients == null) {
        clients = new Set<PriceAboHandler>();
        this.currentAbos.set(symbol, clients);
      }
      if (!clients.has(client)) {
        clients.add(client);
        if (clients.size === 1) {
          symbolsToRequest.push(symbol);
        }
      }
      const tickData = this.currentPrices.get(symbol);
      if (tickData != null) {
        client.onUpdate(symbol, tickData);
      }
    }
    if (symbolsToRequest.length > 0) {
      this.sendAboSymbols(symbolsToRequest);
    }
  }

  private static removeClientAbo(client: PriceAboHandler, symbols: Set<string>) {
    const symbolsToRequest: string[] = [];
    for (const symbol of symbols) {
      const clients = this.currentAbos.get(symbol);
      if (clients != null) {
        clients.delete(client);
        if (clients.size === 0) {
          symbolsToRequest.push(symbol);
        }
      }
    }
    if (symbolsToRequest.length > 0) {
      this.marketDataWebSocketController.sendMessage(new WebSocketMessage("removePriceAbo", { symbols: symbolsToRequest }));
    }
  }

  private static sendAboSymbols(symbols: string[]) {
    generalStore().logDebug("send priceAbo " + symbols.length);
    // add temp asArray to be comp. with pushbox - remove after fe update
    this.marketDataWebSocketController.sendMessage(new WebSocketMessage("priceAbo", { symbols: symbols, asArray: true }));
  }

  private static handlePriceUpdate(symbol: string, tickData: TickData) {
    const prevPrice = this.currentPrices.get(symbol);
    if (prevPrice) {
      tickData.minuteVolume = tickData.volume;
      if (Math.trunc(tickData.timestamp / 60000) === Math.trunc(prevPrice.timestamp / 60000) && prevPrice.minuteVolume !== null && tickData.volume >= prevPrice.minuteVolume) {
        tickData.volume = tickData.volume - prevPrice.minuteVolume;
      }
    }
    this.currentPrices.set(symbol, tickData);
    const clients = this.currentAbos.get(symbol);
    clients?.forEach(value => {
      value.onUpdate(symbol, tickData);
    });
  }

  public getCurrentPrices(symbols: Set<string>) {
    for (const symbol of symbols) {
      const tickData = PriceAboHandler.currentPrices.get(symbol);
      if (tickData != null) {
        this.onUpdate(symbol, tickData);
      }
    }
  }

  public setAbos(symbols: Set<string>) {
    const remainingSymbols = new Set(this.clientAbos);
    const addedSymbols = new Set<string>();
    for (const symbol of symbols) {
      if (remainingSymbols.has(symbol)) {
        remainingSymbols.delete(symbol);
      } else {
        addedSymbols.add(symbol);
      }
    }
    PriceAboHandler.addClientAbo(this, addedSymbols);
    PriceAboHandler.removeClientAbo(this, remainingSymbols);
  }

  public removeAllAbos() {
    PriceAboHandler.removeClientAbo(this, this.clientAbos);
    this.clientAbos.clear();
  }
}


import { ExchangeInfo } from "@/anfin-chart/exchange";
import { RestApiController } from "@/api/rest-api-controller";
import type { ExchangesResponse } from "@/api/messages/exchanges";

export class ExchangeController extends RestApiController {

  private static instance: ExchangeController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new ExchangeController();
    }
    return this.instance;
  }

  public async requestExchangeInfos() {
    const response = await this.sendGetRequest<ExchangesResponse>("hiku/markets/exchanges");
    return this.extractExchangeInfos(response);
  }

  protected override getBaseUrl() {
    return this.environment.marketUrl;
  }

  private extractExchangeInfos(data: ExchangesResponse) {
    const exchangeInfos: ExchangeInfo[] = [];
    const items = data.data ?? [];
    for (const item of items) {
      const name = item.exchange;
      const [startHour, startMinute] = item.tradingInfo.startTrading.split(":");
      const startTime = Number(startHour) * 3600 + Number(startMinute) * 60;
      const [endHour, endMinute] = item.tradingInfo.endTrading.split(":");
      const endTime = Number(endHour) * 3600 + Number(endMinute) * 60;
      const timezone = item.tradingInfo.timeZone.identifier;
      const outsideHoursExchange = item.exchange + data.outsideHoursPostfix;
      const exchangeInfo = new ExchangeInfo(name, timezone, startTime, endTime, 0, 0, outsideHoursExchange);
      exchangeInfos.push(exchangeInfo);
    }
    return exchangeInfos;
  }
}

import { ChartArea } from "@/anfin-chart/area/chart-area";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { LineOptions } from "@/anfin-chart/draw/chart-drawer";
import { combineLatest } from "rxjs";
import { Point, Rectangle } from "@/anfin-chart/geometry";

export enum GridDisplay {
  None = 0,
  Horizontal = 1,
  Vertical = 2,
  All = 3
}

export class DrawingAreaWrapper extends ChartArea {

  public constructor(layer: ChartLayer) {
    super(layer);
  }

  public override initializeEvents() {
    const combinedObservable = combineLatest([
      this.chart.coreLayerArea.getPositionObservable(),
      this.chart.timeAxis.getPositionObservable(),
      this.chart.priceAxisWrapper.getPositionObservable()
    ]);
    this.subscribeOn(combinedObservable, () => this.resize());
  }

  protected override drawInternal() {
    this.drawGridLines();
  }

  protected override resizeInternal() {
    const generalAreaPosition = this.chart.coreLayerArea.getPosition();
    const priceAxisWrapperPosition = this.chart.priceAxisWrapper.getPosition();
    const timeAxisPosition = this.chart.timeAxis.getPosition();
    const xStart = generalAreaPosition.xStart;
    const xEnd = priceAxisWrapperPosition.xStart;
    const yStart = generalAreaPosition.yStart;
    const yEnd = timeAxisPosition.yStart;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private drawGridLines() {
    const gridDisplay = this.chart.optionManager.gridDisplay.getValue();
    if (gridDisplay !== GridDisplay.Vertical && gridDisplay !== GridDisplay.All) {
      return;
    }
    const position = this.getPosition();
    const timeAxis = this.chart.timeAxis;
    const color = this.chart.optionManager.chartColor.helperLine.getValue();
    for (const label of timeAxis.getVisibleLabels()) {
      const start = new Point(label.x, position.yStart);
      const end = new Point(label.x, position.yEnd);
      this.drawer.drawLine(start, end, color);
    }
  }
}

import { defineStore } from "pinia";
import type { Point } from "@/anfin-chart/geometry";
import type { Chart } from "@/anfin-chart/chart";
import type { Alert } from "@/api/models/alert";
import type { OHLCItem } from "@/anfin-chart/plot";
import type { AnalysisToolAlertHook, UserToolAlertHook } from "@/anfin-chart/tools/alert-hook";
import type { AlertDefinitionData } from "@/anfin-chart/tools/alert-tool";
import type { ChartObject } from "@/anfin-chart/options/option";
import type { ChatMessage } from "@/api/models/chat";
import { chartOptionStore } from "@/stores/chart-option-store";

export enum SettingsWindowMode {
  Chart = 0,
  Account = 1,
  Analysis = 2,
  Shortcut = 3,
  WatchlistOverview = 4,
  AlertOverview = 5,
  Debugger = 6,
  Chat = 7,
  None = 8
}

export class ContextMenuData {

  constructor(public readonly chart: Chart,
              public readonly position: Point,
              public readonly price: number | null,
              public readonly bar: OHLCItem | null,
              public readonly alertHooks: (UserToolAlertHook | AnalysisToolAlertHook)[],
              public readonly alertDatas: AlertDefinitionData[]) {
  }
}

export class ChartObjectPanelData {

  constructor(public readonly chartObject: ChartObject,
              public readonly position: Point) {
  }
}

export const uiStateStore = defineStore({
  id: "uiState",

  state() {
    return {
      isIndicatorDefinitionShown: false,
      contextMenuData: null as ContextMenuData | null,
      editedAlert: null as Alert | null,
      chartObjectPanelData: null as ChartObjectPanelData | null,
      editedMessage: null as ChatMessage | null
    };
  },

  getters: {
    settingsWindowMode() {
      return chartOptionStore().optionManager.settingsWindowMode.getValue();
    }
  },

  actions: {
    setSettingsWindowMode(mode: SettingsWindowMode) {
      chartOptionStore().optionManager.settingsWindowMode.setValue(mode);
    },

    toggleSettingsWindowMode(mode: SettingsWindowMode) {
      this.setSettingsWindowMode(this.settingsWindowMode === mode ? SettingsWindowMode.None : mode);
    },

    toggleIndicatorDefinition() {
      this.isIndicatorDefinitionShown = !this.isIndicatorDefinitionShown;
    },

    setEditedAlert(alert: Alert | null) {
      this.editedAlert = alert;
      this.setSettingsWindowMode(SettingsWindowMode.AlertOverview);
    }
  }
});

<template>
  <div class="category-icon-list">
    <WatchlistCategoryIcon
      v-for="category in categories" :key="category" :category="category" @click.stop="selectCategory(category)"
    />
  </div>
</template>

<script lang="ts">
import { SymbolCategory } from "@/api/models/symbol-category";
import WatchlistCategoryIcon from "@/views/watchlist/WatchlistCategoryIcon.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WatchlistCategoryIcons",

  components: { WatchlistCategoryIcon },

  props: {
    includeUnset: Boolean
  },

  emits: {
    selectCategory: (category: SymbolCategory) => category || true
  },

  expose: [],

  computed: {
    categories() {
      const categories = [
        SymbolCategory.Cat1, SymbolCategory.Cat2, SymbolCategory.Cat3, SymbolCategory.Cat4,
        SymbolCategory.Cat5, SymbolCategory.Cat6
      ];
      if (this.includeUnset) {
        categories.splice(0, 0, SymbolCategory.None);
      }
      return categories;
    }
  },

  methods: {
    selectCategory(category: SymbolCategory) {
      this.$emit("selectCategory", category);
    }
  }
});
</script>

<style scoped>
.category-icon-list {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
</style>

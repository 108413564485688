import { AnalysisToolAlertHook, ToolAlertHookProvider } from "@/anfin-chart/tools/alert-hook";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";

export class AnalysisToolDefinition {

  public readonly hookProvider = new ToolAlertHookProvider<AnalysisToolAlertHook>();

  constructor(public readonly id: string,
              public readonly type: AnalysisType,
              public readonly instrument: Instrument,
              public readonly timeframe: Timeframe,
              public readonly startTime: number) {
  }
}

export enum AnalysisType {
  TrendLine = 0,
  Channel = 1,
  DoubleExtreme = 2,
  Extreme = 3,
  AutoHorizontal = 4,
  AutoFibonacci = 5,
  PriceGap = 6,
  HeadAndShoulders = 7
}

import type { Instrument } from "@/anfin-chart/instrument";
import { ChartCursorType } from "@/anfin-chart/mouse-data";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import type { ToolSynchronization } from "@/anfin-chart/tool-synchronization";
import { UserToolConverter, type UserToolResponse, type UserToolResponseList } from "@/api/messages/user-tool";
import { RestApiController } from "@/api/rest-api-controller";
import { chartOptionStore } from "@/stores/chart-option-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { ToolbarItemData } from "@/views/toolbar/toolbar-item-data";
import { chartObjectStore } from "@/stores/chart-object-store";
import { favoriteStore } from "@/stores/favorite-store";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { userRightStore } from "@/stores/user-right-store";
import { UserRightKey } from "@/api/models/user-right";

export class InstrumentUserToolList {

  constructor(public readonly tools: UserToolDefinition[],
              public readonly sharedTimeframes: Timeframe[]) {
  }
}

export class UserToolController extends RestApiController {

  private static instance: UserToolController | null = null;

  private readonly converter = new UserToolConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new UserToolController();
    }
    return this.instance;
  }

  public async getUserTools(instrument: Instrument, timeframe: Timeframe, synchronization: ToolSynchronization) {
    const store = userRightStore();
    const allowPublicTools = store.isAdmin || store.hasRight(UserRightKey.ShowPublicUserTool);
    const parameterMap = new Map<string, string>();
    parameterMap.set("symbol", instrument.getSymbol());
    parameterMap.set("timeframe", timeframe.toShortNotation());
    parameterMap.set("requestType", synchronization.toString());
    const response = await this.sendGetRequest<UserToolResponseList>("user-tools/v2", parameterMap);
    const tools = response.userTools.map(t => this.converter.toModelObject(t)).filter(t => allowPublicTools || !t.isPublic);
    const sharedTimeframes = response.publishedTimeframes.map(t => Timeframe.fromShortNotation(t));
    return new InstrumentUserToolList(tools, sharedTimeframes);
  }

  public async getUserTool(id: number) {
    // TODO: adjust to get multiple ids (comma-separated) when optimizing server requests
    const response = await this.sendGetRequest<UserToolResponse[]>(`user-tools/${id}`);
    if (response.length === 0) {
      return null;
    }
    return this.converter.toModelObject(response[0]);
  }

  public async saveUserTool(tool: UserToolDefinition) {
    tool.changeDate = Date.now();
    const request = {
      symbol: tool.instrument.getSymbol(),
      timeframe: tool.timeframe.toShortNotation(),
      userTool: this.converter.toApiObject(tool)
    };
    return this.sendPostRequest<number>("user-tools", request);
  }

  public async deleteUserTool(tool: UserToolDefinition) {
    if (tool.id == null) {
      return -1;
    }
    const parameterMap = new Map<string, string>();
    parameterMap.set("symbol", tool.instrument.getSymbol());
    return this.sendDeleteRequest<number>(`user-tools/${tool.id}`, parameterMap);
  }

  public setDefaultMode() {
    multiChartStore().activeChart?.setDefaultMode();
  }

  public setChartCursor(owner: any, cursorType: ChartCursorType, icon: string) {
    owner.items[0].icon = icon;
    chartOptionStore().optionManager.cursorType.setValue(cursorType);
    owner.$parent?.$forceUpdate();
  }

  public setZoomMode() {
    multiChartStore().activeChart?.setZoomMode();
  }

  public createDefaultItems(owner: any, onlyUserTools: boolean) {
    const cursor = new ToolbarItemData("none", null, "Crosshair", () => this.setDefaultMode());
    cursor.setSelected(true);
    cursor.setSubItems([
      new ToolbarItemData("cursor_type#2", null, "Crosshair", () => this.setChartCursor(owner, ChartCursorType.Crosshair, "Crosshair")),
      new ToolbarItemData("cursor_type#1", null, "Point", () => this.setChartCursor(owner, ChartCursorType.Point, "Point")),
      new ToolbarItemData("cursor_type#0", null, "Dart", () => this.setChartCursor(owner, ChartCursorType.Dart, "Dart"))
    ]);
    const zoom = new ToolbarItemData("zoom", null, "ZoomIn", () => this.setZoomMode());
    const linesGroup = new ToolbarItemData("group_lines", null, "LinesToolGroup", null);
    linesGroup.setSubItems([
      this.createToolItem("line"),
      this.createToolItem("hline"),
      this.createToolItem("hsegment"),
      this.createToolItem("vline")
    ]);
    const channelsGroup = new ToolbarItemData("group_channels", "Channels", "TrendChannelTool", null);
    channelsGroup.setSubItems([
      this.createToolItem("trchannel"),
      this.createToolItem("hchannel"),
      this.createToolItem("pitch")
    ]);
    const patternsGroup = new ToolbarItemData("group_patterns", null, "PatternsToolGroup", null);
    patternsGroup.setSubItems([
      this.createToolItem("abcd"),
      this.createToolItem("xabcd"),
      this.createToolItem("has"),
      this.createToolItem("ell12345"),
      this.createToolItem("ellabc"),
      this.createToolItem("ellabcde"),
      this.createToolItem("ellwxy"),
      this.createToolItem("ellwxyxz")
    ]);
    const drawGroup = new ToolbarItemData("group_draw", null, "DrawTool", null);
    drawGroup.setSubItems([
      this.createToolItem("fhdraw"),
      this.createToolItem("arrow"),
      this.createToolItem("lstrip")
    ]);
    const formsGroup = new ToolbarItemData("group_forms", null, "RectangleTool", null);
    formsGroup.setSubItems([
      this.createToolItem("rect"),
      this.createToolItem("trect"),
      this.createToolItem("ellipse"),
      this.createToolItem("triangle")
    ]);
    const priceEarningsGroup = new ToolbarItemData("group_price_earnings", null, "PriceEarningsTool", null);
    priceEarningsGroup.setSubItems([
      this.createToolItem("per")
    ]);
    const textGroup = new ToolbarItemData("group_text", null, "TextTool", null);
    textGroup.setSubItems([
      this.createToolItem("text")
    ]);
    const priceRangeGroup = new ToolbarItemData("group_price_range", null, "PriceRangeTool", null);
    priceRangeGroup.setSubItems([
      this.createToolItem("prange")
    ]);
    const fibonacciGroup = new ToolbarItemData("group_fibonacci", null, "FiboTool", null);
    fibonacciGroup.setSubItems([
      this.createToolItem("fibo")
    ]);
    const toolItems = [
      linesGroup, channelsGroup, patternsGroup, fibonacciGroup, priceRangeGroup, textGroup, drawGroup, formsGroup,
      priceEarningsGroup
    ];
    if (onlyUserTools) {
      return toolItems;
    }
    return [cursor, ...toolItems, zoom];
  }

  protected override getBaseUrl() {
    return this.environment.configurationUrl;
  }

  private createToolItem(key: string) {
    const onSelect = () => multiChartStore().addTool(key);
    const itemKey = "user_tool#" + key;
    const icon = chartObjectStore().getToolIcon(key);
    const favoriteKey = favoriteStore().toolPrefix + key;
    return new ToolbarItemData(itemKey, null, icon, onSelect, null, null, favoriteKey);
  }
}

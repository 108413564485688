<template>
  <div class="product-price">
    <div v-if="variant.offerData != null" class="product-price-original">
      <ProductPriceFormatted :variant="variant.offerData.nextVariant" />
    </div>
    <div class="product-price-main text-gradient-gold my-1">
      <ProductPriceFormatted :variant="variant" />
    </div>
    <div v-if="variant.recurringPrice > 0" class="product-price-secondary">
      {{ translationStore.getTranslation("product#price_per_month") }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ShoppingProductVariant } from "@/api/models/shopping-cart";
import { translationStore } from "@/stores/translation-store";
import ProductPriceFormatted from "@/views/pages-landing/product/ProductPriceFormatted.vue";

export default defineComponent({
  name: "ProductPriceInformation",

  components: { ProductPriceFormatted },

  props: {
    variant: {
      type: ShoppingProductVariant,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  }
});
</script>

<style scoped>
.product-price {
  display: flex;
  flex-direction: column;
}

.product-price-main {
  font-size: 160%;
  font-weight: bold;
}

.product-price-secondary {
  color: var(--content-tertiary);
}

.product-price-original {
  font-size: 110%;
  font-weight: bold;
  color: var(--content-secondary);
  text-decoration: line-through;
}
</style>
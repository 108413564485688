<template>
  <div class="h-100">
    <div v-if="isAllowed" class="h-100">
      <div v-if="editedAlert == null" class="alert-overview">
        <SettingsWindowHeader>
          <div class="alert-overview-header">
            <div class="alert-overview-filter-text">
              {{ filterLabel }}
            </div>
            <IconElement iconName="Plus" :size="22" class="ms-auto" @click="createAlert" />
            <IconElement iconName="Filter" :size="20" class="ms-2" @click="toggleFilters" />
          </div>
          <div v-show="showFilters">
            <hr />
            <div>
              <OnOffSwitch
                :modelValue="showOnlyActiveInstruments" :showLabels="false"
                :caption="translationStore.getTranslation('alert_overview#show#active_instruments')"
                @update:modelValue="toggleShowOnlyActiveInstruments"
              />
            </div>
            <div class="d-flex justify-content-between mt-3">
              <OnOffSwitch
                :modelValue="showActive" :showLabels="false"
                :caption="translationStore.getTranslation('alert_overview#show#active')"
                @update:modelValue="toggleShowActive"
              />
              <OnOffSwitch
                :modelValue="showTriggered" :showLabels="false"
                :caption="translationStore.getTranslation('alert_overview#show#triggered')" class="ms-3"
                @update:modelValue="toggleShowTriggered"
              />
              <OnOffSwitch
                :modelValue="showInactive" :showLabels="false"
                :caption="translationStore.getTranslation('alert_overview#show#inactive')" class="ms-3"
                @update:modelValue="toggleShowInactive"
              />
            </div>
          </div>
        </SettingsWindowHeader>
        <div class="alert-overview-item-list p-1">
          <AlertOverviewItem v-for="alert in filteredAlerts" :key="alert.id ?? ''" :alert="alert" class="mb-1" />
        </div>
      </div>
      <AlertEditor v-else :alert="editedAlert" class="p-3" @close="stopEditing" />
    </div>
    <ComingSoon v-else featureKey="alerts" class="mt-4 text-center font-lg" />
  </div>
</template>

<script lang="ts">
import AlertOverviewItem from "@/views/alerts/AlertOverviewItem.vue";
import { defineComponent } from "vue";
import { processPlaceholders, translationStore } from "@/stores/translation-store";
import { Alert, AlertRule, AlertState, SimpleAlertCondition } from "@/api/models/alert";
import ComingSoon from "@/views/components/ComingSoon.vue";
import { storeToRefs } from "pinia";
import { alertStore } from "@/stores/alert-store";
import { userRightStore } from "@/stores/user-right-store";
import { UserRightKey } from "@/api/models/user-right";
import OnOffSwitch from "@/views/components/OnOffSwitch.vue";
import SettingsWindowHeader from "@/views/settings/SettingsWindowHeader.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { multiChartStore } from "@/stores/multi-chart-store";
import AlertEditor from "@/views/alerts/AlertEditor.vue";
import { uiStateStore } from "@/stores/ui-state-store";
import type { Chart } from "@/anfin-chart/chart";
import { chartOptionStore } from "@/stores/chart-option-store";

export default defineComponent({
  name: "AlertOverview",

  components: {
    AlertEditor, IconElement, SettingsWindowHeader, OnOffSwitch, ComingSoon, AlertOverviewItem
  },

  emits: {
    hide: () => true
  },

  expose: [],

  data() {
    const { alerts } = storeToRefs(alertStore());
    const { isAdmin } = storeToRefs(userRightStore());
    const { editedAlert } = storeToRefs(uiStateStore());
    return {
      translationStore: translationStore(),
      alerts: alerts as unknown as Alert[],
      isAdmin: isAdmin as unknown as boolean,
      editedAlert: editedAlert as unknown as Alert | null,
      showFilters: false,
      activeStates: [AlertState.New, AlertState.Pending, AlertState.Active]
    };
  },

  computed: {
    isAllowed() {
      return userRightStore().hasRight(UserRightKey.Alerts);
    },

    shownStates() {
      return new Set(chartOptionStore().optionManager.alert.shownStates.getValue());
    },

    showActive() {
      return this.activeStates.some(s => this.shownStates.has(s));
    },

    showTriggered() {
      return this.shownStates.has(AlertState.Triggered);
    },

    showInactive() {
      return this.shownStates.has(AlertState.Inactive);
    },

    showOnlyActiveInstruments() {
      return chartOptionStore().optionManager.alert.showOnlyActiveInstruments.getValue();
    },

    filteredAlerts() {
      let filteredAlerts = this.alerts.filter(a => this.shownStates.has(a.state));
      if (this.showOnlyActiveInstruments) {
        const symbols = new Set<string>();
        for (const chart of multiChartStore().getChartExports()) {
          for (const instrumentExport of chart.subCharts.map(s => s.instruments).flat()) {
            symbols.add(instrumentExport.instrument.getSymbol());
          }
        }
        filteredAlerts = filteredAlerts.filter(
          a => a.rules.some(r => symbols.has(r.instrument.getSymbol()))
        );
      }
      return filteredAlerts;
    },

    filterLabel() {
      const translation = this.translationStore.getTranslation("alert_overview#filter_text");
      const data = { visibleCount: this.filteredAlerts.length.toString(), totalCount: this.alerts.length.toString() };
      return processPlaceholders(translation, data);
    }
  },

  methods: {
    createAlert() {
      const activeChart = multiChartStore().activeChart;
      const rule = AlertRule.createDefault(activeChart as Chart);
      const condition = new SimpleAlertCondition(0);
      const alert = new Alert(null, "", [rule], condition);
      uiStateStore().setEditedAlert(alert);
    },

    stopEditing() {
      uiStateStore().setEditedAlert(null);
    },

    toggleFilters() {
      this.showFilters = !this.showFilters;
    },

    toggleShowActive() {
      this.toggleStateFilter(this.activeStates, this.showActive);
    },

    toggleShowTriggered() {
      this.toggleStateFilter([AlertState.Triggered], this.showTriggered);
    },

    toggleShowInactive() {
      this.toggleStateFilter([AlertState.Inactive], this.showInactive);
    },

    toggleStateFilter(states: AlertState[], isCurrentActive: boolean) {
      const shownStates = this.shownStates;
      for (const state of states) {
        if (isCurrentActive) {
          shownStates.delete(state);
        } else {
          shownStates.add(state);
        }
      }
      const option = chartOptionStore().optionManager.alert.shownStates;
      option.setValue(Array.from(shownStates));
    },

    toggleShowOnlyActiveInstruments() {
      const option = chartOptionStore().optionManager.alert.showOnlyActiveInstruments;
      option.setValue(!this.showOnlyActiveInstruments);
    }
  }
});
</script>

<style scoped>
.alert-overview {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.alert-overview-item-list {
  overflow-y: auto;
  overflow-x: hidden;
}

.alert-overview-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.alert-overview-filter-text {
  color: var(--content-tertiary);
}
</style>


<template>
  <div>
    <div v-if="!isWithoutText">
      {{ index + 1 }}.
      {{ translationStore.getTranslation("exchange_inquiry#professional_user#question_" + question.id) }}
    </div>
    <div class="exchange-inquiry-answer mt-1">
      <div class="me-5">
        {{ translationStore.getTranslation("exchange_inquiry#professional_user#is_applicable") }}
      </div>
      <div class="form-check me-3">
        <input
          :id="idYes" v-model="question.isChecked" :name="radioGroupName" type="radio" :value="true"
          class="form-check-input"
        />
        <label class="form-check-label" :for="idYes">
          {{ translationStore.getTranslation("exchange_inquiry#professional_user#option_yes") }}
        </label>
      </div>
      <div class="form-check">
        <input
          :id="idNo" v-model="question.isChecked" :name="radioGroupName" type="radio" :value="false"
          class="form-check-input"
        />
        <label class="form-check-label" :for="idNo">
          {{ translationStore.getTranslation("exchange_inquiry#professional_user#option_no") }}
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { translationStore } from "@/stores/translation-store";
import { defineComponent } from "vue";

export class ExchangeInquiryQuestionData {

  constructor(public readonly id: number,
              public readonly isChecked: boolean | null) {
  }
}

export default defineComponent({
  name: "ExchangeInquiryQuestion",

  props: {
    question: {
      type: ExchangeInquiryQuestionData,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isWithoutText: Boolean
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    radioGroupName() {
      return "exchange-inquiry-" + this.question.id;
    },

    idYes() {
      return this.radioGroupName + "-yes";
    },

    idNo() {
      return this.radioGroupName + "-no";
    }
  }
});
</script>

<style scoped>
.exchange-inquiry-answer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
</style>

<template>
  <div class="timeframe-selector">
    <span
      v-for="timeframe in selectableTimeframes" :key="timeframe.toShortNotation()"
      class="timeframe hint--bottom" :class="{ active: Timeframe.isSame(currentTimeframe, timeframe) }"
      :aria-label="translationStore.getTimeframe(timeframe, 'long')"
      @click="setTimeframe(timeframe)"
    >
      {{ translationStore.getTimeframe(timeframe, "short") }}
    </span>
    <ChartTimeframeSelector />
  </div>
</template>

<script lang="ts">
import { favoriteStore } from "@/stores/favorite-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { translationStore } from "@/stores/translation-store";
import ChartTimeframeSelector from "@/views/toolbar/ChartTimeframeSelector.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import { Timeframe } from "@/anfin-chart/time/timeframe";

export default defineComponent({
  name: "TimeframeSelector",

  components: { ChartTimeframeSelector },

  expose: [],

  data() {
    const { timeframe: currentTimeframe } = storeToRefs(multiChartStore());
    const { timeframes: favoriteTimeframes } = storeToRefs(favoriteStore());
    return {
      favoriteTimeframes: favoriteTimeframes as unknown as Timeframe[],
      currentTimeframe: currentTimeframe as unknown as Timeframe,
      translationStore: translationStore(),
      Timeframe
    };
  },

  computed: {
    selectableTimeframes() {
      const timeframes = this.favoriteTimeframes.slice();
      if (this.favoriteTimeframes.every(t => !Timeframe.isSame(t, this.currentTimeframe))) {
        timeframes.push(this.currentTimeframe);
      }
      return timeframes.sort(Timeframe.compare);
    }
  },

  methods: {
    setTimeframe(timeframe: Timeframe) {
      multiChartStore().setTimeframe(timeframe);
    }
  }
});
</script>

<style>
.timeframe-selector {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 0;
  color: var(--content-secondary);
}

.timeframe {
  margin: 0 1px;
  padding: 2px 1px;
  width: 2.5em;
  user-select: none;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid rgba(148, 147, 141, 0.0);
}

.timeframe.active {
  font-weight: 500;
}

.timeframe.active, .timeframe:hover  {
  background-color: var(--background-overlay);
  border: 1px solid var(--border-overlay);
  color: var(--content-primary);
}

@media screen and (max-width: 940px) {
  .timeframe-selector {
    margin-left: auto;
  }
}

@media screen and (max-width: 830px) {
  .timeframe-selector > .timeframe:not(.active) {
    display: none;
  }
}
</style>

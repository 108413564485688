import { defineStore } from "pinia";
import { userRightStore } from "@/stores/user-right-store";
import {
  NotificationChannel,
  NotificationChannelType,
  NotificationSound,
  NotificationSoundType
} from "@/api/models/notification";
import { UserSettingsController } from "@/api/user-settings-controller";
import { toastStore, ToastType } from "@/stores/toast-store";

export const notificationStore = defineStore({
  id: "notification",

  state() {
    const hasDesktopPermission = typeof Notification === "undefined" ? false : Notification.permission === "granted";
    const sounds = [
      new NotificationSound(NotificationSoundType.NoSound),
      new NotificationSound(NotificationSoundType.Bell, "../../static/notification-bell.wav"),
      new NotificationSound(NotificationSoundType.Pop, "../../static/notification-pop.wav")
    ];
    const selectedSound: NotificationSound = sounds[1];
    return {
      hasDesktopPermission,
      isDesktopEnabled: false,
      sounds,
      selectedSound
    };
  },

  actions: {
    async checkPermission() {
      if (typeof Notification !== "undefined") {
        const permission = await Notification.requestPermission();
        this.hasDesktopPermission = permission === "granted";
      }
    },

    setEmailEnabled(isEnabled: boolean) {
      const email = userRightStore().userInfo?.email ?? "";
      const notificationChannel = new NotificationChannel(NotificationChannelType.Email, email, isEnabled, true, "Email");
      UserSettingsController.getInstance().saveNotificationSettings(notificationChannel);
    },

    setDesktopEnabled(isEnabled: boolean) {
      if (isEnabled) {
        notificationStore().checkPermission();
      }
      this.isDesktopEnabled = isEnabled;
      const notificationChannel = new NotificationChannel(NotificationChannelType.Desktop, "", isEnabled, true, "Desktop Notification");
      UserSettingsController.getInstance().saveNotificationSettings(notificationChannel);
    },

    setSelectedSound(type: NotificationSoundType) {
      this.selectedSound = this.sounds.find(s => s.type === type) ?? this.selectedSound;
    },

    playSelectedSound() {
      if (this.selectedSound.audio) {
        this.selectedSound.audio.pause();
        this.selectedSound.audio.play().then();
      }
    },

    async showNotification(title: string, message: string) {
      if (this.isDesktopEnabled) {
        await this.checkPermission();
        if (this.hasDesktopPermission) {
          new Notification(title, { body: message, icon: "/favicon.svg" });
        }
        toastStore().addToast(ToastType.Alert, "toast#alert_triggered", { message });
      }
    }
  }
});

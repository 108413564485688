<template>
  <div>
    <div>
      <label class="form-label">{{ translationStore.getTranslation("indicator_definition#label_name") }}</label>
      <input v-model="indicatorDefinition.name" type="text" class="form-control form-control-sm input-w-medium" />
    </div>
    <div>
      <label class="form-label mt-2">{{ translationStore.getTranslation("indicator_definition#label_type") }}</label>
      <input v-model="indicatorDefinition.type" type="text" class="form-control form-control-sm input-w-medium" />
    </div>
    <div class="form-check mt-2">
      <input id="is-new-sub-chart" v-model="indicatorDefinition.isNewSubChart" type="checkbox" class="form-check-input" />
      <label class="form-check-label" for="is-new-sub-chart">
        {{ translationStore.getTranslation("indicator_definition#label_new_subchart") }}
      </label>
    </div>
    <label class="font-bold font-lg mt-2">
      {{ translationStore.getTranslation("indicator_definition#caption_options") }}
    </label>
    <div class="card-set-container mt-2">
      <IndicatorDefinitionOption
        v-for="(option, optionIndex) in indicatorDefinition.options" :key="optionIndex" :option="option"
        @remove="removeOption(optionIndex)"
      />
    </div>
    <button class="btn btn-primary text-nowrap mt-2" type="button" @click="createOption">
      <span>
        <IconElement :size="14" iconName="Plus" />
      </span>
      {{ translationStore.getTranslation("indicator_definition#button_create_option") }}
    </button>
  </div>
</template>

<script lang="ts">
import { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import { IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import { OptionValueType } from "@/anfin-chart/options/option";
import { translationStore } from "@/stores/translation-store";
import IconElement from "@/views/icons/IconElement.vue";
import IndicatorDefinitionOption from "@/views/indicator-definition/IndicatorDefinitionOption.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IndicatorDefinitionGeneral",

  components: { IndicatorDefinitionOption, IconElement },

  props: {
    indicatorDefinition: {
      type: IndicatorDefinition,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  methods: {
    createOption() {
      const name = this.translationStore.getTranslation("indicator_definition#default_option_name");
      const option = new ChartOptionDefinition(name, OptionValueType.Numeric, 0);
      this.indicatorDefinition.options.push(option);
    },

    removeOption(index: number) {
      this.indicatorDefinition.options.splice(index, 1);
    }
  }
});
</script>

<style scoped>
</style>

<template>
  <PageWrapper
    id="multi-chart" class="multi-chart font-anfin"
    :class="{
      'grid-show-settings': settingsWindowMode !== SettingsWindowMode.None,
      'app-content': isMobile
    }"
  >
    <ChartTopToolbar />
    <LeftToolbar
      v-if="!isMobile || !isMobile && !isSmartPhone" id="multi-chart-left" class="innermainbox"
    />
    <TopSearchbar v-if="!isMobile" id="multi-chart-search" />

    <RightToolbar v-if="!isMobile" id="multi-chart-menu-right-buttons" />

    <div v-if="!isMobile" id="multi-chart-main" class="mainbox">
      <div class="flex-grow-1">
        <div id="multi-chart-grid">
          <AnfinChart
            v-for="(layoutItem, layoutItemIndex) in chartLayout.items" :key="layoutItemIndex"
            :style="getLayoutItemStyle(layoutItem)"
          />
          <ChartObjectPanel :container="editPanelContainer" />
        </div>
        <ChartContextMenu />
      </div>
      <IndicatorDefinition v-show="isIndicatorDefinitionShown" id="multi-chart-indicator-def" />
    </div>

    <FavoritePanel v-if="!isMobile" :container="favoritePanelContainer" />
    <SettingsWindow id="multi-chart-settings" :allowHide="true" @hide="hideSettingsWindow" />
  </PageWrapper>
</template>

<script lang="ts">
import { Instrument } from "@/anfin-chart/instrument";
import { multiChartStore } from "@/stores/multi-chart-store";
import { userRightStore } from "@/stores/user-right-store";
import { ChartHandler } from "@/utils/ChartHandler";
import AnfinChart from "@/views/components/AnfinChart.vue";
import ChartContextMenu from "@/views/components/ChartContextMenu.vue";
import IndicatorDefinition from "@/views/indicator-definition/IndicatorDefinition.vue";
import PageWrapper from "@/views/PageWrapper.vue";
import ChartTopToolbar from "@/views/toolbar/ChartTopToolbar.vue";
import SettingsWindow from "@/views/settings/SettingsWindow.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import LeftToolbar from "../toolbar/LeftToolbar.vue";
import TopSearchbar from "../toolbar/TopSearchbar.vue";
import FavoritePanel from "@/views/panels/FavoritePanel.vue";
import { translationStore } from "@/stores/translation-store";
import type { ChartLayoutDefinition, ChartLayoutItem } from "@/api/models/chart-layout";
import { SettingsWindowMode, uiStateStore } from "@/stores/ui-state-store";
import RightToolbar from "@/views/toolbar/RightToolbar.vue";
import { DraggablePanelContainer } from "@/views/panels/DraggablePanel.vue";
import ChartObjectPanel from "@/views/panels/ChartObjectPanel.vue";

export default defineComponent({
  name: "ChartPage",

  components: {
    ChartObjectPanel, RightToolbar, PageWrapper, ChartTopToolbar, ChartContextMenu, FavoritePanel, AnfinChart,
    TopSearchbar, IndicatorDefinition, SettingsWindow, LeftToolbar
  },

  expose: [],

  data() {
    const chartHandler = ChartHandler.getInstance();
    const { currentLayoutDefinition } = storeToRefs(multiChartStore());
    const { isMobile, isSmartPhone, isAdmin } = storeToRefs(userRightStore());
    const { settingsWindowMode, isIndicatorDefinitionShown } = storeToRefs(uiStateStore());
    return {
      chartHandler,
      translationStore: translationStore(),
      chartLayout: currentLayoutDefinition as unknown as ChartLayoutDefinition,
      settingsWindowMode: settingsWindowMode as unknown as SettingsWindowMode,
      editPanelContainer: new DraggablePanelContainer("#multi-chart-main", "#multi-chart"),
      favoritePanelContainer: new DraggablePanelContainer("#multi-chart", "#multi-chart"),
      isIndicatorDefinitionShown: isIndicatorDefinitionShown as unknown as boolean,
      isRightMenuVisible: false,
      isDebuggerMode: false,
      isMarketViewMode: false,
      isAlertViewMode: false,
      isAdmin: isAdmin as unknown as boolean,
      isMobile: isMobile as unknown as boolean,
      isSmartPhone: isSmartPhone as unknown as boolean,
      SettingsWindowMode
    };
  },

  mounted() {
    window.addEventListener("message", this.onMessage);
    window.addEventListener("click", (e) => {
      if (e.buttons === 2) {
        e.preventDefault();
      }
    }, false);
  },

  methods: {
    getLayoutItemStyle(layoutItem: ChartLayoutItem) {
      const endRow = layoutItem.endRow + 1;
      const endColumn = layoutItem.endColumn + 1;
      return {
        "grid-row": layoutItem.startRow + " / " + endRow,
        "grid-column": layoutItem.startColumn + " / " + endColumn
      };
    },

    onMessage(messageInfo: any) {
      if (messageInfo?.type === "message") {
        if (messageInfo?.data?.action === "addIndicator" && messageInfo?.data?.indicator) {
          multiChartStore().activeChart?.addIndicator(messageInfo?.data?.indicator, messageInfo?.data?.options);
        } else if (messageInfo?.data?.action === "addTool" && messageInfo?.data?.tool) {
          const type = messageInfo?.data?.tool;
          const options = messageInfo?.data?.options;
          multiChartStore().addTool(type, options);
        } else if (messageInfo?.data?.action === "changeSymbol" && messageInfo?.data?.symbol) {
          multiChartStore().changeInstrument(Instrument.fromSymbol(messageInfo?.data?.symbol));
        }
      }
    },

    hideSettingsWindow() {
      uiStateStore().setSettingsWindowMode(SettingsWindowMode.None);
    }
  }
});
</script>

<style>
.mainbox {
  display: flex;
  flex-direction: column;
}

#multi-chart {
  background: var(--background-screen);
}

#multi-chart-grid {
  position: relative;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  height: 100%;
}

#multi-chart-main {
  position: relative;
}

body {
  margin: 0;
  color: #e1e1e1;
}

svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 10;
  stroke: #00a1ff;
  stroke-linecap: round;
  transform: translate(5px,35px);
  stroke-dasharray: 150;
  stroke-dashoffset: 250;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0%,100% {
    stroke-dashoffset: 440;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 880;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

import { Vector } from "@/anfin-chart/geometry";
import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import type { ChartToolPoint, FixedPoint } from "@/anfin-chart/tools/tool-point";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";

export function alignAvoid(tool: ChartTool, anchor: ChartToolPoint, ...avoid: (ChartToolPoint | null)[]) {
  const mergedVector = new Vector(0, 0);
  const anchorPosition = tool.getPosition(anchor);
  for (const point of avoid) {
    if (point == null) {
      continue;
    }
    const pointPosition = tool.getPosition(point);
    const vector = Vector.from(anchorPosition, pointPosition);
    mergedVector.x -= vector.x;
    mergedVector.y -= vector.y;
  }
  return new TextAlignment(mergedVector, 5);
}

export function alignAboveBeyond(anchor: FixedPoint, base: FixedPoint) {
  const y = anchor.price > base.price ? -1 : 1;
  return new TextAlignment(new Vector(0, y), 5);
}

export function alignTriangle(tool: ChartTool, first: ChartToolPoint, second: ChartToolPoint, third: ChartToolPoint) {
  const firstPosition = tool.getPosition(first);
  const secondPosition = tool.getPosition(second);
  const thirdPosition = tool.getPosition(third);
  const diffX = secondPosition.x - firstPosition.x;
  const diffY = secondPosition.y - firstPosition.y;
  const product = diffY * (thirdPosition.x - firstPosition.x) - diffX * (thirdPosition.y - firstPosition.y);
  const vector = product > 0 ? new Vector(-diffY, diffX) : new Vector(diffY, -diffX);
  return new TextAlignment(vector, 5);
}

import { LowerActionArea, UpperActionArea } from "@/anfin-chart/area/action-area";
import { CaptionArea } from "@/anfin-chart/area/caption-area";
import { DrawingArea } from "@/anfin-chart/area/drawing-area";
import { SubChartHintArea } from "@/anfin-chart/area/hint-area";
import { PriceAxis } from "@/anfin-chart/area/price-axis";
import { ResizeArea } from "@/anfin-chart/area/resize-area";
import { SubChartMainArea } from "@/anfin-chart/area/sub-chart-main-area";
import { AlertToolArea, ToolDrawingArea } from "@/anfin-chart/area/tool-drawing-area";
import type { Chart } from "@/anfin-chart/chart";
import type { ChartHint } from "@/anfin-chart/hints/chart-hint";
import type { IndicatorData } from "@/anfin-chart/indicator-data";
import type { Plot, PlotProvider } from "@/anfin-chart/plot";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";
import { BehaviorSubject, distinctUntilChanged, startWith } from "rxjs";

export class SubChart {

  public mainArea: SubChartMainArea;
  public priceAxis: PriceAxis;
  public drawingArea: DrawingArea;
  public captionArea: CaptionArea;
  public resizeArea: ResizeArea;
  public toolDrawingArea: ToolDrawingArea;
  public alertToolArea: AlertToolArea;
  public hintArea: SubChartHintArea;
  public upperActionArea: UpperActionArea;
  public lowerActionArea: LowerActionArea;

  private readonly indicatorDatas = new BehaviorSubject<IndicatorData[]>([]);
  private readonly isExpanded = new BehaviorSubject(true);
  private readonly index = new BehaviorSubject(0);
  private readonly isIndicatorsExpanded = new BehaviorSubject(true);
  private expandedPercentage = 0;

  constructor(public readonly chart: Chart) {
    this.mainArea = new SubChartMainArea(chart.coreLayer, this);
    this.priceAxis = new PriceAxis(chart.coreLayer, this);
    this.drawingArea = new DrawingArea(chart.coreLayer, this);
    this.captionArea = new CaptionArea(chart.overlayLayer, this);
    this.resizeArea = new ResizeArea(chart.overlayLayer, this);
    this.toolDrawingArea = new ToolDrawingArea(chart.toolLayer, this);
    this.alertToolArea = new AlertToolArea(chart.toolLayer, this);
    this.hintArea = new SubChartHintArea(chart.overlayLayer, this);
    this.upperActionArea = new UpperActionArea(chart.overlayLayer, this);
    this.lowerActionArea = new LowerActionArea(chart.overlayLayer, this);
    this.mainArea.initializeEvents();
    this.priceAxis.initializeEvents();
    this.drawingArea.initializeEvents();
    this.captionArea.initializeEvents();
    this.resizeArea.initializeEvents();
    this.toolDrawingArea.initializeEvents();
    this.alertToolArea.initializeEvents();
    this.hintArea.initializeEvents();
    this.upperActionArea.initializeEvents();
    this.lowerActionArea.initializeEvents();

    this.getIsIndicatorsExpandedObservable().subscribe(() => {
      this.chart.callbacks.saveExport();
    });
  }

  public get isPrimary() {
    return this.chart.primarySubChart === this;
  }

  public getIndex() {
    return this.index.value;
  }

  public getIndexObservable() {
    return this.index.pipe(distinctUntilChanged(), startWith(this.index.value));
  }

  public setIndex(index: number) {
    this.index.next(index);
  }

  public getIsExpanded() {
    return this.isExpanded.value;
  }

  public getIsExpandedObservable() {
    return this.isExpanded.pipe(distinctUntilChanged(), startWith(this.isExpanded.value));
  }

  public setIsExpanded(isExpanded: boolean) {
    if (this.isExpanded.value === isExpanded) {
      return;
    }
    this.isExpanded.next(isExpanded);
    const subCharts = this.chart.getSubCharts();
    const totalHeight = subCharts.reduce((previousValue, s) => previousValue + s.getHeight(), 0);
    let height: number;
    if (isExpanded) {
      height = this.expandedPercentage * totalHeight / (1 - this.expandedPercentage);
    } else {
      this.expandedPercentage = this.getHeight() / totalHeight;
      height = this.chart.styleOptions.subChartCollapsedHeight.getValue();
    }
    this.setHeight(height);
  }

  public getIsIndicatorsExpandedObservable() {
    return this.isIndicatorsExpanded.pipe(distinctUntilChanged(), startWith(this.isIndicatorsExpanded.value));
  }

  public getIsIndicatorsExpanded() {
    return this.isIndicatorsExpanded.value;
  }

  public setIsIndicatorsExpanded(isExpanded: boolean) {
    this.isIndicatorsExpanded.next(isExpanded);
  }

  public getPlots() {
    const providers: PlotProvider[] = this.getIndicatorDatas().slice().map(i => i.indicator);
    if (this.isPrimary) {
      providers.push(...this.chart.getInstrumentDatas());
    }
    const plots: Plot[] = [];
    for (const provider of providers) {
      plots.push(...provider.plots);
    }
    return plots.reverse();
  }

  public getIndicatorDatasObservable() {
    return this.indicatorDatas.pipe(distinctUntilChanged(), startWith(this.indicatorDatas.value));
  }

  public getIndicatorDatas() {
    return this.indicatorDatas.value;
  }

  public addIndicator(indicatorData: IndicatorData) {
    const indicatorDatas = this.getIndicatorDatas().slice();
    indicatorDatas.push(indicatorData);
    this.indicatorDatas.next(indicatorDatas);
  }

  public removeIndicator(indicatorData: IndicatorData) {
    const indicatorDatas = this.getIndicatorDatas().slice();
    const index = indicatorDatas.indexOf(indicatorData);
    indicatorDatas.splice(index, 1);
    this.indicatorDatas.next(indicatorDatas);
    this.chart.coreLayer.requireDraw();
    if (!this.isPrimary && indicatorDatas.length === 0) {
      this.chart.removeSubChart(this);
    }
    indicatorData.onDelete();
  }

  public getUserTools() {
    return this.chart.getUserTools().filter(t => t.getSubChart() === this);
  }

  public getAnalysisTools() {
    return this.chart.getAnalysisTools().filter(t => t.getSubChart() === this);
  }

  public getAlertTools() {
    return this.chart.getAlertTools().filter(t => t.getSubChart() === this);
  }

  public getChartTools(): ChartTool[] {
    return [...this.getAlertTools(), ...this.getAnalysisTools(), ...this.getUserTools()];
  }

  public getHints() {
    const hints: ChartHint[] = [];
    for (const tool of this.getChartTools()) {
      for (const hint of tool.hints) {
        if (hint.isVisible) {
          hints.push(hint);
        }
      }
    }
    return hints;
  }

  public getHeight() {
    return this.mainArea.getHeight();
  }

  public setHeight(height: number) {
    return this.mainArea.setHeight(height);
  }
}

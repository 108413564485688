<template>
  <div class="debugger-info-container">
    <div class="debugger-info-filter">
      <SingleSelect v-model="selectedAnalysisType" :items="analysisTypeItems" />
      <template v-if="selectedAnalysisType === AnalysisType.TrendLine">
        <SelectTrendLineType :value="selectedTrendTypes" class="mt-1" @change="selectTrendTypes" />
        <SingleSelect
          :modelValue="selectedTrendOnlyActive" :items="trendActiveItems" class="mt-1"
          @update:modelValue="setSelectedTrendOnlyActive"
        />
        <SingleSelect
          :modelValue="selectedTrendId" :items="trendIdItems" itemKey="id" itemCaption="name" class="mt-1"
          @update:modelValue="setSelectedTrendId"
        />
        <SingleSelect
          v-model="selectedTrendOnly123" :items="ruleItems" itemKey="id" itemCaption="name" class="mt-1"
        />
      </template>
      <button type="button" class="btn btn-primary mt-1 debugger-info-copy" @click="copyDebuggerInfo">
        Copy
      </button>
    </div>
    <div class="debugger-infos-details">
      <div v-for="detail in details" :key="detail.properties" class="mt-2">
        <div class="mb-1">
          {{ detail.name }}
        </div>
        <div class="debugger-info-detail-item">
          {{ detail.properties }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getEnumValues } from "@/anfin-chart/utils";
import type { AnalysisToolDefinition } from "@/api/models/analysis/analysis-tool-definition";
import { AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import type { TrendLineType } from "@/api/models/analysis/auto-trend-line";
import { analysisStore } from "@/stores/analysis-store";
import { translationStore } from "@/stores/translation-store";
import { ChartHandler } from "@/utils/ChartHandler";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import SelectTrendLineType from "@/views/components/SelectTrendLineType.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import { TrendLineFilter } from "@/anfin-chart/options/trend-line-options";
import type { AnalysisData } from "@/api/models/analysis/analysis-data";
import { chartOptionStore } from "@/stores/chart-option-store";
import { DateMixin } from "@/mixins/date-mixin";

const datePropertyRegex = /.*(?:date|time|when).*/i;
const dateProperties = new Set(["startDate", "endDate", "when", "deleted"]);

export class DetailItem {

  constructor(public readonly name: string,
              public readonly properties: string) {
  }
}

export default defineComponent({
  name: "DebuggerInfo",

  components: { SelectTrendLineType, SingleSelect },

  mixins: [DateMixin],

  emits: {
    hide: () => true
  },

  expose: [],

  data() {
    const chartHandler = ChartHandler.getInstance();
    const { analysisData } = storeToRefs(analysisStore());
    return {
      analysisTypeItems: getEnumValues(AnalysisType).map(t =>
        new SingleSelectItem("analysis#" + t, null, t)
      ),
      selectedAnalysisType: AnalysisType.TrendLine,
      trendActiveItems: [
        new SingleSelectItem("debugger_trend_active#true", null, true),
        new SingleSelectItem("debugger_trend_active#false", null, false)
      ],
      ruleItems: [
        new SingleSelectItem("debugger_rule_123#true", null, true),
        new SingleSelectItem("debugger_rule_123#false", null, false)
      ],
      selectedTrendOnly123: true,
      analysisData: analysisData as unknown as AnalysisData,
      chartHandler,
      translationStore: translationStore(),
      AnalysisType
    };
  },

  computed: {
    selectedTrendId() {
      return chartOptionStore().optionManager.trendLine.selectedId.getValue();
    },

    selectedTrendOnlyActive() {
      return chartOptionStore().optionManager.trendLine.showOnlyActive.getValue();
    },

    selectedTrendTypes() {
      return chartOptionStore().optionManager.trendLine.shownTypes.getValue();
    },

    trendIdItems() {
      const filter = new TrendLineFilter("", this.selectedTrendOnlyActive, this.selectedTrendTypes);
      const items = this.analysisData.getTrendLines()
        .filter(trend => filter.apply(trend.id, trend.trendType, trend.deletedAt))
        .map(trend => {
          const name = String(trend.sequenceIndex) + ". Trend (" + trend.trendType + ")" + (trend.deletedAt ? "  -gelöscht-" : "");
          return new SingleSelectItem(trend.id, name, trend.id);
        });
      items.splice(0, 0, new SingleSelectItem("debugger_trend_types#all", null, ""));
      return items;
    },

    currentAnalyses(): AnalysisToolDefinition[] {
      switch (this.selectedAnalysisType) {
        case AnalysisType.TrendLine:
          return this.analysisData.getTrendLines();
        case AnalysisType.Channel:
          return this.analysisData.getChannels();
        case AnalysisType.DoubleExtreme:
          return this.analysisData.getDoubleExtremes();
        case AnalysisType.Extreme:
          return this.analysisData.getExtremes();
        case AnalysisType.AutoHorizontal:
          return this.analysisData.getAutoHorizontals();
        case AnalysisType.AutoFibonacci:
          return this.analysisData.getAutoFibonaccis();
        case AnalysisType.PriceGap:
          return this.analysisData.getPriceGaps();
        case AnalysisType.HeadAndShoulders:
          return this.analysisData.getHeadAndShoulders();
        default:
          return [];
      }
    },

    details() {
      const typeName = this.translationStore.getTranslation("analysis#" + this.selectedAnalysisType);
      const details = [];
      for (let i = 0; i < this.currentAnalyses.length; i++) {
        const analysis = this.currentAnalyses[i];
        const count = i + 1;
        const name = count + ". " + typeName;
        const detail = new DetailItem(name, this.getPropertyString(analysis));
        details.push(detail);
      }
      return details;
    }
  },

  watch: {
    trendIdItems(value: SingleSelectItem[]) {
      if (this.selectedTrendId != null && !value.some(v => v.value === this.selectedTrendId)) {
        this.setSelectedTrendId("");
      }
    }
  },

  methods: {
    selectTrendTypes(types: TrendLineType[]) {
      chartOptionStore().optionManager.trendLine.shownTypes.setValue(types);
    },

    setSelectedTrendOnlyActive(value: boolean) {
      chartOptionStore().optionManager.trendLine.showOnlyActive.setValue(value);
    },

    setSelectedTrendId(value: string) {
      chartOptionStore().optionManager.trendLine.selectedId.setValue(value);
    },

    copyDebuggerInfo() {
      navigator.clipboard.writeText(JSON.stringify(this.currentAnalyses));
    },

    hide() {
      this.$emit("hide");
    },

    getPropertyString(obj: any, offset = "") {
      const lines: string[] = [];
      for (const key of Object.keys(obj)) {
        const value = obj[key];
        const prefix = offset + key + ": ";
        if (value == null) {
          lines.push(prefix + "null");
        } else if (typeof value === "object") {
          lines.push(prefix);
          lines.push(this.getPropertyString(value, offset + "   "));
        } else {
          lines.push(prefix + this.formatPropertyValue(key, value));
        }
      }
      return lines.join("\n");
    },

    formatPropertyValue(key: string, value: unknown) {
      if (typeof value === "number" && (datePropertyRegex.test(key) || dateProperties.has(key))) {
        return this.formatDateTime(value);
      }
      if (typeof value === "string" && key === "rule") {
        return ChartHandler.getInstance().getTranslation(value);
      }
      return String(value);
    }
  }
});
</script>

<style scoped>
.debugger-info-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.debugger-info-filter {
  padding-bottom: 10px;
}

.debugger-info-copy {
  height: 2em;
  width: 100%;
  font-size: small;
  padding: 0;
}

.debugger-infos-details {
  font-size: small;
  flex-shrink: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.debugger-info-detail-item {
  white-space: pre-wrap;
}
</style>

<template>
  <video autoplay muted loop class="landing-video">
    <source src="/src/assets/video_site_small.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LandingVideo",

  expose: []
});
</script>

<style scoped>
.landing-video {
  width: 100%;
}
</style>
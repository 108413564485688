import type { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { AlertAutoToolDefinition, AlertUserToolDefinition } from "@/api/models/alert";
import type { AnalysisToolDefinition } from "@/api/models/analysis/analysis-tool-definition";

export type ToolAlertHook = UserToolAlertHook | AnalysisToolAlertHook;

export class BaseToolAlertHook {

  public percentageOffset: number | null = null;

  constructor(public readonly key: string,
              public readonly anchorPoint: ChartToolPoint) {
  }
}

export class UserToolAlertHook extends BaseToolAlertHook {

  constructor(public readonly tool: UserToolDefinition,
              key: string,
              anchorPoint: ChartToolPoint,
              public readonly basePointIndex: number,
              public targetPointIndex: number | null = null) {
    super(key, anchorPoint);
    tool.hookProvider.alertHooks.push(this);
  }
}

export class AnalysisToolAlertHook extends BaseToolAlertHook {

  constructor(public readonly tool: AnalysisToolDefinition,
              key: string,
              anchorPoint: ChartToolPoint) {
    super(key, anchorPoint);
    tool.hookProvider.alertHooks.push(this);
  }
}

export class ToolAlertHookProvider<T extends ToolAlertHook> {

  public readonly alertHooks: T[] = [];

  public get count() {
    return this.alertHooks.length;
  }

  public getHook(ruleDefinition: AlertUserToolDefinition | AlertAutoToolDefinition) {
    for (const hook of this.alertHooks) {
      if (this.matches(ruleDefinition, hook)) {
        return hook;
      }
    }
    return null;
  }

  private matches(ruleDefinition: AlertUserToolDefinition | AlertAutoToolDefinition, hook: ToolAlertHook) {
    if (ruleDefinition.percentageOffset !== hook.percentageOffset) {
      return false;
    }
    if (ruleDefinition instanceof AlertUserToolDefinition && hook instanceof UserToolAlertHook) {
      return ruleDefinition.basePointIndex === hook.basePointIndex &&
        ruleDefinition.targetPointIndex === hook.targetPointIndex;
    }
    return true;
  }
}

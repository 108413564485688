import { BehaviorSubject } from "rxjs";
import { OHLCItem } from "@/anfin-chart/plot";

export interface TimeBased {
  time: number;
}

export class TimeStoreInsertData<T> {

  public previousIndex: number;

  constructor(public readonly firstIndex: number,
              public readonly items: T[]) {
    this.previousIndex = firstIndex;
  }
}

export class TimeStore<T extends TimeBased> {

  protected items: T[] = [];
  private readonly itemsSubject = new BehaviorSubject<T[]>([]);
  private readonly lastItemSubject = new BehaviorSubject<T | null>(null);
  private readonly indexTable = new Map<number, number>();

  constructor() {
    this.itemsSubject.subscribe(v => this.lastItemSubject.next(v[v.length - 1]));
  }

  public get length() {
    return this.items.length;
  }

  public clear() {
    this.items = [];
    this.onItemsUpdate();
    this.indexTable.clear();
  }

  public getItems() {
    return this.items;
  }

  public getItemsObservable() {
    return this.itemsSubject;
  }

  public getLastItemObservable() {
    return this.lastItemSubject;
  }

  public get(index: number) {
    return this.items[index];
  }

  public getLast() {
    return this.length > 0 ? this.items[this.length - 1] : null;
  }

  public getByTime(time: number) {
    const index = this.indexTable.get(time);
    if (index == null) {
      return null;
    }
    return this.items[index];
  }

  public getByTimeOffset(time: number, offset: number) {
    const index = this.indexTable.get(time);
    const offsetIndex = index == null ? null : index + offset;
    if (offsetIndex == null) {
      return null;
    }
    return this.items[offsetIndex];
  }

  public indexOf(time: number) {
    return this.indexOfInternal(time);
  }

  public indexOfFraction(time: number) {
    return this.findPosition(time);
  }

  public insert(...items: T[]) {
    let insertData: TimeStoreInsertData<T> | null = null;
    for (const item of items) {
      const existingIndex = this.indexOfInternal(item.time);
      if (existingIndex != null) {
        this.items[existingIndex] = item;
        continue;
      }
      let index: number;
      if (insertData == null) {
        index = Math.ceil(this.findPosition(item.time));
        insertData = new TimeStoreInsertData(index, this.items.slice(0, index));
      } else {
        index = insertData.previousIndex;
        while (index < this.items.length && this.items[index].time < item.time) {
          index++;
        }
        const skippedItems = this.items.slice(insertData.previousIndex, index);
        insertData.items.push(...skippedItems);
        insertData.previousIndex = index;
      }
      insertData.items.push(item);
    }
    if (insertData != null) {
      const skippedItems = this.items.slice(insertData.previousIndex);
      insertData.items.push(...skippedItems);
      this.items = insertData.items;
      this.updateIndexes(insertData.firstIndex);
    }
    this.onItemsUpdate();
  }

  public updateLast(item: T) {
    const lastIndex = this.items.length - 1;
    if (item instanceof OHLCItem) {
      const current = this.items[lastIndex] as unknown as OHLCItem;
      if (item.high < current.high) {
        item.high = current.high;
      }
      if (item.low > current.low) {
        item.low = current.low;
      }
      item.open = current.open;
      item.volume += current.volume;
    }
    this.items[lastIndex] = item;
    this.lastItemSubject.next(item);
  }

  public removeItem(index: number, count = 1) {
    const removedItems = this.items.splice(index, count);
    for (const item of removedItems) {
      this.indexTable.delete(item.time);
    }
    this.updateIndexes(index);
    this.onItemsUpdate();
    return removedItems;
  }

  protected findPosition(time: number) {
    const existingIndex = this.indexOfInternal(time);
    if (existingIndex != null) {
      return existingIndex;
    }
    if (this.items.length === 0 || time < this.items[0].time) {
      return 0;
    }
    if (time > this.items[this.items.length - 1].time) {
      return this.items.length;
    }
    let startIndex = 0;
    let endIndex = this.items.length - 1;
    while (startIndex <= endIndex) {
      const startTime = this.items[startIndex].time;
      const endTime = this.items[endIndex].time;
      if (time < startTime) {
        break;
      }
      if (time > endTime) {
        startIndex = endIndex + 1;
        break;
      }
      const offset = time - startTime;
      const itemDifference = endTime - startTime;
      const indexDifference = endIndex - startIndex;
      const offsetIndex = startIndex + Math.floor(offset * indexDifference / itemDifference);
      const offsetTime = this.items[offsetIndex].time;
      if (offsetTime < time) {
        startIndex = offsetIndex + 1;
      } else {
        endIndex = offsetIndex - 1;
      }
    }
    const currentTime = this.items[startIndex].time;
    const lastTime = this.items[startIndex - 1].time;
    return startIndex - (currentTime - time) / (currentTime - lastTime);
  }

  protected updateIndexes(startIndex: number, endIndex: number = this.items.length) {
    for (let i = startIndex; i < endIndex; i++) {
      this.indexTable.set(this.items[i].time, i);
    }
  }

  protected onItemsUpdate() {
    this.itemsSubject.next(this.items);
  }

  private indexOfInternal(time: number) {
    return this.indexTable.get(time) ?? null;
  }
}

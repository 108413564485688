<template>
  <div class="checkout-cart-item">
    <ProductImageCarousel :product="item.product" class="me-3 checkout-cart-image-carousel" />
    <div class="checkout-cart-item-info">
      <div class="checkout-cart-item-name">
        {{ item.product.name }}
      </div>
      <div class="mt-3">
        {{ item.product.description }}
      </div>
      <div class="mt-3">
        <ProductVariantSelector
          v-if="isEditable" v-model="item.variant" :product="item.product" @update:modelValue="setVariant"
        />
        <div v-else>
          {{ item.variant.description }}
        </div>
      </div>
      <div class="checkout-cart-item-price mt-3">
        <div v-if="item.variant.fixedPrice">
          {{ translationStore.getTranslation("checkout#payment_fix") }}: {{ formatPrice(item.variant.fixedPrice) }}
        </div>
        <div v-if="item.variant.recurringPrice">
          {{ translationStore.getTranslation("checkout#payment_recurrrent") }}:
          {{ formatAboPrice(item.variant.recurringPrice, item.variant.feePeriod) }}
          <span v-if="isOfferAutoNextStep">*</span>
        </div>
        <div v-if="isOfferAutoNextStep && item.variant.offerData?.nextVariant" class="next-step-hint my-2">
          <div class="mb-2">
            * Das Produkt hat eine Mindestlaufzeit von {{ formatContractPeriod(item.variant.contractPeriod, "long") }}n und kostet für die Mindestlaufzeit monatlich den angegebenen Preis (inkl. MwSt.).
          </div>
          <div class="mb-2">
            Die Kündigungsfrist beträgt einen Monat zum Ablauf der Mindestlaufzeit. Nach Ablauf der Mindestlaufzeit läuft Ihr Abonnement zum Preis von monatlich {{ formatAboPrice(item.variant.offerData.nextVariant.recurringPrice, item.variant.offerData.nextVariant.feePeriod) }} € (inkl. MwSt.) weiter. Sofern der Vertrag nicht mit einer Frist von einem Monat gekündigt wird, verlängert er sich jeweils automatisch um je einen Monat. Die Kündigungsfrist von einem Monat bleibt hiervon unberührt.
          </div>
          <div>
            Diese Bedingungen gelten, soweit nicht im Einzelfall schriftlich eine abweichende Vereinbarung getroffen wurde.
          </div>
        </div>
      </div>
      <button v-if="isEditable" class="checkout-cart-item-remove btn btn-link" @click="remove">
        {{ translationStore.getTranslation("checkout#remove_cart_item") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import { MonetaryMixin } from "@/mixins/monetary-mixin";
import type { ShoppingProductVariant } from "@/api/models/shopping-cart";
import { paymentStore } from "@/stores/payment-store";
import { ShoppingCartItemResolved } from "@/api/models/checkout";
import ProductImageCarousel from "@/views/pages-landing/product/ProductImageCarousel.vue";
import ProductVariantSelector from "@/views/pages-landing/product/ProductVariantSelector.vue";

export default defineComponent({
  name: "ShoppingCartItem",

  components: { ProductVariantSelector, ProductImageCarousel },

  mixins: [MonetaryMixin],

  props: {
    item: {
      type: ShoppingCartItemResolved,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    isOfferAutoNextStep() {
      return this.item.variant?.offerData?.autoNextStep && this.item.variant?.offerData?.nextVariant;
    }
  },

  methods: {
    async setVariant(variant: ShoppingProductVariant) {
      const cart = paymentStore().currentCart;
      if (cart == null) {
        return;
      }
      for (const cartItem of cart.items) {
        if (cartItem.productId === this.item.product.id) {
          cartItem.variantId = variant.id;
          break;
        }
      }
      await paymentStore().updateCart();
    },

    remove() {
      paymentStore().removeCartItem(this.item.product.id, this.item.variant.id);
    }
  }
});
</script>

<style scoped>
.checkout-cart-item {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-evenly;
  width: 100%;
  gap: 10px;
}

.checkout-cart-image-carousel {
  flex-basis: 300px;
}

.checkout-cart-item-info {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
}

.checkout-cart-item-price,
.checkout-cart-item-name {
  font-size: 120%;
  font-weight: 600;
  color: var(--content-primary);
}

.checkout-cart-item-remove {
  align-self: end;
}

.next-step-hint {
  color: var(--content-tertiary);
  font-size: 60%;
}
</style>
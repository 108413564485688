<template>
  <div class="shortcut-categories">
    <div v-for="section in sections" :key="section.group.category" class="shortcut-category">
      <button type="button" class="shortcut-category-toggle btn btn-secondary" @click="toggleSection(section)">
        <span v-if="section.group.category === ShortcutCategory.Indicator" class="icon-dir-left">
          <IconElement :size="24" iconName="Indicators" />
        </span>
        <span v-if="section.group.category === ShortcutCategory.General" class="icon-dir-left">
          <IconElement :size="24" iconName="GeneralSettings" />
        </span>
        <span v-if="section.group.category === ShortcutCategory.Tool" class="icon-dir-left">
          <IconElement :size="24" iconName="DrawTool" />
        </span>
        <span class="shortcut-category-caption">
          {{ translationStore.getTranslation("config#shortcut#group_" + section.group.category) }}
        </span>
        <span v-if="section.isExpanded" class="icon-dir">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
          </svg>
        </span>
        <span v-else class="icon-dir">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
          </svg>
        </span>
      </button>
      <div v-if="section.isExpanded" class="shortcut-item-section">
        <div v-for="shortcut in section.group.shortcuts" :key="shortcut.name" class="shortcut-item">
          <div v-if="shortcut.customName != null" class="shortcut-key-element-left">
            <IconElement class="shortcut-key-element-left" iconName="Account" :size="22" />
            <span class="shortcut-key-element-left">{{ shortcut.customName }}</span>
          </div>
          <span v-else>
            {{ translationStore.getTranslation(shortcut.name) }}
          </span>

          <div v-if="shortcut.name !== recordShortcut?.name" class="shortcut-key-element-float" @click="deleteKeys(shortcut)">
            <IconElement :size="22" iconName="Delete" :color="shortcut.keyInfo != null ? 'currentColor' : inactiveColor" />
          </div>
          <div v-if="shortcut.name !== recordShortcut?.name" class="shortcut-key-element-float" @click="recordKeys(shortcut)">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" style="stroke: none" />
              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" style="stroke: none" />
            </svg>
          </div>

          <div class="shortcut-key">
            <div v-for="(key, keyIndex) in getKeys(shortcut.keyInfo)" :key="key" class="shortcut-key-element-plus">
              <div class="shortcut-key-element">
                {{ key }}
              </div>
              <div v-if="keyIndex > 0" class="shortcut-key-element-plus">
                +
              </div>
            </div>
          </div>

          <div v-if="shortcut.name === recordShortcut?.name" class="shortcut-edit-hint">
            <span>
              {{ translationStore.getTranslation("config#shortcut#edit_info") }}
            </span>
          </div>
          <div v-else-if="forbiddenKeyShortcut != null && forbiddenKeyShortcut.name === shortcut.name" class="shortcut-conflict-hint">
            <span>
              {{ translationStore.getTranslation("config#shortcut#forbidden_key") }}
            </span>
          </div>
          <div v-else-if="conflict != null && conflict.currentShortcut.name === shortcut.name" class="shortcut-conflict-hint">
            <span>
              {{ conflictInfo }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { ShortcutGroup } from "@/api/models/shortcut";
import { KeyInfo, Shortcut, ShortcutCategory } from "@/api/models/shortcut";
import { ShortcutConflict, shortcutStore } from "@/stores/shortcut-store";
import { translationStore } from "@/stores/translation-store";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import IconElement from "@/views/icons/IconElement.vue";
import { getColorByCSSDefine } from "@/anfin-chart/utils";


class ShortcutSection {

  public isExpanded = false;

  constructor(public readonly group: ShortcutGroup) {
  }
}

export default defineComponent({
  name: "ShortcutSettings",

  components: { IconElement },

  expose: [],

  data() {
    const { recordShortcut, groups, conflict, forbiddenKeyShortcut } = storeToRefs(shortcutStore());
    return {
      translationStore: translationStore(),
      groups: groups as unknown as ShortcutGroup[],
      recordShortcut: recordShortcut as unknown as Shortcut | null,
      conflict: conflict as unknown as ShortcutConflict | null,
      forbiddenKeyShortcut: forbiddenKeyShortcut as unknown as Shortcut | null,
      ShortcutCategory
    };
  },

  computed: {
    sections(): ShortcutSection[] {
      return this.groups.map(g => new ShortcutSection(g));
    },

    inactiveColor() {
      return getColorByCSSDefine("--disabled-primary");
    },

    conflictInfo(): string {
      if (this.conflict == null) {
        return "";
      }
      const conflictTranslation = this.translationStore.getTranslation("config#shortcut#conflict");
      const shortcutTranslation = this.translationStore.getTranslation(this.conflict.previousShortcut.name);
      return conflictTranslation.replace("#SHORTCUT#", shortcutTranslation);
    }
  },

  methods: {
    getKeys(keyInfo: KeyInfo | null) {
      if (keyInfo == null) {
        return [];
      }
      const keys = [];
      if (keyInfo.isControl) {
        keys.push(this.translationStore.getTranslation("keyboard#control"));
      }
      if (keyInfo.isAlt) {
        keys.push(this.translationStore.getTranslation("keyboard#alt"));
      }
      if (keyInfo.isShift) {
        keys.push(this.translationStore.getTranslation("keyboard#shift"));
      }
      keys.push(keyInfo.key);
      return keys;
    },

    deleteKeys(shortcut: Shortcut) {
      shortcutStore().clearShortcut(shortcut, false);
    },

    recordKeys(shortcut: Shortcut) {
      shortcutStore().setRecordShortcut(shortcut);
    },

    toggleSection(section: ShortcutSection) {
      section.isExpanded = !section.isExpanded;
      this.$forceUpdate();
    }
  }
});
</script>

<style scoped>
.shortcut-category {
  width: 100%;
  max-height: 100%;
}

.shortcut-category-toggle {
  width: 100%;
  margin-bottom: 0.5rem;
}

.shortcut-item {
  padding: 8px 0;
  margin: 4px 0;
  min-height: 2.75rem;
}

.icon-dir {
  float: right;
  margin-right: 0.5rem;
}

.icon-dir-left {
  float: left;
  margin-left: 5px;
}

.shortcut-category-caption {
  margin-top: 25px;
}

.shortcut-key {
  float: right;
}

.shortcut-key-element {
  border: 1px solid var(--interactive-secondary);
  border-radius: 6px;
  box-shadow: 2px 2px 0 var(--interactive-secondary);
  float: right;
  min-width: 2.1rem;
  padding: 0 5px;
  text-align: center;
  margin: 0 5px;
}

.shortcut-key-element-plus {
  float: left;
  padding: 0 0;
  text-align: center;
  margin: 0 0;
}

.shortcut-key-element-float {
  float: right;
  margin-left: 8px;
  text-align: center;
}

.shortcut-key-element-left {
  float: left;
  margin-left: 0.2rem;
  text-align: center;
}

.shortcut-edit-hint, .shortcut-conflict-hint {
  text-align: center;
  margin-top: 0.5rem;
}

.shortcut-edit-hint {
  color: rgba(213, 162, 65, 0.71);
}

.shortcut-conflict-hint {
  color: red;
}
</style>

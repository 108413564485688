<template>
  <SingleSelect :modelValue="modelValue" :items="items" @update:modelValue="onChange" />
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import { ShoppingProduct, ShoppingProductVariant } from "@/api/models/shopping-cart";
import { MonetaryMixin } from "@/mixins/monetary-mixin";

export default defineComponent({
  name: "ProductVariantSelector",

  components: { SingleSelect },

  mixins: [MonetaryMixin],

  props: {
    modelValue: {
      type: Object as PropType<ShoppingProductVariant | null>,
      required: true
    },
    product: {
      type: ShoppingProduct,
      required: true
    }
  },

  emits: {
    "update:modelValue": (variant: ShoppingProductVariant) => variant || true
  },

  expose: [],

  computed: {
    items() {
      const items = [];
      for (const variant of this.product.activeVariants) {
        const description = variant.description === ""
          ? this.product.name + " " + this.formatAboPrice(variant.recurringPrice, variant.feePeriod)
          : variant.description;
        items.push(new SingleSelectItem(variant.id, description, variant));
      }
      return items;
    }
  },

  methods: {
    onChange(value: unknown) {
      this.$emit("update:modelValue", value as ShoppingProductVariant);
    }
  }
});
</script>

<style scoped>
</style>
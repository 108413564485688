import { ChartExport, InstrumentExport, SubChartExport } from "@/anfin-chart/export";
import { Instrument } from "@/anfin-chart/instrument";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { ApiModelConverter } from "@/api/messages/converter";
import { IndicatorConverter, type IndicatorResponse } from "@/api/messages/indicator";
import { ChartLayout } from "@/api/models/chart-layout";
import { userRightStore } from "@/stores/user-right-store";

export interface ChartLayoutResponse {
  id: number;
  charts: ChartExportResponse[];
}

export interface ChartExportResponse {
  isLinkedSplitChart: boolean;
  instruments: ChartInstrumentResponse[];
  indicators: IndicatorResponse[];
  subCharts: SubChartExportResponse[];
}

export interface SubChartExportResponse {
  height: number;
  instruments: ChartInstrumentResponse[];
  indicators: IndicatorResponse[];
  isIndicatorsExpanded: boolean | null;
}

export interface ChartInstrumentResponse {
  symbol: string;
  timeframe: string;
}

export class ChartInstrumentConverter extends ApiModelConverter<InstrumentExport, ChartInstrumentResponse> {

  public override toApiObject(instrumentExport: InstrumentExport) {
    return {
      symbol: instrumentExport.instrument.getSymbol(),
      timeframe: instrumentExport.timeframe.toShortNotation()
    };
  }

  public override toModelObject(response: ChartInstrumentResponse) {
    const instrument = Instrument.fromSymbol(response.symbol);
    const timeframe = Timeframe.fromShortNotation(response.timeframe);
    return new InstrumentExport(instrument, timeframe);
  }
}

export class ChartLayoutConverter extends ApiModelConverter<ChartLayout, ChartLayoutResponse> {

  private readonly chartExportConverter = new ChartExportConverter();

  public override toApiObject(layout: ChartLayout) {
    return {
      id: layout.id,
      name: userRightStore().deviceType,
      charts: layout.charts.map(c => this.chartExportConverter.toApiObject(c))
    };
  }

  public override toModelObject(response: ChartLayoutResponse) {
    const chartExports = response.charts.map(c => this.chartExportConverter.toModelObject(c));
    return new ChartLayout(response.id, chartExports);
  }
}

export class ChartExportConverter extends ApiModelConverter<ChartExport, ChartExportResponse> {

  private readonly subChartListConverter = new SubChartExportListConverter();

  public override toApiObject(chartExport: ChartExport) {
    const [subCharts, instruments, indicators] = this.subChartListConverter.toApiObject(chartExport.subCharts);
    return {
      isLinkedSplitChart: chartExport.isLinkedSplitChart,
      instruments,
      indicators,
      subCharts
    };
  }

  public override toModelObject(response: ChartExportResponse) {
    const subCharts = this.subChartListConverter.toModelObject(response.subCharts, response.instruments, response.indicators);
    return new ChartExport(response.isLinkedSplitChart, subCharts);
  }
}

export class SubChartExportListConverter {

  private readonly subChartExportConverter = new SubChartExportConverter();
  private readonly instrumentConverter = new ChartInstrumentConverter();
  private readonly indicatorConverter = new IndicatorConverter();

  public toApiObject(subChartExports: SubChartExport[]): [SubChartExportResponse[], ChartInstrumentResponse[], IndicatorResponse[]] {
    const subChartObjects = [];
    const instrumentObjects = [];
    const indicatorObjects = [];
    if (subChartExports) {
      for (const subChartExport of subChartExports) {
        const subChartObject = this.subChartExportConverter.toApiObject(subChartExport);
        subChartObjects.push(subChartObject);
        instrumentObjects.push(...subChartObject.instruments);
        indicatorObjects.push(...subChartObject.indicators);
      }
    }
    return [subChartObjects, instrumentObjects, indicatorObjects];
  }

  public toModelObject(subChartResponses: SubChartExportResponse[] | null,
                       instrumentResponses: ChartInstrumentResponse[] | null,
                       indicatorResponses: IndicatorResponse[] | null) {
    if (subChartResponses != null && subChartResponses.length > 0) {
      return subChartResponses.map(s => this.subChartExportConverter.toModelObject(s));
    }
    if (instrumentResponses != null && indicatorResponses != null) {
      return this.convertLegacyData(instrumentResponses, indicatorResponses);
    }
    return [new SubChartExport(1, [], [], true)];
    // throw new Error("Invalid sub chart export list");
  }

  private convertLegacyData(instrumentResponses: ChartInstrumentResponse[],
                            indicatorResponses: IndicatorResponse[]) {
    const subChartExports = [];
    for (const indicatorResponse of indicatorResponses) {
      const subChartIndex = indicatorResponse.subChartIndex ?? 0;
      while (subChartExports.length <= subChartIndex) {
        subChartExports.push(new SubChartExport(1, [], [], true));
      }
      const indicatorTemplate = this.indicatorConverter.toModelObject(indicatorResponse);
      subChartExports[subChartIndex].indicators.push(indicatorTemplate);
    }
    if (subChartExports.length === 0) {
      subChartExports.push(new SubChartExport(1, [], [], true));
    }
    const primarySubChart = subChartExports.find(s => s.indicators.length === 0) ?? subChartExports[0];
    primarySubChart.instruments = instrumentResponses.map(i => this.instrumentConverter.toModelObject(i));
    if (primarySubChart.instruments.length === 0) {
      const defaultInstrument = new InstrumentExport(Instrument.default, Timeframe.default);
      primarySubChart.instruments.push(defaultInstrument);
    }
    return subChartExports;
  }
}

export class SubChartExportConverter extends ApiModelConverter<SubChartExport, SubChartExportResponse> {

  private readonly instrumentConverter = new ChartInstrumentConverter();
  private readonly indicatorConverter = new IndicatorConverter();

  public override toApiObject(subChartExport: SubChartExport) {
    return {
      height: subChartExport.height,
      instruments: subChartExport.instruments.map(i => this.instrumentConverter.toApiObject(i)),
      indicators: subChartExport.indicators.map(i => this.indicatorConverter.toApiObject(i)),
      isIndicatorsExpanded: subChartExport.isIndicatorsExpanded
    };
  }

  public override toModelObject(response: SubChartExportResponse) {
    const instruments = response.instruments.map(i => this.instrumentConverter.toModelObject(i));
    const indicators = response.indicators.map(i => this.indicatorConverter.toModelObject(i));
    const isIndicatorsExpanded = response.isIndicatorsExpanded == null ? true : response.isIndicatorsExpanded;
    return new SubChartExport(response.height, instruments, indicators, isIndicatorsExpanded);
  }
}

<template>
  <div class="mb-3">
    <div class="product-detail-caption">
      <slot name="header"></slot>
    </div>
    <div class="mt-2">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProductDetailParagraph",

  expose: []
});
</script>

<style scoped>
.product-detail-caption {
  font-weight: bold;
}
</style>
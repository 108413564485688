import { LineOptions, LineStyle, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { LineElement, TextBox } from "@/anfin-chart/drawable";
import { ChartError } from "@/anfin-chart/error";
import type { InstrumentData } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { ChartToolPoint, FixedPoint } from "@/anfin-chart/tools/tool-point";
import { AutoDoubleExtreme, AutoDoubleExtremeType } from "@/api/models/analysis/auto-double-extreme";
import { Vector } from "@/anfin-chart/geometry";
import type { ColorOption } from "@/anfin-chart/options/option";
import { AnalysisTool } from "@/anfin-chart/tools/analysis-tool";

export class AutoDoubleExtremeTool extends AnalysisTool {

  private readonly colorOption: ColorOption;

  private readonly start: FixedPoint;
  private readonly startTarget: FixedPoint;
  private readonly end: FixedPoint;
  private readonly mainEnd: FixedPoint;
  private readonly brokenEnd: FixedPoint;
  private readonly isInverse: boolean;

  private readonly leftShoulder = new ChartToolPoint();
  private readonly head = new ChartToolPoint();
  private readonly rightShoulder = new ChartToolPoint();
  private readonly broken = new ChartToolPoint();

  constructor(public readonly definition: AutoDoubleExtreme,
              instrumentData: InstrumentData,
              subChart: SubChart) {
    super(definition, instrumentData, subChart);
    this.colorOption = this.getColorOption();
    const price = definition.price;
    this.start = new FixedPoint(definition.firstExtremeDate, price);
    this.startTarget = new FixedPoint(definition.firstExtremeDate, definition.target);
    this.end = new FixedPoint(definition.secondExtremeDate, definition.target);
    this.mainEnd = new FixedPoint(definition.secondExtremeDate, price);
    this.brokenEnd = new FixedPoint(definition.brokenDate ?? 0, price);
    this.isInverse = definition.target > price;
  }

  public override getIsVisible() {
    return super.getIsVisible() && this.optionManager.showDoubleExtreme.getValue();
  }

  protected override createDrawables() {
    const mainOptions = new LineOptions(3);
    const mainLine = new LineElement(this, this.start, this.mainEnd, mainOptions);
    const extensionOptions = new LineOptions(1, LineStyle.Dashed);
    const extensionLine = new LineElement(this, this.startTarget, this.end, extensionOptions);
    let textAlignment: TextAlignment;
    let textAlignmentInverse: TextAlignment;
    if (this.isInverse) {
      textAlignment = new TextAlignment(new Vector(0, 1), 10);
      textAlignmentInverse = new TextAlignment(new Vector(0, -1), 5);
    } else {
      textAlignment = new TextAlignment(new Vector(0, -1), 5);
      textAlignmentInverse = new TextAlignment(new Vector(0, 1), -5);
    }
    const translationPrefix = "auto_tool#double_extreme#";
    const leftShoulderText = new TextBox(this, this.leftShoulder, textAlignment);
    const headText = new TextBox(this, this.head, textAlignmentInverse);
    const rightShoulderText = new TextBox(this, this.rightShoulder, textAlignment);
    this.onUpdate(() => {
      const color = this.colorOption.getValue();
      const fontSize = this.chart.styleOptions.autoToolFontSize.getValue();
      const shoulderKey = this.isInverse ? "label_bottom" : "label_top";
      const shoulderText = this.chart.callbacks.getTranslation(translationPrefix + shoulderKey);
      mainLine.color = color;
      extensionLine.color = color;
      leftShoulderText.fontSize = fontSize;
      leftShoulderText.setText(shoulderText);
      leftShoulderText.color = color;
      headText.fontSize = fontSize;
      headText.setText(this.chart.callbacks.getTranslation(translationPrefix + "label_head"));
      headText.color = color;
      rightShoulderText.fontSize = fontSize;
      rightShoulderText.setText(shoulderText);
      rightShoulderText.color = color;
    });
    if (this.definition.brokenDate != null) {
      const brokenOptions = new LineOptions(1, LineStyle.Dashed);
      const brokenLine = new LineElement(this, this.mainEnd, this.brokenEnd, brokenOptions);
      const brokenText = new TextBox(this, this.broken, textAlignmentInverse);
      this.onUpdate(() => {
        const color = this.colorOption.getValue();
        const fontSize = this.chart.styleOptions.autoToolFontSize.getValue();
        brokenLine.color = color;
        brokenText.color = color;
        brokenText.fontSize = fontSize;
        brokenText.setText(this.chart.callbacks.getTranslation(translationPrefix + "label_breakout"));
      });
    }
  }

  protected override updatePositionInternal() {
    this.updateFixedPoint(this.start, this.startTarget, this.end, this.mainEnd, this.brokenEnd);
    const leftShoulder = this.calculatePoint(this.definition.firstExtremeDate, this.isInverse);
    this.updatePoint(this.leftShoulder, leftShoulder);
    const head = this.calculatePoint(this.definition.middleOppositeExtremeDate, !this.isInverse);
    this.updatePoint(this.head, head);
    const rightShoulder = this.calculatePoint(this.definition.secondExtremeDate, this.isInverse);
    this.updatePoint(this.rightShoulder, rightShoulder);
    if (this.definition.brokenDate != null) {
      const broken = this.calculatePoint(this.definition.brokenDate, !this.isInverse);
      this.updatePoint(this.broken, broken);
    }
  }

  private getColorOption() {
    switch (this.definition.extremeType) {
      case AutoDoubleExtremeType.Top:
        return this.colorOptions.autoDoubleExtremeTop;
      case AutoDoubleExtremeType.Bottom:
        return this.colorOptions.autoDoubleExtremeBottom;
      default:
        throw new ChartError("Unknown auto horizontal type");
    }
  }
}

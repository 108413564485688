import { UserDeviceMode } from "@/api/models/user-info";
import { userRightStore } from "@/stores/user-right-store";

export class NativeAppAdapter {

  private static instance: NativeAppAdapter | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new NativeAppAdapter();
    }
    return this.instance;
  }

  public sendMessage(action: string, data: unknown = {}) {
    const message = { action, data };
    const mode = userRightStore().userInfo?.mode;
    if (mode === UserDeviceMode.ApplePhone || mode === UserDeviceMode.AppleTablet) {
      const messageJson = JSON.stringify(message);
      alert(messageJson);
    } else if ((mode === UserDeviceMode.AndroidPhone || mode === UserDeviceMode.AndroidTablet) && JSAdapter != null) {
      const dataJson = this.serializeData(data);
      JSAdapter.sendMessage(action, dataJson);
    }
  }

  private serializeData(data: unknown) {
    if (data == null) {
      return "";
    }
    if (typeof data === "object") {
      return JSON.stringify(data);
    }
    return data.toString();
  }
}

import { ChartArea } from "@/anfin-chart/area/chart-area";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { Point, Range, Rectangle, Vector } from "@/anfin-chart/geometry";
import type { DoubleClickable, DragData, Draggable } from "@/anfin-chart/interactions";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { DelayedAction } from "@/anfin-chart/utils";
import { BehaviorSubject, combineLatest, distinctUntilChanged, startWith, Subject } from "rxjs";
import type { PriceAxisMarker } from "@/anfin-chart/area/price-axis-marker";
import { AbsolutePriceScale, LogarithmicPriceScale, PriceScale } from "@/anfin-chart/area/price-scale";
import { ChartError } from "@/anfin-chart/error";

export enum PriceScaleType {
  Absolute = 0,
  Logarithmic = 1
}

class PriceAxisLabel {

  constructor(public text: string,
              public y: number) {
  }
}

export class PriceAxis extends ChartArea implements Draggable, DoubleClickable {

  public labels: PriceAxisLabel[] = [];
  public markers = new Set<PriceAxisMarker>();

  private readonly textAlignment = new TextAlignment(new Vector(1, 0));
  private readonly isAutoRange = new BehaviorSubject(true);
  private readonly rangeObservable = new Subject<Range>();
  private priceScale: PriceScale = new AbsolutePriceScale(this);

  private readonly throttledAutoRange = new DelayedAction(this.executeAutoRange.bind(this), 0);

  constructor(layer: ChartLayer,
              private readonly subChart: SubChart) {
    super(layer);
    this.setVisibleOnSubChart(subChart);
  }

  public override initializeEvents() {
    const scaleObservable = combineLatest([
      this.chart.optionManager.priceScale.getValueObservable(),
      this.chart.getPrimarySubChartObservable()
    ]);
    this.subscribeOn(scaleObservable, ([type]) => this.updatePriceScale(type));
    const resizeObservable = combineLatest([
      this.chart.priceAxisWrapper.getPositionObservable(),
      this.subChart.mainArea.getPositionObservable()
    ]);
    this.subscribeOn(resizeObservable, () => this.resize());
    const labelObservable = combineLatest([
      this.getPositionObservable(),
      this.getRangeObservable()
    ]);
    this.subscribeOn(labelObservable, () => this.updateLabels());
    this.subscribeOn(this.chart.timeAxis.getRangeObservable(), () => this.updateAutoRange());
  }

  public onDrag(data: DragData) {
    const position = this.getPosition();
    const height = position.yEnd - position.yStart;
    const isUpperHalf = data.startPosition.y < position.yStart + height / 2;
    const relativeDiff = data.diffY / height;
    this.isAutoRange.next(false);
    if (isUpperHalf) {
      this.priceScale.adjustRangeRelative(0, relativeDiff);
    } else {
      this.priceScale.adjustRangeRelative(relativeDiff, 0);
    }
  }

  public onDoubleClick() {
    this.setAutoRange();
  }

  public getPrice(y: number) {
    return this.priceScale.getPrice(y);
  }

  public getY(price: number) {
    return this.priceScale.getY(price);
  }

  public setAutoRange() {
    this.isAutoRange.next(true);
    this.updateAutoRange();
  }

  public getRange() {
    return this.priceScale.getRange();
  }

  public getRangeObservable() {
    return this.rangeObservable.pipe(
      distinctUntilChanged((last, current) => last.start === current.start && last.end === current.end),
      startWith(this.priceScale.getRange())
    );
  }

  public onRangeChanged(range: Range) {
    this.rangeObservable.next(range);
  }

  public moveRange(delta: number) {
    const position = this.getPosition();
    const relativeDiff = delta / (position.yEnd - position.yStart);
    this.priceScale.adjustRangeRelative(relativeDiff, relativeDiff);
  }

  public zoomCenter(position: Point, delta: number) {
    const scrollBase = 0.9;
    const scrollFactor = scrollBase ** delta;
    this.priceScale.adjustRangeZoom(position.y, scrollFactor);
  }

  public zoomRange(yStart: number, yEnd: number) {
    const startPrice = this.getPrice(yEnd);
    const endPrice = this.getPrice(yStart);
    this.priceScale.setRange(startPrice, endPrice);
  }

  public updateLabels() {
    this.labels = [];
    const labelPrices = this.priceScale.getLabelPrices();
    for (const price of labelPrices) {
      const y = this.priceScale.getY(price);
      const label = new PriceAxisLabel(this.formatPrice(price), y);
      this.labels.push(label);
    }
    this.chart.priceAxisWrapper.resize();
    this.layer.requireDraw();
  }

  public getIsAutoRangeObservable() {
    return this.isAutoRange.pipe(distinctUntilChanged(), startWith(this.isAutoRange.value));
  }

  public getIsAutoRange() {
    return this.isAutoRange.value;
  }

  public updateAutoRange() {
    if (this.getIsAutoRange()) {
      this.throttledAutoRange.run();
    }
  }

  public addMarker(marker: PriceAxisMarker) {
    this.markers.add(marker);
  }

  public removeMarker(marker: PriceAxisMarker) {
    this.markers.delete(marker);
  }

  public formatPrice(price: number) {
    if (!this.subChart.isPrimary) {
      if (price >= 1000000) {
        return (price / 1000000).toFixed(0) + "M";
      } else if (price >= 1000) {
        return (price / 1000).toFixed(0) + "k";
      }
    }
    return this.chart.formatPrice(price);
  }

  public measureLabelWidth() {
    const fontInfo = this.chart.getFontInfo();
    let maxWidth = 0;
    for (const label of this.labels) {
      const size = this.drawer.measureText(label.text, fontInfo);
      maxWidth = Math.max(maxWidth, size.width);
    }
    return maxWidth;
  }

  protected override drawInternal() {
    this.drawLabels();
    this.drawDataLabels();
  }

  protected override resizeInternal() {
    const priceAxisWrapperPosition = this.chart.priceAxisWrapper.getPosition();
    const subChartAreaPosition = this.subChart.mainArea.getPosition();
    const xStart = priceAxisWrapperPosition.xStart;
    const xEnd = priceAxisWrapperPosition.xEnd;
    const yEnd = subChartAreaPosition.yEnd;
    const yStart = subChartAreaPosition.yStart;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private updatePriceScale(type: PriceScaleType) {
    if (!this.subChart.isPrimary) {
      this.priceScale = new AbsolutePriceScale(this);
    } else if (type === PriceScaleType.Absolute) {
      this.priceScale = new AbsolutePriceScale(this);
    } else if (type === PriceScaleType.Logarithmic) {
      this.priceScale = new LogarithmicPriceScale(this);
    } else {
      throw new ChartError("Unknown price scale type: " + type);
    }
    this.setAutoRange();
  }

  private executeAutoRange() {
    const plots = this.subChart.getPlots();
    const timeRange = this.chart.timeAxis.getTimeRange();
    if (timeRange == null) {
      return;
    }
    const lows: number[] = [];
    const highs: number[] = [];
    for (const plot of plots) {
      if (!plot.useAxis) {
        continue;
      }
      const store = plot.store;
      const items = store.getItems();
      const startIndex = Math.trunc(store.indexOfFraction(timeRange.start));
      const endIndex = Math.ceil(store.indexOfFraction(timeRange.end));
      for (let i = startIndex; i <= endIndex && i < items.length; i++) {
        const item = items[i];
        lows.push(item.min);
        highs.push(item.max);
      }
    }
    if (lows.length === 0 || highs.length === 0) {
      this.priceScale.setRange(0, 1);
      return;
    }
    let minPrice = Math.min(...lows);
    let maxPrice = Math.max(...highs);
    const difference = maxPrice - minPrice;
    if (difference === 0) {
      minPrice -= 0.01;
      maxPrice += 0.01;
    }
    this.priceScale.setRange(minPrice, maxPrice);
    const padding = this.chart.styleOptions.priceAutoRangePadding.getValue();
    this.priceScale.adjustRangeRelative(-padding, padding);
  }

  private drawLabels() {
    const fontInfo = this.chart.getFontInfo();
    const paddingLeft = this.chart.styleOptions.priceAxisPaddingLeft.getValue();
    const x = Math.round(this.getPosition().xStart + paddingLeft);
    const color = this.chart.optionManager.chartColor.axisLabel.getValue();
    for (const label of this.labels) {
      const y = Math.round(label.y);
      const position = new Point(x, y);
      this.drawer.printText(position, label.text, color, this.textAlignment, fontInfo);
    }
  }

  private drawDataLabels() {
    if (this.subChart.isPrimary) {
      for (const marker of this.markers) {
        marker.draw();
      }
    }
  }
}

<template>
  <div>
    <div class="legal-document-header">
      {{ data.caption }}
    </div>
    <LegalSection v-for="section in data.sections" :section="section" />
    <div class="mt-4">
      Formationstrader GmbH
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LegalDocument } from "@/api/models/legal-document";
import LegalSection from "@/views/pages-landing/sections/LegalSection.vue";

export default defineComponent({
  name: "LegalDocument",

  components: { LegalSection },

  props: {
    data: {
      type: LegalDocument,
      required: true
    }
  },

  expose: []
});
</script>

<style scoped>

.legal-document-header {
  font-weight: bold;
  font-size: 120%;
}
</style>
<template>
  <div>
    <div class="landing-text-header">
      <slot name="header"></slot>
    </div>
    <div class="landing-text-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LandingText",

  expose: []
});
</script>

<style scoped>
.landing-text-header {
  font-size: 130%;
  font-weight: bold;
  margin-bottom: 1rem;
}

.landing-text-content {
  text-align: justify;
  line-height: 2em;
}
</style>
<template>
  <DraggablePanel
    ref="panel" v-click-outside="onClickOutside" class="tool-edit-panel" :isVisible="isVisible"
    :container="container" :position="position" @changePosition="setPosition"
  >
    <template v-if="allowPresets(chartObject as any)">
      <div class="vl"></div>
      <div class="panel-item hint--top" :aria-label="translationStore.getTranslation('option#Presets')">
        <div class="panel-button" @click="selectOption('presets')">
          <IconElement iconName="Category" :size="18" />
        </div>
      </div>
      <div v-show="selectedOption === 'presets'" class="panel-item-expanded panel-dropdown presets-dropdown">
        <div class="panel-dropdown-item" @click="openPresetDialog">
          {{ saveObjectCaption }} ...
        </div>
        <hr v-if="presets.length > 0" class="my-1" />
        <div v-for="(preset, presetIndex) in presets" :key="preset.id ?? -1" class="panel-dropdown-item" @click="loadPreset(preset)">
          <input v-if="editedPresetIndex === presetIndex" v-model="preset.name" v-click-outside="() => editPresetName(null)" />
          <span v-else>{{ preset.name }}</span>
          <IconElement iconName="Pencil" :size="16" class="ms-auto" @click.stop="editPresetName(presetIndex)" />
          <IconElement iconName="Save" :size="16" class="ms-2" @click.stop="savePreset(preset)" />
          <IconElement iconName="Delete" :size="16" class="ms-2" @click.stop="deletePreset(preset)" />
        </div>
      </div>
    </template>
    <template v-for="option in options">
      <div class="vl"></div>
      <div class="panel-item hint--top" :aria-label="getOptionLabel(option)" @click="selectOption(option.name)">
        <ChartOptionWrapper :option="option">
          <template #boolean="slotProps">
            <div
              class="panel-button" :class="{ active: slotProps.value }"
              @click="slotProps.setValue(!slotProps.value)"
            >
              <IconElement :iconName="getIcon(option)" :size="18" />
            </div>
          </template>
          <template #color="slotProps">
            <div class="panel-button">
              <ColorPickerButton
                ref="colorPickerButtons" :modelValue="slotProps.value" :allowGradient="true" :size="26"
                :alphaPresetColors="isAreaColor(option)" @update:modelValue="setColor(slotProps.setValue, $event)"
              />
            </div>
          </template>
          <template #numeric="slotProps">
            <input
              class="panel-input" type="number" :value="slotProps.value"
              @input="slotProps.setValue(processNumeric($event) ?? 0)"
            />
          </template>
          <template #lineWidth="slotProps">
            <div class="panel-button">
              <IconElement :iconName="getLineWidthIcon(option.getValue())" :size="24" />
            </div>
            <div v-show="selectedOption === option.name" class="panel-item-expanded panel-dropdown">
              <div
                v-for="lineWidthItem in lineWidthItems" :key="lineWidthItem.value"
                class="panel-dropdown-item" @click="slotProps.setValue(lineWidthItem.value)"
              >
                <IconElement :size="24" :iconName="lineWidthItem.icon" />
                {{ translationStore.getTranslation(lineWidthItem.key) }}
              </div>
            </div>
          </template>
          <template #enum="slotProps">
            <div v-if="option.name === OptionName.PlotType">
              <div class="panel-button">
                <IconElement :iconName="getPlotTypeIcon(option.getValue())" :size="24" />
              </div>
              <div v-show="selectedOption === option.name" class="panel-item-expanded panel-dropdown">
                <div
                  v-for="plotTypeItem in plotTypeItems" :key="plotTypeItem.key"
                  class="panel-dropdown-item" @click="slotProps.setValue(plotTypeItem.value)"
                >
                  <IconElement :size="24" :iconName="plotTypeItem.icon" />
                  {{ translationStore.getTranslation(plotTypeItem.key) }}
                </div>
              </div>
            </div>
          </template>
        </ChartOptionWrapper>
      </div>
    </template>
    <template v-if="allowAlert">
      <div class="vl"></div>
      <div class="panel-item hint--top" :aria-label="translationStore.getTranslation('option#CreateAlert')">
        <div class="panel-button" @click="createAlert">
          <IconElement iconName="Bell" :size="22" />
        </div>
      </div>
    </template>
    <template v-if="allowPublic">
      <div class="vl"></div>
      <div class="panel-item hint--top" :aria-label="translationStore.getTranslation('option#PublishTool')">
        <div class="panel-button" :class="{ active: isPublic }" @click="tooglePublic">
          <IconElement iconName="World" :size="22" />
        </div>
      </div>
    </template>
    <template v-if="allowCopy">
      <div class="vl"></div>
      <div class="panel-item hint--top" :aria-label="translationStore.getTranslation('option#Copy')">
        <div class="panel-button" @click="clone">
          <IconElement iconName="Clone" :size="22" />
        </div>
      </div>
    </template>
    <template v-if="allowDelete">
      <div class="vl"></div>
      <div class="panel-item hint--top" :aria-label="translationStore.getTranslation('option#Delete')">
        <div class="panel-button" @click="remove">
          <IconElement iconName="Delete" :size="22" />
        </div>
      </div>
    </template>
    <div class="vl"></div>
    <div class="panel-item hint--top" :aria-label="translationStore.getTranslation('option#Pin')">
      <div class="panel-button" :class="{ active: isPinned }" @click="pinPanel">
        <IconElement :iconName="isPinned ? 'PinActive' : 'PinInactive'" :size="18" />
      </div>
    </div>

    <!-- <template v-for="parameter in parameters" :key="parameter.name">
      <div class="vl"></div>
      <div>
        <input
          :id="parameter.name" class="panel-parameter" type="number" :name="parameter.name" :value="parameter.getValue()"
          @input="setParameterValue(parameter, $event)"
        />
      </div>
    </template> -->
    <ModalDialog ref="presetDialog" :title="saveObjectCaption" labelSave="button_save" @save="saveNewPreset">
      <template #body>
        <div class="input-group">
          <label class="me-3" for="chart-template-name">
            {{ translationStore.getTranslation("edit_panel#save_preset#label_name") }}
          </label>
          <input
            id="chart-template-name" v-model="newPresetName" type="text" class="form-control form-control-sm"
            :placeholder="translationStore.getTranslation('edit_panel#save_preset#placeholder_name')" required
          />
        </div>
      </template>
    </ModalDialog>
  </DraggablePanel>
</template>

<script lang="ts">
import type { ChartColor } from "@/anfin-chart/draw/chart-color";
import { Indicator } from "@/anfin-chart/indicator";
import type { ColorDefinition } from "@/anfin-chart/indicator-definition";
import { applyOptions, type ChartObject, ChartOption, getOptionDefinitions } from "@/anfin-chart/options/option";
import { PlotType } from "@/anfin-chart/plot";
import type { Consumer } from "@/anfin-chart/utils";
import { ChartObjectPreset } from "@/api/models/user-settings/chart";
import { ClickOutside } from "@/directives/click-outside";
import { Draggable } from "@/directives/draggable";
import { InputUtilsMixin } from "@/mixins/input-utils";
import { chartOptionStore, MultiChartOptionManager } from "@/stores/chart-option-store";
import { translationStore } from "@/stores/translation-store";
import { ChartHandler } from "@/utils/ChartHandler";
import ColorPickerButton from "@/views/color-picker/ColorPickerButton.vue";
import DraggablePanel, { DraggablePanelContainer } from "@/views/panels/DraggablePanel.vue";
import ModalDialog from "@/views/components/ModalDialog.vue";
import IconElement from "@/views/icons/IconElement.vue";
import ChartOptionWrapper from "@/views/toolbar/ChartOptionWrapper.vue";
import { storeToRefs } from "pinia";
import { defineComponent, markRaw, nextTick, reactive, toRaw } from "vue";
import { presetStore } from "@/stores/preset-store";
import { OptionName } from "@/anfin-chart/options/option-manager";
import { Point } from "@/anfin-chart/geometry";
import { Alert, AlertRule, AlertUserToolDefinition, SimpleAlertCondition } from "@/api/models/alert";
import { ChartObjectPanelData, uiStateStore } from "@/stores/ui-state-store";
import { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolController } from "@/api/user-tool-controller";
import { userRightStore } from "@/stores/user-right-store";
import { UserRightKey } from "@/api/models/user-right";

class PanelDropdownItem {

  constructor(public readonly key: string,
              public readonly value: number,
              public readonly icon: string) {
  }
}

export default defineComponent({
  name: "ChartObjectPanel",

  components: { DraggablePanel, ChartOptionWrapper, ColorPickerButton, ModalDialog, IconElement },

  directives: { ClickOutside, Draggable },

  mixins: [InputUtilsMixin],

  props: {
    container: {
      type: DraggablePanelContainer,
      required: true
    }
  },

  expose: ["show", "hide"],

  data() {
    const { optionManager } = storeToRefs(chartOptionStore());
    const canPublish = userRightStore().isAdmin && userRightStore().hasRight(UserRightKey.PublishTool);
    const { toolPresets, indicatorPresets } = storeToRefs(presetStore());
    const { chartObjectPanelData } = storeToRefs(uiStateStore());
    return {
      optionManager: optionManager as unknown as MultiChartOptionManager,
      toolPresets: toolPresets as unknown as ChartObjectPreset[],
      indicatorPresets: indicatorPresets as unknown as ChartObjectPreset[],
      panelData: chartObjectPanelData as unknown as ChartObjectPanelData | null,
      isVisible: false,
      chartHandler: ChartHandler.getInstance(),
      lineWidthItems: [
        new PanelDropdownItem("line_width#xs", 0.5, "LineWidthXS"),
        new PanelDropdownItem("line_width#s", 1, "LineWidthS"),
        new PanelDropdownItem("line_width#m", 2, "LineWidthM"),
        new PanelDropdownItem("line_width#l", 3, "LineWidthL"),
        new PanelDropdownItem("line_width#xl", 4, "LineWidthXL")
      ],
      plotTypeItems: [
        new PanelDropdownItem("plot_type#" + PlotType.Line, PlotType.Line, "PlotTypeLine"),
        new PanelDropdownItem("plot_type#" + PlotType.Candle, PlotType.Candle, "PlotTypeCandle"),
        new PanelDropdownItem("plot_type#" + PlotType.Mountain, PlotType.Mountain, "PlotTypeMountain")
      ],
      chartObject: null as ChartObject | null,
      selectedOption: null as string | null,
      ignoredOptions: new Set<string>([OptionName.FreehandTracePoints, OptionName.TextContent]),
      newPresetName: "",
      editedPresetIndex: null as number | null,
      hideTimeout: null as number | null,
      translationStore: translationStore(),
      OptionName,
      canPublish: canPublish as unknown as boolean
    };
  },

  computed: {
    isPinned() {
      return this.optionManager.chartObjectEditBarPinned.getValue();
    },

    position() {
      const [x, y] = this.optionManager.chartObjectEditBarPosition.getValue();
      return new Point(x, y);
    },

    options(): ChartOption<any>[] {
      if (this.chartObject == null) {
        return [];
      }
      const optionMap = reactive(this.chartObject.optionMap);
      const options = [];
      for (const option of optionMap.values()) {
        options.push(reactive(option) as ChartOption<any>);
      }
      return options;
    },

    presets(): ChartObjectPreset[] {
      if (this.isIndicator) {
        const indicator = this.chartObject as Indicator;
        return this.indicatorPresets.filter(p => p.type === indicator.type);
      }
      if (this.isUserTool) {
        const userTool = this.chartObject as UserTool;
        return this.toolPresets.filter(p => p.type === userTool.definition.type);
      }
      return [];
    },

    saveObjectCaption(): string {
      if (this.isIndicator) {
        return this.translationStore.getTranslation("edit_panel#save_indicator_caption");
      }
      if (this.isUserTool) {
        return this.translationStore.getTranslation("edit_panel#save_tool_caption");
      }
      return "";
    },

    isIndicator() {
      return this.chartObject instanceof Indicator;
    },

    isUserTool() {
      return this.chartObject instanceof UserTool;
    },

    allowAlert() {
      return this.chartObject instanceof UserTool && this.chartObject.definition.hookProvider.count > 0;
    },

    allowPublic() {
      return this.canPublish && this.chartObject instanceof UserTool;
    },

    isPublic() {
      return this.chartObject instanceof UserTool && this.chartObject.definition.isPublic;
    },

    allowCopy() {
      return this.isIndicator || this.isUserTool;
    },

    allowDelete() {
      return this.isIndicator || this.isUserTool;
    }
  },

  watch: {
    panelData(data: ChartObjectPanelData | null) {
      if (data == null) {
        this.hide();
      } else {
        this.show(data.chartObject, data.position);
      }
    },

    position() {
      const colorPickerButtons = this.$refs.colorPickerButtons as (typeof ColorPickerButton)[];
      if (colorPickerButtons == null) {
        return;
      }
      for (const button of colorPickerButtons) {
        button.checkPosition();
      }
    }
  },

  methods: {
    show(chartObject: ChartObject, position: Point) {
      window.setTimeout(() => {
        if (this.hideTimeout != null) {
          window.clearTimeout(this.hideTimeout);
          this.hideTimeout = null;
        }
        if (chartObject !== this.chartObject) {
          this.chartObject = markRaw(chartObject);
          this.isVisible = true;
          this.initializePosition(position);
          this.resetPanels();
        }
      });
    },

    hide() {
      this.hideTimeout = window.setTimeout(() => {
        this.chartObject = null;
        this.isVisible = false;
      });
    },

    initializePosition(position: Point) {
      let adjustedPosition: Point;
      if (this.isPinned) {
        adjustedPosition = this.position;
      } else {
        const ratio = window.devicePixelRatio;
        const offset = 10 * ratio;
        adjustedPosition = new Point(position.x / ratio + offset, position.y / ratio + offset);
      }
      nextTick(() => {
        const panel = this.$refs.panel as typeof DraggablePanel;
        panel.setPosition(adjustedPosition);
      });
    },

    setPosition(position: Point) {
      this.optionManager.chartObjectEditBarPosition.setValue([position.x, position.y]);
    },

    getPlotTypeIcon(plotType: number) {
      const plotTypeIndex = this.plotTypeItems.findIndex( x => x.value === plotType);
      return this.plotTypeItems[plotTypeIndex].icon;
    },

    getLineWidthIcon(lineWidth: number) {
      const lineWidthIndex = this.lineWidthItems.findIndex( x => x.value === lineWidth);
      return this.lineWidthItems[lineWidthIndex].icon;
    },

    getIcon(option: ChartOption<any>) {
      switch (option.name) {
        case OptionName.ExpandLeft:
          return "LineExpandLeft";
        case OptionName.ExpandRight:
          return "LineExpandRight";
        case OptionName.TextBold:
          return "TextBold";
        case OptionName.TextItalic:
          return "TextItalic";
        default:
          return "Check";
      }
    },

    onClickOutside() {
      if (this.editedPresetIndex != null) {
        this.editPresetName(null);
        return true;
      }
      if (this.selectedOption != null) {
        this.selectedOption = null;
        return true;
      }
      this.hide();
      return false;
    },

    selectOption(name: string) {
      this.selectedOption = this.selectedOption === name ? null : name;
    },

    setColor(setter: Consumer<ChartColor>, colorDefinition: ColorDefinition) {
      setter(colorDefinition.toColor());
    },

    getChart() {
      return this.chartObject?.getSubChart().chart ?? null;
    },

    createAlert() {
      const chart = this.getChart();
      if (this.chartObject instanceof UserTool && this.chartObject.definition.id != null && chart != null) {
        const hook = this.chartObject.definition.hookProvider.alertHooks[0];
        const definition = new AlertUserToolDefinition(this.chartObject.definition.id, hook?.basePointIndex ?? 0, hook?.targetPointIndex, hook?.percentageOffset);
        const rule = AlertRule.createDefault(chart, definition);
        const condition = new SimpleAlertCondition(0);
        const alert = new Alert(null, "", [rule], condition);
        uiStateStore().setEditedAlert(alert);
      }
    },

    tooglePublic() {
      if (this.chartObject instanceof UserTool) {
        this.chartObject.definition.isPublic = !this.chartObject.definition.isPublic;
        UserToolController.getInstance().saveUserTool(this.chartObject.definition);
      }
    },

    clone() {
      if (this.chartObject != null) {
        this.getChart()?.cloneChartObject(toRaw(this.chartObject) as ChartObject);
      }
    },

    remove() {
      if (this.chartObject != null) {
        this.getChart()?.removeChartObject(this.chartObject as ChartObject);
      }
      this.hide();
    },

    pinPanel() {
      this.optionManager.chartObjectEditBarPinned.setValue(!this.isPinned);
    },

    resetPanels() {
      this.selectedOption = null;
    },

    allowPresets(obj: ChartObject | null): obj is Indicator | UserTool {
      return obj instanceof Indicator || obj instanceof UserTool;
    },

    loadPreset(preset: ChartObjectPreset) {
      const chartObject = this.chartObject as ChartObject | null;
      if (this.allowPresets(chartObject)) {
        applyOptions(chartObject, preset.options);
      }
    },

    openPresetDialog() {
      (this.$refs.presetDialog as typeof ModalDialog).show();
    },

    saveNewPreset() {
      const chartObject = this.chartObject as ChartObject | null;
      if (this.allowPresets(chartObject)) {
        const type = chartObject instanceof Indicator ? chartObject.type : chartObject.definition.type;
        this.savePreset(new ChartObjectPreset(null, this.newPresetName, type, []));
      }
    },

    savePreset(preset: ChartObjectPreset) {
      if (this.chartObject == null) {
        return;
      }
      const options = getOptionDefinitions(this.chartObject);
      preset.options = options.filter(o => !this.ignoredOptions.has(o.name));
      if (this.isIndicator) {
        presetStore().saveIndicatorPreset(preset);
      } else if (this.isUserTool) {
        presetStore().saveToolPreset(preset);
      }
    },

    deletePreset(preset: ChartObjectPreset) {
      if (this.isIndicator) {
        presetStore().deleteIndicatorPreset(preset);
      } else if (this.isUserTool) {
        presetStore().deleteToolPreset(preset);
      }
    },

    editPresetName(index: number | null) {
      if (this.editedPresetIndex === index) {
        return;
      }
      if (this.editedPresetIndex != null) {
        const preset = this.presets[this.editedPresetIndex];
        this.savePreset(preset);
      }
      this.editedPresetIndex = index;
    },

    getOptionLabel(option: ChartOption<any>) {
      const typedObject = this.chartObject as Indicator | UserTool;
      let key = "option#" + typedObject.type + "#" + option.name;
      let translation = translationStore().getTranslation(key, false);
      if (translation === "") {
        key = "option#" + option.name;
        translation = translationStore().getTranslation(key);
      }
      return translation;
    },

    isAreaColor(option: ChartOption<any>) {
      // TODO discuss the final solution with Hamed and Frank
      // const label = this.getOptionLabel(option);
      return false; // label.indexOf("läche") > 0;
    }
  }
});
</script>

<style scoped>
.tool-edit-panel {
  user-select: none;
}

.vl {
  border-left: 1px solid rgba(185, 185, 185, 0.49);
  margin: 2px 5px;
  height: 2em;
}

.panel-item {
  position: relative;
}

.panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1px solid var(--border-neutral);
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.panel-button:hover, .panel-button:focus, .panel-button.active {
  background-color: var(--background-overlay);
  border: 1px solid var(--border-overlay);
}

.panel-item-expanded {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border: 1px solid var(--border-overlay);
  border-radius: 3px;
}

.panel-dropdown {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  border-radius: 6px;
  padding: 6px 0;
  background-color: var(--background-elevated);
}

.panel-dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px;
  cursor: pointer;
  gap: 10px;
  color: var(--content-secondary);
  stroke: var(--content-secondary);
  border: 1px solid #00000000;
}

.panel-dropdown-item:hover {
  background-color: var(--background-overlay);
  border: 1px solid var(--border-overlay);
}

.panel-input {
  font-size: 90%;
  width: 4em;
  height: 2em;
}

.presets-dropdown {
  padding: 3px;
  min-width: 250px;
}
</style>

<template>
  <Sketch :modelValue="rgbaColor" :presetColors="presetColors" @update:modelValue="setColor" />
</template>

<script lang="ts">
import type { RGBA } from "@/anfin-chart/draw/chart-color";
import { ColorDefinition, ColorType } from "@/anfin-chart/indicator-definition";
import { type Payload, Sketch } from "@ckpack/vue-color";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RgbaColorPicker",

  components: { Sketch },

  props: {
    modelValue: {
      type: ColorDefinition,
      required: true
    },
    alphaPresetColors: {
      type: Boolean,
      required: false
    }
  },

  emits: {
    "update:modelValue": (color: ColorDefinition) => color && true
  },

  expose: [],

  computed: {
    rgbaColor() {
      return this.modelValue as RGBA;
    },
    presetColors() {
      if (this.alphaPresetColors) {
        return [
          "#ee042320", "#F5A62320", "#F8E71C20", "#8B572A20", "#7ED32120",
          "#41750520", "#BD10E020", "#9013FE20", "#4A90E220", "#50E3C220",
          "#B8E98620", "#00000020", "#4A4A4A20", "#9B9B9B20", "#FFFFFF20",
          "rgba(0,0,0,0)"
        ];
      }
      return [
        "#ee0423", "#F5A623", "#F8E71C", "#8B572A", "#7ED321",
        "#417505", "#BD10E0", "#9013FE", "#4A90E2", "#50E3C2",
        "#B8E986", "#000000", "#4A4A4A", "#9B9B9B", "#FFFFFF",
        "rgba(0,0,0,0)"
      ];
    }
  },

  methods: {
    setColor(value: Payload) {
      if (value.rgba == null) {
        return;
      }
      const r = Number(value.rgba.r);
      const g = Number(value.rgba.g);
      const b = Number(value.rgba.b);
      const a = Number(value.rgba.a);
      const colorDefinition = new ColorDefinition(ColorType.Rgba, r, g, b, a, this.modelValue.stops);
      this.$emit("update:modelValue", colorDefinition);
    }
  }
});
</script>

<style scoped>

</style>

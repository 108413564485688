import type { Consumer } from "@/anfin-chart/utils";

export class ToolbarItemData {

  public parent: ToolbarItemData | null = null;
  public subItems: ToolbarItemData[] = [];
  public isSelected = false;
  public textColor: string | undefined;
  public noSelection = false;
  public iconColor: string | undefined;
  public iconSize = 24;
  public iconRight: string | undefined;

  constructor(public readonly key: string,
              public readonly name: string | null,
              public icon: string | null,
              public readonly onSelect: Consumer<void> | null = null,
              public readonly onSave: Consumer<void> | null = null,
              public readonly onDelete: Consumer<void> | null = null,
              public readonly favoriteKey: string | null = null) {
  }

  public setSubItems(subItems: ToolbarItemData[]) {
    this.subItems = [];
    for (const subItem of subItems) {
      this.addSubItem(subItem);
    }
  }

  public addSubItem(subItem: ToolbarItemData) {
    this.subItems.push(subItem);
    subItem.parent = this;
  }

  public setSelected(selected: boolean) {
    this.isSelected = selected;
    if (selected) {
      this.parent?.setSelected(true);
    } else {
      for (const subItem of this.subItems) {
        subItem.setSelected(false);
      }
    }
  }
}

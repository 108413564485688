<template>
  <LoginFormTemplate buttonLabel="login#button_register" :contentSize="450" @submit="handleSubmit">
    <div class="row">
      <div class="col-6">
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_salutation") }}
        </label>
        <SingleSelect v-model="salutation" :items="salutationItems" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_first_name") }}
        </label>
        <input
          v-model="firstName" type="text" name="firstName" class="form-control" autocomplete="give-name"
          :placeholder="translationStore.getTranslation('login#label_first_name')"
        />
      </div>
      <div class="col-6">
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_last_name") }}
        </label>
        <input
          v-model="lastName" type="text" name="lastName" class="form-control" autocomplete="family-name"
          :placeholder="translationStore.getTranslation('login#label_last_name')"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div>
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_address") }}
        </label>
        <input
          v-model="address" type="text" name="address" class="form-control" autocomplete="street-address"
          :placeholder="translationStore.getTranslation('login#label_address')"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_postal_code") }}
        </label>
        <input
          v-model="postalCode" type="text" name="postalCode" class="form-control" autocomplete="postal-code"
          :placeholder="translationStore.getTranslation('login#label_postal_code')"
        />
      </div>
      <div class="col-6">
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_city") }}
        </label>
        <input
          v-model="city" type="text" name="city" class="form-control" autocomplete="address-level2"
          :placeholder="translationStore.getTranslation('login#label_city')"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div>
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_phone_number") }}
        </label>
        <input
          v-model="phoneNumber" type="text" name="phoneNumber" class="form-control" autocomplete="tel"
          :placeholder="translationStore.getTranslation('login#label_phone_number')"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_email") }}
        </label>
        <input
          v-model="email" type="email" name="email" class="form-control" autocomplete="email"
          :placeholder="translationStore.getTranslation('login#label_email')"
        />
      </div>
      <div class="col-6">
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_password") }}
        </label>
        <input
          v-model="password" type="password" name="password" class="form-control" autocomplete="new-password"
          :placeholder="translationStore.getTranslation('login#label_password')"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div>
        <label class="form-label">
          {{ translationStore.getTranslation("login#label_user_name") }}
        </label>
        <input
          v-model="userName" type="text" name="userName" class="form-control" autocomplete="username"
          :placeholder="translationStore.getTranslation('login#label_user_name')"
        />
      </div>
    </div>
    <div class="mt-2">
      <router-link class="link" to="/login">
        {{ translationStore.getTranslation("login#link_login") }}
      </router-link>
    </div>
  </LoginFormTemplate>
</template>

<script lang="ts">
import { getEnumValues } from "@/anfin-chart/utils";
import { AuthenticationController } from "@/api/authentication-controller";
import { UserInfo } from "@/api/models/user-info";
import { translationStore } from "@/stores/translation-store";
import { userRightStore } from "@/stores/user-right-store";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import LoginFormTemplate from "@/views/login/LoginFormTemplate.vue";
import { defineComponent } from "vue";

export enum Salutation {
  Mr = 0,
  Mrs = 1
}

export default defineComponent({
  name: "RegisterForm",

  components: { LoginFormTemplate, SingleSelect },

  emits: {
    redirect: () => true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      salutation: null as number | null,
      salutationItems: getEnumValues(Salutation).map(s => new SingleSelectItem("salutation#" + s, null, s)),
      firstName: "",
      lastName: "",
      address: "",
      postalCode: "",
      city: "",
      phoneNumber: "",
      email: "",
      password: "",
      userName: ""
    };
  },

  methods: {
    async handleSubmit() {
      if (this.salutation != null && this.firstName !== "" && this.email !== "" && this.password !== "") {
        const result = await AuthenticationController.getInstance().sendRegister(
          this.salutation, this.firstName, this.lastName, this.address, this.postalCode, this.city,
          this.phoneNumber, this.email, this.password, this.userName
        );
        const token = result.data.token;
        const userInfo = new UserInfo(this.userName, this.email, this.firstName, this.lastName, token, false);
        userRightStore().setUserInfo(userInfo);
        this.$emit("redirect");
      } else {
        console.log("A first name, username and password must be present");
      }
    }
  }
});
</script>

<style scoped>
</style>

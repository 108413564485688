import { defineComponent } from "vue";
import moment from "moment";
import "moment/min/locales";
import "moment-timezone";
import { translationStore } from "@/stores/translation-store";

export const DateMixin = defineComponent({
  computed: {
    locale() {
      return translationStore().currentLanguage;
    }
  },

  watch: {
    locale(value: string) {
      moment.locale(value);
    }
  },

  methods: {
    formatDate(date: Date | number) {
      return this.formatAs(date, "date_format#date");
    },

    formatTime(date: Date | number) {
      return this.formatAs(date, "date_format#time");
    },

    formatDateTime(date: Date | number) {
      return this.formatAs(date, "date_format#date_time");
    },

    isToday(date: Date | number) {
      const d = new Date(date);
      const today = new Date();
      return d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear();
    },

    formatAs(date: Date | number, key: string) {
      const format = translationStore().getTranslation(key);
      return moment(date).format(format);
    }
  }
});

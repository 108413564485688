<template>
  <div v-if="option.name === '-'" class="sep-line border-top">
  </div>
  <div v-else-if="option.name === OptionName.ShownTrendLineTypes">
    <slot name="trendLineTypes" :value="trendLineTypesValue" :disabled="disabled" :setValue="setTrendLineTypes"></slot>
  </div>
  <div v-else-if="option.name === OptionName.LineWidth">
    <slot name="lineWidth" :value="numericValue" :disabled="disabled" :setValue="setNumeric"></slot>
  </div>
  <div v-else-if="option.getType() === OptionValueType.Enum">
    <slot name="enum" :value="numericValue" :disabled="disabled" :setValue="setNumeric"></slot>
  </div>
  <div v-else-if="option.getType() === OptionValueType.Boolean">
    <slot name="boolean" :value="booleanValue" :disabled="disabled" :setValue="setBoolean" :toggle="toggleBoolean"></slot>
  </div>
  <div v-else-if="option.getType() === OptionValueType.Color">
    <slot name="color" :value="colorValue" :disabled="disabled" :setValue="setColor"></slot>
  </div>
  <div v-else-if="option.getType() === OptionValueType.Numeric">
    <slot name="numeric" :value="numericValue" :disabled="disabled" :setValue="setNumeric"></slot>
  </div>
</template>

<script lang="ts">
import type { ChartColor } from "@/anfin-chart/draw/chart-color";
import { ColorDefinition } from "@/anfin-chart/indicator-definition";
import { type ChartOption, DevicePixelOption } from "@/anfin-chart/options/option";
import { OptionValueType } from "@/anfin-chart/options/option";
import type { TrendLineType } from "@/api/models/analysis/auto-trend-line";
import { translationStore } from "@/stores/translation-store";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { OptionName } from "@/anfin-chart/options/option-manager";

export default defineComponent({
  name: "ChartOptionWrapper",

  props: {
    option: {
      type: Object as PropType<ChartOption<any>>,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      OptionName,
      OptionValueType
    };
  },

  computed: {
    booleanValue() {
      return this.option.getValue() as boolean;
    },

    colorValue() {
      return ColorDefinition.fromColor(this.option.getValue() as ChartColor);
    },

    trendLineTypesValue() {
      return this.option.getValue() as TrendLineType[];
    },

    numericValue() {
      if (this.option instanceof DevicePixelOption) {
        return this.option.getPlainValue();
      }
      return this.option.getValue() as number;
    }
  },

  methods: {
    setBoolean(value: boolean) {
      this.option.setValue(value);
    },

    toggleBoolean() {
      this.option.setValue(!this.option.getValue());
    },

    setTrendLineTypes(value: TrendLineType[]) {
      this.option.setValue(value);
    },

    setColor(value: ChartColor) {
      this.option.setValue(value);
    },

    setNumeric(value: number) {
      this.option.setValue(value);
    }
  }
});
</script>

<style scoped>
.sep-line {
  min-height: 0;
  padding: 0.5rem;
  margin-top: -0.5rem;
}
</style>

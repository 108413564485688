<template>
  <div v-click-outside="hide" class="toolbar-item" @click.stop="onItemSelect" @mouseup.stop @mouseover="show" @mouseleave="hide">
    <div v-if="item.name === '-'" class="toolbar-item-line"></div>
    <div v-else class="toolbar-item-sub" :class="{ selected: isActive || item.isSelected, 'toolbar-item-no-select': item.noSelection }">
      <div v-if="item.icon != null" class="toolbar-icon" :class="{ 'mx-1': showName }">
        <IconElement :iconName="item.icon" :size="item.iconSize" :color="item.iconColor" />
      </div>
      <div v-if="showName" class="toolbar-item-name" :class="{ 'mx-1': item.icon == null }" :style="{ color: item.textColor }">
        <span v-if="item.name != null">{{ item.name }}</span>
        <span v-else>{{ translationStore.getTranslation(item.key) }}</span>
      </div>
      <div v-if="showName && (item.favoriteKey != null || item.onSave != null || item.onDelete != null || item.iconRight != null)" class="toolbar-item-actions me-1">
        <IconElement v-if="item.favoriteKey != null" iconName="Star" :size="20" :color="isFavorite(item) ? getColor() : undefined" :fillcolor="isFavorite(item) ? getColor() : 'transparent'" @click.stop="toggleFavorite(item.favoriteKey)" />
        <IconElement v-if="item.onSave != null" iconName="Save" :size="18" @click.stop="item.onSave" />
        <IconElement v-if="item.onDelete != null" iconName="Delete" :size="18" @click.stop="item.onDelete" />
        <IconElement v-if="item.iconRight != null" :iconName="item.iconRight" :size="item.iconSize" :color="item.iconColor" />
      </div>
      <IconElement v-if="item.subItems.length > 0" class="expand-icon" iconName="ExpandRight" :size="6" />
      <div v-if="item.subItems.length > 0" v-show="isActive" class="toolbar-sub-items">
        <ToolbarItem v-for="subItem in item.subItems" :key="subItem.key" :item="subItem" :showName="true" @hide="hide" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ClickOutside } from "@/directives/click-outside";
import { favoriteStore } from "@/stores/favorite-store";
import { ChartHandler } from "@/utils/ChartHandler";
import IconElement from "@/views/icons/IconElement.vue";
import { ToolbarItemData } from "@/views/toolbar/toolbar-item-data";
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import { getColorByCSSDefine } from "@/anfin-chart/utils";

export default defineComponent({
  name: "ToolbarItem",

  components: { IconElement },

  directives: { ClickOutside },

  props: {
    item: {
      type: ToolbarItemData,
      required: true
    },
    showName: Boolean
  },

  emits: {
    hide: () => true
  },

  expose: ["show", "hide"],

  data() {
    return {
      chartHandler: ChartHandler.getInstance(),
      translationStore: translationStore(),
      favoriteStore: favoriteStore(),
      isActive: false
    };
  },

  methods: {
    onItemSelect() {
      if (this.item.onSelect === null) {
        this.show();
      } else {
        this.item.onSelect();
        this.hide();
        this.$emit("hide");
      }
    },

    getColor() {
      return getColorByCSSDefine("--interactive-accent");
    },

    isFavorite(item: ToolbarItemData) {
      return item.favoriteKey != null && this.favoriteStore.isFavorite(item.favoriteKey);
    },

    toggleFavorite(favoriteKey: string) {
      this.favoriteStore.toggle(favoriteKey);
    },

    show() {
      this.isActive = true;
    },

    hide() {
      this.isActive = false;
    }
  }
});
</script>

<style>
.toolbar-item {
  background-color: var(--background-elevated);
  padding: 1px 0;
}

.left-toolbar > .toolbar-item {
  background-color: #00000000;
}

.toolbar-item-sub {
  gap: 8px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 5px;
  user-select: none;
  -webkit-user-select: none;
  border: 1px solid #00000000;
  color: var(--content-primary);
}

.left-toolbar > .toolbar-item > .toolbar-item-sub {
  margin: 3px 7px;
  height: 26px;
  padding: 5px;
  border-radius: 5px;;
  padding-top: 16px;
  padding-bottom: 16px;
}

.toolbar-item-sub > .toolbar-item > .toolbar-item-sub {
  border-radius: 0;
}

.toolbar-item-actions {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  gap: 5px;
}

.toolbar-sub-items > .toolbar-item {
  border-radius: 0;
  margin: 1px 0;
  height: 30px;
}

.toolbar-item-sub:not(.toolbar-item-no-select):hover,
.toolbar-item-sub.selected:not(.toolbar-item-no-select) {
  border: 1px solid var(--border-overlay);
  color: var(--content-primary-top);
  stroke: var(--content-primary-top);
  fill: var(--content-primary-top);
  background-color: var(--background-primary-overlay);
}

.left-toolbar > .toolbar-item > .toolbar-item-sub:hover,
.left-toolbar > .toolbar-item > .toolbar-item-sub.selected {
  border: 1px solid var(--border-overlay);
}

.toolbar-item-name {
  margin: 1px;
}

.expand-icon {
  position: absolute;
  bottom: 2px;
  right: 2px;
}

.left-toolbar > .toolbar-item > .toolbar-item-sub > .expand-icon {
  position: absolute;
  bottom: 2px;
  right: 2px;
}

.toolbar-sub-items {
  position: absolute;
  box-shadow: 0 4px 12px 0 rgba(241, 242, 246, 0.10);
  z-index: 1000;
  top: 0;
  left: 100%;
  padding: 4px 0;
  margin-left: 1px;
  border-radius: 0 10px 10px 0;
  border: 1px solid var(--border-neutral);
  white-space: nowrap;
  background-color: var(--background-elevated);
}

.left-toolbar > .toolbar-item > .toolbar-item-sub > .toolbar-sub-items {
  margin: 0 4px;
  left: 37px;
}

.toolbar-item-line {
  margin: 4px 3px;
  height: 1px;
  background-color: var(--border-neutral);
}
</style>

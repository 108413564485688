import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { LineElement } from "@/anfin-chart/drawable";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { Point } from "@/anfin-chart/geometry";
import { UserToolAlertHook } from "@/anfin-chart/tools/alert-hook";

export class HorizontalLine extends UserToolDefinition {

  public static readonly type = "hline";

  private readonly color = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(22, 168, 22));

  private readonly base = new ChartToolPoint();
  private readonly left = new ChartToolPoint();
  private readonly right = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(HorizontalLine.type, instrument, timeframe, 1, 1);
    new UserToolAlertHook(this, "main", this.base, 0);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
  }

  public override updatePosition(tool: UserTool) {
    const basePosition = tool.getPosition(this.fixedPoints[0]);
    const rect = tool.subChart.drawingArea.getPosition();
    const left = new Point(rect.xStart, basePosition.y);
    const right = new Point(rect.xEnd, basePosition.y);
    tool.updatePoint(this.base, basePosition);
    tool.updatePoint(this.left, left);
    tool.updatePoint(this.right, right);
  }

  private createFirst(tool: UserTool) {
    const line = new LineElement(tool, this.left, this.right);
    tool.onUpdate(() => {
      line.color = this.color.getValue();
      line.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }
}

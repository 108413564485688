<template>
  <ColorPickerButtonTemplate :modelValue="modelValue" :size="size" :allowSwitchType="false" @update:modelValue="onChange">
    <template #rgba="slotProps">
      <RgbaColorPicker :modelValue="slotProps.value" @update:modelValue="slotProps.setValue" />
    </template>
  </ColorPickerButtonTemplate>
</template>

<script lang="ts">
import { ColorDefinition, ColorType } from "@/anfin-chart/indicator-definition";
import ColorPickerButtonTemplate from "@/views/color-picker/ColorPickerButtonTemplate.vue";
import RgbaColorPicker from "@/views/color-picker/RgbaColorPicker.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RgbaColorPickerButton",

  components: { RgbaColorPicker, ColorPickerButtonTemplate },

  props: {
    modelValue: {
      type: ColorDefinition,
      required: true
    },
    size: {
      type: Number,
      required: true
    }
  },

  emits: {
    "update:modelValue": (color: ColorDefinition) => color && true
  },

  expose: [],

  data() {
    return {
      ColorType
    };
  },

  methods: {
    onChange(color: ColorDefinition) {
      this.$emit("update:modelValue", color);
    }
  }
});
</script>

<style scoped>
</style>

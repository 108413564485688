<template>
  <PageWrapper>
    <div class="login-container">
      <div class="login">
        <div v-if="translationLanguages.length > 1" class="login-language-selection">
          <img v-for="item in translationLanguages" :key="item.key" class="login-language-button" :src="item.getIcon()" :alt="item.caption" @click="translationStore.setLanguage(item.key)"/>
        </div>
        <AnfinLogo :style="LogoStyle.Dark" class="login-logo" />
        <label class="login-header mb-4">
          {{ translationStore.getTranslation(headerKey) }}
        </label>
        <RegisterForm v-if="isRegisterForm" @redirect="loadURL" />
        <ResetPasswordForm v-else-if="isResetForm" />
        <ResetNewPasswordForm v-else-if="isResetNewPasswordForm" />
        <LoginForm v-else @redirect="loadURL" />
        <div class="login-version">
          Version: {{ version?.toString() ?? "" }}-{{ commitId }}
        </div>
      </div>
    </div>
  </PageWrapper>
</template>

<script lang="ts">
import { translationStore } from "@/stores/translation-store";
import LoginForm from "@/views/login/LoginForm.vue";
import RegisterForm from "@/views/login/RegisterForm.vue";
import ResetNewPasswordForm from "@/views/login/ResetNewPasswordForm.vue";
import ResetPasswordForm from "@/views/login/ResetPasswordForm.vue";
import PageWrapper from "@/views/PageWrapper.vue";
import { useRoute } from "vue-router";
import { LogoStyle } from "@/api/models/theme";
import AnfinLogo from "@/views/components/AnfinLogo.vue";
import { storeToRefs } from "pinia";
import { Version, versionStore } from "@/stores/version-store";
import ToolbarItem from "@/views/toolbar/ToolbarItem.vue";

export default {
  name: "LoginPage",

  components: { ToolbarItem, AnfinLogo, ResetNewPasswordForm, ResetPasswordForm, RegisterForm, LoginForm, PageWrapper },

  expose: [],

  data() {
    const { version, commitId } = storeToRefs(versionStore());
    return {
      translationStore: translationStore(),
      version: version as unknown as Version | null,
      commitId: commitId as unknown as string,
      resetToken: this.$route.query ? this.$route.query.token : "",
      redirectedFrom: this.$route.redirectedFrom?.fullPath ?? null,
      LogoStyle
    };
  },

  computed: {
    headerKey() {
      if (this.isRegisterForm) {
        return "login#header_register";
      }
      if (this.isResetForm) {
        return "login#header_reset";
      }
      if (this.isResetNewPasswordForm) {
        return "login#header_new_password";
      }
      return "login#header_login";
    },

    translationLanguages() {
      return translationStore().getLanguages();
    },

    isRegisterForm() {
      const route = useRoute();
      return route.path.indexOf("/register") >= 0;
    },

    isResetForm() {
      const route = useRoute();
      return route.path.indexOf("/reset") >= 0 && route.query.token == null;
    },

    isResetNewPasswordForm() {
      const route = useRoute();
      return route.path.indexOf("/reset") >= 0 && route.query.token != null;
    }
  },

  methods: {
    loadURL() {
      let redirectTo = "/chart";
      if (this.redirectedFrom != null && this.redirectedFrom !== "" && !this.redirectedFrom.includes("/login")) {
        redirectTo = this.redirectedFrom;
      }
      this.$router.push(redirectTo).catch(() => {});
    }
  }
};
</script>

<style>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
}

.login {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to bottom, #00300b 0%, #00300b 30%, #114f29 70%, #00300b 100%);
  box-shadow: 0 0 8px 6px rgba(77, 77, 77, 0.7);
  border-radius: 1em;
  overflow: hidden;
  max-width: 650px;
  min-width: 320px;
  width: 100%;
}

.login label {
  color: #FFFFFF;
}

.login-language-selection {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 10px;
  right: 10px;
}

.login-language-button {
  height: 20px;
  border: 1px solid #D0D0D0;
  margin: 5px;
}

.login-logo {
  width: 250px;
  margin: 40px;
}

.login-header {
  font-size: 175%;
}

.login-version {
  position: absolute;
  bottom: 4px;
  right: 8px;
  color: #CCCCCC;
  font-size: 80%;
}
</style>

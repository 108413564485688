import { AuthenticationController } from "@/api/authentication-controller";
import type { ExchangeInquiry, UserInfo } from "@/api/models/user-info";
import { UserInfoConverter } from "@/api/models/user-info";
import type { UserRight, UserRightKey } from "@/api/models/user-right";
import { resetApplicationState } from "@/stores/utils";
import { ChartHandler } from "@/utils/ChartHandler";
import { defineStore } from "pinia";
import { loggingStore } from "@/stores/logging-store";

export const userRightStore = defineStore({
  id: "user-right",

  state() {
    return {
      userInfo: null as UserInfo | null,
      rightsMap: new Map<UserRightKey, UserRight>()
    };
  },

  getters: {
    hasRight() {
      return (key: UserRightKey, subKey: string | null = null) => {
        const right = this.rightsMap.get(key);
        return right != null && (subKey == null || right.details.includes(subKey));
      };
    },

    deviceType(): string {
      if (this.isSmartPhone) {
        return "SmartPhone";
      } else if (this.isTablet) {
        return "Tablet";
      }
      return "default";
    },

    isAdmin(): boolean {
      return this.userInfo?.isAdmin ?? false;
    },

    isMobile(): boolean {
      return this.isSmartPhone || this.isTablet;
    },

    isSmartPhone(): boolean {
      return this.userInfo?.isSmartPhone ?? false;
    },

    isTablet(): boolean {
      return this.userInfo?.isTablet ?? false;
    }
  },

  actions: {
    async requestRights() {
      const rights = await AuthenticationController.getInstance().getRights();
      this.setRights(rights);
    },

    setRights(rights: UserRight[]) {
      if (this.userInfo != null) {
        this.userInfo.rights = rights;
      }
      this.rightsMap.clear();
      for (const right of rights) {
        this.rightsMap.set(right.key, right);
      }
    },

    logout(redirectTo = "/login") {
      this.setUserInfo(null);
      this.router.push(redirectTo);
    },

    setUserInfo(userInfo: UserInfo | null) {
      const previousUserInfo = this.userInfo;
      this.userInfo = userInfo;
      const rights = userInfo?.rights ?? [];
      this.setRights(rights);
      if (userInfo == null) {
        localStorage.removeItem("user");
        ChartHandler.clear();
      } else {
        this.storeUserInfo();
        loggingStore().setConsoleRedirect();
        ChartHandler.getInstance().setToken(userInfo.token);
        this.requestRights();
      }
      if (previousUserInfo == null || userInfo == null) {
        resetApplicationState(this);
      }
    },

    storeUserInfo() {
      if (this.userInfo == null) {
        return;
      }
      const storedUserInfo = new UserInfoConverter().toApiObject(this.userInfo);
      const json = JSON.stringify(storedUserInfo);
      localStorage.setItem("user", json);
    },

    async validateUserInfo() {
      if (this.userInfo == null) {
        return;
      }
      const loginResult = await AuthenticationController.getInstance().validateUserInfo();
      this.setUserInfo(loginResult.userInfo);
    },

    async saveExchangeInquiry(exchangeInquiry: ExchangeInquiry) {
      if (this.userInfo == null) {
        throw new Error("Could not determine user for exchange inquiry");
      }
      this.userInfo.exchangeInquiry = exchangeInquiry;
      this.storeUserInfo();
      await AuthenticationController.getInstance().saveExchangeInquiry(exchangeInquiry);
    }
  }
});

export enum NotificationChannelType {
  Desktop = 0,
  Email = 1,
  IOSPush = 2,
  AndroidPush = 3
}

export class NotificationChannel {

  constructor(public readonly type: NotificationChannelType,
              public readonly target: string,
              public isEnabled: boolean,
              public isDefault: boolean,
              public readonly description: string) {
  }
}

export enum NotificationSoundType {
  Bell = 0,
  Pop = 1,
  NoSound = 99
}

export class NotificationSound {

  public readonly audio: HTMLAudioElement | null;

  constructor(public readonly type: NotificationSoundType, url: string | null = null) {
    if (url) {
      this.audio = new Audio(url);
    } else {
      this.audio = null;
    }
  }
}

import { ApiModelConverter } from "@/api/messages/converter";
import { InstrumentDetail } from "@/api/models/instrument-detail";

export interface InstrumentDetailListResponse {
  data: InstrumentDetailResponse[];
}

export interface InstrumentDetailResponse {
  id: string;
  caption: string;
  currency: string;
  isin: string;
  asset: string;
  printName: string;
  search: string;
  prio: number;
  precision: number;
}

export class InstrumentDetailConverter extends ApiModelConverter<InstrumentDetail, InstrumentDetailResponse> {

  public override toApiObject(instrumentDetail: InstrumentDetail): InstrumentDetailResponse {
    throw new Error("Not implemented");
  }

  public override toModelObject(response: InstrumentDetailResponse) {
    const printName = response.printName ?? response.id;
    const nameSplit = printName.split(":");
    const name = nameSplit[0];
    const exchange = nameSplit[1] ?? name;
    const asset = translateAsset(response.asset, exchange);
    const pricePrecision = response.precision < 99 && response.precision > 0 ? response.precision : null;
    return new InstrumentDetail(
      response.id, name, printName, exchange, response.caption, response.currency, asset, response.search, response.prio, pricePrecision
    );
  }
}

function translateAsset(symbol: string, exchange: string) {
  // TODO add final handle to server
  if (!symbol) {
    if (exchange === "BINANCE") {
      return "Crypto";
    }
    return "";
  } else if (symbol.includes("Fund")) {
    return "Fund";
  } else if (symbol.includes("Depository-Receipt")) {
    return "DepR";
  } else if (symbol.includes("Preferred Stock")) {
    return "VZ";
  }
  return symbol;
}

import { ColorOption } from "@/anfin-chart/options/option";
import type { ChartOptionManager } from "@/anfin-chart/options/option-manager";

export enum ChartColorOptionName {
  Background = "background",
  AxisLabel = "axisLabel",
  DebugInfo = "debugInfo",
  CaptionPrimary = "captionPrimary",
  CaptionSecondary = "captionSecondary",
  PriceDiffPositive = "priceDiffPositive",
  PriceDiffNegative = "priceDiffNegative",
  HelperLine = "helperLine",
  AxisLine = "axisLine",
  Crosshair = "crosshair",
  PriceMarkerMouse = "priceMarkerMouse",
  PriceMarkerMain = "priceMarkerMain",
  PriceMarkerPreTrading = "priceMarkerPreTrading",
  PriceMarkerText = "priceMarkerText",
  TimeMarker = "timeMarker",
  TimeMarkerText = "timeMarkerText",
  CaptionButtonBackground = "captionButtonBackground",
  CaptionButtonText = "captionButtonText",
  SubChartSeparator = "subChartSeparator",
  SubChartSeparatorHover = "subChartSeparatorHover",
  ToolPointBorder = "toolPointBorder",
  ToolPointFill = "toolPointFill",
  ActionButton = "actionButton",
  ActionButtonActive = "actionButtonActive",
  HintText = "hintText",
  HintBackground = "hintBackground",
  ToolText = "toolText",
  GlobalToolIcon = "globalToolIcon",
  SharedTimeframesText = "sharedTimeframesText"
}

export class ChartColorOptions implements Record<ChartColorOptionName, ColorOption> {

  public readonly background: ColorOption;
  public readonly axisLabel: ColorOption;
  public readonly debugInfo: ColorOption;
  public readonly captionPrimary: ColorOption;
  public readonly captionSecondary: ColorOption;
  public readonly priceDiffPositive: ColorOption;
  public readonly priceDiffNegative: ColorOption;
  public readonly helperLine: ColorOption;
  public readonly axisLine: ColorOption;
  public readonly crosshair: ColorOption;
  public readonly priceMarkerMouse: ColorOption;
  public readonly priceMarkerMain: ColorOption;
  public readonly priceMarkerPreTrading: ColorOption;
  public readonly priceMarkerText: ColorOption;
  public readonly timeMarker: ColorOption;
  public readonly timeMarkerText: ColorOption;
  public readonly captionButtonBackground: ColorOption;
  public readonly captionButtonText: ColorOption;
  public readonly subChartSeparator: ColorOption;
  public readonly subChartSeparatorHover: ColorOption;
  public readonly toolPointBorder: ColorOption;
  public readonly toolPointFill: ColorOption;
  public readonly actionButton: ColorOption;
  public readonly actionButtonActive: ColorOption;
  public readonly hintText: ColorOption;
  public readonly hintBackground: ColorOption;
  public readonly toolText: ColorOption;
  public readonly globalToolIcon: ColorOption;
  public readonly sharedTimeframesText: ColorOption;

  constructor(optionManager: ChartOptionManager) {
    this.background = new ColorOption(optionManager, ChartColorOptionName.Background);
    this.axisLabel = new ColorOption(optionManager, ChartColorOptionName.AxisLabel);
    this.debugInfo = new ColorOption(optionManager, ChartColorOptionName.DebugInfo);
    this.captionPrimary = new ColorOption(optionManager, ChartColorOptionName.CaptionPrimary);
    this.captionSecondary = new ColorOption(optionManager, ChartColorOptionName.CaptionSecondary);
    this.priceDiffPositive = new ColorOption(optionManager, ChartColorOptionName.PriceDiffPositive);
    this.priceDiffNegative = new ColorOption(optionManager, ChartColorOptionName.PriceDiffNegative);
    this.helperLine = new ColorOption(optionManager, ChartColorOptionName.HelperLine);
    this.axisLine = new ColorOption(optionManager, ChartColorOptionName.AxisLine);
    this.crosshair = new ColorOption(optionManager, ChartColorOptionName.Crosshair);
    this.priceMarkerMouse = new ColorOption(optionManager, ChartColorOptionName.PriceMarkerMouse);
    this.priceMarkerMain = new ColorOption(optionManager, ChartColorOptionName.PriceMarkerMain);
    this.priceMarkerPreTrading = new ColorOption(optionManager, ChartColorOptionName.PriceMarkerPreTrading);
    this.priceMarkerText = new ColorOption(optionManager, ChartColorOptionName.PriceMarkerText);
    this.timeMarker = new ColorOption(optionManager, ChartColorOptionName.TimeMarker);
    this.timeMarkerText = new ColorOption(optionManager, ChartColorOptionName.TimeMarkerText);
    this.captionButtonBackground = new ColorOption(optionManager, ChartColorOptionName.CaptionButtonBackground);
    this.captionButtonText = new ColorOption(optionManager, ChartColorOptionName.CaptionButtonText);
    this.subChartSeparator = new ColorOption(optionManager, ChartColorOptionName.SubChartSeparator);
    this.subChartSeparatorHover = new ColorOption(optionManager, ChartColorOptionName.SubChartSeparatorHover);
    this.toolPointBorder = new ColorOption(optionManager, ChartColorOptionName.ToolPointBorder);
    this.toolPointFill = new ColorOption(optionManager, ChartColorOptionName.ToolPointFill);
    this.actionButton = new ColorOption(optionManager, ChartColorOptionName.ActionButton);
    this.actionButtonActive = new ColorOption(optionManager, ChartColorOptionName.ActionButtonActive);
    this.hintText = new ColorOption(optionManager, ChartColorOptionName.HintText);
    this.hintBackground = new ColorOption(optionManager, ChartColorOptionName.HintBackground);
    this.toolText = new ColorOption(optionManager, ChartColorOptionName.ToolText);
    this.globalToolIcon = new ColorOption(optionManager, ChartColorOptionName.GlobalToolIcon);
    this.sharedTimeframesText = new ColorOption(optionManager, ChartColorOptionName.SharedTimeframesText);
  }
}

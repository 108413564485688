import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { LineOptions, LineStyle, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { LineElement, TextBox } from "@/anfin-chart/drawable";
import { ChartError } from "@/anfin-chart/error";
import { Point, Vector } from "@/anfin-chart/geometry";
import type { InstrumentData } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import {
  AutoFibonacci,
  AutoFibonacciLevel,
  AutoFibonacciType,
  getFibonacciTypeColor
} from "@/api/models/analysis/auto-fibonacci";
import { DelayedExecutor, formatPercentage } from "@/anfin-chart/utils";
import type { ColorOption } from "@/anfin-chart/options/option";
import { AnalysisTool } from "@/anfin-chart/tools/analysis-tool";
import type { AlertToolData } from "@/anfin-chart/tools/alert-tool";
import type { AlertAutoToolDefinition } from "@/api/models/alert";

export class AutoFibonacciTool extends AnalysisTool {

  private static readonly hoverExecutor = new DelayedExecutor(200);
  private static hoveredInstance: AutoFibonacciTool | null = null;

  private readonly colorOption: ColorOption;
  private readonly levelTextAlignment = new TextAlignment(new Vector(1, -1), 2);

  private readonly priceTextStart = new ChartToolPoint();
  private readonly priceLineStart = new ChartToolPoint();
  private readonly priceLineEnd = new ChartToolPoint();
  private readonly retracementTextStart = new ChartToolPoint();
  private readonly retracementLineStart = new ChartToolPoint();
  private readonly retracementLineEnd = new ChartToolPoint();
  private priceTextBox: TextBox | null = null;
  private retracementTextBox: TextBox | null = null;

  constructor(public readonly definition: AutoFibonacci,
              instrumentData: InstrumentData,
              subChart: SubChart) {
    super(definition, instrumentData, subChart);
    this.colorOption = this.getColorOption();
  }

  public override getIsVisible() {
    return super.getIsVisible() && this.isVisibleType() && (AutoFibonacciTool.hoveredInstance == null ||
      AutoFibonacciTool.hoveredInstance === this);
  }

  public override onHoverChange() {
    super.onHoverChange();
    AutoFibonacciTool.hoverExecutor.cancel();
    if (this.isHovered) {
      AutoFibonacciTool.hoverExecutor.post(() => {
        AutoFibonacciTool.hoveredInstance = this;
        this.updateDrawables();
      });
    } else {
      AutoFibonacciTool.hoveredInstance = null;
      this.updateDrawables();
    }
  }

  protected override createDrawables() {
    for (const level of this.definition.levels) {
      this.createLevelLine(level);
    }
    const priceTextProvider = () => {
      const price = this.getInstrumentPrice();
      const label = this.chart.callbacks.getTranslation("fibonacci#price_line");
      return label + " - " + this.formatPricePercentage(price);
    };
    this.priceTextBox = this.addHoverTextBox(this.priceTextStart, -1, priceTextProvider);
    this.addHoverLine(this.priceLineStart, this.priceLineEnd);
    const retracementPrice = this.definition.retracementPrice;
    if (retracementPrice == null) {
      this.retracementTextBox = null;
    } else {
      const retracementTextProvider = () => {
        const label = this.chart.callbacks.getTranslation("fibonacci#retracement_line");
        return label + " - " + this.formatPricePercentage(retracementPrice);
      };
      this.retracementTextBox = this.addHoverTextBox(this.retracementTextStart, -1, retracementTextProvider);
      this.addHoverLine(this.retracementLineStart, this.retracementLineEnd);
    }
  }

  protected override updatePositionInternal() {
    const xStart = this.chart.timeAxis.getXForTime(this.definition.startTime);
    const yPrice = this.subChart.priceAxis.getY(this.getInstrumentPrice());
    const priceWidth = this.priceTextBox?.currentPosition.width ?? 0;
    const retracementWidth = this.retracementTextBox?.currentPosition.width ?? 0;
    const width = Math.max(priceWidth, retracementWidth);

    const priceLineStart = new Point(xStart, yPrice);
    this.updatePoint(this.priceLineStart, priceLineStart);
    const priceLineEnd = new Point(xStart - width, yPrice);
    this.updatePoint(this.priceLineEnd, priceLineEnd);

    let priceTextY = yPrice;
    if (this.definition.retracementPrice != null) {
      const yRetracement = this.subChart.priceAxis.getY(this.definition.retracementPrice);
      const retracementLineStart = new Point(xStart, yRetracement);
      this.updatePoint(this.retracementLineStart, retracementLineStart);
      const xRetracementEnd = xStart - Math.max(width, retracementWidth);
      const retracementLineEnd = new Point(xRetracementEnd, yRetracement);
      this.updatePoint(this.retracementLineEnd, retracementLineEnd);

      let retracementTextY = yRetracement;
      const minDistance = this.chart.styleOptions.fibonacciHorizontalDistanceTolerance.getValue();
      const priceRetracementDistance = Math.abs(retracementLineStart.y - priceLineStart.y);
      if (width > 0 && priceRetracementDistance < minDistance) {
        const shiftSize = this.chart.styleOptions.fibonacciHorizontalDistanceShift.getValue();
        if (retracementLineStart.y > priceLineStart.y) {
          retracementTextY += shiftSize;
        } else {
          priceTextY += shiftSize;
        }
      }
      const retracementTextStart = new Point(retracementLineStart.x, retracementTextY);
      this.updatePoint(this.retracementTextStart, retracementTextStart);
    }
    const priceTextStart = new Point(priceLineStart.x, priceTextY);
    this.updatePoint(this.priceTextStart, priceTextStart);

    this.updateLevelPositions();
  }

  protected override createAlertDrawables(data: AlertToolData, index: number) {
    const definition = data.data.definition as AlertAutoToolDefinition;
    data.priceChangeConsumer = (price: number) => {
      definition.percentageOffset = this.definition.getRetracementPercentage(price);
    };
    const nextData = this.alertDatas[index + 1];
    data.createLine(nextData);
    const textBox = data.createText(this.levelTextAlignment);
    this.onUpdate(() => {
      const isHighlighted = this.isHighlighted();
      textBox.setIsVisible(isHighlighted);
      textBox.boxColor = this.getTextBoxBackground();
      if (definition.percentageOffset != null) {
        const price = this.definition.getRetracementPrice(definition.percentageOffset);
        textBox.setText(this.getLevelText(price));
      }
      const isShowAreas = isHighlighted && (data.isHovered() || nextData != null && nextData.isHovered());
      for (const polygon of data.polygons) {
        polygon.setIsVisible(isShowAreas);
      }
    });
  }

  protected override updateAlertPosition(data: AlertToolData) {
    super.updateAlertPosition(data);
    const definition = data.data.definition as AlertAutoToolDefinition;
    if (definition.percentageOffset != null) {
      const price = this.definition.getRetracementPrice(definition.percentageOffset);
      this.updatePriceLine(data, price);
    }
  }

  private updatePriceLine(data: AlertToolData, price: number) {
    const [xStart, xEnd] = this.getLevelXRange();
    data.updatePosition(xStart, xEnd, price);
    if (data.icon != null) {
      const y = this.getPosition(data.start).y;
      const iconSize = data.icon.size / 2;
      const offset = data.icon.size / 2 + this.chart.styleOptions.alertIconMargin.getValue() * 2;
      const iconCenter = new Point(xStart + iconSize, y + offset);
      this.updatePoint(data.iconCenter, iconCenter);
    }
  }

  private createLevelLine(level: AutoFibonacciLevel) {
    const lineOptions = new LineOptions(1, level.definition.lineStyle);
    const line = new LineElement(this, level.start, level.end, lineOptions);
    line.hook = level.hook;
    const isExtension = level.definition.isExtension;
    const textBox = new TextBox(this, level.start, this.levelTextAlignment);
    textBox.hook = level.hook;
    this.onUpdate(() => {
      const isHighlighted = this.isHighlighted();
      const color = isHighlighted ? getFibonacciTypeColor(this.definition.fibonacciType) : this.colorOption.getValue();
      line.color = color;
      line.hitTolerance = this.chart.styleOptions.autoFibonacciHitTolerance.getValue();
      textBox.color = color;
      textBox.boxColor = this.getTextBoxBackground();
      textBox.setText(this.getLevelText(level.price, isExtension));
      line.setIsVisible(!isExtension || isHighlighted);
      textBox.setIsVisible(isHighlighted);
    });
  }

  private getLevelText(price: number, isExtension = false) {
    const prefix = isExtension ? this.chart.callbacks.getTranslation("auto_tool#fibonacci#label_extension") + " " : "";
    return prefix + this.formatPricePercentage(price, isExtension);
  }

  private addHoverTextBox(start: ChartToolPoint, direction: 1 | -1, textProvider: () => string) {
    const offset = this.optionManager.fibonacciSidelineOffset.getValue();
    const alignment = new TextAlignment(new Vector(direction, -1), offset);
    const textBox = new TextBox(this, start, alignment);
    this.onUpdate(() => {
      textBox.color = this.chart.optionManager.chartColor.priceMarkerMain.getValue();
      textBox.boxColor = this.getTextBoxBackground();
      textBox.setText(textProvider());
      textBox.setIsVisible(this.isHighlighted());
    });
    return textBox;
  }

  private addHoverLine(start: ChartToolPoint, end: ChartToolPoint) {
    const lineOptions = new LineOptions(1, LineStyle.Solid);
    const line = new LineElement(this, start, end, lineOptions);
    this.onUpdate(() => {
      line.color = this.chart.optionManager.chartColor.priceMarkerMain.getValue();
      line.setIsVisible(this.isHighlighted());
    });
  }

  private updateLevelPositions() {
    const [xStart, xEnd] = this.getLevelXRange();
    for (const level of this.definition.levels) {
      const y = this.subChart.priceAxis.getY(level.price);
      const start = new Point(xStart, y);
      this.updatePoint(level.start, start);
      const end = new Point(xEnd, y);
      this.updatePoint(level.end, end);
    }
  }

  private getLevelXRange() {
    const maxLength = this.isHighlighted() ? null : this.optionManager.fibonacciCollapsedLength.getValue();
    const xStart = this.chart.timeAxis.getXForTime(this.definition.startTime);
    const xEnd = this.chart.timeAxis.getXForTime(this.definition.endTime);
    const xEndAdjusted = maxLength == null ? xEnd : Math.min(xStart + maxLength, xEnd);
    return [xStart, xEndAdjusted];
  }

  private isHighlighted() {
    return AutoFibonacciTool.hoveredInstance === this;
  }

  private getInstrumentPrice() {
    const instrumentData = this.chart.getMainInstrumentData();
    const item = instrumentData.mainPlot.store.getLast();
    return item?.main ?? 0;
  }

  private formatPricePercentage(price: number, isExtension = false) {
    let percentage = (this.definition.endPrice - price) / (this.definition.endPrice - this.definition.startPrice);
    if (isExtension) {
      percentage = 1 - percentage;
    }
    const priceFormatted = this.chart.formatPrice(price);
    const percentageFormatted = formatPercentage(percentage);
    return `${percentageFormatted} (${priceFormatted})`;
  }

  private isVisibleType() {
    const options = this.optionManager;
    switch (this.definition.fibonacciType) {
      case AutoFibonacciType.BasicX:
      case AutoFibonacciType.Basic1:
      case AutoFibonacciType.Basic2:
        return options.showFibonaccis.getValue();
      case AutoFibonacciType.Trend:
      case AutoFibonacciType.SubTrend:
        return options.showFibonacciTrends.getValue();
      default:
        return false;
    }
  }

  private getColorOption() {
    switch (this.definition.fibonacciType) {
      case AutoFibonacciType.BasicX:
      case AutoFibonacciType.Basic1:
      case AutoFibonacciType.Basic2:
        return this.colorOptions.autoFibonacciBasic;
      case AutoFibonacciType.Trend:
      case AutoFibonacciType.SubTrend:
        return this.definition.endPrice > this.definition.startPrice
          ? this.colorOptions.autoFibonacciAscending
          : this.colorOptions.autoFibonacciDescending;
      default:
        throw new ChartError("Unknown auto fibonacci type");
    }
  }

  private getTextBoxBackground() {
    const color = this.chart.optionManager.chartColor.background.getValue();
    if (color instanceof RGBAColor) {
      return color.withAlpha(0.9);
    }
    return color;
  }
}

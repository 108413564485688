<template>
  <div class="coming-soon">
    {{ translationStore.getTranslation("feature_coming_soon#" + featureKey) }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";

export default defineComponent({
  name: "ComingSoon",

  props: {
    featureKey: {
      type: String,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  }
});
</script>

<style scoped>
.coming-soon {
  font-weight: 500;
  color: var(--interactive-accent);
}
</style>
<template>
  <div class="settings-window">
    <div class="settings-window-caption">
      <span class="font-bold">
        {{ translationStore.getTranslation("settings_mode#" + mode) }}
      </span>
      <div class="settings-window-caption-right">
        <slot name="caption"></slot>
        <IconElement
          v-if="allowHide" v-shortkey="['esc']" class="close-button ms-2" iconName="Cross" :size="18"
          @shortkey="hide" @click="hide"
        />
      </div>
    </div>
    <div class="settings-container">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import IconElement from "@/views/icons/IconElement.vue";
import { translationStore } from "@/stores/translation-store";
import { SettingsWindowMode, uiStateStore } from "@/stores/ui-state-store";

export default defineComponent({
  name: "SettingsWindowWrapper",

  components: { IconElement },

  props: {
    mode: {
      type: [Number, null] as PropType<SettingsWindowMode | null>,
      required: true
    },
    allowHide: {
      type: Boolean
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  methods: {
    hide() {
      uiStateStore().setSettingsWindowMode(SettingsWindowMode.None);
    }
  }
});
</script>

<style scoped>
.settings-window {
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  border-left: 1px solid var(--border-overlay);
}

.settings-window-caption {
  display: flex;
  padding: 12px 18px;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid var(--border-overlay);
  background-color: var(--background-overlay);
  color: var(--content-primary);
}

.settings-window-caption-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.settings-container {
  flex-grow: 1;
  overflow-y: auto;
  color: var(--content-secondary);
  background-color: var(--background-screen);
}
</style>
<template>
  <div v-show="isOpened" class="dialog-backdrop">
    <div v-click-outside="onCloseAction" class="dialog-container" role="dialog">
      <div class="dialog-header px-3 pt-3 pb-2">
        <h6 class="dialog-title">
          {{ title }}
        </h6>
        <IconElement
          v-shortkey="['esc']" iconName="Cross" :size="18" class="close-button ms-auto" @shortkey="onCloseAction"
          @click="onCloseAction"
        />
      </div>
      <div class="dialog-body p-3">
        <slot name="body"></slot>
      </div>
      <div class="dialog-footer px-3 pb-3">
        <div class="button-stack justify-content-end">
          <button type="button" class="btn btn-secondary btn-sm" @click="onCloseAction">
            {{ translationStore.getTranslation("button_abort") }}
          </button>
          <button v-if="labelSave != null" type="button" class="btn btn-primary btn-sm" @click="save">
            {{ translationStore.getTranslation(labelSave) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ClickOutside } from "@/directives/click-outside";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent, nextTick, type PropType } from "vue";
import { translationStore } from "@/stores/translation-store";
import { onKeyStroke } from "@vueuse/core";

export default defineComponent({
  name: "ModalDialog",

  components: { IconElement },

  directives: { ClickOutside },

  props: {
    title: {
      type: String,
      required: true
    },
    autoFocus: {
      type: String,
      default: null
    },
    labelSave: {
      type: String as PropType<string | null>,
      default: null
    }
  },

  emits: {
    save: () => true,
    close: () => true
  },

  expose: ["show", "close"],

  data() {
    return {
      translationStore: translationStore(),
      isOpened: false
    };
  },

  mounted() {
    onKeyStroke(["Escape"], (e) => {
      if (e.key === "Escape") {
        this.onCloseAction();
      }
    });
  },

  methods: {
    show() {
      this.isOpened = true;
      if (this.autoFocus != null) {
        nextTick(() => {
          const focusElement = document.getElementById(this.autoFocus);
          focusElement?.focus();
        });
      }
    },

    close() {
      this.isOpened = false;
    },

    save() {
      this.$emit("save");
      this.close();
    },

    onCloseAction() {
      this.$emit("close");
      this.close();
    }
  }
});
</script>

<style scoped>
.dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333333B0;
  z-index: 10000;
  user-select: none;
}

.dialog-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 350px;
  color: var(--content-secondary);
  background: var(--background-neutral);
  border: 1px solid var(--border-neutral);
  border-radius: var(--border-radius-single);
  z-index: 10000;
  overflow: hidden;
  user-select: text;
}

.dialog-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--border-overlay);
}

.dialog-body {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow-y: auto;
}
</style>

import { getTimezoneOffset } from "@/anfin-chart/time/time-utils";

export class ExchangeInfo {

  public offsetTimezone = 0;

  constructor(public readonly name: string,
              public readonly timezone: string,
              public readonly startTradingTime: number,
              public readonly endTradingTime: number,
              public readonly startPreTradingTime: number,
              public readonly endPostTradingTime: number,
              public readonly outsideHoursExchange: string | null) {
    this.offsetTimezone = getTimezoneOffset(timezone) / 1000;
  }
}

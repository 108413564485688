import { ExchangeInquiryConverter } from "@/api/messages/exchange-inquiry";
import type { LoginResponse, RegisterResponse } from "@/api/messages/login";
import { RestApiController } from "@/api/rest-api-controller";
import type { UserRightListResponse } from "@/api/messages/user-rights";
import type { ExchangeInquiry } from "@/api/models/user-info";
import { UserRight, UserRightKey } from "@/api/models/user-right";
import { LoginResult, LoginResultConverter } from "@/api/models/login";
import type { AxiosError } from "axios";

export class AuthenticationController extends RestApiController {

  private static instance: AuthenticationController | null = null;

  private readonly inquiryConverter = new ExchangeInquiryConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new AuthenticationController();
    }
    return this.instance;
  }

  public async sendLogin(username: string, password: string) {
    const request = {
      USERNAME: username,
      PASSWORD: password
    };
    let response: LoginResponse;
    try {
      response = await this.sendPostRequest<LoginResponse>("login/user", request);
    } catch (e) {
      const typedError = e as AxiosError;
      if (typedError.response == null) {
        return new LoginResult(null, null, null);
      }
      response = typedError.response.data as LoginResponse;
    }
    return new LoginResultConverter().toModelObject(response);
  }

  public async sendRegister(salutation: number, firstName: string, lastName: string, address: string,
                            postalCode: string, city: string, phoneNumber: string, email: string, password: string,
                            userName: string) {
    const request = {
      TITLE: salutation,
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      ADDRESS_LINE_1: address,
      POSTAL_CODE: postalCode,
      CITY: city,
      CONTACT_DETAIL_1: phoneNumber,
      EMAIL: email,
      PASSWORD: password
    };
    return await this.sendPostRequest<RegisterResponse>("login/create/user", request);
  }

  public async validateUserInfo() {
    try {
      const response = await this.sendGetRequest<LoginResponse>("login/session/check");
      return new LoginResultConverter().toModelObject(response);
    } catch (e) {
      return new LoginResult(null, null);
    }
  }

  public async resetPassword(email: string) {
    const request = {
      email: email,
      password: "",
      password2: "",
      resetToken: ""
    };
    return await this.sendPostRequest<unknown>("login/reset", request);
  }

  public async sendNewPassword(token: string, password: string) {
    const request = {
      email: "",
      resetToken: token,
      password: password,
      password2: password
    };
    return await this.sendPostRequest<unknown>("login/resetpassword", request);
  }

  public async getRights() {
    const response = await this.sendGetRequest<UserRightListResponse>("login/rights");
    return response.rights.map(r => new UserRight(r.key as UserRightKey, r.details));
  }

  public async saveExchangeInquiry(inquiry: ExchangeInquiry) {
    const request = {
      exchangeUserState: this.inquiryConverter.toApiObject(inquiry)
    };
    return await this.sendPutRequest<unknown>("login/userstate/exchanges", request);
  }

  protected override getBaseUrl(): string {
    return this.environment.authenticationUrl;
  }
}

<template>
  <button
    :disabled="variant == null" type="button" class="btn add-to-cart"
    :class="isOutline ? 'btn-outline-primary' : 'btn-primary'" @click="addToCart"
  >
    {{ addButtonLabel }}
  </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { ShoppingProduct, ShoppingProductVariant } from "@/api/models/shopping-cart";
import { translationStore } from "@/stores/translation-store";
import { storeToRefs } from "pinia";
import { paymentStore } from "@/stores/payment-store";
import type { ShoppingCartResolved } from "@/api/models/checkout";

export default defineComponent({
  name: "AddToCartButton",

  props: {
    product: {
      type: ShoppingProduct,
      required: true
    },
    variant: {
      type: ShoppingProductVariant as PropType<ShoppingProductVariant | null>,
      required: true
    },
    isOutline: {
      type: Boolean
    }
  },

  expose: [],

  data() {
    const { currentCartData } = storeToRefs(paymentStore());
    return {
      translationStore: translationStore(),
      cartData: currentCartData as unknown as ShoppingCartResolved | null
    };
  },

  computed: {
    isAlreadyInCart() {
      const variant = this.variant;
      if (this.cartData == null || variant == null) {
        return false;
      }
      return this.cartData?.items.some(i => i.product.id === this.product.id && i.variant.id === variant.id);
    },

    addButtonLabel() {
      if (this.isAlreadyInCart) {
        return this.translationStore.getTranslation("product#already_in_cart");
      }
      return this.translationStore.getTranslation("product#add_to_cart");
    }
  },

  methods: {
    async addToCart() {
      if (this.variant == null) {
        return;
      }
      const store = paymentStore();
      await store.addCartItem(this.product.id, this.variant.id);
      const route = this.$router.resolve({ name: "Cart" });
      this.$router.push(route);
    }
  }
});
</script>

<style scoped>
.add-to-cart {
  font-size: 120%;
}
</style>
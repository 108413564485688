<template>
  <span class="circle" :style="css"></span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CircleElement",

  props: {
    size: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },

  expose: [],

  computed: {
    css() {
      return {
        width: this.size + "px",
        height: this.size + "px",
        backgroundColor: this.color
      };
    }
  }
});
</script>

<style scoped>
.circle {
  border-radius: 50%;
}
</style>
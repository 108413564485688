import { Instrument } from "@/anfin-chart/instrument";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { AnalysisData } from "@/api/models/analysis/analysis-data";
import { defineStore } from "pinia";
import type { AnalysisToolDefinition } from "@/api/models/analysis/analysis-tool-definition";
import { AnalysisController } from "@/api/analysis-controller";

export const analysisStore = defineStore({
  id: "analysis",

  state() {
    return {
      analysisData: new AnalysisData(Instrument.default, Timeframe.default),
      analysisMap: new Map<string, AnalysisToolDefinition>()
    };
  },

  getters: {
    analyses(): AnalysisToolDefinition[] {
      return Array.from(this.analysisMap.values());
    },

    getAnalysis() {
      return (id: string) => this.analysisMap.get(id) ?? null;
    }
  },

  actions: {
    async requestAnalyses(instrument: Instrument, timeframe: Timeframe, limit: number, start: number | null = null,
                          end: number | null = null) {
      const analysisData = await AnalysisController.getInstance().requestAnalyses(instrument, timeframe, limit, start, end);
      this.addAnalysisData(instrument, timeframe, analysisData);
      for (const analysis of analysisData.getAllAnalyses()) {
        this.analysisMap.set(analysis.id, analysis);
      }
      return analysisData;
    },

    addAnalysisData(instrument: Instrument, timeframe: Timeframe, analysisData: AnalysisData) {
      const isChanged = !Instrument.isSame(this.analysisData.instrument, instrument) ||
        !Timeframe.isSame(this.analysisData.timeframe, timeframe);
      if (isChanged) {
        this.analysisData = new AnalysisData(instrument, timeframe);
      }
      this.analysisData.include(analysisData);
    }
  }
});

import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { Rectangle } from "@/anfin-chart/geometry";
import { StackAlignment, StackDirection, StackPanel } from "@/anfin-chart/area/stack-panel";

export class CoreLayerArea extends StackPanel {

  public constructor(layer: ChartLayer) {
    super(layer, StackDirection.Vertical, StackAlignment.Stretch, layer.chart.styleOptions.subChartSpacing);
  }

  public override initializeEvents() {
    // no event listeners
  }

  protected override drawInternal() {
    const position = this.getPosition();
    this.drawer.clearRect(position);
    const color = this.chart.optionManager.chartColor.background.getValue();
    this.drawer.drawRect(position, null, color);
  }

  protected override resizeInternal() {
    const xStart = 0;
    const xEnd = this.layer.width;
    const yStart = 0;
    const yEnd = this.layer.height;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }
}

import { Timeframe, TimeUnit } from "@/anfin-chart/time/timeframe";
import { truncTo } from "@/anfin-chart/utils";

export enum WeekDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export function setTimezone(date: Date, timezone: string) {
  return new Date(date.toLocaleString("en-US", { timeZone: timezone }));
}

export function getTimezoneOffset(timezone: string, date = new Date()) {
  const utcDate = setTimezone(date, "UTC");
  const timezoneDate = setTimezone(date, timezone);
  return timezoneDate.getTime() - utcDate.getTime();
}

export function getWeekNumber(date: Date) {
  const utc = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  const weekDay = utc.getUTCDay() || 7;
  utc.setUTCDate(utc.getUTCDate() + 4 - weekDay);
  const yearStart = new Date(Date.UTC(utc.getUTCFullYear(), 0, 1));
  return Math.ceil(((utc.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
}

export function truncateDate(date: Date | number, timeframe: Timeframe, timeOffset: number) {
  const unit = timeframe.unit;
  const value = timeframe.value;
  const time = date instanceof Date ? date.getTime() : date;
  const result = new Date(time + timeOffset);
  switch (unit) {
    case TimeUnit.Millisecond:
      const truncatedMillisecond = truncTo(result.getUTCMilliseconds(), value);
      result.setUTCMilliseconds(truncatedMillisecond);
      break;
    case TimeUnit.Second:
      const truncatedSecond = truncTo(result.getUTCSeconds(), value);
      result.setUTCSeconds(truncatedSecond, 0);
      break;
    case TimeUnit.Minute:
      const truncatedMinute = truncTo(result.getUTCMinutes(), value);
      result.setUTCMinutes(truncatedMinute, 0, 0);
      break;
    case TimeUnit.Hour:
      const truncatedHour = truncTo(result.getUTCHours(), value);
      result.setUTCHours(truncatedHour, 0, 0, 0);
      break;
    case TimeUnit.Day:
      const truncatedDay = truncTo(result.getUTCDate(), value);
      result.setUTCDate(truncatedDay);
      result.setUTCHours(0, 0, 0, 0);
      // at D1 truncate only to UTC without offset
      timeOffset = 0;
      break;
    case TimeUnit.Week:
      const day = result.getUTCDate();
      const weekDay = result.getUTCDay() || 7;
      const week = getWeekNumber(result);
      const truncatedWeek = truncTo(week, value);
      const weekDifference = truncatedWeek - week;
      result.setUTCDate(day - weekDay - weekDifference * 7);
      result.setUTCHours(0, 0, 0, 0);
      break;
    case TimeUnit.Month:
      const truncatedMonth = truncTo(result.getUTCMonth() + 1, value);
      result.setUTCMonth(truncatedMonth - 1, 1);
      result.setUTCHours(0, 0, 0, 0);
      break;
    case TimeUnit.Year:
      const truncatedYear = truncTo(result.getUTCFullYear(), value);
      result.setUTCFullYear(truncatedYear, 0, 1);
      result.setUTCHours(0, 0, 0, 0);
      break;
    default:
      throw new RangeError("Unknown time unit: " + unit);
  }
  return new Date(result.getTime() - timeOffset);
}

export function addTimeframe(date: Date, timeframe: Timeframe) {
  const unit = timeframe.unit;
  const value = timeframe.value;
  const result = new Date(date);
  switch (unit) {
    case TimeUnit.Millisecond:
      result.setTime(result.getTime() + value);
      break;
    case TimeUnit.Second:
      result.setTime(result.getTime() + value * 1000);
      break;
    case TimeUnit.Minute:
      result.setTime(result.getTime() + value * 60000);
      break;
    case TimeUnit.Hour:
      result.setTime(result.getTime() + value * 3600000);
      break;
    case TimeUnit.Day:
      result.setDate(result.getDate() + value);
      break;
    case TimeUnit.Week:
      result.setDate(result.getDate() + value * 7);
      break;
    case TimeUnit.Month:
      result.setMonth(result.getMonth() + value);
      break;
    case TimeUnit.Year:
      result.setFullYear(result.getFullYear() + value);
      break;
    default:
      throw new RangeError("Unknown time unit: " + unit);
  }
  return result;
}

export function getTruncatedValue(date: Date, timeframe: Timeframe) {
  let value;
  switch (timeframe.unit) {
    case TimeUnit.Year:
      value = date.getFullYear();
      break;
    case TimeUnit.Month:
      value = date.getMonth();
      break;
    case TimeUnit.Week:
      value = getWeekNumber(date);
      break;
    case TimeUnit.Day:
      value = date.getDate();
      break;
    case TimeUnit.Hour:
      value = date.getHours();
      break;
    case TimeUnit.Minute:
      value = date.getMinutes();
      break;
    case TimeUnit.Second:
      value = date.getSeconds();
      break;
    case TimeUnit.Millisecond:
      value = date.getMilliseconds();
      break;
    default:
      throw new RangeError("Unknown timeframe");
  }
  return value - value % timeframe.value;
}

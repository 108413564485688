import { Instrument } from "@/anfin-chart/instrument";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { UserToolConverter } from "@/api/messages/user-tool";
import type { UserToolResponse } from "@/api/messages/user-tool";
import { ApiModelConverter } from "@/api/messages/converter";
import { ChartView } from "@/api/models/user-settings/chart";

export interface ChartViewResponse {
  id: number | null;
  name: string | null;
  symbol: string;
  timeframe: string;
  tools: UserToolResponse[];
}

export class ChartViewConverter extends ApiModelConverter<ChartView, ChartViewResponse> {

  private readonly toolConverter = new UserToolConverter();

  public override toApiObject(view: ChartView) {
    return {
      id: view.id,
      name: view.name,
      symbol: view.instrument.getSymbol(),
      timeframe: view.timeframe.toShortNotation(),
      tools: view.tools.map(t => this.toolConverter.toApiObject(t))
    };
  }

  public override toModelObject(view: ChartViewResponse) {
    return new ChartView(
      view.id,
      view.name,
      Instrument.fromSymbol(view.symbol),
      Timeframe.fromShortNotation(view.timeframe),
      view.tools.map(t => this.toolConverter.toModelObject(t))
    );
  }
}

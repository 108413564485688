<template>
  <LandingPageWrapper>
    <TestMainSection />
    <LandingTextSection :isExpandable="true">
      <div class="py-5">
        <div class="landing-text-header">
          Transformation Ihres Tradings - mit automatisierter Chartanalyse von AnFin
        </div>
        <div class="landing-section-split mt-5">
          <LandingText>
            <template #header>
              Ihr bestes Chartanalyse-Tool: Der Schlüssel zum Anlage-Erfolg
            </template>
            <template #content>
              <div>
                Willkommen bei AnFin, Ihrer revolutionären Plattform für
                <span class="fw-bold">automatische Chartanalyse</span>, die Ihr Trading transformiert.
              </div>
              <div>
                Mit AnFin erleben Sie präzise und schnelle Analysen. Zudem meistern Sie effektiv die
                komplexen Herausforderungen der
                <span class="fw-bold">Aktien- und Kryptoanalyse</span>
              </div>
              <div>
                Verabschieden Sie sich von langwierigen Lernprozessen und begrüßen Sie die Zukunft des Tradings
                und Investments mit modernster Technik.
              </div>
            </template>
          </LandingText>
          <LandingText>
            <template #header>
              Automatisierte Chartanalyse – Klar und unkompliziert
            </template>
            <template #content>
              <div>
                Unsere Technologie in den Paketen AnFin Pro und AnFin Ultra basiert auf jahrzehntelanger Erfahrung.
              </div>
              <div>
                Sie erkennt wichtige Chartelemente wie Unterstützungen, Widerstände und Trendlinien automatisch.
              </div>
              <div>
                Damit erhöht sich insbesondere die Genauigkeit Ihrer Analysen und Entscheidungen im Bereich
                der <span class="fw-bold">Aktien- und der Kryptoanalyse</span>.
              </div>
            </template>
          </LandingText>
        </div>
        <div class="landing-section-split mt-5">
          <div>
            <LandingVideo class="landing-text-video" />
          </div>
          <div>
            <LandingText class="landing-text-well">
              <template #header>
                <div class="d-flex">
                  <IconElement iconName="LightBulb" :size="24" />
                  <div class="mx-2">
                    Ihre Vorteile der automatisierten Unterstützung
                  </div>
                  <IconElement iconName="LightBulb" :size="24" />
                </div>
              </template>
              <template #content>
                <ul class="landing-text-list">
                  <li>
                    <span class="fw-bold">Effizienz</span>: Erleben Sie, wie die AnFin-Technologie
                    Ihre Analysezeit verkürzt und gleichzeitig die Genauigkeit erhöht.
                  </li>
                  <li>
                    <span class="fw-bold">Präzision</span>: Nutzen Sie präzise, automatisch eingezeichnete
                    Chartelemente, um klügere Anlageentscheidungen treffen zu können.
                  </li>
                  <li>
                    <span class="fw-bold">Lerneffekte</span>: Für Beginner und Fortgeschrittene, die
                    <span class="fw-bold">Chartanalyse lernen</span> und die Theorie live in Anwendung sehen möchten.
                  </li>
                  <li>
                    <span class="fw-bold">Vielseitigkeit</span>: Ideal für die Analyse verschiedener Märkte,
                    einschließlich Aktien und Kryptowährungen.
                  </li>
                </ul>
              </template>
            </LandingText>
          </div>
        </div>
      </div>
    </LandingTextSection>
    <TestProductSection class="py-md-5 py-4" />
    <SellingFactSection class="py-md-5 py-4" />
    <LandingTextSection :isExpandable="true">
      <div class="py-5">
        <div class="landing-section-split">
          <LandingText>
            <template #header>
              Optimieren Sie Ihr Trading mit AnFin’s Zeitersparnis-Technologie
            </template>
            <template #content>
              <div>
                Durch die automatisierte Einzeichnung bewährter Charttechnik-Elemente ermöglicht AnFin
                Ihnen eine schnelle und effiziente Marktanalyse.
              </div>
              <div>
                Das mühsame Erlernen und manuelle Zeichnen dieser Elemente entfällt. Somit können Sie
                schneller intelligente Schlussfolgerungen ziehen.
              </div>
              <div>
                Selbst erfahrene Händler profitieren von der Zeitersparnis, da das manuelle Einzeichnen
                in verschiedenen Zeiteinheiten und für verschiedene Assets sehr zeitaufwendig sein kann.
                AnFin minimiert darüber hinaus das Risiko von Zeichenfehlern und den daraus resultierenden
                ungünstigen Handelsentscheidungen.
              </div>
            </template>
          </LandingText>
          <LandingText>
            <template #header>
              Entdecken Sie die Vorzüge der AnFin-Analyse auch über verschiedene Zeitebenen
            </template>
            <template #content>
              <div>
                AnFin ist so gestaltet, dass es verschiedene Zeiteinheiten unterstützt: von Minutencharts
                für Daytrader bis hin zu Wochen- und Monatscharts für langfristig orientierte Investoren.
                Durch diese Flexibilität hat jeder Nutzer die für seine Handelsstrategie und seinen Zeitplan
                passenden <span class="fw-bold">Chartanalyse-Tools</span> zur Verfügung.
              </div>
              <div>
                Egal, ob Sie schnelle Entscheidungen treffen oder langfristige Trends analysieren möchten:
                AnFin passt sich Ihren Bedürfnissen an.
              </div>
            </template>
          </LandingText>
        </div>
        <div class="landing-section-split mt-5">
          <div class="landing-text-image">
            <img src="/src/assets/asset-categories-screenshot.png" class="w-100" />
          </div>
          <LandingText>
            <template #header>
              Meistern Sie mehr als Aktien- und Krypto-Analyse mit AnFin
            </template>
            <template #content>
              <div>
                Mit AnFin können Sie Ihre <span class="fw-bold">technische Analyse</span> über typische Märkte
                wie Aktien und Kryptowährungen hinaus erweitern.
              </div>
              <div>
                Unsere Plattform wird auch bei der Analyse von Rohstoffen, Devisen, ETFs und Indizes
                erfolgreich eingesetzt.
              </div>
              <div>
                Diese Vielseitigkeit ermöglicht es Händlern und Investoren, ein breites Spektrum
                von Assetklassen effizient zu überwachen und zu analysieren.
              </div>
              <div>
                So stehen Ihnen die Türen zu diversifizierten Anlagestrategien offen.
              </div>
            </template>
          </LandingText>
        </div>
      </div>
    </LandingTextSection>
    <AssetCategoriesSection class="py-lg-5 py-4" />
    <LandingTextSection>
      <div class="py-5">
        <div class="landing-section-split">
          <LandingText>
            <template #header>
              Steigern Sie Ihre Trading-Erfolge mit unserer revolutionären Unterstützung!
            </template>
            <template #content>
              <div>
                Registrieren Sie sich noch heute und entdecken Sie, wie unsere automatisierte Unterstützung
                Ihre Handelsstrategie transformieren kann.
              </div>
              <div>
                Erleben Sie selbst, wie Sie durch präzisere Analysen und effizientes Lernen Ihre
                Anlagefähigkeiten optimieren können.
              </div>
              <div>
                Sie haben Fragen? Kontaktieren Sie uns gerne.
              </div>
            </template>
          </LandingText>
          <div>
            <LandingText class="landing-text-well">
              <template #header>
                Warum AnFin wählen?
              </template>
              <template #content>
                <ul class="landing-text-list">
                  <li>
                    <span class="fw-bold">Verbesserte Entscheidungsfindung</span>: Profitieren Sie von
                    datengestützten Einsichten, die zu cleveren Entscheidungen beitragen können.
                  </li>
                  <li>
                    <span class="fw-bold">Zeitersparnis</span>: Konzentrieren Sie sich mehr auf Ihre
                    Anlagestrategien und weniger auf die technische Vorbereitung.
                  </li>
                  <li>
                    <span class="fw-bold">Benutzerfreundlichkeit</span>: Unsere Lösung ist einfach zu bedienen
                    und erfordert kein tiefgreifendes technisches Wissen.
                  </li>
                </ul>
              </template>
            </LandingText>
          </div>
        </div>
      </div>
    </LandingTextSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import SellingFactSection from "@/views/pages-landing/sections/SellingFactSection.vue";
import TestMainSection from "@/views/pages-landing-test/TestMainSection.vue";
import AssetCategoriesSection from "@/views/pages-landing/sections/AssetCategoriesSection.vue";
import LandingTextSection from "@/views/pages-landing-test/LandingTextSection.vue";
import TestProductSection from "@/views/pages-landing-test/TestProductSection.vue";
import LandingVideo from "@/views/pages-landing/LandingVideo.vue";
import IconElement from "@/views/icons/IconElement.vue";
import LandingText from "@/views/pages-landing-test/LandingText.vue";

export default defineComponent({
  name: "ChartAnalysisPage",

  components: {
    LandingText, IconElement, LandingVideo, TestProductSection,
    LandingTextSection, AssetCategoriesSection, TestMainSection, SellingFactSection,
    LandingPageWrapper
  },

  expose: []
});
</script>

<style scoped>
@media (min-width: 1200px) {
  .landing-section-split {
    gap: 100px;
  }
}

@media (max-width: 991px) {
  .landing-section-split {
    flex-direction: column;
  }
}

.landing-text-header {
  font-size: 200%;
  font-weight: 500;
}

.landing-text-well {
  border: 5px solid var(--interactive-accent);
  border-radius: var(--border-radius-double);
  padding: 20px 10px;
}

.landing-text-list {
  list-style-type: circle;
}

.landing-text-video {
  border: 5px solid var(--interactive-accent);
  border-radius: var(--border-radius-double);
}

.landing-text-image {
  border: 5px solid var(--interactive-accent);
  border-radius: var(--border-radius-double);
  overflow: hidden;
}
</style>
<template>
  <div class="right-toolbar">
    <div class="right-toolbar-button">
      <IconElement
        iconName="MarketOverview" :size="24" class="hint--left"
        :aria-label="translationStore.getTranslation('settings_mode#' + SettingsWindowMode.WatchlistOverview)"
        @click="toggleMarketOverview"
      />
    </div>
    <div class="right-toolbar-button">
      <IconElement
        iconName="Bell" :size="24" class="hint--left"
        :aria-label="translationStore.getTranslation('settings_mode#' + SettingsWindowMode.AlertOverview)"
        @click="toggleAlertOverview"
      />
    </div>
    <hr class="right-toolbar-separator" />
    <div class="right-toolbar-button">
      <IconElement
        iconName="Analyses" :size="24" class="hint--left"
        :aria-label="translationStore.getTranslation('settings_mode#' + SettingsWindowMode.Analysis)"
        @click="toggleAnalysisSettings"
      />
    </div>
    <div class="right-toolbar-button">
      <IconElement
        iconName="Keyboard" :size="24" class="hint--left"
        :aria-label="translationStore.getTranslation('settings_mode#' + SettingsWindowMode.Shortcut)"
        @click="toggleShortcuts"
      />
    </div>
    <div class="right-toolbar-button">
      <IconElement
        iconName="Chat" :size="24" class="hint--left"
        :aria-label="translationStore.getTranslation('settings_mode#' + SettingsWindowMode.Chat)"
        @click="toggleChat"
      />
      <CircleElement v-if="unseenMessages > 0" :size="14" :color="notificationColor" class="notification-dot" />
    </div>
    <div v-if="isDebuggerAllowed" class="right-toolbar-button">
      <IconElement
        iconName="Debugger" :size="24" class="hint--left"
        :aria-label="translationStore.getTranslation('settings_mode#' + SettingsWindowMode.Debugger)"
        @click="toggleDebugger"
      />
    </div>
    <div v-if="isIndicatorEditorAllowed" class="right-toolbar-button mt-auto">
      <IconElement
        iconName="CodePrompt" :size="24" class="hint--left"
        :aria-label="translationStore.getTranslation('toolbar#button_indicator_editor')"
        @click="toggleIndicatorDefinitions"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconElement from "@/views/icons/IconElement.vue";
import { translationStore } from "@/stores/translation-store";
import { storeToRefs } from "pinia";
import { userRightStore } from "@/stores/user-right-store";
import { chatStore } from "@/stores/chat-store";
import { SettingsWindowMode, uiStateStore } from "@/stores/ui-state-store";
import { UserRightKey } from "@/api/models/user-right";
import CircleElement from "@/views/components/CircleElement.vue";
import { getColorByCSSDefine } from "@/anfin-chart/utils";
import { notificationStore } from "@/stores/notification-store";

export default defineComponent({
  name: "RightToolbar",

  components: { CircleElement, IconElement },

  expose: [],

  data() {
    const { isAdmin } = storeToRefs(userRightStore());
    return {
      isAdmin: isAdmin as unknown as boolean,
      translationStore: translationStore(),
      SettingsWindowMode
    };
  },

  computed: {
    isDebuggerAllowed() {
      return this.isAdmin && userRightStore().hasRight(UserRightKey.Debugger);
    },

    isIndicatorEditorAllowed() {
      return this.isAdmin && userRightStore().hasRight(UserRightKey.EditIndicator);
    },

    unseenMessages() {
      const chats = chatStore().chats;
      let unseenMessageCount = 0;
      for (const chat of chats) {
        unseenMessageCount += chat.getUnseenMessageCount();
      }
      if (unseenMessageCount > 0) {
        notificationStore().playSelectedSound();
      }
      return unseenMessageCount;
    },

    notificationColor() {
      return getColorByCSSDefine("--system-red");
    }
  },

  methods: {
    toggleAnalysisSettings() {
      uiStateStore().toggleSettingsWindowMode(SettingsWindowMode.Analysis);
    },

    toggleShortcuts() {
      uiStateStore().toggleSettingsWindowMode(SettingsWindowMode.Shortcut);
    },

    toggleMarketOverview() {
      uiStateStore().toggleSettingsWindowMode(SettingsWindowMode.WatchlistOverview);
    },

    toggleAlertOverview() {
      uiStateStore().toggleSettingsWindowMode(SettingsWindowMode.AlertOverview);
    },

    toggleChat() {
      uiStateStore().toggleSettingsWindowMode(SettingsWindowMode.Chat);
    },

    toggleDebugger() {
      uiStateStore().toggleSettingsWindowMode(SettingsWindowMode.Debugger);
    },

    toggleIndicatorDefinitions() {
      uiStateStore().toggleIndicatorDefinition();
    }
  }
});
</script>

<style scoped>
.right-toolbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  color: var(--content-primary);
}

.right-toolbar-button {
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;
}

.right-toolbar-separator {
  align-self: stretch;
  margin-left: 5px;
  margin-right: 5px;
}

.notification-dot {
  border: 2px solid var(--background-elevated);
  position: absolute;
  top: -3px;
  right: -5px;
}
</style>
export enum UserRightKey {
  Tools = "TOOLS",
  AutoTools = "ANALYSIS_TOOL",
  Layout = "LAYOUT",
  Templates = "TEMPLATES",
  Views = "VIEWS",
  Symbols = "SYMBOLS",
  Publish = "PUBLISH",
  EditRights = "RIGHTS",
  Chart = "CHART",
  Alerts = "ALERTS",
  Debugger = "DEBUGGER",
  EditIndicator = "EDIT_INDICATOR",
  PublishTool = "PUBLISH_TOOLS",
  ShowPublicUserTool = "SHOW_PUBLIC_TOOLS"
}

export enum AutoToolRightKey {
  Fibonacci = "FIBO",
  Trend = "TREND",
  Horizontal = "HORIZONTAL",
  Extreme = "HIGHLOW",
  PriceGap = "GAP",
  Cluster = "CLUSTER",
  DoubleExtreme = "DOUBLE_EXTREME",
  Channels = "CHANNEL"
}

export class UserRight {

  constructor(public readonly key: UserRightKey,
              public readonly details: string[]) {
  }
}

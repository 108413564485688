<template>
  <PageWrapper>
    <div class="hover">
      <p style="text-align:center; background-color: rgb(37,85,121); color: rgb(255,255,255); margin: .3em; margin-top: 0.5em; padding-top: 0.3em; font-size: large; height:2.0em">
        Marktübersicht
        <text class="sub-text st1 st2 tooltip" />
        <i title="Ansicht speichern" class="fa fa-save" @click="setMarketListLayout" style="float: right; font-size:x-large;margin-right:0.5em;margin-left:0.5em;margin-top:0.05em;cursor:default"></i>
        <i v-if="selectedWatchlist != null" title="Marktübersicht hinzufügen" class="fa fa-plus" @click="addMarketListLayout" style="float: right; font-size:x-large;color:#ffffff;margin-right:0.5em;margin-top:0.05em;cursor:default"></i>
        <i v-if="selectedWatchlist == null" title="Marktübersicht hinzufügen" class="fa fa-plus" style="float: right; font-size:x-large;color:#8d8d8d;margin-right:0.5em;margin-top:0.05em;cursor:default"></i>
        <select v-model="selectedWatchlist" class="form-select form-select-sm">
          <option v-for="watchlist in marketListsToAdd" :key="watchlist.id" :value="watchlist">
            {{ watchlist.name }}
          </option>
        </select>
      </p>
    </div>
    <div id="messagesA" style="margin: .3em">
      <GridLayout
        v-model:layout="layout"
        class="layout_mu"
        :colNum="120"
        :margin="[4, 4]"
        :rowHeight="1"
        :maxRows="maxRows"
        :responsive="false"
        :isDraggable="true"
        :isResizable="true"
        :isBounded="false"
        :preventCollision="true"
        :verticalCompact="false"
        :useCssTransforms="false"
        dragAllowFrom=".vue-draggable-handle"
      >
        <GridItem
          v-for="(watchlist, watchlistIndex) in marketListsLayout" :key="layout[watchlistIndex].id"
          :static="false"
          :x="layout[watchlistIndex].x"
          :y="layout[watchlistIndex].y"
          :w="layout[watchlistIndex].width"
          :h="layout[watchlistIndex].height"
          :i="layout[watchlistIndex].id"
        >
          <WatchlistTable :watchlist="watchlist" :canDeleteItem="true" @changeSymbol="changeSymbol" />
        </GridItem>
      </GridLayout>
    </div>
  </PageWrapper>
</template>

<script lang="ts">
import { Instrument } from "@/anfin-chart/instrument";
import type { Watchlist } from "@/api/models/watchlist";
import { multiChartStore } from "@/stores/multi-chart-store";
import { defineComponent } from "vue";
import WatchlistTable from "../watchlist/WatchlistTable.vue";
import { GridItem, GridLayout } from "vue3-grid-layout-next";
import PageWrapper from "@/views/PageWrapper.vue";

export default defineComponent({
  name: "MarketOverviewPage",

  components: { PageWrapper, WatchlistTable, GridLayout, GridItem },

  expose: [],

  data() {
    return {
      marketListsLayout: [] as any[],
      marketListsToAdd: [] as any[],
      layout: [] as any[],
      isDragging: false,
      index: 0,
      selectedWatchlist: null as Watchlist | null,
      maxRows: window.innerHeight / 5 - 12
    };
  },

  methods: {
    changeSymbol(symbol: string) {
      const instrument = Instrument.fromSymbol(symbol);
      multiChartStore().changeInstrument(instrument);
    },

    setMarketListLayout() {
      // TODO: implement
    },

    addMarketListLayout() {
      // TODO: implement
    }
  }
});
</script>

<style scoped>
.layout_mu .vue-grid-item .resizing {
  opacity: 0.9;
}

.layout_mu .vue-grid-item .static {
  background: rgba(204, 204, 238, 0.07);
}

.layout_mu .vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.layout_mu .vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.layout_mu .vue-grid-item .minMax {
  font-size: 12px;
}

.layout_mu .vue-grid-item .add {
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: -100000px;
  background-color: black;
  color: white;
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}

.hover:hover .tooltip {
  z-index: 10000;
  transform: translate3d(-20px,-16px, 0px);
  top: 20px;
  opacity: 1;
}

.hover:hover .sub-text {
  top: 20px;
  opacity: 1;
}

.st1 {
  fill: #0491B7;
}

.st2 {
  fill: #049137;
}

.st1:hover {
  fill: #ffffff;
  opacity: 0.9;
}

.st2:hover {
  fill: red;
}

text {
  display:block;
  fill:#fff;
  font-size:2em;
}

.sub-text {
  display:none;
  fill:#fff;
  font-size:2em;
}
</style>

import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { LineElement, PolygonElement, TextBox } from "@/anfin-chart/drawable";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { alignAvoid, alignTriangle } from "@/anfin-chart/tools/user-tools/alignment";
import { formatNumber } from "@/anfin-chart/utils";
import { OptionName } from "@/anfin-chart/options/option-manager";
import { getCenterPoint } from "@/anfin-chart/geometry";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import type { UserTool } from "@/anfin-chart/tools/user-tool";

export class ABCDPattern extends UserToolDefinition {

  public static readonly type = "abcd";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(0, 116, 178));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(71, 122, 81, 0.25));

  private readonly acPoint = new ChartToolPoint();
  private readonly bdPoint = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(ABCDPattern.type, instrument, timeframe, 4, 4);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
    tool.withAtLeastPoints(3, () => this.createThird(tool));
    tool.withAtLeastPoints(4, () => this.createFourth(tool));
  }

  public override updatePosition(tool: UserTool) {
    const [a, b, c, d] = this.fixedPoints.map(p => tool.getPosition(p));
    if (this.fixedPoints.length >= 3) {
      const ac = getCenterPoint(a, c);
      tool.updatePoint(this.acPoint, ac);
    }
    if (this.fixedPoints.length >= 4) {
      const bd = getCenterPoint(b, d);
      tool.updatePoint(this.bdPoint, bd);
    }
  }

  private createFirst(tool: UserTool) {
    const [a] = this.fixedPoints;
    const aText = new TextBox(tool, a);
    aText.setText("A");
    tool.onUpdate(() => {
      aText.alignment = alignAvoid(tool, a, this.fixedPoints[1], this.fixedPoints[2], this.fixedPoints[3]);
      aText.color = this.lineColor.getValue();
    });
    new FixedSelectablePoint(tool, a);
  }

  private createSecond(tool: UserTool) {
    const [a, b] = this.fixedPoints;
    const bText = new TextBox(tool, b);
    bText.setText("B");
    const abLine = new LineElement(tool, a, b);
    tool.onUpdate(() => {
      const lineColor = this.lineColor.getValue();
      bText.alignment = alignAvoid(tool, b, a, this.fixedPoints[2], this.fixedPoints[3]);
      bText.color = lineColor;
      abLine.color = lineColor;
      abLine.options.width = tool.getLineWidth(false);
    });
    new FixedSelectablePoint(tool, b);
  }

  private createThird(tool: UserTool) {
    const [a, b, c] = this.fixedPoints;
    const cText = new TextBox(tool, c);
    cText.setText("C");
    const acLine = new LineElement(tool, a, c);
    const bcLine = new LineElement(tool, b, c);
    const abcTriangle = new PolygonElement(tool, [a, b, c]);
    abcTriangle.ignoreHit();
    const acTextBox = new TextBox(tool, this.acPoint);
    tool.onUpdate(() => {
      const lineColor = this.lineColor.getValue();
      const areaColor = this.areaColor.getValue();
      const lineWidth = tool.getLineWidth(false);
      cText.alignment = alignAvoid(tool, c, a, b, this.fixedPoints[3]);
      cText.color = lineColor;
      acLine.color = lineColor;
      acLine.options.width = lineWidth;
      bcLine.color = lineColor;
      bcLine.options.width = lineWidth;
      abcTriangle.areaColor = areaColor;
      const abcValue = Math.abs(c.price - b.price) / Math.abs(a.price - b.price);
      acTextBox.color = lineColor;
      acTextBox.alignment = alignTriangle(tool, a, c, b);
      acTextBox.setText(formatNumber(abcValue, 2));
    });
    new FixedSelectablePoint(tool, c);
  }

  private createFourth(tool: UserTool) {
    const [a, b, c, d] = this.fixedPoints;
    const dText = new TextBox(tool, d);
    dText.setText("D");
    const bdLine = new LineElement(tool, b, d);
    const cdLine = new LineElement(tool, c, d);
    const bcdTriangle = new PolygonElement(tool, [b, c, d]);
    bcdTriangle.ignoreHit();
    const bdTextBox = new TextBox(tool, this.bdPoint);
    tool.onUpdate(() => {
      const lineColor = this.lineColor.getValue();
      const areaColor = this.areaColor.getValue();
      const lineWidth = tool.getLineWidth(false);
      dText.color = lineColor;
      dText.alignment = alignAvoid(tool, d, a, b, c);
      bdLine.color = lineColor;
      bdLine.options.width = lineWidth;
      cdLine.color = lineColor;
      cdLine.options.width = lineWidth;
      bcdTriangle.areaColor = areaColor;
      const bcdValue = Math.abs(d.price - c.price) / Math.abs(b.price - c.price);
      bdTextBox.color = lineColor;
      bdTextBox.alignment = alignTriangle(tool, b, d, c);
      bdTextBox.setText(formatNumber(bcdValue, 2));
    });
    new FixedSelectablePoint(tool, d);
  }
}

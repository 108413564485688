import type { ChartColor } from "@/anfin-chart/draw/chart-color";
import { ColorDefinition } from "@/anfin-chart/indicator-definition";
import { ApiModelConverter } from "@/api/messages/converter";
import type { ColorResponse } from "@/api/messages/indicator-definition";
import { ColorConverter } from "@/api/messages/indicator-definition";

export class ChartColorConverter extends ApiModelConverter<ChartColor, ColorResponse> {

  private readonly colorConverter = new ColorConverter();

  public override toApiObject(color: ChartColor) {
    const definition = ColorDefinition.fromColor(color);
    return this.colorConverter.toApiObject(definition);
  }

  public override toModelObject(response: ColorResponse) {
    const definition = this.colorConverter.toModelObject(response);
    return definition.toColor();
  }
}

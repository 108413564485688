import { CircleElement } from "@/anfin-chart/drawable";
import { ExtremeHint } from "@/anfin-chart/hints/extreme-hint";
import type { InstrumentData } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { FixedPoint } from "@/anfin-chart/tools/tool-point";
import type { AutoExtreme } from "@/api/models/analysis/auto-extreme";
import { DelayedExecutor } from "@/anfin-chart/utils";
import type { ColorOption } from "@/anfin-chart/options/option";
import type { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { AnalysisTool } from "@/anfin-chart/tools/analysis-tool";

export class AutoExtremeTool extends AnalysisTool {

  private readonly baseColorOption: ColorOption;
  private hint: ExtremeHint | null = null;
  private readonly hoverExecutor = new DelayedExecutor(200);

  private readonly center: FixedPoint;

  constructor(public readonly definition: AutoExtreme,
              instrumentData: InstrumentData,
              subChart: SubChart) {
    super(definition, instrumentData, subChart);
    this.baseColorOption = this.definition.isHigh ? this.colorOptions.autoExtremeHigh : this.colorOptions.autoExtremeLow;
    this.center = new FixedPoint(this.definition.time, this.definition.price);
  }

  public override getIsVisible() {
    return super.getIsVisible() && this.optionManager.showExtremes.getValue();
  }

  public override onHoverChange() {
    super.onHoverChange();
    this.hoverExecutor.cancel();
    if (this.isHovered) {
      this.hoverExecutor.post(() => {
        this.hint?.show();
      });
    } else {
      this.hint?.hide();
    }
  }

  protected override createDrawables() {
    const circle = new CircleElement(this, this.center, this.definition.strength + 3);
    const hint = new ExtremeHint(this, this.center, this.definition);
    this.hint = hint;
    this.onUpdate(() => {
      const baseColor = this.baseColorOption.getValue() as RGBAColor;
      circle.color = baseColor.withAlpha(0.6);
      hint.mainColor = baseColor;
    });
  }

  protected override updatePositionInternal() {
    this.updateFixedPoint(this.center);
  }
}

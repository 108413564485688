export abstract class ApiModelConverter<T, U> {

  public clone(obj: T) {
    return this.toModelObject(this.toApiObject(obj));
  }

  public abstract toApiObject(obj: T): U;

  public abstract toModelObject(response: U): T;
}

export class BitMaskConverter extends ApiModelConverter<boolean[], number> {

  constructor(private readonly bitCount: number) {
    super();
  }

  public override toApiObject(flags: boolean[]) {
    let result = 0;
    for (let i = flags.length - 1; i >= 0; i--) {
      result <<= 1;
      if (flags[i]) {
        result++;
      }
    }
    return result;
  }

  public override toModelObject(bitMask: number) {
    let remainingBitMask = bitMask;
    const flags = [];
    while (flags.length < this.bitCount) {
      flags.push((remainingBitMask & 1) > 0);
      remainingBitMask >>= 1;
    }
    if (remainingBitMask > 0) {
      console.error("Bit mask is larger than bit count");
    }
    return flags;
  }
}

import type { Directive } from "vue";
import type { Consumer } from "@/anfin-chart/utils";
import { Point } from "@/anfin-chart/geometry";

export interface DraggableOptions {
  handler?: string;
  useTargetImage?: boolean;
  onInitialize?: Consumer<void>;
  onDragStart?: Consumer<void>;
  onDragEnd?: Consumer<void>;
  onDragEnter?: Consumer<void>;
  onDragMove?: Consumer<Point>;
}

const transparentImage = new Image();
transparentImage.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

document.addEventListener("dragover", e => e.preventDefault());

export const Draggable: Directive<HTMLElement, DraggableOptions> = {
  mounted(element, binding) {
    const handlerElement = binding.value.handler == null ? element : element.querySelector(binding.value.handler);
    if (!(handlerElement instanceof HTMLElement)) {
      console.error("Could not find drag handle: " + binding.value.handler);
      return;
    }
    handlerElement.draggable = true;

    let initialX = 0;
    let initialY = 0;
    let isLastTouched = false;
    let isDragging = false;
    const onDragStart = (x: number, y: number) => {
      const rect = handlerElement.getBoundingClientRect();
      initialX = x - rect.left;
      initialY = y - rect.top;
      binding.value.onDragStart?.();
      isDragging = true;
    };
    const onDragEnd = () => {
      binding.value.onDragEnd?.();
      isDragging = false;
    };
    const onDragMove = (x: number, y: number) => {
      if (isDragging && binding.value.onDragMove != null) {
        const positionX = x - initialX;
        const positionY = y - initialY;
        const position = new Point(positionX, positionY);
        binding.value.onDragMove(position);
      }
    };
    const onDragEnter = () => {
      binding.value.onDragEnter?.();
    };

    handlerElement.addEventListener("dragstart", e => {
      if (e.dataTransfer != null) {
        if (binding.value.useTargetImage && e.target instanceof HTMLElement) {
          e.dataTransfer.setDragImage(e.target, 0, 0);
        } else {
          e.dataTransfer.setDragImage(transparentImage, 0, 0);
        }
        e.dataTransfer.effectAllowed = "copyMove";
      }
      onDragStart(e.clientX, e.clientY);
    });

    window.addEventListener("dragover", e => {
      if (e.clientX === 0 && e.clientY === 0) {
        return;
      }
      onDragMove(e.clientX, e.clientY);
    });
    handlerElement.addEventListener("dragend", () => {
      onDragEnd();
    });
    handlerElement.addEventListener("dragenter", () => {
      onDragEnter();
    });

    const onTouchChange = (e: TouchEvent) => {
      if (e.touches.length === 1) {
        onDragStart(e.touches[0].clientX, e.touches[0].clientY);
      } else if (e.touches.length === 0) {
        onDragEnd();
      }
    };
    handlerElement.addEventListener("touchstart", onTouchChange);
    handlerElement.addEventListener("touchend", onTouchChange);
    handlerElement.addEventListener("touchmove", e => {
      if (e.touches.length === 1) {
        onDragMove(e.touches[0].clientX, e.touches[0].clientY);
      }
    });
    document.addEventListener("touchmove", e => {
      const touchElement = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
      const isTouched = touchElement === handlerElement;
      if (isTouched && !isLastTouched) {
        onDragEnter();
      }
      isLastTouched = isTouched;
    });

    binding.value.onInitialize?.();
  }
};

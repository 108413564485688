<template>
  <LandingPageWrapper>
    <LandingPageSection class="py-md-5 py-4">
      <div v-if="orderState.currentStep === CheckoutStep.PaymentFinished" id="checkmark-finished" class="checkmark-text">
        <div class="font-lg text-center">
          <div v-if="paymentState === PaymentState.Default || paymentState === PaymentState.ExternalSuccess">
            <IconElement iconName="Check" :size="40" class="checkmark" />
            <h3 class="font-bold mt-4">
              Vielen Dank für Ihre Bestellung!
            </h3>
            <div class="mt-4">
              {{ translationStore.getTranslation("checkout#payment_finished") }}
            </div>
            <div class="mt-4">
              <div>
                Sie erhalten innerhalb der nächsten Minuten Informationen an die angegebene E-Mail-Adresse.
              </div>
              <div>
                Das kann bis zu 15 Minuten dauern. Bitte sehen Sie auch in Ihrem Spam-Ordner nach.
              </div>
              <div>
                Darin enthalten sind Ihre Bestellbestätigung, Ihre Rechnung sowie Details zum Login.
              </div>
            </div>
            <div class="mt-5">
              <div>
                Falls Sie bereits Klientin/Klient bei AnFin sind und deshalb bereits einen Zugang zur Chartsoftware haben,
              </div>
              <div>
                können Sie sich über den folgenden Button einloggen:
              </div>
            </div>
            <RouterLink to="/login" class="btn btn-primary mt-4">
              {{ translationStore.getTranslation("checkout#to_login") }}
            </RouterLink>
          </div>
          <div v-else-if="paymentState === PaymentState.ExternalPending">
            <div>
              {{ translationStore.getTranslation("checkout#payment_pending") }}
            </div>
            <LoadingSpinner :size="4" class="mt-4" />
          </div>
          <div v-else-if="paymentState === PaymentState.ExternalFailure" class="text-error">
            <IconElement iconName="Cross" :size="40" />
            <div class="mt-3">
              {{ translationStore.getTranslation("checkout#payment_failure") }}
            </div>
            <div class="mt-3">
              {{ externalError }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="cartData == null || cartData.items.length === 0">
        <div class="landing-secondary-header">
          {{ translationStore.getTranslation("checkout#cart_empty") }}
        </div>
        <RouterLink to="/shop" class="btn btn-primary mt-3">
          {{ translationStore.getTranslation("checkout#button_back_to_shop") }}
        </RouterLink>
      </div>
      <div v-else>
        <div v-if="orderState.currentStep < CheckoutStep.Finished" class="checkout-steps mb-4">
          <template v-for="(step, stepIndex) in steps">
            <div v-if="stepIndex > 0" class="mt-2 checkout-step">
              <IconElement iconName="ChevronRight" :size="18" />
            </div>
            <div class="checkout-step" :class="{ active: orderState.currentStep === step }" @click="goToStep(step)">
              {{ translationStore.getTranslation("checkout_step#" + step) }}
            </div>
          </template>
        </div>
        <div class="checkout-container">
          <div class="checkout-main">
            <CustomerDataForm
              v-show="orderState.currentStep === CheckoutStep.UserData" ref="customerData" :data="orderState.customerData"
              @submit="onCustomerDataSubmit"
            />
            <div v-show="orderState.currentStep === CheckoutStep.Payment">
              <div v-if="paymentError != null" class="text-error fw-bold mb-3">
                {{ paymentError }}
              </div>
              <div id="payment" class="card card-transparent"></div>
            </div>
            <div v-show="orderState.currentStep === CheckoutStep.Confirm">
              <div v-if="orderError != null" class="text-error fw-bold mb-3">
                {{ orderError }}
              </div>
              <div>
                <ShoppingCartItem v-for="item in cartData.items" :key="item.product.id" :item="item" :isEditable="false" />
              </div>
              <div class="form-check mt-3">
                <input id="accept-conditions" v-model="hasAcceptedConditions" type="checkbox" class="form-check-input" />
                <label class="form-check-label" for="accept-conditions">
                  <span>Ich habe die </span>
                  <PrivacyPolicyLink />
                  <span>, </span>
                  <AGBLink />
                  <span> und </span>
                  <CancellationPolicyLink />
                  <span> zur Kenntnis genommen und akzeptiere diese.</span>
                </label>
              </div>
            </div>
            <div v-show="orderState.currentStep === CheckoutStep.Finished">
              <div class="mt-5 checkmark-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
              <div class="checkmark-text mt-4 font-lg font-bold">
                {{ translationStore.getTranslation("checkout#payment_external_redirect") }}
              </div>
            </div>
            <button
              type="button" class="btn btn-primary mt-3 next-step-button" :disabled="!isNextEnabled"
              @click="goToNextStep"
            >
              {{ translationStore.getTranslation("checkout#next_step#" + orderState.currentStep) }}
            </button>
          </div>
          <CheckoutSummary :allowNext="isNextEnabled" :nextLabel="nextStepLabel" @next="goToNextStep" />
        </div>
      </div>
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { getColorByCSSDefine } from "@/anfin-chart/utils";
import { paymentStore } from "@/stores/payment-store";
import { translationStore } from "@/stores/translation-store";
import IconElement from "@/views/icons/IconElement.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import { getEnvironment } from "@/api/environment";
import { MonetaryMixin } from "@/mixins/monetary-mixin";
import CustomerDataForm from "@/views/checkout/CustomerDataForm.vue";
import CheckoutSummary from "@/views/checkout/CheckoutSummary.vue";
import { CheckoutStep, ShoppingCartResolved, type ShoppingOrderState } from "@/api/models/checkout";
import ShoppingCartItem from "@/views/checkout/ShoppingCartItem.vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import PrivacyPolicyLink from "@/views/pages-landing/PrivacyPolicyLink.vue";
import CancellationPolicyLink from "@/views/pages-landing/CancellationPolicyLink.vue";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import AGBLink from "@/views/pages-landing/AGBLink.vue";
import { RouterLink } from "vue-router";
import { DynamicScriptMixin } from "@/mixins/DynamicScriptMixin";

export interface PaymentResult {
  errorMessage: string;
  errorData: any;
}

export enum PaymentState {
  Default = 0,
  ExternalPending = 1,
  ExternalSuccess = 2,
  ExternalFailure = 3
}

export default defineComponent({
  name: "CheckoutPage",

  components: {
    AGBLink, LandingPageSection, CancellationPolicyLink, PrivacyPolicyLink, LandingPageWrapper,
    ShoppingCartItem, CheckoutSummary, CustomerDataForm, IconElement, LoadingSpinner, RouterLink
  },

  mixins: [MonetaryMixin, DynamicScriptMixin],

  expose: [],

  data() {
    const { currentCartData, orderState } = storeToRefs(paymentStore());
    return {
      translationStore: translationStore(),
      paymentStore: paymentStore(),
      steps: [CheckoutStep.UserData, CheckoutStep.Payment, CheckoutStep.Confirm],
      orderState: orderState as unknown as ShoppingOrderState,
      cartData: currentCartData as unknown as ShoppingCartResolved | null,
      paymentState: PaymentState.Default as PaymentState,
      externalError: null as string | null,
      hasAcceptedConditions: false,
      paymentError: null as string | null,
      orderError: null as string | null,
      paymentForm: null as any | null,
      CheckoutStep,
      PaymentState
    };
  },

  computed: {
    isNextEnabled() {
      switch (this.orderState.currentStep) {
        case CheckoutStep.UserData:
          return this.orderState.customerData.isValid();
        case CheckoutStep.Confirm:
          return this.hasAcceptedConditions;
        default:
          return true;
      }
    },

    nextStepLabel() {
      return this.translationStore.getTranslation("checkout#next_step#" + this.orderState.currentStep);
    }
  },

  created() {
    const cartId = this.$route.query.cart;
    if (cartId != null) {
      this.paymentStore.loadCart(cartId as string);
    }
    const state = this.$route.query.state;
    const trigger = this.$route.query.trigger;
    if (state === "finished") {
      if (trigger === "Payment") {
        this.paymentState = PaymentState.ExternalPending;
        paymentStore().onInitializationCompleted(() => {
          this.handleRedirectSuccess();
        });
      } else {
        this.setCurrentStep(CheckoutStep.Confirm);
        this.orderError = "Bestellung wurde nicht durchgeführt";
      }
    } else if (state === "switch") {
      this.setCurrentStep(CheckoutStep.Finished);
    }
  },

  mounted() {
    this.addDynamicScript("billwerk-script", getEnvironment().billingUrl, () => this.onBillwerkLoaded());
  },

  methods: {
    createPaymentForm() {
      if (this.paymentForm == null) {
        const environment = getEnvironment();
        const paymentConfig = {
          paymentMethods: environment.paymentMethods,
          publicApiKey: environment.billingPublicApi,
          locale: "de",
          providerReturnUrl: this.getPaymentReturnUrl()
        };
        const color = getColorByCSSDefine("--background-elevated");
        const labelColor = getColorByCSSDefine("--content-secondary");
        const paymentStyle = {
          container: {
            backgroundColor: color
          },
          body: {
            backgroundColor: color
          },
          label: {
            color: labelColor
          },
          sectionContent: {
            color: labelColor
          }
        };
        const paymentContainer = document.getElementById("payment");
        this.paymentForm = SubscriptionJS.createElement(
          "paymentForm", paymentContainer, paymentConfig, paymentStyle, (e: any) => console.error(e)
        );
      }
    },

    getPaymentReturnUrl() {
      const baseUrl = window.location.origin + window.location.pathname;
      const query = window.location.search;
      const stateParameter = "state=finished";
      const stateQuery = query === "" ? "?" + stateParameter : query + "&" + stateParameter;
      return baseUrl + stateQuery;
    },

    goToStep(step: CheckoutStep) {
      if (step >= this.orderState.currentStep) {
        return;
      }
      this.setCurrentStep(step);
    },

    async setCurrentStep(step: CheckoutStep) {
      switch (step) {
        case CheckoutStep.UserData:
          await this.paymentStore.createOrderPreview();
          break;
        case CheckoutStep.Payment:
          this.createPaymentForm();
          await this.paymentStore.createOrderPreview();
          break;
        case CheckoutStep.PaymentFinished:
          await this.checkFinished();
          break;
        default:
          break;
      }
      this.orderState.currentStep = step;
      this.paymentError = null;
      this.orderError = null;
    },

    async goToNextStep() {
      switch (this.orderState.currentStep) {
        case CheckoutStep.UserData:
          (this.$refs.customerData as typeof CustomerDataForm).validateSubmit();
          break;
        case CheckoutStep.Payment:
          await this.createOrder();
          break;
        case CheckoutStep.Confirm:
          this.confirmOrder();
          break;
        default:
          throw new Error("Unknown checkout step: " + this.orderState.currentStep);
      }
    },

    async onCustomerDataSubmit() {
      await this.saveCustomerData();
      await this.setCurrentStep(CheckoutStep.Payment);
    },

    async saveCustomerData() {
      await this.paymentStore.saveUserAddressData();
      const customerData = this.orderState.customerData;
      const customerDataFormatted = {
        FirstName: customerData.firstName,
        LastName: customerData.lastName,
        EmailAddress: customerData.emailAddress,
        Address: {
          Street: customerData.street,
          HouseNumber: customerData.houseNumber,
          PostalCode: customerData.postalCode,
          City: customerData.city,
          Country: customerData.country
        }
      };
      this.createPaymentForm();
      setTimeout(() => this.paymentForm.payerDataChanged(customerDataFormatted), 500);
    },

    async createOrder() {
      this.paymentError = null;
      await this.paymentStore.createOrder();
      if (this.orderState.error != null) {
        this.paymentError = this.orderState.error;
        return;
      }
      await this.setCurrentStep(CheckoutStep.Confirm);
    },

    confirmOrder() {
      const signupService = new SubscriptionJS.Signup();
      const paymentHandler = (result: any) => {
        if (result.Url) {
          // Forward to PSP page to pay
          window.location.href = result.Url;
        } else {
          this.setCurrentStep(CheckoutStep.PaymentFinished);
        }
      };
      const onError = (e: PaymentResult) => {
        this.orderError = e.errorMessage;
      };
      try {
        signupService.paySignupInteractive(null, this.paymentForm, this.orderState.originalOrder, paymentHandler, onError);
      } catch (e) {
        this.orderError = (e as Error).message;
      }
    },

    async handleRedirectSuccess() {
      this.orderState.customerData = await paymentStore().getUserAddressData();
      await this.setCurrentStep(CheckoutStep.PaymentFinished);
    },

    onBillwerkLoaded() {
      if (this.paymentState === PaymentState.ExternalPending) {
        const success = () => {
          this.paymentState = PaymentState.ExternalSuccess;
          this.checkFinished();
        };
        const error = (e: PaymentResult) => {
          this.externalError = e.errorMessage;
          this.paymentState = PaymentState.ExternalFailure;
        };
        SubscriptionJS.finalize(success, error);
      }
    },

    async checkFinished() {
      if (this.paymentState !== PaymentState.Default && this.paymentState !== PaymentState.ExternalSuccess) {
        return;
      }
      const email = this.orderState.customerData.emailAddress;
      fpr("referral", { email });
      await this.paymentStore.deleteCart();
    }
  }
});
</script>

<style scoped>
.checkout-steps {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  gap: 15px;
}

.checkout-step {
  color: var(--disabled-primary);
  cursor: pointer;
}

.checkout-step.active {
  color: var(--content-primary);
  font-weight: bold;
  font-size: 120%;
}

.checkout-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  gap: 15px;
}

@media (max-width: 900px) {
  .checkout-container {
    font-size: 90%;
  }
}

@media (max-width: 767px) {
  .checkout-container {
    flex-direction: column;
  }

  .checkout-main, .checkout-summary {
    width: 100%;
  }

  .next-step-button {
    display: none;
  }
}

.checkout-main {
  flex-grow: 1;
}

.checkmark {
  color: var(--interactive-primary);
}

#payment {
  padding: 2px;
  min-height: 100px;
}
</style>

import { ApiModelConverter, BitMaskConverter } from "@/api/messages/converter";
import { ExchangeInquiry, ExchangeInquiryItem } from "@/api/models/user-info";

export class ExchangeInquiryConverter extends ApiModelConverter<ExchangeInquiry, number> {

  private readonly converter = new BitMaskConverter(32);

  public override toApiObject(inquiry: ExchangeInquiry) {
    const flags = [inquiry.hasBeenAnswered, inquiry.isValid, ...inquiry.items.map(i => i.value)];
    return this.converter.toApiObject(flags);
  }

  public override toModelObject(response: number) {
    const [hasBeenAnswered, isValid, ...itemValues] = this.converter.toModelObject(response);
    const items = itemValues.map((v, i) => new ExchangeInquiryItem(i, v));
    return new ExchangeInquiry(hasBeenAnswered, isValid, items);
  }
}

import { Instrument } from "@/anfin-chart/instrument";
import type { Consumer } from "@/anfin-chart/utils";
import { multiChartStore } from "@/stores/multi-chart-store";

export class MultiWindowBroadcaster {

  private readonly channel: BroadcastChannel;
  private readonly handlerMap = new Map<string, Consumer<void>>();

  constructor(channelName: string) {
    this.channel = new BroadcastChannel(channelName);
    this.channel.addEventListener("message", event => {
      this.onMessage(event.data.action, event.data.data);
    });
  }

  protected registerHandler(action: string, handler: Consumer<any>) {
    this.handlerMap.set(action, handler);
  }

  protected sendMessage(action: string, data: any) {
    const message = { action, data };
    this.channel.postMessage(message);
  }

  private onMessage(action: string, data: any) {
    const handler = this.handlerMap.get(action);
    if (handler != null) {
      handler(data);
    }
  }
}

export enum ChartBroadcastAction {
  ChangeInstrument = "changeInstrument",
  MouseMove = "mouseMove"
}

export interface ChangeInstrumentBroadcast {
  symbol: string;
}

export interface MouseMoveBroadcast {
  symbol: string;
  time: number | null;
  price: number | null;
}

export class ChartBroadcaster extends MultiWindowBroadcaster {

  private static instance: ChartBroadcaster | null = null;

  constructor() {
    super("anfin_chart");
    this.registerHandler(ChartBroadcastAction.ChangeInstrument, (data: ChangeInstrumentBroadcast) => {
      const instrument = Instrument.fromSymbol(data.symbol);
      multiChartStore().onInstrumentBroadcast(instrument);
    });
    this.registerHandler(ChartBroadcastAction.MouseMove, (data: MouseMoveBroadcast) => {
      const instrument = Instrument.fromSymbol(data.symbol);
      multiChartStore().onMouseMoveBroadcast(instrument, data.time, data.price);
    });
  }

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new this();
    }
    return this.instance;
  }

  public changeInstrument(instrument: Instrument) {
    const data: ChangeInstrumentBroadcast = { symbol: instrument.getSymbol() };
    this.sendMessage(ChartBroadcastAction.ChangeInstrument, data);
  }

  public onMouseMove(instrument: Instrument, time: number | null, price: number | null) {
    const data: MouseMoveBroadcast = { symbol: instrument.getSymbol(), time, price };
    this.sendMessage(ChartBroadcastAction.MouseMove, data);
  }
}

export class TableColumn {

  public isDraggable = true;
  public isMinimized = false;
  public isWithoutHeader = false;

  constructor(public readonly key: string,
              public readonly alignment: ColumnAlignment,
              public readonly comparator: (first: any, second: any) => number) {
  }

  public get alignmentCss() {
    return getAlignmentCss(this.alignment);
  }

  public preventDrag() {
    this.isDraggable = false;
    return this;
  }

  public withoutHeader() {
    this.isWithoutHeader = true;
    return this;
  }

  public minimize() {
    this.isMinimized = true;
    return this;
  }
}

export enum ColumnAlignment {
  Left = 0,
  Center = 1,
  Right = 2
}

export function getAlignmentCss(alignment: ColumnAlignment) {
  switch (alignment) {
    case ColumnAlignment.Left:
      return "left";
    case ColumnAlignment.Center:
      return "center";
    case ColumnAlignment.Right:
       return "right";
    default:
      return "left";
  }
}

<template>
  <ToolbarDropdown :items="indicatorItems" titleIcon="Indicators">
    <template #header>
      {{ title }}
    </template>
  </ToolbarDropdown>
</template>

<script lang="ts">
import type { ChartOptionDefinition, IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import { chartObjectStore } from "@/stores/chart-object-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { translationStore } from "@/stores/translation-store";
import { ChartHandler } from "@/utils/ChartHandler";
import { ToolbarItemData } from "@/views/toolbar/toolbar-item-data";
import ToolbarDropdown from "@/views/toolbar/ToolbarDropdown.vue";
import { storeToRefs } from "pinia";
import { defineComponent, toRaw } from "vue";
import { simpleMapCompare } from "@/anfin-chart/utils";
import { presetStore } from "@/stores/preset-store";
import type { ChartObjectPreset } from "@/api/models/user-settings/chart";

export default defineComponent({
  name: "IndicatorSelector",

  components: { ToolbarDropdown },

  expose: [],

  data() {
    const { indicatorDefinitions } = storeToRefs(chartObjectStore());
    const { indicatorPresets } = storeToRefs(presetStore());
    return {
      chartHandler: ChartHandler.getInstance(),
      indicatorDefinitions: indicatorDefinitions as unknown as IndicatorDefinition[],
      indicatorPresets: indicatorPresets as unknown as ChartObjectPreset[]
    };
  },

  computed: {
    title() {
      return translationStore().getTranslation("topbar#indicator_caption");
    },

    indicatorItems() {
      const items: ToolbarItemData[] = [];
      for (const indicatorDefinition of this.indicatorDefinitions) {
        const type = indicatorDefinition.type;
        const item = this.createItem(type, indicatorDefinition.name, indicatorDefinition.options);
        items.push(item);
        const presets = this.indicatorPresets.filter(p => p.type === type);
        if (presets.length > 0) {
          const subItems = presets.map(p => this.createItem(type, p.name, p.options));
          item.setSubItems(subItems);
        }
      }
      return items.sort(simpleMapCompare(d => d.name));
    }
  },

  methods: {
    createItem(type: string, name: string, options: ChartOptionDefinition[]) {
      const onSelect = () => this.addIndicator(type, options);
      return new ToolbarItemData(type, name, null, onSelect);
    },

    addIndicator(type: string, options: ChartOptionDefinition[]) {
      multiChartStore().activeChart?.addIndicator(type, toRaw(options));
    }
  }
});
</script>

<style scoped>

</style>

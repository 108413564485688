<template>
  <div class="pagination pagination-sm">
    <div v-for="(selector, index) in pageSelectors" :key="selector.pageNumber + '_' + index" class="page-item">
      <a
        class="page-link" href="#" :class="{ active: selector.isActive }" @click="setPageNumber(selector.pageNumber)"
      >
        {{ selector.text }}
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

class PageSelector {

  constructor(public readonly pageNumber: number,
              public readonly text: string,
              public readonly isActive = false) {
  }
}

export default defineComponent({
  name: "PaginationSelector",

  props: {
    pageCount: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },

  emits: {
    pageChange: (pageNumber: number) => pageNumber >= 1
  },

  expose: [],

  computed: {
    pageSelectors() {
      const selectors: PageSelector[] = [];
      selectors.push(new PageSelector(1, "\u00AB"));
      const [left, right] = this.getPageRange();
      for (let pageNumber = left; pageNumber <= right; pageNumber++) {
        selectors.push(new PageSelector(pageNumber, pageNumber.toString(), pageNumber === this.currentPage));
      }
      selectors.push(new PageSelector(this.pageCount, "\u00BB"));
      return selectors;
    }
  },

  methods: {
    getPageRange() {
      let left: number;
      let right: number;
      if (this.currentPage === 1) {
        left = 1;
        right = Math.min(this.pageCount, left + 2);
      } else if (this.currentPage === this.pageCount) {
        right = this.pageCount;
        left = Math.max(1, right - 2);
      } else {
        left = this.currentPage - 1;
        right = this.currentPage + 1;
      }
      return [left, right];
    },

    setPageNumber(pageNumber: number) {
      this.$emit("pageChange", pageNumber);
    }
  }
});
</script>

<style scoped>
.page-link:focus {
  box-shadow: none;
}
</style>

import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { TranslationController } from "@/api/translation-controller";
import { defineStore } from "pinia";
import { generalStore } from "@/stores/general-store";
import { cookieStore } from "@/stores/cookie-store";

export class Language {

  constructor(public readonly key: string,
              public readonly caption: string) {
  }

  public getIcon() {
    return "../../static/svg/lang-" + this.key + ".svg";
  }
}

export class TranslationItem {

  constructor(public readonly key: string,
              public readonly value: string) {
  }
}

export function processPlaceholders(translation: string, data: Record<string, string>) {
  let processedTranslation = translation;
  for (const [key, value] of Object.entries(data)) {
    processedTranslation = processedTranslation.replace("#" + key + "#", value);
  }
  return processedTranslation;
}

export const translationStore = defineStore({
  id: "translation",

  state() {
    setTimeout(() => initializeStore());
    return {
      currentLanguage: "de",
      languages: [] as Language[],
      translationMap: new Map<string, string>()
    };
  },

  getters: {
    getTranslation() {
      return (key: string, printWarning = true) => {
        const translation = this.translationMap.get(key);
        if (translation == null && printWarning) {
          generalStore().logWarn("Key for translation not found: " + key);
        }
        return translation ?? "";
      };
    },

    getTimeframeKey() {
      return (timeframe: Timeframe, type: "long" | "short" | "point") => {
        let qualifier: string;
        if (type === "long" || type === "point") {
          const quantity = type === "long" && timeframe.value > 1 ? "plural" : "singular";
          qualifier = "long#" + quantity;
        } else {
          qualifier = type;
        }
        return "time_unit#" + qualifier + "#" + timeframe.unit;
      };
    },

    getTimeframe() {
      return (timeframe: Timeframe, type: "long" | "short" | "point", omitSingularNumber = false) => {
        const key = this.getTimeframeKey(timeframe, type);
        const unitTranslation = this.translationMap.get(key);
        if (unitTranslation == null) {
          generalStore().logWarn("Key for translation not found:" + key);
        }
        if (omitSingularNumber && timeframe.value === 1) {
          return unitTranslation ?? "";
        }
        const space = type === "long" ? " " : (type === "point" ? ". " : "");
        return timeframe.value + space + (unitTranslation ?? "");
      };
    }
  },

  actions: {
    getLanguages() {
      return this.languages;
    },

    async loadCachedLanguage() {
      const language = localStorage.getItem("language") ?? "de";
      await this.applyLanguage(language);
    },

    async applyLanguage(language: string) {
      this.currentLanguage = language;
      await this.requestTranslations();
      cookieStore().setLanguage(language);
    },

    async setLanguage(language: string) {
      localStorage.setItem("language", language);
      await this.applyLanguage(language);
    },

    async requestLanguages() {
      const languages = await TranslationController.getInstance().requestLanguages();
      this.languages = [];
      for (const language of languages) {
        this.languages.push(language);
      }
    },

    async requestTranslations() {
      const translations = await TranslationController.getInstance().requestTranslations(this.currentLanguage);
      for (const translation of translations) {
        this.translationMap.set(translation.key, translation.value);
      }
    },

    async reloadTranslations() {
      await TranslationController.getInstance().reloadTranslations();
      await this.requestTranslations();
    }
  }
});

async function initializeStore() {
  const store = translationStore();
  await store.requestLanguages();
  await store.loadCachedLanguage();
}

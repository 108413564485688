<template>
  <div v-click-outside="hide" class="toolbar-dropdown">
    <div class="menu-item" :class="{ active: isActive }" @click="toggle">
      <IconElement
        v-if="titleIcon != null" :iconName="titleIcon" :size="iconSize || 24"
        :color="iconColor" :fillColor="iconColor" class="toolbar-dropdown-title-icon"
      />
      <div v-if="$slots.header != null" class="toolbar-dropdown-header-content">
        <slot name="header"></slot>
      </div>
      <IconElement v-if="!arrowHidden" iconName="ExtDown" :size="24" class="ms-1" />
    </div>
    <div v-if="isActive" class="toolbar-dropdown-list" :style="position.toCssObject()">
      <template v-if="disabled">
        <ToolbarItem :item="disabledItem" :showName="true" />
      </template>
      <template v-else>
        <slot name="content"></slot>
        <ToolbarItem v-for="item in items" :key="item.key" :item="item" :showName="true" @hide="hide" />
      </template>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { ClickOutside } from "@/directives/click-outside";
import IconElement from "@/views/icons/IconElement.vue";
import ToolbarItem from "@/views/toolbar/ToolbarItem.vue";
import { ToolbarItemData } from "@/views/toolbar/toolbar-item-data";
import { defineComponent, type PropType } from "vue";
import { CssPosition } from "@/anfin-chart/utils";

export default defineComponent({
  name: "ToolbarDropdown",

  components: { ToolbarItem, IconElement },

  directives: { ClickOutside },

  expose: ["hide"],

  props: {
    items: {
      type: Array as PropType<ToolbarItemData[]>,
      required: true
    },
    titleIcon: {
      type: String,
      default: null
    },
    disabled: Boolean,
    arrowHidden: Boolean,
    iconColor: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 24
    },
    position: {
      type: CssPosition,
      default: new CssPosition(36, 0, null, null)
    }
  },

  data() {
    const disabledItem = new ToolbarItemData("feature_disabled", "Verfügbar ab Paket Chart only", null, () => {});
    return {
      isActive: false,
      disabledItem
    };
  },

  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },

    hide() {
      this.isActive = false;
    }
  }
});
</script>

<style scoped>
.toolbar-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  min-width: 0;
}

.toolbar-dropdown-list {
  position: absolute;
  min-width: 210px;
  border-radius: 0 0 10px 10px;
  max-height: 90vh;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 4px 12px 0 var(--background-shadow);
  z-index: 99999;
  background-color: var(--background-elevated);
  color: var(--content-secondary);
  border: 1px solid var(--border-neutral);
}

.toolbar-dropdown-title-icon:not(:only-child) {
  margin-right: 8px;
}

.toolbar-dropdown-header-content {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-item {
  height: 24px;
  display: flex;
  align-items: center;
  max-width: 100%;
}

.menu-item .item-name {
  padding: 0 0;
  top: 0;
  left: 5px;
  float: left;
  position: relative;
}

.menu-item .icon-delete {
  cursor: pointer;
}

.menu-item .icon-save {
  cursor: pointer;
}

.menu-item .icon-fav {
  cursor: pointer;
}

.toolbar-dropdown + .menu-item {
  padding: 0 0;
}
</style>

<template>
  <LandingPageSection>
    <div class="landing-section-split contact-container ms-auto me-auto">
      <AnfinLogo class="anfin-logo-sm" :style="LogoStyle.Green" />
      <div>
        <div class="contact-caption mb-2">
          Sie suchen nach etwas anderem?
        </div>
        <div>
          Zögern Sie bitte nicht uns über das Kontaktformular zu erreichen
        </div>
      </div>
      <RouterLink to="/contact" class="btn btn-primary px-4 py-2 contact-button">
        Kontakt
      </RouterLink>
    </div>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import AnfinLogo from "@/views/components/AnfinLogo.vue";
import { LogoStyle } from "@/api/models/theme";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "ContactSection",

  components: { AnfinLogo, LandingPageSection, RouterLink },

  expose: [],

  data() {
    return {
      LogoStyle
    };
  }
});
</script>

<style scoped>
.contact-container {
  align-items: center;
  justify-content: space-around;
}

.contact-container > * {
  flex-basis: auto;
  flex-grow: 0;
}

.contact-caption {
  font-weight: bold;
  color: var(--background-primary);
}

.contact-button {
  flex-shrink: 0;
}
</style>
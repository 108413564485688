import type { Consumer } from "@/anfin-chart/utils";
import { defineStore } from "pinia";

export class Toast {

  constructor(public readonly id: number,
              public readonly type: ToastType,
              public readonly key: string,
              public readonly data: Record<string, string>,
              public readonly action: Consumer<void> | null) {
  }
}

export enum ToastType {
  Info = 0,
  Error = 1,
  ToolDeleted = 2,
  Alert = 3
}

export const toastStore = defineStore({
  id: "toast",

  state() {
    setTimeout(() => initializeStore());
    return {
      idCounter: 0,
      toasts: [] as Toast[],
      timeoutMap: new Map<number, number>(),
      hiddenTypes: new Set<ToastType>()
    };
  },

  getters: {
    isTypeHidden() {
      return (type: ToastType) => this.hiddenTypes.has(type);
    }
  },

  actions: {
    addToast(type: ToastType, key: string, data: Record<string, string> = {}, action: Consumer<void> | null = null) {
      if (this.hiddenTypes.has(type)) {
        return;
      }
      const id = this.idCounter++;
      const toast = new Toast(id, type, key, data, action);
      this.toasts.push(toast);
      this.setToastTimeout(toast);
    },

    removeToast(id: number) {
      for (let i = 0; i < this.toasts.length; i++) {
        const toast = this.toasts[i];
        if (toast.id === id) {
          this.toasts.splice(i, 1);
          return;
        }
      }
    },

    refreshToast(toast: Toast) {
      const timeout = this.timeoutMap.get(toast.id);
      if (timeout == null) {
        return;
      }
      clearTimeout(timeout);
      this.setToastTimeout(toast);
    },

    setToastTimeout(toast: Toast) {
      const timeout = window.setTimeout(() => this.removeToast(toast.id), 10000);
      this.timeoutMap.set(toast.id, timeout);
    },

    setTypeHidden(type: ToastType, isHidden: boolean) {
      if (isHidden) {
        this.hiddenTypes.add(type);
      } else {
        this.hiddenTypes.delete(type);
      }
      this.saveHiddenTypes();
    },

    loadHiddenTypes() {
      this.hiddenTypes.clear();
      const hiddenTypes = localStorage.getItem("hiddenToastTypes");
      if (hiddenTypes != null) {
        for (const type of hiddenTypes.split(",")) {
          const parsedType = Number(type);
          this.hiddenTypes.add(parsedType as ToastType);
        }
      }
    },

    saveHiddenTypes() {
      const hiddenTypes = Array.from(this.hiddenTypes).join(",");
      localStorage.setItem("hiddenToastTypes", hiddenTypes);
    }
  }
});

function initializeStore() {
  const store = toastStore();
  store.loadHiddenTypes();
}

import { ShoppingProduct } from "@/api/models/shopping-cart";

export enum ProductFeatureOptionType {
  Text = 0,
  Infinity = 1,
  Check = 2,
  ComingSoon = 3
}

export class ProductFeatureOption {

  constructor(public readonly type: ProductFeatureOptionType,
              public readonly value: unknown) {
  }
}

export enum ProductFeature {
  AvailableAssets = "availableAssets",
  HasRealtimeData = "hasRealtimeData",
  HasChartTemplates = "hasChartTemplates",
  HasUserTools = "hasUserTools",
  CanSaveCharts = "canSaveCharts",
  CanImportExportWatchlists = "canImportExportWatchlists",
  CanCreateAlerts = "canCreateAlerts",
  HasMultiscreen = "hasMultiscreen",
  HasAutomaticSaving = "hasAutomaticSaving",
  IsWithoutAds = "isWithoutAds",
  CanCreateTechnicalAlerts = "canCreateTechnicalAlerts",
  CanUseMobileApp = "canUseMobileApp",
  HasAutoAnalyses = "hasAutoAnalyses",
  HasAutoPatternDetection = "hasAutoPatternDetection",
  HasToolWebinar = "hasToolWebinar",
  HasAutoBreakthroughDetection = "hasAutoBreakthroughDetection",
  HasAutoZoneDetection = "hasAutoZoneDetection",
  HasExclusiveLiveTrades = "hasExclusiveLiveTrades",
  HasPerformanceDepot = "hasPerformanceDepot",
  HasExclusiveAnalyses = "hasExclusiveAnalyses"
}

export type ProductFeatureList = Record<ProductFeature, ProductFeatureOption>;

export class AnfinBasicFeatureList implements ProductFeatureList {
  public readonly availableAssets = new ProductFeatureOption(ProductFeatureOptionType.Text, "US-Aktien, EU-Aktien, Indizes, Krypto, Rohstoffe, Forex");
  public readonly hasRealtimeData = new ProductFeatureOption(ProductFeatureOptionType.Text, "Im Paket enthalten");
  public readonly hasChartTemplates = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly hasUserTools = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly canSaveCharts = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly canImportExportWatchlists = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly canCreateAlerts = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly hasMultiscreen = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasAutomaticSaving = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly isWithoutAds = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly canCreateTechnicalAlerts = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly canUseMobileApp = new ProductFeatureOption(ProductFeatureOptionType.ComingSoon, true);
  public readonly hasAutoAnalyses = new ProductFeatureOption(ProductFeatureOptionType.Check, false);
  public readonly hasAutoPatternDetection = new ProductFeatureOption(ProductFeatureOptionType.Check, false);
  public readonly hasToolWebinar = new ProductFeatureOption(ProductFeatureOptionType.Check, false);
  public readonly hasAutoBreakthroughDetection = new ProductFeatureOption(ProductFeatureOptionType.ComingSoon, false);
  public readonly hasAutoZoneDetection = new ProductFeatureOption(ProductFeatureOptionType.ComingSoon, false);
  public readonly hasExclusiveLiveTrades = new ProductFeatureOption(ProductFeatureOptionType.Check, false);
  public readonly hasPerformanceDepot = new ProductFeatureOption(ProductFeatureOptionType.Check, false);
  public readonly hasExclusiveAnalyses = new ProductFeatureOption(ProductFeatureOptionType.Check, false);
}

export class AnfinProFeatureList implements ProductFeatureList {
  public readonly availableAssets = new ProductFeatureOption(ProductFeatureOptionType.Text, "US-Aktien, EU-Aktien, Indizes, Krypto, Rohstoffe, Forex");
  public readonly hasRealtimeData = new ProductFeatureOption(ProductFeatureOptionType.Text, "Im Paket enthalten");
  public readonly hasChartTemplates = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly hasUserTools = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly canSaveCharts = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly canImportExportWatchlists = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly canCreateAlerts = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly hasMultiscreen = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasAutomaticSaving = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly isWithoutAds = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly canCreateTechnicalAlerts = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly canUseMobileApp = new ProductFeatureOption(ProductFeatureOptionType.ComingSoon, true);
  public readonly hasAutoAnalyses = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasAutoPatternDetection = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasToolWebinar = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasAutoBreakthroughDetection = new ProductFeatureOption(ProductFeatureOptionType.ComingSoon, true);
  public readonly hasAutoZoneDetection = new ProductFeatureOption(ProductFeatureOptionType.ComingSoon, true);
  public readonly hasExclusiveLiveTrades = new ProductFeatureOption(ProductFeatureOptionType.Check, false);
  public readonly hasPerformanceDepot = new ProductFeatureOption(ProductFeatureOptionType.Check, false);
  public readonly hasExclusiveAnalyses = new ProductFeatureOption(ProductFeatureOptionType.Check, false);
}

export class AnfinUltraFeatureList implements ProductFeatureList {
  public readonly availableAssets = new ProductFeatureOption(ProductFeatureOptionType.Text, "US-Aktien, EU-Aktien, Indizes, Krypto, Rohstoffe, Forex");
  public readonly hasRealtimeData = new ProductFeatureOption(ProductFeatureOptionType.Text, "Im Paket enthalten");
  public readonly hasChartTemplates = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly hasUserTools = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly canSaveCharts = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly canImportExportWatchlists = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly canCreateAlerts = new ProductFeatureOption(ProductFeatureOptionType.Infinity, true);
  public readonly hasMultiscreen = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasAutomaticSaving = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly isWithoutAds = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly canCreateTechnicalAlerts = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly canUseMobileApp = new ProductFeatureOption(ProductFeatureOptionType.ComingSoon, true);
  public readonly hasAutoAnalyses = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasAutoPatternDetection = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasToolWebinar = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasAutoBreakthroughDetection = new ProductFeatureOption(ProductFeatureOptionType.ComingSoon, true);
  public readonly hasAutoZoneDetection = new ProductFeatureOption(ProductFeatureOptionType.ComingSoon, true);
  public readonly hasExclusiveLiveTrades = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasPerformanceDepot = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
  public readonly hasExclusiveAnalyses = new ProductFeatureOption(ProductFeatureOptionType.Check, true);
}

export class ProductFeatureData {

  constructor(public readonly product: ShoppingProduct,
              public readonly featureList: ProductFeatureList) {
  }
}

export function createProductComparisonData(product: ShoppingProduct) {
  let featureList: ProductFeatureList;
  switch (product.key) {
    case ShoppingProduct.anfinBasic:
      featureList = new AnfinBasicFeatureList();
      break;
    case ShoppingProduct.anfinPro:
      featureList = new AnfinProFeatureList();
      break;
    case ShoppingProduct.anfinUltra:
      featureList = new AnfinUltraFeatureList();
      break;
    default:
      return null;
  }
  return new ProductFeatureData(product, featureList);
}

<template>
  <div class="mt-4">
    <div class="font-bold">
      {{ section.caption }}
    </div>
    <div v-if="section.paragraphs.length > 0" class="mt-2">
      <div v-for="paragraph in section.paragraphs">
        {{ paragraph === "" ? " " : paragraph }}
      </div>
    </div>
    <LegalSection v-for="subSection in section.subSections" :section="subSection" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LegalDocumentSection } from "@/api/models/legal-document";

export default defineComponent({
  name: "LegalSection",

  props: {
    section: {
      type: LegalDocumentSection,
      required: true
    }
  },

  expose: []
});
</script>

<style scoped>

</style>
<template>
  <LandingPageSection class="asset-categories-section">
    <div class="asset-categories-header">
      Entdecken Sie eine umfassende Vielfalt an Finanzkursen
    </div>
    <div class="asset-categories-container mt-lg-5 mt-4">
      <div v-for="category in assetCategories" class="asset-category user-select-none">
        <div class="asset-category-hover-container">
          <div class="asset-category-icon">
            <IconElement :iconName="category.icon" size="3.5em" />
          </div>
          <div class="asset-category-description">
            {{ translationStore.getTranslation("asset_category#" + category.key + "#description") }}
          </div>
        </div>
        <div class="asset-category-name mt-md-2 mt-1">
          {{ translationStore.getTranslation("asset_category#" + category.key) }}
        </div>
      </div>
    </div>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { translationStore } from "@/stores/translation-store";

export class AssetCategory {

  constructor(public readonly key: string,
              public readonly icon: string) {
  }
}

export default defineComponent({
  name: "AssetCategoriesSection",

  components: { IconElement, LandingPageSection },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      assetCategories: [
        new AssetCategory("stock", "Candlesticks"),
        new AssetCategory("index", "StockRising"),
        new AssetCategory("crypto_currency", "CryptoCurrency"),
        new AssetCategory("commodity", "Auction"),
        new AssetCategory("forex", "MoneyUmbrella"),
        new AssetCategory("etf", "MoneyStack")
      ]
    };
  }
});
</script>

<style scoped>
.asset-categories-section {
  position: relative;
  color: white;
  text-align: center;
}

.asset-categories-section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/src/assets/asset-categories.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: brightness(70%);
}

.asset-categories-header {
  font-size: 250%;
  font-weight: 500;
  text-align: center;
}

.asset-categories-container {
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
}

.asset-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 350px;
}

.asset-category-hover-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.asset-category-icon {
  background-color: var(--interactive-accent);
  color: var(--content-secondary);
  border-radius: 50%;
  padding: 25px;
  font-size: 200%;
}

.asset-category-name {
  font-size: 200%;
  font-weight: bold;
}

.asset-category-description {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--interactive-accent-hover);
  border-radius: var(--border-radius-double);
  font-size: 130%;
  text-shadow: 1px 1px 1px #404040;
  padding: 15px;
  transition: all 0.5s ease 0s;
}

.asset-category:hover .asset-category-description {
  opacity: 1;
}

@media (max-width: 1199px) {
  .asset-categories-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 991px) {
  .asset-category {
    max-width: 300px;
  }

  .asset-category-icon {
    font-size: 150%;
  }

  .asset-category-name {
    font-size: 180%;
  }

  .asset-category-description {
    font-size: 120%;
  }
}

@media(max-width: 767px) {
  .asset-categories-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
</style>
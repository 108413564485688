<template>
  <LoginFormTemplate buttonLabel="login#button_reset_password" :contentSize="400" @submit="handleSubmit">
    <div>
      <label class="form-label">
        {{ translationStore.getTranslation("login#label_email") }}
      </label>
      <input
        v-model="email" type="email" name="email" class="form-control" autocomplete="email"
        :placeholder="translationStore.getTranslation('login#label_email')"
      />
    </div>
    <label class="mt-3">
      {{ translationStore.getTranslation("login#reset_password_description") }}
    </label>
    <div v-if="state === ResetPasswordState.MissingData" class="text-error mt-2">
      {{ translationStore.getTranslation("login#reset_password_missing_data") }}
    </div>
    <div v-else-if="state === ResetPasswordState.EmailSent" class="text-highlighted mt-2">
      {{ translationStore.getTranslation("login#reset_password_email_sent") }}
    </div>
    <div class="mt-2">
      <router-link class="link" to="/login">
        {{ translationStore.getTranslation("login#link_login") }}
      </router-link>
    </div>
  </LoginFormTemplate>
</template>

<script lang="ts">
import { AuthenticationController } from "@/api/authentication-controller";
import { translationStore } from "@/stores/translation-store";
import LoginFormTemplate from "@/views/login/LoginFormTemplate.vue";
import { defineComponent } from "vue";

export enum ResetPasswordState {
  Initial = 0,
  MissingData = 1,
  EmailSent = 2
}

export default defineComponent({
  name: "ResetPasswordForm",

  components: { LoginFormTemplate },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      email: "",
      state: ResetPasswordState.Initial as ResetPasswordState,
      ResetPasswordState
    };
  },

  methods: {
    async handleSubmit() {
      this.state = ResetPasswordState.Initial;
      if (this.email === "") {
        this.state = ResetPasswordState.MissingData;
        return;
      }
      try {
        await AuthenticationController.getInstance().resetPassword(this.email);
      } catch (e) {
        console.error(e);
      }
      this.state = ResetPasswordState.EmailSent;
    }
  }
});
</script>

<style scoped>

</style>

import { Instrument } from "@/anfin-chart/instrument";
import { OHLCItem } from "@/anfin-chart/plot";
import { truncateDate } from "@/anfin-chart/time/time-utils";
import { Timeframe, TimeUnit } from "@/anfin-chart/time/timeframe";
import { ApiModelConverter } from "@/api/messages/converter";
import type { SplitResponse } from "@/api/messages/history-data";
import type { HistoryDataResponse } from "@/api/messages/history-data";
import {generalStore} from "@/stores/general-store";

export class HistoryData {

  constructor(public readonly items: OHLCItem[],
              public readonly pricePrecision: number) {
  }
}

export class HistoryDataConverter extends ApiModelConverter<HistoryData, HistoryDataResponse> {

  public override toApiObject(historyData: HistoryData): HistoryDataResponse {
    throw new Error("Not implemented");
  }

  public override toModelObject(response: HistoryDataResponse) {
    const items = this.extractItems(response);
    const pricePrecision = response.pricePrecision;
    return new HistoryData(items, pricePrecision);
  }

  private translate(byte: number): number {
    switch (byte) {
      case 0x0: return 0x00; // 0x00
      case 0x1: return 0x30; // 0
      case 0x2: return 0x31;
      case 0x3: return 0x32;
      case 0x4: return 0x33;
      case 0x5: return 0x34;
      case 0x6: return 0x35;
      case 0x7: return 0x36;
      case 0x8: return 0x37;
      case 0x9: return 0x38;
      case 0xA: return 0x39; // 9
      case 0xB: return 0x2E; //
      case 0xC: return 0x00; // 0x00
      case 0xD: return 0x2D; //
      case 0xE: return 0x2B; //
      case 0xF: return 0x23; // #
    }
    return 0x00;
  }

  private translateBytes(bytes: Uint8Array) {
    let ohlc: number[] = [];
    let data = "";
    for (const byte of bytes) {
      if ((byte & 0xF0) === 0xF0) {
        ohlc = [];
      }
      if ((byte & 0xf) === 0xf) {
        ohlc.push(byte);
      }
      const data1 = this.translate((byte & 0xf0) >> 4);
      const data2 = this.translate(byte & 0xf);
      if ((byte & 0xf0) >> 4 !== 0x00) {
        data += String.fromCharCode(data1);
      }
      data += String.fromCharCode(data2);
    }
    return data;
  }

  private extractNextValue(raw: string, indexStart: number) {
    for (let i = indexStart; i < raw.length; i++) {
      if (raw[i] === "+" || raw[i] === "-") {
        return raw.substring(indexStart - 1, i);
      }
    }
    return raw.substring(indexStart - 1, raw.length);
  }

  private extractItem(raw:  string, minPrice: number, timeBase: number, timeFactor: number) {
    let factor = raw.substring(0, 1);
    let priceFactor: number = Math.pow(10, parseInt(factor));
    let pos = 2;
    const timeStr = this.extractNextValue(raw, pos);
    pos += timeStr.length;
    const openStr = this.extractNextValue(raw, pos);
    pos += openStr.length;
    const highStr = this.extractNextValue(raw, pos);
    pos += highStr.length;
    const lowStr = this.extractNextValue(raw, pos);
    pos += lowStr.length;
    const closeStr = this.extractNextValue(raw, pos);
    pos += closeStr.length;
    const volStr = this.extractNextValue(raw, pos);

    //console.log(priceFactor, factor, minPrice, timeBase, timeFactor, timeStr, openStr, highStr, lowStr, closeStr, volStr);

    const open = minPrice + parseFloat(openStr) / priceFactor;

    return new OHLCItem((parseInt(timeStr) * timeFactor + timeBase) * 1000,
      open,
      open + parseFloat(highStr) / priceFactor,
      open + parseFloat(lowStr) / priceFactor,
      open + parseFloat(closeStr) / priceFactor,
      parseFloat(volStr));
  }

  private extractByteItems(bytes: Uint8Array) {
    const candles: OHLCItem[] = [];
    const translateData = this.translateBytes(bytes);
    const dataBlock = translateData.split("##");

    let lastTime = 0;
    for (const blockRow of dataBlock) {
      const candleBlocks = blockRow.split("#");
      if (candleBlocks.length > 3) {
        const minPrice = parseFloat(candleBlocks[1]);
        const timeBase = parseInt(candleBlocks[2]);
        for (let i = 3; i < candleBlocks.length; i++) {
          const item = this.extractItem(candleBlocks[i], minPrice, timeBase, 60);
          if (lastTime < item.time) {
            candles.push(item);
            lastTime = item.time;
          }
        }
      }
    }
    return candles;
  }

  private extractItems(data: HistoryDataResponse) {
    const timeNow = new Date().getTime();
    const instrument = Instrument.fromSymbol(data.name);
    const timeframe = Timeframe.fromShortNotation(data.timeBase);
    const timeFactor = data.timeCalcFactor ?? 1;
    const priceBase = data.priceBase ?? 0;
    const timeBase = data.timeBaseValue ?? 0;
    const splits = data.splits ?? [];
    let splitIndex = splits.length - 1;
    let splitTime = this.getSplitTime(splits[splitIndex], timeframe);
    let splitFactor = 1;
    const items = [];
    if (data.binary) {
      const binaryString = atob(data.binary);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const candles = this.extractByteItems(bytes);
      for (let i = candles.length - 1; i >= 0; i--) {
        const candle = candles[i];
        while (splitTime != null && splitTime > candle.time) {
          if (timeNow > splitTime) {
            splitFactor *= splits[splitIndex].factor;
          }
          splitIndex--;
          splitTime = this.getSplitTime(splits[splitIndex], timeframe);
        }
        const open = candle.open * splitFactor;
        const high = candle.high * splitFactor;
        const low = candle.low * splitFactor;
        const close = candle.close * splitFactor;
        let volume = candle.volume / splitFactor;
        // TODO: remove volume hacks when server data fixed
        if (candle.time < 1674255600000 && instrument.exchange === "EDGX") {
          volume *= 0.03;
        }
        if (candle.time < 1704063600000 && instrument.exchange === "CEUX") {
          volume *= 0.5;
        }
        const item = new OHLCItem(candle.time, open, high, low, close, volume);
        items.push(item);
      }
      items.reverse();
    } else {
      for (let i = data.candles.length - 1; i >= 0; i--) {
        const candle = data.candles[i];
        const time = candle[0] * timeFactor + timeBase;
        while (splitTime != null && splitTime > time) {
          if (timeNow > splitTime) {
            splitFactor *= splits[splitIndex].factor;
          }
          splitIndex--;
          splitTime = this.getSplitTime(splits[splitIndex], timeframe);
        }
        const open = (candle[1] + priceBase) * splitFactor;
        const high = (candle[2] + priceBase) * splitFactor;
        const low = (candle[3] + priceBase) * splitFactor;
        const close = (candle[4] + priceBase) * splitFactor;
        let volume = candle[5] / splitFactor;
        // TODO: remove volume hacks when server data fixed
        if (time < 1674255600000 && instrument.exchange === "EDGX") {
          volume *= 0.03;
        }
        if (time < 1704063600000 && instrument.exchange === "CEUX") {
          volume *= 0.5;
        }
        const item = new OHLCItem(time, open, high, low, close, volume);
        items.push(item);
      }
      items.reverse();
    }
    return items;
  }

  private getSplitTime(split: SplitResponse | null, timeframe: Timeframe) {
    if (split == null) {
      return null;
    }
    if (timeframe.unit < TimeUnit.Week) {
      return split.time;
    }
    return truncateDate(split.time, timeframe, 0).getTime();
  }
}

import type { Chart } from "@/anfin-chart/chart";
import type { ClickData, DragData } from "@/anfin-chart/interactions";
import type { Predicate } from "@/anfin-chart/utils";

export enum ChartActionType {
  None = "none",
  UserTool = "tool",
  Zoom = "zoom",
  Draw = "draw"
}

export abstract class ChartAction {

  public onCancel: Predicate<void> | null = null;
  public onMouseDown: Predicate<ClickData> | null = null;
  public onMouseUp: Predicate<ClickData> | null = null;
  public onMouseMove: Predicate<ClickData> | null = null;
  public onClick: Predicate<ClickData> | null = null;
  public onDragMove: Predicate<DragData> | null = null;

  public abstract readonly key: string;

  constructor(protected readonly chart: Chart) {
  }
}


import type { ChartExport } from "@/anfin-chart/export";
import type { InstrumentData } from "@/anfin-chart/instrument";
import { InstrumentExport } from "@/anfin-chart/export";

export class ChartLayoutDefinition {

  constructor(public readonly id: number,
              public readonly icon: string,
              public readonly items: ChartLayoutItem[]) {
  }
}

export class ChartLayoutItem {

  constructor(public readonly startRow: number,
              public readonly endRow: number,
              public readonly startColumn: number,
              public readonly endColumn: number) {
  }
}

export class ChartLayout {

  constructor(public readonly id: number,
              public readonly charts: ChartExport[]) {
  }

  public setLinkedInstrument(instrumentData: InstrumentData) {
    for (const chart of this.charts) {
      if (!chart.isLinkedSplitChart) {
        continue;
      }
      for (const subChart of chart.subCharts) {
        if (subChart.instruments.length > 0) {
          const firstInstrumentData = subChart.instruments[0];
          const instrumentExport = new InstrumentExport(instrumentData.instrument, firstInstrumentData.timeframe);
          subChart.instruments = [instrumentExport];
        }
      }
    }
  }
}

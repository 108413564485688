<template>
  <div class="context-menu-list">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContextMenuList",

  expose: []
});
</script>

<style scoped>
.context-menu-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 200px;
  z-index: 99999;
  padding: 5px 0;
  background-color: var(--background-elevated);
  border: 1px solid var(--border-neutral);
  color: var(--content-primary);
  border-radius: 10px;
  box-shadow: 0 4px 12px 0 var(--background-shadow);
}
</style>

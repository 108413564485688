<template>
  <div class="product-feature-label">
    <div>
      {{ translationStore.getTranslation("product_feature#" + feature) }}
    </div>
    <ul v-if="subItems.length > 0" class="product-feature-sub-list mt-2">
      <li v-for="subItem in subItems" :key="subItem">
        {{ subItem }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { translationStore } from "@/stores/translation-store";
import type { ProductFeature } from "@/api/models/product-comparison";

export default defineComponent({
  name: "ProductTableFeature",

  props: {
    feature: {
      type: String as PropType<ProductFeature>,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    subItems() {
      const translation = this.translationStore.getTranslation("product_feature#" + this.feature + "#sub_items");
      if (translation === "") {
        return [];
      }
      return translation.split(";");
    }
  }
});
</script>

<style scoped>
.product-feature-sub-list {
  font-size: 90%;
  margin-bottom: 0;
}
.product-feature-label {
  align-self: center;
}
</style>
<template>
  <ContextMenu ref="contextMenu" container="multi-chart-grid">
    <template #items="slotProps">
      <ContextMenuItem v-for="alertData in existingAlertDatas" :keepOpen="true">
        <div class="d-flex">
          <span>{{ getAlertDefinitionLabel(alertData) }}</span>
          <AlertButtons :alert="alertData.alert" class="ms-auto" @edit="close" @delete="close" />
        </div>
      </ContextMenuItem>
      <ContextMenuItem v-if="allowFixedValueAlert" @click="createFixedValueAlert">
        {{ fixedPriceAlertLabel }}
      </ContextMenuItem>
      <ContextMenuItem v-for="hook in alertHooks" @click="createToolAlert(hook)">
        {{ getToolHookLabel(hook) }}
      </ContextMenuItem>
      <div v-if="hasAlertItems" class="context-menu-item-separator"></div>
      <ContextMenuItem v-if="isAdmin && data?.bar != null" @click="changeBarValues">
        {{ translationStore.getTranslation("context_menu#change_bar_values") }}
      </ContextMenuItem>
      <ContextMenuGroup
        :isRightAligned="slotProps.isRightAligned"
        :label="translationStore.getTranslation('context_menu#watchlist_add_list')"
      >
        <ContextMenuItem v-if="addableWatchlists.length === 0" :disabled="true">
          {{ translationStore.getTranslation("context_menu#watchlist_list_no_entry") }}
        </ContextMenuItem>
        <ContextMenuItem
          v-for="watchlist in addableWatchlists" v-else :key="watchlist.id" @click="addToWatchlist(watchlist)"
        >
          {{ watchlist.name }}
        </ContextMenuItem>
      </ContextMenuGroup>
      <ContextMenuGroup
        :isRightAligned="slotProps.isRightAligned"
        :label="translationStore.getTranslation('context_menu#watchlist_remove_list')"
      >
        <ContextMenuItem v-if="removableWatchlists.length === 0" :disabled="true">
          {{ translationStore.getTranslation("context_menu#watchlist_list_no_entry") }}
        </ContextMenuItem>
        <ContextMenuItem
          v-for="watchlist in removableWatchlists" v-else :key="watchlist.id" @click="removeFromWatchlist(watchlist)"
        >
          {{ watchlist.name }}
        </ContextMenuItem>
      </ContextMenuGroup>
      <ContextMenuItem @click="removeIndicators">
        {{ translationStore.getTranslation("context_menu#delete_all_indicators") }}
      </ContextMenuItem>
      <ContextMenuItem @click="removeTools">
        {{ translationStore.getTranslation("context_menu#delete_all_tools") }}
      </ContextMenuItem>
      <div class="context-menu-item-separator"></div>
      <ChartOptionWrapper v-for="option in chartOptions" :key="option.name" :option="option">
        <template #boolean="optionSlotProps">
          <ContextMenuItem :keepOpen="true" @click="optionSlotProps.toggle">
            <div class="context-check">
              <input :checked="optionSlotProps.value" type="checkbox" class="form-check-input" @change.prevent />
              <label class="context-check-label">
                {{ translationStore.getTranslation("chart_option#" + option.name) }}
              </label>
            </div>
          </ContextMenuItem>
        </template>
      </ChartOptionWrapper>
    </template>
  </ContextMenu>
  <ModalDialog
    ref="changeBarDialog" :title="translationStore.getTranslation('modal_dialog#change_bar_values#header')"
    labelSave="button_save" @save="saveBarValues"
  >
    <template #body>
      <div v-if="data?.bar != null">
        <div>
          <label class="form-label">
            {{ translationStore.getTranslation("modal_dialog#change_bar_values#label_time") }}
          </label>
          <div>
            <span class="font-bold">
              {{ formatDateTime(data.bar.time) }}
            </span>
            <span class="ms-3">
              {{ data.bar.time }}
            </span>
          </div>
        </div>
        <div class="mt-2">
          <label class="form-label">
            {{ translationStore.getTranslation("modal_dialog#change_bar_values#label_open") }}
          </label>
          <input v-model="data.bar.open" type="number" class="form-control form-control-sm" />
        </div>
        <div class="mt-1">
          <label class="form-label">
            {{ translationStore.getTranslation("modal_dialog#change_bar_values#label_high") }}
          </label>
          <input v-model="data.bar.high" type="number" class="form-control form-control-sm" />
        </div>
        <div class="mt-1">
          <label class="form-label">
            {{ translationStore.getTranslation("modal_dialog#change_bar_values#label_low") }}
          </label>
          <input v-model="data.bar.low" type="number" class="form-control form-control-sm" />
        </div>
        <div class="mt-1">
          <label class="form-label">
            {{ translationStore.getTranslation("modal_dialog#change_bar_values#label_close") }}
          </label>
          <input v-model="data.bar.close" type="number" class="form-control form-control-sm" />
        </div>
        <div class="mt-1">
          <label class="form-label">
            {{ translationStore.getTranslation("modal_dialog#change_bar_values#label_volume") }}
          </label>
          <input v-model="data.bar.volume" type="number" class="form-control form-control-sm" />
        </div>
      </div>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import type { ChartOption } from "@/anfin-chart/options/option";
import type { Watchlist } from "@/api/models/watchlist";
import { WatchlistType } from "@/api/models/watchlist";
import { actionHistoryStore } from "@/stores/action-history-store";
import { chartOptionStore, MultiChartOptionManager } from "@/stores/chart-option-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { processPlaceholders, translationStore } from "@/stores/translation-store";
import { watchlistStore } from "@/stores/watchlist-store";
import ContextMenu from "@/views/context-menu/ContextMenu.vue";
import ContextMenuGroup from "@/views/context-menu/ContextMenuGroup.vue";
import ContextMenuItem from "@/views/context-menu/ContextMenuItem.vue";
import ChartOptionWrapper from "@/views/toolbar/ChartOptionWrapper.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import type { Instrument } from "@/anfin-chart/instrument";
import { ContextMenuData, uiStateStore } from "@/stores/ui-state-store";
import { userRightStore } from "@/stores/user-right-store";
import ModalDialog from "@/views/components/ModalDialog.vue";
import { HistoryDataController } from "@/api/history-data-controller";
import {
  Alert,
  AlertAutoToolDefinition,
  AlertFixedValueDefinition,
  AlertRule,
  AlertRuleDefinition,
  AlertRuleDirection,
  AlertUserToolDefinition,
  SimpleAlertCondition
} from "@/api/models/alert";
import type { Chart } from "@/anfin-chart/chart";
import { instrumentStore } from "@/stores/instrument-store";
import { formatPercentage, roundDecimal } from "@/anfin-chart/utils";
import { getAlertDefinitionLabel } from "@/stores/chart-object-store";
import AlertButtons from "@/views/alerts/AlertButtons.vue";
import { DateMixin } from "@/mixins/date-mixin";
import { type ToolAlertHook, UserToolAlertHook } from "@/anfin-chart/tools/alert-hook";
import type { AlertDefinitionData } from "@/anfin-chart/tools/alert-tool";

export default defineComponent({
  name: "ChartContextMenu",

  components: { AlertButtons, ModalDialog, ChartOptionWrapper, ContextMenuItem, ContextMenuGroup, ContextMenu },

  mixins: [DateMixin],

  expose: [],

  data() {
    const { watchlists } = storeToRefs(watchlistStore());
    const { instrument } = storeToRefs(multiChartStore());
    const { optionManager } = storeToRefs(chartOptionStore());
    const { contextMenuData } = storeToRefs(uiStateStore());
    const { isAdmin } = storeToRefs(userRightStore());
    return {
      translationStore: translationStore(),
      watchlists: watchlists as unknown as Watchlist[],
      instrument: instrument as unknown as Instrument,
      optionManager: optionManager as unknown as MultiChartOptionManager,
      data: contextMenuData as unknown as ContextMenuData | null,
      isAdmin: isAdmin as unknown as boolean
    };
  },

  computed: {
    userWatchlists(): Watchlist[] {
      return this.watchlists.filter(w => w.type === WatchlistType.User) as Watchlist[];
    },

    addableWatchlists(): Watchlist[] {
      return this.userWatchlists.filter(w => !w.containsInstrument(this.instrument));
    },

    removableWatchlists(): Watchlist[] {
      return this.userWatchlists.filter(w => w.containsInstrument(this.instrument));
    },

    chartOptions() {
      const optionManager = this.optionManager;
      return [
        optionManager.showUserTools as ChartOption<any>,
        optionManager.showAnalysisTools as ChartOption<any>,
        optionManager.showPublicTools as ChartOption<any>,
        optionManager.showAlertTools as ChartOption<any>
      ];
    },

    allowFixedValueAlert() {
      return this.data?.price != null;
    },

    fixedPrice() {
      if (this.data?.price == null) {
        return null;
      }
      const symbol = this.data.chart.getMainInstrumentData().instrument.getSymbol();
      const decimalPlaces = instrumentStore().getDecimalPlaces(symbol, this.data.price);
      return roundDecimal(this.data.price, decimalPlaces);
    },

    fixedPriceAlertLabel() {
      const translation = this.translationStore.getTranslation("context_menu#create_alert");
      const fixedPriceFormatted = this.fixedPrice == null ? "0" : this.fixedPrice.toString();
      return processPlaceholders(translation, { target: fixedPriceFormatted });
    },

    existingAlertDatas() {
      return this.data?.alertDatas ?? [];
    },

    alertHooks(): ToolAlertHook[] {
      if (this.data == null) {
        return [];
      }
      return this.data.alertHooks as ToolAlertHook[];
    },

    hasAlertItems() {
      return this.existingAlertDatas.length > 0 || this.allowFixedValueAlert || this.alertHooks.length > 0;
    }
  },

  watch: {
    data(value: ContextMenuData | null) {
      const contextMenu = this.$refs.contextMenu as typeof ContextMenu;
      if (value != null) {
        contextMenu.show(value.position.x, value.position.y);
      }
    }
  },

  methods: {
    close() {
      const contextMenu = this.$refs.contextMenu as typeof ContextMenu;
      contextMenu.hide();
    },

    addToWatchlist(watchlist: Watchlist) {
      const symbol = this.instrument.getSymbol();
      watchlistStore().createWatchlistItems(watchlist.id, [symbol]);
    },

    removeFromWatchlist(watchlist: Watchlist) {
      const symbol = this.instrument.getSymbol();
      watchlistStore().deleteWatchlistItem(watchlist, symbol);
    },

    removeIndicators() {
      actionHistoryStore().asCompound(() => {
        for (const chart of multiChartStore().charts) {
          chart.clearIndicators();
        }
      });
    },

    removeTools() {
      actionHistoryStore().asCompound(() => {
        for (const chart of multiChartStore().charts) {
          chart.clearUserTools();
        }
      });
    },

    changeBarValues() {
      (this.$refs.changeBarDialog as typeof ModalDialog).show();
    },

    saveBarValues() {
      (this.$refs.changeBarDialog as typeof ModalDialog).close();
      if (this.data?.bar != null) {
        const instrumentData = this.data.chart.getMainInstrumentData();
        const controller = HistoryDataController.getInstance();
        controller.changeBarValues(instrumentData.instrument, instrumentData.timeframe, this.data.bar);
      }
    },

    createFixedValueAlert() {
      if (this.fixedPrice == null) {
        return;
      }
      const definition = new AlertFixedValueDefinition(this.fixedPrice);
      this.createAlert(definition);
    },

    createToolAlert(hook: ToolAlertHook) {
      if (hook.tool.id == null) {
        return;
      }
      let definition: AlertRuleDefinition;
      if (hook instanceof UserToolAlertHook) {
        definition = new AlertUserToolDefinition(hook.tool.id, hook.basePointIndex, hook.targetPointIndex, hook.percentageOffset);
      } else {
        definition = new AlertAutoToolDefinition(hook.tool.id, hook.percentageOffset);
      }
      this.createAlert(definition);
    },

    createAlert(definition: AlertRuleDefinition) {
      if (this.data == null) {
        return;
      }
      const rule = AlertRule.createDefault(this.data.chart as Chart, definition);
      if (this.data.price != null) {
        const lastPrice = this.data.chart.getMainInstrumentData().mainPlot.getLast();
        rule.direction = this.data.price >= lastPrice ? AlertRuleDirection.Above : AlertRuleDirection.Below;
      }
      const condition = new SimpleAlertCondition(0);
      const alert = new Alert(null, "", [rule], condition);
      uiStateStore().setEditedAlert(alert);
    },

    getAlertDefinitionLabel(alertData: AlertDefinitionData) {
      const translation = this.translationStore.getTranslation("context_menu#existing_alert");
      const definitionLabel = getAlertDefinitionLabel(alertData.definition);
      return processPlaceholders(translation, { target: definitionLabel });
    },

    getToolHookLabel(hook: ToolAlertHook) {
      const translation = this.translationStore.getTranslation("context_menu#create_alert");
      let toolTranslation: string;
      if (hook.percentageOffset == null) {
        const prefix = hook instanceof UserToolAlertHook ? "user_tool" : "analysis";
        toolTranslation = this.translationStore.getTranslation(prefix + "#" + hook.tool.type);
        const toolLineTranslation = this.translationStore.getTranslation(prefix + "#" + hook.tool.type + "#" + hook.key);
        if (toolLineTranslation !== "") {
          toolTranslation += " (" + toolLineTranslation + ")";
        }
      } else {
        toolTranslation = this.translationStore.getTranslation("alert#label_retracement") + " " +
          formatPercentage(hook.percentageOffset);
      }
      return processPlaceholders(translation, { target: toolTranslation });
    }
  }
});
</script>

<style scoped>
.context-check {
  display: block;
}

.context-check-label {
  padding-left: 0.5em;
}

.context-menu-item-separator {
  border-top: 1px solid var(--border-overlay);
  margin: 2px;
}
</style>

<template>
  <div>
    <span v-if="variant.recurringPrice > 0">
      {{ variant.recurringPrice.toFixed(2) + "€" }}
    </span>
    <span v-else>
      {{ variant.fixedPrice.toFixed(2) + "€" }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ShoppingProductVariant } from "@/api/models/shopping-cart";

export default defineComponent({
  name: "ProductPriceFormatted",

  props: {
    variant: {
      type: ShoppingProductVariant,
      required: true
    }
  },

  expose: []
});
</script>

<style scoped>
</style>
import { ApiError } from "@/api/error";
import { AnalysisToolDefinition, AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";

export class AutoDoubleExtreme extends AnalysisToolDefinition {

  constructor(id: string,
              instrument: Instrument,
              timeframe: Timeframe,
              public readonly price: number,
              public readonly target: number,
              public readonly firstExtremeDate: number,
              public readonly middleOppositeExtremeDate: number,
              public readonly secondExtremeDate: number,
              public readonly brokenDate: number | null,
              public readonly brokenByExtreme: boolean,
              public readonly extremeType: AutoDoubleExtremeType) {
    super(id, AnalysisType.DoubleExtreme, instrument, timeframe, firstExtremeDate);
  }
}

export enum AutoDoubleExtremeType {
  Top = 0,
  Bottom = 1
}

export function getAutoDoubleExtremeType(type: string) {
  switch (type) {
    case "TOP":
      return AutoDoubleExtremeType.Top;
    case "BOTTOM":
      return AutoDoubleExtremeType.Bottom;
    default:
      throw new ApiError("Cannot resolve auto horizontal type: " + type);
  }
}

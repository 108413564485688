import { RGBAColor } from "../../draw/chart-color";
import type { Instrument } from "../../instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { LineElement, PolygonElement, TextBox } from "@/anfin-chart/drawable";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { alignAboveBeyond } from "@/anfin-chart/tools/user-tools/alignment";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { getIntersection } from "@/anfin-chart/geometry";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class HeadAndShoulders extends UserToolDefinition {

  public static readonly type = "has";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(0, 116, 178));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(71, 122, 81, 0.25));

  private readonly leftIntersection = new ChartToolPoint();
  private readonly rightIntersection = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(HeadAndShoulders.type, instrument, timeframe, 7, 7);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
    tool.withAtLeastPoints(3, () => this.createThird(tool));
    tool.withAtLeastPoints(4, () => this.createFourth(tool));
    tool.withAtLeastPoints(5, () => this.createFifth(tool));
    tool.withAtLeastPoints(6, () => this.createSixth(tool));
    tool.withAtLeastPoints(7, () => this.createSeventh(tool));
  }

  public override updatePosition(tool: UserTool) {
    const [neck1, leftShoulder, neck2, head, neck3, rightShoulder, neck4] = this.fixedPoints.map(p => tool.getPosition(p));
    if (this.fixedPoints.length >= 5) {
      const leftIntersection = getIntersection(neck2, neck3, neck1, leftShoulder);
      if (leftIntersection != null) {
        tool.updatePoint(this.leftIntersection, leftIntersection);
      }
    }
    if (this.fixedPoints.length >= 7) {
      const rightIntersection = getIntersection(neck2, neck3, neck4, rightShoulder);
      if (rightIntersection != null) {
        tool.updatePoint(this.rightIntersection, rightIntersection);
      }
    }
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const [neck1, leftShoulder] = this.fixedPoints;
    const text = new TextBox(tool, leftShoulder);
    const line = new LineElement(tool, neck1, leftShoulder);
    tool.onUpdate(() => {
      text.color = this.lineColor.getValue();
      text.alignment = alignAboveBeyond(leftShoulder, neck1);
      text.setText(tool.chart.callbacks.getTranslation("user_tool#has#label_shoulder"));
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(false);
    });
    new FixedSelectablePoint(tool, leftShoulder);
  }

  private createThird(tool: UserTool) {
    const [neck1, leftShoulder, neck2] = this.fixedPoints;
    const line = new LineElement(tool, leftShoulder, neck2);
    tool.onUpdate(() => {
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(false);
    });
    new FixedSelectablePoint(tool, neck2);
  }

  private createFourth(tool: UserTool) {
    const [neck1, leftShoulder, neck2, head] = this.fixedPoints;
    const text = new TextBox(tool, head);
    const line = new LineElement(tool, neck2, head);
    tool.onUpdate(() => {
      text.color = this.lineColor.getValue();
      text.alignment = alignAboveBeyond(head, neck2);
      text.setText(tool.chart.callbacks.getTranslation("user_tool#has#label_head"));
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(false);
    });
    new FixedSelectablePoint(tool, head);
  }

  private createFifth(tool: UserTool) {
    const [neck1, leftShoulder, neck2, head, neck3] = this.fixedPoints;
    const line = new LineElement(tool, head, neck3);
    const leftShoulderTriangle = new PolygonElement(tool, [this.leftIntersection, leftShoulder, neck2]);
    leftShoulderTriangle.ignoreHit();
    const headTriangle = new PolygonElement(tool, [neck2, head, neck3]);
    headTriangle.ignoreHit();
    tool.onUpdate(() => {
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(false);
      headTriangle.areaColor = this.areaColor.getValue();
      leftShoulderTriangle.areaColor = this.areaColor.getValue();
    });
    new FixedSelectablePoint(tool, neck3);
  }

  private createSixth(tool: UserTool) {
    const [neck1, leftShoulder, neck2, head, neck3, rightShoulder] = this.fixedPoints;
    const text = new TextBox(tool, rightShoulder);
    const line = new LineElement(tool, neck3, rightShoulder);
    tool.onUpdate(() => {
      text.color = this.lineColor.getValue();
      text.alignment = alignAboveBeyond(rightShoulder, neck3);
      text.setText(tool.chart.callbacks.getTranslation("user_tool#has#label_shoulder"));
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(false);
    });
    new FixedSelectablePoint(tool, rightShoulder);
  }

  private createSeventh(tool: UserTool) {
    const [neck1, leftShoulder, neck2, head, neck3, rightShoulder, neck4] = this.fixedPoints;
    const line = new LineElement(tool, rightShoulder, neck4);
    const rightShoulderTriangle = new PolygonElement(tool, [neck3, rightShoulder, this.rightIntersection]);
    rightShoulderTriangle.ignoreHit();
    tool.onUpdate(() => {
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(false);
      rightShoulderTriangle.areaColor = this.areaColor.getValue();
    });
    new FixedSelectablePoint(tool, neck4);
  }
}

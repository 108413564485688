import type { SymbolCategoryResponse } from "@/api/models/symbol-category";
import { SymbolCategory } from "@/api/models/symbol-category";
import { RestApiController } from "@/api/rest-api-controller";

export class SymbolCategoriesController extends RestApiController {

  private static instance: SymbolCategoriesController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new SymbolCategoriesController();
    }
    return this.instance;
  }

  public async requestSymbolCategories() {
    return await this.sendGetRequest<SymbolCategoryResponse[]>("symbol-categories");
  }

  public async setCategory(symbol: string, category: SymbolCategory) {
    const categories = category === SymbolCategory.None ? [] : [category];
    const request = {
      symbol: symbol,
      categories
    };
    return this.sendPostRequest<number>("symbol-categories", request);
  }

  protected override getBaseUrl() {
    return this.environment.configurationUrl;
  }
}

<template>
  <LandingPageWrapper>
    <ContactFormSection class="py-md-5 py-4" />
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import ContactFormSection from "@/views/pages-landing/sections/ContactFormSection.vue";

export default defineComponent({
  name: "ContactPage",

  components: { ContactFormSection, LandingPageWrapper },

  expose: []
});
</script>

<style scoped>

</style>
import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { projectLineRect, projectLineToX, Vector } from "@/anfin-chart/geometry";
import type { Instrument } from "@/anfin-chart/instrument";
import { BooleanOption, ColorOption } from "@/anfin-chart/options/option";
import { LineElement, PolygonElement } from "@/anfin-chart/drawable";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint, getLeftRight } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class TrendChannel extends UserToolDefinition {

  public static readonly type = "trchannel";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(12, 167, 250));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(58, 145, 207, 0.3));
  private readonly expandLeft = new BooleanOption(this, OptionName.ExpandLeft, false);
  private readonly expandRight = new BooleanOption(this, OptionName.ExpandRight, false);

  private readonly lowerLeft = new ChartToolPoint();
  private readonly lowerRight = new ChartToolPoint();
  private readonly upperLeft = new ChartToolPoint();
  private readonly upperRight = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(TrendChannel.type, instrument, timeframe, 3, 3);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
    tool.withAtLeastPoints(3, () => this.createThird(tool));
  }

  public override updatePosition(tool: UserTool) {
    const positions = this.fixedPoints.map(p => tool.getPosition(p));
    const xValues = positions.map(p => p.x);
    const minX = Math.min(...xValues);
    const maxX = Math.max(...xValues);
    const isExpandLeft = this.expandLeft.getValue();
    const isExpandRight = this.expandRight.getValue();
    const rect = tool.subChart.drawingArea.getPosition();
    if (this.fixedPoints.length >= 2) {
      const [left, right] = getLeftRight(positions);
      const lowerLeft = isExpandLeft ? projectLineRect(right, left, rect) : projectLineToX(left, right, minX);
      tool.updatePoint(this.lowerLeft, lowerLeft);
      const lowerRight = isExpandRight ? projectLineRect(left, right, rect) : projectLineToX(left, right, maxX);
      tool.updatePoint(this.lowerRight, lowerRight);
      if (this.fixedPoints.length >= 3) {
        const upper = positions[2];
        const vector = Vector.from(lowerLeft, lowerRight);
        const thirdExtended = vector.addTo(upper);
        const upperLeft = projectLineToX(upper, thirdExtended, lowerLeft.x);
        tool.updatePoint(this.upperLeft, upperLeft);
        const upperRight = projectLineToX(upper, thirdExtended, lowerRight.x);
        tool.updatePoint(this.upperRight, upperRight);
      }
    }
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const line = new LineElement(tool, this.lowerLeft, this.lowerRight);
    tool.onUpdate(() => {
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, this.fixedPoints[1]);
  }

  private createThird(tool: UserTool) {
    const line = new LineElement(tool, this.upperLeft, this.upperRight);
    const area = new PolygonElement(tool, [this.lowerLeft, this.lowerRight, this.upperRight, this.upperLeft]);
    area.ignoreHit();
    tool.onUpdate(() => {
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(true);
      area.areaColor = this.areaColor.getValue();
    });
    new FixedSelectablePoint(tool, this.fixedPoints[2]);
  }
}

import type { ColorProviderType, ColorType } from "@/anfin-chart/indicator-definition";
import {
    ColorDefinition,
    ColorProviderDefinition,
    DependentIndicatorDefinition,
    GradientStopDefinition,
    IndicatorDefinition,
    PlotDefinition,
    PriceRangeDefinition
} from "@/anfin-chart/indicator-definition";
import type { PlotType } from "@/anfin-chart/plot";
import { ChartOptionConverter, type ChartOptionResponse } from "@/api/messages/chart-option";
import { ApiModelConverter } from "@/api/messages/converter";

export interface IndicatorDefinitionListResponse {
  definitions: IndicatorDefinitionResponse[];
}

export interface IndicatorDefinitionResponse {
  type: string;
  name: string;
  isNewSubChart: boolean;
  options: ChartOptionResponse[];
  plots: PlotDefinitionResponse[];
  indicators: DependentIndicatorResponse[];
  dynamicConfiguration: string | null;
  priceRanges: PriceRangeResponse[];
  initialize: string;
  updateInternal: string;
  utils: string[];
}

export interface PlotDefinitionResponse {
  name: string;
  type: PlotType;
  colorProvider: ColorProviderResponse;
  useAxis: boolean;
}

export interface ColorResponse {
  type: ColorType;
  r: number | null;
  g: number | null;
  b: number | null;
  a: number | null;
  stops: GradientStopResponse[] | null;
}

export interface GradientStopResponse {
  percentage: number;
  color: ColorResponse;
}

export interface ColorProviderResponse {
  type: ColorProviderType;
  colorSets: ColorResponse[][];
  threshold: number | null;
  keys: string[] | null;
  conditions: string[] | null;
}

export interface DependentIndicatorResponse {
  name: string;
  type: string;
}

export interface PriceRangeResponse {
  from: number;
  to: number;
  lineColor: ColorResponse;
  areaColor: ColorResponse;
}

export class IndicatorDefinitionConverter extends ApiModelConverter<IndicatorDefinition, IndicatorDefinitionResponse> {

  private readonly optionConverter = new ChartOptionConverter();
  private readonly plotConverter = new PlotConverter();
  private readonly dependentIndicatorConverter = new DependentIndicatorConverter();
  private readonly priceRangeConverter = new PriceRangeConverter();

  public override toApiObject(definition: IndicatorDefinition): IndicatorDefinitionResponse {
    return {
      type: definition.type,
      name: definition.name,
      isNewSubChart: definition.isNewSubChart,
      options: definition.options.map(o => this.optionConverter.toApiObject(o)),
      plots: definition.plots.map(p => this.plotConverter.toApiObject(p)),
      indicators: definition.indicators.map(i => this.dependentIndicatorConverter.toApiObject(i)),
      dynamicConfiguration: definition.dynamicConfiguration,
      priceRanges: definition.priceRanges.map(p => this.priceRangeConverter.toApiObject(p)),
      initialize: definition.initialize,
      updateInternal: definition.updateInternal,
      utils: definition.utils.slice()
    };
  }

  public override toModelObject(response: IndicatorDefinitionResponse): IndicatorDefinition {
    return new IndicatorDefinition(
      response.type,
      response.name,
      response.isNewSubChart,
      response.options.map(o => this.optionConverter.toModelObject(o)),
      response.plots.map(p => this.plotConverter.toModelObject(p)),
      response.indicators.map(i => this.dependentIndicatorConverter.toModelObject(i)),
      response.dynamicConfiguration,
      response.priceRanges.map(p => this.priceRangeConverter.toModelObject(p)),
      response.initialize,
      response.updateInternal,
      response.utils.slice()
    );
  }
}

export class PlotConverter extends ApiModelConverter<PlotDefinition, PlotDefinitionResponse> {

  private readonly colorProviderConverter = new ColorProviderConverter();

  public override toApiObject(definition: PlotDefinition) {
    return {
      name: definition.name,
      type: definition.type,
      colorProvider: this.colorProviderConverter.toApiObject(definition.colorProvider),
      useAxis: definition.useAxis
    };
  }

  public override toModelObject(response: PlotDefinitionResponse) {
    const colorProvider = this.colorProviderConverter.toModelObject(response.colorProvider);
    return new PlotDefinition(response.name, response.type, colorProvider, response.useAxis);
  }
}

export class ColorProviderConverter extends ApiModelConverter<ColorProviderDefinition, ColorProviderResponse> {

  private readonly colorConverter = new ColorConverter();

  public override toApiObject(definition: ColorProviderDefinition): ColorProviderResponse {
    return {
      type: definition.type,
      colorSets: definition.colorSets.map(colorSet => colorSet.map(c => this.colorConverter.toApiObject(c))),
      threshold: definition.threshold,
      keys: definition.keys?.slice() ?? null,
      conditions: definition.conditions?.slice() ?? null
    };
  }

  public override toModelObject(response: ColorProviderResponse): ColorProviderDefinition {
    const colorSets = response.colorSets.map(colorSet => colorSet.map(c => this.colorConverter.toModelObject(c)));
    const keys = response.keys?.slice() ?? null;
    const conditions = response.conditions?.slice() ?? null;
    return new ColorProviderDefinition(response.type, colorSets, response.threshold, keys, conditions);
  }
}

export class ColorConverter extends ApiModelConverter<ColorDefinition, ColorResponse> {

  private readonly gradientStopConverter = new GradientStopConverter();

  public override toApiObject(definition: ColorDefinition): ColorResponse {
    return {
      type: definition.type,
      r: definition.r,
      g: definition.g,
      b: definition.b,
      a: definition.a,
      stops: definition.stops.map(s => this.gradientStopConverter.toApiObject(s))
    };
  }

  public override toModelObject(response: ColorResponse): ColorDefinition {
    const r = response.r ?? 0;
    const g = response.g ?? 0;
    const b = response.b ?? 0;
    const a = response.a ?? 0;
    const stops = response.stops ?? [];
    const stopDefinitions = stops.map(s => this.gradientStopConverter.toModelObject(s));
    return new ColorDefinition(response.type, r, g, b, a, stopDefinitions);
  }
}

export class GradientStopConverter extends ApiModelConverter<GradientStopDefinition, GradientStopResponse> {

  public override toApiObject(definition: GradientStopDefinition): GradientStopResponse {
    return {
      percentage: definition.percentage,
      color: new ColorConverter().toApiObject(definition.color)
    };
  }

  public override toModelObject(response: GradientStopResponse): GradientStopDefinition {
    const color = new ColorConverter().toModelObject(response.color);
    return new GradientStopDefinition(response.percentage, color);
  }
}

export class DependentIndicatorConverter extends ApiModelConverter<DependentIndicatorDefinition, DependentIndicatorResponse> {

  public override toApiObject(definition: DependentIndicatorDefinition) {
    return {
      name: definition.name,
      type: definition.type
    };
  }

  public override toModelObject(response: DependentIndicatorResponse) {
    return new DependentIndicatorDefinition(response.name, response.type);
  }
}

export class PriceRangeConverter extends ApiModelConverter<PriceRangeDefinition, PriceRangeResponse> {

  private readonly colorConverter = new ColorConverter();

  public override toApiObject(definition: PriceRangeDefinition): PriceRangeResponse {
    return {
      from: definition.from,
      to: definition.to,
      lineColor: this.colorConverter.toApiObject(definition.lineColor),
      areaColor: this.colorConverter.toApiObject(definition.areaColor)
    };
  }

  public override toModelObject(response: PriceRangeResponse): PriceRangeDefinition {
    const lineColor = this.colorConverter.toModelObject(response.lineColor);
    const areaColor = this.colorConverter.toModelObject(response.areaColor);
    return new PriceRangeDefinition(response.from, response.to, lineColor, areaColor);
  }
}

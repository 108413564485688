import { RestApiController } from "@/api/rest-api-controller";
import type { ChatListResponse, ChatMessageListResponse, ChatUnseenResponse } from "@/api/messages/chat";
import {
  Chat,
  ChatConverter,
  ChatMessage,
  ChatMessageConverter
} from "@/api/models/chat";

export class ChatController extends RestApiController {

  private static instance: ChatController | null = null;

  private readonly chatConverter = new ChatConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new ChatController();
    }
    return this.instance;
  }

  public static getParameterMap(chatId: number, lastId: number | null) {
    const parameterMap = new Map<string, string>();
    parameterMap.set("chatId", chatId.toString());
    if (lastId) {
      parameterMap.set("from", lastId.toString());
    }
    return parameterMap;
  }

  public async requestChats() {
    const response = await this.sendGetRequest<ChatListResponse>("chats");
    return response.chats.map(a => this.chatConverter.toModelObject(a));
  }

  public async requestMessages(chat: Chat) {
    const parameterMap = ChatController.getParameterMap(chat.id, null);
    const response = await this.sendGetRequest<ChatMessageListResponse>("messages", parameterMap);
    const converter = new ChatMessageConverter(chat);
    return response.messages.map(m => converter.toModelObject(m));
  }

  public async saveMessage(message: ChatMessage) {
    const converter = new ChatMessageConverter(message.chat);
    const request = converter.toApiObject(message);
    if (message.id == null) {
      return await this.sendPostRequest<number>(message.chat.id + "/messages", request);
    }
    return await this.sendPutRequest<number>(message.chat.id + "/messages/" + message.id, request);
  }

  public async deleteMessage(message: ChatMessage) {
    return await this.sendDeleteRequest<number>(`${message.chat.id}/messages/${message.id}`);
  }

  public async setLastSeenMessage(chat: Chat, message: ChatMessage) {
    await this.sendPutRequest<ChatMessageListResponse>(`messages/${chat.id}/seen/${message.id}`, null);
  }

  protected getBaseUrl() {
    return this.environment.chatUrl + "/chat";
  }
}

<template>
  <div class="navbar navbar-expand-lg landing-topbar">
    <div class="container landing-topbar-content">
      <RouterLink to="/home" class="navbar-brand">
        <AnfinLogo :style="LogoStyle.Transparent" class="anfin-logo-xs" />
      </RouterLink>
      <button
        class="navbar-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbar-supported-content" aria-controls="navbar-supported-content"
        aria-expanded="false" aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbar-supported-content" class="collapse navbar-collapse">
        <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
          <li class="nav-item px-2">
            <RouterLink to="/chart" class="nav-link" target="_blank" rel="noopener noreferrer">
              {{ translationStore.getTranslation("landing_page#link#chart") }}
            </RouterLink>
          </li>
          <li class="nav-item px-2">
            <RouterLink to="/shop" class="nav-link">
              {{ translationStore.getTranslation("landing_page#link#products") }}
            </RouterLink>
          </li>
          <!--
          <li class="nav-item px-2">
            <RouterLink to="/about-us" class="nav-link">
              {{ translationStore.getTranslation("landing_page#link#about_us") }}
            </RouterLink>
          </li>
          <li class="nav-item px-2">
            <RouterLink to="/faq" class="nav-link">
              {{ translationStore.getTranslation("landing_page#link#faq") }}
            </RouterLink>
          </li>
          -->
        </ul>
        <button class="btn btn-primary cart-button ms-2" type="button" @click="showCart">
          <IconElement iconName="Cart" :size="24" />
          <span class="cart-badge badge rounded-pill bg-danger">
            <span>
              {{ cartItemCount }}
            </span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import IconElement from "@/views/icons/IconElement.vue";
import AnfinLogo from "@/views/components/AnfinLogo.vue";
import { LogoStyle } from "@/api/models/theme";
import { storeToRefs } from "pinia";
import { paymentStore } from "@/stores/payment-store";
import type { ShoppingCartResolved } from "@/api/models/checkout";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "LandingTopbar",

  components: { AnfinLogo, IconElement, RouterLink },

  expose: [],

  data() {
    const { currentCartData } = storeToRefs(paymentStore());
    return {
      translationStore: translationStore(),
      cartData: currentCartData as unknown as ShoppingCartResolved | null,
      LogoStyle
    };
  },

  computed: {
    cartItemCount() {
      return this.cartData == null ? 0 : this.cartData.items.length;
    }
  },

  methods: {
    showCart() {
      const store = paymentStore();
      store.setShowCart(!store.isShowCart);
    }
  }
});
</script>

<style scoped>
.landing-topbar {
  position: sticky;
  top: 0;
  z-index: 999;
  background: var(--background-primary);
  color: var(--content-primary-top);
  font-size: 120%;
  padding: 5px 0;
  border-bottom: 1px solid var(--interactive-accent);
}

.nav-link, .nav-link.active {
  color: inherit;
}

.nav-item:hover {
  color: var(--interactive-accent);
}

.dropdown .dropdown-menu {
  margin-top: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.cart-button {
  display: flex;
  position: relative;
  padding: 6px;
}

.cart-button:hover {
  color: #69727d;
}

.cart-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 50%;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-25%);
  padding: 0.2em 0.4em;
}
</style>
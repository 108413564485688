import type { Chart } from "@/anfin-chart/chart";
import { getDistance, isWithin, Point, Range } from "@/anfin-chart/geometry";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { BehaviorSubject, combineLatest, distinctUntilChanged, startWith } from "rxjs";

export enum ChartCursorType {
  Dart = 0,
  Point = 1,
  Crosshair = 2
}

export class MouseData {

  private readonly position = new BehaviorSubject(new Point(0, 0));
  private readonly barTime = new BehaviorSubject<number | null>(null);
  private readonly barIndex = new BehaviorSubject(0);
  private readonly price = new BehaviorSubject<number | null>(null);
  private readonly subChart = new BehaviorSubject<SubChart | null>(null);

  private readonly isMouseOver = new BehaviorSubject(true);
  private isMouseDown = false;
  private mouseDownPosition = new Point(0, 0);
  private touchDifference = 0;
  private isDragging = false;
  private maxDraggingDiff = 0;

  private readonly snapData = new MouseSnapData(this);

  constructor(public readonly chart: Chart) {
  }

  public initializeEvents() {
    this.chart.timeAxis.getRangeObservable().subscribe(() => {
      this.updateBar(this.position.value);
    });
    this.getPositionObservable().subscribe(p => {
      this.updateSubChart(p);
      this.updateBar(p);
      if (this.getMouseOver()) {
        this.snapData.update(p);
      }
    });
    const combinedObservable = combineLatest([this.barTime, this.price]);
    combinedObservable.subscribe(([time, price]) => {
      const isSynchronizationEnabled = this.chart.optionManager.synchronizeMousePosition.getValue();
      if (!this.getMouseOver() || !isSynchronizationEnabled) {
        return;
      }
      const mainPrice = this.subChart.value?.isPrimary ? price : null;
      this.chart.callbacks.onMouseMove(this.chart, time, mainPrice);
    });
  }

  public getPositionObservable() {
    return this.position.pipe(
      distinctUntilChanged((last, current) => last.x === current.x && last.y === current.y),
      startWith(this.position.value)
    );
  }

  public getPosition() {
    return this.position.value;
  }

  public setPosition(position: Point) {
    this.position.next(position);
  }

  public getBarTimeObservable() {
    return this.barTime.pipe(distinctUntilChanged(), startWith(this.barTime.value));
  }

  public getBarTime() {
    return this.barTime.value;
  }

  public getBarIndexObservable() {
    return this.barIndex.pipe(distinctUntilChanged(), startWith(this.barIndex.value));
  }

  public getBarIndex() {
    return this.barIndex.value;
  }

  public getPriceObservable() {
    return this.price.pipe(distinctUntilChanged(), startWith(this.price.value));
  }

  public getPrice() {
    return this.price.value;
  }

  public getSnapPosition(subChart: SubChart) {
    if (subChart !== this.getSubChart()) {
      return null;
    }
    const barTime = this.getBarTime();
    const price = this.getPrice();
    const snapPosition = this.snapData.getPosition();
    const isSnapEnabled = this.chart.optionManager.useToolSnapPrice.getValue();
    if (isSnapEnabled && snapPosition != null) {
      return snapPosition;
    }
    if (barTime != null && price != null) {
      return new SnapPosition(barTime, price);
    }
    return null;
  }

  public getSubChart() {
    return this.subChart.value;
  }

  public getSubChartObservable() {
    return this.subChart.pipe(distinctUntilChanged(), startWith(this.subChart.value));
  }

  public getMouseOver() {
    return this.isMouseOver.value;
  }

  public getMouseOverObservable() {
    return this.isMouseOver.pipe(distinctUntilChanged(), startWith(this.isMouseOver.value));
  }

  public setMouseOver(isMouseOver: boolean) {
    this.isMouseOver.next(isMouseOver);
    if (!isMouseOver) {
      this.subChart.next(null);
    }
  }

  public getMouseDown() {
    return this.isMouseDown;
  }

  public setMouseDown(isMouseDown: boolean) {
    this.isMouseDown = isMouseDown;
    this.isDragging = false;
    if (isMouseDown) {
      this.mouseDownPosition = this.getPosition();
      this.maxDraggingDiff = 0;
    }
  }

  public updateMaxDraggingDifference(maxDraggingDiff: number) {
    this.maxDraggingDiff = Math.max(maxDraggingDiff, this.maxDraggingDiff);
  }

  public getMouseDownPosition() {
    return this.mouseDownPosition;
  }

  public getMaxDraggingDifference() {
    return this.maxDraggingDiff;
  }

  public getDragging() {
    return this.isDragging;
  }

  public setDragging(isDragging: boolean) {
    this.isDragging = isDragging;
  }

  public getTouchDifference() {
    return this.touchDifference;
  }

  public setTouchDifference(difference: number) {
    this.touchDifference = difference;
  }

  private updateBar(position: Point) {
    const index = Math.round(this.chart.timeAxis.getIndexForX(position.x));
    this.barIndex.next(index);
    const time = this.chart.timeAxis.getTime(index);
    this.barTime.next(time);
    this.updatePrice(position);
  }

  private updatePrice(position: Point) {
    const subChart = this.getSubChart();
    const price = subChart == null ? null : subChart.priceAxis.getPrice(position.y);
    this.price.next(price);
  }

  private updateSubChart(position: Point) {
    const subChart = this.resolveSubChart(position);
    this.subChart.next(subChart);
  }

  private resolveSubChart(point: Point) {
    for (const subChart of this.chart.getSubCharts()) {
      const subChartPosition = subChart.mainArea.getPosition();
      if (isWithin(subChartPosition, point)) {
        return subChart;
      }
    }
    return null;
  }
}

export class SnapPosition {

  constructor(public readonly time: number,
              public readonly price: number) {
  }
}

export class MouseSnapData {

  private readonly position = new BehaviorSubject<SnapPosition | null>(null);

  constructor(private readonly mouseData: MouseData) {
  }

  private get maxDistance() {
    return this.mouseData.chart.styleOptions.maxSnapDistance.getValue();
  }

  public getPosition() {
    return this.position.value;
  }

  public update(position: Point) {
    const barTime = this.mouseData.getBarTime();
    const subChart = this.mouseData.getSubChart();
    const price = this.mouseData.getPrice();
    if (barTime == null || subChart == null || price == null) {
      this.position.next(null);
      return;
    }
    const { closestDistance, closestPosition } = this.findClosestSnapPosition(position, subChart, price);
    const snapPosition = closestDistance <= this.maxDistance ? closestPosition : null;
    this.position.next(snapPosition);
  }

  private findClosestSnapPosition(position: Point, subChart: SubChart, price: number) {
    const timeAxis = this.mouseData.chart.timeAxis;
    const range = this.getSnapRange();
    let closestPosition: SnapPosition | null = null;
    let closestDistance = Infinity;
    for (let timeIndex = range.start; timeIndex <= range.end; timeIndex++) {
      const time = timeAxis.getTime(timeIndex);
      if (time == null) {
        continue;
      }
      const x = timeAxis.getXForIndex(timeIndex);
      const value = this.getClosestSnapValue(time, price);
      const y = subChart.priceAxis.getY(value);
      const distance = getDistance(new Point(x, y), position);
      if (distance < closestDistance) {
        closestPosition = new SnapPosition(time, value);
        closestDistance = distance;
      }
    }
    return { closestPosition, closestDistance };
  }

  private getSnapRange() {
    const barIndex = this.mouseData.getBarIndex();
    const barWidth = this.mouseData.chart.timeAxis.getBarWidth();
    if (barWidth === 0) {
      return new Range(0, -1);
    }
    const offset = Math.trunc(this.maxDistance / barWidth);
    const startIndex = Math.trunc(barIndex - offset);
    const endIndex = Math.ceil(barIndex + offset);
    return new Range(startIndex, endIndex);
  }

  private getClosestSnapValue(time: number, price: number) {
    const subChart = this.mouseData.getSubChart();
    const plots = subChart == null ? [] : subChart.getPlots();
    let closestValue = Infinity;
    let closestDistance = Infinity;
    for (const plot of plots) {
      const item = plot.store.getByTime(time);
      if (item == null) {
        continue;
      }
      for (const value of item.values()) {
        const distance = Math.abs(value - price);
        if (distance < closestDistance) {
          closestValue = value;
          closestDistance = distance;
        }
      }
    }
    return closestValue;
  }
}

<template>
  <tr
    class="watchlist-table-item" :class="{ active: active, highlighted: isHighlighted }"
    @click="onClick" @mouseenter="startHover" @mouseleave="endHover"
  >
    <td v-for="column in columns" :key="column.key" :style="{ textAlign: column.alignmentCss }">
      <div v-if="column.key === WatchlistColumnKey.Category">
        <WatchlistCategorySelector ref="categorySelector" :item="item" :showUnset="isHighlighted" />
      </div>
      <div v-else-if="column.key === WatchlistColumnKey.Symbol">
        {{ getShortCaption(item.symbol) }}
      </div>
      <div v-else-if="column.key === WatchlistColumnKey.LastPrice">
        {{ item.data.price.toFixed(fractionDigits) }}
      </div>
      <div v-else-if="column.key === WatchlistColumnKey.DifferenceAbsolute" class="percent-label" :class="{ 'percent-down': item.data.percent < 0 }">
        {{ item.data.diff.toFixed(fractionDigits) }}
      </div>
      <div v-else-if="column.key === WatchlistColumnKey.DifferencePercentage" class="percent-label" :class="{ 'percent-down': item.data.percent < 0 }">
        {{ item.data.percent.toFixed(2) + " %" }}
      </div>
      <div v-else-if="column.key === WatchlistColumnKey.Delete">
        <IconElement :class="{ invisible: !isHighlighted || !allowDelete }" :size="16" iconName="Cross" @click.stop="removeItem" />
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { Instrument } from "@/anfin-chart/instrument";
import { WatchlistItem } from "@/api/models/watchlist";
import { instrumentStore } from "@/stores/instrument-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { userRightStore } from "@/stores/user-right-store";
import { NativeAppAdapter } from "@/utils/native-app-adapter";
import WatchlistCategorySelector from "@/views/watchlist/WatchlistCategorySelector.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent, type PropType } from "vue";
import type { TableColumn } from "@/views/table/table-column";
import { WatchlistColumnKey } from "@/views/watchlist/WatchlistTable.vue";

export default defineComponent({
  name: "WatchlistTableItem",

  components: { WatchlistCategorySelector, IconElement },

  props: {
    item: {
      type: WatchlistItem,
      required: true
    },
    columns: {
      type: Array as PropType<TableColumn[]>,
      required: true
    },
    active: Boolean,
    isHighlighted: Boolean,
    allowDelete: Boolean
  },

  emits: {
    removeItem: () => true,
    changeHover: (isHovered: boolean) => isHovered || true
  },

  expose: [],

  data() {
    return {
      hideTimeout: null as number | null,
      WatchlistColumnKey
    };
  },

  computed: {
    fractionDigits() {
      return instrumentStore().getDecimalPlaces(this.item.symbol, this.item.data.price);
    }
  },

  methods: {
    startHover() {
      this.$emit("changeHover", true);
      if (this.hideTimeout != null) {
        clearTimeout(this.hideTimeout);
      }
    },

    endHover() {
      this.$emit("changeHover", false);
      this.hideTimeout = window.setTimeout(() => {
        const categorySelectors = this.$refs.categorySelector as typeof WatchlistCategorySelector[];
        if (categorySelectors.length > 0) {
          categorySelectors[0].hide();
        }
      }, 200);
    },

    getShortCaption(symbol: string) {
      const instrument = instrumentStore().getDetail(symbol);
      const name = instrument?.printName ?? symbol;
      const index = name.indexOf(":");
      return index > 0 ? name.substring(0, name.indexOf(":")) : name;
    },

    removeItem() {
      this.$emit("removeItem");
    },

    onClick() {
      const symbol = this.item.symbol;
      NativeAppAdapter.getInstance().sendMessage("changeSymbol", symbol);
      if (!userRightStore().isMobile) {
        const instrument = Instrument.fromSymbol(symbol);
        multiChartStore().changeInstrument(instrument);
      }
    }
  }
});
</script>

<style scoped>
.watchlist-table-item {
  position: relative;
  border: 1px solid transparent;
  height: 30px;
  cursor: pointer;
}

.watchlist-table-item td {
  border-bottom: 1px solid var(--border-neutral);
}

.watchlist-table-item.active:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 6px;
  background-color: var(--interactive-accent);
}

.watchlist-table-item.highlighted {
  border: 1px solid var(--border-overlay);
  background: var(--background-overlay);
}

.percent-label {
  color: var(--system-green);
}

.percent-down {
  color: var(--system-red);
}
</style>

import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import type { HistoryDataResponse } from "@/api/messages/history-data";
import { HistoryDataConverter } from "@/api/models/history-data";
import { RestApiController } from "@/api/rest-api-controller";
import type { OHLCItem } from "@/anfin-chart/plot";

export class HistoryDataController extends RestApiController {

  private static instance: HistoryDataController | null = null;

  private readonly historyDataConverter = new HistoryDataConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new HistoryDataController();
    }
    return this.instance;
  }

  public static getParameterMap(limit: number, start: number | null, end: number | null) {
    const parameterMap = new Map<string, string>();
    parameterMap.set("limit", limit.toString());
    if (start != null) {
      parameterMap.set("start", this.formatDate(start));
      parameterMap.set("startDT", Math.trunc(start).toString());
    }
    if (end != null) {
      parameterMap.set("end", this.formatDate(end));
      parameterMap.set("endDT", Math.trunc(end).toString());
    }
    return parameterMap;
  }

  private static formatDate(time: number) {
    const date = new Date(time);
    const month = date.getUTCMonth() + 1;
    const fixLength = (value: number, count: number) => value.toString().padStart(count, "0");
    return date.getUTCFullYear() + "-" + fixLength(month, 2) + "-" +
      fixLength(date.getUTCDate(), 2) + "T" + fixLength(date.getUTCHours(), 2) + ":" +
      fixLength(date.getUTCMinutes(), 2) + ":" + fixLength(date.getUTCSeconds(), 2) + "." +
      fixLength(date.getUTCMilliseconds(), 3) + "%2B00:00";
  }

  public async requestHistory(instrument: Instrument,
                              timeframe: Timeframe,
                              limit: number,
                              start: number | null = null,
                              end: number | null = null) {
    const symbol = instrument.getSymbol();
    const timeframeValue = timeframe.toShortNotation();
    // temp. use this.environment.marketRequestMode;
    const requestMode = this.environment.marketRequestMode;
    const url = `hiku/markets/${symbol}/${timeframeValue}/${requestMode}/`;
    const parameterMap = HistoryDataController.getParameterMap(limit, start, end);
    const response = await this.sendGetRequest<HistoryDataResponse>(url, parameterMap);
    return this.historyDataConverter.toModelObject(response);
  }

  public async changeBarValues(instrument: Instrument, timeframe: Timeframe, item: OHLCItem) {
    const symbol = instrument.getSymbol();
    const timeframeValue = timeframe.toShortNotation();
    const url = `hiku/markets/correct/bar/${symbol}/${timeframeValue}?dumpToFile=true`;
    const request = [{
      timestamp: item.time / 1000,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
      vol: item.volume
    }];
    return await this.sendPostRequest(url, request);
  }

  protected override getBaseUrl() {
    return this.environment.marketUrl;
  }
}

import type { Observable } from "rxjs";
import { map, pairwise, pipe, type UnaryFunction } from "rxjs";

interface Diff<T> {
  added: T[];
  removed: T[];
}

export function diffArray<T>(): UnaryFunction<Observable<T[]>, Observable<Diff<T>>> {
  return pipe(
    pairwise(),
    map(([previous, current]) => {
      const added = current.filter(item => !previous.includes(item));
      const removed = previous.filter(item => !current.includes(item));
      return { added, removed };
    })
  );
}

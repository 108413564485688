<template>
  <div class="card card-sm card-transparent indicator-option">
    <IconElement class="close-button" :size="14" iconName="Cross" @click="remove" />
    <div>
      <label class="form-label">
        {{ translationStore.getTranslation("indicator_definition#label_option_name") }}
      </label>
      <input v-model="option.name" type="text" class="form-control form-control-sm" />
    </div>
    <div class="mt-1">
      <label class="form-label">
        {{ translationStore.getTranslation("indicator_definition#label_option_type") }}
      </label>
      <SingleSelect v-model="option.type" :items="typeItems" />
    </div>
    <div class="mt-1">
      <label class="form-label">
        {{ translationStore.getTranslation("indicator_definition#label_option_default_value") }}
      </label>
      <input :value="valueString" type="text" class="form-control form-control-sm" @input="setValue" />
    </div>
  </div>
</template>

<script lang="ts">
import { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import { OptionValueType } from "@/anfin-chart/options/option";
import { getEnumValues } from "@/anfin-chart/utils";
import { resolveValue } from "@/api/messages/chart-option";
import { translationStore } from "@/stores/translation-store";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent, toRaw } from "vue";

export default defineComponent({
  name: "IndicatorDefinitionOption",

  components: { SingleSelect, IconElement },

  props: {
    option: {
      type: ChartOptionDefinition,
      required: true
    }
  },

  emits: {
    remove: () => true
  },

  expose: [],

  data() {
    const typeItems = [];
    for (const optionType of getEnumValues(OptionValueType)) {
      const key = "indicator_definition#option_type#" + optionType;
      typeItems.push(new SingleSelectItem(key, null, optionType));
    }
    return {
      translationStore: translationStore(),
      typeItems
    };
  },

  computed: {
    valueString() {
      return String(this.option.value);
    }
  },

  methods: {
    setValue(event: Event) {
      const target = event.target as HTMLInputElement;
      this.option.value = toRaw(resolveValue(target.value, this.option.type));
    },

    remove() {
      this.$emit("remove");
    }
  }
});
</script>

<style scoped>

</style>

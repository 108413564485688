import { RestApiController } from "@/api/rest-api-controller";
import { Language, TranslationItem } from "@/stores/translation-store";
import type { LanguageListResponse, TranslationListResponse } from "@/api/messages/translations";

export class TranslationController extends RestApiController {

  private static instance: TranslationController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new TranslationController();
    }
    return this.instance;
  }

  public async requestLanguages() {
    const response = await this.sendGetRequest<LanguageListResponse>("translation/languages", null);
    return response.languages.map(t => new Language(t.language, t.caption));
  }

  public async requestTranslations(language: string) {
    const response = await this.sendGetRequest<TranslationListResponse>(`translations/${language}`, null);
    return response.translations.map(t => new TranslationItem(t.key, t.value));
  }

  public async reloadTranslations() {
    return await this.sendGetRequest<unknown>("translation/reload");
  }

  protected getBaseUrl() {
    return this.environment.configurationUrl;
  }
}

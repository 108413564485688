import { type TimeBased, TimeStore } from "@/anfin-chart/time/time-store";
import { TradingHours } from "@/anfin-chart/trading-times";
import type { Range } from "@/anfin-chart/geometry";
import type { Predicate } from "@/anfin-chart/utils";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { TimeAxis, TimeAxisItem } from "@/anfin-chart/area/time-axis";

export class ProjectedTimeStore extends TimeStore<TimeAxisItem> {

  private firstRealTime: number | null = null;
  private lastRealTime: number | null = null;
  private tradingHours = new TradingHours("UTC");

  constructor(private readonly timeAxis: TimeAxis) {
    super();
    this.chart.getMainInstrumentDataObservable().subscribe(instrumentData => {
      if (instrumentData != null) {
        this.tradingHours = this.chart.tradingHoursProvider.getTradingHours(instrumentData.instrument);
      }
    });
  }

  public override get length() {
    if (this.lastRealTime == null) {
      return 0;
    }
    const lastIndex = this.findPosition(this.lastRealTime);
    return lastIndex + 1;
  }

  private get chart() {
    return this.timeAxis.chart;
  }

  public override clear() {
    super.clear();
    this.firstRealTime = null;
    this.lastRealTime = null;
  }

  public override indexOf(time: number) {
    if (this.items.length === 0) {
      return null;
    }
    this.projectToTime(time);
    return super.indexOf(time);
  }

  public override indexOfFraction(time: number) {
    if (this.items.length === 0) {
      return 0;
    }
    this.projectToTime(time);
    return super.indexOfFraction(time);
  }

  public override insert(...items: TimeBased[]) {
    if (items.length === 0) {
      return;
    }
    const itemTimes = items.map(i => i.time);
    const firstTime = Math.min(...itemTimes);
    const lastTime = Math.max(...itemTimes);
    if (this.firstRealTime == null) {
      this.firstRealTime = firstTime;
    } else {
      this.removeProjections(firstTime, this.firstRealTime, true);
      this.firstRealTime = Math.min(this.firstRealTime, firstTime);
    }
    if (this.lastRealTime == null) {
      this.lastRealTime = lastTime;
    } else {
      this.removeProjections(this.lastRealTime, lastTime, false);
      this.lastRealTime = Math.max(this.lastRealTime, lastTime);
    }
    super.insert(...items.map(i => new TimeAxisItem(i.time)));
  }

  public projectToRange(range: Range) {
    if (this.items.length === 0) {
      return;
    }
    let futureCount = range.end - this.items.length;
    this.projectFutureWhile(() => futureCount-- > 0);
    let pastCount = -range.start;
    this.projectPastWhile(() => pastCount-- > 0);
    this.onItemsUpdate();
  }

  private projectToTime(until: number) {
    if (this.items.length === 0) {
      return;
    }
    this.projectFutureWhile(lastItem => until > lastItem.time);
    this.projectPastWhile(firstItem => until < firstItem.time);
    this.onItemsUpdate();
  }

  private projectFutureWhile(condition: Predicate<TimeBased>) {
    const instrumentData = this.chart.getMainInstrumentData();
    const currentProjections = this.lastRealTime == null ? 0 : this.items.length - 1 - this.findPosition(this.lastRealTime);
    const maxProjections = this.chart.styleOptions.maxBarCount.getValue();
    let lastItem = this.items[this.items.length - 1];
    const newItems = [];
    while (condition(lastItem) && currentProjections + newItems.length < maxProjections) {
      const nextTime = this.createProjection(this.tradingHours, lastItem.time, instrumentData.timeframe);
      if (nextTime == null || nextTime <= lastItem.time) {
        break;
      }
      lastItem = new TimeAxisItem(nextTime);
      newItems.push(lastItem);
    }
    if (newItems.length > 0) {
      this.items.push(...newItems);
      this.updateIndexes(this.items.length - newItems.length);
    }
  }

  private projectPastWhile(condition: Predicate<TimeBased>) {
    const instrumentData = this.chart.getMainInstrumentData();
    const timeframe = new Timeframe(instrumentData.timeframe.unit, -instrumentData.timeframe.value);
    const currentProjections = this.firstRealTime == null ? 0 : this.findPosition(this.firstRealTime);
    const maxProjections = this.chart.styleOptions.maxBarCount.getValue();
    let firstItem = this.items[0];
    const newItems = [];
    while (condition(firstItem) && currentProjections + newItems.length < maxProjections) {
      const nextTime = this.createProjection(this.tradingHours, firstItem.time, timeframe);
      if (nextTime == null || nextTime >= firstItem.time) {
        break;
      }
      firstItem = new TimeAxisItem(nextTime);
      newItems.push(firstItem);
    }
    if (newItems.length > 0) {
      this.items.splice(0, 0, ...newItems.reverse());
      this.updateIndexes(0);
      const range = this.timeAxis.getRange();
      this.timeAxis.setRange(range.start + newItems.length, range.end + newItems.length);
    }
  }

  private createProjection(tradingHours: TradingHours, baseTime: number, timeframe: Timeframe) {
    if (this.items.length === 0) {
      return null;
    }
    const nextTime = tradingHours.getNext(baseTime, timeframe);
    if (nextTime == null) {
      console.error("Could not project time from " + baseTime);
      return null;
    }
    return nextTime;
  }

  private removeProjections(start: number, end: number, isPast: boolean) {
    let startIndex = Math.ceil(this.findPosition(start));
    let endIndex = Math.trunc(this.findPosition(end));
    if (this.items.length === 0 || startIndex >= endIndex) {
      return;
    }
    if (isPast) {
      endIndex--;
    } else {
      startIndex++;
    }
    this.removeItem(startIndex, endIndex - startIndex + 1);
  }
}
<template>
  <div>
    <SettingsWindowOptionWrapper v-for="option in analysesOptions" :key="option.name" :option="option" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsWindowOptionWrapper from "@/views/settings/SettingsWindowOptionWrapper.vue";
import { userRightStore } from "@/stores/user-right-store";
import type { ChartOption } from "@/anfin-chart/options/option";
import { storeToRefs } from "pinia";
import { chartOptionStore, MultiChartOptionManager } from "@/stores/chart-option-store";
import { SettingsWindowMode } from "@/stores/ui-state-store";

export default defineComponent({
  name: "AnalysisSettings",

  components: { SettingsWindowOptionWrapper },

  expose: [],

  data() {
    const { optionManager } = storeToRefs(chartOptionStore());
    return {
      optionManager: optionManager as unknown as MultiChartOptionManager,
      SettingsWindowMode
    };
  },

  computed: {
    analysesOptions() {
      const optionManager = this.optionManager;
      const options = [];
      options.push(optionManager.showAnalysisTools);
      options.push({ name: "-" });
      if (userRightStore().isAdmin) {
        options.push(optionManager.showFibonaccis);
      }
      options.push(optionManager.showFibonacciTrends);
      options.push(optionManager.showHorizontals);
      options.push(optionManager.showDoubleExtreme);
      options.push(optionManager.showChannels);
      if (userRightStore().isAdmin) {
        options.push(optionManager.showExtremes);
        options.push(optionManager.showTrends);
        options.push(optionManager.trendLine.shownTypes);
      }
      options.push(optionManager.showPriceGaps);
      if (userRightStore().isAdmin) {
        options.push(optionManager.showHeadAndShoulders);
      }
      options.push({ name: "-" });
      options.push(optionManager.showPublicTools);
      return options as ChartOption<any>[];
    }
  }
});
</script>

<style scoped>
</style>
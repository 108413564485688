import { type StoredUserInfo, UserInfo, UserInfoConverter } from "@/api/models/user-info";

export function getUserInfo() {
  const storedUser = localStorage.getItem("user");
  if (storedUser == null) {
    return new UserInfo("", "", "", "", "", false);
  }
  const parsedUser = JSON.parse(storedUser) as StoredUserInfo;
  return new UserInfoConverter().toModelObject(parsedUser);
}

import { Timeframe } from "@/anfin-chart/time/timeframe";
import { defineStore } from "pinia";
import { FavoritesController } from "@/api/favorites-controller";
import type { Favorite } from "@/api/messages/favorite";

export class ToolFavorite {

  constructor(public readonly key: string,
              public readonly type: string,
              public readonly presetId: number | null) {
  }
}

export const favoriteStore = defineStore({
  id: "favorite",

  state() {
    setTimeout(() => initializeStore());
    return {
      controller: FavoritesController.getInstance(),
      favoriteSet: new Set<string>(),
      timeframeSet: new Set<string>(),
      timeframePrefix: "timeframe#",
      toolPrefix: "tool#",
      presetPrefix: "preset_",
      isLoading: false
    };
  },

  getters: {
    timeframes(): Timeframe[] {
      const timeframes = [];
      for (const key of this.timeframeSet) {
        const notation = key.substring(this.timeframePrefix.length);
        timeframes.push(Timeframe.fromShortNotation(notation));
      }
      return timeframes;
    },

    tools(): ToolFavorite[] {
      const tools = [];
      for (const key of this.favoriteSet) {
        if (!key.startsWith(this.toolPrefix)) {
          continue;
        }
        const toolKey = key.substring(this.toolPrefix.length);
        const split = toolKey.split("#");
        let presetId: number | null = null;
        if (split.length > 1 && split[1].startsWith(this.presetPrefix)) {
          presetId = Number(split[1].substring(this.presetPrefix.length));
        }
        const favorite = new ToolFavorite(toolKey, split[0], presetId);
        tools.push(favorite);
      }
      return tools;
    }
  },

  actions: {
    loadFavorites(data: { name: string, sortIndex: number }[]) {
      data.sort((f1, f2) => {
        return f1.sortIndex - f2.sortIndex;
      });

      this.isLoading = true;
      this.favoriteSet.clear();
      this.timeframeSet.clear();
      for (const item of data) {
        this.setFavorite(item.name);
      }
      this.isLoading = false;
    },

    async getFavorites() {
      const favorites: Favorite[] = await this.controller.requestFavorites();

      this.loadFavorites(favorites);
    },

    saveFavorites() {
      if (this.isLoading) {
        return;
      }
      this.controller.saveFavorites(this.favoriteSet);
    },

    isFavorite(key: string) {
      return this.favoriteSet.has(key);
    },

    toggle(key: string) {
      if (this.favoriteSet.has(key)) {
        this.unsetFavorite(key);
      } else {
        this.setFavorite(key);
      }
    },

    setFavorite(key: string) {
      this.favoriteSet.add(key);
      if (key.startsWith(this.timeframePrefix)) {
        this.timeframeSet.add(key);
      }
      this.saveFavorites();
    },

    unsetFavorite(key: string) {
      this.favoriteSet.delete(key);
      this.timeframeSet.delete(key);
      this.saveFavorites();
    },

    getTimeframeKey(timeframe: Timeframe) {
      return this.timeframePrefix + timeframe.toShortNotation();
    }
  }
});

function initializeStore() {
  const store = favoriteStore();
  store.getFavorites();
}

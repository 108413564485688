import { ChartAction } from "@/anfin-chart/actions/actions";
import type { Chart } from "@/anfin-chart/chart";
import { isWithin } from "@/anfin-chart/geometry";
import type { ClickData, DragData } from "@/anfin-chart/interactions";
import { getOptionDefinitions } from "@/anfin-chart/options/option";
import { FreehandDrawTool } from "@/anfin-chart/tools/user-tools/fhdraw";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";

export class UserToolAction extends ChartAction {

  private isFinished = false;

  constructor(chart: Chart,
              public readonly key: string,
              public readonly tool: UserTool) {
    super(chart);
    tool.createPoint();
  }

  public onCancel = () => {
    if (!this.isFinished) {
      if (this.tool.definition.minCount >= this.tool.definition.fixedPoints.length) {
        this.tool.delete();
      } else {
        this.tool.removeLastPoint();
      }
    }
    return true;
  };

  public onMouseDown = (data: ClickData) => {
    if (this.chart.isSecondaryActionMode()) {
      return false;
    }
    const drawingAreaPosition = this.chart.drawingAreaWrapper.getPosition();
    if (!isWithin(drawingAreaPosition, data.position)) {
      return false;
    }
    this.chart.dragHandler.unselect();
    if (!(this.tool.definition instanceof FreehandDrawTool)) {
      this.fixPoint();
    }
    return true;
  };

  public onDragMove = (data: DragData) => {
    if (this.tool.definition instanceof FreehandDrawTool) {
      this.tool.definition.tracePoint(this.tool, data.position);
      return true;
    }
    return false;
  };

  public onMouseUp = (data: ClickData) => {
    if (!this.chart.mouseData.getMouseOver()) {
      return false;
    }
    if (this.tool.definition instanceof FreehandDrawTool) {
      const tracePoints = this.tool.definition.getTracePoints();
      if (tracePoints.length < 3) {
        this.onCancel();
        this.chart.dragHandler.onDragStart(data);
      } else if (tracePoints.length < 10) {
        this.onCancel();
        this.setNextInstance();
      } else {
        this.isFinished = true;
      }
    }
    if (this.isFinished) {
      this.finishUserTool();
    }
    return true;
  };

  public onMouseMove = () => {
    const subChart = this.chart.mouseData.getSubChart();
    if (subChart == null || this.isFinished || this.tool.definition instanceof FreehandDrawTool && this.chart.mouseData.getMouseDown()) {
      return false;
    }
    if (this.tool.definition.fixedPoints.length === 1) {
      this.tool.setSubChart(subChart);
    }
    this.tool.moveToMousePosition(this.tool.currentPoint);
    return true;
  };

  private fixPoint() {
    const barTime = this.chart.mouseData.getBarTime();
    const price = this.chart.mouseData.getPrice();
    const pointCount = this.tool.definition.fixedPoints.length;
    if (barTime == null || price == null || this.tool.getSubChart() !== this.chart.mouseData.getSubChart()) {
      return;
    }
    const maxCount = this.tool.definition.maxCount;
    if (maxCount != null && maxCount <= pointCount) {
      this.isFinished = true;
    } else if (!(this.tool.definition instanceof FreehandDrawTool)) {
      this.tool.createPoint();
    }
    this.chart.toolLayer.requireDraw();
  }

  private finishUserTool() {
    if (this.chart.optionManager.isChainToolMode.getValue() && !this.chart.isMobileMode()) {
      this.setNextInstance();
    } else {
      this.chart.setDefaultMode();
    }
    this.tool.finishInitialization();
    this.tool.select();
  }

  private setNextInstance() {
    const options = getOptionDefinitions(this.tool.definition).filter(o => o.name !== OptionName.FreehandTracePoints);
    this.chart.setPendingTool(this.tool.definition.type, options, this.key);
  }
}

<template>
  <div class="chart-image">
    <img
      class="mw-100"
      decoding="async"
      src="/src/assets/chart-w1920.jpg"
      alt="AnFin Chart"
      srcset="/src/assets/chart-w300.jpg 300w, /src/assets/chart-w600.jpg 600w, /src/assets/chart-w768.jpg 768w, /src/assets/chart-w1024.jpg 1024w, /src/assets/chart-w1536.jpg 1536w, /src/assets/chart-w1920.jpg 1920w"
    />
    <img src="/src/assets/anfin-watermark-2.svg" class="chart-image-overlay" alt="" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LandingPageChartImage",

  expose: []
});
</script>

<style scoped>
.chart-image {
  position: relative;
  overflow: hidden;
}

.chart-image-overlay {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
<template>
  <CustomDropdown :disabled="disabled">
    <template #header>
      {{ headerText }}
    </template>
    <template #items>
      <div v-for="item in items" :key="item.key" class="custom-dropdown-item" @click.stop="onItemClick(item)">
        <input v-model="item.isSelected" type="checkbox" class="custom-dropdown-item-check me-2" @change="onItemChange" />
        <span class="form-check-label">
          {{ translationStore.getTranslation(item.key) }}
        </span>
      </div>
    </template>
  </CustomDropdown>
</template>

<script lang="ts">
import { ClickOutside } from "@/directives/click-outside";
import { translationStore } from "@/stores/translation-store";
import CustomDropdown from "@/views/components/CustomDropdown.vue";
import { defineComponent, type PropType } from "vue";

export class MultiSelectItem {

  constructor(public readonly key: string,
              public readonly value: unknown,
              public isSelected: boolean) {
  }

  public toggle() {
    this.isSelected = !this.isSelected;
  }
}

export default defineComponent({
  name: "MultiSelect",

  components: { CustomDropdown },

  directives: { ClickOutside },

  props: {
    items: {
      type: Array as PropType<MultiSelectItem[]>,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },

  emits: {
    change: (values: unknown[]) => values || true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    headerText() {
      const selectedCount = this.selectedItems.length;
      if (selectedCount === this.items.length) {
        return this.translationStore.getTranslation("multi_select#caption_all");
      }
      if (selectedCount === 0) {
        return this.translationStore.getTranslation("multi_select#caption_none");
      }
      return selectedCount + " " + this.translationStore.getTranslation("multi_select#caption_selected_count");
    },

    selectedItems() {
      return this.items.filter(o => o.isSelected);
    }
  },

  methods: {
    onItemClick(item: MultiSelectItem) {
      item.toggle();
      this.onItemChange();
    },

    onItemChange() {
      this.$emit("change", this.items.filter(i => i.isSelected).map(i => i.value));
    }
  }
});
</script>

<style scoped>
</style>

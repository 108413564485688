import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { LineElement } from "@/anfin-chart/drawable";
import type { Instrument } from "@/anfin-chart/instrument";
import { BooleanOption, ColorOption } from "@/anfin-chart/options/option";
import { FixedSelectablePoint, ValueSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint, getLeftRight } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { Point } from "@/anfin-chart/geometry";
import { UserToolAlertHook } from "@/anfin-chart/tools/alert-hook";

export class HorizontalSegment extends UserToolDefinition {

  public static readonly type = "hsegment";

  private readonly color = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(22, 168, 22));
  private readonly expandLeft = new BooleanOption(this, OptionName.ExpandLeft, false);
  private readonly expandRight = new BooleanOption(this, OptionName.ExpandRight, false);

  private readonly left = new ChartToolPoint();
  private readonly right = new ChartToolPoint();
  private readonly leftExpanded = new ChartToolPoint();
  private readonly rightExpanded = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(HorizontalSegment.type, instrument, timeframe, 2, 2);
    new UserToolAlertHook(this, "main", this.right, 1);
  }

  public override createDrawables(tool: UserTool) {
    tool.withExactPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
  }

  public override updatePosition(tool: UserTool) {
    const [first, second] = this.fixedPoints.map(p => tool.getPosition(p));
    if (this.fixedPoints.length >= 2) {
      const [left, right] = getLeftRight([first, second]);
      const y = right.y;
      const leftPosition = new Point(left.x, y);
      tool.updatePoint(this.left, leftPosition);
      const rightPosition = new Point(right.x, y);
      tool.updatePoint(this.right, rightPosition);
      const isExpandLeft = this.expandLeft.getValue();
      const isExpandRight = this.expandRight.getValue();
      const rect = tool.subChart.drawingArea.getPosition();
      const leftExpanded = new Point(isExpandLeft ? rect.xStart : left.x, y);
      tool.updatePoint(this.leftExpanded, leftExpanded);
      const rightExpanded = new Point(isExpandRight ? rect.xEnd : right.x, y);
      tool.updatePoint(this.rightExpanded, rightExpanded);
    }
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const [first, second] = this.fixedPoints;
    const line = new LineElement(tool, this.leftExpanded, this.rightExpanded);
    tool.onUpdate(() => {
      line.color = this.color.getValue();
      line.options.width = tool.getLineWidth(true);
    });
    new ValueSelectablePoint(tool, this.left, (time, price) => {
      first.update(time, price);
      second.update(second.time, price);
    });
    new ValueSelectablePoint(tool, this.right, (time, price) => {
      second.update(time, price);
      first.update(first.time, price);
    });
  }
}

<template>
  <LandingPageWrapper>
    <LandingPageSection :paddingBottom="20" class="section-gray pt-md-5 py-4">
      <div class="landing-header">
        Häufig gestellte Fragen
      </div>
      <div class="landing-sub-header mt-4">
        Hier erfahren Sie weitere Details zu den Inhalten und Funktionsweisen der Produkte. Klicken Sie dafür
        auf einen der Oberbegriffe
      </div>
      <div class="faq-category-container mt-4">
        <div
          v-for="category in categories" class="faq-category" :class="{ active: category === currentCategory }"
          type="button" @click="setCategory(category)"
        >
          {{ category.caption }}
        </div>
      </div>
    </LandingPageSection>
    <FAQSection v-if="currentCategory != null" :category="currentCategory" class="pt-md-5 py-4" />
    <ContactSection class="section-gray pt-md-5 py-4" />
    <BestChoiceSection class="pt-md-5 py-4" />
    <LandingPageSection class="section-gray pt-md-5 py-4">
      <div class="slogan-container">
        <div class="landing-header slogan">
          Der Weg zum Ziel beginnt an dem Tag, an dem Du die hundertprozentige Verantwortung für Dein Tun übernimmst.
        </div>
        <div class="slogan-author mt-3">
          Dante Alighieri
        </div>
        <AnfinLogo :style="LogoStyle.Green" class="anfin-logo-sm mt-4" />
      </div>
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import BestChoiceSection from "@/views/pages-landing/sections/BestChoiceSection.vue";
import ContactSection from "@/views/pages-landing/sections/ContactSection.vue";
import AnfinLogo from "@/views/components/AnfinLogo.vue";
import { LogoStyle } from "@/api/models/theme";
import { faqCategories, FAQCategory, FAQContentType } from "@/api/models/faq";
import FAQSection from "@/views/pages-landing/sections/FAQSection.vue";

export default defineComponent({
  name: "FAQPage",

  components: { FAQSection, AnfinLogo, ContactSection, BestChoiceSection, LandingPageSection, LandingPageWrapper },

  expose: [],

  data() {
    return {
      categories: faqCategories,
      currentCategory: faqCategories[0],
      LogoStyle,
      FAQContentType
    };
  },

  methods: {
    setCategory(category: FAQCategory) {
      this.currentCategory = category;
    }
  }
});
</script>

<style scoped>
.faq-category-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 8px;
  align-items: center;
}

.faq-category {
  color: var(--background-primary);
  font-size: 120%;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 12px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius-single);
}

.faq-category.active {
  border-color: var(--background-primary);
}

.slogan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.slogan {
  max-width: 1000px;
  font-weight: bold;
}

.slogan-author {
  font-size: 120%;
  font-weight: bold;
  font-style: italic;
  color: var(--background-primary);
  opacity: 0.8;
}

@media (max-width: 767px) {
  .faq-category-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
</style>
import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ElliotWave } from "@/anfin-chart/tools/user-tools/elliot-wave";

export class Elliot12345 extends ElliotWave {

  public static readonly type = "ell12345";

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(
      Elliot12345.type, instrument, timeframe, 6, ["0", "1", "2", "3", "4", "5"],
      new RGBAColor(0, 116, 178)
    );
  }
}

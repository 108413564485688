import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { Vector } from "@/anfin-chart/geometry";
import { HintText } from "@/anfin-chart/hints/chart-hint";
import { ToolHint } from "@/anfin-chart/hints/tool-hint";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";
import type { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import type { AutoExtreme } from "@/api/models/analysis/auto-extreme";

export class ExtremeHint extends ToolHint {

  constructor(tool: ChartTool,
              point: ChartToolPoint,
              extreme: AutoExtreme) {
    super(tool, point, new TextAlignment(new Vector(0, extreme.isHigh ? -1 : 1), 10), RGBAColor.transparent);
    const dateFormatted = this.chart.formatDate(new Date(extreme.time));
    const text = extreme.description + " | " + this.chart.formatPrice(extreme.price) + " | " + dateFormatted;
    this.addItem(new HintText(this, text));
  }
}

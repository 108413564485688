import type { RangeCache } from "@/anfin-chart/area/time-axis";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import type { ChartAnalysis } from "@/api/models/user-settings/analysis";
import type { ChartView, MultiWindowTemplate } from "@/api/models/user-settings/chart";
import { UserSettingsController } from "@/api/user-settings-controller";
import { defineStore } from "pinia";
import { toastStore, ToastType } from "@/stores/toast-store";

function getKey(instrument: Instrument, timeframe: Timeframe) {
  return instrument.getSymbol() + "_" + timeframe.toShortNotation();
}

export const userSettingStore = defineStore({
  id: "user-setting",

  state() {
    return {
      controller: UserSettingsController.getInstance(),
      chartViewMap: new Map<string, ChartView>(),
      namedChartViews: [] as ChartView[],
      chartTemplateMap: new Map<number, MultiWindowTemplate>(),
      chartAnalysisMap: new Map<number, ChartAnalysis>(),
      rangeCacheMap: new Map<string, RangeCache>()
    };
  },

  getters: {
    chartTemplates(): MultiWindowTemplate[] {
      return Array.from(this.chartTemplateMap.values());
    }
  },

  actions: {
    getRangeCache(timeframe: Timeframe) {
      const key = timeframe.toShortNotation();
      return this.rangeCacheMap.get(key) ?? null;
    },

    setRangeCache(timeframe: Timeframe, rangeCache: RangeCache) {
      const key = timeframe.toShortNotation();
      this.rangeCacheMap.set(key, rangeCache);
    },

    clearRangeCache() {
      this.rangeCacheMap.clear();
    },

    async fetchChartViews(instrument: Instrument) {
      // current not used
      // this.namedChartViews = await UserSettingsController.getInstance().getChartViewList(instrument);
    },

    async getChartView(instrument: Instrument, timeframe: Timeframe) {
      const key = getKey(instrument, timeframe);
      const existingView = this.chartViewMap.get(key);
      if (existingView != null) {
        return existingView;
      }
      const view = await this.controller.getChartView(instrument, timeframe);
      if (view != null) {
        this.chartViewMap.set(key, view);
      }
      return view;
    },

    async saveChartView(view: ChartView) {
      if (view.tools.length === 0 && view.name == null) {
        await this.deleteChartView(view);
        return;
      }
      if (view.name == null) {
        const key = getKey(view.instrument, view.timeframe);
        this.chartViewMap.set(key, view);
      } else {
        this.namedChartViews.push(view);
      }
      view.id = await this.controller.saveChartView(view);
    },

    async deleteChartView(view: ChartView) {
      if (view.name == null) {
        const key = getKey(view.instrument, view.timeframe);
        this.chartViewMap.delete(key);
      } else {
        const index = this.namedChartViews.findIndex(v => v.id === view.id);
        this.namedChartViews.splice(index, 1);
      }
      if (view.id != null) {
        await this.controller.deleteChartView(view);
      }
    },

    async fetchChartTemplates() {
      if (this.chartTemplateMap.size !== 0) {
        return;
      }
      const templates = await this.controller.getChartTemplateList();
      for (const template of templates) {
        if (template.id != null) {
          this.chartTemplateMap.set(template.id, template);
        }
      }
    },

    async saveChartTemplate(template: MultiWindowTemplate) {
      const id = await this.controller.saveChartTemplate(template);
      template.id = id;
      this.chartTemplateMap.set(id, template);
      toastStore().addToast(ToastType.Info, "toast#template_saved", { name: template.name });
    },

    async deleteChartTemplate(template: MultiWindowTemplate) {
      if (template.id != null) {
        this.chartTemplateMap.delete(template.id);
        await this.controller.deleteTemplate(template);
      }
    },

    async saveChartAnalysis(analysis: ChartAnalysis) {
      const id = await this.controller.saveChartAnalysis(analysis);
      analysis.id = id;
      this.chartAnalysisMap.set(id, analysis);
    }
  }
});

import { UserToolAction } from "@/anfin-chart/actions/user-tool-action";
import { Drawable } from "@/anfin-chart/drawable";
import { getDistance, Point } from "@/anfin-chart/geometry";
import type { DragData, Draggable } from "@/anfin-chart/interactions";
import type { ChartToolPoint, FixedPoint } from "@/anfin-chart/tools/tool-point";
import type { Consumer } from "@/anfin-chart/utils";
import { UserTool } from "@/anfin-chart/tools/user-tool";

export abstract class SelectablePoint extends Drawable implements Draggable {

  constructor(tool: UserTool,
              public readonly point: ChartToolPoint) {
    super(tool);
  }

  public override getIsVisible() {
    if (!super.getIsVisible()) {
      return false;
    }
    if (this.chart.action instanceof UserToolAction && this.chart.action.tool === this.tool) {
      return true;
    }
    for (const target of this.chart.dragHandler.getTargets()) {
      if (target instanceof Drawable && target.tool === this.tool ||
        target instanceof UserTool && target === this.tool) {
        return true;
      }
    }
    return this.tool.isHovered;
  }

  public override isHitInternal(point: Point) {
    const tolerance = this.getHitTolerance();
    const position = this.getPosition(this.point);
    return getDistance(position, point) <= tolerance;
  }

  public onDrag(data: DragData) {
    this.moveTo(data.position);
    this.tool.updateDrawables();
  }

  public onDragEnd() {
    (this.tool as UserTool).save();
  }

  protected override getHitTolerance() {
    if (this.chart.isMobileMode()) {
      return this.chart.styleOptions.hitToleranceMobile.getValue();
    }
    return this.chart.styleOptions.toolPointRadius.getValue();
  }

  protected abstract moveTo(point: Point): void;
}

export class FixedSelectablePoint extends SelectablePoint {

  protected override moveTo() {
    (this.tool as UserTool).moveToMousePosition(this.point as FixedPoint);
  }
}

export class ValueSelectablePoint extends SelectablePoint {

  constructor(tool: UserTool,
              point: ChartToolPoint,
              private readonly action: (time: number, price: number) => void) {
    super(tool, point);
  }

  protected override moveTo() {
    const snapPosition = this.chart.mouseData.getSnapPosition(this.subChart);
    if (snapPosition != null) {
      this.action(snapPosition.time, snapPosition.price);
      this.tool.updatePosition();
    }
  }
}

export class PositionSelectablePoint extends SelectablePoint {

  constructor(tool: UserTool,
              point: ChartToolPoint,
              private readonly action: Consumer<Point>) {
    super(tool, point);
  }

  protected override moveTo(point: Point) {
    this.action(point);
    this.tool.updatePosition();
  }
}

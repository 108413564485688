<template>
  <ChartOptionWrapper
    :option="option" :disabled="isOptionDisabled(option)" class="settings-window-option-wrapper"
  >
    <template #boolean="slotProps">
      <OnOffSwitch
        :modelValue="slotProps.value"
        :caption="translationStore.getTranslation('chart_option#' + option.name)"
        :showLabels="true"
        :disabled="slotProps.disabled"
        class="w-100"
        @update:modelValue="slotProps.setValue"
      />
    </template>
    <template #trendLineTypes="slotProps">
      <div class="settings-window-option">
        <span class="settings-window-option-label">
          {{ translationStore.getTranslation("chart_option#" + option.name) }}
        </span>
        <SelectTrendLineType
          :value="slotProps.value"
          :disabled="slotProps.disabled"
          class="settings-window-option-input"
          @change="slotProps.setValue"
        />
      </div>
    </template>
    <template #enum="slotProps">
      <div class="settings-window-option">
        <span class="settings-window-option-label">
          {{ translationStore.getTranslation("chart_option#" + option.name) }}
        </span>
        <SingleSelect
          :modelValue="slotProps.value"
          :items="getEnumOptions(option.name)"
          class="settings-window-option-input"
          @update:modelValue="slotProps.setValue"
        />
      </div>
    </template>
  </ChartOptionWrapper>
</template>

<script lang="ts">
import { GridDisplay } from "@/anfin-chart/area/drawing-area-wrapper";
import { PriceScaleType } from "@/anfin-chart/area/price-axis";
import type { ChartOption } from "@/anfin-chart/options/option";

import { ToolSynchronization } from "@/anfin-chart/tool-synchronization";
import { getEnumValues } from "@/anfin-chart/utils";
import { chartOptionStore } from "@/stores/chart-option-store";
import { translationStore } from "@/stores/translation-store";
import { userRightStore } from "@/stores/user-right-store";
import SelectTrendLineType from "@/views/components/SelectTrendLineType.vue";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import OnOffSwitch from "@/views/components/OnOffSwitch.vue";
import ChartOptionWrapper from "@/views/toolbar/ChartOptionWrapper.vue";
import { storeToRefs } from "pinia";
import { defineComponent, type PropType } from "vue";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { Theme } from "@/api/models/theme";
import { AutoToolRightKey, UserRightKey } from "@/api/models/user-right";
import { NotificationSoundType } from "@/api/models/notification";

export default defineComponent({
  name: "SettingsWindowOptionWrapper",

  components: { SingleSelect, OnOffSwitch, SelectTrendLineType, ChartOptionWrapper },

  props: {
    option: {
      type: Object as PropType<ChartOption<any>>,
      required: true
    }
  },

  expose: [],

  data() {
    const { themes } = storeToRefs(chartOptionStore());
    return {
      gridOptions: getEnumValues(GridDisplay).map(g =>
        new SingleSelectItem("grid_display#" + g, null, g)
      ),
      priceScaleOptions: getEnumValues(PriceScaleType).map(p =>
        new SingleSelectItem("chart_price_scale#" + p, null, p)
      ),
      toolSynchronizationOptions: getEnumValues(ToolSynchronization).map(t =>
        new SingleSelectItem("tool_synchronization#" + t, null, t)
      ),
      notificationSoundOptions: getEnumValues(NotificationSoundType).map(n =>
        new SingleSelectItem("notification_sound#" + n, null, n)
      ),
      themes: themes as unknown as Theme[],
      userRightStore: userRightStore(),
      translationStore: translationStore()
    };
  },

  computed: {
    themeOptions() {
      return this.themes.map(t => new SingleSelectItem("theme#" + t.name, t.name, t.id));
    },

    isOptionDisabled() {
      return (option: ChartOption<any>) => {
        const optionShowAnalysisTools = chartOptionStore().optionManager.showAnalysisTools.getValue();
        const store = userRightStore();
        switch (option.name) {
          case OptionName.ShowAutoFibonaccis:
          case OptionName.ShowFibonacciTrendLines:
            return !optionShowAnalysisTools || !store.hasRight(UserRightKey.AutoTools, AutoToolRightKey.Fibonacci);
          case OptionName.ShownTrendLineTypes:
            return !optionShowAnalysisTools || !store.hasRight(UserRightKey.AutoTools, AutoToolRightKey.Trend);
          case OptionName.ShowAutoHorizontals:
            return !optionShowAnalysisTools || !store.hasRight(UserRightKey.AutoTools, AutoToolRightKey.Horizontal);
          case OptionName.ShowDoubleExtreme:
            return !optionShowAnalysisTools || !store.hasRight(UserRightKey.AutoTools, AutoToolRightKey.DoubleExtreme);
          case OptionName.ShowChannels:
            return !optionShowAnalysisTools || !store.hasRight(UserRightKey.AutoTools, AutoToolRightKey.Channels);
          case OptionName.ShowExtremes:
            return !optionShowAnalysisTools || !store.hasRight(UserRightKey.AutoTools, AutoToolRightKey.Extreme);
          case OptionName.ShowPriceGaps:
            return !optionShowAnalysisTools || !store.hasRight(UserRightKey.AutoTools, AutoToolRightKey.PriceGap);
          case OptionName.ShowHeadAndShoulders:
            return !optionShowAnalysisTools || !store.hasRight(UserRightKey.AutoTools, AutoToolRightKey.DoubleExtreme);
          default:
            return false;
        }
      };
    }
  },

  methods: {
    getEnumOptions(name: string) {
      switch (name) {
        case OptionName.GridDisplay:
          return this.gridOptions;
        case OptionName.PriceScale:
          return this.priceScaleOptions;
        case OptionName.ToolSynchronization:
          return this.toolSynchronizationOptions;
        case OptionName.Theme:
          return this.themeOptions;
        case OptionName.NotificationSound:
          return this.notificationSoundOptions;
        default:
          return [];
      }
    }
  }
});
</script>

<style scoped>
.settings-window-option-wrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  min-height: 2.5rem;
  padding-bottom: 25px;
}

.settings-window-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.settings-window-option > .settings-window-option-label {
  flex-grow: 1;
}

.settings-window-option > .settings-window-option-input {
  width: 160px;
}
</style>

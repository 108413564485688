import type { ColorProvider } from "@/anfin-chart/draw/chart-color";
import { LineStyle } from "@/anfin-chart/draw/chart-drawer";
import { getDistance, getLineDistance, Point } from "@/anfin-chart/geometry";
import type { DoubleClickable } from "@/anfin-chart/interactions";
import type { ChartObject } from "@/anfin-chart/options/option";
import { TimeStore } from "@/anfin-chart/time/time-store";

export enum PlotType {
  Line = 0,
  Candle = 1,
  Mountain = 2,
  Bar = 3,
  SimpleBar = 4
}

export class Plot implements DoubleClickable {

  public readonly store = new TimeStore<DataItem>();
  public lineWidth = 1;
  public lineStyle = LineStyle.Solid;

  public offsetProjection = 0;

  constructor(public readonly provider: PlotProvider & ChartObject,
              public name: string,
              public type: PlotType,
              public colorProvider: ColorProvider,
              public useAxis = false) {
    this.provider.plots.push(this);
  }

  public onDoubleClick() {
    this.provider.getSubChart().chart.editChartObject(this.provider);
  }

  public clear() {
    this.store.clear();
  }

  public getCurrentItem(offset = 0) {
    if (this.store.length <= offset) {
      return null;
    }
    return this.store.get(this.store.length - 1 - offset);
  }

  public getLast(offset = 0) {
    if (this.store.length <= offset) {
      return 0;
    }
    return this.store.get(this.store.length - 1 - offset).main;
  }

  public isHit(point: Point) {
    const subChart = this.provider.getSubChart();
    const position = subChart.chart.mouseData.getPosition();
    const index = Math.trunc(subChart.chart.timeAxis.getIndexForX(position.x) - this.offsetProjection);
    const time = subChart.chart.timeAxis.getTime(index);
    if (time == null) {
      return false;
    }
    const localIndex = this.store.indexOf(time);
    if (localIndex == null) {
      return false;
    }
    switch (this.type) {
      case PlotType.Line:
      case PlotType.Mountain:
        return this.isHitLine(localIndex, point);
      case PlotType.Bar:
      case PlotType.SimpleBar:
        return this.isHitBar(localIndex, point);
      case PlotType.Candle:
        return this.isHitBar(localIndex, point) || this.isHitWick(localIndex, point);
      default:
        return false;
    }
  }

  private getHitTolerance() {
    const chart = this.provider.getSubChart().chart;
    if (chart.isMobileMode()) {
      return chart.styleOptions.hitToleranceMobile.getValue();
    }
    return chart.styleOptions.lineHitTolerance.getValue();
  }

  private isHitLine(index: number, point: Point) {
    const subChart = this.provider.getSubChart();
    const lastIndex = Math.trunc(index);
    const lastItem = this.store.get(lastIndex);
    if (lastItem == null) {
      return false;
    }
    const lastX = this.getItemX(lastItem);
    const lastY = subChart.priceAxis.getY(lastItem.main);
    const lastPoint = new Point(lastX, lastY);
    const item = this.store.get(lastIndex + 1);
    let distance: number;
    if (item == null) {
      distance = getDistance(lastPoint, point);
    } else {
      const currentX = this.getItemX(item);
      const currentY = subChart.priceAxis.getY(item.main);
      const currentPoint = new Point(currentX, currentY);
      distance = getLineDistance(lastPoint, currentPoint, point);
    }
    return distance < this.getHitTolerance();
  }

  private isHitBar(index: number, point: Point) {
    const subChart = this.provider.getSubChart();
    const tolerance = this.getHitTolerance();
    const timeAxis = subChart.chart.timeAxis;
    const barIndex = Math.round(index);
    const item = this.store.get(barIndex);
    if (item == null) {
      return false;
    }
    const candleWidth = timeAxis.getCandleWidth();
    const x = this.getItemX(item);
    const distanceX = Math.abs(point.x - x) - candleWidth / 2;
    if (distanceX > tolerance) {
      return false;
    }
    const mainY = subChart.priceAxis.getY(item.main);
    const secondY = subChart.priceAxis.getY(item.second);
    let upperY: number;
    let lowerY: number;
    if (mainY > secondY) {
      upperY = mainY;
      lowerY = secondY;
    } else {
      upperY = secondY;
      lowerY = mainY;
    }
    return point.y - upperY <= tolerance && lowerY - point.y <= tolerance;
  }

  private isHitWick(index: number, point: Point) {
    const subChart = this.provider.getSubChart();
    const tolerance = this.getHitTolerance();
    const barIndex = Math.round(index);
    const item = this.store.get(barIndex);
    if (item == null) {
      return false;
    }
    const x = this.getItemX(item);
    const price = subChart.priceAxis.getPrice(point.y);
    return Math.abs(point.x - x) <= tolerance && item.max >= price && item.min <= price;
  }

  private getItemX(item: DataItem) {
    const subChart = this.provider.getSubChart();
    const timeAxis = subChart.chart.timeAxis;
    const index = timeAxis.getIndexForTime(item.time) + this.offsetProjection;
    return timeAxis.getXForIndex(index);
  }
}

export abstract class DataItem {

  public key: string | null = null;

  protected constructor(public readonly time: number) {
  }

  public abstract get main(): number;

  public abstract get second(): number;

  public abstract get min(): number;

  public abstract get max(): number;

  public abstract values(): number[];
}

export class OHLCItem extends DataItem {

  constructor(time: number,
              public open: number,
              public high: number,
              public low: number,
              public readonly close: number,
              public volume: number) {
    super(time);
  }

  public override get main() {
    return this.close;
  }

  public override get second() {
    return this.open;
  }

  public override get min() {
    return this.low;
  }

  public override get max() {
    return this.high;
  }

  public override values() {
    return [this.close, this.high, this.low, this.open];
  }
}

export class ValueBaseItem extends DataItem {

  constructor(time: number,
              public readonly value: number,
              public readonly base: number | null = null) {
    super(time);
  }

  public override get main() {
    return this.value;
  }

  public override get second() {
    return this.base ?? 0;
  }

  public override get min() {
    return this.value;
  }

  public override get max() {
    return this.value;
  }

  public override values() {
    if (this.base == null) {
      return [this.value];
    }
    return [this.value, this.base];
  }
}

export interface PlotProvider {
  plots: Plot[];
}

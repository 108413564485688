import { ChartColor, convertToRgbaCss, RGBAColor } from "@/anfin-chart/draw/chart-color";
import { LineOptions, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { getAngle, getDistance, getLineDistance, isWithin, Point, Rectangle } from "@/anfin-chart/geometry";
import type { DoubleClickable } from "@/anfin-chart/interactions";
import type { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";
import { asHoverable } from "@/anfin-chart/mixins/hoverable";
import type { ToolAlertHook } from "@/anfin-chart/tools/alert-hook";
import type { StringOption } from "@/anfin-chart/options/option";

export abstract class Drawable extends asHoverable(Object) {

  public hook: ToolAlertHook | null = null;
  public hitTolerance: number | null = null;
  protected isVisible = true;
  private isHitIgnored = false;
  private isBlurred = false;

  protected constructor(public readonly tool: ChartTool) {
    super();
    tool.addDrawable(this);
  }

  protected get subChart() {
    return this.tool.getSubChart();
  }

  protected get chart() {
    return this.subChart.chart;
  }

  public override onStartHover() {
    super.onStartHover();
    this.tool.onHoverChange();
  }

  public override onStopHover() {
    super.onStopHover();
    this.tool.onHoverChange();
  }

  public getIsVisible() {
    return this.isVisible;
  }

  public setIsVisible(isVisible: boolean) {
    this.isVisible = isVisible;
  }

  public ignoreHit() {
    this.isHitIgnored = true;
  }

  public isHit(point: Point) {
    if (this.isHitIgnored) {
      return false;
    }
    const drawingAreaPosition = this.subChart.drawingArea.getPosition();
    return isWithin(drawingAreaPosition, point) && this.isHitInternal(point);
  }

  public getPosition(point: ChartToolPoint) {
    return this.tool.getPosition(point);
  }

  public getIsBlurred() {
    return this.isBlurred;
  }

  public setIsBlurred(isBlurred: boolean) {
    this.isBlurred = isBlurred;
  }

  protected getHitTolerance() {
    if (this.hitTolerance != null) {
      return this.hitTolerance;
    }
    if (this.chart.isMobileMode()) {
      return this.chart.styleOptions.hitToleranceMobile.getValue();
    }
    return this.chart.styleOptions.toolHitTolerance.getValue();
  }

  protected abstract isHitInternal(point: Point): boolean;
}

export class LineElement extends Drawable {

  public color: ChartColor = RGBAColor.transparent;

  constructor(tool: ChartTool,
              public readonly start: ChartToolPoint,
              public readonly end: ChartToolPoint,
              public readonly options = new LineOptions()) {
    super(tool);
  }

  public override isHitInternal(point: Point) {
    const start = this.getPosition(this.start);
    const end = this.getPosition(this.end);
    const distance = getLineDistance(start, end, point, true);
    return distance <= this.getHitTolerance();
  }
}

export class LineStrip extends Drawable {

  public color: ChartColor = RGBAColor.transparent;

  constructor(tool: ChartTool,
              public readonly points: ChartToolPoint[],
              public options = new LineOptions()) {
    super(tool);
  }

  public isHitInternal(point: Point) {
    const tolerance = this.getHitTolerance();
    for (let i = 1; i < this.points.length; i++) {
      const lastPoint = this.getPosition(this.points[i - 1]);
      const currentPoint = this.getPosition(this.points[i]);
      const distance = getLineDistance(lastPoint, currentPoint, point, true);
      if (distance <= tolerance) {
        return true;
      }
    }
    return false;
  }
}

export class PolygonElement extends Drawable {

  public areaColor: ChartColor = RGBAColor.transparent;
  public lineColor: ChartColor = RGBAColor.transparent;

  public constructor(tool: ChartTool,
                     public readonly points: ChartToolPoint[],
                     public readonly options = new LineOptions()) {
    super(tool);
  }

  public override isHitInternal(point: Point) {
    const tolerance = this.getHitTolerance();
    let inside = false;
    const x = point.x;
    const y = point.y;
    const count = this.points.length;
    for (let i = 0; i < count; i++) {
      const currentPosition = this.getPosition(this.points[i]);
      const lastIndex = i === 0 ? count - 1 : i - 1;
      const lastPosition = this.getPosition(this.points[lastIndex]);
      const currentX = currentPosition.x;
      const currentY = currentPosition.y;
      const lastX = lastPosition.x;
      const lastY = lastPosition.y;
      const distance = getLineDistance(currentPosition, lastPosition, point, true);
      if (distance <= tolerance) {
        return true;
      }
      if (currentY > y !== lastY > y && x < (lastX - currentX) * (y - currentY) / (lastY - currentY) + currentX) {
        inside = !inside;
      }
    }
    return inside;
  }
}

export class TextBox extends Drawable {

  public color: ChartColor = RGBAColor.transparent;
  public boxColor: ChartColor | null = null;
  public borderColor: ChartColor | null = null;
  public fontSize: number | null = null;
  public isBold = false;
  public isItalic = false;
  public currentPosition = new Rectangle(0, 0, 0, 0);

  private text = "";

  constructor(tool: ChartTool,
              public readonly point: ChartToolPoint,
              public alignment = new TextAlignment()) {
    super(tool);
  }

  public override isHitInternal(point: Point) {
    return isWithin(this.currentPosition, point, this.getHitTolerance());
  }

  public getText() {
    return this.text;
  }

  public setText(text: string) {
    this.text = text;
    this.updatePosition();
  }

  public getFontInfo() {
    const size = this.fontSize ?? this.chart.styleOptions.standardFontSize.getValue();
    const fontInfo = this.chart.getFontInfo(size);
    fontInfo.isBold = this.isBold;
    fontInfo.isItalic = this.isItalic;
    return fontInfo;
  }

  public updatePosition() {
    const drawer = this.chart.toolLayer.drawer;
    const fontInfo = this.getFontInfo();
    const text = this.getText();
    const textSize = drawer.measureText(text, fontInfo);
    const paddingHorizontal = this.chart.styleOptions.textBoxPaddingHorizontal.getValue();
    const paddingVertical = this.chart.styleOptions.textBoxPaddingVertical.getValue();
    const paddedSize = textSize.withPadding(paddingHorizontal, paddingVertical);
    const position = this.getPosition(this.point);
    this.currentPosition = drawer.getAlignedPosition(position, paddedSize, this.alignment).asRounded();
  }
}

export class EditableTextBox extends TextBox implements DoubleClickable {

  public isEditMode = false;
  private inputOverlay: HTMLInputElement | null = null;
  private editedText: string | null = null;

  constructor(tool: ChartTool,
              point: ChartToolPoint,
              private readonly textOption: StringOption,
              alignment: TextAlignment = new TextAlignment()) {
    super(tool, point, alignment);
  }

  public override updatePosition() {
    super.updatePosition();
    if (this.inputOverlay != null) {
      this.inputOverlay.style.top = this.adjustCssSize(this.currentPosition.yStart) + "px";
      this.inputOverlay.style.left = this.adjustCssSize(this.currentPosition.xStart) + "px";
      this.inputOverlay.style.width = this.adjustCssSize(this.currentPosition.width) + 1 + "px";
      this.inputOverlay.style.height = this.adjustCssSize(this.currentPosition.height) + "px";
    }
  }

  public override getText() {
    return this.editedText ?? super.getText();
  }

  public onDoubleClick() {
    this.isEditMode = true;
    this.editedText = this.getText();
    const inputOverlay = document.createElement("input");
    this.inputOverlay = inputOverlay;
    inputOverlay.addEventListener("input", () => {
      this.onChange(inputOverlay.value);
    });
    inputOverlay.addEventListener("blur", () => {
      this.onEndEdit();
    });
    inputOverlay.value = this.getText();
    this.setInputStyle();
    this.chart.container.appendChild(inputOverlay);
    this.setIsVisible(false);
    inputOverlay.focus();
    inputOverlay.select();
    this.tool.updateDrawables();
  }

  private adjustCssSize(size: number) {
    return size / window.devicePixelRatio;
  }

  private onChange(text: string) {
    this.editedText = text;
    this.tool.updatePosition();
  }

  private setInputStyle() {
    if (this.inputOverlay == null) {
      return;
    }
    this.inputOverlay.style.position = "absolute";
    this.inputOverlay.style.background = "transparent";
    const color = convertToRgbaCss(this.color as RGBAColor);
    this.inputOverlay.style.color = color;
    this.inputOverlay.style.border = "1px solid " + color;
    const styleOptions = this.chart.styleOptions;
    const borderRadius = this.adjustCssSize(styleOptions.textBoxBorderRadius.getValue());
    this.inputOverlay.style.borderRadius = borderRadius + "px";
    const paddingHorizontal = this.adjustCssSize(styleOptions.textBoxPaddingHorizontal.getValue());
    const paddingVertical = this.adjustCssSize(styleOptions.textBoxPaddingVertical.getValue());
    this.inputOverlay.style.padding = paddingVertical + "px " + paddingHorizontal + "px";
    const fontInfo = this.getFontInfo();
    fontInfo.size = this.adjustCssSize(fontInfo.size);
    this.inputOverlay.style.font = fontInfo.font;
    this.inputOverlay.style.textAlign = "center";
  }

  private onEndEdit() {
    if (this.editedText != null) {
      this.textOption.setValue(this.editedText);
    }
    this.isEditMode = false;
    this.editedText = null;
    this.setIsVisible(true);
    if (this.inputOverlay != null) {
      this.chart.container.removeChild(this.inputOverlay);
      this.inputOverlay = null;
    }
    this.tool.updateDrawables();
  }
}

export class CircleElement extends Drawable {

  public color: ChartColor = RGBAColor.transparent;

  constructor(tool: ChartTool,
              public readonly center: ChartToolPoint,
              public readonly radius: number) {
    super(tool);
  }

  public override isHitInternal(point: Point) {
    const centerPosition = this.getPosition(this.center);
    return getDistance(centerPosition, point) - this.radius <= this.getHitTolerance();
  }
}

export class EllipseElement extends Drawable {

  public lineColor: ChartColor = RGBAColor.transparent;
  public areaColor: ChartColor = RGBAColor.transparent;

  constructor(tool: ChartTool,
              public readonly center: ChartToolPoint,
              public readonly mainAxis: ChartToolPoint,
              public readonly offAxis: ChartToolPoint,
              public readonly options = new LineOptions()) {
    super(tool);
  }

  public isHitInternal(point: Point) {
    const center = this.getPosition(this.center);
    const main = this.getPosition(this.mainAxis);
    const off = this.getPosition(this.offAxis);
    const mainDistance = getDistance(center, main);
    const offDistance = getLineDistance(center, main, off);
    const mainAngle = getAngle(center, main);
    const pointAngle = getAngle(center, point) - mainAngle;
    const transformedX = Math.cos(pointAngle);
    const transformedY = Math.sin(pointAngle) * mainDistance / offDistance;
    const transformedAngle = Math.atan2(transformedY, transformedX);
    const edgeX = mainDistance * Math.cos(transformedAngle);
    const edgeY = offDistance * Math.sin(transformedAngle);
    const edgeDistance = Math.sqrt(edgeX ** 2 + edgeY ** 2);
    const pointDistance = getDistance(center, point);
    return pointDistance <= edgeDistance + this.getHitTolerance();
  }
}

export class IconElement extends Drawable {

  public color: ChartColor = RGBAColor.transparent;
  public size = 0;

  constructor(tool: ChartTool,
              public readonly center: ChartToolPoint,
              public readonly iconName: string,
              public alignment: TextAlignment = new TextAlignment()) {
    super(tool);
  }

  public isHitInternal(point: Point) {
    const position = this.getPosition(this.center);
    const distance = getDistance(position, point);
    return distance <= this.size;
  }
}

import { getColorByCSSDefine } from "@/anfin-chart/utils";

export enum SymbolCategory {
  None = 0,
  Cat1 = 1,
  Cat2 = 2,
  Cat3 = 3,
  Cat4 = 4,
  Cat5 = 5,
  Cat6 = 6
}

export interface SymbolCategoryResponse {
  category: number;
  symbols: string[];
}

const categoryColors = [
  "--system-red", "--system-orange", "--system-yellow", "--system-green", "--system-blue", "--system-purple"
];

export function getCategoryColor(category: SymbolCategory) {
  return getColorByCSSDefine(categoryColors[category - 1]);
}

import { defineStore } from "pinia";

export const generalStore = defineStore({
  id: "general",

  state() {
    return {
      isDebugLoggingEnabled: false
    };
  },

  actions: {
    logDebug(message: string) {
      if (this.isDebugLoggingEnabled) {
        console.log(message);
      }
    },

    logWarn(message: string) {
      if (this.isDebugLoggingEnabled) {
        console.warn(message);
      }
    }
  }
});

<template>
  <LandingPageWrapper>
    <LandingPageSection class="py-md-5 py-4">
      <div class="landing-header">
        Widerrufsrecht
      </div>
      <div class="font-bold mt-4">
        Widerrufsbelehrung
      </div>
      <div class="mt-2">
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
      </div>
      <div class="mt-2">
        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.
      </div>
      <div class="mt-2">
        Falls Sie vor dem Abschluss des Kaufvertrages nicht ausdrücklich der Ausführung des Vertrages vor Ablauf der Widerrufsfrist zugestimmt haben und davon Kenntnis genommen haben, dass Ihr Widerrufsrecht mit Beginn der Ausführung des Vertrags erlischt*, gelten die nachfolgenden Widerrufsbelehrungen:
      </div>
      <div class="mt-4">
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
      </div>
      <div class="font-bold mt-3">
        <div>Formationstrader GmbH, vertreten durch Dr. Hamed Esnaashari</div>
        <div>Willy-Brandt-Allee 31d, D-23554 Lübeck</div>
        <div>Telefon: 0451-58340830</div>
        <div>E-Mail: kontakt@formationstrader.de</div>
      </div>
      <div class="mt-3">
        mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren
        Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte
        Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
      </div>
      <div class="mt-2">
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
        Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
      </div>
      <div class="font-bold mt-4">
        Folgen des Widerrufs
      </div>
      <div class="mt-2">
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
        einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie
        eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
        unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist.
      </div>
      <div class="mt-2">
        Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion
        eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall
        werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
      </div>
      <div class="font-bold mt-5">
        Musterwiderrufsformular
      </div>
      <div class="mt-3">
        <div>
          Hiermit widerrufe(n) ich/wir*) den von mir/uns*) abgeschlossenen Vertrag über den Kauf der folgenden Waren:
        </div>
        <div class="mt-1">
          ....................................................................... (Titel der Ratgeber, Anzahl, ggf. Bestellnr. und Preis)
        </div>
        <div class="mt-1">
          Bestellt am ................................. erhalten am ............................
        </div>
      </div>
      <div class="mt-4">
        <div>Name des / der Verbraucher(s)</div>
        <div class="mt-1">
          Anschrift des / der Verbraucher(s)
        </div>
        <div class="mt-1">
          Datum
        </div>
        <div class="mt-1">
          Unterschrift (nur bei Mitteilung auf Papier)
        </div>
        <div class="mt-1">
          *) Unzutreffendes streichen
        </div>
      </div>
      <div class="mt-4">
        <div>
          *Bitte bedenken Sie: Bei Dienstleistungen, die eine solche Bestätigung vor Abschluss des Kaufvertrags
          erforderlich machen, haben Sie direkt im Anschluss an Ihren Kauf Zugang zu einem Teil unseres
          Premium-Bereichs. Dort finden Sie mittel- und langfristige Strategien in Videoformat zu Assets des
          gebuchten Pakets.
        </div>
        <div>
          Somit haben Sie direkt Nach Kaufabschluss die Möglichkeit, Einen Teil unserer Inhalte bereits zu nutzen.
        </div>
      </div>
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";

export default defineComponent({
  name: "CancellationPolicyPage",

  components: { LandingPageSection, LandingPageWrapper },

  expose: []
});
</script>

<style scoped>
</style>
import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { LineStrip } from "@/anfin-chart/drawable";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class LineStripTool extends UserToolDefinition {

  public static readonly type = "lstrip";

  private readonly color = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(0, 116, 178));

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(LineStripTool.type, instrument, timeframe, 2, null);
  }

  public override createDrawables(tool: UserTool) {
    const lineStrip = new LineStrip(tool, this.fixedPoints);
    tool.onUpdate(() => {
      lineStrip.color = this.color.getValue();
      lineStrip.options.width = tool.getLineWidth(true);
    });
    for (const point of this.fixedPoints) {
      new FixedSelectablePoint(tool, point);
    }
  }

  public override updatePosition(tool: UserTool) {
    // do nothing
  }
}

<template>
  <SettingsWindowWrapper v-show="mode !== SettingsWindowMode.None" :mode="mode" :allowHide="allowHide">
    <template #caption>
      <WatchlistSelector v-if="mode === SettingsWindowMode.WatchlistOverview" />
    </template>
    <template #content>
      <ChartSettings v-if="mode === SettingsWindowMode.Chart" class="settings-content-padded" />
      <AccountSettings v-if="mode === SettingsWindowMode.Account" class="settings-content-padded" />
      <AnalysisSettings v-if="mode === SettingsWindowMode.Analysis" class="settings-content-padded" />
      <ShortcutSettings v-if="mode === SettingsWindowMode.Shortcut" class="settings-content-padded" />
      <WatchlistOverview v-if="mode === SettingsWindowMode.WatchlistOverview" />
      <AlertOverview v-if="mode === SettingsWindowMode.AlertOverview" />
      <DebuggerInfo v-if="mode === SettingsWindowMode.Debugger" class="settings-content-padded" />
      <ChatWindow v-show="isChatMode" :isShown="isChatMode" />
    </template>
  </SettingsWindowWrapper>
</template>

<script lang="ts">
import { InputUtilsMixin } from "@/mixins/input-utils";
import { defineComponent } from "vue";
import ChartSettings from "@/views/settings/ChartSettings.vue";
import AnalysisSettings from "@/views/settings/AnalysisSettings.vue";
import DebuggerInfo from "@/views/components/DebuggerInfo.vue";
import AlertOverview from "@/views/alerts/AlertOverview.vue";
import WatchlistOverview from "@/views/watchlist/WatchlistOverview.vue";
import SettingsWindowWrapper from "@/views/settings/SettingsWindowWrapper.vue";
import AccountSettings from "@/views/settings/AccountSettings.vue";
import ShortcutSettings from "@/views/settings/ShortcutSettings.vue";
import { storeToRefs } from "pinia";
import { SettingsWindowMode, uiStateStore } from "@/stores/ui-state-store";
import WatchlistSelector from "@/views/watchlist/WatchlistSelector.vue";
import ChatWindow from "@/views/chat/ChatWindow.vue";

export default defineComponent({
  name: "SettingsWindow",

  components: {
    ChatWindow, WatchlistSelector, ShortcutSettings, AccountSettings, SettingsWindowWrapper, WatchlistOverview,
    AlertOverview, DebuggerInfo, AnalysisSettings, ChartSettings
  },

  mixins: [InputUtilsMixin],

  props: {
    allowHide: {
      type: Boolean
    }
  },

  expose: [],

  data() {
    const { settingsWindowMode } = storeToRefs(uiStateStore());
    return {
      mode: settingsWindowMode as unknown as SettingsWindowMode,
      SettingsWindowMode
    };
  },

  computed: {
    isChatMode() {
      return this.mode === SettingsWindowMode.Chat;
    }
  }
});
</script>

<style scoped>
.settings-content-padded {
  padding: 18px 32px;
}
</style>

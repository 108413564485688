<template>
  <RouterLink to="/agb" class="link" target="_blank" rel="noopener noreferrer">
    {{ translationStore.getTranslation("terms_and_conditions") }}
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "AGBLink",

  components: { RouterLink },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  }
});
</script>

<style scoped>

</style>
import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { PolygonElement } from "@/anfin-chart/drawable";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { OptionName } from "@/anfin-chart/options/option-manager";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import type { UserTool } from "@/anfin-chart/tools/user-tool";

export class TriangleTool extends UserToolDefinition {

  public static readonly type = "triangle";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(18, 173, 196));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(69, 103, 138, 0.25));

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(TriangleTool.type, instrument, timeframe, 3, 3);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
    tool.withAtLeastPoints(3, () => this.createThird(tool));
  }

  public override updatePosition(tool: UserTool) {
    // do nothing
  }

  private createFirst(tool: UserTool) {
    const polygon = new PolygonElement(tool, this.fixedPoints);
    tool.onUpdate(() => {
      polygon.lineColor = this.lineColor.getValue();
      polygon.areaColor = this.areaColor.getValue();
      polygon.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[1]);
  }

  private createThird(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[2]);
  }
}

<template>
  <LandingPageWrapper>
    <LandingPageSection class="py-md-5 py-4">
      <div class="landing-header">
        {{ translationStore.getTranslation("privacy_policy") }}
      </div>
      <div class="mt-4">
        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von
        personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der
        mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B.
        unser Social Media Profile auf. (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick
        auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen
        wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
      </div>
      <div class="font-bold mt-4">
        Verantwortlicher
      </div>
      <div class="mt-2">
        <div>Formationstrader GmbH</div>
        <div>Willy-Brandt-Allee 31 d</div>
        <div>23554 Lübeck</div>
        <div>Deutschland</div>
      </div>
      <div class="mt-4">
        <div>Vertreten durch:</div>
        <div>Geschäftsführer: Dr. Hamed Esnaashari</div>
        <div>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: Dr. Hamed Esnaashari</div>
      </div>
      <div class="mt-4">
        <ImprintLink target="_blank" rel="noopener noreferrer">
          Link zum Impressum
        </ImprintLink>
      </div>
      <div class="privacy-policy-caption mt-4">
        Arten der verarbeiteten Daten:
      </div>
      <ul class="mt-2">
        <li>Bestandsdaten (z.B., Namen, Adressen)</li>
        <li>Kontaktdaten (z.B., E-Mail, Telefonnummern)</li>
        <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos)</li>
        <li>Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten)</li>
        <li>Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen)</li>
      </ul>
      <div class="privacy-policy-caption mt-4">
        Kategorien betroffener Personen
      </div>
      <div class="mt-2">
        Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen
        zusammenfassend auch als „Nutzer“).
      </div>
      <div class="font-bold mt-4">
        Zweck der Verarbeitung
      </div>
      <ul class="mt-2">
        <li>Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte</li>
        <li>Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern</li>
        <li>Sicherheitsmaßnahmen</li>
        <li>Reichweitenmessung/Marketing</li>
      </ul>
      <div class="font-bold mt-4">
        Verwendete Begrifflichkeiten
      </div>
      <div class="mt-2">
        „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder
        identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar
        wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
        zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
        (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die
        Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
        oder sozialen Identität dieser natürlichen Person sind.
      </div>
      <div class="mt-2">
        „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder
        jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und
        umfasst praktisch jeden Umgang mit Daten.
      </div>
      <div class="mt-2">
        Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere
        Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten entscheidet, bezeichnet.
      </div>
      <div class="privacy-policy-caption mt-4">
        Maßgebliche Rechtsgrundlagen
      </div>
      <div class="mt-2">
        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit.
        Sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
        Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die
        Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher
        Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die
        Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die
        Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1
        lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen
        natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
        lit. d DSGVO als Rechtsgrundlage.
      </div>
      <div class="font-bold mt-4">
        Zusammenarbeit mit Auftragsverarbeitern und Dritten
      </div>
      <div class="mt-2">
        Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen
        (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff
        auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn
        eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO
        zur Vertragserfüllung erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies
        vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten,
        Webhostern, etc.).
      </div>
      <div class="mt-2">
        Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“
        beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.
      </div>
      <div class="font-bold mt-4">
        Übermittlungen in Drittländer
      </div>
      <div class="mt-2">
        Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen
        Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
        Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung
        unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen
        Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher
        oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim
        Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung
        erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines
        der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder Beachtung
        offiziell anerkannter spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).
      </div>
      <div class="font-bold mt-4">
        Rechte der betroffenen Personen
      </div>
      <div class="mt-2">
        Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden
        und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend
        Art. 15 DSGVO.
      </div>
      <div class="mt-2">
        Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder
        die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
      </div>
      <div class="mt-2">
        Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich
        gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung
        der Daten zu verlangen.
      </div>
      <div class="mt-2">
        Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben
        nach Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
      </div>
      <div class="mt-2">
        Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
        einzureichen.
      </div>
      <div class="font-bold mt-4">
        Widerrufsrecht
      </div>
      <div class="mt-2">
        Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen
      </div>
      <div class="font-bold mt-4">
        Widerspruchsrecht
      </div>
      <div class="mt-2">
        Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO
        jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
        Direktwerbung erfolgen.
      </div>
      <div class="font-bold mt-4">
        Cookies und Widerspruchsrecht bei Direktwerbung
      </div>
      <div class="mt-2">
        Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb
        der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben
        zu einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch
        innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder
        „transiente Cookies“, werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot
        verlässt und seinen Browser schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in
        einem Onlineshop oder ein Login-Staus gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies
        bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status
        gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen
        Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder Marketingzwecke
        verwendet werden. Als „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem
        Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen
        Cookies sind spricht man von „First-Party Cookies“).
      </div>
      <div class="mt-2">
        Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer
        Datenschutzerklärung auf.
      </div>
      <div class="mt-2">
        Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten
        die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies
        können in den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
        Funktionseinschränkungen dieses Onlineangebotes führen.
      </div>
      <div class="mt-2">
        Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann
        bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite
        <a href="http://www.aboutads.info/choices" class="link" target="_blank" rel="noopener noreferrer">http://www.aboutads.info/choices</a>
        oder die EU-Seite
        <a href="http://www.youronlinechoices.com" class="link" target="_blank" rel="noopener noreferrer">http://www.youronlinechoices.com</a>
        erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen
        des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses
        Onlineangebotes genutzt werden können.
      </div>
      <div class="privacy-policy-caption mt-4">
        Löschung von Daten
      </div>
      <div class="mt-2">
        Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer
        Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben,
        werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr
        erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die
        Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind,
        wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke
        verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden
        müssen.
      </div>
      <div class="mt-2">
        Nach gesetzlichen Vorgaben in Deutschland erfolgt die Aufbewahrung insbesondere für 6 Jahre gemäß
        § 257 Abs. 1 HGB (Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe,
        Buchungsbelege, etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO (Bücher, Aufzeichnungen, Lageberichte,
        Buchungsbelege, Handels- und Geschäftsbriefe, Für Besteuerung relevante Unterlagen, etc.).
      </div>
      <div class="mt-2">
        Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7 J gemäß
        § 132 Abs. 1 BAO (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere,
        Aufstellung der Einnahmen und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit Grundstücken und
        für 10 Jahre bei Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen, Telekommunikations-,
        Rundfunk- und Fernsehleistungen, die an Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und
        für die der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
      </div>
      <div class="font-bold mt-4">
        Geschäftsbezogene Verarbeitung
      </div>
      <div class="mt-2">
        Zusätzlich verarbeiten wir
      </div>
      <ul class="mt-1 mb-0">
        <li>Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie)</li>
        <li>Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)</li>
      </ul>
      <div class="mt-1">
        von unseren Kunden, Interessenten und Geschäftspartner zwecks Erbringung vertraglicher Leistungen, Service
        und Kundenpflege, Marketing, Werbung und Marktforschung.
      </div>
      <div class="font-bold mt-4">
        Hosting
      </div>
      <div class="mt-2">
        Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden
        Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
        Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des
        Betriebs dieses Onlineangebotes einsetzen.
      </div>
      <div class="mt-2">
        Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten,
        Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern
        dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
        Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO
        (Abschluss Auftragsverarbeitungsvertrag).
      </div>
      <div class="font-bold mt-4">
        Erhebung von Zugriffsdaten und Logfiles
      </div>
      <div class="mt-2">
        Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne des
        Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
        (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum
        und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
        das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende
        Provider.
      </div>
      <div class="mt-2">
        Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder
        Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren
        weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
        Vorfalls von der Löschung ausgenommen.
      </div>
      <div class="font-bold mt-4">
        Bestellabwicklung im Onlineshop und Kundenkonto
      </div>
      <div class="mt-2">
        Wir verarbeiten die Daten unserer Kunden im Rahmen der Bestellvorgänge in unserem Onlineshop, um ihnen die
        Auswahl und die Bestellung der gewählten Produkte und Leistungen, sowie deren Bezahlung und Zustellung,
        bzw. Ausführung zu ermöglichen.
      </div>
      <div class="mt-2">
        Zu den verarbeiteten Daten gehören Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten und zu
        den betroffenen Personen unsere Kunden, Interessenten und sonstige Geschäftspartner. Die Verarbeitung
        erfolgt zum Zweck der Erbringung von Vertragsleistungen im Rahmen des Betriebs eines Onlineshops,
        Abrechnung, Auslieferung und der Kundenservices. Hierbei setzen wir Session Cookies für die Speicherung
        des Warenkorb-Inhalts und permanente Cookies für die Speicherung des Login-Status ein.
      </div>
      <div class="mt-2">
        Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b (Durchführung Bestellvorgänge) und c
        (Gesetzlich erforderliche Archivierung) DSGVO. Dabei sind die als erforderlich gekennzeichneten Angaben
        zur Begründung und Erfüllung des Vertrages erforderlich. Die Daten offenbaren wir gegenüber Dritten nur
        im Rahmen der Auslieferung, Zahlung oder im Rahmen der gesetzlichen Erlaubnisse und Pflichten gegenüber
        Rechtsberatern und Behörden. Die Daten werden in Drittländern nur dann verarbeitet, wenn dies zur
        Vertragserfüllung erforderlich ist (z.B. auf Kundenwunsch bei Auslieferung oder Zahlung).
      </div>
      <div class="mt-2">
        Nutzer können optional ein Nutzerkonto anlegen, indem sie insbesondere ihre Bestellungen einsehen können.
        Im Rahmen der Registrierung, werden die erforderlichen Pflichtangaben den Nutzern mitgeteilt. Die
        Nutzerkonten sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden. Wenn Nutzer ihr
        Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto gelöscht, vorbehaltlich
        deren Aufbewahrung ist aus handels- oder steuerrechtlichen Gründen entspr. Art. 6 Abs. 1 lit. c DSGVO
        notwendig. Angaben im Kundenkonto verbleiben bis zu dessen Löschung mit anschließender Archivierung
        im Fall einer rechtlichen Verpflichtung. Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung
        vor dem Vertragsende zu sichern.
      </div>
      <div class="mt-2">
        Im Rahmen der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme unserer Onlinedienste,
        speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt
        auf Grundlage unserer berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und sonstiger
        unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur
        Verfolgung unserer Ansprüche erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung gem.
        Art. 6 Abs. 1 lit. c DSGVO.
      </div>
      <div class="mt-2">
        Die Löschung erfolgt nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, die
        Erforderlichkeit der Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen
        Archivierungspflichten erfolgt die Löschung nach deren Ablauf (Ende handelsrechtlicher (6 Jahre) und
        steuerrechtlicher (10 Jahre) Aufbewahrungspflicht).
      </div>
      <div class="font-bold mt-4">
        Erbringung vertraglicher Leistungen
      </div>
      <div class="mt-2">
        Wir verarbeiten Bestandsdaten (z.B., Namen und Adressen sowie Kontaktdaten von Nutzern), Vertragsdaten
        (z.B., in Anspruch genommene Leistungen, Namen von Kontaktpersonen, Zahlungsinformationen) zwecks
        Erfüllung unserer vertraglichen Verpflichtungen und Serviceleistungen gem. Art. 6 Abs. 1 lit b. DSGVO.
        Die in Onlineformularen als verpflichtend gekennzeichneten Eingaben, sind für den Vertragsschluss
        erforderlich.
      </div>
      <div class="mt-2">
        Im Rahmen der Inanspruchnahme unserer Onlinedienste, speichern wir die IP-Adresse und den Zeitpunkt der
        jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als
        auch der Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten
        an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche erforderlich oder
        es besteht hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO.
      </div>
      <div class="mt-2">
        Wir verarbeiten Nutzungsdaten (z.B., die besuchten Webseiten unseres Onlineangebotes, Interesse an unseren
        Produkten) und Inhaltsdaten (z.B., Eingaben im Kontaktformular oder Nutzerprofil) für Werbezwecke in einem
        Nutzerprofil, um den Nutzer z.B. Produkthinweise ausgehend von ihren bisher in Anspruch genommenen
        Leistungen einzublenden.
      </div>
      <div class="mt-2">
        Die Löschung der Daten erfolgt nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten,
        die Erforderlichkeit der Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen
        Archivierungspflichten erfolgt die Löschung nach deren Ablauf. Angaben im etwaigen Kundenkonto verbleiben
        bis zu dessen Löschung.
      </div>
      <div class="font-bold mt-4">
        Registrierfunktion
      </div>
      <div class="mt-2">
        Nutzer können optional ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden die erforderlichen
        Pflichtangaben den Nutzern mitgeteilt. Die im Rahmen der Registrierung eingegebenen Daten werden für die
        Zwecke der Nutzung des Angebotes verwendet. Die Nutzer können über angebots- oder registrierungsrelevante
        Informationen, wie Änderungen des Angebotsumfangs oder technische Umstände per E-Mail informiert werden.
        Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto gelöscht,
        vorbehaltlich deren Aufbewahrung ist aus handels- oder steuerrechtlichen Gründen entspr.
        Art. 6 Abs. 1 lit. c DSGVO notwendig. Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem
        Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer gespeicherten Daten
        des Nutzers unwiederbringlich zu löschen.
      </div>
      <div class="mt-2">
        Im Rahmen der Inanspruchnahme unserer Regsitrierungs- und Anmeldefunktionen sowie der Nutzung der
        Nutzerkontos, speichern wird die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
        Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als auch der Nutzer an Schutz
        vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
        grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht
        hierzu besteht eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO. Die IP-Adressen werden
        spätestens nach 7 Tagen anonymisiert oder gelöscht.
      </div>
      <div class="font-bold mt-4">
        Kontaktaufnahme
      </div>
      <div class="mt-2">
        Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien)
        werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem.
        Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem
        Customer-Relationship-Management System ("CRM System") oder vergleichbarer Anfragenorganisation
        gespeichert werden.
      </div>
      <div class="mt-2">
        Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit
        alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.
      </div>
      <div class="font-bold mt-4">
        Google Analytics
      </div>
      <div class="mt-2">
        Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
        wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics,
        einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie
        erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen
        Server von Google in den USA übertragen und dort gespeichert.
      </div>
      <div class="mt-2">
        Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
        europäische Datenschutzrecht einzuhalten
        (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" class="link" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>).
      </div>
      <div class="mt-2">
        Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch
        die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen
        und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen,
        uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer
        erstellt werden.
      </div>
      <div class="mt-2">
        Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der
        Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
        des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle
        IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
      </div>
      <div class="mt-2">
        Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google
        zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung
        ihrer Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie
        erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung
        dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin
        herunterladen und installieren:
        <a href="http://tools.google.com/dlpage/gaoptout?hl=de" class="link" target="_blank" rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
      </div>
      <div class="mt-2">
        Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten erfahren
        Sie auf den Webseiten von Google:
        <a href="https://www.google.com/intl/de/policies/privacy/partners" class="link" target="_blank" rel="noopener noreferrer">https://www.google.com/intl/de/policies/privacy/partners</a>
        („Datennutzung durch Google bei Ihrer Nutzung von Websites oder Apps unserer Partner“),
        <a href="http://www.google.com/policies/technologies/ads" class="link" target="_blank" rel="noopener noreferrer">http://www.google.com/policies/technologies/ads</a>
        („Datennutzung zu Werbezwecken“),
        <a href="http://www.google.de/settings/ads" class="link" target="_blank" rel="noopener noreferrer">http://www.google.de/settings/ads</a>
        („Informationen verwalten, die Google verwendet, um Ihnen Werbung einzublenden“).
      </div>
      <div class="font-bold mt-4">
        Einbindung von Diensten und Inhalten Dritter
      </div>
      <div class="mt-2">
        Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse
        an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des
        Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und
        Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).
      </div>
      <div class="mt-2">
        Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da
        sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für
        die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren
        jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können
        ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische
        oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf
        den Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf
        dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und
        Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
        enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.
      </div>
      <div class="font-bold mt-4">
        Youtube
      </div>
      <div class="mt-2">
        Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC, 1600 Amphitheatre Parkway,
        Mountain View, CA 94043, USA, ein. Datenschutzerklärung:
        <a href="https://www.google.com/policies/privacy" class="link" target="_blank" rel="noopener noreferrer">https://www.google.com/policies/privacy</a>,
        Opt-Out:
        <a href="https://adssettings.google.com/authenticated" class="link" target="_blank" rel="noopener noreferrer">https://adssettings.google.com/authenticated</a>.
      </div>
      <div class="font-bold mt-4">
        Twitter
      </div>
      <div class="mt-2">
        Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des Dienstes Twitter eingebunden, angeboten
        durch die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Hierzu können z.B.
        Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Ihr Gefallen betreffend
        die Inhalte kundtun, den Verfassern der Inhalte oder unsere Beiträge abonnieren können. Sofern die Nutzer
        Mitglieder der Plattform Twitter sind, kann Twitter den Aufruf der o.g. Inhalte und Funktionen den dortigen
        Profilen der Nutzer zuordnen. Twitter ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
        hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten
        (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active" class="link" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active</a>).
        Datenschutzerklärung:
        <a href="https://twitter.com/de/privacy" class="link" target="_blank" rel="noopener noreferrer">https://twitter.com/de/privacy</a>,
        Opt-Out:
        <a href="https://twitter.com/personalization" class="link" target="_blank" rel="noopener noreferrer">https://twitter.com/personalization</a>.
      </div>
      <div class="privacy-policy-caption mt-4">
        Einbindung des Trusted Shops Trustbadge
      </div>
      <div class="mt-2">
        Zur Anzeige unseres Trusted Shops Gütesiegels und der gegebenenfalls gesammelten Bewertungen sowie zum
        Angebot der Trusted Shops Produkte für Käufer nach einer Bestellung ist auf dieser Webseite das Trusted
        Shops Trustbadge eingebunden.
      </div>
      <div class="mt-2">
        Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen
        an einer optimalen Vermarktung durch Ermöglichung eines sicheren Einkaufs gemäß Art. 6 Abs. 1 S. 1 lit. f
        DSGVO. Das Trustbadge und die damit beworbenen Dienste sind ein Angebot der Trusted Shops GmbH, Subbelrather
        Str. 15C, 50823 Köln. Das Trustbadge wird im Rahmen einer Auftragsverarbeitung durch einen CDN-Anbieter
        (Content-Delivery-Network) zur Verfügung gestellt. Die Trusted Shops GmbH setzt auch Dienstleister aus
        den USA ein. Ein angemessenes Datenschutzniveau ist sichergestellt. Weitere Informationen zum Datenschutz
        der Trusted Shops GmbH finden Sie hier:
        <a href="https://www.trustedshops.de/impressum/#datenschutz" class="link" target="_blank" rel="noopener noreferrer">https://www.trustedshops.de/impressum/#datenschutz</a>
      </div>
      <div class="mt-2">
        Bei dem Aufruf des Trustbadge speichert der Webserver automatisch ein sogenanntes Server-Logfile, das auch
        Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider
        (Zugriffsdaten) enthält und den Abruf dokumentiert. Einzelne Zugriffsdaten werden für die Analyse von
        Sicherheitsauffälligkeiten in einer Sicherheitsdatenbank gespeichert. Die Logfiles werden spätestens
        90 Tage nach Erstellung automatisch gelöscht.
      </div>
      <div class="mt-2">
        Weitere personenbezogene Daten werden an die Trusted Shops GmbH übertragen, wenn Sie sich nach Abschluss
        einer Bestellung für die Nutzung von Trusted Shops Produkten entscheiden oder sich bereits für die Nutzung
        registriert haben. Es gilt die zwischen Ihnen und Trusted Shops getroffene vertragliche Vereinbarung.
        Hierfür erfolgt eine automatische Erhebung personenbezogener Daten aus den Bestelldaten. Ob Sie als Käufer
        bereits für eine Produktnutzung registriert ist, wird anhand eines neutralen Parameters, der per
        kryptologischer Einwegfunktion gehashten E-Mail-Adresse, automatisiert überprüft. Die E-Mail Adresse wird
        vor der Übermittlung in diesen für Trusted Shops nicht zu entschlüsselnden Hash-Wert umgerechnet. Nach
        Überprüfung auf einen Match, wird der Parameter automatisch gelöscht.
      </div>
      <div class="mt-2">
        Dies ist für die Erfüllung unserer und Trusted Shops‘ überwiegender berechtigter Interessen an der
        Erbringung des jeweils an die konkrete Bestellung gekoppelten Käuferschutzes und der transaktionellen
        Bewertungsleistungen gem. Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich. Weitere Details, auch zum
        Widerspruch, sind der oben und im Trustbadge verlinkten Trusted Shops Datenschutzerklärung zu entnehmen.
      </div>
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import ImprintLink from "@/views/pages-landing/ImprintLink.vue";

export default defineComponent({
  name: "PrivacyPolicyPage",

  components: { ImprintLink, LandingPageSection, LandingPageWrapper },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  }
});
</script>

<style scoped>
.privacy-policy-caption {
  font-weight: bold;
  font-size: 110%;
}
</style>
import type { ColorOption } from "@/anfin-chart/options/option";
import { Point, Polygon, Vector } from "@/anfin-chart/geometry";
import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import type { PriceAxis } from "@/anfin-chart/area/price-axis";

export class PriceAxisMarker {

  public price: number | null = null;
  public isVisible = true;

  constructor(private readonly priceAxis: PriceAxis,
              private readonly color: ColorOption) {
    priceAxis.addMarker(this);
  }

  public setPrice(price: number | null) {
    this.price = price;
  }

  public draw() {
    if (this.price == null || !this.isVisible) {
      return;
    }
    const chart = this.priceAxis.chart;
    const drawer = this.priceAxis.drawer;
    const position = this.priceAxis.getPosition();
    const fontInfo = chart.getFontInfo();
    const text = this.priceAxis.formatPrice(this.price);
    const textSize = drawer.measureText(text, fontInfo);
    const padding = chart.styleOptions.priceMarkerPadding.getValue();
    const height = textSize.height + padding;
    const color = this.color.getValue();
    const marginLeft = chart.styleOptions.priceAxisPaddingLeft.getValue();
    const x = position.xStart + marginLeft;
    const rectX = x - padding;
    const y = Math.round(this.priceAxis.getY(this.price));
    const yStart = y - height / 2;
    const yEnd = y + height / 2;
    const polygon = new Polygon(
      new Point(rectX, yStart),
      new Point(position.xEnd, yStart),
      new Point(position.xEnd, yEnd),
      new Point(rectX, yEnd),
      new Point(position.xStart, y)
    );
    drawer.drawPolygon(polygon, null, color);
    const alignment = new TextAlignment(new Vector(1, 0));
    const textX = Math.round(x);
    const textY = y + padding;
    const textPosition = new Point(textX, textY);
    const textColor = chart.optionManager.chartColor.priceMarkerText.getValue();
    drawer.printText(textPosition, text, textColor, alignment, fontInfo);
  }
}

<template>
  <LandingPageSection>
    <div class="landing-section-split">
      <div class="contact-form-image">
        <img
          class="w-100"
          src="/src/assets/contact-w960.png"
          alt="Kontakt"
          srcset="/src/assets/contact-w200.png 200w, /src/assets/contact-w300.png 300w, /src/assets/contact-w600.png 600w, /src/assets/contact-w768.png 768w, /src/assets/contact-w960.png 960w"
        />
      </div>
      <div>
        <div class="landing-header text-center">
          Gemeinsam Ziele erreichen
        </div>
        <div class="contact-sub-header mt-3 text-center">
          Ihr Erfolg ist unser Anliegen
        </div>
        <form v-if="state === ContactFormState.Initial" class="contact-form mt-md-4 mt-3" @submit.prevent="sendEmail">
          <input v-model="name" type="text" class="form-control" placeholder="Name" required autocomplete="name" />
          <input v-model="email" type="email" class="form-control mt-2" placeholder="Email" required autocomplete="email" />
          <textarea v-model="text" rows="5" class="form-control mt-2" placeholder="Nachricht" required></textarea>
          <button class="btn btn-primary mt-2 ms-auto" type="submit">ABSENDEN &gt;</button>
        </form>
        <div v-else-if="state === ContactFormState.Sending" class="contact-form-sending mt-3">
          <LoadingSpinner :size="5" />
          <span>Ihre E-Mail wird gesendet...</span>
        </div>
        <div v-else-if="state === ContactFormState.Sent" class="fw-bold mt-4">
          Danke für Ihre E-Mail! Wir werden uns so schnell wie möglich bei Ihnen zurückmelden.
        </div>
        <div v-else-if="state === ContactFormState.Error" class="text-error fw-bold mt-4">
          Ihre E-Mail konnte leider aktuell nicht gesendet werden. Bitte versuchen Sie es später noch einmal oder
          kontaktieren Sie uns auf
          <ContactLink />
        </div>
      </div>
    </div>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import { ContactController } from "@/api/contact-controller";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import ContactLink from "@/views/pages-landing/ContactLink.vue";

export enum ContactFormState {
  Initial = 0,
  Sending = 1,
  Sent = 2,
  Error = 3
}

export default defineComponent({
  name: "ContactFormSection",

  components: { ContactLink, LoadingSpinner, LandingPageSection },

  expose: [],

  data() {
    return {
      name: "",
      email: "",
      text: "",
      state: ContactFormState.Initial as ContactFormState,
      ContactFormState
    };
  },

  methods: {
    async sendEmail() {
      this.state = ContactFormState.Sending;
      const successful = await ContactController.getInstance().sendEmail(this.name, this.email, this.text);
      this.state = successful ? ContactFormState.Sent : ContactFormState.Error;
    }
  }
});
</script>

<style scoped>
.contact-sub-header {
  font-size: 150%;
  font-weight: 500;
  color: var(--background-primary);
}

.contact-form-image {
  border-radius: var(--border-radius-double);
  overflow: hidden;
  max-width: 100%;
  max-height: 500px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form-sending {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
</style>
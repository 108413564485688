<template>
  <LandingPageSection class="section-green">
    <div class="landing-section-split start-now-section">
      <div class="landing-header">
        Warum Warten?
        <br />
        Jetzt starten!
      </div>
      <div>
        Erleben Sie, wie Sie mit AnFin den Grundstein für Ihren Erfolg im Trading legen. Effizient, klar und mit Vorsprung
      </div>
      <div class="start-now-button-container">
        <RouterLink to="/shop" class="btn btn-primary start-now-button">
          JETZT STARTEN &gt;
        </RouterLink>
      </div>
    </div>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "StartNowSection",

  components: { LandingPageSection, RouterLink },

  expose: []
});
</script>

<style scoped>
.start-now-section {
  align-items: center;
}

.start-now-button-container {
  text-align: center;
}

.start-now-button {
  font-size: 120%;
  padding: 10px 25px;
}
</style>
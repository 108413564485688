<template>
  <RouterView />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RouterView } from "vue-router";
import { storeToRefs } from "pinia";
import { chartOptionStore } from "@/stores/chart-option-store";
import type { Theme } from "@/api/models/theme";

export default defineComponent({
  name: "App",

  components: { RouterView },

  expose: [],

  data() {
    const { currentTheme } = storeToRefs(chartOptionStore());
    return {
      currentTheme: currentTheme as unknown as Theme
    };
  },

  watch: {
    currentTheme(theme: Theme, previousTheme: Theme) {
      document.body.classList.remove(previousTheme.className);
      document.body.classList.add(theme.className);
    }
  },

  mounted() {
    document.body.classList.add(this.currentTheme.className);
  }
});
</script>

<style scoped>
</style>

import type { CaptionLine } from "@/anfin-chart/area/caption-line";
import { GradientColor } from "@/anfin-chart/draw/chart-color";
import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { Point, Size, Vector } from "@/anfin-chart/geometry";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Clickable } from "@/anfin-chart/interactions";
import type { ColorOption, DevicePixelOption } from "@/anfin-chart/options/option";
import type { Producer } from "@/anfin-chart/utils";
import { StackItem } from "@/anfin-chart/area/stack-item";
import { combineLatest } from "rxjs";

export abstract class CaptionItem extends StackItem {

  protected constructor(protected readonly captionLine: CaptionLine) {
    super(captionLine.stackPanel);
    captionLine.addItem(this);
    this.subscribeOn(this.captionLine.getPositionObservable(), () => this.resize());
  }
}

export class CaptionText extends CaptionItem {

  private readonly textAlignment = new TextAlignment(new Vector(1, -1));
  private text = "";
  private textProvider: Producer<void, string> | null = null;

  constructor(captionLine: CaptionLine,
              private readonly fontSize: DevicePixelOption,
              private color: ColorOption) {
    super(captionLine);
    const combinedObservable = combineLatest([
      this.fontSize.getValueObservable(),
      this.chart.getPixelRatioObservable()
    ]);
    this.subscribeOn(combinedObservable, () => this.calculateTextSize());
  }

  public setText(text: string) {
    if (text === this.text) {
      return;
    }
    this.text = text;
    this.calculateTextSize();
  }

  public setTextProvider(textProvider: Producer<void, string>) {
    this.textProvider = textProvider;
    this.updateText();
  }

  public setColor(color: ColorOption) {
    this.color = color;
  }

  protected override drawInternal() {
    this.updateText();
    const fontInfo = this.chart.getFontInfo(this.fontSize.getValue());
    const position = this.getPosition();
    const textX = Math.floor(position.xStart);
    const textY = Math.ceil(position.yEnd);
    const textPosition = new Point(textX, textY);
    let color = this.color.getValue();
    if (color instanceof GradientColor) {
      color = color.stops[0].color;
    }
    this.drawer.printText(textPosition, this.text, color, this.textAlignment, fontInfo);
  }

  private updateText() {
    if (this.textProvider == null) {
      return;
    }
    this.setText(this.textProvider());
  }

  private calculateTextSize() {
    const fontInfo = this.chart.getFontInfo(this.fontSize.getValue());
    const textSize = this.drawer.measureText(this.text, fontInfo);
    this.setSize(textSize);
  }
}

export class CaptionButton extends CaptionItem implements Clickable {

  constructor(captionLine: CaptionLine,
              private readonly clickAction: () => void) {
    super(captionLine);
    const sizeOption = this.chart.styleOptions.captionButtonSize;
    const combinedObservable = combineLatest([
      sizeOption.getValueObservable(),
      this.chart.getPixelRatioObservable()
    ]);
    this.subscribeOn(combinedObservable, () => {
      const size = sizeOption.getValue();
      this.setSize(new Size(size, size));
    });
  }

  public onClick() {
    this.clickAction();
  }

  protected override drawInternal() {
    const radius = this.getRadius();
    const center = this.getCenter();
    const color = this.chart.optionManager.chartColor.captionButtonBackground.getValue();
    this.drawer.drawCircle(center, radius, null, color);
  }

  protected getRadius() {
    return this.getSize().height / 2;
  }

  protected getCenter() {
    const position = this.getPosition();
    const xStart = position.xStart;
    const yStart = position.yStart;
    const centerX = xStart + this.getRadius();
    const centerY = yStart + (position.yEnd - yStart) / 2;
    return new Point(centerX, centerY);
  }
}

export class CaptionSettings extends CaptionButton {

  private readonly textAlignment = new TextAlignment();

  protected override drawInternal() {
    super.drawInternal();
    const center = this.getCenter();
    const fontInfo = this.chart.getFontInfo(this.chart.styleOptions.captionButtonFontSize.getValue());
    const color = this.chart.optionManager.chartColor.captionButtonText.getValue();
    this.drawer.printText(center, "S", color, this.textAlignment, fontInfo);
  }
}

export class CaptionDelete extends CaptionButton {

  private readonly textAlignment = new TextAlignment();

  protected override drawInternal() {
    super.drawInternal();
    const center = this.getCenter();
    const fontInfo = this.chart.getFontInfo(this.chart.styleOptions.captionButtonFontSize.getValue());
    const color = this.chart.optionManager.chartColor.captionButtonText.getValue();
    this.drawer.printText(center, "x", color, this.textAlignment, fontInfo);
  }
}

export class CaptionInstrumentIcon extends CaptionItem {

  constructor(captionLine: CaptionLine, private readonly instrument: Instrument) {
    super(captionLine);
  }

  protected override drawInternal() {
    const icon = this.chart.callbacks.getInstrumentIcon(this.instrument);
    let size: number;
    if (icon == null) {
      size = 0;
    } else {
      size = this.chart.styleOptions.captionIconSize.getValue();
      const position = this.getPosition();
      this.drawer.drawSvg(icon, position);
    }
    this.setSize(new Size(size, size));
  }
}

export class CaptionExpand extends CaptionItem {

  private isExpanded = false;

  constructor(captionLine: CaptionLine,
              sizeOption: DevicePixelOption,
              private readonly colorOption: ColorOption) {
    super(captionLine);
    const combinedObservable = combineLatest([
      sizeOption.getValueObservable(),
      this.chart.getPixelRatioObservable()
    ]);
    this.subscribeOn(combinedObservable, () => {
      const size = sizeOption.getValue();
      this.setSize(new Size(size, size));
    });
  }

  public setIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  protected override drawInternal() {
    const iconKey = this.isExpanded ? "CollapseCaption" : "ExpandCaption";
    const icon = this.chart.iconStore.getIcon(iconKey);
    const position = this.getPosition();
    this.drawer.drawSvg(icon, position, this.colorOption.getValue());
  }
}

import { AnalysisToolDefinition, AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";

export class AutoHeadAndShoulders extends AnalysisToolDefinition {

  constructor(id: string,
              instrument: Instrument,
              timeframe: Timeframe,
              public readonly leftShoulderTime: number,
              public readonly headTime: number,
              public readonly rightShoulderTime: number,
              public readonly neckStartTime: number,
              public readonly neckStartPrice: number,
              public readonly neckEndTime: number,
              public readonly neckEndPrice: number) {
    super(id, AnalysisType.HeadAndShoulders, instrument, timeframe, neckStartTime);
  }
}

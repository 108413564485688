import { exportDataToFile, simpleCompare, simpleMapCompare } from "@/anfin-chart/utils";
import type { TickData } from "@/api/messages/tick-update";
import type { WatchlistResponse } from "@/api/messages/watchlist";
import type { SymbolCategoryResponse } from "@/api/models/symbol-category";
import { SymbolCategory } from "@/api/models/symbol-category";
import {
  countVirtualWatchlist,
  Watchlist,
  WatchlistImportInfo,
  WatchlistItem,
  WatchlistItemData,
  WatchlistType
} from "@/api/models/watchlist";
import { SymbolCategoriesController } from "@/api/symbol-categories-controller";
import { WatchlistController } from "@/api/watchlist-controller";
import { instrumentStore } from "@/stores/instrument-store";
import { defineStore } from "pinia";

export const watchlistStore = defineStore({
  id: "watchlist",

  state() {
    setTimeout(() => initializeStore());
    const virtualWatchlists = [];
    const watchlistsMap = new Map<number, Watchlist>();
    for (let i = 1; i <= countVirtualWatchlist; i++) {
      const id = -i;
      const watchlist = new Watchlist(id, "", WatchlistType.Virtual);
      virtualWatchlists.push(watchlist);
      watchlistsMap.set(id, watchlist);
    }
    return {
      controller: WatchlistController.getInstance(),
      categoryController: SymbolCategoriesController.getInstance(),
      watchlistsMap,
      virtualWatchlists,
      itemDataMap: new Map<string, WatchlistItemData>(),
      symbolCategoryMap: new Map<string, number>()
    };
  },

  getters: {
    watchlists(): Watchlist[] {
      return Array.from(this.watchlistsMap.values());
    },

    getWatchlist() {
      return (id: number) => this.watchlistsMap.get(id) ?? null;
    }
  },

  actions: {
    async requestWatchlists() {
      const result = await this.controller.requestWatchlists();
      const watchlists = [];
      for (const watchlistData of result as WatchlistResponse[]) {
        const id = watchlistData.id;
        const type = watchlistData.type;
        const watchlist = new Watchlist(id, watchlistData.name, type);
        watchlists.push(watchlist);
      }
      watchlists.sort((wl1: Watchlist, wl2: Watchlist) => {
        if (wl1.type === wl2.type) {
          return simpleCompare(wl1.name, wl2.name);
        }
        return simpleCompare(wl2.type, wl1.type);
      });
      this.setWatchlists(watchlists);
    },

    async requestWatchlistItems(watchlist: Watchlist) {
      const items = await this.controller.requestWatchlistItems(watchlist);
      items.sort(simpleMapCompare(i => i.sortIndex));
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        this.addItem(watchlist.id, item.symbol, i);
      }
    },

    async requestCategories() {
      const categories = await this.categoryController.requestSymbolCategories();
      this.setCategories(categories);
    },

    getCategoryWatchlist(categoryId: SymbolCategory) {
      return this.getWatchlist(-categoryId);
    },

    setCategories(categories: SymbolCategoryResponse[]) {
      for (const category of categories) {
        for (const symbol of category.symbols) {
          this.setCategory(symbol, category.category, false);
        }
      }
    },

    setCategory(symbol: string, category: SymbolCategory, withSave = true) {
      const oldCategory = this.symbolCategoryMap.get(symbol);
      if (category === oldCategory) {
        return;
      }
      const watchlist = this.getCategoryWatchlist(category);
      if (watchlist != null) {
        this.addItem(watchlist.id, symbol);
      }
      const oldWatchlist = oldCategory == null ? null : this.getCategoryWatchlist(oldCategory);
      if (oldWatchlist != null) {
        this.removeItem(oldWatchlist.id, symbol);
      }
      this.symbolCategoryMap.set(symbol, category);
      const itemData = this.itemDataMap.get(symbol);
      if (itemData != null) {
        itemData.category = category;
      }
      if (withSave) {
        this.categoryController.setCategory(symbol, category);
      }
    },

    setWatchlists(watchlists: Watchlist[]) {
      this.watchlistsMap.clear();
      for (const watchlist of this.virtualWatchlists as Watchlist[]) {
        this.watchlistsMap.set(watchlist.id, watchlist);
      }
      for (const watchlist of watchlists) {
        this.addWatchlist(watchlist);
      }
    },

    async createWatchlist(name: string) {
      const id = await this.controller.createWatchlist(name);
      const watchlist = new Watchlist(id, name, WatchlistType.User);
      this.addWatchlist(watchlist);
      return watchlist;
    },

    async cloneWatchlist(id: number, name: string) {
      const sourceWatchlist = this.getWatchlist(id);
      if (sourceWatchlist == null) {
        return null;
      }
      const newWatchlist = await this.createWatchlist(name);
      for (const item of sourceWatchlist.getItems()) {
        watchlistStore().addItem(newWatchlist.id, item.symbol);
      }
      await this.controller.saveWatchlistItems(newWatchlist.id, newWatchlist.getItems());
      return newWatchlist;
    },

    createWatchlistItems(watchlistId: number, symbols: string[]) {
      const watchlist = this.getWatchlist(watchlistId);
      if (watchlist == null) {
        return;
      }
      const items = [];
      for (const symbol of symbols) {
        const item = this.addItem(watchlistId, symbol);
        if (item != null) {
          items.push(item);
        }
      }
      this.controller.saveWatchlistItems(watchlist.id, watchlist.getItems());
    },

    deleteWatchlistItem(watchlist: Watchlist, symbol: string) {
      if (watchlist.id > 0) {
        this.controller.deleteWatchlistItem(watchlist.id, symbol);
      } else {
        this.setCategory(symbol, SymbolCategory.None);
      }
    },

    clearWatchlist(watchlist: Watchlist) {
      const symbols = watchlist.getItems().map(i => i.symbol);
      for (const symbol of symbols) {
        this.deleteWatchlistItem(watchlist, symbol);
      }
    },

    async deleteWatchlist(watchlistId: number) {
      return await this.controller.deleteWatchlist(watchlistId);
    },

    addWatchlist(watchlist: Watchlist) {
      const matchingWatchlist = this.getWatchlist(watchlist.id);
      if (matchingWatchlist == null) {
        this.watchlistsMap.set(watchlist.id, watchlist);
        this.requestWatchlistItems(watchlist);
      } else {
        matchingWatchlist.name = watchlist.name;
      }
    },

    removeWatchlist(watchlistId: number) {
      const matchingWatchlist = this.getWatchlist(watchlistId);
      if (matchingWatchlist != null) {
        this.watchlistsMap.delete(watchlistId);
      }
    },

    handleUpdateMarketListDetail(data: any) {
      console.error("Not implemented");
    },

    addItem(watchlistId: number, symbol: string, index: number | null = null) {
      const watchlist = this.getWatchlist(watchlistId);
      if (watchlist == null) {
        return null;
      }
      let itemData = this.itemDataMap.get(symbol);
      if (itemData == null) {
        const category = this.symbolCategoryMap.get(symbol) ?? SymbolCategory.None;
        itemData = new WatchlistItemData(category);
        this.itemDataMap.set(symbol, itemData);
      }
      const item = new WatchlistItem(symbol, itemData, index ?? watchlist.getItems().length);
      watchlist.addItem(item);
      return item;
    },

    getCategory(symbol: string) {
      return this.symbolCategoryMap.get(symbol) ?? null;
    },

    removeItem(watchlistId: number, symbol: string) {
      const watchlist = this.getWatchlist(watchlistId);
      watchlist?.removeItem(symbol);
    },

    updatePrice(symbol: string, tickData: TickData) {
      const itemData = this.itemDataMap.get(symbol);
      if (itemData != null) {
        itemData.update(tickData);
      }
    },

    exportToCsv(watchlist: Watchlist) {
      const csvLines = watchlist?.getItems().map(value => value.symbol);
      const csvFile = "symbol\n" + csvLines?.join("\n");
      if (csvFile) {
        const filename = watchlist?.name.replace(" ", "_") + ".csv";
        exportDataToFile(filename, csvFile);
      }
    },

    // TODO: Refactor this function
    parseImportData(watchlistIdToCompare: number, csvData: string): WatchlistImportInfo {
      const watchlist = this.getWatchlist(watchlistIdToCompare);
      const watchlistImportInfo = new WatchlistImportInfo();
      const lines = csvData.split("\n").slice(1);
      for (const line of lines) {
        const cols = line.split(";");
        if (cols.length > 0) {
          let symbol = cols[0].replace("\r", "");
          if (symbol !== "") {
            if (!instrumentStore().symbolExists(symbol)) {
              const exchanges = ["CEUX", "EDGX"];
              for (const exchange of exchanges) {
                if (instrumentStore().symbolExists(symbol + ":" + exchange)) {
                  symbol += ":" + exchange;
                }
              }
            }

            if (instrumentStore().symbolExists(symbol)) {
              const existingSymbols = watchlist?.getItems().filter(value => value.symbol === symbol) ?? [];
              if (existingSymbols.length > 0) {
                watchlistImportInfo.symbolsOkButExists++;
              } else {
                watchlistImportInfo.symbols.push(symbol);
              }
            } else {
              watchlistImportInfo.symbolsNotOk++;
              console.log("import - symbolsNotOk", symbol);
            }
          }
        }
      }
      return watchlistImportInfo;
    }
  }
});

function initializeStore() {
  const store = watchlistStore();
  store.requestWatchlists();
  store.requestCategories();
}

<template>
  <LandingPageWrapper>
    <LandingPageSection class="py-md-5 py-4 section-gray">
      <ProductTable>
        <template #default="slotProps">
          <div class="product-table-caption">
            Produktübersicht
          </div>
          <ProductPeriodSelector
            :modelValue="slotProps.period" class="product-table-period m-4" @update:modelValue="slotProps.setPeriod"
          />
          <ProductTableCardRow :period="slotProps.period" :showJfd="true" />
          <ProductTableFeatureList :productDatas="slotProps.productDatas" />
          <ProductTableCardRow :period="slotProps.period" :showJfd="true" />
          <ProductTableFooter :productDatas="slotProps.productDatas" />
        </template>
      </ProductTable>
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import ProductTable from "@/views/pages-landing/product/ProductTable.vue";
import { TrustedShopMixin } from "@/mixins/trusted-shop";
import ProductTableFooter from "@/views/pages-landing/product/ProductTableFooter.vue";
import ProductTableFeatureList from "@/views/pages-landing/product/ProductTableFeatureList.vue";
import ProductPeriodSelector from "@/views/pages-landing/product/ProductPeriodSelector.vue";
import ProductTableCardRow from "@/views/pages-landing/product/ProductTableCardRow.vue";

export default defineComponent({
  name: "ShopPage",

  components: {
    ProductTableCardRow, ProductPeriodSelector, ProductTableFeatureList, ProductTableFooter, ProductTable,
    LandingPageSection, LandingPageWrapper
  },

  mixins: [TrustedShopMixin],

  expose: []
});
</script>

<style scoped>
.product-table-caption {
  font-size: 250%;
  font-weight: 400;
  justify-self: center;
}

.product-table-caption, .product-table-period {
  grid-column: 2 / 5;
}
</style>
import {
  CaptionLine,
  IndicatorCaptionLine,
  IndicatorExpanderLine,
  InstrumentCaptionLine
} from "@/anfin-chart/area/caption-line";
import { StackAlignment, StackDirection, StackPanel } from "@/anfin-chart/area/stack-panel";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { Rectangle } from "@/anfin-chart/geometry";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { combineLatest } from "rxjs";

export class CaptionArea extends StackPanel {

  private instrumentLines: CaptionLine[] = [];
  private indicatorLines: CaptionLine[] = [];
  private expanderLine: CaptionLine | null = null;

  constructor(layer: ChartLayer,
              public readonly subChart: SubChart) {
    super(layer, StackDirection.Vertical, StackAlignment.Start, layer.chart.styleOptions.captionLineMargin);
    this.setVisibleOnSubChart(subChart);
  }

  public override initializeEvents() {
    this.subscribeOn(this.subChart.drawingArea.getPositionObservable(), () => this.resize());
    const isPrimaryObservable = this.chart.getPrimarySubChartObservable();
    const instrumentDatasObservable = this.chart.getInstrumentDatasObservable();
    const isIndicatorsExpandedObservable = this.subChart.getIsIndicatorsExpandedObservable();
    const indicatorDatasObservable = this.subChart.getIndicatorDatasObservable();
    const instrumentsObservable = combineLatest([isPrimaryObservable, instrumentDatasObservable]);
    const indicatorsObservable = combineLatest([isIndicatorsExpandedObservable, indicatorDatasObservable]);
    const expanderObservable = combineLatest([isPrimaryObservable, indicatorDatasObservable]);
    this.subscribeOn(instrumentsObservable, () => this.updateInstrumentLines());
    this.subscribeOn(indicatorsObservable, () => this.updateIndicatorLines());
    this.subscribeOn(expanderObservable, () => this.updateExpanderLine());
  }

  protected override drawInternal() {
    // nothing
  }

  protected override resizeInternal() {
    const drawingAreaPosition = this.subChart.drawingArea.getPosition();
    const captionOffset = this.chart.styleOptions.captionOffset.getValue();
    const size = this.getSize();
    const xStart = drawingAreaPosition.xStart + captionOffset;
    const xEnd = xStart + size.width;
    const yStart = drawingAreaPosition.yStart + captionOffset;
    const yEnd = yStart + size.height;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private updateInstrumentLines() {
    this.instrumentLines = [];
    if (this.subChart.isPrimary) {
      const instrumentDatas = this.chart.getInstrumentDatas();
      for (let i = 0; i < instrumentDatas.length; i++) {
        if (i > 0 || this.chart.styleOptions.showMainInstrumentCaption.getValue()) {
          const instrumentData = instrumentDatas[i];
          const line = new InstrumentCaptionLine(this, instrumentData, i > 0);
          line.initializeEvents();
          this.instrumentLines.push(line);
        }
      }
    }
    this.updateItems();
  }

  private updateIndicatorLines() {
    this.indicatorLines = [];
    const indicatorDatas = this.subChart.getIndicatorDatas();
    if (this.subChart.getIsIndicatorsExpanded()) {
      for (const indicatorData of indicatorDatas) {
        const line = new IndicatorCaptionLine(this, indicatorData);
        line.initializeEvents();
        this.indicatorLines.push(line);
      }
    }
    this.updateItems();
  }

  private updateExpanderLine() {
    const indicatorDatas = this.subChart.getIndicatorDatas();
    if (this.subChart.isPrimary && indicatorDatas.length > 0) {
      this.expanderLine = new IndicatorExpanderLine(this);
      this.expanderLine.initializeEvents();
    } else {
      this.expanderLine = null;
    }
    this.updateItems();
  }

  private updateItems() {
    const lines = [...this.instrumentLines, ...this.indicatorLines];
    if (this.expanderLine != null) {
      lines.push(this.expanderLine);
    }
    this.setStackItems(lines);
  }
}

import { ApiError } from "@/api/error";
import { AnalysisToolDefinition, AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint, FixedPoint } from "@/anfin-chart/tools/tool-point";
import { AnalysisToolAlertHook } from "@/anfin-chart/tools/alert-hook";

export class AutoHorizontal extends AnalysisToolDefinition {

  public readonly start: FixedPoint;
  public readonly mainEnd: FixedPoint;
  public readonly end = new ChartToolPoint();

  constructor(id: string,
              instrument: Instrument,
              timeframe: Timeframe,
              startTime: number,
              public readonly price: number,
              public readonly breakoutTime: number | null,
              public readonly horizontalType: AutoHorizontalType,
              public readonly deletedAt: number | null) {
    super(id, AnalysisType.AutoHorizontal, instrument, timeframe, startTime);
    this.start = new FixedPoint(startTime, price);
    this.mainEnd = new FixedPoint(breakoutTime ?? 0, price);
    new AnalysisToolAlertHook(this, "main", this.end);
  }
}

export enum AutoHorizontalType {
  Support = 0,
  Resistance = 1
}

export function getAutoHorizontalType(type: string) {
  switch (type) {
    case "SUPPORT":
      return AutoHorizontalType.Support;
    case "RESISTANCE":
      return AutoHorizontalType.Resistance;
    default:
      throw new ApiError("Cannot resolve auto horizontal type: " + type);
  }
}

<template>
  <PageWrapper>
    <div class="my-2">
      <WatchlistSelector />
    </div>
    <WatchlistOverview :allowHide="false" />
  </PageWrapper>
</template>

<script lang="ts">
import WatchlistOverview from "@/views/watchlist/WatchlistOverview.vue";
import PageWrapper from "@/views/PageWrapper.vue";
import { defineComponent } from "vue";
import WatchlistSelector from "@/views/watchlist/WatchlistSelector.vue";

export default defineComponent({
  name: "MarketOverviewMobile",

  components: { WatchlistSelector, PageWrapper, WatchlistOverview },

  expose: []
});
</script>

<style scoped>
</style>

import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { LineElement, PolygonElement, TextBox } from "@/anfin-chart/drawable";
import { FixedSelectablePoint, ValueSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { formatNumber, formatPercentage, simpleMapCompare } from "@/anfin-chart/utils";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { Point, Size } from "@/anfin-chart/geometry";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class PriceEarningsRatio extends UserToolDefinition {

  public static readonly type = "per";

  private readonly middleLineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(190, 128, 0));
  private readonly upperLineColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(108, 168, 91));
  private readonly lowerLineColor = new ColorOption(this, OptionName.Color + "_2", new RGBAColor(189, 0, 0));
  private readonly upperAreaColor = new ColorOption(this, OptionName.Color + "_3", new RGBAColor(76, 97, 145, 0.2));
  private readonly lowerAreaColor = new ColorOption(this, OptionName.Color + "_4", new RGBAColor(134, 7, 7, 0.2));
  private readonly textColor = new ColorOption(this, OptionName.Color + "_5", new RGBAColor(230, 230, 230));

  private readonly middleRight = new ChartToolPoint();
  private readonly middleText = new ChartToolPoint();
  private readonly upperLeft = new ChartToolPoint();
  private readonly upperText = new ChartToolPoint();
  private readonly lowerLeft = new ChartToolPoint();
  private readonly lowerRight = new ChartToolPoint();
  private readonly lowerText = new ChartToolPoint();

  private middleTextBox: TextBox | null = null;
  private upperTextBox: TextBox | null = null;
  private lowerTextBox: TextBox | null = null;

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(PriceEarningsRatio.type, instrument, timeframe, 3, 3);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
    tool.withAtLeastPoints(3, () => this.createThird(tool));
  }

  public override updatePosition(tool: UserTool) {
    const [middle, upper, lower] = this.fixedPoints.map(p => tool.getPosition(p));
    if (this.fixedPoints.length >= 2) {
      const middleRight = new Point(upper.x, middle.y);
      tool.updatePoint(this.middleRight, middleRight);
      const upperLeft = new Point(middle.x, upper.y);
      tool.updatePoint(this.upperLeft, upperLeft);
    }
    if (this.fixedPoints.length >= 3) {
      const lowerLeft = new Point(middle.x, lower.y);
      tool.updatePoint(this.lowerLeft, lowerLeft);
      const lowerRight = new Point(upper.x, lower.y);
      tool.updatePoint(this.lowerRight, lowerRight);
    }
    this.updateTextPoints(tool);
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const [middle, upper] = this.fixedPoints;
    const middleLine = new LineElement(tool, middle, this.middleRight);
    const upperLine = new LineElement(tool, upper, this.upperLeft);
    const upperRect = new PolygonElement(tool, [middle, this.middleRight, upper, this.upperLeft]);
    upperRect.ignoreHit();
    const middleTextBox = new TextBox(tool, this.middleText);
    const upperTextBox = new TextBox(tool, this.upperText);
    this.middleTextBox = middleTextBox;
    this.upperTextBox = upperTextBox;
    tool.onUpdate(() => {
      const lineWidth = tool.getLineWidth(true);
      middleLine.color = this.middleLineColor.getValue();
      middleLine.options.width = lineWidth;
      upperLine.color = this.upperLineColor.getValue();
      upperLine.options.width = lineWidth;
      upperRect.areaColor = this.upperAreaColor.getValue();
      middleTextBox.color = this.textColor.getValue();
      middleTextBox.boxColor = this.middleLineColor.getValue();
      middleTextBox.borderColor = this.textColor.getValue();
      middleTextBox.setText(this.getMiddleText(tool));
      upperTextBox.color = this.textColor.getValue();
      upperTextBox.boxColor = this.upperLineColor.getValue();
      upperTextBox.borderColor = this.textColor.getValue();
      upperTextBox.setText(this.getUpperText(tool));
    });
    new FixedSelectablePoint(tool, upper);
    new ValueSelectablePoint(tool, this.middleRight, (time, price) => {
      middle.update(middle.time, price);
      upper.update(time, upper.price);
    });
    new ValueSelectablePoint(tool, this.upperLeft, (time, price) => {
      middle.update(time, middle.price);
      upper.update(upper.time, price);
    });
  }

  private createThird(tool: UserTool) {
    const [first, second, third] = this.fixedPoints;
    const lowerLine = new LineElement(tool, this.lowerLeft, this.lowerRight);
    const lowerRect = new PolygonElement(tool, [first, this.middleRight, this.lowerRight, this.lowerLeft]);
    lowerRect.ignoreHit();
    const lowerTextBox = new TextBox(tool, this.lowerText);
    this.lowerTextBox = lowerTextBox;
    tool.onUpdate(() => {
      lowerLine.color = this.lowerLineColor.getValue();
      lowerLine.options.width = tool.getLineWidth(true);
      lowerRect.areaColor = this.lowerAreaColor.getValue();
      lowerTextBox.color = this.textColor.getValue();
      lowerTextBox.boxColor = this.lowerLineColor.getValue();
      lowerTextBox.borderColor = this.textColor.getValue();
      lowerTextBox.setText(this.getLowerText(tool));
    });
    new ValueSelectablePoint(tool, this.lowerLeft, (time, price) => {
      first.update(time, first.price);
      third.update(second.time, price);
    });
    new ValueSelectablePoint(tool, this.lowerRight, (time, price) => {
      second.update(time, second.price);
      third.update(second.time, price);
    });
  }

  private getMiddleText(tool: UserTool) {
    const [first, second, third] = this.fixedPoints;
    const entryLabel = tool.chart.callbacks.getTranslation("user_tool#per#label_entry");
    let text = `${entryLabel}: ${tool.chart.formatPrice(first.price)}`;
    if (third != null) {
      const ratio = (second.price - first.price) / (first.price - third.price);
      const ratioLabel = tool.chart.callbacks.getTranslation("user_tool#per#label_ratio");
      text += ` - ${ratioLabel}: ${formatNumber(ratio, 2)}`;
    }
    return text;
  }

  private getUpperText(tool: UserTool) {
    const [first, second] = this.fixedPoints;
    const upperDiff = second.price - first.price;
    const upperDiffPercentage = upperDiff / first.price;
    const priceFormatted = tool.chart.formatPrice(second.price);
    const diffFormatted = tool.chart.formatPrice(upperDiff);
    const percentageFormatted = formatPercentage(upperDiffPercentage);
    const targetLabel = tool.chart.callbacks.getTranslation("user_tool#per#label_target");
    return `${targetLabel}: ${priceFormatted} \u25b2 ${diffFormatted} | ${percentageFormatted}`;
  }

  private getLowerText(tool: UserTool) {
    const [first, second, third] = this.fixedPoints;
    const lowerDiff = third.price - first.price;
    const lowerDiffPercentage = lowerDiff / first.price;
    const priceFormatted = tool.chart.formatPrice(third.price);
    const diffFormatted = tool.chart.formatPrice(lowerDiff);
    const percentageFormatted = formatPercentage(lowerDiffPercentage);
    const stopLabel = tool.chart.callbacks.getTranslation("user_tool#per#label_stop");
    return `${stopLabel}: ${priceFormatted} \u25bc ${diffFormatted} | ${percentageFormatted}`;
  }

  private updateTextPoints(tool: UserTool) {
    const [middle, upper, lower] = this.fixedPoints.map(p => tool.getPosition(p));
    const middleRight = tool.getPosition(this.middleRight);
    const pointSize = tool.chart.styleOptions.toolPointRadius.getValue();
    const distance = Math.abs(middle.x - middleRight.x) - pointSize;
    const middleX = (middle.x + middleRight.x) / 2;
    const lineDatas = [];
    if (this.upperTextBox != null) {
      lineDatas.push({ textBox: this.upperTextBox, point: upper });
    }
    if (this.middleTextBox != null) {
      lineDatas.push({ textBox: this.middleTextBox, point: middle });
    }
    if (this.lowerTextBox != null) {
      lineDatas.push({ textBox: this.lowerTextBox, point: lower });
    }
    lineDatas.sort(simpleMapCompare(t => t.point.y));
    for (let i = 0; i < lineDatas.length; i++) {
      const lineData = lineDatas[i];
      const textPosition = lineData.textBox.currentPosition;
      const textSize = new Size(textPosition.width, textPosition.height);
      const textOffset = this.getTextOffset(tool, i, textSize, distance);
      const middleY = lineData.point.y + textOffset;
      const middleText = new Point(middleX, middleY);
      tool.updatePoint(lineData.textBox.point, middleText);
    }
  }

  private getTextOffset(tool: UserTool, index: number, textSize: Size, distance: number) {
    const isSmall = textSize.width >= distance;
    const heightOffset = textSize.height / 2;
    const pointSize = tool.chart.styleOptions.toolPointRadius.getValue();
    const offset = isSmall ? heightOffset + pointSize : heightOffset + window.devicePixelRatio;
    switch (index) {
      case 0:
        return -offset;
      case 1:
        if (isSmall) {
          const points = this.fixedPoints.map(p => tool.getPosition(p));
          if (points.length > 2) {
            points.sort(simpleMapCompare(p => p.y));
            const isUpperHalf = Math.abs(points[0].y - points[1].y) > Math.abs(points[2].y - points[1].y);
            const direction = isUpperHalf ? -1 : 1;
            return direction * offset;
          }
        }
        return 0;
      case 2:
        return offset;
      default:
        return 0;
    }
  }
}

<template>
  <div class="page-wrapper" :class="[themeClass]">
    <slot></slot>
    <ToastContainer />
  </div>
</template>

<script lang="ts">
import { chartOptionStore } from "@/stores/chart-option-store";
import ToastContainer from "@/views/components/ToastContainer.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import type { Theme } from "@/api/models/theme";

export default defineComponent({
  name: "PageWrapper",

  components: { ToastContainer },

  props: {
    staticTheme: {
      type: Number,
      default: null
    }
  },

  expose: [],

  data() {
    const { currentTheme, themes } = storeToRefs(chartOptionStore());
    return {
      currentTheme: currentTheme as unknown as Theme,
      themes: themes as unknown as Theme[]
    };
  },

  computed: {
    themeClass() {
      const theme = this.themes.find(t => t.id === this.staticTheme) ?? this.currentTheme;
      return theme.className;
    }
  }
});
</script>

<style scoped>
.page-wrapper {
  width: 100%;
  height: 100dvh;
  background-color: var(--background-screen);
  color: var(--content-primary);
  font-style: normal;
  line-height: normal;
}
</style>

import type { PriceAxis } from "@/anfin-chart/area/price-axis";
import { ScientificNotation } from "@/anfin-chart/utils";
import { Range } from "@/anfin-chart/geometry";

export abstract class PriceScale {

  protected range = new Range(0, 1);

  constructor(protected readonly priceAxis: PriceAxis) {
  }

  public getPrice(y: number) {
    const position = this.priceAxis.getPosition();
    if (position.height === 0) {
      return 0;
    }
    const relativePosition = (position.yEnd - y) / position.height;
    return this.getPriceInternal(relativePosition);
  }

  public getY(price: number) {
    const relativePosition = this.getRelativePosition(price);
    const position = this.priceAxis.getPosition();
    return position.yEnd - relativePosition * (position.yEnd - position.yStart);
  }

  public getRange() {
    return this.range;
  }

  public setRange(minPrice: number, maxPrice: number) {
    if (this.range.start === minPrice && this.range.end === maxPrice) {
      return;
    }
    this.range = new Range(minPrice, maxPrice);
    this.priceAxis.onRangeChanged(this.range);
  }

  protected getStepDistance(priceDifference: number) {
    const scientificNotation = ScientificNotation.from(priceDifference);
    const value = scientificNotation.value;
    if (value <= 1) {
      scientificNotation.value = 1;
    } else if (value <= 2) {
      scientificNotation.value = 2;
    } else if (value <= 5) {
      scientificNotation.value = 5;
    } else {
      scientificNotation.exponent++;
      scientificNotation.value = 1;
    }
    return scientificNotation;
  }

  public abstract adjustRangeRelative(startOffset: number, endOffset: number): void;

  public abstract adjustRangeZoom(baseY: number, zoomFactor: number): void;

  public abstract getLabelPrices(): number[];

  protected abstract getPriceInternal(relativePosition: number): number;

  protected abstract getRelativePosition(price: number): number;
}

export class AbsolutePriceScale extends PriceScale {

  public override adjustRangeRelative(startOffset: number, endOffset: number) {
    const range = this.priceAxis.getRange();
    const start = range.start + range.size * startOffset;
    const end = range.end + range.size * endOffset;
    this.setRange(start, end);
  }

  public override adjustRangeZoom(baseY: number, zoomFactor: number) {
    const range = this.priceAxis.getRange();
    const basePrice = this.getPrice(baseY);
    const start = basePrice - (basePrice - range.start) * zoomFactor;
    const end = basePrice - (basePrice - range.end) * zoomFactor;
    this.setRange(start, end);
  }

  public override getLabelPrices() {
    const labelDifference = this.getLabelDifference();
    if (labelDifference == null) {
      return [];
    }
    const stepSize = labelDifference.toNumber();
    const range = this.priceAxis.getRange();
    let currentPrice = range.start - range.start % stepSize;
    const prices = [];
    while (currentPrice <= range.end) {
      prices.push(currentPrice);
      currentPrice += stepSize;
    }
    return prices;
  }

  protected override getPriceInternal(relativePosition: number) {
    const range = this.priceAxis.getRange();
    return range.start + relativePosition * range.size;
  }

  protected override getRelativePosition(price: number) {
    const range = this.priceAxis.getRange();
    return (price - range.start) / range.size;
  }

  private getLabelDifference() {
    const position = this.priceAxis.getPosition();
    const height = position.yEnd - position.yStart;
    if (height === 0) {
      return null;
    }
    const minDistance = this.priceAxis.chart.styleOptions.minPriceLabelDistance.getValue();
    const labelCount = Math.max(Math.round(height / minDistance), 1);
    const minPriceDistance = this.priceAxis.getRange().size / labelCount;
    return this.getStepDistance(minPriceDistance);
  }
}

export class LogarithmicPriceScale extends PriceScale {

  private static readonly minLogPrice = 2 ** -100;

  private logarithmicRange = new Range(0, 1);

  public override setRange(minPrice: number, maxPrice: number) {
    let start: number;
    let end: number;
    if (minPrice < LogarithmicPriceScale.minLogPrice) {
      start = 2 ** -10;
      end = maxPrice > start ? maxPrice : 1;
    } else {
      start = minPrice;
      end = maxPrice;
    }
    this.logarithmicRange = new Range(Math.log2(start), Math.log2(end));
    super.setRange(start, end);
  }

  public override adjustRangeRelative(startOffset: number, endOffset: number) {
    const logDifference = this.logarithmicRange.size;
    const start = this.logarithmicRange.start + logDifference * startOffset;
    const end = this.logarithmicRange.end + logDifference * endOffset;
    this.setLogarithmicRange(start, end);
  }

  public override adjustRangeZoom(baseY: number, zoomFactor: number) {
    const basePrice = this.getPrice(baseY);
    const baseLog = this.getLogarithmus(basePrice);
    const start = baseLog - (baseLog - this.logarithmicRange.start) * zoomFactor;
    const end = baseLog - (baseLog - this.logarithmicRange.end) * zoomFactor;
    this.setLogarithmicRange(start, end);
  }

  public override getLabelPrices() {
    const position = this.priceAxis.getPosition();
    const height = position.yEnd - position.yStart;
    if (height <= 0) {
      return [];
    }
    const minDistance = this.priceAxis.chart.styleOptions.minPriceLabelDistance.getValue();
    const logDifference = this.logarithmicRange.end - this.logarithmicRange.start;
    const labelLogDifference = minDistance / height * logDifference;
    let currentLog = this.logarithmicRange.start;
    const prices = [];
    while (currentLog <= this.logarithmicRange.end) {
      const currentPrice = 2 ** currentLog;
      const nextPrice = 2 ** (currentLog + labelLogDifference);
      const stepDistance = this.getStepDistance((nextPrice - currentPrice) / 2).toNumber();
      const correction = currentPrice % stepDistance;
      const roundedPrice = correction === 0 ? currentPrice : currentPrice + stepDistance - currentPrice % stepDistance;
      prices.push(roundedPrice);
      currentLog = this.getLogarithmus(roundedPrice) + labelLogDifference;
    }
    return prices;
  }
  
  protected override getPriceInternal(relativePosition: number) {
    const logDifference = this.logarithmicRange.end - this.logarithmicRange.start;
    const logPrice = this.logarithmicRange.start + relativePosition * logDifference;
    return 2 ** logPrice;
  }

  protected override getRelativePosition(price: number) {
    const logPrice = this.getLogarithmus(price);
    return (logPrice - this.logarithmicRange.start) / (this.logarithmicRange.end - this.logarithmicRange.start);
  }

  private getLogarithmus(price: number) {
    return price <= 0 ? -100 : Math.log2(price);
  }

  private setLogarithmicRange(logStart: number, logEnd: number) {
    this.setRange(2 ** logStart, 2 ** logEnd);
  }
}

import { ChartArea } from "@/anfin-chart/area/chart-area";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { LineOptions } from "@/anfin-chart/draw/chart-drawer";
import {
  CircleElement,
  type Drawable,
  EllipseElement, IconElement,
  LineElement,
  LineStrip,
  PolygonElement,
  TextBox
} from "@/anfin-chart/drawable";
import { ChartError } from "@/anfin-chart/error";
import { getAngle, getDistance, isWithin, Point, Polygon, Rectangle, Size } from "@/anfin-chart/geometry";
import { SelectablePoint } from "@/anfin-chart/selectable-point";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { simpleMapCompare } from "@/anfin-chart/utils";
import { combineLatest } from "rxjs";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";
import { AlertIconElement, AlertLineElement } from "@/anfin-chart/tools/alert-tool";

function getDrawablePriority(drawable: Drawable) {
  if (drawable instanceof SelectablePoint) {
    return 3;
  }
  if (drawable instanceof AlertIconElement || drawable instanceof AlertLineElement) {
    return 2;
  }
  if (drawable instanceof TextBox) {
    return 1;
  }
  return 0;
}

export abstract class BaseToolArea extends ChartArea {

  protected constructor(layer: ChartLayer,
                        protected readonly subChart: SubChart,
                        private readonly baseArea: ChartArea) {
    super(layer);
    this.setVisibleOnSubChart(subChart);
  }

  public override initializeEvents() {
    this.subscribeOn(this.baseArea.getPositionObservable(), () => this.resize());
    const combinedObservable = combineLatest([
      this.chart.timeAxis.getRangeObservable(),
      this.subChart.priceAxis.getRangeObservable(),
      this.getPositionObservable()
    ]);
    this.subscribeOn(combinedObservable, () => {
      this.getTools().forEach(t => t.updatePosition());
    });
  }

  protected override drawInternal() {
    if (this.chart.timeAxis.getTimeCount() === 0) {
      return;
    }
    const drawables: Drawable[] = [];
    for (const tool of this.getTools()) {
      if (!tool.getIsVisible()) {
        continue;
      }
      for (const drawable of tool.getDrawables()) {
        if (drawable.getIsVisible()) {
          drawables.push(drawable);
        }
      }
    }
    drawables.sort(simpleMapCompare(getDrawablePriority));
    for (const drawable of drawables) {
      const alpha = drawable.getIsBlurred() ? this.chart.styleOptions.toolBlurAlpha.getValue() : 1;
      this.drawer.withGlobalAlpha(alpha, () => this.drawToolElement(drawable));
    }
  }

  protected override resizeInternal() {
    const baseAreaPosition = this.baseArea.getPosition();
    const xStart = baseAreaPosition.xStart;
    const xEnd = baseAreaPosition.xEnd;
    const yStart = baseAreaPosition.yStart;
    const yEnd = baseAreaPosition.yEnd;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private drawToolElement(drawable: Drawable) {
    if (drawable instanceof SelectablePoint) {
      this.drawSelectablePoint(drawable);
    } else if (drawable instanceof LineElement) {
      this.drawLine(drawable);
    } else if (drawable instanceof LineStrip) {
      this.drawLineStrip(drawable);
    } else if (drawable instanceof PolygonElement) {
      this.drawPolygon(drawable);
    } else if (drawable instanceof TextBox) {
      this.drawTextBox(drawable);
    } else if (drawable instanceof CircleElement) {
      this.drawCircle(drawable);
    } else if (drawable instanceof EllipseElement) {
      this.drawEllipse(drawable);
    } else if (drawable instanceof IconElement) {
      this.drawIcon(drawable);
    } else {
      throw new ChartError("Unknown drawing tool element");
    }
  }

  private drawSelectablePoint(point: SelectablePoint) {
    const lineWidth = this.chart.styleOptions.toolPointBorder.getPlainValue();
    const radius = this.chart.styleOptions.toolPointRadius.getValue() - lineWidth;
    const lineColor = this.chart.optionManager.chartColor.toolPointBorder.getValue();
    const fillColor = this.chart.optionManager.chartColor.toolPointFill.getValue();
    const position = point.getPosition(point.point);
    const positionRounded = new Point(Math.floor(position.x), Math.round(position.y));
    this.drawer.drawCircle(positionRounded, radius, lineColor, fillColor, new LineOptions(lineWidth));
  }

  private drawLine(line: LineElement) {
    const start = line.getPosition(line.start);
    const end = line.getPosition(line.end);
    const startRounded = new Point(Math.round(start.x), Math.round(start.y));
    const endRounded = new Point(Math.round(end.x), Math.round(end.y));
    this.drawer.drawLine(startRounded, endRounded, line.color, line.options);
  }

  private drawLineStrip(strip: LineStrip) {
    if (strip.points.length === 0) {
      return;
    }
    const context = this.drawer.getContext();
    this.drawer.setLineOptions(strip.options);
    context.beginPath();
    const firstPoint = strip.getPosition(strip.points[0]);
    context.moveTo(firstPoint.x, firstPoint.y);
    for (let i = 1; i < strip.points.length; i++) {
      const point = strip.getPosition(strip.points[i]);
      context.lineTo(point.x, point.y);
    }
    context.strokeStyle = this.drawer.getStyle(strip.color);
    context.stroke();
  }

  private drawPolygon(polygonElement: PolygonElement) {
    const positions = polygonElement.points.map(p => polygonElement.getPosition(p));
    const polygon = new Polygon(...positions);
    this.drawer.drawPolygon(polygon, polygonElement.lineColor, polygonElement.areaColor, polygonElement.options);
  }

  private drawTextBox(textBox: TextBox) {
    const position = textBox.getPosition(textBox.point);
    if (!isWithin(this.getPosition(), position)) {
      return;
    }
    const text = textBox.getText();
    const textColor = textBox.color;
    const boxColor = textBox.boxColor;
    const borderColor = textBox.borderColor;
    const fontInfo = textBox.getFontInfo();
    if (boxColor != null || borderColor != null) {
      const borderRadius = this.chart.styleOptions.textBoxBorderRadius.getValue();
      this.drawer.setLineOptions(new LineOptions());
      this.drawer.drawRoundRect(textBox.currentPosition, borderRadius, borderColor, boxColor);
    }
    this.drawer.printTextAligned(textBox.currentPosition, text, textColor, fontInfo);
  }

  private drawCircle(circleElement: CircleElement) {
    const center = circleElement.getPosition(circleElement.center);
    if (!isWithin(this.getPosition(), center)) {
      return;
    }
    const centerRounded = new Point(Math.floor(center.x), Math.round(center.y));
    this.drawer.drawCircle(centerRounded, circleElement.radius, null, circleElement.color);
  }

  private drawEllipse(ellipse: EllipseElement) {
    const centerPosition = ellipse.getPosition(ellipse.center);
    const mainAxisPosition = ellipse.getPosition(ellipse.mainAxis);
    const centerRounded = new Point(Math.round(centerPosition.x), Math.round(centerPosition.y));
    const angle = getAngle(centerPosition, mainAxisPosition);
    const mainAxis = getDistance(centerPosition, mainAxisPosition);
    const offAxisPosition = ellipse.getPosition(ellipse.offAxis);
    const offAxis = getDistance(centerPosition, offAxisPosition);
    this.drawer.drawEllipse(
      centerRounded, mainAxis, offAxis, angle, ellipse.lineColor, ellipse.areaColor, ellipse.options
    );
  }

  private drawIcon(iconElement: IconElement) {
    const center = iconElement.getPosition(iconElement.center);
    const size = new Size(iconElement.size, iconElement.size);
    const alignedPosition = this.drawer.getAlignedPosition(center, size, iconElement.alignment);
    const icon = this.chart.iconStore.getIcon(iconElement.iconName);
    this.drawer.drawSvg(icon, alignedPosition, iconElement.color);
  }

  protected abstract getTools(): ChartTool[];
}

export class AlertToolArea extends BaseToolArea {

  constructor(layer: ChartLayer, subChart: SubChart) {
    super(layer, subChart, subChart.drawingArea);
  }

  protected override getTools() {
    return this.subChart.getAlertTools();
  }
}

export class ToolDrawingArea extends BaseToolArea {

  constructor(layer: ChartLayer, subChart: SubChart) {
    super(layer, subChart, subChart.drawingArea);
  }

  protected override getTools() {
    return [...this.subChart.getAnalysisTools(), ...this.subChart.getUserTools()];
  }
}

import { TwoWayMap } from "@/anfin-chart/utils";
import type { Consumer } from "@/anfin-chart/utils";

const keyCodeMap = new TwoWayMap<string, string>();
keyCodeMap.set("Digit1", "1");
keyCodeMap.set("Digit2", "2");
keyCodeMap.set("Digit3", "3");
keyCodeMap.set("Digit4", "4");
keyCodeMap.set("Digit5", "5");
keyCodeMap.set("Digit6", "6");
keyCodeMap.set("Digit7", "7");
keyCodeMap.set("Digit8", "8");
keyCodeMap.set("Digit9", "9");
keyCodeMap.set("Digit0", "0");
keyCodeMap.set("Minus", "-");
keyCodeMap.set("Equal", "=");
keyCodeMap.set("KeyA", "A");
keyCodeMap.set("KeyB", "B");
keyCodeMap.set("KeyC", "C");
keyCodeMap.set("KeyD", "D");
keyCodeMap.set("KeyE", "E");
keyCodeMap.set("KeyF", "F");
keyCodeMap.set("KeyG", "G");
keyCodeMap.set("KeyH", "H");
keyCodeMap.set("KeyI", "I");
keyCodeMap.set("KeyJ", "J");
keyCodeMap.set("KeyK", "K");
keyCodeMap.set("KeyL", "L");
keyCodeMap.set("KeyM", "M");
keyCodeMap.set("KeyN", "N");
keyCodeMap.set("KeyO", "O");
keyCodeMap.set("KeyP", "P");
keyCodeMap.set("KeyQ", "Q");
keyCodeMap.set("KeyR", "R");
keyCodeMap.set("KeyS", "S");
keyCodeMap.set("KeyT", "T");
keyCodeMap.set("KeyU", "U");
keyCodeMap.set("KeyV", "V");
keyCodeMap.set("KeyW", "W");
keyCodeMap.set("KeyX", "X");
keyCodeMap.set("KeyY", "Z");
keyCodeMap.set("KeyZ", "Y");
keyCodeMap.set("BracketLeft", "(");
keyCodeMap.set("BracketRight", ")");
keyCodeMap.set("Semicolon", ";");
keyCodeMap.set("Quote", "´");
keyCodeMap.set("Backquote", "`");
keyCodeMap.set("Backslash", "\\");
keyCodeMap.set("Comma", ",");
keyCodeMap.set("Period", ".");
keyCodeMap.set("Slash", "/");
keyCodeMap.set("F1", "F1");
keyCodeMap.set("F2", "F2");
keyCodeMap.set("F3", "F3");
keyCodeMap.set("F4", "F4");
keyCodeMap.set("F5", "F5");
keyCodeMap.set("F6", "F6");
keyCodeMap.set("F7", "F7");
keyCodeMap.set("F8", "F8");
keyCodeMap.set("F9", "F9");
keyCodeMap.set("F10", "F10");

let layoutMap: any | null = null;
const keyboard = (navigator as any).keyboard;
if (keyboard != null) {
  keyboard.getLayoutMap().then((m: any) => {
    layoutMap = m;
  });
}

export function convertKeyCode(code: string) {
  return layoutMap?.get(code) ?? keyCodeMap.getValue(code) ?? code;
}

export enum ShortcutCategory {
  General = 0,
  Indicator = 1,
  Tool = 2
}

export class KeyInfo {

  constructor(public key: string,
              public readonly isAlt = false,
              public readonly isControl = false,
              public readonly isShift = false) {
  }
}

export class ShortcutGroup {

  public readonly shortcuts: Shortcut[] = [];

  constructor(public readonly category: ShortcutCategory) {
  }
}

export class Shortcut {

  public keyInfo: KeyInfo | null = null;

  constructor(public readonly group: ShortcutGroup,
              public readonly id: number,
              public readonly name: string,
              public readonly defaultKeyInfo: KeyInfo | null,
              public readonly action: Consumer<void>,
              public readonly customName: string | null) {
    this.keyInfo = defaultKeyInfo;
    group.shortcuts.push(this);
  }
}

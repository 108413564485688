<template>
  <div class="product-table-card-row">
    <div class="product-table-header">
      {{ translationStore.getTranslation("product#comparison_table#header") }}
    </div>
    <ProductCardGroup :period="period" :showJfd="showJfd" class="product-table-card-container" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProductCardGroup from "@/views/pages-landing/product/ProductCardGroup.vue";
import { translationStore } from "@/stores/translation-store";
import { Timeframe } from "@/anfin-chart/time/timeframe";

export default defineComponent({
  name: "ProductTableCardRow",

  components: { ProductCardGroup },

  props: {
    period: {
      type: Timeframe,
      required: true
    },
    showJfd: Boolean
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  }
});
</script>

<style>
.product-table-card-row {
  display: contents;
}

.product-table-header {
  color: var(--background-primary);
  font-size: 250%;
  font-weight: bold;
  align-self: center;
  justify-self: center;
}

.product-table-card-container {
  display: contents;
}

.product-table-card-container > * {
  align-self: stretch;
  justify-self: center;
}
</style>
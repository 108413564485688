import type { ColorProvider, ColorSet } from "@/anfin-chart/draw/chart-color";
import type { ChartDrawer } from "@/anfin-chart/draw/chart-drawer";
import type { PlotData, PlotDataItem } from "@/anfin-chart/draw/plot-data";
import { ChartError } from "@/anfin-chart/error";
import { Point } from "@/anfin-chart/geometry";
import { PlotType } from "@/anfin-chart/plot";
import type { SubChart } from "@/anfin-chart/sub-chart";

export class PlotDrawer {

  private readonly paths: MultiColorPath[];

  constructor(public readonly drawer: ChartDrawer,
              public readonly subChart: SubChart,
              private readonly plotData: PlotData,
              public readonly colorProvider: ColorProvider) {
    this.paths = this.createPaths();
  }

  public draw() {
    this.initialize();
    for (const item of this.plotData.items) {
      this.colorProvider.update(item);
      this.addItem(item);
    }
    for (const path of this.paths) {
      path.draw();
    }
  }

  protected initialize() {
    this.colorProvider.reset();
    for (const path of this.paths) {
      path.initialize();
    }
  }

  private addItem(item: PlotDataItem) {
    for (let i = 0; i < item.values.length; i++) {
      const value = item.values[i];
      item.values[i] = this.subChart.priceAxis.getY(value);
    }
    for (const path of this.paths) {
      path.addPoint(item.x, item.values);
    }
  }

  private createPaths(): MultiColorPath[] {
    const plotType = this.plotData.plotType;
    switch (plotType) {
      case PlotType.Line:
        return [new LinePath(this, 0)];
      case PlotType.Mountain:
        return [new LinePath(this, 0), new MountainPath(this, 1)];
      case PlotType.Bar:
        return [new BarPath(this)];
      case PlotType.Candle:
        return [new CandlePath(this)];
      case PlotType.SimpleBar:
        return [new SimpleBarPath(this)];
      default:
        throw new ChartError("Unknown plot type: " + plotType);
    }
  }
}

export class LastPathData {

  constructor(public paths: Path2D[],
              public x: number,
              public yValues: number[]) {
  }
}

export abstract class MultiColorPath {

  protected readonly context: CanvasRenderingContext2D;
  protected lastPathData: LastPathData | null = null;

  private readonly colorPathsMap = new Map<ColorSet, Path2D[]>();

  protected constructor(protected readonly plotDrawer: PlotDrawer, private readonly pathCount: number) {
    this.context = this.drawer.getContext();
  }

  protected get drawer() {
    return this.plotDrawer.drawer;
  }

  public initialize() {
    // override in subclass if necessary
  }

  public draw() {
    for (const [colorSet, paths] of this.colorPathsMap.entries()) {
      this.applyColorSet(colorSet, paths);
    }
  }

  public addPoint(x: number, yValues: number[]) {
    const paths = this.getPaths();
    this.addPointInternal(x, yValues, paths, this.plotDrawer.colorProvider.switchPercentage);
    if (this.lastPathData == null) {
      this.lastPathData = new LastPathData(paths, x, yValues);
    } else {
      this.lastPathData.paths = paths;
      this.lastPathData.x = x;
      this.lastPathData.yValues = yValues;
    }
  }

  protected getPaths() {
    const colorSet = this.plotDrawer.colorProvider.currentSet;
    if (colorSet == null) {
      throw new ChartError("Color set must be initialized before accessing paths.");
    }
    let paths = this.colorPathsMap.get(colorSet);
    if (paths == null) {
      paths = Array.from({ length: this.pathCount }).map(() => new Path2D());
      this.colorPathsMap.set(colorSet, paths);
    }
    return paths;
  }

  protected abstract addPointInternal(x: number, yValues: number[], paths: Path2D[], switchPercentage: number | null): void;

  protected abstract applyColorSet(colorSet: ColorSet, paths: Path2D[]): void;
}

export class LinePath extends MultiColorPath {

  constructor(plotDrawer: PlotDrawer, private readonly colorIndex: number) {
    super(plotDrawer, 1);
  }

  protected override addPointInternal(x: number, yValues: number[], paths: Path2D[], switchPercentage: number | null) {
    const path = paths[0];
    const y = yValues[0];
    if (this.lastPathData == null) {
      path.moveTo(x, y);
      return;
    }
    const lastPath = this.lastPathData.paths[0];
    if (path === lastPath) {
      lastPath.lineTo(x, y);
    } else if (switchPercentage == null) {
      lastPath.lineTo(x, y);
      path.moveTo(x, y);
    } else {
      const lastX = this.lastPathData.x;
      const lastY = this.lastPathData.yValues[0];
      const switchX = lastX + (x - lastX) * switchPercentage;
      const switchY = lastY + (y - lastY) * switchPercentage;
      lastPath.lineTo(switchX, switchY);
      path.moveTo(switchX, switchY);
      path.lineTo(x, y);
    }
  }

  protected override applyColorSet(colorSet: ColorSet, paths: Path2D[]) {
    const lineColor = colorSet.colors[this.colorIndex].getValue();
    this.context.strokeStyle = this.drawer.getStyle(lineColor);
    this.context.stroke(paths[0]);
  }
}

export class MountainPath extends MultiColorPath {

  private reversePoints: Point[] = [];

  constructor(plotDrawer: PlotDrawer, private readonly colorIndex: number) {
    super(plotDrawer, 1);
  }

  public override draw() {
    const lastPath = this.lastPathData?.paths[0];
    if (lastPath != null) {
      this.finishPath(lastPath);
    }
    super.draw();
  }

  protected override addPointInternal(x: number, yValues: number[], paths: Path2D[], switchPercentage: number | null) {
    const path = paths[0];
    const [y1, y2] = yValues;
    if (this.lastPathData == null) {
      this.initializePath(path, x, yValues);
      return;
    }
    const lastPath = this.lastPathData.paths[0];
    if (path === lastPath) {
      lastPath.lineTo(x, y1);
      this.reversePoints.push(new Point(x, y2));
    } else if (switchPercentage == null) {
      lastPath.lineTo(x, y1);
      this.reversePoints.push(new Point(x, y2));
      this.finishPath(lastPath);
      this.initializePath(path, x, yValues);
    } else {
      const lastX = this.lastPathData.x;
      const lastY1 = this.lastPathData.yValues[0];
      const lastY2 = this.lastPathData.yValues[1];
      const switchX = lastX + (x - lastX) * switchPercentage;
      const switchY1 = lastY1 + (y1 - lastY1) * switchPercentage;
      const switchY2 = lastY2 + (y2 - lastY2) * switchPercentage;
      lastPath.lineTo(switchX, switchY1);
      this.reversePoints.push(new Point(switchX, switchY2));
      this.finishPath(lastPath);
      this.initializePath(path, switchX, [switchY1, switchY2]);
      path.moveTo(x, y1);
      this.reversePoints.push(new Point(x, y2));
    }
  }

  protected override applyColorSet(colorSet: ColorSet, paths: Path2D[]) {
    const lineColor = colorSet.colors[this.colorIndex].getValue();
    this.context.fillStyle = this.drawer.getStyle(lineColor);
    this.context.fill(paths[0]);
  }

  private initializePath(path: Path2D, x: number, yValues: number[]) {
    path.moveTo(x, yValues[0]);
    this.reversePoints = [new Point(x, yValues[1])];
  }

  private finishPath(path: Path2D) {
    for (let i = this.reversePoints.length - 1; i >= 0; i--) {
      const point = this.reversePoints[i];
      path.lineTo(point.x, point.y);
    }
  }
}

export class BaseCandlePath extends MultiColorPath {

  private isCandleMinimized = false;
  private bodySize = 0;

  constructor(plotDrawer: PlotDrawer, private readonly isDrawWicks: boolean) {
    super(plotDrawer, 3);
  }

  private get chart() {
    return this.plotDrawer.subChart.chart;
  }

  public override initialize() {
    super.initialize();
    this.isCandleMinimized = this.chart.timeAxis.getBarWidth() < this.chart.styleOptions.minCandleWidth.getValue();
    const candleWidth = this.chart.timeAxis.getCandleWidth();
    this.bodySize = Math.round(candleWidth / 2);
  }

  protected override addPointInternal(x: number, yValues: number[], paths: Path2D[]) {
    const xBase = Math.floor(x);
    const yOpen = Math.round(yValues[0]);
    const yClose = Math.round(yValues[1]);

    if (this.isDrawWicks) {
      const wickPath = paths[2];
      const yHigh = Math.floor(yValues[2]);
      const yLow = Math.ceil(yValues[3]);
      const yMaxOpenClose = Math.max(yClose, yOpen);
      const yMinOpenClose = Math.min(yClose, yOpen);
      wickPath.moveTo(xBase, yHigh);
      if (!this.isCandleMinimized) {
        wickPath.lineTo(xBase, yMinOpenClose);
        wickPath.moveTo(xBase, yMaxOpenClose);
      }
      wickPath.lineTo(xBase, yLow);
    }
    const bodyPath = yOpen <= yClose ? paths[0] : paths[1];
    if (this.isCandleMinimized) {
      bodyPath.moveTo(xBase, yClose);
      bodyPath.lineTo(xBase, yOpen);
    } else {
      bodyPath.rect(xBase - this.bodySize, yClose, this.bodySize * 2, yOpen - yClose);
    }
  }

  protected override applyColorSet(colorSet: ColorSet, paths: Path2D[]) {
    const [ascendingPath, descendingPath, wickPath] = paths;
    this.context.lineWidth = window.devicePixelRatio;
    const colors = this.getColors(colorSet);
    const ascendingStyle = this.drawer.getStyle(colors[0].getValue());
    const descendingStyle = this.drawer.getStyle(colors[1].getValue());
    const borderStyle = colors[2] ? this.drawer.getStyle(colors[2].getValue()) : "#00000000";
    const wickStyle = this.isDrawWicks && colors[3] ? this.drawer.getStyle(colors[3].getValue()) : null;
    if (wickStyle != null) {
      this.context.strokeStyle = wickStyle;
      this.context.stroke(wickPath);
    }
    if (!this.isCandleMinimized) {
      this.context.fillStyle = ascendingStyle;
      this.context.fill(ascendingPath);
      this.context.fillStyle = descendingStyle;
      this.context.fill(descendingPath);
      this.context.strokeStyle = borderStyle;
      this.context.stroke(ascendingPath);
      this.context.stroke(descendingPath);
    } else if (wickStyle == null) {
      this.context.strokeStyle = ascendingStyle;
      this.context.stroke(ascendingPath);
      this.context.strokeStyle = descendingStyle;
      this.context.stroke(descendingPath);
    }
  }

  protected getColors(colorSet: ColorSet) {
    return colorSet.colors;
  }
}

export class CandlePath extends BaseCandlePath {

  constructor(plotDrawer: PlotDrawer) {
    super(plotDrawer, true);
  }
}

export class BarPath extends BaseCandlePath {

  constructor(plotDrawer: PlotDrawer) {
    super(plotDrawer, false);
  }
}

export class SimpleBarPath extends BaseCandlePath {

  constructor(plotDrawer: PlotDrawer) {
    super(plotDrawer, false);
  }

  protected override getColors(colorSet: ColorSet) {
    return [colorSet.colors[0], colorSet.colors[0], colorSet.colors[1]];
  }
}

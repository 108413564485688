import { LineOptions, LineStyle, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { LineElement, TextBox } from "@/anfin-chart/drawable";
import { ChartError } from "@/anfin-chart/error";
import { Point, Vector } from "@/anfin-chart/geometry";
import { TrendExtensionHint } from "@/anfin-chart/hints/trend-extension-hint";
import type { InstrumentData } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { ChartToolPoint, FixedPoint } from "@/anfin-chart/tools/tool-point";
import type { AutoTrendLine } from "@/api/models/analysis/auto-trend-line";
import { TrendExtension, TrendLineType } from "@/api/models/analysis/auto-trend-line";
import type { ColorOption } from "@/anfin-chart/options/option";
import { AnalysisTool } from "@/anfin-chart/tools/analysis-tool";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";
import type { ToolHint } from "@/anfin-chart/hints/tool-hint";

class AutoTrendExtension {

  public readonly hintPoint = new ChartToolPoint();
  public readonly markerStart = new ChartToolPoint();
  public readonly markerEnd = new ChartToolPoint();

  constructor(public readonly data: TrendExtension) {
  }
}

class TrendLineMarker extends LineElement {

  constructor(tool: ChartTool,
              start: ChartToolPoint,
              end: ChartToolPoint,
              private readonly hint: ToolHint) {
    super(tool, start, end, new LineOptions(2));
  }

  public override onStartHover() {
    if (this.tool.getIsVisible()) {
      this.hint.show();
    }
  }

  public override onStopHover() {
    this.hint.hide();
  }
}

export class AutoTrendLineTool extends AnalysisTool {

  private static hoveredTool: AutoTrendLineTool | null = null;

  private readonly extensions: AutoTrendExtension[] = [];

  private readonly lineOptions: LineOptions;
  private readonly colorOption: ColorOption;
  private readonly textOffset: number;

  private readonly start: FixedPoint;
  private readonly end: FixedPoint;

  constructor(public readonly definition: AutoTrendLine,
              instrumentData: InstrumentData,
              subChart: SubChart) {
    super(definition, instrumentData, subChart);
    this.lineOptions = new LineOptions(this.getWidth(), LineStyle.Dashed);
    const isAscending = this.definition.endPrice > this.definition.startPrice;
    this.colorOption = isAscending ? this.colorOptions.autoTrendLineAscending : this.colorOptions.autoTrendLineDescending;
    this.textOffset = definition.trendType === TrendLineType.Main ? 40 : 10;
    this.start = new FixedPoint(this.definition.startTime, this.definition.startPrice);
    this.end = new FixedPoint(this.definition.endTime, this.definition.endPrice);
    for (const extension of this.definition.extensions) {
      this.extensions.push(new AutoTrendExtension(extension));
    }
  }

  public override getIsVisible() {
    if (!super.getIsVisible()) {
      return false;
    }
    const isVisibleTrend = this.optionManager.trendLine.isVisible(this.id, this.definition.trendType, this.definition.deletedAt);
    if (!this.optionManager.showTrends.getValue() || !isVisibleTrend) {
      return false;
    }
    if (AutoTrendLineTool.hoveredTool == null) {
      return true;
    }
    return this.isHighlighted();
  }

  public override onHoverChange() {
    super.onHoverChange();
    if (this.isHovered) {
      AutoTrendLineTool.hoveredTool = this;
    } else {
      AutoTrendLineTool.hoveredTool = null;
    }
    this.updateDrawables();
  }

  protected override createDrawables() {
    const line = new LineElement(this, this.start, this.end, this.lineOptions);
    const alignmentY = this.definition.startPrice < this.definition.endPrice ? 1 : -1;
    const alignment = new TextAlignment(new Vector(0, alignmentY), this.textOffset);
    const textBox = new TextBox(this, this.start, alignment);
    this.onUpdate(() => {
      line.color = this.colorOption.getValue();
      const textColor = this.colorOptions.autoTrendLineText.getValue();
      textBox.color = textColor;
      textBox.boxColor = this.colorOptions.autoTrendLineBox.getValue();
      textBox.borderColor = textColor;
      textBox.setText(this.definition.sequenceIndex.toString());
    });
    for (const extension of this.extensions) {
      const extensionHint = new TrendExtensionHint(this, extension.hintPoint, extension.data);
      const extensionMarker = new TrendLineMarker(this, extension.markerStart, extension.markerEnd, extensionHint);
      this.onUpdate(() => {
        extensionMarker.color = this.colorOptions.autoTrendLineMarker.getValue();
        extensionMarker.setIsVisible(this.isHighlighted());
      });
    }
  }

  protected override updatePositionInternal() {
    this.updateFixedPoint(this.start, this.end);
    const startPosition = this.getPosition(this.start);
    const endPosition = this.getPosition(this.end);
    const xStart = startPosition.x;
    const yStart = startPosition.y;
    const xEnd = endPosition.x;
    const yEnd = endPosition.y;
    for (const extension of this.extensions) {
      const x = this.chart.timeAxis.getXForTime(extension.data.time);
      const xPercentage = (x - xStart) / (xEnd - xStart);
      const y = yStart + (yEnd - yStart) * xPercentage;
      const markerSize = this.chart.styleOptions.autoTrendLineMarkerSize.getValue();
      const markerStart = new Point(x, y - markerSize);
      this.updatePoint(extension.markerStart, markerStart);
      const markerEnd = new Point(x, y + markerSize);
      this.updatePoint(extension.markerEnd, markerEnd);
      let hintPoint: Point;
      if (extension.data.breakoutPrice == null) {
        hintPoint = new Point(x, y);
      } else {
        const yBreakout = this.subChart.priceAxis.getY(extension.data.breakoutPrice);
        hintPoint = new Point(x, yBreakout);
      }
      this.updatePoint(extension.hintPoint, hintPoint);
    }
  }

  private isHighlighted() {
    if (AutoTrendLineTool.hoveredTool == null) {
      return false;
    }
    const hoveredAnalysis = AutoTrendLineTool.hoveredTool.definition;
    return AutoTrendLineTool.hoveredTool === this ||
      this.definition.parent != null && hoveredAnalysis.startTime === this.definition.parent ||
      hoveredAnalysis.parent != null && hoveredAnalysis.parent === this.definition.startTime;
  }

  private getWidth() {
    switch (this.definition.trendType) {
      case TrendLineType.Main:
        return 3;
      case TrendLineType.SubMain:
        return 1.5;
      case TrendLineType.Sub:
      case TrendLineType.Candidate:
        return 0.8;
      default:
        throw new ChartError("Unknown trend line type: " + this.definition.trendType);
    }
  }
}

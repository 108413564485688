import { ChartArea } from "@/anfin-chart/area/chart-area";
import { LineOptions } from "@/anfin-chart/draw/chart-drawer";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { Point, Rectangle } from "@/anfin-chart/geometry";
import type { DragData, Draggable } from "@/anfin-chart/interactions";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { asHoverable } from "@/anfin-chart/mixins/hoverable";

export class ResizeArea extends asHoverable(ChartArea) implements Draggable {

  constructor(layer: ChartLayer,
              private readonly subChart: SubChart) {
    super(layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.subChart.mainArea.getPositionObservable(), () => this.resize());
    this.subscribeOn(this.subChart.getIndexObservable(), i => {
      this.setIsVisible(i > 0);
    });
  }

  public onDrag(data: DragData) {
    if (!this.subChart.getIsExpanded()) {
      return;
    }
    const currentHeight = this.subChart.getHeight();
    let difference = this.getPosition().yStart - data.position.y;
    const minHeight = this.chart.styleOptions.minSubChartHeight.getValue();
    const subCharts = this.chart.getSubCharts();
    const index = this.subChart.getIndex();
    const previousSubChart = subCharts[index - 1];
    const previousHeight = previousSubChart.getHeight();
    if (difference < 0 && currentHeight + difference < minHeight) {
      difference = minHeight - currentHeight;
    }
    if (difference > 0 && previousHeight - difference < minHeight) {
      difference = previousHeight - minHeight;
    }
    const newCurrentHeight = currentHeight + difference;
    const newPreviousHeight = previousHeight - difference;
    this.subChart.setHeight(newCurrentHeight);
    previousSubChart.setHeight(newPreviousHeight);
  }

  public onDragEnd() {
    this.chart.callbacks.saveExport();
  }

  protected override drawInternal() {
    const position = this.getPosition();
    const yCenter = Math.round((position.yStart + position.yEnd) / 2);
    const start = new Point(position.xStart, yCenter);
    const end = new Point(position.xEnd, yCenter);
    const chartColors = this.chart.optionManager.chartColor;
    const colorOption = this.isHovered ? chartColors.subChartSeparatorHover : chartColors.subChartSeparator;
    const hoverWidth = this.chart.styleOptions.subChartSeparatorHoverLineWidth.getPlainValue();
    const lineOptions = new LineOptions(this.isHovered ? hoverWidth : 1);
    this.drawer.drawLine(start, end, colorOption.getValue(), lineOptions);
  }

  protected override resizeInternal() {
    const subChartAreaPosition = this.subChart.mainArea.getPosition();
    const height = this.chart.styleOptions.resizeAreaSize.getValue();
    const xStart = subChartAreaPosition.xStart;
    const xEnd = subChartAreaPosition.xEnd;
    const yStart = subChartAreaPosition.yStart - height;
    const yEnd = subChartAreaPosition.yStart + height;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }
}

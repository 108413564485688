<template>
  <ExpandableList ref="expandableList">
    <template #header>
      <WatchlistCategoryIcon :category="item.data.category" :class="{ invisible: item.data.category === 0 && !showUnset }" />
    </template>
    <template #content>
      <WatchlistCategoryIcons :includeUnset="true" class="p-1" @selectCategory="setCategory" />
    </template>
  </ExpandableList>
</template>

<script lang="ts">
import type { SymbolCategory } from "@/api/models/symbol-category";
import { WatchlistItem } from "@/api/models/watchlist";
import { watchlistStore } from "@/stores/watchlist-store";
import ExpandableList from "@/views/components/ExpandableList.vue";
import WatchlistCategoryIcon from "@/views/watchlist/WatchlistCategoryIcon.vue";
import WatchlistCategoryIcons from "@/views/watchlist/WatchlistCategoryIcons.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WatchlistCategorySelector",

  components: { WatchlistCategoryIcon, WatchlistCategoryIcons, ExpandableList },

  props: {
    item: {
      type: WatchlistItem,
      required: true
    },
    showUnset: Boolean
  },

  emits: {
    change: (category: SymbolCategory) => category || true
  },

  expose: ["hide"],

  methods: {
    hide() {
      const expandableList = this.$refs.expandableList as typeof ExpandableList;
      expandableList.hide();
    },

    setCategory(category: SymbolCategory) {
      watchlistStore().setCategory(this.item.symbol, category);
      this.$emit("change", category);
      this.hide();
    }
  }
});
</script>

<style scoped>
</style>

import { ColorOption } from "@/anfin-chart/options/option";
import { GradientColor, RGBAColor } from "@/anfin-chart/draw/chart-color";
import { PlotType } from "@/anfin-chart/plot";
import { ChartError } from "@/anfin-chart/error";
import type { ChartOptionManager } from "@/anfin-chart/options/option-manager";

export enum PlotColorOptionName {
  Line = "line",
  MountainLine = "mountainLine",
  MountainArea = "mountainArea",
  BarAscending = "barAscending",
  BarDescending = "barDescending",
  BarBorder = "barBorder",
  CandleAscending = "candleAscending",
  CandleDescending = "candleDescending",
  CandleWick = "candleWick",
  CandleBorder = "candleBorder"
}

export class PlotColorOptions implements Record<PlotColorOptionName, ColorOption> {

  public readonly line: ColorOption;
  public readonly mountainLine: ColorOption;
  public readonly mountainArea: ColorOption;
  public readonly barAscending: ColorOption;
  public readonly barDescending: ColorOption;
  public readonly barBorder: ColorOption;
  public readonly candleAscending: ColorOption;
  public readonly candleDescending: ColorOption;
  public readonly candleBorder: ColorOption;
  public readonly candleWick: ColorOption;

  constructor(optionManager: ChartOptionManager) {
    this.line = new ColorOption(optionManager, PlotColorOptionName.Line, new RGBAColor(234, 234, 234));
    this.mountainLine = new ColorOption(optionManager, PlotColorOptionName.MountainLine, new RGBAColor(234, 234, 234));
    const mountainTop = new RGBAColor(139, 204, 215, 0.5);
    const mountainBottom = new RGBAColor(127, 164, 201, 0.5);
    const mountain = new GradientColor()
      .addStop(0, mountainTop)
      .addStop(1, mountainBottom);
    this.mountainArea = new ColorOption(optionManager, PlotColorOptionName.MountainArea, mountain);
    this.barAscending = new ColorOption(optionManager, PlotColorOptionName.BarAscending, new RGBAColor(242, 242, 242));
    this.barDescending = new ColorOption(optionManager, PlotColorOptionName.BarDescending, new RGBAColor(212, 49, 63));
    this.barBorder = new ColorOption(optionManager, PlotColorOptionName.BarBorder, new RGBAColor(242, 242, 242));
    this.candleAscending = new ColorOption(optionManager, PlotColorOptionName.CandleAscending, new RGBAColor(242, 242, 242));
    this.candleDescending = new ColorOption(optionManager, PlotColorOptionName.CandleDescending, new RGBAColor(212, 49, 63));
    this.candleBorder = new ColorOption(optionManager, PlotColorOptionName.CandleBorder, new RGBAColor(242, 242, 242));
    this.candleWick = new ColorOption(optionManager, PlotColorOptionName.CandleWick, new RGBAColor(242, 242, 242));
  }

  public getPlotColors(type: PlotType) {
    switch (type) {
      case PlotType.Line:
        return [this.line];
      case PlotType.Mountain:
        return [this.mountainLine, this.mountainArea];
      case PlotType.Bar:
        return [this.barAscending, this.barDescending, this.barBorder];
      case PlotType.SimpleBar:
        return [this.barAscending, this.barBorder];
      case PlotType.Candle:
        return [this.candleAscending, this.candleDescending, this.candleBorder, this.candleWick];
      default:
        throw new ChartError("Unknown plot type: " + type);
    }
  }
}

import type { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { ChartError } from "@/anfin-chart/error";
import type { IndicatorData } from "@/anfin-chart/indicator-data";
import { ChartOption, ColorOption, EnumOption, NumericOption, type OptionProvider } from "@/anfin-chart/options/option";
import { DataItem, Plot, type PlotProvider, ValueBaseItem } from "@/anfin-chart/plot";
import { Debouncer } from "@/anfin-chart/utils";
import { BehaviorSubject } from "rxjs";
import { OptionName } from "@/anfin-chart/options/option-manager";

enum UpdateMode {
  Bar = 0,
  Tick = 1
}

export abstract class Indicator implements PlotProvider, OptionProvider {

  private static idCounter = 0;

  public readonly optionMap = new Map<string, ChartOption<any>>();
  public readonly id = Indicator.generateId();
  public indicatorData: IndicatorData | null = null;
  public basePlot: Plot | null = null;
  public readonly plots: Plot[] = [];
  public readonly priceRanges: PriceRange[] = [];
  public readonly resetSubject = new BehaviorSubject<null>(null);

  private readonly items: DataItem[] = [];
  private updateMode = UpdateMode.Bar;
  private readonly debouncer = new Debouncer(500);
  private isSaveUpdates = false;

  private readonly lineWidth: NumericOption;

  protected constructor(public readonly type: string,
                        public readonly name: string,
                        public readonly isNewSubChart: boolean) {
    this.lineWidth = new EnumOption(this, OptionName.LineWidth, 1);
    this.lineWidth.getValueObservable().subscribe(w => {
      for (const plot of this.plots) {
        plot.lineWidth = w;
      }
    });
  }

  public get mainPlot() {
    return this.plots[0];
  }

  private get currentItem() {
    return this.items[this.items.length - 1];
  }

  private static generateId() {
    return this.idCounter++;
  }

  public getSubChart() {
    const subChart = this.indicatorData?.subChart;
    if (subChart == null) {
      throw new ChartError("Cannot access sub chart before initialization");
    }
    return subChart;
  }

  public reset() {
    this.items.splice(0);
    for (const plot of this.plots) {
      plot.clear();
    }
    this.initialize();
    const dependentIndicators = this.getDependentIndicators();
    for (const indicator of dependentIndicators) {
      indicator.reset();
    }
    this.resetSubject.next(null);
  }

  public calculate() {
    if (this.basePlot == null) {
      return;
    }
    this.reset();
    for (const item of this.basePlot.store.getItems()) {
      this.updateBar(item);
    }
  }

  public updateBar(item: DataItem) {
    this.executeUpdate(item, UpdateMode.Bar);
  }

  public updateTick(item: DataItem) {
    this.executeUpdate(item, UpdateMode.Tick);
  }

  public onOptionChange(option: ChartOption<unknown>) {
    if (option instanceof ColorOption || option === this.lineWidth) {
      this.getSubChart().chart.coreLayer.requireDraw();
    } else if (this.indicatorData != null) {
      this.indicatorData.calculate();
    }
    if (this.isSaveUpdates) {
      this.debouncer.execute(() => this.save());
    }
  }

  public enableSaving() {
    this.isSaveUpdates = true;
  }

  public save() {
    this.getSubChart().chart.callbacks.saveExport();
  }

  public getCaption() {
    let caption = this.name;
    const parameter = [] as string[];
    this.optionMap.forEach((value) => {
      if (value.getType() === 0) {
        parameter.push(value.getValue());
      }
    });
    if (parameter.length > 0) {
      caption += " (";
      caption += parameter.toString();
      caption += ")";
    }
    return caption;
  }

  protected afterConstruct() {
    this.reset();
  }

  protected updateDependent(indicator: Indicator, item: DataItem) {
    indicator.executeUpdate(item, this.updateMode);
  }

  protected updatePlot(plot: Plot, value: number, base: number | null = null, key: string | null = null) {
    const item = new ValueBaseItem(this.currentItem.time, value, base);
    item.key = key;
    switch (this.updateMode) {
      case UpdateMode.Bar:
        plot.store.insert(item);
        break;
      case UpdateMode.Tick:
        plot.store.updateLast(item);
        break;
      default:
        throw new ChartError("Unknown indicator update mode");
    }
  }

  protected getItem(offset: number, plot: Plot | null = null) {
    const modeOffset = this.updateMode === UpdateMode.Tick ? 1 : 0;
    if (plot != null) {
      const store = plot.store;
      return store.get(store.length - offset - modeOffset);
    }
    return this.items[this.items.length - 1 - offset - modeOffset] ?? null;
  }

  protected getItemRange(size: number) {
    const startIndex = Math.max(0, this.items.length - size);
    return this.items.slice(startIndex);
  }

  private executeUpdate(item: DataItem, mode: UpdateMode) {
    this.prepareUpdate(item, mode);
    this.updateInternal(item);
  }

  private prepareUpdate(item: DataItem, mode: UpdateMode) {
    if (mode === UpdateMode.Tick && this.items.length > 0) {
      this.items[this.items.length - 1] = item;
    } else {
      this.items.push(item);
    }
    this.updateMode = mode;
  }

  public abstract initialize(): void;

  protected abstract updateInternal(item: DataItem): void;

  protected abstract getDependentIndicators(): Indicator[];
}

export class PriceRange {

  constructor(public readonly from: number,
              public readonly to: number,
              public readonly lineColor: RGBAColor,
              public readonly areaColor: RGBAColor) {
  }
}


<template>
  <Transition name="slide">
    <div class="shopping-cart">
      <IconElement iconName="Cross" :size="18" class="close-button" @click="hide" />
      <div class="shopping-cart-header">
        Warenkorb
      </div>
      <div v-if="cartData == null || cartData.items.length === 0" class="mt-4">
        {{ translationStore.getTranslation("landing_page#cart#no_items") }}
      </div>
      <div v-else>
        <div v-for="item in cartData.items" class="mt-4">
          <div class="shopping-cart-item-name">
            {{ item.product.name }}
          </div>
          <div class="mt-1">
            {{ item.variant.description }}
          </div>
          <button class="btn btn-link mt-2" @click="removeItem(item)">
            Entfernen
          </button>
          <hr class="mt-3" />
        </div>
        <RouterLink to="/cart" class="btn btn-primary mt-3">
          Warenkorb anzeigen
        </RouterLink>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import IconElement from "@/views/icons/IconElement.vue";
import { paymentStore } from "@/stores/payment-store";
import { storeToRefs } from "pinia";
import type { ShoppingCartItemResolved, ShoppingCartResolved } from "@/api/models/checkout";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "ShoppingCartModal",

  components: { IconElement, RouterLink },

  expose: [],

  data() {
    const { currentCartData } = storeToRefs(paymentStore());
    return {
      translationStore: translationStore(),
      cartData: currentCartData as unknown as ShoppingCartResolved | null
    };
  },

  methods: {
    hide() {
      paymentStore().setShowCart(false);
    },

    removeItem(item: ShoppingCartItemResolved) {
      paymentStore().removeCartItem(item.product.id, item.variant.id);
    }
  }
});
</script>

<style scoped>
.shopping-cart {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 350px;
  height: 100%;
  padding: 40px 30px 20px 30px;
  background: var(--background-primary);
  color: var(--content-primary-top);
  border-left: 1px solid var(--interactive-accent);
  transition: 1s;
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.2s ease;
}

.slide-enter-from, .slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.shopping-cart-header {
  font-size: 150%;
}

.shopping-cart-item-name {
  font-weight: bold;
}
</style>
import { RestApiController } from "@/api/rest-api-controller";

export class LoggingController extends RestApiController {

  private static instance: LoggingController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new LoggingController();
    }
    return this.instance;
  }

  public async sendErrorLog(data: unknown) {
    const message = JSON.stringify(data);
    try {
      await this.sendPostRequest<unknown>("log", { message });
    } catch (e) {
      console.warn("Suppress error for log request", e);
    }
  }

  protected getBaseUrl(): string {
    return this.environment.webApplicationUrl;
  }
}

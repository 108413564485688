import type { UserRight } from "@/api/models/user-right";
import { ApiModelConverter } from "@/api/messages/converter";
import { ExchangeInquiryConverter } from "@/api/messages/exchange-inquiry";

export enum UserDeviceMode {
  Standard = "Standard",
  ApplePhone = "iOS",
  AndroidPhone = "Android-Phone",
  AppleTablet = "iOS-iPad",
  AndroidTablet = "Android-Tablet"
}

export class ExchangeInquiryItem {

  constructor(public readonly bitIndex: number,
              public readonly value: boolean) {
  }
}

export class ExchangeInquiry {

  constructor(public readonly hasBeenAnswered = false,
              public readonly isValid = false,
              public readonly items: ExchangeInquiryItem[] = []) {
  }
}

export class UserInfo {

  public mode = UserDeviceMode.Standard;
  public rights: UserRight[] = [];
  public abos: any[] = [];

  constructor(public readonly user: string,
              public readonly email: string,
              public readonly firstName: string,
              public readonly lastName: string,
              public readonly token: string,
              public readonly isAdmin: boolean,
              public exchangeInquiry = new ExchangeInquiry()) {
  }

  public get fullName() {
    return this.firstName + " " + this.lastName;
  }

  public get isSmartPhone() {
    return this.mode === UserDeviceMode.ApplePhone || this.mode === UserDeviceMode.AndroidPhone;
  }

  public get isTablet() {
    return this.mode === UserDeviceMode.AppleTablet || this.mode === UserDeviceMode.AndroidTablet;
  }
}

export interface StoredUserInfo {
  user: string;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  isAdmin: boolean;
  exchangeInquiry: number;
}

export class UserInfoConverter extends ApiModelConverter<UserInfo, StoredUserInfo> {

  private readonly inquiryConverter = new ExchangeInquiryConverter();

  public override toApiObject(userInfo: UserInfo) {
    return {
      user: userInfo.user,
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      token: userInfo.token,
      isAdmin: userInfo.isAdmin,
      exchangeInquiry: this.inquiryConverter.toApiObject(userInfo.exchangeInquiry)
    };
  }

  public override toModelObject(storedUserInfo: StoredUserInfo) {
    const exchangeInquiry = storedUserInfo.exchangeInquiry == null
      ? new ExchangeInquiry()
      : this.inquiryConverter.toModelObject(storedUserInfo.exchangeInquiry);
    return new UserInfo(
      storedUserInfo.user ?? "",
      storedUserInfo.email ?? "",
      storedUserInfo.firstName ?? "",
      storedUserInfo.lastName ?? "",
      storedUserInfo.token ?? "",
      storedUserInfo.isAdmin ?? false,
      exchangeInquiry
    );
  }
}

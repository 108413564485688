import { Timeframe } from "@/anfin-chart/time/timeframe";
import { translationStore } from "@/stores/translation-store";
import { defineComponent } from "vue";

export const MonetaryMixin = defineComponent({
  methods: {
    formatPrice(price: number | null) {
      if (price == null) {
        return "";
      }
      return "€ " + price.toFixed(2);
    },

    formatAboPrice(price: number | null, timeframe: Timeframe) {
      if (price == null) {
        return "";
      }
      const priceFormatted = this.formatPrice(price);
      const timeframeFormatted = this.formatAboPeriod(timeframe);
      return priceFormatted + " " + timeframeFormatted;
    },

    formatAboPeriod(timeframe: Timeframe) {
      const perUnitLabel = translationStore().getTranslation("checkout#perUnit");
      const unitLabel = translationStore().getTimeframe(timeframe, "long", true);
      return perUnitLabel + " " + unitLabel;
    },

    formatContractPeriod(timeframe: Timeframe, type: "long" | "short" | "point", addValue = 0) {
      let contractTimeframe = timeframe;
      if (contractTimeframe.unit === 7 && contractTimeframe.value === 1) {
        contractTimeframe = new Timeframe(contractTimeframe.unit - 1, timeframe.value * 12 + addValue, false);
      } else {
        contractTimeframe = new Timeframe(contractTimeframe.unit, timeframe.value + addValue, false);
      }
      return translationStore().getTimeframe(contractTimeframe, type, false);
    }
  }
});

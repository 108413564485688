<template>
  <LoginFormTemplate buttonLabel="login#button_login" @submit="handleSubmit">
    <div>
      <label class="form-label">
        {{ translationStore.getTranslation("login#label_email") }}
      </label>
      <input
        v-model="userName" type="email" name="email" class="form-control" autocomplete="email"
        :placeholder="translationStore.getTranslation('login#label_email')"
      />
    </div>
    <div class="mt-3">
      <label class="form-label">
        {{ translationStore.getTranslation("login#label_password") }}
      </label>
      <input
        v-model="password" type="password" name="password" class="form-control" autocomplete="current-password"
        :placeholder="translationStore.getTranslation('login#label_password')"
      />
    </div>
    <div v-if="state === LoginState.MissingData" class="mt-2 text-error">
      {{ translationStore.getTranslation("login#login_missing_data") }}
    </div>
    <div v-else-if="state === LoginState.LoginFailed" class="mt-2 text-error">
      {{ errorMessage }}
    </div>
    <div class="mt-2">
      <router-link class="link" to="/reset">
        {{ translationStore.getTranslation("login#link_reset_password") }}
      </router-link>
    </div>
  </LoginFormTemplate>
</template>

<script lang="ts">
import { AuthenticationController } from "@/api/authentication-controller";
import { userRightStore } from "@/stores/user-right-store";
import LoginFormTemplate from "@/views/login/LoginFormTemplate.vue";
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import type { LoginResult } from "@/api/models/login";

export enum LoginState {
  Initial = 0,
  MissingData = 1,
  LoginFailed = 2,
  LoginSuccess = 3
}

export default defineComponent({
  name: "LoginForm",

  components: { LoginFormTemplate },

  emits: {
    redirect: () => true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      userName: "",
      password: "",
      state: LoginState.Initial as LoginState,
      loginResult: null as LoginResult | null,
      LoginState
    };
  },

  computed: {
    errorMessage() {
      if (this.loginResult == null) {
        return null;
      }
      const errorKey = this.loginResult.errorMessage;
      const translation = errorKey == null ? null : this.translationStore.getTranslation(errorKey);
      if (translation != null && translation !== "") {
        return translation;
      }
      return this.translationStore.getTranslation("login#login_failed");
    }
  },

  methods: {
    async handleSubmit() {
      this.state = LoginState.Initial;
      if (this.userName === "" || this.password === "") {
        this.state = LoginState.MissingData;
        return;
      }
      this.loginResult = await AuthenticationController.getInstance().sendLogin(this.userName, this.password);
      if (this.loginResult.userInfo == null) {
        this.state = LoginState.LoginFailed;
      } else if (this.loginResult.resetPasswordToken == null) {
        userRightStore().setUserInfo(this.loginResult.userInfo);
        this.$emit("redirect");
      } else {
        this.$router.push("/reset?token=" + this.loginResult.resetPasswordToken).catch(() => {});
      }
    }
  }
});
</script>

<style scoped>
</style>

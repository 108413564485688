<template>
  <div class="alert-buttons">
    <IconElement
      v-if="alert.state === AlertState.Inactive || alert.state === AlertState.Triggered" :size="14"
      iconName="Reload" @click="activateAlert"
    />
    <IconElement v-else :size="14" iconName="Pause" @click="pauseAlert" />
    <IconElement :size="14" iconName="Pencil" @click="editAlert" />
    <IconElement :size="14" iconName="Cross" @click="openDeleteDialog" />
    <ConfirmationModalDialog
      ref="deleteAlertDialog" question="alert_overview#delete#question" labelOk="button_delete"
      titleDialog="alert_overview#delete#title" @pressedOk="deleteAlert"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconElement from "@/views/icons/IconElement.vue";
import { Alert, AlertState } from "@/api/models/alert";
import { alertStore } from "@/stores/alert-store";
import { uiStateStore } from "@/stores/ui-state-store";
import ConfirmationModalDialog from "@/views/dialogs/ConfirmationModalDialog.vue";

export default defineComponent({
  name: "AlertButtons",

  components: { ConfirmationModalDialog, IconElement },

  props: {
    alert: {
      type: Alert,
      required: true
    }
  },

  emits: {
    edit: () => true,
    delete: () => true
  },

  expose: [],

  data() {
    return {
      AlertState
    };
  },

  methods: {
    activateAlert() {
      alertStore().setAlertState(this.alert, AlertState.Pending);
    },

    pauseAlert() {
      alertStore().setAlertState(this.alert, AlertState.Inactive);
    },

    editAlert() {
      uiStateStore().setEditedAlert(this.alert);
      this.$emit("edit");
    },

    openDeleteDialog() {
      const dialog = (this.$refs.deleteAlertDialog as typeof ConfirmationModalDialog);
      dialog.show();
    },

    deleteAlert() {
      alertStore().deleteAlert(this.alert);
      this.$emit("delete");
    }
  }
});
</script>

<style scoped>
.alert-buttons  {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
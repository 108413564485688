<template>
  <div class="product-image-carousel-container">
    <div :id="id" class="product-image-carousel carousel slide" data-bs-interval="false">
      <div v-if="product.images.length > 1" class="carousel-indicators">
        <button
          v-for="(image, imageIndex) in product.images" type="button"
          data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="imageIndex"
          :class="{ active: imageIndex === 0 }"
        ></button>
      </div>
      <div class="carousel-inner" @click="onClick">
        <div v-for="(image, imageIndex) in product.images" class="carousel-item" :class="{ active: imageIndex === 0 }">
          <img :src="image" class="d-block w-100" alt="" />
        </div>
        <div v-if="product.images.length === 0" class="carousel-item active">
          <img src="/src/assets/chart-w600.jpg" class="d-block w-100" alt="" />
        </div>
      </div>
      <button v-if="product.images.length > 1" class="carousel-control-prev" type="button" :data-bs-target="'#' + id" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button v-if="product.images.length > 1" class="carousel-control-next" type="button" :data-bs-target="'#' + id" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ShoppingProduct } from "@/api/models/shopping-cart";

let idCounter = 0;

export default defineComponent({
  name: "ProductImageCarousel",

  props: {
    product: {
      type: ShoppingProduct,
      required: true
    }
  },

  emits: {
    imageClick: () => true
  },

  expose: [],

  data() {
    return {
      id: "product-item-carousel-" + idCounter++
    };
  },

  methods: {
    onClick() {
      this.$emit("imageClick");
    }
  }
});
</script>

<style scoped>
.product-image-carousel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 200px;
  max-width: 100%;
}

.product-image-carousel {
  box-shadow: 0 0 4px 1px var(--background-overlay);
}

.product-image-carousel .carousel-indicators {
  margin-bottom: 0.5rem;
}

.product-image-carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
import type { DragData, Draggable } from "@/anfin-chart/interactions";
import type { ChartOption } from "@/anfin-chart/options/option";
import { Point, Vector } from "@/anfin-chart/geometry";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { ChartTool } from "@/anfin-chart/tools/chart-tool";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { FreehandDrawTool } from "@/anfin-chart/tools/user-tools/fhdraw";
import type { FixedPoint } from "@/anfin-chart/tools/tool-point";
import { Selectable, Selection } from "@/anfin-chart/selection";
import { AlertDefinitionData, AlertToolData } from "@/anfin-chart/tools/alert-tool";
import { Alert, AlertRule, AlertRuleDefinition, AlertUserToolDefinition } from "@/api/models/alert";
import { userRightStore } from "@/stores/user-right-store";
import { Drawable, IconElement } from "@/anfin-chart/drawable";
import { UserRightKey } from "@/api/models/user-right";
import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import type { Consumer } from "@/anfin-chart/utils";

export class UserTool extends ChartTool implements Draggable {

  public isVisible = true;
  public dragOffset = new Vector(0, 0);

  private isInitialized = false;
  private isBlurNewDrawables = false;

  constructor(public definition: UserToolDefinition, subChart: SubChart) {
    super(definition.instrument, definition.timeframe, subChart);
    this.subscribeOptions();
  }

  public get optionMap() {
    return this.definition.optionMap;
  }

  public get type() {
    return this.definition.type;
  }

  public get currentPoint() {
    const points = this.definition.fixedPoints;
    return points[points.length - 1];
  }

  public onDrag(data: DragData) {
    if (!this.isEditable()) {
      return;
    }
    const timeAxis = this.chart.timeAxis;
    const points = this.definition.fixedPoints;
    const basePosition = this.getPosition(points[0]);
    const updates: { time: number, price: number }[] = [];
    for (const point of points) {
      const position = this.getPosition(point);
      const x = data.position.x - this.dragOffset.x - basePosition.x + position.x;
      const y = data.position.y - this.dragOffset.y - basePosition.y + position.y;
      const index = timeAxis.getIndexForX(x);
      const time = timeAxis.getTime(Math.round(index));
      const price = this.subChart.priceAxis.getPrice(y);
      if (time == null) {
        return;
      }
      updates.push({ time, price });
    }
    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      const update = updates[i];
      point.update(update.time, update.price);
    }
    this.updateDrawables();
  }

  public onDragEnd() {
    if (this.isEditable()) {
      this.save();
    }
  }

  public onOptionChange(option: ChartOption<unknown>) {
    this.updateDrawables();
    if (this.isInitialized) {
      this.save();
    }
  }

  public override isSelectionAllowed() {
    return this.isEditable();
  }

  public override addDrawable(drawable: Drawable) {
    super.addDrawable(drawable);
    if (this.isBlurNewDrawables) {
      drawable.setIsBlurred(true);
    }
  }

  public override updateDrawables() {
    super.updateDrawables();
    for (const alertTool of this.chart.getAlertTools()) {
      alertTool.updatePosition();
    }
  }

  public setDefinition(definition: UserToolDefinition) {
    if (this.definition !== definition) {
      this.subscriptionManager.unsubscribe(this.definition);
      this.definition = definition;
      this.subscribeOptions();
    }
    this.resetDrawables();
  }

  public subscribeOptions() {
    const observable = this.definition.getOptionChangeObservable();
    this.subscriptionManager.subscribe(observable, option => this.onOptionChange(option), this.definition);
  }

  public finishInitialization() {
    this.isInitialized = true;
    this.save();
  }

  public override getIsVisible() {
    const showUserTools = this.chart.optionManager.showUserTools.getValue();
    const showPublicTools = this.chart.optionManager.showPublicTools.getValue();
    if (!this.isVisible || !showUserTools || !showPublicTools && this.definition.isPublic) {
      return false;
    }
    const firstTime = this.chart.timeAxis.getTime(0);
    return firstTime != null && this.definition.fixedPoints.every(p => p.time >= firstTime);
  }

  public setDragOffset(position: Point) {
    const basePosition = this.getPosition(this.definition.fixedPoints[0]);
    const offsetX = position.x - basePosition.x;
    const offsetY = position.y - basePosition.y;
    this.dragOffset = new Vector(offsetX, offsetY);
  }

  public createPoint() {
    const fixedPoint = this.definition.createPoint(Date.now(), 0);
    this.moveToMousePosition(fixedPoint);
    if (this.definition instanceof FreehandDrawTool) {
      const position = this.chart.mouseData.getPosition();
      this.definition.tracePoint(this, position);
    }
    this.resetDrawables();
  }

  public removeLastPoint() {
    this.definition.fixedPoints.pop();
    this.resetDrawables();
  }

  public moveToMousePosition(point: FixedPoint) {
    const snapPosition = this.chart.mouseData.getSnapPosition(this.subChart);
    if (snapPosition != null) {
      point.update(snapPosition.time, snapPosition.price);
      this.updateFixedPoint(point);
    }
    this.updateDrawables();
  }

  public select() {
    const selectable = new Selectable<Draggable>(this);
    const position = this.getPosition(this.currentPoint);
    const selection = new Selection(selectable, position);
    this.chart.dragHandler.setSelections([selection]);
  }

  public save() {
    this.chart.callbacks.saveUserTool(this.definition);
  }

  public delete() {
    this.chart.onUserToolRemoved(this);
    if (this.definition.id != null) {
      this.chart.callbacks.deleteUserTool(this.definition);
    }
    this.onDelete();
  }

  public getLineWidth(withHover: boolean) {
    let width: number = this.definition.lineWidth == null ? 1 : this.definition.lineWidth.getValue();
    // TODO discuss with Hamed - only mouseover - Hamed check the new one
    // if ((this.isHovered || this.chart.editedObject === this) && withHover) {
    if (this.isHovered && withHover) {
      width += 2 * window.devicePixelRatio;
    }
    return width;
  }

  public withAtLeastPoints(count: number, action: Consumer<void>) {
    const pointCount = this.definition.fixedPoints.length;
    if (pointCount >= count) {
      this.isBlurNewDrawables = !this.isInitialized && this.definition.fixedPoints.length === count;
      action();
      this.isBlurNewDrawables = false;
    }
  }

  public withExactPoints(count: number, action: Consumer<void>) {
    const pointCount = this.definition.fixedPoints.length;
    if (pointCount === count) {
      this.isBlurNewDrawables = !this.isInitialized;
      action();
      this.isBlurNewDrawables = false;
    }
  }

  protected override createDrawables() {
    this.definition.createDrawables(this);
    if (this.definition.isPublic) {
      const iconPoint = this.definition.fixedPoints[this.definition.fixedPoints.length - 1];
      const publicIcon = new IconElement(this, iconPoint, "World");
      this.onUpdate(() => {
        const size = this.chart.styleOptions.globalToolIconSize.getValue();
        publicIcon.size = size;
        publicIcon.color = this.chart.optionManager.chartColor.globalToolIcon.getValue();
        const offset = size / 2 * Math.sqrt(2);
        publicIcon.alignment = new TextAlignment(new Vector(1, 1), offset);
      });
    }
  }

  protected override updatePositionInternal() {
    this.updateFixedPoint(...this.definition.fixedPoints);
    this.definition.updatePosition(this);
  }

  protected override createAlertData(alert: Alert, rule: AlertRule, definition: AlertRuleDefinition) {
    if (definition instanceof AlertUserToolDefinition && definition.toolId === this.definition.id) {
      const definitionData = new AlertDefinitionData(alert, rule, definition);
      this.alertDatas.push(new AlertToolData(this, definitionData));
    }
  }

  protected override createAlertDrawables(data: AlertToolData, index: number) {
    const nextData = this.alertDatas[index + 1];
    data.createLine(nextData);
    this.onUpdate(() => {
      for (const polygon of data.polygons) {
        polygon.setIsVisible(this.isHovered);
      }
    });
    this.definition.createAlertDrawables(data);
  }

  protected override updateAlertPosition(data: AlertToolData) {
    const hook = this.definition.hookProvider.getHook(data.data.definition as AlertUserToolDefinition);
    if (hook != null && data.icon != null) {
      const position = this.getPosition(hook.anchorPoint);
      const iconSize = data.icon.size / 2;
      const offset = iconSize + this.chart.styleOptions.alertIconMargin.getValue() * 2;
      const iconPosition = new Point(position.x, position.y + offset);
      this.updatePoint(data.iconCenter, iconPosition);
    }
    this.definition.updateAlertPosition(data);
  }

  private isEditable() {
    return !this.definition.isPublic || userRightStore().isAdmin && userRightStore().hasRight(UserRightKey.PublishTool);
  }
}


import { Instrument } from "@/anfin-chart/instrument";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartOptionConverter, type ChartOptionResponse } from "@/api/messages/chart-option";
import { ApiModelConverter } from "@/api/messages/converter";
import { ChartObjectPreset } from "@/api/models/user-settings/chart";
import { UserToolRegistry } from "@/anfin-chart/registry";
import { applyOptions, getOptionDefinitions } from "@/anfin-chart/options/option";
import { FixedPoint } from "@/anfin-chart/tools/tool-point";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { UserToolRelatedIndicator } from "@/anfin-chart/tools/user-tool-definition";

export interface PresetResponse {
  id: number | null;
  name: string;
  type: string;
  options: ChartOptionResponse[];
}

export interface UserToolResponseList {
  userTools: UserToolResponse[];
  publishedTimeframes: string[];
}

export interface UserToolResponse {
  id: number | null;
  type: string;
  symbol: string;
  timeframe: string;
  isPublic: boolean | null;
  changeDate: number | null;
  subChartInfo: RelatedIndicatorResponse[];
  points: ToolPointResponse[];
  options: ChartOptionResponse[];
}

export interface RelatedIndicatorResponse {
  type: string;
}

export interface ToolPointResponse {
  time: number;
  price: number;
}

export class PresetConverter extends ApiModelConverter<ChartObjectPreset, PresetResponse> {

  private readonly optionConverter = new ChartOptionConverter();

  public override toApiObject(chartObject: ChartObjectPreset) {
    return {
      id: chartObject.id,
      name: chartObject.name,
      type: chartObject.type,
      options: chartObject.options.map(o => this.optionConverter.toApiObject(o))
    };
  }

  public override toModelObject(chartObject: PresetResponse) {
    return new ChartObjectPreset(
      chartObject.id,
      chartObject.name,
      chartObject.type,
      chartObject.options.map(o => this.optionConverter.toModelObject(o))
    );
  }
}

export class UserToolConverter extends ApiModelConverter<UserToolDefinition, UserToolResponse> {

  private readonly optionConverter = new ChartOptionConverter();
  private readonly pointConverter = new UserToolPointConverter();
  private readonly relatedIndicatorConverter = new RelatedIndicatorConverter();

  public override toApiObject(tool: UserToolDefinition) {
    return {
      id: tool.id,
      type: tool.type,
      symbol: tool.instrument.getSymbol(),
      timeframe: tool.timeframe.toShortNotation(),
      changeDate: tool.changeDate,
      isPublic: tool.isPublic,
      subChartInfo: [],
      points: tool.fixedPoints.map(p => this.pointConverter.toApiObject(p)),
      options: getOptionDefinitions(tool).map(o => this.optionConverter.toApiObject(o)),
      // TODO: remove subChartIndex, apply related indicators
      // subChartInfo: tool.relatedIndicators.map(i => this.relatedIndicatorConverter.toApiObject(i)),
      subChartIndex: 0
    };
  }

  public override toModelObject(response: UserToolResponse) {
    const instrument = Instrument.fromSymbol(response.symbol);
    const timeframe = Timeframe.fromShortNotation(response.timeframe);
    const toolDefinition = UserToolRegistry.create(response.type, instrument, timeframe);
    const options = response.options.map(o => this.optionConverter.toModelObject(o));
    toolDefinition.id = response.id;
    toolDefinition.changeDate = response.changeDate ?? Date.now();
    toolDefinition.isPublic = response.isPublic ?? false;
    applyOptions(toolDefinition, options);
    const points = response.points.map(p => this.pointConverter.toModelObject(p));
    toolDefinition.fixedPoints.push(...points);
    // TODO: apply related indicators
    // const subChartInfo = response.subChartInfo ?? [];
    // toolDefinition.relatedIndicators = subChartInfo.map(i => this.relatedIndicatorConverter.toModelObject(i));
    return toolDefinition;
  }
}

export class UserToolPointConverter extends ApiModelConverter<FixedPoint, ToolPointResponse> {

  public override toApiObject(obj: FixedPoint) {
    return {
      time: obj.time,
      price: obj.price
    };
  }

  public override toModelObject(response: ToolPointResponse) {
    return new FixedPoint(response.time, response.price);
  }
}

export class RelatedIndicatorConverter extends ApiModelConverter<UserToolRelatedIndicator, RelatedIndicatorResponse> {

  public override toApiObject(relatedIndicator: UserToolRelatedIndicator) {
    return {
      type: relatedIndicator.type
    };
  }

  public override toModelObject(response: RelatedIndicatorResponse) {
    return new UserToolRelatedIndicator(response.type);
  }
}

export class FAQCategory {

  public readonly questions: FAQQuestion[];

  constructor(public readonly caption: string,
              ...questions: FAQQuestion[]) {
    this.questions = questions;
  }
}

export class FAQQuestion {

  public readonly answerLines: FAQAnswerLine[];

  constructor(public readonly text: string,
              ...answerLines: FAQAnswerLine[]) {
    this.answerLines = answerLines;
  }
}

export class FAQAnswerLine {

  public readonly parts: FAQAnswerPart[];

  constructor(...parts: FAQAnswerPart[]) {
    this.parts = parts;
  }
}

export enum FAQContentType {
  PlainText = 0,
  Link = 1
}

export class FAQAnswerPart {

  constructor(public readonly type: FAQContentType,
              public readonly content: string,
              public readonly contentSecondary?: string) {
  }
}

export const faqCategories = [
  new FAQCategory(
    "Allgemeine Informationen",
    new FAQQuestion(
      "Wie unterscheidet sich AnFins Chartsoftware von denen der Konkurrenz?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Im Vergleich zu Konkurrenzprodukten setzt AnFin mit der Chartsoftware neue Maßstäbe - auch in " +
          "Sachen Handhabung und Anwendung. Die jahrzehntelange Erfahrung des AnFin-Teams, die tägliche Nutzung " +
          "von diversen Trading-Plattformen sowie das Feedback und die Bedürfnisse von angehenden Tradern sind in " +
          "die Erstellung der Chartsoftware eingeflossen."
        )
      ),
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Basierend auf dieser Expertise wurde eine bisher einzigartige Möglichkeit geschaffen, Trader bei " +
            "ihrer Analyse automatisiert zu unterstützen."
        )
      ),
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Mithilfe aufwendiger Testverfahren und kontinuierlicher Anpassungen, gelang es, entscheidende Vorteile " +
          "gegenüber bisher etablierten Anbietern zu genieren."
        )
      )
    ),
    new FAQQuestion(
      "Welche unterschiedlichen Produkte kann ich von AnFin buchen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Derzeit bieten wir drei verschiedene Produkte an. Das Produkt AnFin Basic bietet den Zugang " +
            "zur Chartsoftware, ohne automatisierte Chart-Unterstützung, während AnFin Pro diese enthält. Mit " +
            "AnFin Ultra haben sie neben den Vorzügen von AnFin Pro auch Zugriff auf sämtliche Annehmlichkeiten " +
            "von Formationstrader+."
        )
      ),
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Weitere Details zu den Produkten finden Sie "
        ),
        new FAQAnswerPart(
          FAQContentType.Link,
          "im AnFin Shop.",
          "/shop"
        )
      )
    ),
    new FAQQuestion(
      "Welche Zahlungsmethoden akzeptieren Sie?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Derzeit ist die Zahlung via Kreditkarte und SEPA-Lastschriftverfahren sowie PayPal möglich. " +
            "Perspektivisch sollen auch noch weitere Zahlungsmethoden angebunden werden."
        )
      )
    ),
    new FAQQuestion(
      "Kann ich mein Abo jederzeit kündigen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Die Kündigung eines Abos ist zum Ende der gebuchten Mindestlaufzeit möglich. Nach Ablauf der " +
            "Mindestlaufzeit ist eine Kündigung mit einem Vorlauf von einem Monat möglich. Weitere Details finden Sie "
        ),
        new FAQAnswerPart(
          FAQContentType.Link,
          "in den AGB.",
          "/agb"
        )
      )
    ),
    new FAQQuestion(
      "Wo können Service-Pakete gebucht werden?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Unsere Angebote können Sie direkt auf unserer Homepage oder in der Chartverwaltung buchen."
        )
      )
    ),
    new FAQQuestion(
      "Sind meine Daten bei Ihnen sicher?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Ihre Daten werden gemäß europäischem Datenschutz sicher gespeichert. Gerne verweisen wir auf die " +
            "detaillierten Informationen in unserer "
        ),
        new FAQAnswerPart(
          FAQContentType.Link,
          "Datenschutz-Seite.",
          "/privacy-policy"
        )
      )
    )
  ),
  new FAQCategory(
    "Installation und Einrichtung",
    new FAQQuestion(
      "Muss ich für die Nutzung etwas installieren?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Für die Nutzung der Chartsoftware ist keine Installation notwendig. Die Software funktioniert " +
            "webbasiert."
        )
      )
    ),
    new FAQQuestion(
      "Gibt es einen bevorzugten Browser, den ich nutzen sollte?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Die Chartsoftware sollte mit allen gängigen, modernen Browsern funktionieren. Wichtig ist, " +
            "dass Ihr Browser einen aktuellen Softwareversionsstand hat."
        )
      )
    ),
    new FAQQuestion(
      "Kann ich mein Abo upgraden oder downgraden, je nachdem, welche Features ich benötige?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Ja. Je nach Bedarf kann zwischen den einzelnen Produkten auf- bzw. abgestuft werden."
        )
      )
    ),
    new FAQQuestion(
      "Wo sehe ich, wie lange mein(e) Abo(s) noch laufen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "In der Übersichtsseite, die Sie mit einem Klick auf das Account-Symbol ganz oben rechts " +
            "erreichen, sehen Sie ihre gebuchten Produkte."
        )
      )
    )
  ),
  new FAQCategory(
    "Daten und Datenquellen",
    new FAQQuestion(
      "Wie aktuell sind die Börsendaten, die die Chartsoftware anzeigt?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Alle Charts zeigen Live-Daten an. Je nach Assetklasse reicht die historische Kursdarstellung " +
            "zurück bis ins Jahr 1990."
        )
      )
    ),
    new FAQQuestion(
      "Welche Börsenplätze oder Märkte deckt die Chartsoftware ab?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Alle Kursdaten zeigen die Daten von Cboe. Historische Kursdaten stammen von unterschiedlichen " +
            "Anbietern und repräsentieren die Kursdaten fast gänzlich ohne Abweichungen zu den Heimatbörsen."
        )
      )
    ),
    new FAQQuestion(
      "Wie schützen Sie die Kursdaten vor Manipulation oder Fehlinformationen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Da die Kurse direkt vom hoch angesehenen Anbieter Cboe stammen, sind sie sehr verlässlich. " +
            "Nichtsdestotrotz kann auch bei größter Sorgfalt nicht ausgeschlossen werden, dass es zu möglichen " +
            "„Bad-Ticks“ kommt. Damit sind Kursdarstellungen gemeint, die für kurze Zeit sehr deutlich von der " +
            "eigentlichen Notierung abweichen und in der Realität nicht stattgefunden haben. Darüber hinaus kann " +
            "es vorkommen, dass nicht alle Aktien um ihre historischen Splits bereinigt sind."
        )
      )
    ),
    new FAQQuestion(
      "Sind die angezeigten Kursdaten live? Woran sehe ich ggf., wie verzögert die Daten sind?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Ja. In aller Regel sind die Kursdaten live. Dies ist z.B. ersichtlich wenn Sie den Cursor auf " +
            "die aktuelle Kerze in einer kleinen Zeiteinheit halten. Auf der X-Achse können Sie so die Zeit der " +
            "aktuellsten Kerze ablesen."
        )
      )
    ),
    new FAQQuestion(
      "Wird die Chartsoftware regelmäßig aktualisiert, um neue Features oder technische Indikatoren hinzuzufügen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Wir wollen die Plattform stets optimieren und weiterentwickeln. Gerne informieren wir auf unserer " +
            "Homepage und in der Chartsoftware selbst, wenn es hierzu Neuigkeiten gibt. Falls Sie selbst Anregungen " +
            "und / oder Kritik äußern möchten, zögern Sie bitte nicht das über "
        ),
        new FAQAnswerPart(
          FAQContentType.Link,
          "unseren Klientensupport",
          "/contact"
        ),
        new FAQAnswerPart(
          FAQContentType.PlainText,
          " zu tun."
        )
      )
    ),
    new FAQQuestion(
      "(Wie) werden fundamentale Daten oder Nachrichtenereignisse in der Chartsoftware dargestellt?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Derzeit werden ausschließlich Informationen bzgl. charttechnischer Komponenten dargestellt. " +
            "Perspektivisch werden auch fundamentale Ereignisse wie z.B. Quartalsergebnisse von Unternehmen " +
            "dargestellt."
        )
      )
    )
  ),
  new FAQCategory(
    "Funktionalität und Anwendung",
    new FAQQuestion(
      "Welche technischen Indikatoren und Oszillatoren bietet die Chartsoftware an?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Derzeit sind mehr als 20 beliebte Indikatoren bzw. Oszillatoren eingebunden. Wir arbeiten " +
            "konstant daran, diese Zahl zu vergrößern."
        )
      )
    ),
    new FAQQuestion(
      "Gibt es Einschränkungen bezüglich der Anzahl von Abfragen oder der Menge an Daten, die ich nutzen kann?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Nein. Anders als bei diversen anderen Anbietern, können Sie beispielsweise beliebig viele Charts " +
            "analysieren und speichern und brauchen hierfür kein kostenpflichtiges Abonnement."
        )
      )
    ),
    new FAQQuestion(
      "Kann ich in der Chartsoftware eigene Indikatoren oder Algorithmen integrieren?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Derzeit nicht. Wir arbeiten mit Hochdruck daran, weitere Indikatoren und Oszillatoren in den " +
            "Bestand der Chartsoftware aufzunehmen. Falls Ihr gewünschter Indikator noch nicht verfügbar ist, wenden " +
            "Sie sich gerne an unseren Klientensupport."
        )
      )
    ),
    new FAQQuestion(
      "Unterstützt die Chartsoftware Mehrfach-Zeitrahmen-Analysen? (Mehrere Zeiteinheiten)",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Ja. Sie können die Chartsoftware in mehreren Zeiteinheiten gleichzeitig öffnen. Und das Beste: " +
            "Ihr gespeichertes Layout öffnet sich automatisch und passt sich Ihrem Arbeitsplatz an."
        )
      )
    ),
    new FAQQuestion(
      "Kann ich mehrere Charts gleichzeitig in verschiedenen Fenstern oder Tabs anzeigen lassen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Ja. Mit einem eigenen Symbol können sie die Verknüpfung der Charts lösen, sodass sie z.B. auf " +
            "mehreren Bildschirmen, unterschiedliche Charts analysieren können."
        )
      )
    ),
    new FAQQuestion(
      "Wie viele Charttypen (z.B. Kerzen, Balken, Linie) sind in der Software verfügbar?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Derzeit ist nur der Modus für Kerzencharts verfügbar. Perspektivisch werden auch andere " +
            "Darstellungstypen wie Balken- und Liniencharts verfügbar sein."
        )
      )
    ),
    new FAQQuestion(
      "Kann ich in der Chartsoftware eigene Zeichnungen zu den Charts tätigen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Ja, das ist möglich. Ihre selbstgezeichneten Chart-Elemente sind völlig unabhängig von den " +
            "automatisch gezeichneten Elementen."
        )
      )
    ),
    new FAQQuestion(
      "Kann ich in der Chartsoftware Alarme für bestimmte Preisniveaus oder technische Signale einstellen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Das Setzen von Alarmen ist aktuell noch nicht möglich. Wir sind gerade dabei, diese umzusetzen. " +
            "Klassische Kursalarme werden zeitnah verfügbar sein."
        )
      ),
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Wir streben zudem die Revolution der Kursalarme an: In der weiteren Entwicklung wird es aller " +
            "Voraussicht nach möglich sein, auch bei Linienbrüchen, Erreichen von Fibo-Marken und " +
            "Formationsausbrüchen per Alarm informiert zu werden."
        )
      )
    ),
    new FAQQuestion(
      "Bietet die Chartsoftware auch eine Backtesting-Funktion für Handelsstrategien?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Dies ist derzeit nicht möglich. Eine Replay-Funktion, bei der der historische Kurs abgespielt " +
            "werden kann, soll perspektivisch ermöglicht werden. Klassische Backtesting-Möglichkeiten für eigene " +
            "Handelsstrategien sind nicht geplant."
        )
      )
    ),
    new FAQQuestion(
      "Gibt es eine Funktion für automatisierten Handel oder Schnittstellen zu Brokern in der Chartsoftware?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Dies ist derzeit nicht möglich. Auch hier wird es perspektivisch eine Anbindung geben. Gerne " +
            "informieren wir hier auf unserer Homepage, sobald dies möglich ist."
        )
      )
    ),
    new FAQQuestion(
      "Gibt es eine Möglichkeit, in der Chartsoftware direkt Trades zu platzieren oder Orders zu senden?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Dies ist derzeit nicht möglich. Auch hier wird es diese Möglichkeit perspektivisch geben. Gerne " +
            "informieren wir hier auf unserer Homepage, sobald dies möglich ist."
        )
      )
    )
  ),
  new FAQCategory(
    "Personalisierung und Anpassung",
    new FAQQuestion(
      "Wie flexibel sind meine Möglichkeiten für das Anlegen von Watchlisten?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "In der Chartsoftware können Sie unbegrenzt viele Watchlisten anlegen."
        )
      )
    ),
    new FAQQuestion(
      "Gibt es Chart-Vorlagen und Templates? Falls ja, wie flexibel bin ich in der Nutzung?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Derzeit gibt es keine zur Verfügung gestellte Chart-Vorlagen. Sie können aber jederzeit nach " +
            "eigenen Belieben den Chart einrichten und als eigene Vorlage abspeichern."
        )
      )
    ),
    new FAQQuestion(
      "Kann ich meine bevorzugten Einstellungen in der Chartsoftware speichern und später wieder aufrufen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Ja, dies ist ganz bequem möglich."
        )
      )
    ),
    new FAQQuestion(
      "Ich möchte meine Zahlungsart ändern oder eine andere Kreditkarte hinterlegen. Wie mache ich das?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Dies ist über Ihre Account-Einstellungen jederzeit möglich. Bitte beachten Sie, dass eine " +
            "solche Umstellung einige Tage in Anspruch nehmen kann. Falls Ihre nächste Abbuchung also " +
            "unmittelbar bevorsteht, kann es sein, dass die alte Zahlungsmethode belastet wird."
        )
      )
    ),
    new FAQQuestion(
      "Kann ich meine E-Mail-Adresse ändern? Falls ja, wie?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Dies ist über Ihre Account-Einstellungen jederzeit möglich."
        )
      )
    ),
    new FAQQuestion(
      "Ich habe mein Passwort vergessen. Wie gehe ich vor?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Über das Login-Feld „Passwort vergessen“ wird Ihnen ein Link zum Ändern des Passworts an " +
            "die mit Ihrem Account verknüpfte Email-Adresse versandt."
        )
      )
    ),
    new FAQQuestion(
      "Wo kann ich meine Rechnungen einsehen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Dies ist über Ihre Account-Einstellungen jederzeit möglich."
        )
      )
    ),
    new FAQQuestion(
      "Ich sitze im Ausland und brauche eine Rechnung ohne Ausweisung der Steuer. Ist das möglich?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Bitte wenden Sie sich in diesem Fall an unseren Klienten-Support. Dort können wir Ihnen " +
            "individuell auf Ihre Wünsche eingehen."
        )
      )
    ),
    new FAQQuestion(
      "Wie kann ich mein Abo kündigen?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Dies ist über Ihre Account-Einstellungen jederzeit möglich."
        )
      )
    )
  ),
  new FAQCategory(
    "Integration und Kompatibilität",
    new FAQQuestion(
      "Kann ich mein Portfolio in Ihrem Produkt integrieren oder verwalten?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Sie können die Werte wie z.B. Aktien als Kursliste in der Chartsoftware anlegen. So haben Sie " +
            "einen kompakten Überblick über die Kursverläufe Ihrer Investitionen. Eine Portfolio-Replikation " +
            "samt monetärer Gewichtung oder Musterdepots sind in der Chartsoftware nicht möglich."
        )
      )
    ),
    new FAQQuestion(
      "Unterstützt die Chartsoftware den Import und Export von Daten?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Import und Export von Listen ist als CSV-Datei möglich. Dies ist über die Marktübersicht bei der " +
            "Listenansicht möglich."
        )
      )
    ),
    new FAQQuestion(
      "Gibt es eine mobile App für Ihr Produkt, und wenn ja, auf welchen Geräten ist sie verfügbar?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Noch nicht. Wir sind in den letzten Entwicklungsphasen und testen sie bereits. Sie wird für " +
            "Android und iOS verfügbar sein. Einen konkreten Zeitpunkt für die Bereitstellung können wir derzeit " +
            "nicht sicher sagen. Unsere Entwickler arbeiten mit Hochdruck und wir gehen davon aus, dass es nicht " +
            "mehr lange dauern wird."
        )
      )
    )
  ),
  new FAQCategory(
    "Community und Ressourcen",
    new FAQQuestion(
      "Wie kann ich Meinungen oder Feedback zu Ihrem Produkt abgeben?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Feedback und Meinungen sowie Verbesserungswünsche sind jederzeit bei "
        ),
        new FAQAnswerPart(
          FAQContentType.Link,
          "unserem Klientensupport",
          "/contact"
        ),
        new FAQAnswerPart(
          FAQContentType.PlainText,
          " willkommen."
        )
      )
    ),
    new FAQQuestion(
      "Gibt es eine Community oder einen Marktplatz, um Indikatoren, Skripte oder Strategien von anderen " +
        "Nutzern zu teilen oder zu erwerben?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Eine Community ist derzeit nicht geplant. Gerne können Sie aber mithilfe der Vielzahl an " +
            "bereitgestellten Indikatoren und Oszillatoren eigene Handelsstrategien entwerfen und verfolgen. " +
            "Darüber hinaus unterstützen wir Sie mit den buchbaren Dienstleistungen der Chartsoftware. Genauere " +
            "Details zum Angebot finden Sie "
        ),
        new FAQAnswerPart(
          FAQContentType.Link,
          "in unserem Shop.",
          "/shop"
        )
      )
    )
  ),
  new FAQCategory(
    "Technischer Support und Fehlerbehebung",
    new FAQQuestion(
      "Welchen Kundensupport bieten Sie an?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Sie erreichen unseren Kundensupport via Mail unter: "
        ),
        new FAQAnswerPart(
          FAQContentType.Link,
          "kontakt@anfin.com",
          "mailto:kontakt@anfin.com"
        )
      )
    ),
    new FAQQuestion(
      "Wie gehe ich vor, wenn die Chartsoftware abstürzt oder nicht wie erwartet funktioniert?",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Bitte überprüfen Sie zunächst, ob Sie die aktuellste Browser-Version installiert haben. Prüfen " +
            "Sie bitte auch Ihren PC/Laptop auf Systemupdates. In den meisten Fällen sollte das Problem damit " +
            "gelöst werden. Falls es weiter bestehen sollte, wenden Sie sich sehr gerne an "
        ),
        new FAQAnswerPart(
          FAQContentType.Link,
          "unseren Klienten-Support.",
          "/contact"
        )
      )
    )
  ),
  new FAQCategory(
    "Anwendung Chartsoftware",
    new FAQQuestion(
      "",
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Spezifische Fragen und deren Antworten hinsichtlich der Anwendung der Chartsoftware werden in " +
            "Kürze hier bekanntgegeben."
        )
      ),
      new FAQAnswerLine(
        new FAQAnswerPart(
          FAQContentType.PlainText,
          "Falls wir Sie bei diesem Thema unterstützen können, zögern Sie bitte nicht, uns unter "
        ),
        new FAQAnswerPart(
          FAQContentType.Link,
          "kontakt@anfin.com",
          "mailto:kontakt@anfin.com"
        ),
        new FAQAnswerPart(
          FAQContentType.PlainText,
          " zu kontaktieren."
        )
      )
    )
  )
];

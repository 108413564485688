import { RestApiController } from "@/api/rest-api-controller";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import type { AnalysisDataResponse } from "@/api/messages/analysis-data";
import { AnalysisDataConverter } from "@/api/models/analysis/analysis-data";
import { HistoryDataController } from "@/api/history-data-controller";

export class AnalysisController extends RestApiController {

  private static instance: AnalysisController | null = null;

  private readonly analysisConverter = new AnalysisDataConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new AnalysisController();
    }
    return this.instance;
  }

  public async requestAnalyses(instrument: Instrument,
                               timeframe: Timeframe,
                               limit: number,
                               start: number | null = null,
                               end: number | null = null) {
    const symbol = instrument.getSymbol();
    const timeframeValue = timeframe.toShortNotation();
    const baseUrl = `markets/${symbol}/${timeframeValue}/TOHLCV`;
    // TODO: switch -> Peters server don#t support MN1 now
    const url = baseUrl.replace("/MN1/", "/MN/");
    const parameterMap = HistoryDataController.getParameterMap(limit, start, end);
    const response = await this.sendGetRequest<AnalysisDataResponse>(url, parameterMap);
    return this.analysisConverter.toModelObject(response);
  }

  protected override getBaseUrl() {
    return this.environment.analysisUrl;
  }
}
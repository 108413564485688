import { simpleCompare, TwoWayMap } from "@/anfin-chart/utils";

export enum TimeUnit {
  Millisecond = 0,
  Second = 1,
  Minute = 2,
  Hour = 3,
  Day = 4,
  Week = 5,
  Month = 6,
  Year = 7
}

function getNextUnitFactor(unit: TimeUnit) {
  switch (unit) {
    case TimeUnit.Millisecond:
      return 1000;
    case TimeUnit.Second:
    case TimeUnit.Minute:
      return 60;
    case TimeUnit.Hour:
      return 24;
    case TimeUnit.Month:
      return 12;
    default:
      return null;
  }
}

export class Timeframe {

  public static readonly Y1 = new Timeframe(TimeUnit.Year, 1);
  public static readonly MN1 = new Timeframe(TimeUnit.Month, 1);
  public static readonly D1 = new Timeframe(TimeUnit.Day, 1);
  public static readonly default = this.D1;

  private static readonly unitMap = new TwoWayMap<string, TimeUnit>();

  public readonly unit: TimeUnit;
  public readonly value: number;

  static {
    this.unitMap.set("M", TimeUnit.Minute);
    this.unitMap.set("H", TimeUnit.Hour);
    this.unitMap.set("D", TimeUnit.Day);
    this.unitMap.set("W", TimeUnit.Week);
    this.unitMap.set("MN", TimeUnit.Month);
    this.unitMap.set("Y", TimeUnit.Year);
  }

  constructor(unit: TimeUnit, value: number, useNormalization = true) {
    const [normalizedUnit, normalizedValue] = useNormalization ? Timeframe.normalize(unit, value) : [unit, value];
    this.unit = normalizedUnit;
    this.value = normalizedValue;
  }

  public static isSame(first: Timeframe, second: Timeframe) {
    return first.unit === second.unit && first.value === second.value;
  }

  public static normalize(unit: TimeUnit, value: number): [TimeUnit, number] {
    const unitFactor = getNextUnitFactor(unit);
    if (unitFactor != null && value >= unitFactor && value % unitFactor === 0) {
      return this.normalize(unit + 1, value / unitFactor);
    }
    return [unit, value];
  }

  public static fromShortNotation(notation: string) {
    let index = 0;
    while (index < notation.length && (notation[index] < "0" || notation[index] > "9")) {
      index++;
    }
    const prefix = notation.substring(0, index);
    const unit = this.unitMap.getValue(prefix);
    const value = Number(notation.substring(index));
    if (unit == null || isNaN(value)) {
      throw new RangeError("Unknown timeframe");
    }
    return new Timeframe(unit, value);
  }

  public static compare(first: Timeframe, second: Timeframe) {
    const unitCompare = simpleCompare(first.unit, second.unit);
    if (unitCompare !== 0) {
      return unitCompare;
    }
    return simpleCompare(first.value, second.value);
  }

  public toShortNotation() {
    const prefix = Timeframe.unitMap.getKey(this.unit);
    if (prefix == null) {
      throw new RangeError("Unknown timeframe");
    }
    return prefix + this.value;
  }
}


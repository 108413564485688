import type { ChartArea } from "@/anfin-chart/area/chart-area";
import type { Chart } from "@/anfin-chart/chart";
import { ChartDrawer } from "@/anfin-chart/draw/chart-drawer";
import { StopWatch } from "@/anfin-chart/utils";
import { Subject } from "rxjs";

export class DrawData {

  constructor(public readonly duration: number) {
  }
}

export class ChartLayer {

  public width = 0;
  public height = 0;

  public areas: ChartArea[] = [];

  public readonly canvas: HTMLCanvasElement;
  public readonly drawer: ChartDrawer;

  private shouldDraw = false;
  private animationFrame: number | null = null;
  private readonly drawDataSubject = new Subject<DrawData>();

  constructor(public readonly chart: Chart,
              public readonly name: string) {
    this.canvas = document.createElement("canvas");
    chart.container.appendChild(this.canvas);
    this.drawer = new ChartDrawer(this.canvas);
    this.draw();
  }

  public requireDraw() {
    this.shouldDraw = true;
  }

  public getDrawDataObservable() {
    return this.drawDataSubject.asObservable();
  }

  public resize(width: number, height: number) {
    this.width = width * window.devicePixelRatio;
    this.height = height * window.devicePixelRatio;
    this.reset();
  }

  public registerArea(area: ChartArea) {
    this.areas.push(area);
    this.requireDraw();
  }

  public unregisterArea(area: ChartArea) {
    const index = this.areas.indexOf(area);
    if (index >= 0) {
      this.areas.splice(index, 1);
      this.requireDraw();
    }
  }

  private reset() {
    if (this.animationFrame != null) {
      window.cancelAnimationFrame(this.animationFrame);
    }
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.shouldDraw = true;
    this.drawer.initialize();
    this.draw();
  }

  private draw() {
    if (this.chart.isDestroyed) {
      return;
    }
    if (this.shouldDraw) {
      const duration = StopWatch.run(() => {
        this.shouldDraw = false;
        for (const area of this.areas) {
          area.draw();
        }
      });
      this.drawDataSubject.next(new DrawData(duration));
    }
    this.animationFrame = window.requestAnimationFrame(() => this.draw());
  }
}

import { type Environment, getEnvironment } from "@/api/environment";
import axios from "axios";
import { getUserInfo } from "@/api/auth";
import { generalStore } from "@/stores/general-store";

export abstract class RestApiController {

  protected readonly environment: Environment;
  protected readonly baseUrl: string;

  protected constructor() {
    this.environment = getEnvironment();
    this.baseUrl = this.getBaseUrl();
  }

  protected async sendGetRequest<T>(subPath: string, parameterMap: Map<string, string> | null = null) {
    const url = this.resolveUrl(subPath, parameterMap);
    const config = this.createRequestConfig();
    generalStore().logDebug("GET " + url);
    axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
    const response = await axios.get<T>(url, config);
    return response.data;
  }

  protected async sendPostRequest<T>(subPath: string, data: unknown) {
    const url = this.resolveUrl(subPath);
    generalStore().logDebug("POST " + url);
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    const response = await axios.post<T>(url, data, this.createRequestConfig());
    return response.data;
  }

  protected async sendPutRequest<T>(subPath: string, data: unknown) {
    const url = this.resolveUrl(subPath);
    generalStore().logDebug("PUT " + url);
    axios.defaults.headers.put["Access-Control-Allow-Origin"] = "*";
    const response = await axios.put<T>(url, data, this.createRequestConfig());
    return response.data;
  }

  protected async sendDeleteRequest<T>(subPath: string, parameterMap: Map<string, string> | null = null) {
    const url = this.resolveUrl(subPath, parameterMap);
    generalStore().logDebug("DELETE " + url);
    axios.defaults.headers.delete["Access-Control-Allow-Origin"] = "*";
    const response = await axios.delete<T>(url, this.createRequestConfig());
    return response.data;
  }

  private resolveUrl(subPath: string, parameterMap: Map<string, string> | null = null) {
    const url = this.baseUrl + "/" + subPath;
    if (parameterMap == null || parameterMap.size === 0) {
      return url;
    }
    const parameters = Array.from(parameterMap.entries())
      .map(entry => entry[0] + "=" + entry[1])
      .join("&");
    return url + "?" + parameters;
  }

  private createRequestConfig() {
    const userInfo = getUserInfo();
    return {
      headers: {
        email: `${userInfo?.email ?? ""}`,
        token: `${userInfo?.token ?? ""}`
      }
    };
  }

  protected abstract getBaseUrl(): string;
}

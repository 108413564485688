import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { Vector } from "@/anfin-chart/geometry";
import { HintTable, HintText } from "@/anfin-chart/hints/chart-hint";
import { ToolHint } from "@/anfin-chart/hints/tool-hint";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";
import type { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import type { TrendExtension } from "@/api/models/analysis/auto-trend-line";

export class TrendExtensionHint extends ToolHint {

  constructor(tool: ChartTool,
              point: ChartToolPoint,
              private readonly extension: TrendExtension) {
    super(tool, point, new TextAlignment(new Vector(1, 0), 10));
    const chart = tool.getSubChart().chart;
    const dateFormatted = chart.formatDate(new Date(extension.time));
    const breakoutPriceLabel = new HintText(this, "Breakout price");
    const breakoutPrice = new HintText(this, extension.breakoutPrice == null ? "" : String(extension.breakoutPrice));
    const consolidationPriceLabel = new HintText(this, "Consolidation price");
    const consolidationPrice = new HintText(this, extension.consolidationPrice == null ? "" : String(extension.consolidationPrice));
    const ruleDateLabel = new HintText(this, "Rule date");
    const ruleDate = new HintText(this, extension.ruleDate == null ? "" : chart.formatDate(new Date(extension.ruleDate)));
    const table = new HintTable(this, 2, this.chart.styleOptions.hintColumnSpacing.getValue());
    table.addRow(breakoutPriceLabel, breakoutPrice);
    table.addRow(consolidationPriceLabel, consolidationPrice);
    table.addRow(ruleDateLabel, ruleDate);
    this.addItem(
      new HintText(this, dateFormatted),
      new HintText(this, extension.ruleName ?? "Verlängerung nach ursprünglichen Fib-Regeln"),
      table
    );
  }
}

<template>
  <form class="login-form" @submit.prevent="handleSubmit">
    <div class="login-form-main mb-4" :style="{ maxWidth: contentSizeCss }">
      <slot></slot>
    </div>
    <div class="login-form-footer">
      <button class="btn btn-primary" type="submit">
        {{ translationStore.getTranslation(buttonLabel) }}
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { translationStore } from "@/stores/translation-store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoginFormTemplate",

  props: {
    buttonLabel: {
      type: String,
      required: true
    },
    contentSize: {
      type: Number,
      default: 300
    }
  },

  emits: {
    submit: () => true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    contentSizeCss() {
      return this.contentSize + "px";
    }
  },

  methods: {
    handleSubmit() {
      this.$emit("submit");
    }
  }
});
</script>

<style scoped>
.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form-main {
  width: 100%;
  max-width: 280px;
}

.login-form-footer {
  width: 100%;
  padding: 20px;
  background: #555555;
  text-align: center;
}
</style>

<template>
  <ToolbarDropdown id="top-toolbar-layout" :items="chartLayoutItems" :disabled="!allowLayout" :titleIcon="titleIcon">
    <template #header>
      {{ translationStore.getTranslation("topbar#chart_layout_caption") }}
    </template>
  </ToolbarDropdown>
</template>

<script lang="ts">
import { translationStore } from "@/stores/translation-store";
import { ToolbarItemData } from "@/views/toolbar/toolbar-item-data";
import ToolbarDropdown from "@/views/toolbar/ToolbarDropdown.vue";
import { defineComponent } from "vue";
import { multiChartStore } from "@/stores/multi-chart-store";
import { storeToRefs } from "pinia";
import type { ChartLayoutDefinition } from "@/api/models/chart-layout";

export default defineComponent({
  name: "ChartLayoutSelector",

  components: { ToolbarDropdown },

  expose: [],

  data() {
    const { layoutDefinitions, currentLayoutDefinition } = storeToRefs(multiChartStore());
    return {
      translationStore: translationStore(),
      layoutDefinitions: layoutDefinitions as unknown as ChartLayoutDefinition[],
      currentLayoutDefinition: currentLayoutDefinition as unknown as ChartLayoutDefinition,
      allowLayout: true // userRightStore().hasRight(UserRightKey.Layout)
    };
  },

  computed: {
    titleIcon() {
      return this.currentLayoutDefinition.icon;
    },

    chartLayoutItems(): ToolbarItemData[] {
      return this.layoutDefinitions.map(l => {
        const key = "chart_layout_" + l.id;
        return new ToolbarItemData(key, null, l.icon, () => this.setChartLayout(l));
      });
    }
  },

  methods: {
    setChartLayout(layout: ChartLayoutDefinition) {
      multiChartStore().setLayoutDefinition(layout);
      multiChartStore().saveLayout();
    }
  }
});
</script>

<style scoped>

</style>

import { GradientColor, RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { ChartColor, GradientStop } from "@/anfin-chart/draw/chart-color";
import type { OptionValueType } from "@/anfin-chart/options/option";
import type { PlotType } from "@/anfin-chart/plot";

export class IndicatorDefinition {

  constructor(public type: string,
              public name: string,
              public isNewSubChart: boolean,
              public options: ChartOptionDefinition[],
              public plots: PlotDefinition[],
              public indicators: DependentIndicatorDefinition[],
              public dynamicConfiguration: string | null,
              public priceRanges: PriceRangeDefinition[],
              public initialize: string,
              public updateInternal: string,
              public utils: string[]) {
  }
}

export class ChartOptionDefinition {

  constructor(public name: string,
              public type: OptionValueType,
              public value: unknown) {
  }
}

export class PlotDefinition {

  constructor(public name: string,
              public type: PlotType,
              public colorProvider: ColorProviderDefinition,
              public useAxis: boolean) {
  }
}

export enum ColorType {
  Rgba = 0,
  Gradient = 1
}

export enum ColorProviderType {
  Simple = 0,
  Threshold = 1,
  MultiKey = 2,
  Conditional = 3
}

export class ColorDefinition {

  constructor(public type: ColorType,
              public r = 0,
              public g = 0,
              public b = 0,
              public a = 1,
              public stops: GradientStopDefinition[] = []) {
  }

  public static fromColor(color: ChartColor) {
    const definition = new ColorDefinition(ColorType.Rgba);
    if (color instanceof RGBAColor) {
      definition.type = ColorType.Rgba;
      definition.r = color.r;
      definition.g = color.g;
      definition.b = color.b;
      definition.a = color.a;
    } else if (color instanceof GradientColor) {
      definition.type = ColorType.Gradient;
      definition.stops = color.stops.map(s => GradientStopDefinition.fromStop(s));
    } else {
      console.error("Unknown color type");
    }
    return definition;
  }

  public toColor() {
    switch (this.type) {
      case ColorType.Rgba:
        return RGBAColor.fromRgba(this);
      case ColorType.Gradient:
        const color = new GradientColor();
        for (const stopDefinition of this.stops) {
          const percentage = stopDefinition.percentage;
          const stopColor = RGBAColor.fromRgba(stopDefinition.color);
          if (percentage < 0 || percentage > 1 || isNaN(percentage)) {
            console.error("Invalid stop color");
            color.addStop(1, stopColor);
            continue;
          }
          color.addStop(percentage, stopColor);
        }
        return color;
      default:
        console.error("Unknown color type");
        return RGBAColor.black;
    }
  }
}

export class GradientStopDefinition {

  constructor(public percentage: number,
              public color: ColorDefinition) {
  }

  public static fromStop(stop: GradientStop) {
    return new GradientStopDefinition(stop.percentage, ColorDefinition.fromColor(stop.color));
  }
}

export class ColorProviderDefinition {

  constructor(public type: ColorProviderType,
              public colorSets: ColorDefinition[][],
              public threshold: number | null,
              public keys: string[] | null,
              public conditions: string[] | null) {
  }
}

export class DependentIndicatorDefinition {

  constructor(public name: string,
              public type: string) {
  }
}

export class PriceRangeDefinition {

  constructor(public from: number,
              public to: number,
              public lineColor: ColorDefinition,
              public areaColor: ColorDefinition) {
  }
}

<template>
  <div v-if="isAnfinBasic">
    <ProductDetailParagraph>
      <template #header>
        AnFin Basic: Hochentwickelte Chartsoftware für Anleger
      </template>
      <template #content>
        <div>
          AnFin Basic ist eine hochentwickelte Chartsoftware, die in jahrelanger Detailarbeit von Tradern für Trader
          entwickelt wurde.
        </div>
        <div>
          Mit unserer jahrzehntelangen Expertise und Erfahrung im Handel und der Schulung von Tradern haben wir
          ein Tool erschaffen, das exakt auf Ihre Bedürfnisse zugeschnitten ist.
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Für „Pro-Trader“, mit eigener Analysearbeit: Effizient und benutzerfreundlich
      </template>
      <template #content>
        <div>
          Als jemand, der charttechnische Analysen für Handelsentscheidungen nutzt, benötigen Sie ein
          professionelles Tool. AnFin Basic ermöglicht Ihnen, Ihre Analysen auf einer Plattform durchzuführen,
          die auch von Experten verwendet wird. AnFin Basic ist intuitiv und benutzerfreundlich, ermöglicht
          schnellen Zugriff auf alle notwendigen Chartinformationen und unterstützt Sie dabei, Ihre
          Trading-Strategien effektiv und effizient umzusetzen.
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Für Professionelle Trader: Flexibel und leistungsstark
      </template>
      <template #content>
        <div>
          Mit AnFin Basic erhalten Sie ein flexibles und leistungsstarkes Tool an die Hand, das Ihnen erlaubt,
          Ihre Charts ohne Beschränkungen anzulegen und zu speichern. Nutzen Sie die Möglichkeit, unbegrenzte Listen
          und individuelle Chart-Einstellungen anzulegen, um Ihre Handelsstrategien optimal zu unterstützen.
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Für Investoren: Präzise und zuverlässig
      </template>
      <template #content>
        <div>
          Als Investor wissen Sie, wie wichtig präzise und zuverlässige Chartanalysen sind. AnFin Basic bietet
          Ihnen genau das: Ein Tool, das Sie bei der Analyse unterstützt und Ihnen hilft, fundierte Entscheidungen
          zu treffen, die auch für Ihre langfristigen Anlagestrategien von großer Bedeutung sein können.
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Unbegrenzte Funktionen für jeden Anleger
      </template>
      <template #content>
        <ul class="product-detail-list">
          <li>
            <span class="text-decoration-underline">Unbegrenzte Möglichkeiten</span>
            <span>: Erstellen und speichern Sie so viele Listen und Charts, wie Sie benötigen. Keine versteckten Kosten. Keine notwendigen Upgrades</span>
          </li>
          <li>
            <span class="text-decoration-underline">Intuitives Design</span>
            <span>: Unser Tool ist speziell für Trader konzipiert und ermöglicht u.a. ein einfaches und intuitives Zoomen in den Charts</span>
          </li>
          <li>
            <span class="text-decoration-underline">Webbasiertes Multi-Screen</span>
            <span>: Nutzen Sie die Flexibilität unseres webbasierten Tools, das Analysen auf mehreren Bildschirmen ermöglicht, ohne dafür eine Software zu installieren</span>
          </li>
          <li>
            <span class="text-decoration-underline">Automatisiertes Speichern</span>
            <span>: Alle Ihre Änderungen werden direkt und automatisch gespeichert, sodass Sie sich nie wieder Sorgen um verlorene Daten machen müssen</span>
          </li>
        </ul>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Jetzt zu AnFin wechseln
      </template>
      <template #content>
        <div>
          AnFin Basic ist die ideale Chartsoftware für Trader und Anleger aller Erfahrungsstufen. Entdecken
          Sie die Möglichkeiten, die Ihnen unser Tool bietet, und heben Sie Ihre Strategien auf das nächste
          Level. Mit AnFin Basic haben Sie die Kontrolle und die Flexibilität, die Sie für erfolgreiche
          Anlagen benötigen.
        </div>
      </template>
    </ProductDetailParagraph>
  </div>

  <div v-else-if="isAnfinPro">
    <ProductDetailParagraph>
      <template #header>
        AnFin Pro: Innovative Anleger-Unterstützung
      </template>
      <template #content>
        <div>
          AnFin Pro repräsentiert nicht nur eine fortschrittliche Chartsoftware, sondern auch eine bahnbrechende
          Unterstützung für Anleger auf allen Ebenen. Es ist das Resultat aus Expertise, Präzision und einem tiefen
          Verständnis für die Herausforderungen im Handel.
        </div>
        <div>
          Diese Software ist Ihre Brücke zwischen Technologie, Trading-Kompetenz und emotionalem Gleichgewicht.
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Revolutionäre Unterstützung im Trading
      </template>
      <template #content>
        <div>
          AnFin bringt mit der Pro-Lösung eine lang ersehnte Unterstützung für Trader und Anleger. Die
          wichtigen Elemente der technischen Analyse sind nach gängiger Lehre der Charttechnik in den
          Charts bereits einwandfrei eingezeichnet, wodurch selbst ungeübte Augen alle relevanten
          Informationen, wie Unterstützungen und Ausbrüche, sofort erkennen und wertvolle Zeit
          sparen können. Ihre Lernkurve wird steiler, die Effizienz gesteigert und Ihre Performance kann
          dank besserer Entscheidungen auf einer fundierten Basis optimiert werden.
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Steigerung der mentalen Belastbarkeit
      </template>
      <template #content>
        <div>
          Trading ist ein Mikrokosmos menschlicher Emotionen, und AnFin Pro ist Ihr Werkzeug, um diese zu managen.
          Jeder Trade und jede Anlageentscheidung zieht ein Spektrum an Gefühlen mit sich, wobei es entscheidend
          ist, diese zu verstehen und zu kontrollieren. AnFin gibt Ihnen ein fundiertes Rahmenwerk an die Hand,
          unterstützt Sie dabei, Entscheidungen rationaler und objektiver zu treffen, und trägt somit zu einer
          signifikanten Steigerung Ihrer mentalen Belastbarkeit bei.
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Vorteile von AnFin Pro auf einen Blick:
      </template>
      <template #content>
        <ul class="product-detail-list">
          <li>
            <span class="text-decoration-underline">Präzise eingezeichnete Elemente der technischen Analyse</span>
            <span>: Steigern Sie Ihre Analysefähigkeiten und treffen Sie fundierte Entscheidungen</span>
          </li>
          <li>
            <span class="text-decoration-underline">Zeitersparnis und effizienteres Trading</span>
            <span>: Analysieren Sie mehr Assets innerhalb kürzerer Zeit und verbessern Sie so Ihre Trading-Performance</span>
          </li>
          <li>
            <span class="text-decoration-underline">Emotionales Management</span>
            <span>: Erkennen und kontrollieren Sie Ihre Emotionen im Handel, um langfristig erfolgreicher agieren zu können</span>
          </li>
          <li>
            <span class="text-decoration-underline">Steilere Lernkurve</span>
            <span>: Profitieren Sie von der Expertise des AnFin-Teams und beschleunigen Sie Ihren Lernprozess bei allen Handelsentscheidungen</span>
          </li>
          <li>
            <span class="text-decoration-underline">Fundierte Entscheidungsgrundlagen</span>
            <span>: Nutzen Sie das durch AnFin bereitgestellte Rahmenwerk für rationale und objektive Trading-Entscheidungen</span>
          </li>
          <li>
            <span class="text-decoration-underline">Konstante Weiterentwicklung</span>
            <span>: AnFin versteht sich als hochinnovatives Unternehmen, das auch AnFin Pro stetig optimieren will. Sie erhalten zukünftige Updates kostenlos</span>
          </li>
          <li>
            <span class="text-decoration-underline">10 Ausbildungswebinare zur technischen Analyse, Mai 2024 - Jan. 2025</span>
            <span>: 45-minütige, kompakte Sessions mit erfahrenen Tradern und Mentoren, inklusive Tipps für das AnFin-Ultra-Paket.</span>
          </li>
        </ul>
      </template>
    </ProductDetailParagraph>
  </div>

  <div v-else-if="isAnfinUltra">
    <ProductDetailParagraph>
      <template #header>
        AnFin Ultra: Das ultimative Trading- und Investment-Paket
      </template>
      <template #content>
        <div>
          AnFin Ultra ist nicht nur eine Evolution unserer bisherigen Angebote, sondern auch eine Komplettlösung
          für ernsthafte Trader und Investoren. Es vereint alle Funktionen von AnFin Pro mit den bewährten
          Eigenschaften unseres ultimativen Finanzmarkt-Master-Pakets, um Ihnen ein umfassendes Tool für
          Ihren Handelserfolg zur Verfügung zu stellen
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Komplettes Trading-Ökosystem in Einem
      </template>
      <template #content>
        <div>
          Mit AnFin Ultra erhalten Sie die fortschrittlichste Chartsoftware und ein allumfassendes Investment-
          und Trading-Servicepaket. Es ist das Ergebnis einer Fusion aus unserem innovativen AnFin Pro und dem
          renommierten ultimativen Finanzmarkt-Master-Paket.
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Was AnFin Ultra für Sie bereithält:
      </template>
      <template #content>
        <ol class="product-detail-outer-list">
          <li>
            <span class="text-decoration-underline">Alles aus AnFin Pro</span>
            <span>:</span>
            <ul class="product-detail-list">
              <li>
                Präzise eingezeichnete Elemente der technischen Analyse für schnelle und fundierte Handelsentscheidungen
              </li>
              <li>
                Emotionales Management: Behalten Sie Ihre Emotionen im Handel unter Kontrolle
              </li>
              <li>
                Zeitersparnis und effizienteres Trading: Analysieren Sie mehr Assets innerhalb kürzerer Zeit
              </li>
              <li>
                Lernkurvenbeschleunigung durch Expertise und innovative Werkzeuge
              </li>
              <li>
                Kontinuierliche Produktverbesserungen und kostenlose Updates
              </li>
            </ul>
          </li>
          <li>
            <span class="text-decoration-underline">Zusätzliche Features aus dem ultimativen Finanzmarkt-Master-Paket</span>
            <span>:</span>
            <ul class="product-detail-list">
              <li>
                Zugriff auf Live-Anwendung der seit 5 Jahren nachweislich erfolgreichen Handelsstrategien
                inkl. Live-Trading
              </li>
              <li>
                Trading-Signale in Echtzeit für optimale Handelsentscheidungen
              </li>
              <li>
                Langfristige Investmentstrategien mit hoher Rendite-Chance
              </li>
              <li>
                Komplettes Risiko- und Exit-Management für sichere und gewinnbringende Trades
              </li>
              <li>
                Zeitersparnis durch gefilterte Finanznachrichten und präzise Marktanalysen
              </li>
              <li>
                Exklusiver Zugriff auf multimediale Inhalte und detaillierte Positionsübersichten
              </li>
            </ul>
          </li>
          <li>
            <span class="text-decoration-underline">Vereinfachte Kommunikation</span>
            <span>:</span>
            <ul class="product-detail-list">
              <li>
                Alle wichtigen Informationen und Updates über einen Kanal im Telegram-Messenger, E-Mail-Verteiler
                und im Premium-Bereich unserer Webseite
              </li>
            </ul>
          </li>
          <li>
            <span class="text-decoration-underline">10 Teilnahmen an Ausbildungswebinaren zwischen Mai 2024 und Januar 2025:</span>
            <span>:</span>
            <ul class="product-detail-list">
              <li>
                jeweils 45 Minuten Kompakte und übersichtliche Lehre der technischen Analyse mit
                erfahrenen Tradern und Mentoren mit jahrzehntelanger Erfahrung im Coaching
              </li>
              <li>
                Tipps zur optimalen Nutzung der Tools des AnFin-Ultra-Pakets
              </li>
            </ul>
          </li>
        </ol>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Ihr Trading und Investment auf das nächste Level heben
      </template>
      <template #content>
        <div>
          AnFin Ultra ist Ihre All-in-One-Lösung, die Sie dabei unterstützt, von kurzfristigen
          Handelsmöglichkeiten profitieren zu können. Begleitet durch das Fachwissen unseres
          Expertenteams, können Sie sich darauf verlassen, in jeder Marktlage einen verlässlichen Partner
          an Ihrer Seite zu wissen.
        </div>
      </template>
    </ProductDetailParagraph>
    <ProductDetailParagraph>
      <template #header>
        Jetzt die Zukunft des Tradings und Investments erleben
      </template>
      <template #content>
        <div>
          Mit AnFin Ultra betreten Sie eine neue Dimension des Tradings. Es kombiniert die Leistungsfähigkeit
          unseres innovativen Chartwerkzeugs mit dem umfassenden Service unseres Master-Pakets, um Ihnen eine
          unübertroffene Erfahrung im Finanzmarkt zu bieten. Treffen Sie intelligentere Entscheidungen, managen
          Sie Ihre Risiken effektiver und steigern Sie die Wahrscheinlichkeit für eine nachhaltig erfolgreiche
          Performance.
        </div>
        <br />
        <div>
          Der Service des ultimativen Finanzmarkt-MasterPakets (auch bekannt als Formationstrader+) steht
          Ihnen an mindestens 80% der Werktage (Montag bis Freitag) der gebuchten Abonnementlaufzeit zur Verfügung.
          Nach Buchung und Übermittlung Ihrer Telefonnummer, die Sie für Ihre Teilnahme verwenden wollen,
          werden Sie innerhalb von zwei Werktagen zur Telegram Gruppe hinzugefügt.
        </div>
      </template>
    </ProductDetailParagraph>
  </div>
  <div v-else>
    {{ product.description }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ShoppingProduct } from "@/api/models/shopping-cart";
import ProductDetailParagraph from "@/views/pages-landing/product/ProductDetailParagraph.vue";

export default defineComponent({
  name: "ProductDetail",

  components: { ProductDetailParagraph },

  props: {
    product: {
      type: ShoppingProduct,
      required: true
    }
  },

  expose: [],

  computed: {
    isAnfinBasic() {
      return this.product.key === ShoppingProduct.anfinBasic;
    },

    isAnfinPro() {
      return this.product.key === ShoppingProduct.anfinPro;
    },

    isAnfinUltra() {
      return this.product.key === ShoppingProduct.anfinUltra;
    }
  }
});
</script>

<style scoped>
.product-detail-outer-list > li:not(:last-child) {
  margin-bottom: 5px;
}

.product-detail-outer-list .product-detail-list {
  margin-top: 3px;
}

.product-detail-list > li:not(:last-child) {
  margin-bottom: 1px;
}
</style>
<template>
  <LandingPageSection class="section-gray">
    <ProductTable>
      <template #default="slotProps">
        <ProductPeriodSelector v-model="slotProps.period" class="product-table-period m-4" />
        <ProductTableCardRow :period="slotProps.period" />
        <div class="product-table-detail-toggle" @click="toggleExpanded">
          <span>{{ isExpanded ? 'Weniger anzeigen' : 'Mehr anzeigen' }}</span>
          <IconElement :iconName="isExpanded ? 'Collapse' : 'Expand'" :size="16" class="ms-2" />
        </div>
        <ProductTableFeatureList v-if="isExpanded" :productDatas="slotProps.productDatas" />
        <ProductTableFooter v-if="isExpanded" :productDatas="slotProps.productDatas" />
      </template>
    </ProductTable>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import ProductTable from "@/views/pages-landing/product/ProductTable.vue";
import ProductTableFooter from "@/views/pages-landing/product/ProductTableFooter.vue";
import ProductTableFeatureList from "@/views/pages-landing/product/ProductTableFeatureList.vue";
import ProductPeriodSelector from "@/views/pages-landing/product/ProductPeriodSelector.vue";
import ProductTableCardRow from "@/views/pages-landing/product/ProductTableCardRow.vue";
import IconElement from "@/views/icons/IconElement.vue";

export default defineComponent({
  name: "TestProductSection",

  components: {
    IconElement,
    ProductTableCardRow, ProductPeriodSelector, ProductTableFeatureList, ProductTableFooter, ProductTable,
    LandingPageSection
  },

  expose: [],

  data() {
    return {
      isExpanded: false
    };
  },

  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    }
  }
});
</script>

<style scoped>
.product-table-period, .product-table-detail-toggle {
  grid-column: 2 / 5;
}

.product-table-detail-toggle {
  background-color: var(--interactive-accent);
  color: var(--content-primary-top);
  text-align: center;
  cursor: pointer;
  border-radius: var(--border-radius-single);
  padding: 3px;
}

.product-table-detail-toggle:hover {
  background-color: var(--interactive-accent-hover);
}
</style>
import type { ShoppingOrderResult, ShoppingProduct, ShoppingProductVariant } from "@/api/models/shopping-cart";
import type { CountryResponse, ShoppingOrderResponse } from "@/api/messages/payments";
import { ApiModelConverter } from "@/api/messages/converter";
import { CustomerData } from "@/api/models/customer-data";

export enum CheckoutStep {
  UserData = 0,
  Payment = 1,
  Confirm = 2,
  Finished = 3,
  PaymentFinished = 4
}

export class ShoppingCartResolved {

  constructor(public readonly id: string,
              public readonly hasError: boolean,
              public readonly items: ShoppingCartItemResolved[]) {
  }
}

export class ShoppingCartItemResolved {

  constructor(public readonly product: ShoppingProduct,
              public readonly variant: ShoppingProductVariant) {
  }
}

export class ShoppingOrderState {

  public currentStep = CheckoutStep.UserData;
  public error: string | null = null;
  public originalOrder: ShoppingOrderResponse | null = null;
  public customerData = new CustomerData();
  public totalNet = 0;
  public totalVat = 0;
  public totalGross = 0;
  public currency = "";

  public setOrderData(orderResult: ShoppingOrderResult) {
    this.error = orderResult.error;
    if (orderResult.response) {
      this.originalOrder = orderResult.response;
      this.totalNet = orderResult.response.Total;
      this.totalVat = orderResult.response.TotalVat;
      this.totalGross = orderResult.response.TotalGross;
      this.currency = orderResult.response.Currency;
    }
  }
}

export class PaymentCountry {

  constructor(public readonly key: string,
              public readonly name: string) {
  }
}

export class PaymentCountryConverter extends ApiModelConverter<PaymentCountry, CountryResponse> {

  public override toApiObject(country: PaymentCountry): CountryResponse {
    throw new Error("Not implemented");
  }

  public override toModelObject(response: CountryResponse) {
    return new PaymentCountry(response.twoLetterCode, response.de);
  }
}

export class TerminationInfoResult {

  constructor(public readonly id: string,
              public readonly nextPossibleCancellationDate: string,
              public readonly endDate: string,
              public readonly revocationUntil: number) {
  }
}

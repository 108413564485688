import type { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartOptionConverter, type ChartOptionResponse } from "@/api/messages/chart-option";
import { RestApiController } from "@/api/rest-api-controller";
import { MultiWindowTemplateConverter } from "@/api/messages/chart-template";
import { PresetConverter } from "@/api/messages/user-tool";
import { ChartViewConverter, type ChartViewResponse } from "@/api/messages/chart-view";
import type {
  ChartTemplateListResponse,
  ChartViewListResponse,
  IndicatorPresetListResponse,
  ToolPresetListResponse
} from "@/api/messages/user-settings";
import type { ChartAnalysis } from "@/api/models/user-settings/analysis";
import type { ChartObjectPreset, ChartView, MultiWindowTemplate } from "@/api/models/user-settings/chart";
import type { NotificationChannel } from "@/api/models/notification";

export class UserSettingsController extends RestApiController {

  private static instance: UserSettingsController | null = null;

  private readonly chartViewConverter = new ChartViewConverter();
  private readonly multiWindowConverter = new MultiWindowTemplateConverter();
  private readonly presetConverter = new PresetConverter();
  private readonly optionConverter = new ChartOptionConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new UserSettingsController();
    }
    return this.instance;
  }

  public async getChartSettings() {
    const response = await this.sendGetRequest<ChartOptionResponse[]>("settings");
    return response.map(o => this.optionConverter.toModelObject(o));
  }

  public async saveChartSettings(options: ChartOptionDefinition[]) {
    const request = options.map(o => this.optionConverter.toApiObject(o));
    return await this.sendPostRequest("settings", request);
  }

  public async saveNotificationSettings(notificationChannel: NotificationChannel) {
    const request = {
      systemType: notificationChannel.type,
      systemId: notificationChannel.target,
      isAllowed: notificationChannel.isEnabled,
      isDefault: notificationChannel.isDefault,
      description: notificationChannel.description
    };
    return await this.sendPostRequest("notification", request);
  }

  public async getToolPresetList() {
    const response = await this.sendGetRequest<ToolPresetListResponse>("tool-presets");
    return response.presets.map(t => this.presetConverter.toModelObject(t));
  }

  public async saveToolPreset(preset: ChartObjectPreset) {
    const request = this.presetConverter.toApiObject(preset);
    if (preset.id == null) {
    return await this.sendPostRequest<number>("tool-presets", request);
    }
    return await this.sendPutRequest<number>(`tool-presets/${preset.id}`, request);
  }

  public async deleteToolPreset(preset: ChartObjectPreset) {
    return this.sendDeleteRequest<number>(`tool-presets/${preset.id}`);
  }

  public async getIndicatorPresetList() {
    const response = await this.sendGetRequest<IndicatorPresetListResponse>("indicator-presets");
    return response.presets.map(i => this.presetConverter.toModelObject(i));
  }

  public async saveIndicatorPreset(preset: ChartObjectPreset) {
    const request = this.presetConverter.toApiObject(preset);
    if (preset.id == null) {
      return await this.sendPostRequest<number>("indicator-presets", request);
    }
    return await this.sendPutRequest<number>(`indicator-presets/${preset.id}`, request);
  }

  public async deleteIndicatorPreset(preset: ChartObjectPreset) {
    return this.sendDeleteRequest<number>(`indicator-presets/${preset.id}`);
  }

  public async getChartViewList(instrument: Instrument) {
    const parameterMap = new Map<string, string>();
    parameterMap.set("symbol", instrument.getSymbol());
    const response = await this.sendGetRequest<ChartViewListResponse>("chart-views/named", parameterMap);
    return response.views.map(v => this.chartViewConverter.toModelObject(v));
  }

  public async getChartView(instrument: Instrument, timeframe: Timeframe) {
    const parameterMap = new Map<string, string>();
    parameterMap.set("symbol", instrument.getSymbol());
    parameterMap.set("timeframe", timeframe.toShortNotation());
    const response = await this.sendGetRequest<ChartViewResponse>("chart-views", parameterMap);
    return this.chartViewConverter.toModelObject(response);
  }

  public async saveChartView(view: ChartView) {
    const request = this.chartViewConverter.toApiObject(view);
    if (view.id == null) {
      return await this.sendPostRequest<number>("chart-views", request);
    }
    return await this.sendPutRequest<number>(`chart-views/${view.id}`, request);
  }

  public async deleteChartView(view: ChartView) {
    return this.sendDeleteRequest<number>(`chart-views/${view.id}`);
  }

  public async getChartTemplateList() {
    const response = await this.sendGetRequest<ChartTemplateListResponse>("view-templates");
    return response.templates.map(v => this.multiWindowConverter.toModelObject(v));
  }

  public async saveChartTemplate(template: MultiWindowTemplate) {
    const request = this.multiWindowConverter.toApiObject(template);
    if (template.id == null) {
      return await this.sendPostRequest<number>("view-templates", request);
    }
    return await this.sendPutRequest<number>(`view-templates/${template.id}`, request);
  }

  public deleteTemplate(template: MultiWindowTemplate) {
    return this.sendDeleteRequest<number>(`view-templates/${template.id}`);
  }

  public async saveChartAnalysis(analysis: ChartAnalysis) {
    if (analysis.id == null) {
      return await this.sendPostRequest<number>("chart-analysis", analysis);
    }
    return await this.sendPutRequest<number>(`chart-analysis/${analysis.id}`, analysis);
  }

  protected override getBaseUrl(): string {
    return this.environment.configurationUrl;
  }
}

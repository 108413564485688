<template>
  <span class="icon-container">
    <template v-if="icon.type === IconType.FontAwesome">
      <i class="fa correction-fa-icon" :class="icon.value" :style="{ color: color ?? 'inherit', fontSize: size + 'px' }"></i>
    </template>
    <template v-if="icon.type === IconType.Base64">
      <div v-for="value in getPath()" :key="value" :style="getBase64Style(value)"></div>
    </template>
    <template v-if="icon.type === IconType.Svg">
      <svg xmlns="http://www.w3.org/2000/svg" :height="size" :fill="color ?? 'currentColor'" :stroke="color ?? 'currentColor'" :viewBox="icon.viewBox">
        <path v-for="path in getPath()" :key="path" :d="path" />
      </svg>
    </template>
    <template v-if="icon.type === IconType.SvgFile">
      <inline-svg
        v-for="value in getPath()" :key="value"
        :src="getSVGImageUrl(value)"
        fillClassName="toolbar-item"
        strokeClassName="toolbar-item"
        :width="iconSize"
        :height="iconSize"
        :style="{ fill: fillcolor ?? color ?? 'currentColor', stroke: color ?? 'currentColor' }"
      />
    </template>
  </span>
</template>

<script lang="ts">
import { IconProvider, IconType } from "@/views/icons/icons";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "IconElement",

  props: {
    iconName: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      required: true
    },
    color: {
      type: String as PropType<string | null>,
      default: null
    },
    fillcolor: {
      type: String as PropType<string | null>,
      default: null
    }
  },

  expose: [],

  data() {
    return {
      IconType,
      iconData: null
    };
  },

  computed: {
    icon() {
      return IconProvider.getInstance().getIcon(this.iconName);
    },

    iconSize(): string {
      if (typeof this.size === "string") {
        return this.size;
      }
      return this.size + "px";
    }
  },

  methods: {
    getSVGImageUrl(svgName: string): string {
      return new URL("../../../static/svg/" + svgName, import.meta.url).href;
    },

    getPath() {
      if (typeof this.icon.value === "string") {
        return [this.icon.value];
      }
      return this.icon.value;
    },

    getBase64Style(value: string) {
      return {
        "background-image": `url(${value})`,
        width: "24px",
        height: "24px",
        "background-repeat": "no-repeat",
        "background-size": "contain"
      };
    }
  }
});
</script>

<style scoped>
.icon-container {
  display: inline-block;
  line-height: 0;
}

.correction-fa-icon {
  margin-top: 1px;
}
</style>

<template>
  <ContextMenuList
    v-show="isShown" v-click-outside="hide" class="context-menu"
    :class="{ 'context-menu-right-aligned': isRightAligned }" :style="position.toCssObject()" @contextmenu.prevent
  >
    <slot name="items" :isRightAligned="isRightAligned"></slot>
  </ContextMenuList>
</template>

<script lang="ts">
import ContextMenuList from "@/views/context-menu/ContextMenuList.vue";
import { defineComponent, nextTick } from "vue";
import { CssPosition } from "@/anfin-chart/utils";

export default defineComponent({
  name: "ContextMenu",

  components: { ContextMenuList },

  props: {
    container: {
      type: String,
      required: true
    }
  },

  expose: ["show", "hide"],

  data() {
    return {
      isShown: false,
      position: new CssPosition(0, 0, null, null),
      isRightAligned: false
    };
  },

  methods: {
    show(x: number, y: number) {
      this.isShown = true;
      this.position = new CssPosition(y, x, null, null);
      nextTick(() => this.updatePosition());
    },

    hide() {
      this.isShown = false;
    },

    updatePosition() {
      const mainElement = this.$el;
      const container = document.getElementById(this.container);
      if (container == null) {
        return;
      }
      const mainRect = mainElement.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      this.isRightAligned = mainRect.x + mainRect.width > containerRect.x + containerRect.width;
      if (this.isRightAligned) {
        this.position.right = window.innerWidth - containerRect.width - containerRect.x;
        this.position.left = null;
      }
      if (mainRect.y + mainRect.height > containerRect.y + containerRect.height) {
        this.position.bottom = window.innerHeight - containerRect.height - containerRect.y;
        this.position.top = null;
      }
    }
  }
});
</script>

<style scoped>
.context-menu {
  position: fixed;
}
</style>

import { defineStore } from "pinia";
import { userRightStore } from "@/stores/user-right-store";

export interface VersionInfo {
  version: string;
  git: string;
}

export class Version {

  constructor(public readonly major: number,
              public readonly minor: number,
              public readonly micro: number) {
  }

  public toString() {
    return this.major + "." + this.minor + "." + this.micro;
  }
}

export const versionStore = defineStore({
  id: "version",

  state() {
    setTimeout(() => initializeStore());
    return {
      version: null as Version | null,
      commitId: ""
    };
  },

  actions: {
    async checkVersion() {
      const response = await fetch("/version.json");
      const json = await response.json() as VersionInfo;
      const version = this.parseVersion(json.version);
      const previousVersion = this.version;
      this.version = version;
      this.commitId = json.git;
      if (previousVersion == null) {
        return;
      }
      if (version.major !== previousVersion.major || version.minor !== previousVersion.minor) {
        userRightStore().logout();
      } else if (version.micro !== previousVersion.micro) {
        window.location.reload();
      }
    },

    parseVersion(version: string) {
      const versionParts = version.split(".");
      if (versionParts.length !== 3) {
        console.error("Invalid version, expected 3 parts, but got " + versionParts.length);
      }
      const [major, minor, micro] = versionParts.map(p => {
        const parsedPart = Number(p);
        return isNaN(parsedPart) ? 0 : parsedPart;
      });
      return new Version(major, minor, micro);
    }
  }
});

function initializeStore() {
  const store = versionStore();
  store.checkVersion();
  window.setInterval(() => store.checkVersion(), 30000);
}

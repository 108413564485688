<template>
  <div class="context-menu-item user-select-none" :class="{ disabled: disabled }" @click="onSelect">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import type ContextMenu from "@/views/context-menu/ContextMenu.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContextMenuItem",

  props: {
    keepOpen: Boolean,
    disabled: Boolean
  },

  expose: [],

  methods: {
    onSelect() {
      const contextMenu = this.getContextMenu();
      if (contextMenu != null && !this.keepOpen) {
        contextMenu.hide();
      }
    },

    getContextMenu() {
      let parent = this.$parent;
      while (parent != null) {
        if (parent.$options.name === "ContextMenu") {
          return parent as unknown as typeof ContextMenu;
        }
        parent = parent.$parent;
      }
      return null;
    }
  }
});
</script>

<style scoped>
.context-menu-item {
  padding: 6px 12px 6px 12px;
  border: 1px solid transparent;
  white-space: nowrap;
}

.context-menu-item.disabled {
  color: var(--disabled-primary);
}

.context-menu-item:hover:not(.disabled) {
  background-color: var(--background-overlay);
  border: 1px solid var(--border-overlay);
}
</style>

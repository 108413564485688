import { GridDisplay } from "@/anfin-chart/area/drawing-area-wrapper";
import { PriceScaleType } from "@/anfin-chart/area/price-axis";
import {
  BooleanOption,
  ChartOption, DevicePixelOption,
  EnumOption,
  NumericArrayOption,
  NumericOption,
  type OptionProvider, StringArrayOption, StringOption
} from "@/anfin-chart/options/option";
import { ToolSynchronization } from "@/anfin-chart/tool-synchronization";
import { PlotColorOptions } from "@/anfin-chart/options/plot-color-options";
import { TrendLineOptions } from "@/anfin-chart/options/trend-line-options";
import { ChartColorOptions } from "@/anfin-chart/options/chart-color-options";
import { AnalysisToolColorOptions } from "@/anfin-chart/options/analysis-tool-options";
import { AlertColorOptions, AlertOptions } from "@/anfin-chart/options/alert-options";
import { NotificationSoundType } from "@/api/models/notification";
import { SettingsWindowMode } from "@/stores/ui-state-store";

export enum OptionName {
  LineWidth = "LineWidth",
  Color = "Color",
  ExpandRight = "ExpandRight",
  ExpandLeft = "ExpandLeft",
  ArrowSize = "ArrowSize",
  PlotType = "PlotType",
  TextContent = "TextContent",
  TextBold = "TextBold",
  TextItalic = "TextItalic",
  FontSize = "FontSize",

  UseToolSnapPrice = "UseToolSnapPrice",
  IsChainToolMode = "IsChainToolMode",
  CursorType = "CursorType",
  GridDisplay = "GridDisplay",
  PriceScale = "PriceScale",
  ToolSynchronization = "ToolSynchronization",
  Theme = "Theme",
  TimeRangeCache = "TimeRangeCache",
  SynchronizeInstrument = "SynchronizeInstrument",
  SynchronizeMousePosition = "SynchronizeMousePosition",

  ShowUserTools = "ShowUserTools",
  ShowAnalysisTools = "ShowAnalysisTools",
  ShowAlertTools = "ShowAlertTools",
  ShowPublicTools = "ShowPublicTools",
  ShowAutoFibonaccis = "ShowAutoFibonaccis",
  ShowFibonacciTrendLines = "ShowFibonacciTrendLines",
  ShowAutoHorizontals = "ShowAutoHorizontals",
  ShowDoubleExtreme = "ShowDoubleExtreme",
  ShowChannels = "ShowChannels",
  ShowExtremes = "ShowExtremes",
  ShowPriceGaps = "ShowPriceGaps",
  ShowTrends = "ShowTrends",
  ShowHeadAndShoulders = "ShowHeadAndShoulders",
  SelectedTrendLineId = "SelectedTrendLineId",
  ShowOnlyActiveTrendLines = "ShowOnlyActiveTrendLines",
  ShownTrendLineTypes = "ShownTrendLineTypes",
  FibonacciCollapsedLength = "FibonacciCollapsedLength",
  FibonacciLineMaxLength = "FibonacciLineMaxLength",
  FibonacciSidelineOffset = "FibonacciSidelineOffset",
  FreehandTracePoints = "FreehandTracePoints",
  FibonacciLevels = "FibonacciLevels",
  AlertShownStates = "AlertShownStates",
  AlertShowOnlyActiveInstruments = "AlertShowOnlyActiveInstruments",

  FavoritePanelPosition = "FavoritePanelPosition",
  SelectedWatchlistMainView = "SelectedWatchlistMainView",
  ChartObjectEditBarPinned = "ChartObjectEditBarPinned",
  ChartObjectEditBarPosition = "ChartObjectEditBarPosition",
  WatchlistSortColumn = "watchlistSortColumn",
  WatchlistSortAscending = "watchlistSortAscending",
  WatchlistColumnOrder = "watchlistColumnOrder",
  IsEmailNotificationEnabled = "IsEmailNotificationEnabled",
  IsDesktopNotificationEnabled = "IsDesktopNotificationEnabled",
  NotificationSound = "NotificationSound",
  SelectedChats = "SelectedChats",
  SettingsMode = "SettingsMode"
}

export abstract class ChartOptionManager implements OptionProvider {

  public readonly optionMap = new Map<string, ChartOption<any>>();

  public readonly useToolSnapPrice = new BooleanOption(this, OptionName.UseToolSnapPrice, true);
  public readonly isChainToolMode = new BooleanOption(this, OptionName.IsChainToolMode, false);
  public readonly cursorType = new EnumOption(this, OptionName.CursorType, 0);
  public readonly gridDisplay = new EnumOption(this, OptionName.GridDisplay, GridDisplay.Horizontal);
  public readonly priceScale = new EnumOption(this, OptionName.PriceScale, PriceScaleType.Absolute);
  public readonly toolSynchronization = new EnumOption(this, OptionName.ToolSynchronization, ToolSynchronization.None);
  public readonly theme = new EnumOption(this, OptionName.Theme, 3);
  public readonly timeRangeCache = new BooleanOption(this, OptionName.TimeRangeCache, true);
  public readonly synchronizeInstrument = new BooleanOption(this, OptionName.SynchronizeInstrument, true);
  public readonly synchronizeMousePosition = new BooleanOption(this, OptionName.SynchronizeMousePosition, true);

  public readonly showUserTools = new BooleanOption(this, OptionName.ShowUserTools, true);
  public readonly showAnalysisTools = new BooleanOption(this, OptionName.ShowAnalysisTools, true);
  public readonly showAlertTools = new BooleanOption(this, OptionName.ShowAlertTools, true);
  public readonly showPublicTools = new BooleanOption(this, OptionName.ShowPublicTools, true);
  public readonly showFibonaccis = new BooleanOption(this, OptionName.ShowAutoFibonaccis, false);
  public readonly showFibonacciTrends = new BooleanOption(this, OptionName.ShowFibonacciTrendLines, false);
  public readonly showHorizontals = new BooleanOption(this, OptionName.ShowAutoHorizontals, false);
  public readonly showDoubleExtreme = new BooleanOption(this, OptionName.ShowDoubleExtreme, false);
  public readonly showChannels = new BooleanOption(this, OptionName.ShowChannels, false);
  public readonly showExtremes = new BooleanOption(this, OptionName.ShowExtremes, false);
  public readonly showPriceGaps = new BooleanOption(this, OptionName.ShowPriceGaps, false);
  public readonly showTrends = new BooleanOption(this, OptionName.ShowTrends, false);
  public readonly showHeadAndShoulders = new BooleanOption(this, OptionName.ShowHeadAndShoulders, false);
  public readonly fibonacciCollapsedLength = new DevicePixelOption(this, OptionName.FibonacciCollapsedLength, 30);
  public readonly fibonacciMaxLength = new DevicePixelOption(this, OptionName.FibonacciLineMaxLength, 100);
  public readonly fibonacciSidelineOffset = new DevicePixelOption(this, OptionName.FibonacciSidelineOffset, 2);

  // TODO: remove non-chart-related options from this class
  public readonly favoritePanelPosition = new NumericArrayOption(this, OptionName.FavoritePanelPosition, [250, 250]);
  public readonly selectedWatchlistMainView = new NumericOption(this, OptionName.SelectedWatchlistMainView, 0);
  public readonly chartObjectEditBarPinned = new BooleanOption(this, OptionName.ChartObjectEditBarPinned, false);
  public readonly chartObjectEditBarPosition = new NumericArrayOption(this, OptionName.ChartObjectEditBarPosition, [250, 250]);
  public readonly watchlistSortColumn = new StringOption(this, OptionName.WatchlistSortColumn, "");
  public readonly watchlistSortAscending = new BooleanOption(this, OptionName.WatchlistSortAscending, true);
  public readonly watchlistColumnOrder = new StringArrayOption(this, OptionName.WatchlistColumnOrder, []);
  public readonly isEmailNotificationEnabled = new BooleanOption(this, OptionName.IsEmailNotificationEnabled, false);
  public readonly isDesktopNotificationEnabled = new BooleanOption(this, OptionName.IsDesktopNotificationEnabled, false);
  public readonly notificationSound = new EnumOption(this, OptionName.NotificationSound, NotificationSoundType.Bell);
  public readonly settingsWindowMode = new EnumOption(this, OptionName.SettingsMode, SettingsWindowMode.None);

  public readonly trendLine = new TrendLineOptions(this);
  public readonly alert = new AlertOptions(this);

  public readonly selectedChats = new NumericArrayOption(this, OptionName.SelectedChats, [-1]);

  public readonly plotColor = new PlotColorOptions(this);
  public readonly chartColor = new ChartColorOptions(this);
  public readonly analysisToolColor = new AnalysisToolColorOptions(this);
  public readonly alertToolColor = new AlertColorOptions(this);

  public abstract onOptionChange(option: ChartOption<unknown>): void;
}

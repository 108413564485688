<template>
  <div>
    <div>
      <label class="form-label">
        {{ translationStore.getTranslation("indicator_definition#label_initialize") }}
      </label>
      <AutoSizeTextarea v-model="indicatorDefinition.initialize" />
    </div>
    <div class="form-check mt-2">
      <input
        id="use-dynamic-configuration" :checked="hasDynamicConfiguration" type="checkbox"
        class="form-check-input" @change="setUseDynamicConfiguration"
      />
      <label class="form-check-label" for="use-dynamic-configuration">
        {{ translationStore.getTranslation("indicator_definition#label_dynamic_configuration") }}
      </label>
    </div>
    <div v-if="hasDynamicConfiguration && indicatorDefinition.dynamicConfiguration != null" class="mt-1">
      <AutoSizeTextarea v-model="indicatorDefinition.dynamicConfiguration" />
    </div>
  </div>
</template>

<script lang="ts">
import { IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import { translationStore } from "@/stores/translation-store";
import AutoSizeTextarea from "@/views/components/AutoSizeTextarea.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IndicatorDefinitionInitialize",

  components: { AutoSizeTextarea },

  props: {
    indicatorDefinition: {
      type: IndicatorDefinition,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      hasDynamicConfiguration: false,
      translationStore: translationStore()
    };
  },

  watch: {
    indicatorDefinition: {
      handler(indicatorDefinition: IndicatorDefinition) {
        this.hasDynamicConfiguration = indicatorDefinition.dynamicConfiguration != null;
      },
      immediate: true
    }
  },

  methods: {
    setUseDynamicConfiguration(event: Event) {
      const target = event.target as HTMLInputElement;
      const value = target.checked;
      if (value) {
        this.indicatorDefinition.dynamicConfiguration = "";
      } else {
        this.indicatorDefinition.dynamicConfiguration = null;
      }
      this.hasDynamicConfiguration = value;
    }
  }
});
</script>

<style scoped>

</style>

import { getActivePinia } from "pinia";
import type { Pinia, Store } from "pinia";

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

export function resetApplicationState(except: Store | null = null) {
  const pinia = getActivePinia() as ExtendedPinia;
  if (pinia != null) {
    const stores = pinia._s.values();
    for (const store of stores) {
      if (store.$id !== except?.$id) {
        store.$reset();
      }
    }
  }
}

<template>
  <div class="product-table-feature-list">
    <div v-for="(feature, featureIndex) in productFeatures" :key="feature" class="product-table-row">
      <ProductTableFeature :feature="feature" />
      <ProductTableItem
        v-for="productData in productDatas" :key="productData.product.id"
        :feature="productData.featureList[feature]"
      />
      <div
        v-if="featureIndex < productFeatures.length - 1" class="product-table-row-separator" :style="columnStyleFull"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import ProductTableFeature from "@/views/pages-landing/product/ProductTableFeature.vue";
import ProductTableItem from "@/views/pages-landing/product/ProductTableItem.vue";
import { ProductFeature, type ProductFeatureData } from "@/api/models/product-comparison";
import { getEnumValues } from "@/anfin-chart/utils";

export default defineComponent({
  name: "ProductTableFeatureList",

  components: { ProductTableItem, ProductTableFeature },

  props: {
    productDatas: {
      type: Array as PropType<ProductFeatureData[]>,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      productFeatures: getEnumValues(ProductFeature)
    };
  },

  computed: {
    columnStyleFull() {
      return {
        gridColumn: "1 / span " + (this.productDatas.length + 1)
      };
    }
  }
});
</script>

<style scoped>
.product-table-feature-list, .product-table-row {
  display: contents;
}

.product-table-row > *:not(.product-table-row-separator) {
  margin: 30px 0;
}

.product-table-row-separator {
  border-top: 1px solid #D0D0D0;
}

@media (max-width: 1199px) {
  .product-table-row > * {
    margin-top: 0;
  }
}
</style>
<template>
  <PageWrapper>
    <TopToolbar>
      <template #content-main>
        <h3>{{ translationStore.getTranslation("screener_overview#caption") }}</h3>
      </template>
    </TopToolbar>
    <div class="container main-content screener-container">
      <div class="d-flex justify-content-between mb-2">
        <div class="button-group">
          <button
            v-for="selector in timeframeSelectors" :key="selector.timeframe.toShortNotation()" type="button"
            class="btn btn-sm toggle-button" :class="{ active: selector.isSelected }" @click="selector.toggle()"
          >
            {{ selector.timeframe.toShortNotation() }}
          </button>
        </div>
        <div class="button-group">
          <div class="btn btn-sm toggle-button" :class="{ active: false }">S/R</div>
          <div class="btn btn-sm toggle-button" :class="{ active: true }">Fibos</div>
          <div class="btn btn-sm toggle-button" :class="{ active: false }">HT/ST</div>
          <div class="btn btn-sm toggle-button" :class="{ active: false }">SMA/EMA</div>
          <div class="btn btn-sm toggle-button" :class="{ active: false }">Gaps</div>
          <div class="btn btn-sm toggle-button" :class="{ active: false }">Breakouts</div>
          <div class="btn btn-sm toggle-button" :class="{ active: false }">Patterns</div>
          <div class="btn btn-sm toggle-button" :class="{ active: false }">Consos</div>
        </div>
      </div>
      <div class="screener-filter-container mb-2">
        <div v-for="filter in filters" :key="filter.definition.key" class="screener-filter" :class="{ active: filter.isSelected }" @mousedown="filter.toggle()">
          <div class="screener-label user-select-none">
            {{ filter.definition.name }}
          </div>
          <input
            :value="filter.min" type="number" class="form-control form-control-sm"
            @mousedown.stop @input="filter.min = processNumeric($event)" @keyup.enter="requestScreeners"
          />
          <input
            :value="filter.max" type="number" class="form-control form-control-sm"
            @mousedown.stop @input="filter.max = processNumeric($event)" @keyup.enter="requestScreeners"
          />
        </div>
      </div>
      <div class="d-flex flex-row align-items-center">
        <button type="button" class="btn btn-sm btn-primary px-3" @click="requestScreeners">
          Find Stocks
        </button>
        <input v-model="searchText" class="form-control form-control-sm search-input ms-2" placeholder="Search..." />
        <div v-if="results.length > 0" class="ms-auto me-3">
          Showing {{ startIndex + 1 }} to {{ endIndex }} of {{ filteredResults.length }}
        </div>
        <PaginationSelector :currentPage="pageNumber" :pageCount="pageCount" @pageChange="setPageNumber" />
      </div>
      <hr />
      <table class="table table-sm table-primary table-striped table-hover">
        <thead>
          <tr>
            <th
              v-for="(column, columnIndex) in columns" :key="column.key" class="screener-header user-select-none"
              :class="column.alignment" :style="{ 'grid-column': columnIndex + 1 }" @click="setSortColumn(column)"
            >
              <span>{{ column.name }}</span>
              <TableSortMarker v-if="sortColumn?.key === column?.key" :isAscending="isSortAscending" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="result in currentResults" :key="result.symbol + result.timeframe">
            <td>
              <a href="#" @click="openChart(result.symbol, result.timeframe)">{{ result.symbol }}</a>
            </td>
            <td v-for="column in columns.slice(1)" :key="column.key" :class="column.alignment">
              {{ column.getResultText(result) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </PageWrapper>
</template>

<script lang="ts">
import { Timeframe, TimeUnit } from "@/anfin-chart/time/timeframe";
import { simpleCompare } from "@/anfin-chart/utils";
import type { ScreenerDefinition, ScreenerFilter, ScreenerResult } from "@/api/messages/screener";
import { ScreenerController } from "@/api/screener-controller";
import { InputUtilsMixin } from "@/mixins/input-utils";
import { translationStore } from "@/stores/translation-store";
import PageWrapper from "@/views/PageWrapper.vue";
import PaginationSelector from "@/views/screener/PaginationSelector.vue";
import TopToolbar from "@/views/toolbar/TopToolbar.vue";
import { defineComponent } from "vue";
import TableSortMarker from "@/views/table/TableSortMarker.vue";

class TimeframeSelector {

  public isSelected = true;

  constructor(public readonly timeframe: Timeframe) {
  }

  public toggle() {
    this.isSelected = !this.isSelected;
  }
}

class ScreenerFilterItem implements ScreenerFilter {

  public key: string;
  public min: number | null = null;
  public max: number | null = null;

  public isSelected = true;

  constructor(public readonly definition: ScreenerDefinition) {
    this.key = definition.key;
  }

  public toggle() {
    this.isSelected = !this.isSelected;
  }
}

abstract class ScreenerColumn<T> {

  constructor(public readonly key: string,
              public readonly name: string,
              public readonly alignment: string,
              public readonly accessor: (result: ScreenerResult) => T) {
  }

  public getResultText(result: ScreenerResult) {
    return this.getText(this.accessor(result));
  }

  public compareResult(first: ScreenerResult, second: ScreenerResult) {
    return this.compare(this.accessor(first), this.accessor(second));
  }

  protected abstract getText(value: T): string;

  protected abstract compare(first: T, second: T): number;
}

class StringColumn extends ScreenerColumn<string> {

  protected override getText(value: string) {
    return value;
  }

  protected override compare(first: string, second: string) {
    return simpleCompare(first, second);
  }
}

class TimeframeColumn extends ScreenerColumn<Timeframe> {

  protected override getText(value: Timeframe) {
    return value.toShortNotation();
  }

  protected override compare(first: Timeframe, second: Timeframe) {
    return Timeframe.compare(first, second);
  }
}

class NumberColumn extends ScreenerColumn<number> {

  protected override getText(value: number) {
    return value.toFixed(2);
  }

  protected override compare(first: number, second: number) {
    return simpleCompare(first, second);
  }
}

export default defineComponent({
  name: "ScreenerPage",

  components: { TableSortMarker, PageWrapper, TopToolbar, PaginationSelector },

  mixins: [InputUtilsMixin],

  expose: [],

  data() {
    const timeframeSelectors = [
      new TimeframeSelector(new Timeframe(TimeUnit.Minute, 1)),
      new TimeframeSelector(new Timeframe(TimeUnit.Minute, 5)),
      new TimeframeSelector(new Timeframe(TimeUnit.Minute, 15)),
      new TimeframeSelector(new Timeframe(TimeUnit.Minute, 30)),
      new TimeframeSelector(new Timeframe(TimeUnit.Hour, 1)),
      new TimeframeSelector(new Timeframe(TimeUnit.Hour, 4)),
      new TimeframeSelector(Timeframe.D1),
      new TimeframeSelector(new Timeframe(TimeUnit.Week, 1)),
      new TimeframeSelector(Timeframe.MN1)
    ];
    return {
      filters: [] as ScreenerFilterItem[],
      searchText: "",
      timeframeSelectors: timeframeSelectors,
      results: [] as ScreenerResult[],
      selectedDefinitions: [] as ScreenerDefinition[],
      sortColumn: null as ScreenerColumn<unknown> | null,
      isSortAscending: true,
      pageNumber: 1,
      pageSize: 18,
      translationStore: translationStore()
    };
  },

  computed: {
    columns(): ScreenerColumn<unknown>[] {
      const columns = [
        new StringColumn("instrument", "Instrument", "text-start", result => result.symbol),
        new TimeframeColumn("timeframe", "Timeframe", "text-start", result => Timeframe.fromShortNotation(result.timeframe)),
        new NumberColumn("last", "Last", "text-end", result => result.last)
      ];
      for (let i = 0; i < this.selectedDefinitions.length; i++) {
        const definition = this.selectedDefinitions[i];
        const column = new NumberColumn(definition.key, definition.name, "text-end", result => result.screener[i]);
        columns.push(column);
      }
      return columns;
    },

    startIndex() {
      return (this.pageNumber - 1) * this.pageSize;
    },

    endIndex() {
      return Math.min(this.startIndex + this.pageSize, this.filteredResults.length);
    },

    filteredResults(): ScreenerResult[] {
      if (this.searchText === "") {
        return this.results;
      }
      const searchText = this.searchText.toUpperCase();
      return this.results.filter(r =>
        r.symbol.toUpperCase().includes(searchText) ||
        r.timeframe.toUpperCase().includes(searchText)
      );
    },

    currentResults(): ScreenerResult[] {
      return this.filteredResults.slice(this.startIndex, this.endIndex);
    },

    pageCount() {
      return Math.ceil(this.results.length / this.pageSize);
    }
  },

  watch: {
    columns(value: ScreenerColumn<unknown>[]) {
      if (this.sortColumn == null) {
        this.sortColumn = value[0];
      }
    }
  },

  created() {
    const controller = ScreenerController.getInstance();
    controller.getScreenerDefinitions().then(definitions => this.setDefinitions(definitions));
  },

  methods: {
    setDefinitions(definitions: ScreenerDefinition[]) {
      this.filters = definitions.map(d => new ScreenerFilterItem(d));
    },

    requestScreeners() {
      const selectedFilters = this.filters.filter(f => f.isSelected);
      this.selectedDefinitions = selectedFilters.map(f => f.definition);
      const selectedTimeframes = this.timeframeSelectors.filter(s => s.isSelected).map(s => s.timeframe);
      const controller = ScreenerController.getInstance();
      controller.getScreenerResults(selectedFilters, selectedTimeframes)
        .then(results => this.setScreenerResults(results));
    },

    setScreenerResults(results: ScreenerResult[]) {
      console.log(results);
      this.pageNumber = 1;
      this.results = results;
      this.sortResults();
    },

    setSortColumn(column: ScreenerColumn<unknown>) {
      this.isSortAscending = !this.isSortAscending || this.sortColumn?.key !== column.key;
      this.sortColumn = column;
      this.sortResults();
    },

    sortResults() {
      const sortColumn = this.sortColumn;
      if (sortColumn == null) {
        return;
      }
      this.results.sort((first, second) => {
        const result = sortColumn.compareResult(first, second);
        return this.isSortAscending ? result : -result;
      });
    },

    setPageNumber(pageNumber: number) {
      this.pageNumber = pageNumber;
    },

    openChart(symbol: string, timeframe: string) {
      const route = this.$router.resolve({ name: "Multichart", params: { symbol, timeframe } });
      window.open(route.href, "_blank");
    }
  }
});
</script>

<style scoped>
.screener-container {
  font-size: 90%;
}

.screener-filter-container {
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 8px;
  grid-row-gap: 1px;
}

.screener-filter {
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 3px;
  overflow: hidden;
}

.screener-filter:not(.active)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(127, 127, 127, 0.3);
}

.screener-filter .screener-label {
  margin-right: auto;
  flex-shrink: 1;
  text-overflow: ellipsis;
}

.screener-filter > *:not(:first-child) {
  margin-left: 5px;
}

.screener-filter input {
  width: 100px;
}

.screener-filter.active {
  background-color: #a68c3d;
}

.search-input {
  width: 250px;
}

.screener-header {
  font-weight: bold;
}

.screener-header:hover {
  background-color: #e1cb84;
}
</style>

<template>
  <div class="switch-button">
    <span
      v-if="labelLeft != null" class="switch-button-left" :class="{ invisible: showOnlyActiveLabel && modelValue }"
    >
      {{ labelLeft }}
    </span>
    <div class="form-switch">
      <input class="form-check-input" :checked="modelValue" :disabled="disabled" type="checkbox" role="switch" @click="toggle" />
    </div>
    <span
      v-if="labelRight != null" class="switch-button-right" :class="{ invisible: showOnlyActiveLabel && !modelValue }"
    >
      {{ labelRight }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "SwitchButton",

  props: {
    disabled: {
      type: Boolean
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    labelLeft: {
      type: String as PropType<string | null>,
      default: null
    },
    labelRight: {
      type: String as PropType<string | null>,
      default: null
    },
    showOnlyActiveLabel: {
      type: Boolean
    }
  },

  emits: {
    "update:modelValue": (value: boolean) => value || true
  },

  expose: [],

  methods: {
    toggle(event: Event) {
      const newValue = this.processCheck(event);
      this.$emit("update:modelValue", newValue);
    },

    processCheck(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        return event.target.checked;
      }
      return false;
    }
  }
});
</script>

<style scoped>
.switch-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.switch-button-left {
  margin-right: 8px;
}

.switch-button-right {
  margin-left: 8px;
}

.form-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.form-switch .form-check-input {
  height: 1.1rem;
  width: 2rem;
  margin: 0;
}

.form-switch .form-check-input:focus {
  box-shadow: none;
}

.form-switch .form-check-input:active {
  filter: none;
}

.form-switch .form-check-input:checked {
  background-color: var(--interactive-accent);
  border-color: var(--interactive-accent);
}

.form-switch .form-check-input:not(:checked):active, .form-switch .form-check-input:not(:checked):focus {
  border-color: #00000040;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/></svg>");
}
</style>
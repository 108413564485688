import type { TimeAxisItem } from "@/anfin-chart/area/time-axis";
import type { Chart } from "@/anfin-chart/chart";
import { getTruncatedValue } from "@/anfin-chart/time/time-utils";
import { Timeframe, TimeUnit } from "@/anfin-chart/time/timeframe";
import { padLeft } from "@/anfin-chart/utils";

export class TimeAxisLabel {

  public before: TimeAxisLabel[] = [];
  public after: TimeAxisLabel[] = [];

  constructor(public readonly text: string,
              public readonly date: Date,
              public readonly index: number) {
  }
}

export class VisibleTimeLabel {

  constructor(public readonly label: TimeAxisLabel,
              public readonly x: number,
              public readonly isBold: boolean) {
  }
}

class TimeframeData {

  public value = 0;
  public labels: TimeAxisLabel[] = [];

  constructor(public readonly timeframe: Timeframe) {
  }
}

export class LabelCalculator {

  private readonly timeframeDatas: TimeframeData[];

  constructor(private readonly chart: Chart,
              timeframes: Timeframe[]) {
    this.timeframeDatas = [];
    for (const timeframe of timeframes) {
      const timeframeData = new TimeframeData(timeframe);
      this.timeframeDatas.push(timeframeData);
    }
  }

  public getLabels(items: TimeAxisItem[]) {
    if (items.length === 0) {
      return [];
    }
    const firstDate = new Date(items[0].time);
    this.initialize(firstDate);
    this.calculateTimeframes(items);
    for (let i = 0; i < this.timeframeDatas.length; i++) {
      const timeframeData = this.timeframeDatas[i];
      const labels = timeframeData.labels;
      if (labels.length > 0) {
        this.recreateFirstLabel(labels, i, firstDate);
        this.appendLastLabels(labels, i);
        return labels;
      }
    }
    return [];
  }

  private initialize(firstDate: Date) {
    for (const timeframeData of this.timeframeDatas) {
      timeframeData.value = getTruncatedValue(firstDate, timeframeData.timeframe);
      timeframeData.labels = [];
    }
  }

  private formatDate(date: Date, timeframe: Timeframe) {
    const unit = timeframe.unit;
    if (unit >= TimeUnit.Year) {
      return date.getFullYear().toString();
    }
    if (unit >= TimeUnit.Month) {
      return this.chart.callbacks.getTranslation("month_short#" + date.getMonth());
    }
    if (unit >= TimeUnit.Day) {
      return date.getDate().toString();
    }
    const hourPadded = padLeft(date.getHours().toString(), "0", 2);
    const minutePadded = padLeft(date.getMinutes().toString(), "0", 2);
    return hourPadded + ":" + minutePadded;
  }

  private calculateTimeframes(items: TimeAxisItem[]) {
    for (let timeIndex = 0; timeIndex < items.length; timeIndex++) {
      const date = items[timeIndex].date;
      for (let timeframeIndex = 0; timeframeIndex < this.timeframeDatas.length; timeframeIndex++) {
        const timeframeData = this.timeframeDatas[timeframeIndex];
        const value = getTruncatedValue(date, timeframeData.timeframe);
        if (value !== timeframeData.value) {
          const label = this.createLabel(timeIndex, date, timeframeData.timeframe);
          timeframeData.labels.push(label);
          timeframeData.value = value;
          this.updateSubTimeframes(timeframeIndex + 1, date, label);
          break;
        }
      }
    }
  }

  private updateSubTimeframes(fromIndex: number, date: Date, label: TimeAxisLabel) {
    const timeframeCount = this.timeframeDatas.length;
    if (fromIndex >= timeframeCount) {
      return;
    }
    const nextTimeframeData = this.timeframeDatas[fromIndex];
    label.before = nextTimeframeData.labels;
    nextTimeframeData.labels = label.after;
    nextTimeframeData.value = getTruncatedValue(date, nextTimeframeData.timeframe);
    let currentIndex = fromIndex + 1;
    while (currentIndex < timeframeCount) {
      const timeframeData = this.timeframeDatas[currentIndex];
      timeframeData.value = getTruncatedValue(date, timeframeData.timeframe);
      if (label.before.length === 0) {
        label.before = timeframeData.labels;
      }
      timeframeData.labels = [];
      currentIndex++;
    }
  }

  private recreateFirstLabel(labels: TimeAxisLabel[], timeframeIndex: number, firstDate: Date) {
    let currentLabels = labels;
    let currentIndex = timeframeIndex;
    while (currentLabels[0].before.length > 0) {
      currentLabels = currentLabels[0].before;
      currentIndex++;
    }
    const firstLabel = this.createLabel(0, firstDate, this.timeframeDatas[currentIndex].timeframe);
    currentLabels.splice(0, 0, firstLabel);
  }

  private createLabel(timeIndex: number, date: Date, timeframe: Timeframe) {
    const text = this.formatDate(date, timeframe);
    return new TimeAxisLabel(text, date, timeIndex);
  }

  private appendLastLabels(labels: TimeAxisLabel[], timeframeIndex: number) {
    let lastLabel = labels[labels.length - 1];
    let currentIndex = timeframeIndex + 1;
    while (currentIndex < this.timeframeDatas.length) {
      const currentLabels = this.timeframeDatas[currentIndex].labels;
      if (currentLabels.length > 0) {
        lastLabel.after = currentLabels;
        lastLabel = currentLabels[currentLabels.length - 1];
      }
      currentIndex++;
    }
  }
}

import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { LineStrip } from "@/anfin-chart/drawable";
import { Point } from "@/anfin-chart/geometry";
import type { Instrument } from "@/anfin-chart/instrument";
import { ChartOption, ColorOption, NumericArrayOption } from "@/anfin-chart/options/option";
import { PositionSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint, TracePoint } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class FreehandDrawTool extends UserToolDefinition {

  public static readonly type = "fhdraw";

  private readonly color = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(208, 2, 27));
  private readonly tracePoints: TracePoint[] = [];
  private readonly tracePointsOption = new NumericArrayOption(this, OptionName.FreehandTracePoints, []);

  private readonly start = new ChartToolPoint();
  private readonly end = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(FreehandDrawTool.type, instrument, timeframe, 2, null);
  }

  public override onOptionChange(option: ChartOption<unknown>) {
    super.onOptionChange(option);
    if (option.name === OptionName.FreehandTracePoints) {
      this.setTraces(option.getValue() as number[]);
    }
  }

  public tracePoint(tool: UserTool, position: Point) {
    const basePoint = this.fixedPoints[0];
    const baseIndex = tool.chart.timeAxis.getIndexForTime(basePoint.time);
    const index = tool.chart.timeAxis.getIndexForX(position.x);
    const price = tool.subChart.priceAxis.getPrice(position.y);
    const indexOffset = index - baseIndex;
    const priceOffset = price - basePoint.price;
    const point = new TracePoint(indexOffset, priceOffset);
    this.tracePoints.push(point);
    const values = this.tracePointsOption.getValue();
    values.push(indexOffset, priceOffset);
    tool.updateDrawables();
  }

  public getTracePoints() {
    return this.tracePoints;
  }

  public setTraces(traces: number[]) {
    this.tracePoints.splice(0);
    for (let i = 0; i < traces.length; i += 2) {
      const indexOffset = traces[i];
      const priceOffset = traces[i + 1];
      const point = new TracePoint(indexOffset, priceOffset);
      this.tracePoints.push(point);
    }
  }

  public override createDrawables(tool: UserTool) {
    const lineStrip = new LineStrip(tool, this.tracePoints);
    tool.onUpdate(() => {
      lineStrip.color = this.color.getValue();
      lineStrip.options.width = tool.getLineWidth(false);
    });
    new PositionSelectablePoint(tool, this.start, position => {
      this.updateBasePoint(tool, position, this.tracePoints[0]);
    });
    new PositionSelectablePoint(tool, this.end, position => {
      this.updateBasePoint(tool, position, this.tracePoints[this.tracePoints.length - 1]);
    });
  }

  public override updatePosition(tool: UserTool) {
    const basePoint = this.fixedPoints[0];
    const firstTrace = this.tracePoints[0];
    const start = this.getTracePosition(tool, firstTrace);
    tool.updatePoint(this.start, start);
    const lastTrace = this.tracePoints[this.tracePoints.length - 1];
    const end = this.getTracePosition(tool, lastTrace);
    tool.updatePoint(this.end, end);
    for (const trace of this.tracePoints) {
      const timeAxis = tool.subChart.chart.timeAxis;
      const baseIndex = timeAxis.getIndexForTime(basePoint.time);
      const x = timeAxis.getXForIndex(baseIndex + trace.indexOffset);
      const y = tool.subChart.priceAxis.getY(basePoint.price + trace.priceOffset);
      const tracePosition = new Point(x, y);
      tool.updatePoint(trace, tracePosition);
    }
  }

  private updateBasePoint(tool: UserTool, position: Point, tracePoint: TracePoint) {
    const index = tool.chart.timeAxis.getIndexForX(position.x);
    const newIndex = Math.round(index - tracePoint.indexOffset);
    const newTime = tool.chart.timeAxis.getTime(newIndex);
    const newPrice = tool.subChart.priceAxis.getPrice(position.y) - tracePoint.priceOffset;
    if (newTime != null) {
      this.fixedPoints[0].update(newTime, newPrice);
    }
  }

  private getTracePosition(tool: UserTool, tracePoint: TracePoint | null) {
    const basePoint = this.fixedPoints[0];
    const basePosition = tool.getPosition(basePoint);
    if (tracePoint == null) {
      return basePosition;
    }
    const index = tool.chart.timeAxis.getIndexForX(basePosition.x) + tracePoint.indexOffset;
    const price = tool.subChart.priceAxis.getPrice(basePosition.y) + tracePoint.priceOffset;
    const traceX = tool.chart.timeAxis.getXForIndex(index);
    const traceY = tool.subChart.priceAxis.getY(price);
    return new Point(traceX, traceY);
  }
}

import { ChartArea } from "@/anfin-chart/area/chart-area";
import { LabelCalculator, TimeAxisLabel, VisibleTimeLabel } from "@/anfin-chart/area/time-labels";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { LineOptions, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { Point, Range, Rectangle } from "@/anfin-chart/geometry";
import type { InstrumentData } from "@/anfin-chart/instrument";
import type { DoubleClickable, DragData, Draggable } from "@/anfin-chart/interactions";
import { diffArray } from "@/anfin-chart/pipes";
import type { TimeBased } from "@/anfin-chart/time/time-store";
import { Timeframe, TimeUnit } from "@/anfin-chart/time/timeframe";
import { DelayedAction, LinkedList } from "@/anfin-chart/utils";
import { BehaviorSubject, combineLatest, distinctUntilChanged, startWith, Subscription } from "rxjs";
import { ProjectedTimeStore } from "@/anfin-chart/time/projected-time-store";

class LabelDrawRange {

  constructor(public readonly labels: TimeAxisLabel[],
              public readonly xStart: number,
              public readonly xEnd: number,
              public readonly isBold: boolean) {
  }
}

export class RangeCache {

  constructor(public readonly size: number,
              public readonly offset: number) {
  }
}

export class TimeAxisItem implements TimeBased {

  public readonly date: Date;

  constructor(public readonly time: number) {
    this.date = new Date(time);
  }
}

export class TimeAxis extends ChartArea implements Draggable, DoubleClickable {

  private static readonly labelTimeframes = [
    Timeframe.Y1,
    Timeframe.MN1,
    new Timeframe(TimeUnit.Day, 5),
    new Timeframe(TimeUnit.Day, 1),
    new Timeframe(TimeUnit.Hour, 1),
    new Timeframe(TimeUnit.Minute, 15),
    new Timeframe(TimeUnit.Minute, 5)
  ];

  private readonly store: ProjectedTimeStore;
  private labels: TimeAxisLabel[] = [];
  private visibleLabels: VisibleTimeLabel[] = [];

  private readonly textAlignment = new TextAlignment();
  private readonly range = new BehaviorSubject<Range>(new Range(0, 0));
  private rangeCache: RangeCache | null = null;
  private lastOffset: number;

  private readonly labelCalculator: LabelCalculator;
  private readonly throttledLabelUpdate = new DelayedAction(this.executeLabelUpdate.bind(this), 0);
  private readonly subscriptions = new Map<InstrumentData, Subscription>();

  constructor(layer: ChartLayer) {
    super(layer);
    this.store = new ProjectedTimeStore(this);
    this.lastOffset = this.chart.styleOptions.initialOffset.getValue();
    this.labelCalculator = new LabelCalculator(this.chart, TimeAxis.labelTimeframes);
    this.initializeSubscription();
  }

  public override initializeEvents() {
    const combinedObservable = combineLatest([
      this.chart.coreLayerArea.getPositionObservable(),
      this.chart.priceAxisWrapper.getPositionObservable()
    ]);
    this.subscribeOn(combinedObservable, () => this.resize());
    this.subscribeOn(this.getRangeObservable(), range => {
      this.store.projectToRange(range);
      this.updateLabels();
    });
    this.subscribeOn(this.chart.getPixelRatioObservable(), () => this.updateLabels());
  }

  public onDrag(data: DragData) {
    const position = this.getPosition();
    const width = position.xEnd - position.xStart;
    this.zoomFixed(-data.diffX / width * 20);
  }

  public onDoubleClick() {
    if (this.rangeCache == null) {
      const currentRange = this.getRange();
      this.setFullRange(this.chart.styleOptions.initialOffset.getValue());
      this.rangeCache = this.createRangeCache(currentRange);
    } else {
      this.applyRangeCache();
    }
  }

  public getTimeCount() {
    return this.store.length;
  }

  public getFirstIndex() {
    const range = this.getRange();
    return Math.min(this.getTimeCount() - 1, Math.max(0, Math.trunc(range.start) - 1));
  }

  public getLastIndex() {
    const range = this.getRange();
    return Math.min(this.getTimeCount() - 1, Math.max(0, Math.trunc(range.end)));
  }

  public getTimeRange() {
    const firstIndex = this.getFirstIndex();
    const lastIndex = this.getLastIndex();
    const startTime = this.getTime(firstIndex);
    const endTime = this.getTime(lastIndex);
    if (startTime == null || endTime == null) {
      return null;
    }
    return new Range(startTime, endTime);
  }

  public clear() {
    const timeCount = this.getTimeCount();
    if (timeCount > 0) {
      const offset = this.getPosition().xEnd - this.getXForIndex(timeCount - 1);
      this.lastOffset = offset > 0 ? offset : this.chart.styleOptions.initialOffset.getValue();
    }
    this.store.clear();
    this.labels = [];
  }

  public getRangeObservable() {
    return this.range.pipe(
      distinctUntilChanged((last, current) => last.start === current.start &&
        last.end === current.end),
      startWith(this.range.value)
    );
  }

  public getRange() {
    return this.range.value;
  }

  public setRange(startIndex: number, endIndex: number) {
    const currentRange = this.getRange();
    if (currentRange.start === startIndex && currentRange.end === endIndex) {
      return;
    }
    const range = this.adjustOffscreenRange(startIndex, endIndex);
    this.range.next(range);
    if (this.getTimeCount() === 0) {
      this.rangeCache = this.createRangeCache(range);
    } else {
      this.clearCacheRange();
    }
    this.updateVisibleLabels();
    this.layer.requireDraw();
  }

  public isAtLast() {
    return this.getRange().end >= this.getTimeCount() - 1;
  }

  public moveToLast() {
    const offset = this.chart.styleOptions.initialOffset.getValue() / this.getBarWidth();
    const newEndIndex = this.getTimeCount() + offset;
    const newStartIndex = newEndIndex - this.getRange().size;
    this.setRange(newStartIndex, newEndIndex);
  }

  public updateItems() {
    this.clear();
    for (const instrumentData of this.chart.getInstrumentDatas()) {
      this.addItems(instrumentData.mainPlot.store.getItems());
    }
    this.setFullRange(this.lastOffset);
  }

  public addItems(items: TimeBased[]) {
    const existingCount = this.getTimeCount();
    this.store.insert(...items);
    if (existingCount === 0) {
      this.setFullRange(this.lastOffset);
    } else {
      const addedCount = this.store.length - existingCount;
      const range = this.getRange();
      this.setRange(range.start + addedCount, range.end + addedCount);
    }
    this.updateLabels();
    this.layer.requireDraw();
  }

  public getXForIndex(index: number) {
    const range = this.getRange();
    if (range.size === 0) {
      return 0;
    }
    const position = this.getPosition();
    const relativePosition = (index - range.start) / range.size;
    return position.xStart + relativePosition * (position.xEnd - position.xStart);
  }

  public getXForTime(time: number, offset = 0) {
    const index = this.getIndexForTime(time);
    return this.getXForIndex(index + offset);
  }

  public getIndexForTime(time: number) {
    return this.store.indexOfFraction(time);
  }

  public getIndexForX(x: number) {
    const position = this.getPosition();
    const relativePosition = (x - position.xStart) / (position.xEnd - position.xStart);
    const range = this.getRange();
    return range.start + relativePosition * range.size;
  }

  public getTime(index: number): number | null {
    return this.store.get(index)?.time ?? null;
  }

  public getLastTime(): number | null {
    const last = this.store.getLast();
    return last == null ? null : last.time;
  }

  public getBarWidth(barCount: number | null = null) {
    const count = barCount == null ? this.getRange().size : barCount;
    if (count <= 0) {
      return 0;
    }
    const position = this.getPosition();
    return (position.xEnd - position.xStart) / count;
  }

  public getCandleWidth() {
    let candleWidth = Math.ceil(this.chart.timeAxis.getBarWidth());
    const minWidth = this.chart.styleOptions.minCandleWidth.getValue();
    const maxWidth = this.chart.styleOptions.maxCandleWidth.getValue();
    if (candleWidth >= minWidth) {
      candleWidth = Math.min(candleWidth - 2, candleWidth * 2 / 3, maxWidth);
    }
    return candleWidth;
  }

  public getVisibleLabels() {
    return this.visibleLabels;
  }

  public moveRange(delta: number) {
    const position = this.getPosition();
    const relativeDiff = delta / (position.xEnd - position.xStart);
    const range = this.getRange();
    const indexDiff = -relativeDiff * range.size;
    const newStartIndex = range.start + indexDiff;
    const newEndIndex = range.end + indexDiff;
    this.setRange(newStartIndex, newEndIndex);
  }

  public zoomFixed(delta: number) {
    const lastX = this.getXForIndex(this.getTimeCount() - 1);
    const position = new Point(lastX, 0);
    this.zoomCenter(position, delta);
  }

  public zoomCenter(position: Point, delta: number) {
    const scrollBase = 0.9;
    const scrollFactor = scrollBase ** delta;
    const range = this.getRange();
    const baseIndex = this.getIndexForX(position.x);
    const newRangeSize = this.adjustRangeSize(range.size * scrollFactor);
    const adjustedScrollFactor = newRangeSize / range.size;
    const newStartIndex = baseIndex - (baseIndex - range.start) * adjustedScrollFactor;
    const newEndIndex = baseIndex - (baseIndex - range.end) * adjustedScrollFactor;
    this.setRange(newStartIndex, newEndIndex);
  }

  public createRangeCache(range = this.getRange()) {
    const lastIndex = this.getTimeCount() - 1;
    return new RangeCache(range.size, range.end - lastIndex);
  }

  public applyRangeCache(cache = this.rangeCache) {
    if (cache != null) {
      const end = this.getTimeCount() - 1 + cache.offset;
      const start = end - cache.size;
      this.setRange(start, end);
    }
  }

  protected override drawInternal() {
    this.drawBorder();
    this.drawLabels();
  }

  protected override resizeInternal() {
    const generalAreaPosition = this.chart.coreLayerArea.getPosition();
    const priceAxisWrapperPosition = this.chart.priceAxisWrapper.getPosition();
    const xStart = generalAreaPosition.xStart;
    const xEnd = priceAxisWrapperPosition.xStart;
    const yEnd = generalAreaPosition.yEnd;
    const yStart = yEnd - this.chart.styleOptions.timeAxisHeight.getValue();
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private initializeSubscription() {
    const diffObservable = this.chart.getInstrumentDatasObservable().pipe(diffArray());
    this.subscribeOn(diffObservable, diff => {
      for (const data of diff.removed) {
        const subscription = this.subscriptions.get(data);
        subscription?.unsubscribe();
      }
      for (const data of diff.added) {
        const itemsObservable = data.mainPlot.store.getItemsObservable();
        const subscription = itemsObservable.subscribe(items => this.addItems(items));
        this.subscriptions.set(data, subscription);
      }
      if (diff.removed.length > 0) {
        this.updateItems();
      }
    });
  }

  private adjustRangeSize(size: number) {
    const minBarCount = this.chart.styleOptions.minBarCount.getValue();
    if (size < minBarCount) {
      return minBarCount;
    }
    const maxBarCount = this.chart.styleOptions.maxBarCount.getValue();
    if (size > maxBarCount) {
      return maxBarCount;
    }
    return size;
  }

  private adjustOffscreenRange(startIndex: number, endIndex: number) {
    const lastIndex = this.getTimeCount() - 2;
    let start = startIndex;
    let end = endIndex;
    if (start > lastIndex) {
      end -= start - lastIndex;
      start = lastIndex;
    }
    if (end < 0) {
      start = start - end;
      end = 0;
    }
    return new Range(start, end);
  }

  private clearCacheRange() {
    this.rangeCache = null;
  }

  private setFullRange(offset: number) {
    const minBarCount = this.chart.styleOptions.minVisibleBarCount.getValue();
    const count = this.getTimeCount();
    if (this.rangeCache != null && this.rangeCache.size - this.rangeCache.offset > minBarCount) {
      this.applyRangeCache();
    } else if (count > 1) {
      const rangeSize = this.adjustRangeSize(count);
      const indexOffset = offset / this.getBarWidth(rangeSize);
      const endIndex = count + indexOffset;
      const startIndex = endIndex - rangeSize;
      const initialOffset = this.chart.styleOptions.initialOffset.getValue();
      if (offset - endIndex <= minBarCount && offset !== initialOffset) {
        this.setFullRange(initialOffset);
      } else {
        this.setRange(startIndex, endIndex);
      }
    }
  }

  private updateLabels() {
    if (this.getTimeCount() === 0) {
      this.labels = [];
      return;
    }
    this.throttledLabelUpdate.run();
  }

  private executeLabelUpdate() {
    const items = this.store.getItems();
    this.labels = this.labelCalculator.getLabels(items);
    this.updateVisibleLabels();
    this.layer.requireDraw();
  }

  private updateVisibleLabels() {
    this.visibleLabels = [];
    const position = this.getPosition();
    const minDistance = this.chart.styleOptions.minTimeLabelDistance.getValue();
    const drawRanges = new LinkedList(new LabelDrawRange(this.labels, position.xStart, position.xEnd, true));
    const fontInfo = this.chart.getFontInfo();
    while (true) {
      const range = drawRanges.popHead()?.data;
      if (range == null) {
        return;
      }
      const labels = range.labels;
      const xStart = range.xStart;
      const xEnd = range.xEnd;
      if (labels.length === 0 || xStart > xEnd) {
        continue;
      }
      const middleIndex = Math.floor(labels.length / 2);
      const middleLabel = labels[middleIndex];
      const middleX = Math.round(this.getXForIndex(middleLabel.index));
      let offset = 0;
      if (middleX >= xStart && middleX <= range.xEnd) {
        const visibleLabel = new VisibleTimeLabel(middleLabel, middleX, range.isBold);
        this.visibleLabels.push(visibleLabel);
        const size = this.drawer.measureText(middleLabel.text, fontInfo);
        offset = size.width / 2;
      }

      let labelsBefore: TimeAxisLabel[];
      let isBold: boolean;
      if (middleIndex > 0) {
        labelsBefore = labels.slice(0, middleIndex);
        isBold = range.isBold;
      } else {
        labelsBefore = middleLabel.before;
        isBold = false;
      }
      drawRanges.add(new LabelDrawRange(labelsBefore, xStart, middleX - offset - minDistance, isBold));

      let labelsAfter: TimeAxisLabel[];
      if (middleIndex < labels.length - 1) {
        labelsAfter = labels.slice(middleIndex + 1, labels.length);
        isBold = range.isBold;
      } else {
        labelsAfter = middleLabel.after;
        isBold = false;
      }
      drawRanges.add(new LabelDrawRange(labelsAfter, middleX + offset + minDistance, xEnd, isBold));
    }
  }

  private drawBorder() {
    const position = this.getPosition();
    const lineY = Math.round(position.yStart);
    const lineStart = new Point(position.xStart, lineY);
    const lineEnd = new Point(position.xEnd, lineY);
    const color = this.chart.optionManager.chartColor.axisLine.getValue();
    this.drawer.drawLine(lineStart, lineEnd, color);
  }

  private drawLabels() {
    const position = this.getPosition();
    const fontInfo = this.chart.getFontInfo();
    const textY = Math.round((position.yStart + position.yEnd) / 2);
    const color = this.chart.optionManager.chartColor.axisLabel.getValue();
    for (const visibleLabel of this.visibleLabels) {
      const textPosition = new Point(visibleLabel.x, textY);
      fontInfo.isBold = visibleLabel.isBold;
      this.drawer.printText(textPosition, visibleLabel.label.text, color, this.textAlignment, fontInfo);
    }
  }
}

<template>
  <LandingPageWrapper>
    <LandingPageSection class="flex-grow-1 py-md-5 py-3">
      <div v-if="product == null" class="landing-header">
        {{ translationStore.getTranslation("product#not_found") }}
      </div>
      <div v-else>
        <div class="landing-header">
          {{ product.name }}
        </div>
        <div v-if="!product.isActive" class="landing-sub-header mt-3">
          {{ translationStore.getTranslation("product#not_available") }}
        </div>
        <div v-else class="landing-section-split product-info mt-3">
          <div class="mw-100">
            <ProductImageCarousel :product="product" />
            <ProductVariantSelector v-model="variant" :product="product" class="mt-3" />
            <div v-if="variant != null" class="mt-3 product-price-information">
              <ProductPriceInformation :product="product" :variant="variant" />
              <ProductOfferValidUntil v-if="variant.offerData != null" :offerData="variant.offerData" />
            </div>
            <AddToCartButton :product="product" :variant="variant" class="mt-3" />
          </div>
          <ProductDetail :product="product" />
        </div>
      </div>
      <hr class="mt-4" />
      <RouterLink to="/shop" class="btn btn-primary mt-3">
        {{ translationStore.getTranslation("product#back_to_shop") }}
      </RouterLink>
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { paymentStore } from "@/stores/payment-store";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import { translationStore } from "@/stores/translation-store";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import ProductImageCarousel from "@/views/pages-landing/product/ProductImageCarousel.vue";
import type { ShoppingProduct, ShoppingProductVariant } from "@/api/models/shopping-cart";
import ProductVariantSelector from "@/views/pages-landing/product/ProductVariantSelector.vue";
import ProductPriceInformation from "@/views/pages-landing/product/ProductPriceInformation.vue";
import ProductOfferValidUntil from "@/views/pages-landing/product/ProductOfferValidUntil.vue";
import ProductDetail from "@/views/pages-landing/product/ProductDetail.vue";
import AddToCartButton from "@/views/pages-landing/AddToCartButton.vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "ProductPage",

  components: {
    AddToCartButton, ProductDetail, ProductOfferValidUntil,
    ProductPriceInformation, ProductVariantSelector, ProductImageCarousel, LandingPageSection,
    LandingPageWrapper, RouterLink
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      id: this.$route.params.id as string,
      variant: null as ShoppingProductVariant | null
    };
  },

  computed: {
    product() {
      return paymentStore().getProduct(this.id);
    }
  },

  watch: {
    product(value: ShoppingProduct | null) {
      this.variant = value?.defaultVariant ?? null;
    }
  }
});
</script>

<style scoped>
.product-info {
  align-items: start;
}

.product-price-information {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
}
</style>
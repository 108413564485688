import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { LineElement, PolygonElement } from "@/anfin-chart/drawable";
import { getAngle, getAngleVector, getCenterPoint, getLineDistance, Point, Vector } from "@/anfin-chart/geometry";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { FixedSelectablePoint, PositionSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class TiltedRectangleTool extends UserToolDefinition {

  public static readonly type = "trect";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(18, 173, 196));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(69, 103, 138, 0.25));

  private mainVector = new Vector(0, 0);
  private offVector = new Vector(0, 0);
  private readonly center = new ChartToolPoint();
  private readonly leftBottom = new ChartToolPoint();
  private readonly rightBottom = new ChartToolPoint();
  private readonly leftTop = new ChartToolPoint();
  private readonly rightTop = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(TiltedRectangleTool.type, instrument, timeframe, 3, 3);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withExactPoints(2, () => this.createSecond(tool));
    tool.withAtLeastPoints(3, () => this.createThird(tool));
  }

  public override updatePosition(tool: UserTool) {
    if (this.fixedPoints.length >= 3) {
      const positions = this.fixedPoints.map(p => tool.getPosition(p));
      const center = getCenterPoint(positions[0], positions[1]);
      tool.updatePoint(this.center, center);
      this.updateCornerMatrix(center, positions);
      const mainVectorInverted = this.mainVector.asScaled(-1);
      const offVectorInverted = this.offVector.asScaled(-1);
      const leftBottom = mainVectorInverted.addTo(this.offVector.addTo(center));
      tool.updatePoint(this.leftBottom, leftBottom);
      const rightBottom = this.mainVector.addTo(this.offVector.addTo(center));
      tool.updatePoint(this.rightBottom, rightBottom);
      const leftTop = mainVectorInverted.addTo(offVectorInverted.addTo(center));
      tool.updatePoint(this.leftTop, leftTop);
      const rightTop = this.mainVector.addTo(offVectorInverted.addTo(center));
      tool.updatePoint(this.rightTop, rightTop);
    }
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const [first, second] = this.fixedPoints;
    const line = new LineElement(tool, first, second);
    tool.onUpdate(() => {
      line.color = this.lineColor.getValue();
      line.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, second);
  }

  private createThird(tool: UserTool) {
    const polygon = new PolygonElement(tool, [this.leftBottom, this.rightBottom, this.rightTop, this.leftTop]);
    tool.onUpdate(() => {
      polygon.areaColor = this.areaColor.getValue();
      polygon.lineColor = this.lineColor.getValue();
      polygon.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, this.fixedPoints[1]);
    new PositionSelectablePoint(tool, this.leftBottom, point => this.updateCorner(tool, point));
    new PositionSelectablePoint(tool, this.leftTop, point => this.updateCorner(tool, point));
    new PositionSelectablePoint(tool, this.rightBottom, point => this.updateCorner(tool, point));
    new PositionSelectablePoint(tool, this.rightTop, point => this.updateCorner(tool, point));
  }

  private updateCornerMatrix(center: Point, positions: Point[]) {
    const [first, second, third] = positions;
    this.mainVector = new Vector(second.x - center.x, second.y - center.y);
    const offAngle = getAngle(first, second) + Math.PI / 2;
    const offDistance = getLineDistance(first, second, third);
    this.offVector = getAngleVector(offAngle, offDistance);
  }

  private updateCorner(tool: UserTool, point: Point) {
    const center = tool.getPosition(this.center);
    const mx = this.mainVector.x;
    const my = this.mainVector.y;
    const ox = this.offVector.x;
    const oy = this.offVector.y;
    const px = point.x - center.x;
    const py = point.y - center.y;
    const b = (px * my - py * mx) / (ox * my - mx * oy);
    const x = center.x + ox * b;
    const y = center.y + oy * b;
    const index = Math.round(tool.chart.timeAxis.getIndexForX(x));
    const time = tool.chart.timeAxis.getTime(index);
    const price = tool.subChart.priceAxis.getPrice(y);
    if (time != null) {
      this.fixedPoints[2].update(time, price);
    }
  }
}

import { AutoFibonacciTool } from "@/anfin-chart/tools/analysis-tools/auto-fibonacci";
import type { Chart } from "@/anfin-chart/chart";
import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { ChartDrawer } from "@/anfin-chart/draw/chart-drawer";
import { Rectangle, Size } from "@/anfin-chart/geometry";
import { ChartHint, HintTable, HintText } from "@/anfin-chart/hints/chart-hint";
import type { Clickable } from "@/anfin-chart/interactions";
import { formatNumber, simpleCompare } from "@/anfin-chart/utils";
import { AutoFibonacci, AutoFibonacciType, getFibonacciTypeColor } from "@/api/models/analysis/auto-fibonacci";

class FibonacciText extends HintText implements Clickable {

  constructor(hint: ChartHint,
              private readonly chart: Chart,
              private readonly tool: AutoFibonacciTool,
              text: string) {
    super(hint, text, new Size(8, 2));
  }

  public onClick() {
    const timeAxis = this.chart.timeAxis;
    const startIndex = timeAxis.getIndexForTime(this.tool.definition.startTime);
    const endIndex = timeAxis.getLastIndex();
    const offset = (endIndex - startIndex) * 0.05;
    timeAxis.setRange(startIndex - offset, endIndex + offset);
  }

  protected override getTextColor() {
    return this.tool.isHovered ? RGBAColor.white : getFibonacciTypeColor(this.tool.definition.fibonacciType);
  }

  protected override getBackgroundColor() {
    return this.tool.isHovered ? getFibonacciTypeColor(this.tool.definition.fibonacciType) : RGBAColor.transparent;
  }
}

export class FibonacciHint extends ChartHint {

  private static readonly typeOrder = [AutoFibonacciType.BasicX, AutoFibonacciType.Basic1, AutoFibonacciType.Basic2,
    AutoFibonacciType.Trend, AutoFibonacciType.SubTrend];

  public constructor(chart: Chart) {
    super(chart);
  }

  private static compareFibonacci(first: AutoFibonacci, second: AutoFibonacci) {
    const firstTypeIndex = this.typeOrder.indexOf(first.fibonacciType);
    const secondTypeIndex = this.typeOrder.indexOf(second.fibonacciType);
    const typeCompare = simpleCompare(firstTypeIndex, secondTypeIndex);
    if (typeCompare !== 0) {
      return typeCompare;
    }
    return simpleCompare(first.startTime, second.startTime);
  }

  public updateItems() {
    this.clear();
    if (this.chart.showFibonacciHint) {
      const fibonacciTools: AutoFibonacciTool[] = [];
      for (const tool of this.chart.getAnalysisTools()) {
        if (tool instanceof AutoFibonacciTool) {
          fibonacciTools.push(tool);
        }
      }
      fibonacciTools.sort((a, b) => FibonacciHint.compareFibonacci(a.definition, b.definition));
      if (fibonacciTools.length > 0) {
        this.show();
      } else {
        this.hide();
      }
      this.createTable(fibonacciTools);
    } else {
      this.hide();
    }
  }

  protected override getPosition(drawer: ChartDrawer) {
    const size = this.getSize(drawer);
    const basePosition = this.chart.drawingAreaWrapper.getPosition();
    const xStart = basePosition.xStart + this.chart.styleOptions.fibonacciHintMarginLeft.getValue();
    const xEnd = xStart + size.width;
    const yEnd = basePosition.yEnd - this.chart.styleOptions.fibonacciHintMarginBottom.getValue();
    const yStart = yEnd - size.height;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private createTable(fibonacciTools: AutoFibonacciTool[]) {
    const rows = this.createRows();
    for (const tool of fibonacciTools) {
      const analysis = tool.definition;
      const typeItem = new FibonacciText(this, this.chart, tool, analysis.caption);
      const ratioItem = new FibonacciText(this, this.chart, tool, formatNumber(analysis.ratio, 2));
      const consolidationFactorText = formatNumber(analysis.consolidationFactor, 2);
      const consolidationFactorItem = new FibonacciText(this, this.chart, tool, consolidationFactorText);
      const directionText = analysis.endPrice > analysis.startPrice ? "Up" : "Down";
      const directionItem = new FibonacciText(this, this.chart, tool, directionText);
      rows[0].push(typeItem);
      rows[1].push(ratioItem);
      rows[2].push(consolidationFactorItem);
      rows[3].push(directionItem);
    }
    const columnSpacing = this.chart.styleOptions.hintColumnSpacing.getValue();
    const table = new HintTable(this, fibonacciTools.length + 1, columnSpacing);
    for (const row of rows) {
      table.addRow(...row);
    }
    this.addItem(table);
  }

  private createRows() {
    return [
      [new HintText(this, "")],
      [new HintText(this, "Fib Ratio")],
      [new HintText(this, "Conso Factor")],
      [new HintText(this, "Direction")]
    ];
  }
}

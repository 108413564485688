import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { LineElement, PolygonElement, TextBox } from "@/anfin-chart/drawable";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { alignAvoid, alignTriangle } from "@/anfin-chart/tools/user-tools/alignment";
import { formatNumber } from "@/anfin-chart/utils";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { getCenterPoint } from "@/anfin-chart/geometry";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class XABCDPattern extends UserToolDefinition {

  public static readonly type = "xabcd";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(0, 116, 178));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(71, 122, 81, 0.25));

  private readonly xbPoint = new ChartToolPoint();
  private readonly acPoint = new ChartToolPoint();
  private readonly bdPoint = new ChartToolPoint();
  private readonly xdPoint = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(XABCDPattern.type, instrument, timeframe, 5, 5);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
    tool.withAtLeastPoints(3, () => this.createThird(tool));
    tool.withAtLeastPoints(4, () => this.createFourth(tool));
    tool.withAtLeastPoints(5, () => this.createFifth(tool));
  }

  public override updatePosition(tool: UserTool) {
    const [x, a, b, c, d] = this.fixedPoints.map(p => tool.getPosition(p));
    if (this.fixedPoints.length >= 3) {
      const xb = getCenterPoint(x, b);
      tool.updatePoint(this.xbPoint, xb);
    }
    if (this.fixedPoints.length >= 4) {
      const ac = getCenterPoint(a, c);
      tool.updatePoint(this.acPoint, ac);
    }
    if (this.fixedPoints.length >= 5) {
      const bd = getCenterPoint(b, d);
      tool.updatePoint(this.bdPoint, bd);
      const xd = getCenterPoint(x, d);
      tool.updatePoint(this.xdPoint, xd);
    }
  }

  private createFirst(tool: UserTool) {
    const [x] = this.fixedPoints;
    const xText = new TextBox(tool, x);
    xText.setText("X");
    tool.onUpdate(() => {
      xText.color = this.lineColor.getValue();
      xText.alignment = alignAvoid(tool, x, this.fixedPoints[1], this.fixedPoints[2], this.fixedPoints[3], this.fixedPoints[4]);
    });
    new FixedSelectablePoint(tool, x);
  }

  private createSecond(tool: UserTool) {
    const [x, a] = this.fixedPoints;
    const aText = new TextBox(tool, a);
    aText.setText("A");
    const xaLine = new LineElement(tool, x, a);
    tool.onUpdate(() => {
      aText.color = this.lineColor.getValue();
      aText.alignment = alignAvoid(tool, a, x, this.fixedPoints[2], this.fixedPoints[3], this.fixedPoints[4]);
      xaLine.color = this.lineColor.getValue();
      xaLine.options.width = tool.getLineWidth(false);
    });
    new FixedSelectablePoint(tool, a);
  }

  private createThird(tool: UserTool) {
    const [x, a, b] = this.fixedPoints;
    const bText = new TextBox(tool, b);
    bText.setText("B");
    const abLine = new LineElement(tool, a, b);
    const xbLine = new LineElement(tool, x, b);
    const xabTriangle = new PolygonElement(tool, [x, a, b]);
    xabTriangle.ignoreHit();
    const xbTextBox = new TextBox(tool, this.xbPoint);
    tool.onUpdate(() => {
      const lineColor = this.lineColor.getValue();
      const lineWidth = tool.getLineWidth(false);
      bText.color = lineColor;
      bText.alignment = alignAvoid(tool, b, x, a, this.fixedPoints[3], this.fixedPoints[4]);
      abLine.color = lineColor;
      abLine.options.width = lineWidth;
      xbLine.color = lineColor;
      xbLine.options.width = lineWidth;
      xabTriangle.areaColor = this.areaColor.getValue();
      const xabValue = Math.abs(b.price - a.price) / Math.abs(x.price - a.price);
      xbTextBox.color = lineColor;
      xbTextBox.alignment = alignTriangle(tool, x, b, a);
      xbTextBox.setText(formatNumber(xabValue, 2));
    });
    new FixedSelectablePoint(tool, b);
  }

  private createFourth(tool: UserTool) {
    const [x, a, b, c] = this.fixedPoints;
    const cText = new TextBox(tool, c);
    cText.setText("C");
    const acLine = new LineElement(tool, a, c);
    const bcLine = new LineElement(tool, b, c);
    const acTextBox = new TextBox(tool, this.acPoint);
    tool.onUpdate(() => {
      const lineColor = this.lineColor.getValue();
      const lineWidth = tool.getLineWidth(false);
      cText.color = lineColor;
      cText.alignment = alignAvoid(tool, c, x, a, b, this.fixedPoints[4]);
      acLine.color = lineColor;
      acLine.options.width = lineWidth;
      bcLine.color = lineColor;
      bcLine.options.width = lineWidth;
      const abcValue = Math.abs(c.price - b.price) / Math.abs(a.price - b.price);
      acTextBox.color = lineColor;
      acTextBox.alignment = alignTriangle(tool, a, c, b);
      acTextBox.setText(formatNumber(abcValue, 2));
    });
    new FixedSelectablePoint(tool, c);
  }

  private createFifth(tool: UserTool) {
    const [x, a, b, c, d] = this.fixedPoints;
    const dText = new TextBox(tool, d);
    dText.setText("D");
    const bdLine = new LineElement(tool, b, d);
    const cdLine = new LineElement(tool, c, d);
    const bcdTriangle = new PolygonElement(tool, [b, c, d]);
    bcdTriangle.ignoreHit();
    const bdTextBox = new TextBox(tool, this.bdPoint);
    const xdLine = new LineElement(tool, x, d);
    const xdTextBox = new TextBox(tool, this.xdPoint);
    tool.onUpdate(() => {
      const lineColor = this.lineColor.getValue();
      const lineWidth = tool.getLineWidth(false);
      dText.color = lineColor;
      dText.alignment = alignAvoid(tool, d, x, a, b, c);
      bdLine.color = lineColor;
      bdLine.options.width = lineWidth;
      cdLine.color = lineColor;
      cdLine.options.width = lineWidth;
      bcdTriangle.areaColor = this.areaColor.getValue();
      bdTextBox.color = lineColor;
      bdTextBox.alignment = alignTriangle(tool, b, d, c);
      const bcdValue = Math.abs(d.price - c.price) / Math.abs(b.price - c.price);
      bdTextBox.setText(formatNumber(bcdValue, 2));
      xdLine.color = lineColor;
      xdLine.options.width = lineWidth;
      const xadValue = Math.abs(d.price - a.price) / Math.abs(x.price - a.price);
      xdTextBox.color = lineColor;
      xdTextBox.alignment = alignTriangle(tool, x, d, a);
      xdTextBox.setText(formatNumber(xadValue, 2));
    });
    new FixedSelectablePoint(tool, d);
  }
}

import { UserToolAction } from "@/anfin-chart/actions/user-tool-action";
import { ChartArea } from "@/anfin-chart/area/chart-area";
import type { Chart } from "@/anfin-chart/chart";
import { Drawable } from "@/anfin-chart/drawable";
import { isWithin, Point } from "@/anfin-chart/geometry";
import { HintItem } from "@/anfin-chart/hints/chart-hint";
import type {
  Clickable,
  DoubleClickable,
  Draggable,
  Scrollable,
  TypeGuard
} from "@/anfin-chart/interactions";
import {
  ClickData,
  DragData,
  isClickable,
  isDoubleClickable,
  isDraggable,
  isScrollable,
  ScrollData
} from "@/anfin-chart/interactions";
import { Plot } from "@/anfin-chart/plot";
import { ChartTool } from "@/anfin-chart/tools/chart-tool";
import { ResizeArea } from "@/anfin-chart/area/resize-area";
import { UserTool } from "@/anfin-chart/tools/user-tool";
import type { Hoverable } from "@/anfin-chart/mixins/hoverable";
import { isHoverable } from "@/anfin-chart/mixins/hoverable";

export class Selection<T> {

  constructor(public readonly selectable: Selectable<T>,
              public readonly position: Point) {
  }

  public get target() {
    return this.selectable.target;
  }
}

export class Selectable<T> {

  constructor(public readonly target: T,
              public readonly relatives: T[] = []) {
  }

  public contains(item: T) {
    return this.target === item || this.relatives.includes(item);
  }
}

export class SelectableGroup<T> {

  public readonly items: Selectable<T>[] = [];

  public add(target: T, relatives: T[] = []) {
    this.items.push(new Selectable(target, relatives));
  }

  public findSelectables(point: Point, currentSelectables: Selectable<T>[]) {
    const candidates: Selectable<T>[] = [];
    for (const item of this.items) {
      if (this.checkSelection(point, item.target)) {
        candidates.push(item);
      }
    }
    const matchingCandidates = candidates.filter(s => currentSelectables.some(c => c.contains(s.target)));
    if (matchingCandidates.length > 0) {
      return matchingCandidates;
    }
    return candidates;
  }

  private checkSelection(point: Point, target: unknown) {
    if (target instanceof ChartArea) {
      return isWithin(target.getPosition(), point);
    }
    if (target instanceof Drawable) {
      return target.isHit(point);
    }
    if (target instanceof ChartTool) {
      return target.getDrawables().some(d => d.getIsVisible() && d.isHit(point));
    }
    if (target instanceof HintItem) {
      return isWithin(target.position, point);
    }
    if (target instanceof Plot) {
      return target.isHit(point);
    }
    return false;
  }
}

export class SelectionHandler<T> {

  protected selections: Selection<T>[] = [];
  private position = new Point(0, 0);

  constructor(protected readonly chart: Chart,
              private readonly typeGuard: TypeGuard<T>,
              private readonly allowMultiple: boolean,
              private readonly preferCurrent: boolean) {
  }

  public getTargets() {
    return this.selections.map(s => s.target);
  }

  public getPosition() {
    return this.position;
  }

  public setSelections(selections: Selection<T>[]) {
    this.selections = selections;
  }

  public unselect() {
    this.setSelections([]);
  }

  protected update(position: Point) {
    this.position = position;
    const results: Selection<T>[] = [];
    const currentSelectables = this.preferCurrent ? this.selections.map(s => s.selectable) : [];
    const selectableGroups = this.getSelectableGroups();
    for (const group of selectableGroups) {
      const selectables = group.findSelectables(position, currentSelectables);
      const selections = selectables.map(s => new Selection(s, position));
      results.push(...selections);
    }
    const selections = this.allowMultiple ? results : results.slice(0, 1);
    this.setSelections(selections);
  }

  private getSelectableGroups() {
    return [
      this.getHintItemGroup(),
      ...this.getToolGroups(),
      this.getPlotGroup(),
      ...this.getAreaGroups()
    ];
  }

  private getHintItemGroup() {
    const group = new SelectableGroup<T>();
    for (const hint of this.chart.getHints()) {
      for (const item of hint.getAllItems()) {
        if (this.typeGuard(item)) {
          group.add(item);
        }
      }
    }
    return group;
  }

  private getToolGroups() {
    const subChart = this.chart.mouseData.getSubChart();
    const chartTools = subChart?.getChartTools() ?? [];
    const drawableGroup = new SelectableGroup<T>();
    const toolGroup = new SelectableGroup<T>();
    for (let toolIndex = chartTools.length - 1; toolIndex >= 0; toolIndex--) {
      const tool = chartTools[toolIndex];
      if (this.chart.action instanceof UserToolAction && this.chart.action.tool === tool || !tool.getIsVisible()) {
        continue;
      }
      const isToolRelative = this.typeGuard(tool);
      const toolRelatives: T[] = [];
      const drawableRelatives: T[] = isToolRelative ? [tool] : [];
      const drawables = tool.getDrawables();
      for (let drawableIndex = drawables.length - 1; drawableIndex >= 0; drawableIndex--) {
        const drawable = drawables[drawableIndex];
        if (this.typeGuard(drawable) && drawable.getIsVisible()) {
          toolRelatives.push(drawable);
          drawableGroup.add(drawable, drawableRelatives);
        }
      }
      if (isToolRelative) {
        toolGroup.add(tool, toolRelatives);
      }
    }
    return [drawableGroup, toolGroup];
  }

  private getPlotGroup() {
    const group = new SelectableGroup<T>();
    const subChart = this.chart.mouseData.getSubChart();
    const plots = subChart?.getPlots() ?? [];
    for (const plot of plots) {
      if (this.typeGuard(plot)) {
        group.add(plot);
      }
    }
    return group;
  }

  private getAreaGroups() {
    const priorityGroup = new SelectableGroup<T>();
    const group = new SelectableGroup<T>();
    const areas = this.chart.getAreas().filter(a => a.getIsVisible());
    for (const area of areas) {
      if (!this.typeGuard(area)) {
        continue;
      }
      if (area instanceof ResizeArea) {
        priorityGroup.add(area);
      } else {
        group.add(area);
      }
    }
    return [priorityGroup, group];
  }
}

export class DragHandler extends SelectionHandler<Draggable> {

  constructor(chart: Chart) {
    super(chart, isDraggable, false, true);
  }

  public override setSelections(selections: Selection<Draggable>[]) {
    super.setSelections(selections);
    this.chart.onDragSelectionChange();
  }

  public onDragStart(data: ClickData) {
    if (data.touchCount === 1) {
      this.update(data.position);
    }
  }

  public onDragMove(data: DragData) {
    this.getTargets().forEach(t => t.onDrag(data));
  }

  public onDragEnd(data: ClickData) {
    for (const target of this.getTargets()) {
      target.onDragEnd?.(data);
    }
  }
}

export class HoverHandler extends SelectionHandler<Hoverable> {

  constructor(chart: Chart) {
    super(chart, isHoverable, true, false);
  }

  public override unselect() {
    for (const selection of this.selections) {
      selection.target.onStopHover();
    }
    super.unselect();
  }

  public onHover(data: ClickData) {
    const previousTargets = new Set(this.getTargets());
    this.update(data.position);
    const newTargets = [];
    for (const selection of this.selections) {
      if (previousTargets.has(selection.target)) {
        previousTargets.delete(selection.target);
      } else {
        newTargets.push(selection.target);
      }
    }
    previousTargets.forEach(t => t.onStopHover());
    newTargets.forEach(t => t.onStartHover());
  }

  protected override update(position: Point) {
    super.update(position);
    let hasUserTool = false;
    let i = 0;
    while (i < this.selections.length) {
      const target = this.selections[i].target;
      const isUserTool = target instanceof UserTool || target instanceof Drawable && target.tool instanceof UserTool;
      if (isUserTool) {
        if (hasUserTool) {
          this.selections.splice(i, 1);
          continue;
        } else {
          hasUserTool = true;
        }
      }
      i++;
    }
  }
}

export class ClickHandler extends SelectionHandler<Clickable> {

  constructor(chart: Chart) {
    super(chart, isClickable, false, false);
  }

  public onClick(data: ClickData) {
    this.update(data.position);
    if (this.selections.length > 0) {
      this.selections[0].target.onClick(data);
    }
  }
}

export class DoubleClickHandler extends SelectionHandler<DoubleClickable> {

  constructor(chart: Chart) {
    super(chart, isDoubleClickable, false, false);
  }

  public onDoubleClick(data: ClickData) {
    this.update(data.position);
    if (this.selections.length > 0) {
      this.selections[0].target.onDoubleClick(data);
    }
  }
}

export class ScrollHandler extends SelectionHandler<Scrollable> {

  constructor(chart: Chart) {
    super(chart, isScrollable, false, false);
  }

  public onScroll(data: ScrollData) {
    this.update(data.position);
    if (this.selections.length > 0) {
      this.selections[0].target.onScroll(data);
    }
  }
}

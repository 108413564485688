import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { ChartError } from "@/anfin-chart/error";
import { Rectangle } from "@/anfin-chart/geometry";

export enum SvgDrawMethod {
  Fill = 1,
  Stroke = 2
}

export class SVG {

  constructor(public readonly key: string,
              public readonly paths: SVGPath[],
              public readonly viewPort: Rectangle = new Rectangle(0, 0, 18, 18)) {
  }
}

export class SVGPath {

  constructor(public readonly path: string,
              public readonly drawMethod = SvgDrawMethod.Stroke,
              public readonly color = RGBAColor.black,
              public readonly lineWidth = 1,
              public readonly lineCap: CanvasLineCap = "butt") {
  }
}

export class IconStore {

  private readonly iconMap = new Map<string, SVG>();

  public addIcon(icon: SVG) {
    this.iconMap.set(icon.key, icon);
  }

  public getIcon(key: string) {
    const icon = this.iconMap.get(key);
    if (icon == null) {
      throw new ChartError("Could not find icon for key: " + key);
    }
    return icon;
  }
}

<template>
  <IconElement iconName="Category" :color="getColor(category)" :size="24" />
</template>

<script lang="ts">
import { getCategoryColor } from "@/api/models/symbol-category";
import type { SymbolCategory } from "@/api/models/symbol-category";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "WatchlistCategoryIcon",

  components: { IconElement },

  props: {
    category: {
      type: Number as PropType<SymbolCategory>,
      required: true
    }
  },

  expose: [],

  methods: {
    getColor(category: SymbolCategory) {
      return getCategoryColor(category);
    }
  }
});
</script>

<style scoped>
</style>

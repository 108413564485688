<template>
  <AutoFilterList
    :id="id"
    ref="filterList"
    :items="displayList"
    :itemProjection="itemProjection"
    :placeholder="placeholder"
    :showIconRight="showIconRight"
    :icon="icon"
    @onInput="setFilterText"
    @onFocus="setFilterText"
    @selectItem="selectItem"
    @onBlur="onBlur"
  >
    <template #default="props">
      <div class="instrument-search-item">
        <div class="search-col-symbol" style="min-width: 70px;" v-html="boldMatchText(props.item.name, props.input)"></div>
        <div class="flex-grow-1" v-html="boldMatchText(props.item.caption, props.input)"></div>
        <div class="search-col-exchange" style="min-width: 50px;">
          {{ props.item.exchange }}
        </div>
        <span v-if="showAsset" class="search-col-asset" style="width: 10%"> {{ props.item.asset }}</span>
        <span v-if="showCurrency" class="search-col-currency" style="width: 4%"> {{ props.item.currency }}</span>
      </div>
    </template>
  </AutoFilterList>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AutoFilterList from "@/views/components/AutoFilterList.vue";
import type { InstrumentDetail } from "@/api/models/instrument-detail";
import { instrumentStore } from "@/stores/instrument-store";

export default defineComponent({
  name: "InstrumentFilterList",

  components: { AutoFilterList },

  props: {
    id: {
      type: String,
      required: true
    },
    itemProjection: {
      type: Function,
      default(item: any) {
        return item;
      }
    },
    limit: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: ""
    },
    useHistory: Boolean,
    showAsset: Boolean,
    showCurrency: Boolean,
    showIconRight: Boolean,
    icon: {
      type: String,
      default: ""
    }
  },

  emits: {
    selectItem: (item: any) => item || true,
    onBlur: () => true
  },

  expose: ["clearInput"],

  data() {
    return {
      filterText: ""
    };
  },

  computed: {
    displayList(): InstrumentDetail[] {
      const existingSymbols = new Set();
      const filterText = this.filterText.toUpperCase();
      const displayList = [];
      if (this.useHistory) {
        const instrumentHistory = instrumentStore().instrumentHistory;
        for (let i = instrumentHistory.length - 1; i >= 0; i--) {
          const symbol = instrumentHistory[i];
          const detail = instrumentStore().getDetail(symbol);
          const matches = detail != null && (filterText === "" || detail.checkSearch(filterText, false));
          if (matches && displayList.length < this.limit) {
            existingSymbols.add(detail.symbol);
            displayList.push(detail);
          }
        }
      }
      if (filterText !== "") {
        const instrumentDetails = instrumentStore().instrumentDetails;
        for (const detail of instrumentDetails) {
          if (!existingSymbols.has(detail.symbol) && detail.checkSearch(filterText, true) && displayList.length < this.limit) {
            existingSymbols.add(detail.symbol);
            displayList.push(detail);
          }
        }
        for (const detail of instrumentDetails) {
          if (!existingSymbols.has(detail.symbol) && detail.checkSearch(filterText, false) && displayList.length < this.limit) {
            displayList.push(detail);
          }
        }
      }
      return displayList;
    }
  },

  methods: {
    escapeRegExp(text: string) {
      return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },

    boldMatchText(text: string, input: string) {
      const regexp = new RegExp(`(${this.escapeRegExp(input)})`, "ig");
      return text.replace(regexp, "<strong>$1</strong>");
    },

    setFilterText(text: string) {
      this.filterText = text;
    },

    selectItem(item: any) {
      this.$emit("selectItem", item);
    },

    onBlur() {
      this.clearInput();
      this.$emit("onBlur");
    },

    clearInput() {
      (this.$refs.filterList as typeof AutoFilterList).clearInput();
    }
  }
});
</script>

<style scoped>
.instrument-search-item {
  display: flex;
  width: 100%;
  gap: 5px;
}
</style>
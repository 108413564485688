import type { RangeCache } from "@/anfin-chart/area/time-axis";
import type { SVG } from "@/anfin-chart/icon-store";
import type { Instrument } from "@/anfin-chart/instrument";
import type { ChartObject } from "@/anfin-chart/options/option";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import type { Consumer } from "@/anfin-chart/utils";
import type { Chart } from "@/anfin-chart/chart";
import type { Alert } from "@/api/models/alert";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export interface ChartCallbacks {
  onInitializationFinished(): void;
  requestExchangeInfos(): void;
  requestHistory(instrument: Instrument, timeframe: Timeframe, count: number, beforeTime: number | null): void;
  loadUserTools(instrument: Instrument, timeframe: Timeframe): void;
  saveUserTool(tool: UserToolDefinition): void;
  deleteUserTool(tool: UserToolDefinition): void;
  saveAlert(alert: Alert): void;
  onInstrumentChange(instrument: Instrument): void;
  onTimeframeChange(timeframe: Timeframe): void;
  onMouseMove(chart: Chart, time: number | null, price: number | null): void;
  onMouseLeave(): void;
  saveExport(): void;
  loadRangeCache(instrument: Instrument, timeframe: Timeframe): void;
  saveRangeCache(instrument: Instrument, timeframe: Timeframe, cache: RangeCache): void;
  editChartObject(chartObject: ChartObject | null): void;
  onActionChange(key: string): void;
  getTranslation(key: string): string;
  getInstrumentName(instrument: Instrument): string;
  getInstrumentIcon(instrument: Instrument): SVG | null;
}

export class ChartCallbackRunner implements ChartCallbacks {

  public isExternalUpdate = false;

  constructor(private readonly callbacks: ChartCallbacks) {
  }

  public onInitializationFinished() {
    this.callbacks.onInitializationFinished();
  }

  public requestExchangeInfos() {
    this.callbacks.requestExchangeInfos();
  }

  public requestHistory(instrument: Instrument, timeframe: Timeframe, count: number, beforeTime: number | null) {
    this.callbacks.requestHistory(instrument, timeframe, count, beforeTime);
  }

  public loadUserTools(instrument: Instrument, timeframe: Timeframe) {
    this.callbacks.loadUserTools(instrument, timeframe);
  }

  public saveUserTool(tool: UserToolDefinition) {
    this.runSaveAction(() => this.callbacks.saveUserTool(tool));
  }

  public deleteUserTool(tool: UserToolDefinition) {
    this.runSaveAction(() => this.callbacks.deleteUserTool(tool));
  }

  public saveAlert(alert: Alert) {
    this.runSaveAction(() => this.callbacks.saveAlert(alert));
  }

  public onInstrumentChange(instrument: Instrument) {
    this.callbacks.onInstrumentChange(instrument);
    this.saveExport();
  }

  public onTimeframeChange(timeframe: Timeframe) {
    this.callbacks.onTimeframeChange(timeframe);
    this.saveExport();
  }

  public onMouseMove(chart: Chart, time: number | null, price: number | null) {
    this.runSaveAction(() => this.callbacks.onMouseMove(chart, time, price));
  }

  public onMouseLeave() {
    this.callbacks.onMouseLeave();
  }

  public saveExport() {
    this.runSaveAction(() => this.callbacks.saveExport());
  }

  public loadRangeCache(instrument: Instrument, timeframe: Timeframe) {
    this.callbacks.loadRangeCache(instrument, timeframe);
  }

  public saveRangeCache(instrument: Instrument, timeframe: Timeframe, cache: RangeCache) {
    this.callbacks.saveRangeCache(instrument, timeframe, cache);
  }

  public editChartObject(chartObject: ChartObject | null) {
    this.callbacks.editChartObject(chartObject);
  }

  public onActionChange(key: string) {
    this.callbacks.onActionChange(key);
  }

  public getTranslation(key: string) {
    return this.callbacks.getTranslation(key);
  }

  public getInstrumentName(instrument: Instrument) {
    return this.callbacks.getInstrumentName(instrument);
  }

  public getInstrumentIcon(instrument: Instrument) {
    return this.callbacks.getInstrumentIcon(instrument);
  }

  private runSaveAction(action: Consumer<void>) {
    if (!this.isExternalUpdate) {
      action();
    }
  }
}

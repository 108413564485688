<template>
  <div>
    <div class="mb-2 font-lg">
      <div class="account-subscription-title">
        {{ abo.product.name }}
      </div>
    </div>
    <div v-if="abo.product.description" class="mb-3 mx-2">
      {{ abo.product.description }}
    </div>
    <div v-if="abo.product.description" class="mb-3 mt-4 mx-2">
      {{ contractDescription }}
    </div>
    <div v-if="abo.subscription != null">
      <div v-if="abo.subscription.currentPeriodStart && abo.subscription.pendingDowngrade === 0" class="label-group mb-2">
        <span class="col-8">aktuelle Zahlungperiode:</span>
        <span class="col-4">
          {{ formatDate(abo.subscription.currentPeriodStart) }} -
          {{ formatDate(abo.subscription.currentPeriodEnd) }}
        </span>
      </div>
      <div v-if="abo.amount > 0 && abo.subscription.pendingDowngrade > 0" class="label-group mb-2">
        <span class="col-12">
          Sie zahlen {{ (abo.amount - abo.subscription.amountOff || abo.product.defaultVariant.recurringPrice || 0).toFixed(2) + ' €' }}
          ab dem {{ formatDate(abo.subscription.pendingDowngrade * 1000) }} (Ende der Kündigungfrist des aktuellen Produktes)
        </span>
      </div>
      <div v-if="abo.amount > 0 && abo.subscription.pendingDowngrade === 0" class="label-group">
        <span class="col-8">Sie zahlen monatlich:</span>
        <span>{{ (abo.amount - abo.subscription.amountOff || abo.product.defaultVariant.recurringPrice || 0).toFixed(2) + ' €' }}</span>
      </div>
      <div v-if="abo.amount === 0" class="label-group">
        <span class="col-8">Aktueller Testzugang:</span>
        <span>{{ abo.amount.toFixed(2) + ' €' }}</span>
      </div>
      <div v-if="abo.subscription.amountOff > 0" class="label-group">
        <span class="col-8">Rabatt inkl.:</span>
        <span>{{ abo.subscription.amountOff.toFixed(2) + ' €' }}</span>
      </div>
      <div v-if="abo.subscription.cancelAt" class="label-group">
        <span class="col-8">gekündigt zum:</span>
        <span>{{ formatDate(abo.subscription.cancelAt) }}</span>
      </div>
      <div v-if="abo.product.offerValidUntil" class="label-group">
        <span class="col-8">Angebot gültig bis:</span>
        <span>{{ formatDate(abo.product.offerValidUntil) }}</span>
      </div>
      <div v-if="abo.subscription.trialEnd && !abo.subscription.cancelAt" class="label-group">
        <span class="col-8">Trial läuft bis:</span>
        <span>{{ formatDate(abo.subscription.trialEnd) }}</span>
      </div>
    </div>
    <div v-else>
      <div v-if="abo.product.defaultVariant.recurringPrice > 0" class="label-group">
        <span class="col-8">Sie zahlen monatlich:</span>
        <span>{{ abo.product.defaultVariant.recurringPrice.toFixed(2) + ' €' }}</span>
      </div>
      <div v-if="abo.product.defaultVariant.fixedPrice > 0" class="label-group">
        <span class="col-8">Sie zahlen einmalig:</span>
        <span>{{ abo.product.defaultVariant.fixedPrice.toFixed(2) + ' €' }}</span>
      </div>
    </div>
    <div v-if="abo.isActive && contractId !== ''" class="text-center mt-3">
      <button type="button" class="btn btn-primary btn-sm abo-item-button" @click="setContractInfos">
        {{ translationStore.getTranslation("account_info#contract_info") }}
      </button>
    </div>
    <div v-if="possibleAboChange !== 2 || abo.amount > 0" class="text-center my-2">
      <button type="button" class="btn btn-primary btn-sm abo-item-button" @click="setAboState">
        {{ possibleAboChangeLabel }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { ShoppingProductAbo } from "@/api/models/shopping-cart";
import { translationStore } from "@/stores/translation-store";
import { defineComponent } from "vue";
import { paymentStore } from "@/stores/payment-store";
import { DateMixin } from "@/mixins/date-mixin";

export enum AccountAboChange {
  New = 0,
  Terminate = 1,
  CancelTermination = 2,
  Extend = 3
}

export default defineComponent({
  name: "AccountInfoAboItem",

  mixins: [DateMixin],

  props: {
    abo: {
      type: ShoppingProductAbo,
      required: true
    }
  },

  emits: {
    setAboState: (type: AccountAboChange) => type || true,
    setContractInfos: (type: string) => type
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      AccountAboChange
    };
  },

  computed: {
    possibleAboChange() {
      const subscription = this.abo.subscription;
      if (subscription != null && !subscription.cancelAt && !subscription.trialEnd && subscription.pendingDowngrade === 0) {
        return AccountAboChange.Terminate;
      }
      if (subscription != null && subscription.cancelAt !== 0 && this.abo.isActive) {
        return AccountAboChange.CancelTermination;
      }
      if (subscription != null && subscription.trialEnd !== 0 && subscription.cancelAt === 0) {
        return AccountAboChange.Extend;
      }
      if (!this.abo.isActive) {
        return AccountAboChange.New;
      }
      throw new Error("Unknown abo state");
    },

    possibleAboChangeLabel() {
      let key = "abo_change#" + this.possibleAboChange;
      if (this.possibleAboChange === AccountAboChange.New) {
        const aboQualifier = this.abo.product.defaultVariant.recurringPrice > 0 ? "recurring" : "fixed";
        key += "_" + aboQualifier;
      }
      return this.translationStore.getTranslation(key);
    },

    contractDescription() {
      let description = this.abo.product.defaultVariant.description;
      if (this.abo.subscription) {
        const product = paymentStore().getProduct(this.abo.subscription.productId);
        const variants = product?.variants.filter(value => value.id === this.abo.subscription?.id);
        if (variants && variants.length > 0) {
          description = variants[0].description;
        }
      }
      return description;
    },

    contractId() {
      return this.abo.subscription?.id ?? "";
    }
  },

  methods: {
    setAboState() {
      this.$emit("setAboState", this.possibleAboChange);
    },

    setContractInfos() {
      this.$emit("setContractInfos", this.contractId);
    }
  }
});
</script>

<style scoped>
.label-group {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.abo-item-button {
  width: 230px;
}

.account-subscription-title {
  font-weight: bold;
}
</style>

<template>
  <ModalDialog ref="dialog" :title="translationStore.getTranslation('account_info#terminate#title')">
    <template #body>
      <div class="cancel-cards">
        <div v-if="isRevocation" class="cancel-card card card-md card-transparent">
          <div class="mb-3">
            <div>
              Innerhalb der ersten 14 Tage nach Abschluss des Vertrags, haben Sie die Möglichkeit von diesem zurückzutreten.
            </div>
            <div class="mt-3">
              Damit würde der Vertrag mit sofortiger Wirkung beendet werden! Sie erhalten den eventuell bereits bezahlten Betrag zurückerstattet.
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-sm cancel-button mt-auto" @click="pressedRevocation">
            {{ translationStore.getTranslation("account_info#revocationOk") }}
          </button>
        </div>
        <div class="cancel-card card card-md card-transparent">
          <div class="mb-3">
            <div>
              Sie können den Vertrag zum Ende der aktuellen Vertragslaufzeit kündigen.
            </div>
            <div class="mt-3">
              Der Inhalt des Abos steht Ihnen selbstverständlich bis zu diesem Zeitpunkt voll zu Verfügung!
            </div>
            <div class="mt-3">
              Die Kündigung wäre zum {{ formatDate(aboTermination?.subscriptionEnd) }} wirksam.
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-sm cancel-button mt-auto" @click="pressedTerminate">
            {{ translationStore.getTranslation("account_info#terminate#ok") }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { ChartHandler } from "@/utils/ChartHandler";
import { defineComponent, type PropType } from "vue";
import { translationStore } from "@/stores/translation-store";
import ModalDialog from "@/views/components/ModalDialog.vue";
import { DateMixin } from "@/mixins/date-mixin";

export default defineComponent({
  name: "AccountAboCancellationDialog",

  components: { ModalDialog },

  mixins: [DateMixin],

  props: {
    aboTermination: {
      type: [Object, null] as PropType<any | null>,
      required: true
    }
  },

  emits: {
    pressedRevocation: () => true,
    pressedTermination: () => true
  },

  expose: ["show", "close"],

  data() {
    return {
      chartHandler: ChartHandler.getInstance(),
      translationStore: translationStore()
    };
  },

  computed: {
    isRevocation() {
      return this.aboTermination?.revocationUntil > Date.now();
    },

    grid() {
      return this.isRevocation ? "grid" : "flex";
    }
  },

  methods: {
    show() {
      const dialog = this.$refs.dialog as typeof ModalDialog;
      dialog.show();
    },

    close() {
      const dialog = this.$refs.dialog as typeof ModalDialog;
      dialog.close();
    },

    pressedTerminate() {
      this.$emit("pressedTermination");
    },

    pressedRevocation() {
      this.$emit("pressedRevocation");
    }
  }
});
</script>

<style scoped>
.cancel-cards {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.cancel-card {
  border-radius: 5px;
  color: var(--content-tertiary);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 320px;
  text-align: center;
}

.cancel-button {
  width: 200px;
}

@media (max-width: 767px) {
  .cancel-cards {
    flex-direction: column;
  }
}
</style>

import { ChartArea } from "@/anfin-chart/area/chart-area";
import { LineOptions, LineStyle } from "@/anfin-chart/draw/chart-drawer";
import { Point, Rectangle } from "@/anfin-chart/geometry";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { combineLatest } from "rxjs";

export class Crosshair extends ChartArea {

  public constructor(layer: ChartLayer) {
    super(layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.chart.drawingAreaWrapper.getPositionObservable(), () => this.resize());
    this.subscribeOn(this.chart.mouseData.getPositionObservable(), () => {
      this.layer.requireDraw();
    });
    this.subscribeOn(this.chart.mouseData.getBarIndexObservable(), () => {
      this.layer.requireDraw();
    });
    const combinedObservable = combineLatest([
      this.chart.mouseData.getMouseOverObservable(),
      this.chart.getIsSynchronizedObservable()
    ]);
    this.subscribeOn(combinedObservable, ([isMouseOver, isSynchronized]) => {
      this.setIsVisible(isMouseOver || isSynchronized);
    });
  }

  protected override drawInternal() {
    const barIndex = this.chart.mouseData.getBarIndex();
    const x = Math.round(this.chart.timeAxis.getXForIndex(barIndex));
    const y = Math.round(this.chart.mouseData.getPosition().y);
    const position = this.getPosition();
    const lineStyle = new LineOptions(1, LineStyle.Dashed);
    const color = this.chart.optionManager.chartColor.crosshair.getValue();
    if (x > 0) {
      const verticalStart = new Point(x, position.yStart);
      const verticalEnd = new Point(x, position.yEnd);
      this.drawer.drawLine(verticalStart, verticalEnd, color, lineStyle);
    }
    if (y > 0) {
      const horizontalStart = new Point(position.xStart, y);
      const horizontalEnd = new Point(position.xEnd, y);
      this.drawer.drawLine(horizontalStart, horizontalEnd, color, lineStyle);
    }
  }

  protected override resizeInternal() {
    const drawingAreaWrapperPosition = this.chart.drawingAreaWrapper.getPosition();
    const xStart = drawingAreaWrapperPosition.xStart;
    const xEnd = drawingAreaWrapperPosition.xEnd;
    const yStart = drawingAreaWrapperPosition.yStart;
    const yEnd = drawingAreaWrapperPosition.yEnd;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }
}

import { type Consumer, LinkedListNode } from "@/anfin-chart/utils";
import type { ChartLayout } from "@/api/models/chart-layout";
import { chartObjectStore } from "@/stores/chart-object-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { toastStore, ToastType } from "@/stores/toast-store";
import { defineStore } from "pinia";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { translationStore } from "@/stores/translation-store";
import { UserToolConverter } from "@/api/messages/user-tool";
import { ChartLayoutConverter } from "@/api/messages/layout";

export enum ActionType {
  Compound = 0,
  Layout = 1,
  Tool = 2
}

export abstract class HistoryAction {

  protected constructor(public readonly type: ActionType) {
  }
}

export abstract class RevertibleAction extends HistoryAction {

  public abstract execute(): void;
}

export abstract class UserToolAction extends RevertibleAction {

  public readonly tool: UserToolDefinition;

  constructor(tool: UserToolDefinition) {
    super(ActionType.Tool);
    this.tool = new UserToolConverter().clone(tool);
  }
}

export class UserToolEditAction extends UserToolAction {

  public override execute() {
    chartObjectStore().requestSaveTool(this.tool);
  }
}

export class UserToolDeleteAction extends UserToolAction {

  public override execute() {
    if (this.tool.id != null) {
      chartObjectStore().requestDeleteTool(this.tool);
    }
  }
}

export class ChartLayoutAction extends RevertibleAction {

  public readonly layout: ChartLayout;

  constructor(layout: ChartLayout) {
    super(ActionType.Layout);
    this.layout = new ChartLayoutConverter().clone(layout);
  }

  public override execute() {
    multiChartStore().applyLayout(this.layout);
  }
}

export class CompoundAction extends HistoryAction {

  constructor(public readonly keys: string[]) {
    super(ActionType.Compound);
  }
}

export const actionHistoryStore = defineStore({
  id: "action-history",

  state() {
    return {
      currentActionNode: new LinkedListNode<CompoundAction>(new CompoundAction([])),
      typedActionMap: new Map<string, LinkedListNode<RevertibleAction>>(),
      isExecutingAction: false,
      compoundAction: null as CompoundAction | null,
      enableLogging: false
    };
  },

  getters: {
    canUndo(): boolean {
      return this.currentActionNode?.previous != null;
    },

    canRedo(): boolean {
      return this.currentActionNode?.next != null;
    }
  },

  actions: {
    addBaseAction(action: RevertibleAction) {
      const node = new LinkedListNode<RevertibleAction>(action);
      const key = this.getKey(action);
      if (!this.typedActionMap.has(key)) {
        this.typedActionMap.set(key, node);
      }
    },

    addAction(action: RevertibleAction) {
      if (this.isExecutingAction) {
        return;
      }
      const node = new LinkedListNode<RevertibleAction>(action);
      const key = this.getKey(action);
      this.addTypedAction(key, node);
      if (this.compoundAction == null) {
        this.finishCompound(new CompoundAction([key]));
      } else {
        this.compoundAction.keys.push(key);
      }
      this.log();
    },

    addTypedAction(key: string, node: LinkedListNode<RevertibleAction>) {
      const typedAction = this.typedActionMap.get(key);
      if (typedAction != null) {
        typedAction.setNext(node);
      }
      this.typedActionMap.set(key, node);
    },

    asCompound(callback: Consumer<void>) {
      this.compoundAction = new CompoundAction([]);
      try {
        callback();
      } finally {
        if (this.compoundAction.keys.length > 0) {
          this.finishCompound(this.compoundAction);
        }
        this.compoundAction = null;
      }
    },

    finishCompound(action: CompoundAction) {
      const node = new LinkedListNode<CompoundAction>(action);
      if (this.currentActionNode != null) {
        this.currentActionNode.setNext(node);
      }
      this.currentActionNode = node;
      this.checkActionToast();
    },

    redo() {
      const nextNode = this.currentActionNode?.next;
      if (nextNode == null) {
        return;
      }
      this.currentActionNode = nextNode;
      for (const key of nextNode.data.keys) {
        const typedNode = this.typedActionMap.get(key);
        const typedNextNode = typedNode?.next;
        if (typedNextNode != null) {
          this.runAction(typedNextNode.data);
          this.typedActionMap.set(key, typedNextNode);
        }
      }
      this.checkActionToast();
      this.log();
    },

    undo(action: CompoundAction | null = null) {
      if (action != null) {
        const isFound = this.rearrangeToCurrent(action);
        if (!isFound) {
          return;
        }
      }
      const currentNode = this.currentActionNode;
      const previousNode = currentNode?.previous;
      if (currentNode == null || previousNode == null) {
        return;
      }
      this.currentActionNode = previousNode;
      for (const key of currentNode.data.keys) {
        const typedNode = this.typedActionMap.get(key);
        const typedPreviousNode = typedNode?.previous;
        if (typedPreviousNode != null) {
          this.runAction(typedPreviousNode.data);
          this.typedActionMap.set(key, typedPreviousNode);
        }
      }
      this.log();
    },

    runAction(action: RevertibleAction) {
      this.isExecutingAction = true;
      try {
        action.execute();
      } finally {
        this.isExecutingAction = false;
      }
    },

    getKey(action: RevertibleAction) {
      if (action instanceof UserToolAction) {
        return action.type + "_" + action.tool.id;
      }
      return String(action.type);
    },

    rearrangeToCurrent(action: CompoundAction) {
      const currentNode = this.currentActionNode;
      if (currentNode == null) {
        return false;
      }
      if (currentNode.data === action) {
        return true;
      }
      let targetNode = currentNode.previous;
      while (targetNode != null) {
        if (targetNode.data === action) {
          targetNode.previous?.setNext(targetNode.next);
          targetNode.next?.setPrevious(targetNode.previous);
          const nextNode = currentNode.next;
          currentNode.setNext(targetNode);
          targetNode.setNext(nextNode);
          this.currentActionNode = targetNode;
          return true;
        }
        targetNode = targetNode.previous;
      }
      return false;
    },

    checkActionToast() {
      const currentAction = this.currentActionNode?.data;
      if (currentAction == null) {
        return;
      }
      const toolNames = [];
      const keys = currentAction.keys;
      for (const key of keys) {
        const typedAction = this.typedActionMap.get(key)?.data;
        if (typedAction instanceof UserToolDeleteAction) {
          const toolName = translationStore().getTranslation("user_tool#" + typedAction.tool.type);
          toolNames.push(toolName);
        }
      }
      if (toolNames.length > 0) {
        const data = { tools: toolNames.join(", ") };
        toastStore().addToast(ToastType.ToolDeleted, "toast#undo", data, () => actionHistoryStore().undo(currentAction));
      }
    },

    log() {
      if (!this.enableLogging) {
        return;
      }
      console.log("ACTION HISTORY");
      const currentNode = this.currentActionNode;
      if (currentNode == null) {
        console.log("no actions");
        return;
      }
      console.log(this.getLogChain(currentNode), "font-weight: bold;");
      for (const [key, value] of this.typedActionMap.entries()) {
        console.log("TYPE " + key + " " + this.getLogChain(value), "font-weight: bold;");
      }
    },

    getLogChain(node: LinkedListNode<HistoryAction>) {
      let firstNode = node;
      while (true) {
        if (firstNode.previous == null) {
          break;
        }
        firstNode = firstNode.previous;
      }
      let currentNode: LinkedListNode<HistoryAction> | null = firstNode;
      const nodeLogs = [];
      while (currentNode != null) {
        let nodeLog = this.getActionLog(currentNode.data);
        if (currentNode === node) {
          nodeLog = "%c" + nodeLog;
        }
        nodeLogs.push(nodeLog);
        currentNode = currentNode.next;
      }
      return nodeLogs.join(" > ");
    },

    getActionLog(action: HistoryAction) {
      if (action instanceof ChartLayoutAction) {
        return "LO " + action.layout.charts.length;
      }
      if (action instanceof UserToolEditAction) {
        return "TE " + action.tool.id + " " + action.tool.type;
      }
      if (action instanceof UserToolDeleteAction) {
        return "TD " + action.tool.id + " " + action.tool.type;
      }
      if (action instanceof CompoundAction) {
        return "CO (" + action.keys.join("|") + ")";
      }
      return translationStore().getTranslation("not_available").toUpperCase();
    }
  }
});

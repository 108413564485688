import { ChartArea } from "@/anfin-chart/area/chart-area";
import { GridDisplay } from "@/anfin-chart/area/drawing-area-wrapper";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { LineOptions, LineStyle } from "@/anfin-chart/draw/chart-drawer";
import { PlotData } from "@/anfin-chart/draw/plot-data";
import { ChartError } from "@/anfin-chart/error";
import { Point, Rectangle } from "@/anfin-chart/geometry";
import type { DragData, Draggable, Scrollable, ScrollData } from "@/anfin-chart/interactions";
import { DataItem, OHLCItem, Plot, PlotType } from "@/anfin-chart/plot";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { combineLatest } from "rxjs";

export class DrawingArea extends ChartArea implements Draggable, Scrollable {

  public constructor(layer: ChartLayer,
                     private readonly subChart: SubChart) {
    super(layer);
    this.setVisibleOnSubChart(subChart);
  }

  public override initializeEvents() {
    const combinedObservable = combineLatest([
      this.chart.drawingAreaWrapper.getPositionObservable(),
      this.subChart.mainArea.getPositionObservable()
    ]);
    this.subscribeOn(combinedObservable, () => this.resize());
  }

  public onDrag(data: DragData) {
    this.chart.timeAxis.moveRange(data.diffX);
    const priceAxis = this.subChart.priceAxis;
    if (!priceAxis.getIsAutoRange()) {
      priceAxis.moveRange(data.diffY);
    }
    if (this.subChart.priceAxis.getIsAutoRange() && data.checkYMoving()) {
      this.subChart.priceAxis.onDrag(data);
    }
  }

  public onScroll(data: ScrollData) {
    const timeAxis = this.chart.timeAxis;
    if (timeAxis.isAtLast() && !data.scrollMobil) {
      timeAxis.zoomFixed(-data.delta);
    } else {
      timeAxis.zoomCenter(data.position, -data.delta);
    }
  }

  protected override drawInternal() {
    this.drawPriceRanges();
    this.drawGridLines();
    this.drawPlots();
  }

  protected override resizeInternal() {
    const drawingAreaWrapperPosition = this.chart.drawingAreaWrapper.getPosition();
    const subChartAreaPosition = this.subChart.mainArea.getPosition();
    const xStart = drawingAreaWrapperPosition.xStart;
    const xEnd = drawingAreaWrapperPosition.xEnd;
    const yStart = subChartAreaPosition.yStart;
    const yEnd = subChartAreaPosition.yEnd;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private drawPriceRanges() {
    const position = this.getPosition();
    const lineStyle = new LineOptions(1, LineStyle.Dashed);
    for (const indicatorData of this.subChart.getIndicatorDatas()) {
      for (const priceRange of indicatorData.indicator.priceRanges) {
        const xStart = position.xStart;
        const xEnd = position.xEnd;
        const yFrom = this.subChart.priceAxis.getY(priceRange.from);
        const yTo = this.subChart.priceAxis.getY(priceRange.to);
        const rect = new Rectangle(xStart, yFrom, xEnd, yTo);
        this.drawer.drawRect(rect, null, priceRange.areaColor);
        const fromLineStart = new Point(xStart, yFrom);
        const fromLineEnd = new Point(xEnd, yFrom);
        this.drawer.drawLine(fromLineStart, fromLineEnd, priceRange.lineColor, lineStyle);
        const toLineStart = new Point(xStart, yTo);
        const toLineEnd = new Point(xEnd, yTo);
        this.drawer.drawLine(toLineStart, toLineEnd, priceRange.lineColor, lineStyle);
      }
    }
  }

  private drawGridLines() {
    const gridDisplay = this.chart.optionManager.gridDisplay.getValue();
    if (gridDisplay !== GridDisplay.Horizontal && gridDisplay !== GridDisplay.All) {
      return;
    }
    const position = this.getPosition();
    const labels = this.subChart.priceAxis.labels;
    const color = this.chart.optionManager.chartColor.helperLine.getValue();
    for (const label of labels) {
      const y = Math.round(label.y);
      const start = new Point(position.xStart, y);
      const end = new Point(position.xEnd, y);
      this.drawer.drawLine(start, end, color);
    }
  }

  private drawPlots() {
    const plots = this.subChart.getPlots();
    for (const plot of plots) {
      this.drawer.setLineOptions(new LineOptions(plot.lineWidth, plot.lineStyle));
      const items = this.filterItems(plot);
      const plotData = this.getPlotData(items, plot);
      this.drawer.drawPlot(this.subChart, plotData, plot.colorProvider);
    }
  }

  private getPlotData(items: DataItem[], plot: Plot) {
    const plotData = new PlotData(plot.type);
    for (const item of items) {
      const x = this.chart.timeAxis.getXForTime(item.time, plot.offsetProjection);
      let values: number[];
      switch (plot.type) {
        case PlotType.Line:
          values = [item.main];
          break;
        case PlotType.Mountain:
          const base = item instanceof OHLCItem ? 0 : item.second;
          values = [item.main, base];
          break;
        case PlotType.Bar:
        case PlotType.SimpleBar:
        case PlotType.Candle:
          values = [item.main, item.second, item.max, item.min];
          break;
        default:
          throw new ChartError("Unknown plot type");
      }
      plotData.addItem(item, x, values);
    }
    return plotData;
  }

  private filterItems(plot: Plot) {
    if (plot.store.length === 0) {
      return [];
    }
    const timeRange = this.chart.timeAxis.getTimeRange();
    if (timeRange == null) {
      return [];
    }
    let startIndex = Math.trunc(plot.store.indexOfFraction(timeRange.start));
    let endIndex = Math.trunc(plot.store.indexOfFraction(timeRange.end)) + 2;
    const offset = plot.offsetProjection;
    if (offset > 0) {
      startIndex = Math.max(0, startIndex - offset);
    } else if (offset < 0) {
      startIndex -= offset;
      endIndex -= offset;
    }
    return plot.store.getItems().slice(startIndex, endIndex);
  }
}

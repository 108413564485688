<template>
  <div>
    <h4>{{ translationStore.getTranslation("checkout#customer_data#header") }}</h4>
    <form class="mt-3" @submit.prevent="onSubmit">
      <div>
        <label class="form-label">{{ translationStore.getTranslation("checkout#customer_data#label_first_name") }}</label>
        <input v-model="data.firstName" type="text" class="form-control" autocomplete="given-name" required />
      </div>
      <div class="mt-2">
        <label class="form-label">{{ translationStore.getTranslation("checkout#customer_data#label_last_name") }}</label>
        <input v-model="data.lastName" type="text" class="form-control" autocomplete="family-name" required />
      </div>
      <div class="mt-2">
        <label class="form-label">{{ translationStore.getTranslation("checkout#customer_data#label_street") }}</label>
        <input v-model="data.street" type="text" class="form-control" autocomplete="address-level3" required />
      </div>
      <div class="mt-2">
        <label class="form-label">{{ translationStore.getTranslation("checkout#customer_data#label_house_number") }}</label>
        <input v-model="data.houseNumber" type="text" class="form-control" autocomplete="address-level3" required />
      </div>
      <div class="mt-2">
        <label class="form-label">{{ translationStore.getTranslation("checkout#customer_data#label_postal_code") }}</label>
        <input v-model="data.postalCode" type="text" class="form-control" autocomplete="postal-code" required />
      </div>
      <div class="mt-2">
        <label class="form-label">{{ translationStore.getTranslation("checkout#customer_data#label_city") }}</label>
        <input v-model="data.city" type="text" class="form-control" autocomplete="address-level2" required />
      </div>
      <div class="mt-2">
        <label class="form-label">{{ translationStore.getTranslation("checkout#customer_data#label_country") }}</label>
        <SingleSelect v-model="data.country" :items="countryItems" />
      </div>
      <div class="mt-2">
        <label class="form-label">{{ translationStore.getTranslation("checkout#customer_data#label_email") }}</label>
        <input v-model="data.emailAddress" type="email" class="form-control" autocomplete="email" required />
      </div>
      <div class="mt-2">
        <label class="form-label">{{ translationStore.getTranslation("checkout#customer_data#label_email_confirm") }}</label>
        <input
          ref="emailConfirm" v-model="data.emailAddressConfirm" type="email" class="form-control"
          autocomplete="off" required @input="checkEmailConfirm"
        />
      </div>
      <button ref="submitButton" type="submit" class="visually-hidden"></button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import { storeToRefs } from "pinia";
import { paymentStore } from "@/stores/payment-store";
import { simpleMapCompare } from "@/anfin-chart/utils";
import type { PaymentCountry } from "@/api/models/checkout";
import { CustomerData } from "@/api/models/customer-data";

export default defineComponent({
  name: "CustomerDataForm",

  components: { SingleSelect },

  props: {
    data: {
      type: CustomerData,
      required: true
    }
  },

  emits: {
    submit: () => true
  },

  expose: ["validateSubmit"],

  data() {
    const { countries } = storeToRefs(paymentStore());
    return {
      translationStore: translationStore(),
      countries: countries as unknown as PaymentCountry[]
    };
  },

  computed: {
    countryItems() {
      const items = this.countries.map(c => new SingleSelectItem(c.key, c.name, c.key));
      return items.sort(simpleMapCompare(c => c.name));
    }
  },

  methods: {
    onSubmit() {
      if (this.data.isValid()) {
        this.$emit("submit");
      }
    },

    validateSubmit() {
      const submitButton = (this.$refs.submitButton as HTMLButtonElement);
      return submitButton.click();
    },

    checkEmailConfirm() {
      const emailConfirm = this.$refs.emailConfirm as HTMLInputElement;
      let errorMessage = "";
      if (this.data.emailAddressConfirm !== this.data.emailAddress) {
        errorMessage = this.translationStore.getTranslation("checkout#customer_data#validate#email_confirm");
      }
      emailConfirm.setCustomValidity(errorMessage);
    }
  }
});
</script>

<style scoped>

</style>
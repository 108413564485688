import type { Point } from "@/anfin-chart/geometry";

export type TypeGuard<T> = (obj: unknown) => obj is T;

export interface Draggable {
  onDrag(data: DragData): void;
  onDragEnd?(data: ClickData): void;
}

export interface Deletable {
  onDelete(): void;
}

export interface Clickable {
  onClick(data: ClickData): void;
}

export interface RightClickable {
  onRightClick(data: ClickData): void;
}

export interface DoubleClickable {
  onDoubleClick(data: ClickData): void;
}

export interface Scrollable {
  onScroll(data: ScrollData): void;
}

export function isDraggable(obj: unknown): obj is Draggable {
  const draggable = obj as Draggable;
  return draggable.onDrag != null;
}

export function isDeletable(obj: unknown): obj is Deletable {
  const deletable = obj as Deletable;
  return deletable.onDelete != null;
}

export function isClickable(obj: unknown): obj is Clickable {
  const clickable = obj as Clickable;
  return clickable.onClick != null;
}

export function isRightClickable(obj: unknown): obj is RightClickable {
  const rightClickable = obj as RightClickable;
  return rightClickable.onRightClick != null;
}

export function isDoubleClickable(obj: unknown): obj is DoubleClickable {
  const doubleClickable = obj as DoubleClickable;
  return doubleClickable.onDoubleClick != null;
}

export function isScrollable(obj: unknown): obj is Scrollable {
  const scrollable = obj as Scrollable;
  return scrollable.onScroll != null;
}

export class KeyData {

  constructor(public readonly keyName: string,
              public readonly code: string) {
  }
}

export class DragData {

  constructor(public readonly position: Point,
              public readonly startPosition: Point,
              public readonly maxDraggingDifference: number,
              public readonly diffX: number,
              public readonly diffY: number) {
  }

  public checkYMoving(): boolean {
    return Math.abs(this.startPosition.y - this.position.y) > 100 &&
      Math.abs(this.startPosition.x - this.position.x) < 50 &&
      this.maxDraggingDifference < 50 &&
      Math.abs(this.startPosition.y - this.position.y) > Math.abs(this.startPosition.x - this.position.x) * 5;
  }
}

export class ClickData {

  constructor(public readonly position: Point,
              public readonly touchCount = 1) {
  }
}

export class ScrollData {

  constructor(public readonly position: Point,
              public readonly delta: number,
              public readonly scrollMobil: boolean) {
  }
}

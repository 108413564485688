<template>
  <div class="indicator-definition-container">
    <div class="tabs">
      <div
        v-for="(pageKey, index) in pageKeys" :key="pageKey" class="nav-item" :class="{ active: activePage === index }"
        @click="setActivePage(index)"
      >
        <label>{{ translationStore.getTranslation("indicator_definition#" + pageKey) }}</label>
      </div>
      <div class="ms-auto indicator-definition-actions">
        <IconElement iconName="NewFile" :size="14" class="indicator-definition-action-button" @click="createNew" />
        <IconElement v-if="!isNew" iconName="Reload" :size="14" class="indicator-definition-action-button" @click="restore" />
        <IconElement iconName="Save" :size="14" class="indicator-definition-action-button" @click="save" />
        <SingleSelect v-model="currentType" :items="indicatorDefinitionItems" />
      </div>
    </div>
    <div v-if="editedDefinition != null" class="indicator-definition-main">
      <div v-if="activePage === 0" class="indicator-definition-main-content">
        <IndicatorDefinitionGeneral :indicatorDefinition="editedDefinition" />
      </div>
      <div v-if="activePage === 1" class="indicator-definition-main-content">
        <IndicatorDefinitionLines :indicatorDefinition="editedDefinition" />
      </div>
      <div v-if="activePage === 2" class="indicator-definition-main-content">
        <IndicatorDefinitionInitialize :indicatorDefinition="editedDefinition" />
      </div>
      <div v-if="activePage === 3" class="indicator-definition-main-content">
        <IndicatorDefinitionUpdate :indicatorDefinition="editedDefinition" />
      </div>
      <div v-if="activePage === 4" class="indicator-definition-main-content">
        <IndicatorDefinitionDependencies :indicatorDefinition="editedDefinition" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import { simpleMapCompare } from "@/anfin-chart/utils";
import { IndicatorDefinitionConverter } from "@/api/messages/indicator-definition";
import { chartObjectStore } from "@/stores/chart-object-store";
import { translationStore } from "@/stores/translation-store";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import IconElement from "@/views/icons/IconElement.vue";
import IndicatorDefinitionDependencies from "@/views/indicator-definition/IndicatorDefinitionDependencies.vue";
import IndicatorDefinitionInitialize from "@/views/indicator-definition/IndicatorDefinitionInitialize.vue";
import IndicatorDefinitionUpdate from "@/views/indicator-definition/IndicatorDefinitionUpdate.vue";
import IndicatorDefinitionGeneral from "@/views/indicator-definition/IndicatorDefinitionGeneral.vue";
import IndicatorDefinitionLines from "@/views/indicator-definition/IndicatorDefinitionLines.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IndicatorDefinition",

  components: {
    SingleSelect,
    IndicatorDefinitionDependencies, IndicatorDefinitionUpdate, IndicatorDefinitionInitialize,
    IndicatorDefinitionLines, IndicatorDefinitionGeneral, IconElement
  },

  expose: [],

  data() {
    const { indicatorDefinitionMap } = storeToRefs(chartObjectStore());
    const currentType = chartObjectStore().indicatorDefinitions[0]?.type ?? null;
    return {
      translationStore: translationStore(),
      currentType: currentType,
      editedDefinition: null as IndicatorDefinition | null,
      isNew: false,
      indicatorDefinitionMap: indicatorDefinitionMap as unknown as Map<string, IndicatorDefinition>,
      activePage: 0,
      pageKeys: [
        "header_general", "header_lines", "header_initialize", "header_update", "header_dependencies"
      ]
    };
  },

  computed: {
    indicatorDefinitions() {
      const indicatorDefinitions = Array.from(this.indicatorDefinitionMap.values());
      return indicatorDefinitions.sort(simpleMapCompare(i => i.type));
    },

    indicatorDefinitionItems() {
      return this.indicatorDefinitions.map(d => new SingleSelectItem(d.type, d.name, d.type));
    },

    currentDefinition() {
      return this.indicatorDefinitionMap.get(this.currentType) ?? null;
    }
  },

  watch: {
    indicatorDefinitions(definitions: IndicatorDefinition[]) {
      if (this.currentType == null && definitions.length > 0) {
        this.currentType = definitions[0].type;
      }
    },

    currentDefinition: {
      handler(definition: IndicatorDefinition | null) {
        if (definition != null) {
          this.editedDefinition = this.cloneDefinition(definition);
        }
        this.isNew = false;
      },
      immediate: true
    }
  },

  methods: {
    setActivePage(index: number) {
      this.activePage = index;
    },

    createNew() {
      const name = this.translationStore.getTranslation("indicator_definition#default_indicator_name");
      this.currentDefinition = new IndicatorDefinition(
        "", name, false, [], [], [], null, [],
        "", "", []
      );
      this.isNew = true;
    },

    save() {
      if (this.editedDefinition != null) {
        chartObjectStore().saveIndicatorDefinition(this.editedDefinition);
      }
    },

    restore() {
      this.editedDefinition = this.cloneDefinition(this.currentDefinition);
    },

    cloneDefinition(indicatorDefinition: IndicatorDefinition | null) {
      if (indicatorDefinition == null) {
        return null;
      }
      return new IndicatorDefinitionConverter().clone(indicatorDefinition);
    }
  }
});
</script>

<style scoped>
.indicator-definition-container {
  background-color: var(--background-neutral);
  display: flex;
  flex-direction: column;
  height: var(--size-indicator-def);
}

.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: end;
  gap: 5px;
  padding: 0 15px;
  border-bottom: 1px solid var(--border-neutral);
}

.nav-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px 10px 0 0;
  padding: 4px 10px;
  border: 1px solid var(--border-neutral);
  background-color: var(--background-neutral);
  color: var(--content-secondary);
}

.active {
  background-color: var(--background-primary);
  color: var(--content-secondary-top);
}

.indicator-definition-actions {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.indicator-definition-action-button {
  cursor: pointer;
}

.indicator-definition-main {
  height: 100%;
  padding: 10px 15px;
  background-color: var(--background-elevated);
  color: var(--content-primary);
  overflow-y: auto;
}
</style>

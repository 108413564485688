<template>
  <LandingPageWrapper>
    <LandingPageSection class="about-us-image-section">
      <img
        class="mw-100"
        decoding="async"
        src="/src/assets/about-chart-w1152.jpg"
        alt="AnFin Chart"
        srcset="/src/assets/about-chart-w200.jpg 200w, /src/assets/about-chart-w300.jpg 300w, /src/assets/about-chart-w600.jpg 600w, /src/assets/about-chart-w768.jpg 768w, /src/assets/about-chart-w1152.jpg 1152w"
      />
      <div class="about-us-text-overlay">
        <div class="about-us-text-header">
          Die Experten an Ihrer Seite
        </div>
        <div class="mt-3">
          Erfahren Sie, wer hinter AnFin steht und wie die revolutionäre Technologie entstand.
        </div>
        <div class="mt-1">
          Die Besonderheiten verleihen Ihrem Trading den entscheidenden Vorsprung.
        </div>
        <div class="mt-1">
          Das ist unser Ziel.
        </div>
        <a class="btn btn-primary mt-3" href="#start-now">
          Über uns
        </a>
      </div>
    </LandingPageSection>
    <StartNowSection id="start-now" class="py-md-5 py-4" />
    <LandingPageSection class="section-gray py-md-5 py-4">
      <div class="about-us-section-wrapper">
        <div class="landing-header">
          Die Experten an Ihrer Seite
        </div>
        <div class="about-us-history mt-5">
          <div class="fw-bold">
            Geschichte und Gründung:
          </div>
          <div class="mt-3">
            Hamed Esnaashari, ursprünglich Arzt und Facharzt für Chirurgie, entdeckte seine Leidenschaft für das
            Trading und die Börse. Nach einige Rückschlägen im Trading entschied er sich, vollständig in den Handel
            einzusteigen. Sein Engagement und seine hochprofitabel entwickelte Handelsstrategie führten zur Gründung der
            Formationstrader GmbH, die aufgrund der hohen Nachfrage Seminare anbot und den Grundstein für AnFin bot.
          </div>
        </div>
        <div class="about-us-quote-container mt-md-5 mt-4">
          <HamedPortraitImage class="about-us-quote-image" />
          <div class="about-us-quote">
            <div class="about-us-quote-text">
              "In einer Ära, in der die Digitalisierung und künstliche Intelligenz das Finanzwesen transformieren,
              steht AnFin an erster Stelle, um Ihnen nicht nur effiziente, sondern auch rentable Handelsprozesse
              zu bieten."
            </div>
            <div class="about-us-quote-name mt-3">
              Dr. Hamed Esnaashari
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </LandingPageSection>
    <SellingFactSection class="py-md-5 py-4" />
    <LandingPageSection class="section-gray py-md-5 py-4">
      <div class="about-us-section-wrapper">
        <div class="about-us-mission">
          <div class="fw-bold">
            Unsere Mission:
          </div>
          <div class="mt-3">
            Mehr als 90% aller privaten Trader sind nicht konsequent erfolgreich. Dies ist häufig auf Mängel in
            Marktverständnis, technischer Analyse und Börsenpsychologie zurückzuführen.
          </div>
          <div class="mt-2">
            Unsere Zielsetzung ist es, durch revolutionäre Ansätze in der Charttechnik und der Entscheidungsfindung
            von Anlageentscheidungen, Ihre Profitabilität zu steigern.
          </div>
          <div class="mt-2">
            Wir sind stets bestrebt, Ihnen das Beste zu bieten. Besuchen Sie unseren Shop, um sich einen Überblick
            über unsere aktuellen Produkte und Angebote zu verschaffen. Jedes Produkt wurde mit dem Ziel entwickelt,
            Ihren Trading-Erfolg zu maximieren.
          </div>
        </div>
        <LetsGoButton class="mt-md-5 mt-4" />
      </div>
    </LandingPageSection>
    <LandingPageSection class="py-md-5 py-4">
      <div class="about-us-section-wrapper">
        <div class="about-us-successes">
          <div class="fw-bold">
            Unsere Erfolge:
          </div>
          <div class="mt-3">
            Unsere Bilanz spricht für sich: In nur 8 Jahren haben wir mit der Formationstrader GmbH über 1.000 Trader
            zu Meistern Ihres Fachs gemacht. Überwältigende 98% unserer Absolventen würden uns jederzeit weiterempfehlen.
            Unsere Reputation glänzt nicht nur durch das Feedback unserer Teilnehmer, sondern auch durch strahlende
            Bewertungen auf Plattformen wie Trusted Shops und Google.
          </div>
          <div id="trustbadgeCustomCheckoutContainer"></div>
          <div class="fw-bold mt-md-5 mt-4">
            Zielgruppen unserer Produkte:
          </div>
          <div class="mt-3">
            AnFin richtet sich an ein vielfältiges Klientel: Von den "Feierabend-Tradern", die nach der Arbeit
            effizient und professionell handeln möchten, über die professionellen Trader, die nach fortlaufender
            Optimierung und Anpassung an dynamische Marktbedingungen suchen, bis hin zu Investoren, die ihre
            langfristigen Anlagestrategien mit präzisem Timing verfeinern wollen.
          </div>
          <div class="mt-2">
            Unsere Technologien und Analysen sind so konzipiert, dass sie den individuellen Bedürfnissen jedes dieser
            Profile gerecht werden, wodurch wir jedem die Möglichkeit bieten, seine Handels- oder Anlagestrategie
            zu optimieren und herausragende finanzielle Ergebnisse zu erzielen.
          </div>
          <div class="mt-2">
            Besuchen Sie unseren Shop, um sich einen Überblick über unsere aktuellen Produkte und Angebote zu verschaffen.
          </div>
        </div>
      </div>
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import StartNowSection from "@/views/pages-landing/sections/StartNowSection.vue";
import HamedPortraitImage from "@/views/pages-landing/HamedPortraitImage.vue";
import SellingFactSection from "@/views/pages-landing/sections/SellingFactSection.vue";
import LetsGoButton from "@/views/pages-landing/LetsGoButton.vue";
import { TrustedShopMixin } from "@/mixins/trusted-shop";

export default defineComponent({
  name: "AboutUsPage",

  components: {
    LetsGoButton, SellingFactSection, HamedPortraitImage, StartNowSection, LandingPageSection, LandingPageWrapper
  },

  mixins: [TrustedShopMixin],

  expose: []
});
</script>

<style scoped>
.about-us-image-section {
  background: #151c15 !important;
  background: linear-gradient(to bottom, #0e130e 0%, #111913 25%, #0f1511 50%, #0c120e 65%, #131d15 85%, #151a14 100%) !important
}

.about-us-text-overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  color: var(--content-primary-top);
  text-align: center;
  padding: 20px;
  background: #151c1580;
}

.about-us-text-header {
  font-size: 200%;
}

.about-us-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-us-history {
  max-width: 800px;
}

.about-us-history, .about-us-mission, .about-us-successes {
  font-size: 120%;
}

.about-us-quote-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}

.about-us-quote-image {
  min-width: 200px;
  max-width: 300px;
}

.about-us-quote {
  max-width: 500px;
  font-size: 120%;
}

.about-us-quote-text {
  border: 3px solid #cfaf4a;
  padding: 5px;
}

.about-us-quote-name {
  display: inline-block;
  background-color: #cfaf4a;
  padding: 10px 40px;
  font-weight: bold;
}

.about-us-quote-text, .about-us-quote-name {
  border-radius: 2px;
}

.about-us-mission {
  max-width: 1000px;
}

@media (max-width: 539px) {
  .about-us-quote-container {
    flex-direction: column;
  }
}
</style>
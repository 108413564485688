import type { InstrumentData } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { ChartTool } from "@/anfin-chart/tools/chart-tool";
import { Point } from "@/anfin-chart/geometry";
import type { AnalysisToolDefinition } from "@/api/models/analysis/analysis-tool-definition";
import { AlertDefinitionData, AlertToolData } from "@/anfin-chart/tools/alert-tool";
import { Alert, AlertAutoToolDefinition, AlertRule, AlertRuleDefinition } from "@/api/models/alert";

export abstract class AnalysisTool extends ChartTool {

  protected constructor(public readonly definition: AnalysisToolDefinition,
                        public readonly instrumentData: InstrumentData,
                        subChart: SubChart) {
    super(instrumentData.instrument, instrumentData.timeframe, subChart);
  }

  public get id() {
    return this.definition.id;
  }

  public get type() {
    return this.definition.type;
  }

  protected get colorOptions() {
    return this.chart.optionManager.analysisToolColor;
  }

  public override getIsVisible() {
    return this.chart.optionManager.showAnalysisTools.getValue();
  }

  public initialize() {
    this.drawables.splice(0);
    this.resetDrawables();
  }

  protected override createAlertData(alert: Alert, rule: AlertRule, definition: AlertRuleDefinition) {
    if (definition instanceof AlertAutoToolDefinition && definition.toolId === this.definition.id) {
      const definitionData = new AlertDefinitionData(alert, rule, definition);
      this.alertDatas.push(new AlertToolData(this, definitionData));
    }
  }

  protected override createAlertDrawables(data: AlertToolData, index: number) {
    // do nothing
  }

  protected override updateAlertPosition(data: AlertToolData) {
    const definition = data.data.definition as AlertAutoToolDefinition;
    const hook = this.definition.hookProvider.getHook(definition);
    if (data.icon != null && hook != null) {
      const position = this.getPosition(hook.anchorPoint);
      const offset = data.icon.size / 2 + this.chart.styleOptions.alertIconMargin.getValue() * 2;
      const iconPosition = new Point(position.x, position.y + offset);
      this.updatePoint(data.iconCenter, iconPosition);
    }
  }

  protected calculatePoint(time: number, isInverse: boolean) {
    const item = this.instrumentData.mainPlot.store.getByTime(time);
    let price: number;
    if (item == null) {
      price = 0;
    } else {
      price = isInverse ? item.min : item.max;
    }
    const x = this.chart.timeAxis.getXForTime(time);
    const y = this.subChart.priceAxis.getY(price);
    return new Point(x, y);
  }

  protected getFutureProjectionX() {
    const timeAxis = this.chart.timeAxis;
    const offset = this.chart.styleOptions.autoToolFutureProjection.getValue();
    const lastTime = timeAxis.getLastTime();
    const endIndex = lastTime == null ? 0 : timeAxis.getIndexForTime(lastTime) + offset;
    return timeAxis.getXForIndex(endIndex);
  }
}

<template>
  <DraggablePanel :isVisible="isVisible" :container="container" :position="position" @changePosition="setPosition">
    <div v-for="favorite in favorites" :key="favorite.key">
      <div class="panel-button ms-1" @click.stop="loadFavorite(favorite)">
        <IconElement
          v-if="favorite.icon" :class="{ 'fav-tool-icon': favorite.color !== 'transparent' }"
          :size="favorite.color !== 'transparent' ? 22 : 24" :iconName="favorite.icon"
        />
        <div class="square" :style="{ backgroundColor: favorite.color || '#555' }"></div>
      </div>
    </div>
  </DraggablePanel>
</template>

<script lang="ts">
import type { RGBA } from "@/anfin-chart/draw/chart-color";
import { convertToHex } from "@/anfin-chart/draw/chart-color";
import type { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import { OptionValueType } from "@/anfin-chart/options/option";
import { chartOptionStore, MultiChartOptionManager } from "@/stores/chart-option-store";
import { favoriteStore } from "@/stores/favorite-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import DraggablePanel, { DraggablePanelContainer } from "@/views/panels/DraggablePanel.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { storeToRefs } from "pinia";
import { chartObjectStore } from "@/stores/chart-object-store";
import { presetStore } from "@/stores/preset-store";
import { Point } from "@/anfin-chart/geometry";
import { userRightStore } from "@/stores/user-right-store";

export class FavoriteItem {

  constructor(public readonly key: string,
              public readonly type: string,
              public readonly icon: string | null,
              public readonly color: string | null,
              public readonly options: ChartOptionDefinition[]) {
  }
}

export default {
  name: "FavoritePanel",

  components: { DraggablePanel, IconElement },

  props: {
    container: {
      type: DraggablePanelContainer,
      required: true
    }
  },

  expose: [],

  data() {
    const { optionManager } = storeToRefs(chartOptionStore());
    const userRights = storeToRefs(userRightStore());
    return {
      optionManager: optionManager as unknown as MultiChartOptionManager,
      favoriteStore: favoriteStore(),
      userRights
    };
  },

  computed: {
    position() {
      const [x, y] = this.optionManager.favoritePanelPosition.getValue();
      return new Point(x, y);
    },

    isVisible() {
      return !this.userRights.isMobile && this.favorites.length > 0;
    },

    favorites() {
      const favoriteItems: FavoriteItem[] = [];
      const toolFavorites = this.favoriteStore.tools;
      const presetMap = presetStore().toolPresetMap;
      for (const toolFavorite of toolFavorites) {
        const preset = toolFavorite.presetId == null ? null : presetMap.get(toolFavorite.presetId);
        // only valid presets
        if (toolFavorite.presetId == null || preset != null) {
          const options = preset == null ? [] : preset.options;
          const color = this.getColor(options);
          const icon = chartObjectStore().getToolIcon(toolFavorite.type);
          const favoriteItem = new FavoriteItem(toolFavorite.key, toolFavorite.type, icon, color, options);
          favoriteItems.push(favoriteItem);
        }
      }
      return favoriteItems;
    }
  },

  methods: {
    getColor(options: ChartOptionDefinition[]) {
      for (const option of options) {
        if (option.type === OptionValueType.Color) {
          return convertToHex(option.value as RGBA);
        }
      }
      return "transparent";
    },

    loadFavorite(item: FavoriteItem) {
      multiChartStore().addTool(item.type, item.options, item.key);
    },

    setPosition(position: Point) {
      this.optionManager.favoritePanelPosition.setValue([position.x, position.y]);
    }
  }
};
</script>

<style scoped>
.panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 28px;
  height: 28px;
  border: 1px solid var(--border-neutral);
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.panel-button:hover, .panel-button:focus, .panel-button.active {
  background-color: var(--background-overlay);
  border: 1px solid var(--border-overlay);
}

.square {
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  border-radius: 2px;
}

.fav-tool-icon {
  display: block;
  margin-top: -6px;
}
</style>


<template>
  <div>
    <AccountAboCancellationDialog
      ref="terminationDialog" :aboTermination="aboTermination" @pressedTermination="confirmTermination"
      @pressedRevocation="confirmRevocation"
    />
    <div class="account-info-caption">
      {{ translationStore.getTranslation("account_info#header") }}
    </div>
    <div v-if="userInfo != null">
      <div class="d-flex m-3">
        <span class="col-3">
          {{ translationStore.getTranslation("account_info#label_user_name") }}
        </span>
        <span>{{ userInfo.user }}</span>
      </div>
      <div class="d-flex m-3">
        <span class="col-3">
          {{ translationStore.getTranslation("account_info#label_email") }}
        </span>
        <span>{{ userInfo.email }}</span>
      </div>
    </div>
    <div class="text-center">
      <div>
        <RouterLink to="/exchangeinquiry" class="btn btn-primary mt-2">
          {{ translationStore.getTranslation("account_info#button_exchange_inquiry") }}
        </RouterLink>
      </div>
      <div>
        <button type="button" class="btn btn-secondary mt-2" @click="openCookieBanner">
          {{ translationStore.getTranslation("open_cookie_banner") }}
        </button>
      </div>
      <div>
        <button v-if="isAdmin" type="button" class="btn btn-secondary mt-2" @click="reloadTranslations">
          {{ translationStore.getTranslation("account_info#button_reload_translations") }}
        </button>
      </div>
    </div>
    <div v-if="activeAbos.length > 0" class="account-info-caption mt-4">
      {{ translationStore.getTranslation("account_info#active_abos") }}
    </div>
    <div v-for="abo in activeAbos" :key="abo.product.id" class="card card-sm card-transparent mt-3">
      <AccountInfoAboItem :abo="abo" @setAboState="setAboState($event, abo)" @setContractInfos="setContractInfos(abo)" />
    </div>
    <div v-if="inactiveAbos.length > 0" class="account-info-caption mt-3 mb-2">
      {{ translationStore.getTranslation("account_info#available_products") }}
    </div>
    <div v-for="abo in inactiveAbos" :key="abo.product.id" class="card card-sm card-transparent mt-3">
      <AccountInfoAboItem :abo="abo" @setAboState="setAboState($event, abo)" />
    </div>
  </div>
</template>

<script lang="ts">
import { ShoppingProduct, ShoppingProductAbo } from "@/api/models/shopping-cart";
import { paymentStore } from "@/stores/payment-store";
import { ChartHandler } from "@/utils/ChartHandler";
import { AccountAboChange } from "@/views/components/AccountInfoAboItem.vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import AccountAboCancellationDialog from "../components/AccountAboCancellationDialog.vue";
import AccountInfoAboItem from "../components/AccountInfoAboItem.vue";
import { translationStore } from "@/stores/translation-store";
import { userRightStore } from "@/stores/user-right-store";
import type { UserInfo } from "@/api/models/user-info";
import { cookieStore } from "@/stores/cookie-store";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "AccountSettings",

  components: { AccountInfoAboItem, AccountAboCancellationDialog, RouterLink },

  expose: [],

  data() {
    const { userInfo, isAdmin } = storeToRefs(userRightStore());
    const { products } = storeToRefs(paymentStore());
    return {
      paymentStore: paymentStore(),
      translationStore: translationStore(),
      products: products as unknown as ShoppingProduct[],
      userInfo: userInfo as unknown as UserInfo | null,
      isAdmin: isAdmin as unknown as boolean,
      chartHandler: ChartHandler.getInstance(),
      abos: [] as ShoppingProductAbo[],
      aboTermination: null as any
    };
  },

  computed: {
    activeAbos() {
      return this.abos.filter(abo => abo.isActive);
    },

    inactiveAbos() {
      return this.abos.filter(abo => !abo.isActive && abo.product.isActive && abo.product.defaultVariant?.isActive);
    }
  },

  watch: {
    products() {
      this.refreshAbos();
    }
  },

  mounted() {
    this.refreshAbos();
  },

  methods: {
    async refreshAbos() {
      const abos = [] as ShoppingProductAbo[];
      for (const product of this.products) {
        const abo = new ShoppingProductAbo(product, null, 0);
        abos.push(abo);
      }
      const subscriptions = this.userInfo == null ? [] : await this.paymentStore.getSubscriptions(this.userInfo.email);
      for (const subscription of subscriptions) {
        abos.forEach((abo, index) => {
          if (abo.product.id === subscription.productId) {
            for (const variant of abo.product.variants) {
              if (variant.id === subscription.planVariantId) {
                abos[index].amount = variant.recurringPrice;
              }
            }
            abos[index].subscription = subscription;
          }
        });
      }
      this.abos = abos;
    },

    async confirmTermination() {
      await this.paymentStore.requestTermination(this.aboTermination.subscription);
      setTimeout(this.hideTerminationDialog.bind(this), 750);
    },

    async confirmRevocation() {
      await this.paymentStore.requestRevocation(this.aboTermination.subscription);
      setTimeout(this.hideTerminationDialog.bind(this), 750);
    },

    async setContractInfos(abo: ShoppingProductAbo) {
      if (abo.subscription != null) {
        await this.paymentStore.requestSelfServiceToken(abo.subscription.id);
      }
    },

    async requestTerminationInfo(abo: ShoppingProductAbo) {
      if (abo.subscription == null) {
        return;
      }
      this.aboTermination = abo;
      const cancelInfo = await this.paymentStore.requestTerminationInfo(abo.subscription);
      this.aboTermination.subscriptionEnd = cancelInfo.nextPossibleCancellationDate;
      this.aboTermination.revocationUntil = cancelInfo.revocationUntil;
      const dialog = (this.$refs.terminationDialog as typeof AccountAboCancellationDialog);
      dialog.show();
    },

    openCookieBanner() {
      cookieStore().openCookieBanner();
    },

    reloadTranslations() {
      translationStore().reloadTranslations();
    },

    hideTerminationDialog() {
      this.refreshAbos();
      this.aboTermination = null;
      const dialog = (this.$refs.terminationDialog as typeof AccountAboCancellationDialog);
      dialog.close();
    },

    async setAboState(type: AccountAboChange, abo: ShoppingProductAbo) {
      if (type === AccountAboChange.Terminate) {
        this.requestTerminationInfo(abo);
      } else if (type === AccountAboChange.CancelTermination) {
        if (abo.subscription == null) {
          return;
        }
        await this.paymentStore.requestCancelTermination(abo.subscription);
        setTimeout(this.hideTerminationDialog.bind(this), 500);
      } else {
        const cart = await this.paymentStore.createCart();
        await this.paymentStore.addCartItem(abo.product.id, abo.product.defaultVariant.id);
        const routeData = this.$router.resolve({ name: "Cart", query: { cart: cart.id } });
        window.open(routeData.href, "_blank", "noreferrer");
      }
    }
  }
});
</script>

<style scoped>
.account-info-caption {
  margin: 5px;
  font-size: large;
  font-weight: bold;
}
</style>

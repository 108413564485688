import type { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { ChartDrawer, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";
import type { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { ChartHint } from "@/anfin-chart/hints/chart-hint";

export abstract class ToolHint extends ChartHint {

  protected constructor(protected readonly tool: ChartTool,
                        private readonly point: ChartToolPoint,
                        private readonly alignment: TextAlignment,
                        borderColor: RGBAColor | null = null) {
    super(tool.getSubChart().chart, borderColor);
    tool.hints.push(this);
  }

  protected override getPosition(drawer: ChartDrawer) {
    const position = this.tool.getPosition(this.point);
    return drawer.getAlignedPosition(position, this.getSize(drawer), this.alignment);
  }
}

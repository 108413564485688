export interface Environment {
  name: string;
  marketUrl: string;
  marketRequestMode: string;
  analysisUrl: string;
  userUrl: string;
  websocketUrl: string;
  marketWebsocketUrl: string;
  screenerUrl: string;
  homeUrl: string;
  paymentUrl: string;
  authenticationUrl: string;
  configurationUrl: string;
  chatUrl: string;
  webApplicationUrl: string;
  billingUrl: string;
  billingPublicApi: string;
  paymentMethods: string[];
  isSendErrorLogs: boolean;
}

const environments: Record<string, Environment> = {
  STANDARD: {
    name: "as01",
    marketUrl: "https://hd.anfin.com",
    marketRequestMode: "TOHLCV",
    analysisUrl: "https://at01.anfin.com",
    userUrl: "https://as01.anfin.com",
    websocketUrl: "wss://ws01.anfin.com",
    marketWebsocketUrl: "wss://md01.anfin.com/md",
    screenerUrl: "https://mon.anfin.com/screener",
    homeUrl: "https://de.anfin.com",
    paymentUrl: "https://pay.anfin.com/payment",
    authenticationUrl: "https://login.anfin.com",
    configurationUrl: "https://cfg.anfin.com/user/config",
    chatUrl: "https://cfg.anfin.com",
    webApplicationUrl: "https://was.anfin.com/was",
    billingUrl: "https://selfservice.billwerk.com/subscription.js",
    billingPublicApi: "64c10c97697cab0f72d23866",
    paymentMethods: ["Debit:SepaXml", "CreditCard:Reepay", "PayPal", "ApplePay:Reepay"],
    isSendErrorLogs: false
  },
  TEST: {
    name: "test",
    marketUrl: "https://test.anfin.com/analyse",
    marketRequestMode: "TOHLCV",
    analysisUrl: "https://test.anfin.com/analyse",
    userUrl: "https://test.anfin.com/api",
    websocketUrl: "wss://test.anfin.com/wsapi",
    marketWebsocketUrl: "wss://md01.anfin.com/md",
    screenerUrl: "https://test.anfin.com/screener",
    homeUrl: "https://test.anfin.com/chart",
    paymentUrl: "https://test.anfin.com/payment",
    authenticationUrl: "https://test.anfin.com",
    configurationUrl: "https://test.anfin.com/user/config",
    chatUrl: "https://test.anfin.com",
    webApplicationUrl: "https://test.anfin.com/was",
    billingUrl: "https://selfservice.sandbox.billwerk.com/subscription.js",
    billingPublicApi: "64897f86072a62da6ada5c68",
    paymentMethods: ["Debit:Stripe", "CreditCard:Reepay", "PayPal", "ApplePay:Reepay"],
    isSendErrorLogs: true
  },
  UAT: {
    name: "uat",
    marketUrl: "https://hd.anfin.com",
    marketRequestMode: "TOHLCV",
    analysisUrl: "https://at01.anfin.com",
    userUrl: "https://as01.anfin.com",
    websocketUrl: "wss://ws01.anfin.com",
    marketWebsocketUrl: "wss://md01.anfin.com/md",
    screenerUrl: "https://mon.anfin.com/screener",
    homeUrl: "https://uat.anfin.com/chart",
    paymentUrl: "https://bill.anfin.com/payment",
    authenticationUrl: "https://login.anfin.com",
    configurationUrl: "https://cfg.anfin.com/user/config",
    chatUrl: "https://cfg.anfin.com",
    webApplicationUrl: "https://was.anfin.com/was",
    billingUrl: "https://selfservice.billwerk.com/subscription.js",
    billingPublicApi: "64c10c97697cab0f72d23866",
    paymentMethods: ["Debit:SepaXml", "CreditCard:Reepay", "PayPal", "ApplePay:Reepay"],
    isSendErrorLogs: true
  }
};

export function getEnvironment() {
  const host = window.location.hostname;
  if (host === "de.anfin.com" || host === "uat.anfin.com") {
    return environments.UAT;
  }
  if (host === "test.anfin.com" || host === "localhost" || host === "app.anfin.com") {
    return environments.TEST;
  }
  return environments.STANDARD;
}

<template>
  <LandingPageWrapper>
    <LandingPageSection class="py-md-5 py-4">
      <div class="landing-header">
        Impressum
      </div>
      <div class="mt-5">
        <div>Formationstrader GmbH</div>
        <div>Willy-Brandt-Allee 31 D</div>
        <div>23554 Lübeck</div>
      </div>
      <div class="mt-4">
        <span>Email: </span>
        <a href="mailto:kontakt@formationstrader.de">kontakt@formationstrader.de</a>
      </div>
      <div class="mt-4">
        Geschäftsführer: Dr. Hamed Esnaashari, Patrick Bienia, Markus Janssen
      </div>
      <div class="mt-4">
        Verantwortlich nach §18 Abs. 1 MStV: Dr. Hamed Esnaashari, Patrick Bienia, Markus Janssen
      </div>
      <div class="mt-4">
        <div>Handelsregister: Amtsgericht Lübeck, HRB 17271 HL</div>
        <div>USt.-ID. nach § 27a UStG: DE 316572245</div>
      </div>
      <div class="mt-4">
        <div>Fotos:</div>
        <div>Getty Images / N+P Industrial Design</div>
      </div>
      <div class="mt-4 font-bold">
        Alternative Streitbeilegung und OS-Plattform
      </div>
      <div class="mt-3">
        <div>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Diese ist über die folgende Internetadresse erreichbar:</div>
        <div class="mt-1">
          <a href="https://ec.europa.eu/consumers/odr/" class="link" target="_blank" rel="noopener noreferrer">
            https://ec.europa.eu/consumers/odr/
          </a>
        </div>
      </div>
      <div class="mt-3 font-bold">
        Wir sind nicht verpflichtet an einem Streitbeilegungsverfahren der Verbraucherschlichtungsstellen teilzunehmen.
      </div>
      <LegalDocument :data="imprintDocument" class="mt-5" />
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import LegalDocument from "@/views/pages-landing/LegalDocument.vue";
import { imprintDocument } from "@/api/models/legal-document";

export default defineComponent({
  name: "ImprintPage",

  components: { LegalDocument, LandingPageSection, LandingPageWrapper },

  expose: [],

  data() {
    return {
      imprintDocument
    };
  }
});
</script>

<style scoped>
</style>
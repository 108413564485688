import type { Point } from "@/anfin-chart/geometry";
import { simpleMapCompare } from "@/anfin-chart/utils";

export class ChartToolPoint {

  private static idCounter = 0;

  public readonly id: number;

  constructor() {
    this.id = ChartToolPoint.idCounter++;
  }
}

export class FixedPoint extends ChartToolPoint {

  constructor(public time: number,
              public price: number) {
    super();
  }

  public update(time: number, price: number) {
    if (time === this.time && price === this.price) {
      return;
    }
    this.time = time;
    this.price = price;
  }
}

export class TracePoint extends ChartToolPoint {

  constructor(public readonly indexOffset: number,
              public readonly priceOffset: number) {
    super();
  }
}

export function getLeftRight(points: Point[]) {
  return points.slice(0, 2).sort(simpleMapCompare(p => p.x, p => p.y));
}

import { ZoomAction } from "@/anfin-chart/actions/zoom-action";
import { ChartArea } from "@/anfin-chart/area/chart-area";
import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { Rectangle } from "@/anfin-chart/geometry";

export class ZoomWindowOverlay extends ChartArea {

  public constructor(layer: ChartLayer) {
    super(layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.chart.drawingAreaWrapper.getPositionObservable(), () => this.resize());
  }

  public getZoomWindow() {
    const mouseDownPosition = this.chart.mouseData.getMouseDownPosition();
    const currentPosition = this.chart.mouseData.getPosition();
    const xStart = Math.min(mouseDownPosition.x, currentPosition.x);
    const xEnd = Math.max(mouseDownPosition.x, currentPosition.x);
    const yStart = Math.min(mouseDownPosition.y, currentPosition.y);
    const yEnd = Math.max(mouseDownPosition.y, currentPosition.y);
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  protected override drawInternal() {
    if (this.chart.action instanceof ZoomAction && this.chart.mouseData.getMouseDown()) {
      this.drawer.drawRect(this.getZoomWindow(), null, new RGBAColor(101, 156, 194, 0.3));
    }
  }

  protected override resizeInternal() {
    return this.chart.drawingAreaWrapper.getPosition();
  }
}

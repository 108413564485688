<template>
  <div class="switch" :class="{ 'switch-disabled': disabled }">
    <label v-if="caption != null" class="form-check-label me-2">{{ caption }}</label>
    <SwitchButton
      :disabled="disabled" :modelValue="modelValue" :labelLeft="labelOff" :labelRight="labelOn"
      :showOnlyActiveLabel="true" @update:modelValue="update"
    />
  </div>
</template>

<script lang="ts">
import { translationStore } from "@/stores/translation-store";
import { defineComponent } from "vue";
import SwitchButton from "@/views/components/SwitchButton.vue";

export default defineComponent({
  name: "OnOffSwitch",

  components: { SwitchButton },

  props: {
    caption: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean
    },
    showLabels: {
      type: Boolean
    }
  },

  emits: {
    "update:modelValue": (value: boolean) => value || true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    labelOff() {
      return this.showLabels ? this.translationStore.getTranslation("switch_button#off") : null;
    },

    labelOn() {
      return this.showLabels ? this.translationStore.getTranslation("switch_button#on") : null;
    }
  },

  methods: {
    update(value: boolean) {
      this.$emit("update:modelValue", value);
    }
  }
});
</script>

<style scoped>
.switch {
  display: inline-flex;
  align-items: center;
}

.switch-disabled {
  color: #676767;
}
</style>

<template>
  <div class="context-menu-group" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="context-menu-group-content user-select-none">
      <span>{{ label }}</span>
      <IconElement iconName="ChevronRight" :size="16" class="context-menu-group-arrow" />
    </div>

    <ContextMenuList v-show="isHovered" class="context-menu-group-expanded">
      <slot></slot>
    </ContextMenuList>
  </div>
</template>

<script lang="ts">
import ContextMenuList from "@/views/context-menu/ContextMenuList.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContextMenuGroup",

  components: { ContextMenuList, IconElement },

  props: {
    label: {
      type: String,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      isHovered: false
    };
  },

  methods: {
    onMouseEnter() {
      this.isHovered = true;
    },

    onMouseLeave() {
      this.isHovered = false;
    }
  }
});
</script>

<style scoped>
.context-menu-group {
  position: relative;
}

.context-menu-group-content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 30px 6px 12px;
  border: 1px solid transparent;
}

.context-menu-group-content:hover {
  background-color: var(--background-overlay);
  border: 1px solid var(--border-overlay);
}

.context-menu-group-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.context-menu-group-expanded {
  position: absolute;
  top: 0;
  left: 100%;
}

.context-menu.context-menu-right-aligned .context-menu-group-expanded {
  left: auto;
  right: 100%;
}
</style>

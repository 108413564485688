<template>
  <LandingPageWrapper>
    <LandingPageSection class="py-md-5 py-4">
      <LegalDocument :data="agbDocument" />
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import LegalDocument from "@/views/pages-landing/LegalDocument.vue";
import { agbDocument } from "@/api/models/legal-document";

export default defineComponent({
  name: "AGBPage",

  components: { LegalDocument, LandingPageSection, LandingPageWrapper },

  expose: [],

  data() {
    return {
      agbDocument
    };
  }
});
</script>

<style scoped>
</style>
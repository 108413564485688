import { InstrumentDetailConverter, type InstrumentDetailListResponse } from "@/api/messages/instrument";
import { RestApiController } from "@/api/rest-api-controller";

export class InstrumentController extends RestApiController {

  private static instance: InstrumentController | null = null;

  private readonly converter = new InstrumentDetailConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new InstrumentController();
    }
    return this.instance;
  }

  public async getInstrumentDetails() {
    const response = await this.sendGetRequest<InstrumentDetailListResponse>("hiku/markets/instruments");
    return response.data.map(i => this.converter.toModelObject(i));
  }

  protected override getBaseUrl() {
    return this.environment.marketUrl;
  }
}

import { RestApiController } from "@/api/rest-api-controller";

export class ContactController extends RestApiController {

  private static instance: ContactController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new ContactController();
    }
    return this.instance;
  }

  public async sendEmail(name: string, email: string, text: string) {
    const request = { name, email, text };
    try {
      await this.sendPostRequest<unknown>("contact", request);
      return true;
    } catch (e) {
      return false;
    }
  }

  protected getBaseUrl(): string {
    return this.environment.webApplicationUrl;
  }
}

import { defineComponent } from "vue";

const trustedShopElementId = "trusted-shop-script";

export const TrustedShopMixin = defineComponent({
  mounted() {
    if (document.getElementById(trustedShopElementId) != null) {
      return;
    }
    const trustedShopScript = document.createElement("script");
    trustedShopScript.id = trustedShopElementId;
    const trustedShopId = "X584180FF8A068ADE2C578E817AE7B589";
    trustedShopScript.setAttribute("src", "https://widgets.trustedshops.com/js/" + trustedShopId + ".js");
    document.body.appendChild(trustedShopScript);
  }
});

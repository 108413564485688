import { ClickOutside } from "@/directives/click-outside";
import { Draggable } from "@/directives/draggable";
import "bootstrap";

import { createPinia } from "pinia";

import * as Vue from "vue";
import InlineSvg from "vue-inline-svg";
import App from "./App.vue";

import "./assets/main.css";
import router from "./router";

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = Vue.markRaw(router);
});

const app = Vue.createApp(App);
app.directive("click-outside", ClickOutside);
app.directive("draggable", Draggable);
app.directive("shortkey", {
  // TODO: implement or use correct extension library, this placeholder is just to prevent annoying console warnings
});
app.component("inline-svg", InlineSvg);
app.use(pinia);
app.use(router);
app.mount("#app");

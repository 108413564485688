import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { LineElement } from "@/anfin-chart/drawable";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { Point } from "@/anfin-chart/geometry";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class VerticalLine extends UserToolDefinition {

  public static readonly type = "vline";

  private readonly color = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(140, 29, 68));

  private readonly upper = new ChartToolPoint();
  private readonly lower = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(VerticalLine.type, instrument, timeframe, 1, 1);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
  }

  public override updatePosition(tool: UserTool) {
    const base = tool.getPosition(this.fixedPoints[0]);
    const rect = tool.subChart.drawingArea.getPosition();
    const upper = new Point(base.x, rect.yStart);
    tool.updatePoint(this.upper, upper);
    const lower = new Point(base.x, rect.yEnd);
    tool.updatePoint(this.lower, lower);
  }

  private createFirst(tool: UserTool) {
    const line = new LineElement(tool, this.upper, this.lower);
    tool.onUpdate(() => {
      line.color = this.color.getValue();
      line.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }
}

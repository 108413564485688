<template>
  <img :src="url" alt="AnFin Logo" />
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { chartOptionStore } from "@/stores/chart-option-store";
import type { LogoStyle } from "@/api/models/theme";

export default defineComponent({
  name: "AnfinLogo",

  props: {
    style: {
      type: String as PropType<LogoStyle>,
      default: null
    }
  },

  expose: [],

  computed: {
    url() {
      const style = this.style ?? chartOptionStore().currentTheme.logo;
      return "../../static/images/anfin_" + style + ".svg";
    }
  }
});
</script>

<style scoped>
.anfin-logo-xs {
  height: 44px;
}

.anfin-logo-sm {
  height: 80px;
}

.anfin-logo-md {
  height: 120px;
}
</style>
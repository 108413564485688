<template>
  <PageWrapper :staticTheme="0">
    <div class="landing-page-wrapper">
      <slot name="topbar">
        <LandingTopbar />
      </slot>
      <div class="content-wrapper">
        <slot></slot>
        <LandingFooter />
        <ShoppingCartModal v-show="isShowCart" />
      </div>
    </div>
  </PageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingTopbar from "@/views/pages-landing/LandingTopbar.vue";
import PageWrapper from "@/views/PageWrapper.vue";
import ShoppingCartModal from "@/views/pages-landing/ShoppingCartModal.vue";
import LandingFooter from "@/views/pages-landing/LandingFooter.vue";
import { storeToRefs } from "pinia";
import { paymentStore } from "@/stores/payment-store";

export default defineComponent({
  name: "LandingPageWrapper",

  components: { LandingFooter, ShoppingCartModal, PageWrapper, LandingTopbar },

  expose: [],

  data() {
    const { isShowCart } = storeToRefs(paymentStore());
    return {
      isShowCart: isShowCart as unknown as boolean
    };
  }
});
</script>

<style>
.landing-page-wrapper {
  width: 100%;
  height: 100%;
  font-size: 16px;
  overflow-y: auto;
  font-family: Roboto, sans-serif;
}

.landing-page-wrapper .content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.landing-header {
  font-size: 200%;
  font-weight: 600;
  color: var(--background-primary);
}

.landing-sub-header {
  font-size: 125%;
  color: var(--content-tertiary);
  font-weight: 700;
}

.landing-secondary-header {
  font-size: 125%;
}

.landing-page-wrapper .link {
  text-decoration: none;
  font-size: inherit;
}

.landing-page-wrapper

.section-gray {
  background-color: #F0F0F0;
}

.section-green {
  background-color: var(--background-primary) !important;
  color: var(--content-primary-top);
}

.section-green .landing-header {
  color: var(--content-primary-top);
}

.landing-section-split {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: start;
}

.landing-section-split > * {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.text-gradient-gold {
  color: transparent;
  background: #cfaf4a;
  background-clip: text;
}

@media (max-width: 539px) {
  .landing-section-split {
    flex-direction: column;
  }

  .landing-section-split > * {
    flex-shrink: 0;
  }
}

@media (max-width: 767px) {
  .landing-page-wrapper {
    font-size: 14px;
  }

  .landing-section-split {
    gap: 25px;
  }
}

@media (max-width: 1199px) {
  .landing-header {
    font-size: 150%;
  }
}
</style>
<template>
  <RouterLink to="/imprint" class="link">
    <slot>
      {{ translationStore.getTranslation("imprint") }}
    </slot>
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "ImprintLink",

  components: { RouterLink },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  }
});
</script>

<style scoped>
</style>
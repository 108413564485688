<template>
  <div class="mt-1">
    <span v-if="chapter.header != null" class="font-bold">
      <u>{{ chapter.header }}</u>
    </span>
    <div class="font-smaller mt-1 d-flex">
      <a href="#" class="message-video-time link" @click="setVideoTime">{{ timeFormatted }}</a>
      <div class="ps-1">
        {{ chapter.title }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ChatVideoChapter } from "@/api/models/chat";

export default defineComponent({
  name: "ChatMessageVideoChapter",

  props: {
    chapter: {
      type: ChatVideoChapter,
      required: true
    }
  },

  emits: {
    setVideoTime: () => true
  },

  expose: [],

  computed: {
    timeFormatted() {
      const time = this.chapter.time;
      if (time == null) {
        return "";
      }
      return Math.trunc(time / 60) + ":" + (time % 60).toString().padStart(2, "0");
    }
  },

  methods: {
    setVideoTime() {
      this.$emit("setVideoTime");
    }
  }
});
</script>

<style scoped>
.message-video-time {
  display: inline-block;
  text-align: center;
  flex: 40px 0 0;
  text-decoration: none;
}
</style>
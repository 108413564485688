<template>
  <RouterLink to="/shop" class="btn btn-primary lets-go-button">
    JETZT MIT ANFIN DURCHSTARTEN
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "LetsGoButton",

  components: { RouterLink },

  expose: []
});
</script>

<style scoped>
.lets-go-button {
  font-size: 150% !important;
  background-color: var(--interactive-accent-hover);
  border: 1px solid var(--interactive-accent-hover) !important;
}

.lets-go-button:hover, .lets-go-button:active {
  background-color: var(--background-primary) !important;
  box-shadow: none;
  border-color: white !important;
  text-shadow: none;
}

@media (max-width: 1200px) {
  .lets-go-button {
    font-size: 120% !important;
  }
}
</style>
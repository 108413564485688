import { userRightStore } from "@/stores/user-right-store";

export class CustomerData {

  public firstName = "";
  public lastName = "";
  public street = "";
  public houseNumber = "";
  public postalCode = "";
  public city = "";
  public emailAddress = "";
  public emailAddressConfirm = "";
  public country = "DE";

  constructor() {
    const userInfo = userRightStore().userInfo;
    if (userInfo != null) {
      this.firstName = userInfo.firstName;
      this.lastName = userInfo.lastName;
      this.emailAddress = userInfo.email;
    }
  }

  public isValid() {
    return this.city.length > 0 && this.emailAddress.length > 0 && this.emailAddressConfirm.length > 0 &&
      this.firstName.length > 0 && this.lastName.length > 0 && this.street.length > 0 &&
      this.houseNumber.length > 0 && this.postalCode.length > 0 && this.country.length > 0;
  }
}

<template>
  <PageWrapper class="overflow-auto">
    <TopToolbar>
      <template #content-main>
        <h5>{{ translationStore.getTranslation("exchange_inquiry#header") }}</h5>
      </template>
    </TopToolbar>
    <ExchangeInquiry />
  </PageWrapper>
</template>

<script lang="ts">
import { translationStore } from "@/stores/translation-store";
import ExchangeInquiry from "@/views/components/ExchangeInquiry.vue";
import PageWrapper from "@/views/PageWrapper.vue";
import TopToolbar from "@/views/toolbar/TopToolbar.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExchangeInquiryPage",

  components: { ExchangeInquiry, TopToolbar, PageWrapper },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  }
});
</script>

<style scoped>
</style>

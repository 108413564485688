<template>
  <div class="timeframe-additional">
    <ToolbarDropdown
      class="timeframe-additional-item" :items="items" :disabled="!allowChartViews" :arrowHidden="true"
      titleIcon="Additional" :position="dropdownPosition"
    />
  </div>
</template>

<script lang="ts">
import { Instrument } from "@/anfin-chart/instrument";
import { Timeframe, TimeUnit } from "@/anfin-chart/time/timeframe";
import { CssPosition, simpleCompare } from "@/anfin-chart/utils";
import { favoriteStore } from "@/stores/favorite-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { translationStore } from "@/stores/translation-store";
import { userSettingStore } from "@/stores/user-setting-store";
import { ToolbarItemData } from "@/views/toolbar/toolbar-item-data";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import ToolbarDropdown from "./ToolbarDropdown.vue";
import type { ChartView } from "@/api/models/user-settings/chart";

export default defineComponent({
  name: "ChartTimeframeSelector",

  components: { ToolbarDropdown },

  expose: [],

  data() {
    const { instrument, timeframe } = storeToRefs(multiChartStore());
    const { namedChartViews } = storeToRefs(userSettingStore());
    return {
      instrument: instrument as unknown as Instrument,
      timeframe: timeframe as unknown as Timeframe,
      chartViews: namedChartViews as unknown as ChartView[],
      allowChartViews: true,
      // allowChartViews: userRightStore().hasRight(UserRightKey.Views),
      defaultTimeframes: [
        new Timeframe(TimeUnit.Minute, 1),
        new Timeframe(TimeUnit.Minute, 5),
        new Timeframe(TimeUnit.Minute, 10),
        new Timeframe(TimeUnit.Minute, 15),
        new Timeframe(TimeUnit.Minute, 30),
        new Timeframe(TimeUnit.Hour, 1),
        new Timeframe(TimeUnit.Hour, 4),
        new Timeframe(TimeUnit.Hour, 6),
        new Timeframe(TimeUnit.Hour, 8),
        Timeframe.D1,
        new Timeframe(TimeUnit.Week, 1),
        Timeframe.MN1
      ],
      dropdownPosition: new CssPosition(32, 0, null, null),
      translationStore: translationStore()
    };
  },

  computed: {
    sortedChartViews() {
      const sortedViews = this.chartViews.filter(v => Instrument.isSame(v.instrument, this.instrument));
      sortedViews.sort((first, second) => {
        const timeframeCompare = Timeframe.compare(first.timeframe, second.timeframe);
        if (timeframeCompare !== 0) {
          return timeframeCompare;
        }
        return simpleCompare(first.name, second.name);
      });
      return sortedViews;
    },

    items() {
      return this.defaultTimeframes.map((tf: Timeframe) => {
        const onSelect = () => this.setTimeframe(tf);
        const name = this.translationStore.getTimeframe(tf, "long");
        const favoriteKey = favoriteStore().getTimeframeKey(tf);
        return new ToolbarItemData(tf.toShortNotation(), name, null, onSelect, null, null, favoriteKey);
      });
    }
  },

  watch: {
    instrument(instrument: Instrument) {
      userSettingStore().fetchChartViews(instrument);
    }
  },

  methods: {
    setTimeframe(timeframe: Timeframe) {
      multiChartStore().setTimeframe(timeframe);
    }
  }
});
</script>

<style>
.timeframe-additional {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.timeframe-additional-item {
  display: flex;
  border-radius: 6px;
  align-items: center;
  border: 1px solid #00000000;
  margin: 4px 1px;
  padding: 0 5px;
  height: 24px;
  stroke: var(--content-secondary);
  fill: var(--content-secondary);
}

.timeframe-additional-item:hover {
  background-color: var(--background-overlay);
  border: 1px solid var(--border-overlay);
}

@media screen and (max-width: 940px) {
  .timeframe-additional-item > .toolbar-dropdown-list {
    right: 0;
  }
}
</style>

<template>
  <ExpandableList ref="expandableList" :disabled="disabled" class="custom-dropdown">
    <template #header>
      <div class="custom-dropdown-toggle form-control form-control-sm" :class="{ disabled: disabled }">
        <span class="custom-dropdown-header">
          <slot name="header"></slot>
        </span>
        <IconElement iconName="Expand" :size="12" class="ms-auto" />
      </div>
    </template>
    <template #content>
      <div class="custom-dropdown-item-list">
        <slot name="items"></slot>
      </div>
    </template>
  </ExpandableList>
</template>

<script lang="ts">
import ExpandableList from "@/views/components/ExpandableList.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomDropdown",

  components: { ExpandableList, IconElement },

  props: {
    disabled: {
      type: Boolean
    }
  },

  expose: ["hide"],

  methods: {
    hide() {
      const expandableList = this.$refs.expandableList as typeof ExpandableList;
      expandableList.hide();
    }
  }
});
</script>

<style scoped>
.custom-dropdown {
  min-width: 100px;
}

.custom-dropdown-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.custom-dropdown-toggle.disabled {
  background-color: var(--disabled-primary);
}

.custom-dropdown-header {
  min-width: 0;
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 8px;
}

.custom-dropdown-item-list {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

import { WebSocketApiController } from "@/api/web-socket-api-controller";
import { getEnvironment } from "@/api/environment";

type PriceConsumer = (symbol: string, tickData: any) => void;
type ReconnectConsumer = () => void;

export class MarketDataWebSocketController extends WebSocketApiController {

  private static instance: MarketDataWebSocketController | null = null;
  private onPriceCallback: PriceConsumer | null = null;
  private onReconnectCallback: ReconnectConsumer | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new MarketDataWebSocketController();
    }
    return this.instance;
  }

  public setOnPriceCallback(onPriceCallback: PriceConsumer) {
    this.onPriceCallback = onPriceCallback;
  }

  public setOnReconnectCallback(onReconnectCallback: ReconnectConsumer) {
    this.onReconnectCallback = onReconnectCallback;
  }

  public override onMessage(event: MessageEvent) {
    if (event.data === "") {
      return;
    }
    try {
      JSON.parse(event.data);
    } catch (e) {
      console.error(e, event.data);
      return;
    }

    const message = JSON.parse(event.data);
    const action = message.action;
    if (action == null) {
      console.error("Cannot resolve web socket action");
      return;
    }
    // super.logAction(message, "WS RECEIVE");
    const data = message.data;
    if (action === "login") {
      if (super.onLoginResponse(data) && this.onReconnectCallback) {
        this.onReconnectCallback();
      }
    } else if (action === "updPrice") {
      if (data.candle?.timestamp != null && data.candle.timestamp < 16745930000) {
        data.candle.timestamp *= 1000;
      }
      this.handlePriceUpdate(data.name, data.candle);
    } else if (action === "updPrices") {
      // new version price update with array
      const priceArray: any[] = data;
      priceArray.forEach((data) => {
        this.handlePriceUpdate(data.name, data.candle);
      });
    } else if (action === "resHeartbeat") {
      super.onHeartbeatResponse();
    } else {
      console.error("Unhandled action: " + action);
    }
    const requestId = message.requestId;
    const request = requestId == null ? null : this.requestCallbackMap.get(requestId);
    if (request != null) {
      request.callback(data);
      this.requestCallbackMap.delete(requestId);
    }
  }

  public handlePriceUpdate(symbol: string, tickData: any) {
    if (this.onPriceCallback) {
      this.onPriceCallback(symbol, tickData);
    }
  }

  protected override getBaseUrl() {
    return getEnvironment().marketWebsocketUrl;
  }
}

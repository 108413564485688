<template>
  <div>
    <div class="card-set-container">
      <div v-for="(plot, plotIndex) in indicatorDefinition.plots" :key="plot.name" class="card card-sm card-transparent mb-2">
        <IconElement class="close-button" :size="14" iconName="Cross" @click="removePlot(plotIndex)" />
        <div class="d-flex flex-row gap-1">
          <IconElement :size="16" iconName="ChevronUp" @click="movePlot(plotIndex, -1)" />
          <IconElement :size="16" iconName="ChevronDown" @click="movePlot(plotIndex, 1)" />
        </div>
        <div class="mt-2">
          <label class="form-label">
            {{ translationStore.getTranslation("indicator_definition#label_plot_name") }}
          </label>
          <input v-model="plot.name" type="text" class="form-control form-control-sm input-w-medium" />
        </div>
        <div class="mt-2">
          <label class="form-label">
            {{ translationStore.getTranslation("indicator_definition#label_plot_type") }}
          </label>
          <SingleSelect v-model="plot.type" :items="plotTypeItems" />
        </div>
        <div class="form-check mt-2">
          <input :id="'plot-' + plotIndex + '-use-axis'" v-model="plot.useAxis" type="checkbox" class="form-check-input" />
          <label class="form-check-label" :for="'plot-' + plotIndex + '-use-axis'">
            {{ translationStore.getTranslation("indicator_definition#label_use_axis") }}
          </label>
        </div>
        <div class="mt-2">
          <label class="form-label">
            {{ translationStore.getTranslation("indicator_definition#label_color_provider_type") }}
          </label>
          <ColorProviderEditor v-model="plot.colorProvider" :plotType="plot.type" />
        </div>
      </div>
    </div>
    <button class="btn btn-primary text-nowrap mt-2" type="button" @click="createPlot">
      <span>
        <IconElement :size="14" iconName="Plus" />
      </span>
      {{ translationStore.getTranslation("indicator_definition#button_create_plot") }}
    </button>
    <div class="card-set-container mt-2">
      <div v-for="(priceRange, priceRangeIndex) in indicatorDefinition.priceRanges" :key="priceRangeIndex" class="card card-sm card-transparent mb-2">
        <IconElement class="close-button" :size="14" iconName="Cross" @click="removePriceRange(priceRangeIndex)" />
        <div>
          <label class="form-label">
            {{ translationStore.getTranslation("indicator_definition#label_price_range_from") }}
          </label>
          <input v-model="priceRange.from" type="number" class="form-control form-control-sm input-w-medium" />
        </div>
        <div>
          <label class="form-label">
            {{ translationStore.getTranslation("indicator_definition#label_price_range_to") }}
          </label>
          <input v-model="priceRange.to" type="number" class="form-control form-control-sm input-w-medium" />
        </div>
        <div class="mt-2 d-flex flex-row">
          <RgbaColorPickerButton v-model="priceRange.lineColor" :size="20" class="mt-2" />
          <RgbaColorPickerButton v-model="priceRange.areaColor" :size="20" class="mt-2" />
        </div>
      </div>
    </div>
    <button class="btn btn-primary text-nowrap mt-2" type="button" @click="createPriceRange">
      <span>
        <IconElement :size="14" iconName="Plus" />
      </span>
      {{ translationStore.getTranslation("indicator_definition#button_create_price_range") }}
    </button>
  </div>
</template>

<script lang="ts">
import { IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import {
  ColorDefinition,
  ColorProviderDefinition,
  ColorProviderType,
  ColorType,
  PlotDefinition,
  PriceRangeDefinition
} from "@/anfin-chart/indicator-definition";
import { PlotType } from "@/anfin-chart/plot";
import { getEnumValues } from "@/anfin-chart/utils";
import { translationStore } from "@/stores/translation-store";
import RgbaColorPickerButton from "@/views/color-picker/RgbaColorPickerButton.vue";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import IconElement from "@/views/icons/IconElement.vue";
import ColorProviderEditor from "@/views/indicator-definition/ColorProviderEditor.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IndicatorDefinitionLines",

  components: { SingleSelect, RgbaColorPickerButton, IconElement, ColorProviderEditor },

  props: {
    indicatorDefinition: {
      type: IndicatorDefinition,
      required: true
    }
  },

  expose: [],

  data() {
    const plotTypeItems = [];
    for (const plotType of getEnumValues(PlotType)) {
      const key = "plot_type#" + plotType;
      plotTypeItems.push(new SingleSelectItem(key, null, plotType));
    }
    return {
      translationStore: translationStore(),
      plotTypeItems
    };
  },

  methods: {
    createPlot() {
      const colorProvider = new ColorProviderDefinition(ColorProviderType.Simple, [], null, null, null);
      const plot = new PlotDefinition("", PlotType.Line, colorProvider, false);
      this.indicatorDefinition.plots.push(plot);
    },

    removePlot(index: number) {
      this.indicatorDefinition.plots.splice(index, 1);
    },

    movePlot(index: number, offset: number) {
      const lastIndex = this.indicatorDefinition.plots.length - 1;
      const targetIndex = Math.max(0, Math.min(lastIndex, index + offset));
      if (targetIndex !== index) {
        const [plot] = this.indicatorDefinition.plots.splice(index, 1);
        this.indicatorDefinition.plots.splice(targetIndex, 0, plot);
      }
      console.log(this.indicatorDefinition.plots);
    },

    createPriceRange() {
      const lineColor = new ColorDefinition(ColorType.Rgba, 255, 255, 255, 0.5);
      const areaColor = new ColorDefinition(ColorType.Rgba, 255, 255, 255, 0.2);
      const priceRange = new PriceRangeDefinition(0, 0, lineColor, areaColor);
      this.indicatorDefinition.priceRanges.push(priceRange);
    },

    removePriceRange(index: number) {
      this.indicatorDefinition.priceRanges.splice(index, 1);
    }
  }
});
</script>

<style scoped>
</style>

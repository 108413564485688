<template>
  <div :id="wrapperId" class="simple-typeahead">
    <form class="nosubmit">
      <IconElement v-if="!showIconRight" iconName="Search" :size="20" class="search-icon" />
      <input
        ref="inputRef"
        v-model="input"
        v-bind="$attrs"
        class="nosubmit"
        type="text"
        :placeholder="placeholder"
        autocomplete="off"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keydown.enter.prevent="selectCurrentSelection"
        @keydown.tab.prevent="selectCurrentSelectionTab"
        @keydown.stop
      />
      <div v-if="showIconRight" style="margin-right: 10px">
        <IconElement v-if="icon" :size="22" :iconName="icon" />
        <svg v-else id="search-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75032 1.66797C4.83831 1.66797 1.66699 4.83929 1.66699 8.7513C1.66699 12.6633 4.83831 15.8346 8.75032 15.8346C10.4061 15.8346 11.9292 15.2666 13.1352 14.3146L16.1788 17.3582C16.5042 17.6836 17.0319 17.6836 17.3573 17.3582C17.6827 17.0328 17.6827 16.5051 17.3573 16.1797L14.3137 13.1361C15.2656 11.9301 15.8337 10.407 15.8337 8.7513C15.8337 4.83929 12.6623 1.66797 8.75032 1.66797ZM3.33366 8.7513C3.33366 5.75976 5.75878 3.33464 8.75032 3.33464C11.7419 3.33464 14.167 5.75976 14.167 8.7513C14.167 11.7429 11.7419 14.168 8.75032 14.168C5.75878 14.168 3.33366 11.7429 3.33366 8.7513Z" />
        </svg>
      </div>
    </form>
    <div v-if="isListVisible" class="simple-typeahead-list font-sm">
      <div v-if="$slots['list-header']" class="simple-typeahead-list-header">
        <slot name="list-header"></slot>
      </div>
      <div class="simple-typeahead-list-body">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="simple-typeahead-list-item"
          :class="{ 'highlight-filter-line': currentSelectionIndex === index }"
          @mousedown.stop.prevent
          @mouseup.stop.prevent
          @click="onItemSelect(item)"
          @mouseenter="currentSelectionIndex = index"
        >
          <slot :item="item" :input="input"></slot>
        </div>
      </div>
      <div v-if="$slots['list-footer']" class="simple-typeahead-list-footer">
        <slot name="list-footer"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconElement from "@/views/icons/IconElement.vue";

export default defineComponent({
  name: "AutoFilterList",

  components: { IconElement },

  inheritAttrs: false,

  props: {
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      required: true
    },
    itemProjection: {
      type: Function,
      default(item: any) {
        return item;
      }
    },
    minInputLength: {
      type: Number,
      default: 0,
      validator: (value: number) => {
        return value >= 0;
      }
    },
    showIconRight: {
      type: Boolean
    },
    icon: {
      type: String,
      default: ""
    }
  },
  
  emits: {
    onInput: (input: string) => input || true,
    onFocus: (input: string) => input || true,
    onBlur: (input: string) => input || true,
    selectItem: (item: any) => item || true
  },

  expose: ["clearInput"],

  data() {
    return {
      inputId: this.id ?? `simple_typeahead_${(Math.random() * 1000).toFixed()}`,
      input: "",
      isInputFocused: false,
      currentSelectionIndex: 2
    };
  },

  computed: {
    wrapperId() {
      return `${this.inputId}`;
    },

    filteredItems() {
      /* if (this.input && this.input.length) {
        const regexp = new RegExp(this.escapeRegExp(this.input), 'i');
        return this.items.filter(item => this.itemProjection(item).match(regexp));
      }*/
      return this.items;
    },

    isListVisible() {
      return this.items.length && this.isInputFocused && this.input.length >= this.minInputLength;
    },

    currentSelection() {
      return this.isListVisible && this.currentSelectionIndex < this.filteredItems.length ? this.filteredItems[this.currentSelectionIndex] : null;
    }
  },
  
  methods: {
    onInput() {
      if (this.isListVisible && this.currentSelectionIndex >= this.filteredItems.length) {
        this.currentSelectionIndex = Math.max(this.filteredItems.length - 1, 0);
      }
      this.$emit("onInput", this.input);
    },

    onFocus() {
      this.isInputFocused = true;
      this.$emit("onFocus", this.input);
    },

    onBlur() {
      this.isInputFocused = false;
      this.$emit("onBlur", this.input);
    },

    onArrowDown() {
      if (this.isListVisible && this.currentSelectionIndex < this.filteredItems.length - 1) {
        this.currentSelectionIndex++;
      }
      this.scrollSelectionIntoView();
    },

    onArrowUp() {
      if (this.isListVisible && this.currentSelectionIndex > 0) {
        this.currentSelectionIndex--;
      }
      this.scrollSelectionIntoView();
    },

    scrollSelectionIntoView() {
      setTimeout(() => {
        const listNode = document.querySelector(`#${this.wrapperId} .simple-typeahead-list`) as HTMLElement;
        const activeNode = document.querySelector(`#${this.wrapperId} .simple-typeahead-list-item.simple-typeahead-list-item-active`) as HTMLElement;
        if (activeNode && !(activeNode.offsetTop >= listNode.scrollTop && activeNode.offsetTop + activeNode.offsetHeight < listNode.scrollTop + listNode.offsetHeight)) {
          let scrollTo = 0;
          if (activeNode.offsetTop > listNode.scrollTop) {
            scrollTo = activeNode.offsetTop + activeNode.offsetHeight - listNode.offsetHeight;
          } else if (activeNode.offsetTop < listNode.scrollTop) {
            scrollTo = activeNode.offsetTop;
          }

          listNode.scrollTo(0, scrollTo);
        }
      });
    },

    selectCurrentSelection(event: KeyboardEvent | null) {
      if (this.currentSelection != null) {
        this.onItemSelect(this.currentSelection);
        return;
      }
      const target = event?.target as HTMLInputElement | null;
      if (target != null && target.value !== "") {
        this.$emit("selectItem",  { name: target.value, caption: target.value, selected: false, id: target.value });
      }
    },

    selectCurrentSelectionTab() {
      this.selectCurrentSelection(null);
    },

    onItemSelect(item: any) {
      this.input = this.itemProjection(item) ?? "";
      this.currentSelectionIndex = 0;
      this.blurInput();
      this.$emit("selectItem", item);
    },

    blurInput() {
      (this.$refs.inputRef as HTMLInputElement).blur();
    },

    escapeRegExp(text: string) {
      return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },

    boldMatchText(text: string) {
      const regexp = new RegExp(`(${this.escapeRegExp("")})`, "ig");
      return text.replace(regexp, "<strong>$1</strong>");
    },

    clearInput() {
      this.input = "";
    }
  }
});
</script>

<style scoped>
.simple-typeahead {
  position: relative;
  border-radius: 2px;
  border: 1px solid var(--border-neutral);
}

.simple-typeahead > input {
  margin-bottom: 0;
}

.simple-typeahead .simple-typeahead-list {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  margin-top: 10px;
  border: 0.1rem solid #88888855;
  z-index: 10100;
  padding: 5px 0;
  background-color: var(--background-neutral);
  border-radius: 10px;
}

.simple-typeahead .simple-typeahead-list .simple-typeahead-list-header {
  padding: 0.6rem 1rem;
}

.simple-typeahead .simple-typeahead-list .simple-typeahead-list-footer {
  padding: 0.6rem 1rem;
}

.simple-typeahead-list-body {
  overflow-y: auto;
  margin: 0 2px;
  max-height: 300px;
}

.simple-typeahead .simple-typeahead-list .simple-typeahead-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  padding: 0.25rem 0.4rem;
  color: var(--content-secondary);
  font-weight: normal;
}

.simple-typeahead .simple-typeahead-list .simple-typeahead-list-item:not(:last-child) {
  border-bottom: 1px solid var(--border-neutral);
}

.simple-typeahead .simple-typeahead-list-body .simple-typeahead-list-item:hover {
  color: var(--content-primary-top);
  background-color: var(--background-primary-overlay);
}

form.nosubmit {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  padding: 2px 8px;
}

input.nosubmit {
  background-color: #00000000;
  color: var(--content-primary);
  font-weight: 400;
  border: none;
  outline: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--content-tertiary);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--content-tertiary);

}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--content-tertiary);

}

input:focus::placeholder {
  color: transparent;
}


.simple-typeahead .simple-typeahead-list .highlight-filter-line {
  color: var(--content-primary-top);
  background-color: var(--background-primary-overlay);
}

.search-icon {
  margin-top: 1px;
  color: var(--content-secondary);
}
</style>

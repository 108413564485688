<template>
  <div>
    <ToolbarDropdown ref="templatesDropdown" :items="items" :disabled="!allowTemplates" titleIcon="Templates">
      <template #header>
        {{ translationStore.getTranslation("topbar#chart_template_caption") }}
      </template>
      <template #content>
        <button type="button" class="btn btn-sm btn-primary px-3 m-2" @click="openSaveDialog">
          {{ translationStore.getTranslation("button_save_as") }}
        </button>
      </template>
    </ToolbarDropdown>
    <ModalDialog
      ref="dialog" :title="translationStore.getTranslation('modal_dialog#chart_template#header')"
      autoFocus="chart-template-name" labelSave="button_save" @save="createNewTemplate"
    >
      <template #body>
        <div class="input-group">
          <label class="col-3" for="chart-template-name">Name</label>
          <input
            id="chart-template-name" v-model="templateName" type="text" class="form-control form-control-sm"
            placeholder="Enter Template Name" required
          />
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script lang="ts">
import { multiChartStore } from "@/stores/multi-chart-store";
import { userSettingStore } from "@/stores/user-setting-store";
import { ChartHandler } from "@/utils/ChartHandler";
import ModalDialog from "@/views/components/ModalDialog.vue";
import { ToolbarItemData } from "@/views/toolbar/toolbar-item-data";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import ToolbarDropdown from "./ToolbarDropdown.vue";
import { translationStore } from "@/stores/translation-store";
import { MultiWindowTemplate } from "@/api/models/user-settings/chart";
import { ChartLayout } from "@/api/models/chart-layout";
import { ChartLayoutConverter } from "@/api/messages/layout";
import type { Chart } from "@/anfin-chart/chart";
import { simpleMapCompare } from "@/anfin-chart/utils";

export default defineComponent({
  name: "ChartTemplateSelector",

  components: { ModalDialog, ToolbarDropdown },

  expose: [],

  data() {
    const { chartTemplates } = storeToRefs(userSettingStore());
    return {
      translationStore: translationStore(),
      chartHandler: ChartHandler.getInstance(),
      allowTemplates: true,
      // allowTemplates: userRightStore().hasRight(UserRightKey.Templates),
      templates: chartTemplates as unknown as MultiWindowTemplate[],
      templateName: ""
    };
  },

  computed: {
    sortedTemplates() {
      return this.templates.slice().sort(simpleMapCompare(t => t.name));
    },

    items() {
      const items: ToolbarItemData[] = [];
      for (const template of this.sortedTemplates) {
        const key = "chart_template_" + template.id;
        const onSelect = () => this.loadTemplate(template);
        const onSave = () => this.saveTemplate(template);
        const onDelete = () => this.deleteTemplate(template);
        items.push(new ToolbarItemData(key, template.name, null, onSelect, onSave, onDelete));
      }
      return items;
    }
  },

  created() {
    userSettingStore().fetchChartTemplates();
  },

  methods: {
    createNewTemplate() {
      const name = this.templateName.trim();
      if (name === "") {
        return;
      }
      this.templateName = "";
      const template = new MultiWindowTemplate(null, name, []);
      this.saveTemplate(template);
      (this.$refs.dialog as typeof ModalDialog).close();
    },

    saveTemplate(template: MultiWindowTemplate) {
      // TODO: adjust when adding multi-window support
      const layoutId = multiChartStore().currentLayoutDefinition.id;
      const chartExports = multiChartStore().charts.map(c => c.getExport());
      const windowLayout = new ChartLayout(layoutId, chartExports);
      template.windows = [windowLayout];
      userSettingStore().saveChartTemplate(template);
    },

    loadTemplate(template: MultiWindowTemplate) {
      // TODO: adjust when adding multi-window support
      const layoutCopy = new ChartLayoutConverter().clone(template.windows[0]);
      let linkedCharts = multiChartStore().getLinkedCharts();
      if (linkedCharts.length === 0) {
        linkedCharts = multiChartStore().charts as Chart[];
      }
      if (linkedCharts.length > 0) {
        const instrumentData = linkedCharts[0].getMainInstrumentData();
        layoutCopy.setLinkedInstrument(instrumentData);
      }
      multiChartStore().applyLayout(layoutCopy);
    },

    deleteTemplate(template: MultiWindowTemplate) {
      userSettingStore().deleteChartTemplate(template);
    },

    openSaveDialog() {
      (this.$refs.dialog as typeof ModalDialog).show();
      (this.$refs.templatesDropdown as typeof ToolbarDropdown).hide();
    }
  }
});
</script>

<style scoped>
</style>

import { ChartColor, GradientColor, RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { PlotColorOptionName } from "@/anfin-chart/options/plot-color-options";
import type { ChartColorOptionName } from "@/anfin-chart/options/chart-color-options";
import type { AnalysisToolColorOptionName } from "@/anfin-chart/options/analysis-tool-options";
import type { AlertColorOptionName } from "@/anfin-chart/options/alert-options";

export class Theme {

  constructor(public readonly id: number,
              public readonly name: string,
              public readonly className: string,
              public readonly chartStyle: ChartStyle,
              public readonly logo: LogoStyle) {
  }
}

export enum LogoStyle {
  Transparent = "transparent",
  Dark = "dark",
  Light = "light",
  Green = "green"
}

export type ChartStyleOptionName = ChartColorOptionName | PlotColorOptionName | AnalysisToolColorOptionName |
  AlertColorOptionName;

export interface ChartStyle extends Record<ChartStyleOptionName, ChartColor> {
}

export class DarkChartStyle implements ChartStyle {

  public readonly background = new RGBAColor(27, 28, 31);
  public readonly axisLabel = new RGBAColor(242, 242, 242);
  public readonly debugInfo = new RGBAColor(242, 242, 242);
  public readonly captionPrimary = new RGBAColor(242, 242, 242);
  public readonly captionSecondary = new RGBAColor(200, 200, 200);
  public readonly priceDiffPositive = new RGBAColor(20, 240, 20);
  public readonly priceDiffNegative = new RGBAColor(255, 40, 40);
  public readonly helperLine = new RGBAColor(221, 221, 221, 0.08);
  public readonly axisLine = new RGBAColor(221, 221, 221, 0.4);
  public readonly crosshair = new RGBAColor(144, 144, 144, 0.6);
  public readonly priceMarkerMouse = new RGBAColor(245, 211, 74, 0.95);
  public readonly priceMarkerMain = new RGBAColor(245, 211, 74, 0.95);
  public readonly priceMarkerPreTrading = new RGBAColor(191, 80, 20);
  public readonly priceMarkerText = new RGBAColor(24, 24, 24);
  public readonly timeMarker = new RGBAColor(245, 211, 74, 0.95);
  public readonly timeMarkerText = new RGBAColor(24, 24, 24);
  public readonly captionButtonBackground = new RGBAColor(111, 111, 111);
  public readonly captionButtonText = new RGBAColor(255, 255, 255);
  public readonly subChartSeparator = new RGBAColor(160, 160, 160);
  public readonly subChartSeparatorHover = new RGBAColor(245, 211, 74, 0.85);
  public readonly toolPointBorder = new RGBAColor(238, 238, 238);
  public readonly toolPointFill = new RGBAColor(44, 44, 44);
  public readonly actionButton = new RGBAColor(221, 221, 221);
  public readonly actionButtonActive = new RGBAColor(221, 221, 221, 0.3);
  public readonly hintText = new RGBAColor(255, 255, 255);
  public readonly hintBackground = new RGBAColor(35, 35, 35);
  public readonly toolText = new RGBAColor(235, 235, 235);
  public readonly globalToolIcon = new RGBAColor(155, 155, 113);
  public readonly sharedTimeframesText = new RGBAColor(242, 242, 242);

  public readonly line = new RGBAColor(234, 234, 234);
  public readonly mountainLine = new RGBAColor(234, 234, 234);
  public readonly mountainArea: ChartColor;
  public readonly barAscending = new RGBAColor(242, 242, 242);
  public readonly barDescending = new RGBAColor(212, 49, 63);
  public readonly barBorder = new RGBAColor(242, 242, 242);
  public readonly candleAscending = new RGBAColor(242, 242, 242);
  public readonly candleDescending = new RGBAColor(212, 49, 63);
  public readonly candleWick = new RGBAColor(242, 242, 242);
  public readonly candleBorder = new RGBAColor(242, 242, 242);

  public readonly autoChannelAscendingLine = new RGBAColor(12, 167, 250);
  public readonly autoChannelAscendingArea = new RGBAColor(45, 128, 32, 0.1);
  public readonly autoChannelDescendingLine = new RGBAColor(12, 167, 250);
  public readonly autoChannelDescendingArea = new RGBAColor(128, 23, 56, 0.1);
  public readonly autoChannelTargetLine = new RGBAColor(30, 60, 220);
  public readonly autoChannelBrokenAtLine = new RGBAColor(200, 30, 45, 0.5);
  public readonly autoDoubleExtremeTop = new RGBAColor(128, 23, 56);
  public readonly autoDoubleExtremeBottom = new RGBAColor(45, 128, 32);
  public readonly autoFibonacciBasic = new RGBAColor(220, 220, 220, 0.7);
  public readonly autoFibonacciAscending = new RGBAColor(97, 207, 49, 0.7);
  public readonly autoFibonacciDescending = new RGBAColor(255, 0, 61, 0.7);
  public readonly autoHeadAndShouldersNeck = new RGBAColor(220, 30, 30);
  public readonly autoHeadAndShouldersText = new RGBAColor(220, 30, 30);
  public readonly autoExtremeHigh = new RGBAColor(35, 210, 35);
  public readonly autoExtremeLow = new RGBAColor(255, 0, 0);
  public readonly autoHorizontalSupport = new RGBAColor(126, 211, 33);
  public readonly autoHorizontalResistance = new RGBAColor(255, 255, 0);
  public readonly autoPriceGap = new RGBAColor(125, 125, 125, 0.05);
  public readonly autoTrendLineAscending = new RGBAColor(97, 207, 49);
  public readonly autoTrendLineDescending = new RGBAColor(255, 0, 61);
  public readonly autoTrendLineText = new RGBAColor(255, 255, 255);
  public readonly autoTrendLineBox = RGBAColor.transparent;
  public readonly autoTrendLineMarker = new RGBAColor(255, 255, 255);

  public readonly alertToolUpperLine = new RGBAColor(0, 255, 0);
  public readonly alertToolLowerLine = new RGBAColor(255, 0, 0);
  public readonly alertToolTriggeredLine = new RGBAColor(95, 159, 255);
  public readonly alertToolInactiveLine = new RGBAColor(170, 170, 170);
  public readonly alertToolActiveArea = new RGBAColor(175, 175, 5, 0.05);
  public readonly alertToolTriggeredArea = new RGBAColor(95, 159, 255, 0.075);
  public readonly alertToolInactiveArea = new RGBAColor(170, 170, 170, 0.075);

  constructor() {
    const mountainTop = new RGBAColor(139, 204, 215);
    const mountainBottom = new RGBAColor(127, 164, 201);
    this.mountainArea = new GradientColor()
      .addStop(0, mountainTop)
      .addStop(1, mountainBottom);
  }
}

export class LightChartStyle implements ChartStyle {

  public readonly background = new RGBAColor(255, 255, 255);
  public readonly axisLabel = new RGBAColor(96, 96, 96);
  public readonly debugInfo = new RGBAColor(96, 96, 96);
  public readonly captionPrimary = new RGBAColor(63, 63, 63);
  public readonly captionSecondary = new RGBAColor(128, 128, 128);
  public readonly priceDiffPositive = new RGBAColor(0, 200, 0);
  public readonly priceDiffNegative = new RGBAColor(200, 0, 0);
  public readonly helperLine = new RGBAColor(221, 221, 221, 0.2);
  public readonly axisLine = new RGBAColor(96, 96, 96, 0.4);
  public readonly crosshair = new RGBAColor(144, 144, 144, 0.5);
  public readonly priceMarkerMouse = new RGBAColor(24, 115, 59);
  public readonly priceMarkerMain = new RGBAColor(24, 115, 59);
  public readonly priceMarkerPreTrading = new RGBAColor(191, 80, 20);
  public readonly priceMarkerText = new RGBAColor(240, 240, 240);
  public readonly timeMarker = new RGBAColor(24, 115, 59);
  public readonly timeMarkerText = new RGBAColor(240, 240, 240);
  public readonly captionButtonBackground = new RGBAColor(150, 150, 150);
  public readonly captionButtonText = new RGBAColor(255, 255, 255);
  public readonly subChartSeparator = new RGBAColor(96, 96, 96);
  public readonly subChartSeparatorHover = new RGBAColor(245, 211, 74, 1.0);
  public readonly toolPointBorder = new RGBAColor(238, 238, 238);
  public readonly toolPointFill = new RGBAColor(44, 44, 44);
  public readonly actionButton = new RGBAColor(118, 118, 118);
  public readonly actionButtonActive = new RGBAColor(118, 118, 118, 0.2);
  public readonly hintText = new RGBAColor(40, 40, 40);
  public readonly hintBackground = new RGBAColor(240, 240, 240);
  public readonly toolText = new RGBAColor(40, 40, 40);
  public readonly globalToolIcon = new RGBAColor(155, 155, 113);
  public readonly sharedTimeframesText = new RGBAColor(63, 63, 63);

  public readonly line = new RGBAColor(242, 242, 242);
  public readonly mountainLine = new RGBAColor(242, 242, 242);
  public readonly mountainArea: ChartColor;
  public readonly barAscending = new RGBAColor(255, 255, 255);
  public readonly barDescending = new RGBAColor(255, 10, 10);
  public readonly barBorder = new RGBAColor(43, 43, 43);
  public readonly candleAscending = new RGBAColor(255, 255, 255);
  public readonly candleDescending = new RGBAColor(255, 10, 10);
  public readonly candleWick = new RGBAColor(43, 43, 43);
  public readonly candleBorder = new RGBAColor(43, 43, 43);

  public readonly autoChannelAscendingLine = new RGBAColor(130, 73, 165);
  public readonly autoChannelAscendingArea = new RGBAColor(45, 128, 32, 0.1);
  public readonly autoChannelDescendingLine = new RGBAColor(130, 73, 165);
  public readonly autoChannelDescendingArea = new RGBAColor(128, 23, 56, 0.1);
  public readonly autoChannelTargetLine = new RGBAColor(30, 60, 220);
  public readonly autoChannelBrokenAtLine = new RGBAColor(200, 30, 45, 0.5);
  public readonly autoDoubleExtremeTop = new RGBAColor(128, 23, 56);
  public readonly autoDoubleExtremeBottom = new RGBAColor(45, 128, 32);
  public readonly autoFibonacciBasic = new RGBAColor(74, 74, 74, 0.7);
  public readonly autoFibonacciAscending = new RGBAColor(74, 74, 74);
  public readonly autoFibonacciDescending = new RGBAColor(255, 0, 61, 0.7);
  public readonly autoHeadAndShouldersNeck = new RGBAColor(220, 30, 30);
  public readonly autoHeadAndShouldersText = new RGBAColor(220, 30, 30);
  public readonly autoExtremeHigh = new RGBAColor(35, 210, 35);
  public readonly autoExtremeLow = new RGBAColor(255, 0, 0);
  public readonly autoHorizontalSupport = new RGBAColor(74, 144, 226);
  public readonly autoHorizontalResistance = new RGBAColor(208, 2, 27);
  public readonly autoPriceGap = new RGBAColor(125, 125, 125, 0.05);
  public readonly autoTrendLineAscending = new RGBAColor(97, 207, 49);
  public readonly autoTrendLineDescending = new RGBAColor(255, 0, 61);
  public readonly autoTrendLineText = new RGBAColor(255, 255, 255);
  public readonly autoTrendLineBox = RGBAColor.transparent;
  public readonly autoTrendLineMarker = new RGBAColor(255, 255, 255);

  public readonly alertToolUpperLine = new RGBAColor(0, 255, 0);
  public readonly alertToolLowerLine = new RGBAColor(255, 0, 0);
  public readonly alertToolTriggeredLine = new RGBAColor(19, 101, 225);
  public readonly alertToolInactiveLine = new RGBAColor(170, 170, 170);
  public readonly alertToolActiveArea = new RGBAColor(220, 200, 5, 0.07);
  public readonly alertToolTriggeredArea = new RGBAColor(19, 101, 255, 0.09);
  public readonly alertToolInactiveArea = new RGBAColor(170, 170, 170, 0.25);

  constructor() {
    const mountainTop = new RGBAColor(139, 204, 215);
    const mountainBottom = new RGBAColor(127, 164, 201);
    this.mountainArea = new GradientColor()
      .addStop(0, mountainTop)
      .addStop(1, mountainBottom);
  }
}

export const lightTheme = new Theme(0, "Light", "anfin-light-mode", new LightChartStyle(), LogoStyle.Dark);
export const darkTheme = new Theme(1, "Dark", "anfin-dark-mode", new DarkChartStyle(), LogoStyle.Dark);
export const neutralDarkTheme = new Theme(2, "Neutral-Dark", "neutral-dark-mode", new DarkChartStyle(), LogoStyle.Dark);
export const neutralLightTheme = new Theme(3, "Neutral-Light", "neutral-light-mode", new LightChartStyle(), LogoStyle.Light);
export const themes = [lightTheme, darkTheme, neutralLightTheme, neutralDarkTheme];

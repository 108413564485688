import { AnalysisToolDefinition, AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";

export class AutoExtreme extends AnalysisToolDefinition {

  constructor(id: string,
              instrument: Instrument,
              timeframe: Timeframe,
              public readonly time: number,
              public readonly price: number,
              public readonly isHigh: boolean,
              public readonly strength: number,
              public readonly description: string) {
    super(id, AnalysisType.Extreme, instrument, timeframe, time);
  }
}

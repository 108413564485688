import { RestApiController } from "@/api/rest-api-controller";
import type { FavoriteList } from "@/api/messages/favorite";

export class FavoritesController extends RestApiController {

  private static instance: FavoritesController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new FavoritesController();
    }
    return this.instance;
  }

  public async requestFavorites() {
    const response = await this.sendGetRequest<FavoriteList>("favorites");
    return response.favorites;
  }

  public async saveFavorites(data: Set<string>) {
    const favs: FavoriteList = { favorites: [] };
    let index = 1;
    for (const key of data) {
      favs.favorites.push({ name: key, sortIndex: index++ });
    }
    return this.sendPostRequest<number>("favorites", favs);
  }

  protected override getBaseUrl() {
    return this.environment.configurationUrl;
  }
}

<template>
  <div class="loading-spinner">
    <div class="loading-spinner-1"></div>
    <div class="loading-spinner-2"></div>
    <div class="loading-spinner-3"></div>
    <div class="loading-spinner-4"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSpinner",

  props: {
    size: {
      type: Number,
      required: true
    }
  },

  expose: [],

  computed: {
    baseSize() {
      return this.size + "px";
    },

    outerSize() {
      return this.size * 10 + "px";
    },

    innerSize() {
      return this.size * 8 + "px";
    }
  }
});
</script>

<style scoped>
.loading-spinner {
  display: inline-block;
  position: relative;
  width: v-bind(outerSize);
  height: v-bind(outerSize);
}

.loading-spinner > * {
  display: block;
  position: absolute;
  width: v-bind(innerSize);
  height: v-bind(innerSize);
  margin: v-bind(baseSize);
  border-style: solid;
  border-width: v-bind(baseSize);
  border-radius: 50%;
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--interactive-primary) transparent transparent transparent;
}

.loading-spinner-1 {
  animation-delay: -0.45s;
}

.loading-spinner-2 {
  animation-delay: -0.3s;
}

.loading-spinner-3 {
  animation-delay: -0.15s;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

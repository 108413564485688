import { WebSocketMessage } from "@/api/web-socket-api-controller";
import { ChartWebSocketController } from "@/api/chart-web-socket-controller";
import { MarketDataWebSocketController } from "@/api/market-data-web-socket-controller";

export class ChartServerInterface {

  public readonly apiWebSocket: ChartWebSocketController;

  constructor(public readonly owner: any) {
    this.apiWebSocket = new ChartWebSocketController(this);
  }

  public handleUserApiLogin(loginResult: any) {
    this.owner.handleUserApiLogin(loginResult);
  }

  public setToken(token: string) {
    this.apiWebSocket.setToken(token);
    MarketDataWebSocketController.getInstance().setToken(token);
  }

  public saveLastView(symbol: string, timeframe: string) {
    setTimeout(() => {
      const data = {
        mode: "saveLastView",
        symbol: symbol,
        tf: timeframe
      };
      this.apiWebSocket.sendMessage(new WebSocketMessage("chart_cfg", data));
    }, 500);
  }

  public requestLastChartView() {
    this.apiWebSocket.sendMessage(new WebSocketMessage("getLastView", {}));
  }
}

import { ChartAction, ChartActionType } from "@/anfin-chart/actions/actions";
import { UserToolAction } from "@/anfin-chart/actions/user-tool-action";
import { ZoomAction } from "@/anfin-chart/actions/zoom-action";
import { AutoChannelTool } from "@/anfin-chart/tools/analysis-tools/auto-channel";
import { AutoDoubleExtremeTool } from "@/anfin-chart/tools/analysis-tools/auto-double-extreme";
import { AutoFibonacciTool } from "@/anfin-chart/tools/analysis-tools/auto-fibonacci";
import { AutoHeadAndShouldersTool } from "@/anfin-chart/tools/analysis-tools/auto-head-and-shoulders";
import { AutoHorizontalTool } from "@/anfin-chart/tools/analysis-tools/auto-horizontal";
import { AutoExtremeTool } from "@/anfin-chart/tools/analysis-tools/auto-extreme";
import { AutoPriceGapTool } from "@/anfin-chart/tools/analysis-tools/auto-price-gap";
import { AutoTrendLineTool } from "@/anfin-chart/tools/analysis-tools/auto-trend-line";
import { CoreLayerArea } from "@/anfin-chart/area/core-layer-area";
import { Crosshair } from "@/anfin-chart/area/crosshair";
import { DebugInfo } from "@/anfin-chart/area/debug-info";
import { DrawingAreaWrapper } from "@/anfin-chart/area/drawing-area-wrapper";
import { ChartHintArea } from "@/anfin-chart/area/hint-area";
import { MousePriceMarker } from "@/anfin-chart/area/mouse-price-marker";
import { MouseTimeMarker } from "@/anfin-chart/area/mouse-time-marker";
import { OverlayLayerArea } from "@/anfin-chart/area/overlay-layer-area";
import { PriceAxisWrapper } from "@/anfin-chart/area/price-axis-wrapper";
import { TimeAxis } from "@/anfin-chart/area/time-axis";
import { ToolLayerArea } from "@/anfin-chart/area/tool-layer-area";
import { ZoomWindowOverlay } from "@/anfin-chart/area/zoom-window-overlay";
import type { ChartCallbacks } from "@/anfin-chart/callbacks";
import { ChartCallbackRunner } from "@/anfin-chart/callbacks";
import { ChartLayer } from "@/anfin-chart/chart-layer";
import { FontInfo } from "@/anfin-chart/draw/chart-drawer";
import { Drawable } from "@/anfin-chart/drawable";
import { ChartError } from "@/anfin-chart/error";
import type { ExchangeInfo } from "@/anfin-chart/exchange";
import { ChartExport, InstrumentExport, SubChartExport } from "@/anfin-chart/export";
import { Point } from "@/anfin-chart/geometry";
import type { ChartHint } from "@/anfin-chart/hints/chart-hint";
import { FibonacciHint } from "@/anfin-chart/hints/fibonacci-hint";
import { IconStore, SVG } from "@/anfin-chart/icon-store";
import { Indicator } from "@/anfin-chart/indicator";
import type { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import { InteractionHandler } from "@/anfin-chart/interaction-handler";
import type { ClickData, DragData, KeyData, ScrollData } from "@/anfin-chart/interactions";
import { MouseData } from "@/anfin-chart/mouse-data";
import type { ChartOptionManager } from "@/anfin-chart/options/option-manager";
import { applyOptions, type ChartObject, getOptionDefinitions } from "@/anfin-chart/options/option";
import { diffArray } from "@/anfin-chart/pipes";
import { OHLCItem } from "@/anfin-chart/plot";
import { UserToolRegistry } from "@/anfin-chart/registry";
import { ClickHandler, DoubleClickHandler, DragHandler, HoverHandler, ScrollHandler } from "@/anfin-chart/selection";
import { StyleOptions } from "@/anfin-chart/options/style-options";
import { SubChart } from "@/anfin-chart/sub-chart";
import { TradingHoursProvider } from "@/anfin-chart/trading-times";
import { padLeft } from "@/anfin-chart/utils";
import type { TickData } from "@/api/messages/tick-update";
import type { AnalysisToolDefinition } from "@/api/models/analysis/analysis-tool-definition";
import { AutoChannel } from "@/api/models/analysis/auto-channel";
import { AutoDoubleExtreme } from "@/api/models/analysis/auto-double-extreme";
import { AutoFibonacci } from "@/api/models/analysis/auto-fibonacci";
import { AutoHeadAndShoulders } from "@/api/models/analysis/auto-head-and-shoulders";
import { AutoHorizontal } from "@/api/models/analysis/auto-horizontal";
import { AutoExtreme } from "@/api/models/analysis/auto-extreme";
import { AutoPriceGap } from "@/api/models/analysis/auto-price-gap";
import { AutoTrendLine } from "@/api/models/analysis/auto-trend-line";
import { ChartView } from "@/api/models/user-settings/chart";
import { userRightStore } from "@/stores/user-right-store";
import { BehaviorSubject, distinctUntilChanged, map, startWith, Subject, switchMap } from "rxjs";
import { Instrument, InstrumentData, type InstrumentTimeframe } from "./instrument";
import { Timeframe, TimeUnit } from "./time/timeframe";
import { ChartIndicatorConverter, IndicatorTemplate } from "@/anfin-chart/converter/indicator-converter";
import { ContextMenuData } from "@/stores/ui-state-store";
import type { Alert } from "@/api/models/alert";
import { AlertAutoToolDefinition, AlertUserToolDefinition } from "@/api/models/alert";
import { AnalysisTool } from "@/anfin-chart/tools/analysis-tool";
import { UserTool } from "@/anfin-chart/tools/user-tool";
import {
  AlertDefinitionData,
  AlertIconElement,
  AlertLineElement,
  AlertTextBox,
  AlertTool
} from "@/anfin-chart/tools/alert-tool";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { matchesToolSynchronization } from "@/anfin-chart/tool-synchronization";
import type { ToolAlertHook } from "@/anfin-chart/tools/alert-hook";
import type { ChartTool } from "@/anfin-chart/tools/chart-tool";
import { SharedTimeframeArea } from "@/anfin-chart/area/shared-timeframe-area";

export class Chart {

  public readonly styleOptions = new StyleOptions();

  public readonly enableLogging = true;
  public readonly enableDebug = false;

  public readonly coreLayer: ChartLayer;
  public readonly toolLayer: ChartLayer;
  public readonly overlayLayer: ChartLayer;
  public readonly layers: ChartLayer[] = [];
  public readonly interactionHandler: InteractionHandler;
  public isDestroyed = false;

  public mouseData = new MouseData(this);
  public readonly iconStore = new IconStore();

  public coreLayerArea: CoreLayerArea;
  public toolLayerArea: ToolLayerArea;
  public overlayLayerArea: OverlayLayerArea;
  public timeAxis: TimeAxis;
  public priceAxisWrapper: PriceAxisWrapper;
  public drawingAreaWrapper: DrawingAreaWrapper;
  public crosshair: Crosshair;
  public mousePriceMarker: MousePriceMarker;
  public mouseTimeMarker: MouseTimeMarker;
  public debugInfo: DebugInfo;
  public hintArea: ChartHintArea;
  public zoomWindowOverlay: ZoomWindowOverlay;
  public sharedTimeframeArea: SharedTimeframeArea;

  public primarySubChart: SubChart;

  public exchangeInfos = new Map<string, ExchangeInfo>();
  public tradingHoursProvider = new TradingHoursProvider();
  public sharedToolTimeframes = new Map<string, Timeframe[]>();
  public readonly callbacks: ChartCallbackRunner;

  public dragHandler = new DragHandler(this);
  public hoverHandler = new HoverHandler(this);
  public clickHandler = new ClickHandler(this);
  public doubleClickHandler = new DoubleClickHandler(this);
  public scrollHandler = new ScrollHandler(this);

  public fibonacciHint: FibonacciHint;
  public showFibonacciHint = false;

  public action: ChartAction | null = null;
  public readonly pressedKeys = new Set<string>();

  private readonly primarySubChartObservable = new Subject<SubChart>();
  private readonly subCharts = new BehaviorSubject<SubChart[]>([]);
  private readonly instrumentDatas = new BehaviorSubject<InstrumentData[]>([]);
  private editedObject: ChartObject | null = null;
  private readonly analysisToolMap = new Map<string, AnalysisTool>();
  private readonly userToolMap = new Map<number, UserTool>();
  private readonly pendingUserTools = new Set<UserTool>();
  private readonly alertToolMap = new Map<string, AlertTool[]>();

  private readonly pixelRatio = new BehaviorSubject(window.devicePixelRatio);
  private readonly isLinkedSplitChart = new BehaviorSubject(true);
  private readonly isLinkButtonEnabled = new BehaviorSubject(false);
  private readonly isSynchronized = new BehaviorSubject(false);

  constructor(public readonly id: number,
              public readonly container: HTMLElement,
              callbacks: ChartCallbacks,
              public readonly optionManager: ChartOptionManager) {
    this.log("Initialize chart");
    this.callbacks = new ChartCallbackRunner(callbacks);
    this.setExternalUpdate(true);
    this.coreLayer = this.createLayer("Core");
    this.toolLayer = this.createLayer("Tool");
    this.overlayLayer = this.createLayer("Overlay");

    this.coreLayerArea = new CoreLayerArea(this.coreLayer);
    this.toolLayerArea = new ToolLayerArea(this.toolLayer);
    this.overlayLayerArea = new OverlayLayerArea(this.overlayLayer);
    this.timeAxis = new TimeAxis(this.coreLayer);
    this.priceAxisWrapper = new PriceAxisWrapper(this.coreLayer);
    this.drawingAreaWrapper = new DrawingAreaWrapper(this.coreLayer);
    this.crosshair = new Crosshair(this.overlayLayer);
    this.mousePriceMarker = new MousePriceMarker(this.overlayLayer);
    this.mouseTimeMarker = new MouseTimeMarker(this.overlayLayer);
    this.debugInfo = new DebugInfo(this.overlayLayer);
    this.hintArea = new ChartHintArea(this.overlayLayer);
    this.zoomWindowOverlay = new ZoomWindowOverlay(this.overlayLayer);
    this.sharedTimeframeArea = new SharedTimeframeArea(this.toolLayer);

    this.getSubChartsObservable().subscribe(subCharts => {
      this.redistributeHeight();
      for (let i = 0; i < subCharts.length; i++) {
        const subChart = subCharts[i];
        subChart.setIndex(i);
      }
      this.coreLayerArea.setStackItems(subCharts.map(s => s.mainArea));
      this.coreLayer.requireDraw();
    });
    this.primarySubChart = new SubChart(this);
    this.setSubCharts([this.primarySubChart]);
    this.getIsLinkedSplitChartObservable().subscribe(() => {
      this.callbacks.saveExport();
    });

    this.coreLayerArea.initializeEvents();
    this.toolLayerArea.initializeEvents();
    this.overlayLayerArea.initializeEvents();
    this.timeAxis.initializeEvents();
    this.priceAxisWrapper.initializeEvents();
    this.drawingAreaWrapper.initializeEvents();
    this.crosshair.initializeEvents();
    this.mousePriceMarker.initializeEvents();
    this.mouseTimeMarker.initializeEvents();
    this.debugInfo.initializeEvents();
    this.hintArea.initializeEvents();
    this.zoomWindowOverlay.initializeEvents();
    this.sharedTimeframeArea.initializeEvents();

    this.mouseData.initializeEvents();

    const instrument = new Instrument("", "");
    const timeframe = new Timeframe(TimeUnit.Minute, 5);
    this.setInstrumentDatas([{ instrument, timeframe }]);
    this.setUpInstrumentDataHandling();
    this.setUpDeviceRatio();

    this.interactionHandler = new InteractionHandler(this);

    this.callbacks.requestExchangeInfos();
    this.fibonacciHint = new FibonacciHint(this);
    this.callbacks.onActionChange(ChartActionType.None);

    this.setExternalUpdate(false);
    window.setTimeout(() => this.initialize(), 0);
  }

  public onDestroy() {
    this.isDestroyed = true;
  }

  public getSubChartsObservable() {
    return this.subCharts.pipe(distinctUntilChanged(), startWith(this.subCharts.value));
  }

  public getSubCharts() {
    return this.subCharts.value;
  }

  public createSubChart() {
    const subCharts = this.getSubCharts().slice();
    const newSubChart = new SubChart(this);
    subCharts.push(newSubChart);
    this.setSubCharts(subCharts);
    return newSubChart;
  }

  public setSubCharts(subCharts: SubChart[]) {
    this.subCharts.next(subCharts);
  }

  public removeSubChart(subChart: SubChart) {
    const subCharts = this.getSubCharts().slice();
    const index = subChart.getIndex();
    const removedSubChart = subCharts[index];
    removedSubChart.mainArea.onDelete();
    subCharts.splice(index, 1);
    this.setSubCharts(subCharts);
  }

  public getPrimarySubChartObservable() {
    return this.primarySubChartObservable.pipe(distinctUntilChanged(), startWith(this.primarySubChart));
  }

  public setPrimarySubChart(subChart: SubChart) {
    this.primarySubChart = subChart;
    this.primarySubChartObservable.next(subChart);
  }

  public resize() {
    const width = this.container.offsetWidth;
    const height = this.container.offsetHeight;
    if (width === 0 || height === 0) {
      return;
    }
    for (const layer of this.layers) {
      layer.resize(width, height);
    }
    this.coreLayerArea.resize();
    this.redistributeHeight();
  }

  public redistributeHeight() {
    const subCharts = this.getSubCharts();
    if (subCharts.length === 0) {
      return;
    }
    const totalHeight = this.getTotalHeight();
    if (subCharts.length === 1) {
      subCharts[0].setHeight(totalHeight);
      return;
    }
    const actualHeight = subCharts.reduce((previous, subChart) => previous + subChart.getHeight(), 0);
    const resizePercentage = actualHeight > 0 ? totalHeight / actualHeight : 0;
    let minHeight = this.styleOptions.minSubChartHeight.getValue();
    if (totalHeight < minHeight * subCharts.length) {
      minHeight = 0;
    }
    let resizeHeight = totalHeight;
    let newHeightSum = 0;
    for (const subChart of subCharts) {
      const height = subChart.getHeight();
      if (subChart.getIsExpanded()) {
        const newHeight = Math.max(minHeight, Math.trunc(height * resizePercentage));
        subChart.setHeight(newHeight);
        newHeightSum += newHeight;
      } else {
        resizeHeight -= height;
      }
    }
    const remainingDifference = resizeHeight - newHeightSum;
    const remainingSubChart = this.primarySubChart.getIsExpanded() ? this.primarySubChart : subCharts.find(s => s.getIsExpanded()) ?? subCharts[0];
    remainingSubChart.setHeight(remainingSubChart.getHeight() + remainingDifference);
  }

  public getIsLinkedSplitChart() {
    return this.isLinkedSplitChart.value;
  }

  public getIsLinkedSplitChartObservable() {
    return this.isLinkedSplitChart.pipe(distinctUntilChanged(), startWith(this.isLinkedSplitChart.value));
  }

  public setIsLinkedSplitChart(isLinked: boolean) {
    this.isLinkedSplitChart.next(isLinked);
  }

  public getLinkButtonEnabledObservable() {
    return this.isLinkButtonEnabled.pipe(distinctUntilChanged(), startWith(this.isLinkButtonEnabled.value));
  }

  public getLinkButtonEnabled() {
    return this.isLinkButtonEnabled.value;
  }

  public setLinkButtonEnabled(isEnabled: boolean) {
    this.isLinkButtonEnabled.next(isEnabled);
  }

  public getInstrumentDatasObservable() {
    return this.instrumentDatas.pipe(distinctUntilChanged(), startWith(this.instrumentDatas.value));
  }

  public getInstrumentDatas() {
    return this.instrumentDatas.value.slice();
  }

  public getInstrumentData(instrument: Instrument, timeframe: Timeframe) {
    const instrumentTimeframe = { instrument, timeframe };
    return this.getInstrumentDatas().find(d => d.matches(instrumentTimeframe)) ?? null;
  }

  public getMainInstrumentDataObservable() {
    return this.instrumentDatas.pipe(
      map(d => d[0]),
      distinctUntilChanged((last, current) => last != null && current.matches(last)),
      startWith(this.instrumentDatas.value[0])
    );
  }

  public getMainInstrumentData() {
    return this.instrumentDatas.value[0] ?? null;
  }

  public setInitialInstrument(instrument: Instrument, timeframe: Timeframe) {
    this.setInstrumentDatas([{ instrument, timeframe }]);
  }

  public addInstrument(instrument: Instrument, keepExisting: boolean) {
    this.log("Set instrument");
    const instrumentDatas = this.getInstrumentDatas();
    const instrumentTimeframe = { instrument, timeframe: instrumentDatas[0].timeframe };
    if (keepExisting) {
      this.setInstrumentDatas([...instrumentDatas, instrumentTimeframe]);
    } else {
      this.saveTimeRange();
      this.setInstrumentDatas([instrumentTimeframe]);
    }
    this.callbacks.onInstrumentChange(instrument);
    if (!keepExisting) {
      this.loadTimeRange();
    }
  }

  public removeInstrument(instrumentData: InstrumentData) {
    const instrumentDatas = this.getInstrumentDatas();
    const index = instrumentDatas.indexOf(instrumentData);
    if (index !== -1) {
      instrumentDatas.splice(index, 1);
      this.setInstrumentDatas(instrumentDatas);
    }
  }

  public setTimeframe(timeframe: Timeframe) {
    this.log("Set timeframe");
    const mainInstrumentData = this.getMainInstrumentData();
    const currentTimeframe = mainInstrumentData.timeframe;
    if (currentTimeframe.unit === timeframe.unit && currentTimeframe.value === timeframe.value) {
      return;
    }
    const instrumentDatas = this.getInstrumentDatas();
    const newInstrumentDatas = [];
    for (const instrumentData of instrumentDatas) {
      newInstrumentDatas.push({ instrument: instrumentData.instrument, timeframe });
    }
    this.saveTimeRange();
    this.setInstrumentDatas(newInstrumentDatas);
    this.callbacks.onTimeframeChange(timeframe);
    this.loadTimeRange();
  }

  public setExchangeInfos(exchangeInfos: ExchangeInfo[]) {
    this.exchangeInfos.clear();
    for (const exchangeInfo of exchangeInfos) {
      this.exchangeInfos.set(exchangeInfo.name, exchangeInfo);
    }
  }

  public getExchangeInfo(exchange: string) {
    return this.exchangeInfos.get(exchange) ?? null;
  }

  public editChartObject(chartObject: ChartObject | null) {
    const previousObject = this.editedObject;
    this.editedObject = chartObject;
    this.callbacks.editChartObject(chartObject);
    if (this.editedObject instanceof UserTool) {
      this.editedObject.updateDrawables();
    }
    if (previousObject instanceof UserTool) {
      previousObject.updateDrawables();
    }
  }

  public cloneChartObject(chartObject: ChartObject) {
    let clonedObject: ChartObject | null = null;
    if (chartObject instanceof Indicator) {
      clonedObject = this.addIndicator(chartObject.type, getOptionDefinitions(chartObject));
    } else if (chartObject instanceof UserTool) {
      clonedObject = this.createUserTool(chartObject.definition.type, chartObject.getSubChart());
      const optionTemplates = getOptionDefinitions(chartObject.definition);
      for (const fixedPoint of chartObject.definition.fixedPoints) {
        clonedObject.definition.createPoint(fixedPoint.time, fixedPoint.price);
      }
      clonedObject.updateDrawables();
      applyOptions(clonedObject.definition, optionTemplates);
      clonedObject.finishInitialization();
      clonedObject.select();
    }
    if (clonedObject != null) {
      this.editChartObject(clonedObject);
    }
  }

  public removeChartObject(chartObject: ChartObject) {
    if (chartObject instanceof Indicator) {
      const indicatorData = chartObject.indicatorData;
      indicatorData?.subChart.removeIndicator(indicatorData);
    } else if (chartObject instanceof UserTool) {
      chartObject.delete();
    } else {
      throw new ChartError("Unknown chart object");
    }
    if (this.editedObject === chartObject) {
      this.editChartObject(null);
    }
  }

  public addIndicator(type: string, options: ChartOptionDefinition[] = []) {
    const template = new IndicatorTemplate(type, null, options);
    const indicator = ChartIndicatorConverter.import(template, this, null);
    if (indicator != null) {
      indicator.save();
    }
    return indicator;
  }

  public clearIndicators() {
    for (const subChart of this.getSubCharts()) {
      for (const indicatorData of subChart.getIndicatorDatas()) {
        subChart.removeIndicator(indicatorData);
      }
    }
  }

  public getUserTools() {
    return [...this.userToolMap.values(), ...this.pendingUserTools];
  }

  public getUserTool(id: number) {
    return this.userToolMap.get(id) ?? null;
  }

  public deleteUserTool(id: number) {
    const userTool = this.getUserTool(id);
    if (userTool != null) {
      this.removeChartObject(userTool);
    }
  }

  public onUserToolRemoved(tool: UserTool) {
    if (this.action instanceof UserToolAction && this.action.tool === tool) {
      this.setDefaultMode();
    }
    this.pendingUserTools.delete(tool);
    if (tool.definition.id != null) {
      this.userToolMap.delete(tool.definition.id);
    }
    this.toolLayer.requireDraw();
  }

  public clearUserTools() {
    for (const userTool of this.getUserTools()) {
      this.removeChartObject(userTool);
    }
  }

  public reloadUserTools() {
    const instrumentData = this.getMainInstrumentData();
    if (instrumentData != null) {
      this.userToolMap.clear();
      this.callbacks.loadUserTools(instrumentData.instrument, instrumentData.timeframe);
    }
  }

  public setPendingTool(type: string, options: ChartOptionDefinition[] = [], actionKey: string | null = null) {
    this.optionManager.showUserTools.setValue(true);
    const tool = this.createUserTool(type);
    this.setAction(new UserToolAction(this, actionKey ?? type, tool));
    applyOptions(tool.definition, options);
  }

  public setSharedToolTimeframes(instrument: Instrument, timeframes: Timeframe[]) {
    this.sharedToolTimeframes.set(instrument.getSymbol(), timeframes);
    this.sharedTimeframeArea.updateTimeframes();
  }

  public getAnalysisTools() {
    return Array.from(this.analysisToolMap.values());
  }

  public getAnalysisTool(id: string) {
    return this.analysisToolMap.get(id) ?? null;
  }

  public addAnalyses(instrument: Instrument, timeframe: Timeframe, analyses: AnalysisToolDefinition[]) {
    const instrumentData = this.getInstrumentData(instrument, timeframe);
    if (instrumentData == null) {
      return;
    }
    for (const analysis of analyses) {
      this.createAnalysisTool(analysis, instrumentData);
    }
    this.fibonacciHint.updateItems();
  }

  public removeAnalysisTool(tool: AnalysisTool) {
    this.analysisToolMap.delete(tool.id);
    if (tool instanceof AutoFibonacciTool) {
      this.fibonacciHint.updateItems();
    }
    this.toolLayer.requireDraw();
  }

  public getAlertTools() {
    return Array.from(this.alertToolMap.values()).flat();
  }

  public addAlert(alert: Alert) {
    if (alert.id == null) {
      return;
    }
    const alertTools = alert.rules.map(r => new AlertTool(alert, r, this.primarySubChart));
    this.alertToolMap.set(alert.id, alertTools);
    this.updateAlertTools(alert);
  }

  public removeAlert(alert: Alert) {
    if (alert.id == null) {
      return;
    }
    this.alertToolMap.delete(alert.id);
    this.updateAlertTools(alert);
    this.toolLayer.requireDraw();
  }

  public setDefaultMode() {
    this.setAction(null);
  }

  public setZoomMode() {
    this.setAction(new ZoomAction(this));
  }

  public isSecondaryActionMode() {
    return this.pressedKeys.has("Control");
  }

  public requestHistory(instrumentData: InstrumentData, beforeTime: number | null = null) {
    const instrument = instrumentData.instrument;
    const timeframe = instrumentData.timeframe;
    if (instrument == null || timeframe == null || instrument.getSymbol() === "") {
      return;
    }
    setTimeout(() => {
      const calculatedCount = Math.round(this.coreLayer.width / window.devicePixelRatio / 4);
      let count = Math.max(50, Math.min(2700, calculatedCount));
      if (beforeTime != null) {
        const startIndex = this.timeAxis.getRange().start;
        const beforeIndex = this.timeAxis.getIndexForTime(beforeTime);
        count = Math.max(count, Math.round(beforeIndex - startIndex));
      }
      console.log("Request history data " + count + " bars");
      this.callbacks.requestHistory(instrument, timeframe, count, beforeTime);
    });
  }

  public setHistoryData(instrument: Instrument, timeframe: Timeframe, items: OHLCItem[],
                        pricePrecision: number | null) {
    this.log("Set history data: " + items.length);
    const instrumentTimeframe = { instrument, timeframe };
    const matchingData = this.getInstrumentDatas().find(d => d.matches(instrumentTimeframe));
    if (matchingData == null) {
      return;
    }
    if (pricePrecision != null) {
      this.styleOptions.pricePrecision.setValue(pricePrecision);
    }
    matchingData.extend(...items);
    if (items.length > 0) {
      matchingData.checkHistoryDataRequired();
      for (const subChart of this.getSubCharts()) {
        subChart.priceAxis.updateAutoRange();
      }
    }
  }

  public updatePrice(instrument: Instrument, tickData: TickData) {
    const instrumentDatas = this.getInstrumentDatas();
    const matchingData = this.getInstrumentData(instrument, instrumentDatas[0].timeframe);
    if (matchingData == null) {
      return;
    }
    matchingData.handleTickUpdate(tickData);
  }

  public formatPrice(price: number) {
    return price.toFixed(this.styleOptions.pricePrecision.getValue());
  }

  public formatDate(date: Date) {
    const timeframe = this.getMainInstrumentData().timeframe;
    const timeUnit = timeframe.unit ?? TimeUnit.Millisecond;
    const yearTruncated = date.getFullYear() % 100;
    const yearPadded = padLeft(yearTruncated.toString(), "0", 2);
    const monthName = this.callbacks.getTranslation("month_short#" + date.getMonth());
    let text = monthName + " `" + yearPadded;
    if (timeUnit <= TimeUnit.Week) {
      const day = date.getDate();
      text = day + " " + text;
      if (timeUnit <= TimeUnit.Hour) {
        const hourPadded = padLeft(date.getHours().toString(), "0", 2);
        const minutePadded = padLeft(date.getMinutes().toString(), "0", 2);
        text += " " + hourPadded + ":" + minutePadded;
      }
    }
    return text;
  }

  public getFontInfo(size = this.styleOptions.standardFontSize.getValue()) {
    return new FontInfo(this.styleOptions.fontName.getValue(), size);
  }

  public onStyleChange() {
    for (const tool of this.getAnalysisTools()) {
      tool.updateDrawables();
    }
  }

  public getPixelRatioObservable() {
    return this.pixelRatio.pipe(
      distinctUntilChanged(),
      startWith(this.pixelRatio.value)
    );
  }

  public addIcon(...icons: SVG[]) {
    for (const icon of icons) {
      this.iconStore.addIcon(icon);
    }
  }

  public handleKeyDown(data: KeyData): boolean {
    let handled = false;
    this.pressedKeys.add(data.keyName);
    switch (data.keyName) {
      case "Backspace":
      case "Delete":
        if (this.editedObject != null) {
          this.removeChartObject(this.editedObject);
          handled = true;
        }
        break;
      case "Escape":
        if (this.action?.onCancel != null) {
          this.action.onCancel();
        }
        this.setDefaultMode();
        handled = true;
        break;
      default:
        break;
    }
    return handled;
  }

  public handleKeyRelease(data: KeyData) {
    this.pressedKeys.delete(data.keyName);
  }

  public handleMouseMove(data: ClickData) {
    if (this.action?.onMouseMove?.(data)) {
      return;
    }
    this.hoverHandler.onHover(data);
  }

  public handleMouseLeave() {
    this.mouseData.setMouseOver(false);
    this.hoverHandler.unselect();
    this.callbacks.onMouseLeave();
  }

  public handleMouseDown(data: ClickData) {
    if (this.action?.onMouseDown?.(data)) {
      return;
    }
    this.dragHandler.onDragStart(data);
  }

  public handleDrag(data: DragData) {
    if (this.action?.onDragMove?.(data)) {
      return;
    }
    this.dragHandler.onDragMove(data);
  }

  public handleMouseUp(data: ClickData) {
    if (this.mouseData.getDragging()) {
      this.dragHandler.onDragEnd(data);
    }
    if (this.action?.onMouseUp?.(data)) {
      // add "return;" if a default action is added below
    }
    // no default action
  }

  public handleClick(data: ClickData) {
    if (this.action?.onClick?.(data)) {
      return;
    }
    this.clickHandler.onClick(data);
  }

  public handleRightClick(data: ClickData) {
    if (this.action == null) {
      return false;
    }
    this.setDefaultMode();
    return true;
  }

  public handleDoubleClick(data: ClickData) {
    this.doubleClickHandler.onDoubleClick(data);
  }

  public handleScroll(data: ScrollData) {
    this.scrollHandler.onScroll(data);
  }

  public setExternalUpdate(isExternalUpdate: boolean) {
    this.callbacks.isExternalUpdate = isExternalUpdate;
  }

  public getExport() {
    const subCharts = this.exportSubCharts();
    return new ChartExport(this.getIsLinkedSplitChart(), subCharts);
  }

  public setExport(chartExport: ChartExport) {
    this.isLinkedSplitChart.next(chartExport.isLinkedSplitChart);
    this.importSubCharts(chartExport.subCharts);

    const instruments = [];
    for (const subChartExport of chartExport.subCharts) {
      if (subChartExport.instruments.length > 0) {
        instruments.push(...subChartExport.instruments);
      }
    }
    if (instruments.length > 0) {
      this.importInstruments(instruments);
    }
    this.reloadUserTools();
  }

  public exportInstruments() {
    const instruments = [];
    for (const instrumentData of this.getInstrumentDatas()) {
      instruments.push(new InstrumentExport(instrumentData.instrument, instrumentData.timeframe));
    }
    return instruments;
  }

  public importInstruments(instrumentExports: InstrumentExport[]) {
    this.setInstrumentDatas(instrumentExports, false);
    this.callbacks.onInstrumentChange(instrumentExports[0].instrument);
    this.callbacks.onTimeframeChange(instrumentExports[0].timeframe);
  }

  public exportTools() {
    return this.getUserTools().map(t => t.definition);
  }

  public importTool(toolDefinition: UserToolDefinition) {
    const id = toolDefinition.id;
    const instrumentData = this.getMainInstrumentData();
    const matchesInstrument = Instrument.isSame(instrumentData.instrument, toolDefinition.instrument);
    const toolSynchronization = this.optionManager.toolSynchronization.getValue();
    const matchesTimeframe = matchesToolSynchronization(instrumentData.timeframe, toolDefinition.timeframe, toolSynchronization);
    if (id == null || !matchesInstrument || !matchesTimeframe) {
      return;
    }
    const existingTool = this.getUserTool(id) ?? this.resolvePendingTool(id);
    if (existingTool != null) {
      existingTool.setDefinition(toolDefinition);
      return;
    }
    const tool = new UserTool(toolDefinition, this.primarySubChart);
    this.userToolMap.set(id, tool);
    tool.finishInitialization();
  }

  public exportSubCharts() {
    const subCharts = this.getSubCharts();
    const totalHeight = this.getTotalHeight();
    const subChartExports = [];
    for (const subChart of subCharts) {
      const indicators = [];
      for (const indicatorData of subChart.getIndicatorDatas()) {
        const indicatorTemplate = ChartIndicatorConverter.export(indicatorData);
        indicators.push(indicatorTemplate);
      }
      const percentageHeight = subChart.getHeight() / totalHeight;
      const instruments = subChart === this.primarySubChart ? this.exportInstruments() : [];
      const isIndicatorsExpanded = subChart.getIsIndicatorsExpanded();
      const subChartExport = new SubChartExport(percentageHeight, instruments, indicators, isIndicatorsExpanded);
      subChartExports.push(subChartExport);
    }
    return subChartExports;
  }

  public importSubCharts(subChartExports: SubChartExport[]) {
    this.clearIndicators();
    for (const subChart of this.getSubCharts()) {
      this.removeSubChart(subChart);
    }
    const filteredSubChartExports = subChartExports.filter(e => e.instruments.length > 0 || e.indicators.length > 0);
    while (this.getSubCharts().length < filteredSubChartExports.length) {
      this.createSubChart();
    }
    const totalHeight = this.getTotalHeight();
    const subCharts = this.getSubCharts();
    let primarySubChart: SubChart | null = null;
    for (let i = 0; i < filteredSubChartExports.length; i++) {
      const subChartExport = filteredSubChartExports[i];
      const subChart = subCharts[i];
      for (const indicatorTemplate of subChartExport.indicators) {
        ChartIndicatorConverter.import(indicatorTemplate, this, subChart);
      }
      subChart.setIsIndicatorsExpanded(subChartExport.isIndicatorsExpanded);
      subChart.setHeight(subChartExport.height * totalHeight);
      if (subChartExport.instruments.length > 0) {
        primarySubChart = subChart;
      }
    }
    this.setPrimarySubChart(primarySubChart ?? subCharts[0]);
    this.redistributeHeight();
    for (const analysisTool of this.getAnalysisTools()) {
      analysisTool.setSubChart(this.primarySubChart);
    }
    for (const alertTool of this.getAlertTools()) {
      alertTool.setSubChart(this.primarySubChart);
    }
  }

  public createChartView(instrument: Instrument, timeframe: Timeframe, name: string | null = null) {
    return new ChartView(null, name, instrument, timeframe, this.exportTools());
  }

  public setChartView(view: ChartView) {
    const instrumentData = this.getMainInstrumentData();
    if (!instrumentData.matches(view)) {
      return;
    }
    this.clearUserTools();
    for (const tool of view.tools) {
      this.importTool(tool);
    }
  }

  public getAreas() {
    const areas = [];
    for (const layer of this.layers) {
      areas.push(...layer.areas);
    }
    return areas.reverse();
  }

  public getHints() {
    const hints: ChartHint[] = [this.fibonacciHint];
    for (const subChart of this.getSubCharts()) {
      hints.push(...subChart.getHints());
    }
    return hints;
  }

  public onDragSelectionChange() {
    this.toolLayer.requireDraw();
    const target = this.dragHandler.getTargets()[0];
    if (target instanceof UserTool) {
      const position = this.dragHandler.getPosition();
      target.setDragOffset(position);
      this.editChartObject(target);
    } else if (target instanceof Drawable && target.tool instanceof UserTool) {
      this.editChartObject(target.tool);
    } else {
      this.editChartObject(null);
    }
  }

  public isMobileMode() {
    return userRightStore().isMobile;
  }

  public setMousePosition(time: number | null, price: number | null) {
    let x: number;
    if (time == null) {
      x = -100;
    } else {
      const barIndex = this.timeAxis.getIndexForTime(time);
      x = this.timeAxis.getXForIndex(Math.floor(barIndex));
    }
    const y = price == null ? -100 : this.primarySubChart.priceAxis.getY(price);
    this.mouseData.setPosition(new Point(x, y));
  }

  public getIsSynchronizedObservable() {
    return this.isSynchronized.pipe(distinctUntilChanged(), startWith(this.isSynchronized.value));
  }

  public setIsSynchronized(isSynchronized: boolean) {
    this.isSynchronized.next(isSynchronized);
  }

  public getContextMenuData(event: MouseEvent) {
    const position = new Point(event.x, event.y);
    const subChart = this.mouseData.getSubChart();
    const price = subChart?.isPrimary ? this.mouseData.getPrice() : null;
    const instrumentData = this.getMainInstrumentData();
    const barTime = this.mouseData.getBarTime();
    const currentBar = barTime == null ? null : instrumentData.mainPlot.store.getByTime(barTime);
    let currentBarCopy: OHLCItem | null;
    if (currentBar instanceof OHLCItem) {
      currentBarCopy = new OHLCItem(
        currentBar.time, currentBar.open, currentBar.high, currentBar.low, currentBar.close, currentBar.volume
      );
    } else {
      currentBarCopy = null;
    }
    const alertHooks = new Set<ToolAlertHook>();
    const alerts = new Set<AlertDefinitionData>();
    for (const target of this.hoverHandler.getTargets()) {
      if (!(target instanceof Drawable)) {
        continue;
      }

      if (target instanceof AlertLineElement || target instanceof AlertIconElement || target instanceof AlertTextBox) {
        alerts.add(target.alertData.data);
      } else if (target.hook != null) {
        alertHooks.add(target.hook);
      } else if (target.tool instanceof UserTool) {
        target.tool.definition.hookProvider.alertHooks.forEach(h => alertHooks.add(h));
      } else if (target.tool instanceof AnalysisTool) {
        target.tool.definition.hookProvider.alertHooks.forEach(h => alertHooks.add(h));
      }
    }
    return new ContextMenuData(this, position, price, currentBarCopy, Array.from(alertHooks), Array.from(alerts));
  }

  public log(text: string) {
    if (this.enableLogging) {
      console.log(text);
    }
  }

  private initialize() {
    this.resize();
    this.callbacks.onInitializationFinished();
  }

  private getTotalHeight() {
    return this.drawingAreaWrapper.getPosition().height;
  }

  private setUpInstrumentDataHandling() {
    this.timeAxis.getRangeObservable().subscribe(() => {
      for (const instrumentData of this.getInstrumentDatas()) {
        instrumentData.checkHistoryDataRequired();
      }
    });
    const mainInstrumentDataObservable = this.getMainInstrumentDataObservable();
    mainInstrumentDataObservable.subscribe(() => {
      for (const subChart of this.getSubCharts()) {
        subChart.priceAxis.setAutoRange();
      }
      this.analysisToolMap.clear();
      this.reloadUserTools();
    });
    mainInstrumentDataObservable.pipe(switchMap(i => i.getLoadingStateObservable())).subscribe(state => {
      // TODO: show loading state text
    });
    this.instrumentDatas.pipe(diffArray()).subscribe(diff => {
      for (const tool of this.getAnalysisTools()) {
        if (diff.removed.some(d => d.matches(tool))) {
          this.removeAnalysisTool(tool);
        }
      }
      for (const instrumentData of diff.removed) {
        instrumentData.onDelete();
      }
    });
  }

  private setUpDeviceRatio() {
    let mediaQuery: MediaQueryList | null = null;
    const onChange = () => {
      this.setPixelRatio(window.devicePixelRatio);
      if (mediaQuery != null) {
        mediaQuery.removeEventListener("change", onChange);
      }
      updateListener();
    };
    const updateListener = () => {
      mediaQuery = matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
      mediaQuery.addEventListener("change", onChange, { once: true });
    };
    updateListener();
  }

  private setPixelRatio(pixelRatio: number) {
    this.pixelRatio.next(pixelRatio);
  }

  private createLayer(name: string) {
    const layer = new ChartLayer(this, name);
    if (this.layers.length > 0) {
      const lastLayer = this.layers[this.layers.length - 1];
      lastLayer.getDrawDataObservable().subscribe(() => layer.requireDraw());
    }
    this.layers.push(layer);
    return layer;
  }

  private setInstrumentDatas(instrumentTimeframes: InstrumentTimeframe[], allowMatchExisting = true) {
    const newInstrumentDatas = [];
    for (const instrumentTimeframe of instrumentTimeframes) {
      if (instrumentTimeframe instanceof InstrumentData) {
        newInstrumentDatas.push(instrumentTimeframe);
        continue;
      }
      let matchingInstrumentData = allowMatchExisting ? this.findMatchingInstrumentData(instrumentTimeframe) : null;
      if (matchingInstrumentData == null) {
        matchingInstrumentData = new InstrumentData(this, instrumentTimeframe.instrument, instrumentTimeframe.timeframe);
      }
      newInstrumentDatas.push(matchingInstrumentData);
    }
    this.instrumentDatas.next(newInstrumentDatas);
  }

  private findMatchingInstrumentData(instrumentTimeframe: InstrumentTimeframe) {
    const instrumentDatas = this.getInstrumentDatas();
    for (const instrumentData of instrumentDatas) {
      if (instrumentData.matches(instrumentTimeframe)) {
        return instrumentData;
      }
    }
    return null;
  }

  private createUserTool(type: string, subChart: SubChart = this.primarySubChart) {
    const instrumentData = this.getMainInstrumentData();
    const toolDefinition = UserToolRegistry.create(type, instrumentData.instrument, instrumentData.timeframe);
    const tool = new UserTool(toolDefinition, subChart);
    this.pendingUserTools.add(tool);
    return tool;
  }

  private resolvePendingTool(id: number) {
    for (const pendingTool of this.pendingUserTools) {
      if (pendingTool.definition.id === id) {
        this.pendingUserTools.delete(pendingTool);
        this.userToolMap.set(id, pendingTool);
        return pendingTool;
      }
    }
    return null;
  }

  private createAnalysisTool(analysis: AnalysisToolDefinition, instrumentData: InstrumentData) {
    let tool: AnalysisTool;
    if (analysis instanceof AutoExtreme) {
      tool = new AutoExtremeTool(analysis, instrumentData, this.primarySubChart);
    } else if (analysis instanceof AutoFibonacci) {
      tool = new AutoFibonacciTool(analysis, instrumentData, this.primarySubChart);
    } else if (analysis instanceof AutoHorizontal) {
      tool = new AutoHorizontalTool(analysis, instrumentData, this.primarySubChart);
    } else if (analysis instanceof AutoDoubleExtreme) {
      tool = new AutoDoubleExtremeTool(analysis, instrumentData, this.primarySubChart);
    } else if (analysis instanceof AutoChannel) {
      tool = new AutoChannelTool(analysis, instrumentData, this.primarySubChart);
    } else if (analysis instanceof AutoPriceGap) {
      tool = new AutoPriceGapTool(analysis, instrumentData, this.primarySubChart);
    } else if (analysis instanceof AutoTrendLine) {
      tool = new AutoTrendLineTool(analysis, instrumentData, this.primarySubChart);
    } else if (analysis instanceof AutoHeadAndShoulders) {
      tool = new AutoHeadAndShouldersTool(analysis, instrumentData, this.primarySubChart);
    } else {
      throw new ChartError("Unknown analysis type");
    }
    tool.initialize();
    this.analysisToolMap.set(tool.id, tool);
  }

  private updateAlertTools(alert: Alert) {
    const definitions = alert.rules.map(r => r.definitions).flat();
    const tools = new Set<ChartTool>();
    for (const definition of definitions) {
      let tool: ChartTool | null = null;
      if (definition instanceof AlertUserToolDefinition) {
        tool = this.getUserTool(definition.toolId);
      } else if (definition instanceof AlertAutoToolDefinition) {
        tool = this.getAnalysisTool(definition.toolId);
      }
      if (tool != null) {
        tools.add(tool);
      }
    }
    tools.forEach(t => t.resetDrawables());
  }

  private setAction(action: ChartAction | null) {
    const previousAction = this.action;
    this.action = action;
    previousAction?.onCancel?.();
    if (action != null) {
      this.hoverHandler.unselect();
    }
    this.callbacks.onActionChange(action == null ? ChartActionType.None : action.key);
  }

  private saveTimeRange() {
    const instrumentData = this.getMainInstrumentData();
    const rangeCache = this.timeAxis.createRangeCache();
    this.callbacks.saveRangeCache(instrumentData.instrument, instrumentData.timeframe, rangeCache);
  }

  private loadTimeRange() {
    const instrumentData = this.getMainInstrumentData();
    this.callbacks.loadRangeCache(instrumentData.instrument, instrumentData.timeframe);
  }
}

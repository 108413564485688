import { ChartColor, GradientColor, RGBAColor } from "@/anfin-chart/draw/chart-color";
import { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import { BehaviorSubject, distinctUntilChanged, map, startWith } from "rxjs";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import type { Indicator } from "@/anfin-chart/indicator";
import type { InstrumentData } from "@/anfin-chart/instrument";

export interface OptionProvider {
  optionMap: Map<string, ChartOption<any>>;
  onOptionChange(option: ChartOption<unknown>): void;
}

export type ChartObject = UserTool | Indicator | InstrumentData;

export abstract class ChartOption<T> {

  private readonly valueSubject: BehaviorSubject<T>;

  constructor(private readonly provider: OptionProvider,
              public readonly name: string,
              private value: T) {
    provider.optionMap.set(name, this);
    this.valueSubject = new BehaviorSubject<T>(this.value);
  }

  public getValue() {
    return this.value;
  }

  public getValueObservable() {
    return this.valueSubject.pipe(distinctUntilChanged(), startWith(this.valueSubject.value));
  }

  public setValue(value: T, isNotifyChange = true) {
    if (this.isSame(value)) {
      return;
    }
    this.value = value;
    if (isNotifyChange) {
      this.valueSubject.next(value);
      this.provider.onOptionChange(this as ChartOption<unknown>);
    }
  }

  protected isSame(value: T) {
    return value === this.getValue();
  }

  public abstract getType(): OptionValueType;
}

export class ColorOption extends ChartOption<ChartColor> {

  constructor(provider: OptionProvider,
              name: string,
              value: ChartColor = RGBAColor.transparent) {
    super(provider, name, value);
  }

  public override getType() {
    return OptionValueType.Color;
  }

  protected override isSame(value: ChartColor) {
    const currentValue = this.getValue();
    if (value instanceof RGBAColor) {
      return currentValue instanceof RGBAColor && this.isSameRgba(value, currentValue);
    }
    if (value instanceof GradientColor) {
      if (!(currentValue instanceof GradientColor) || value.stops.length !== currentValue.stops.length) {
        return false;
      }
      for (let i = 0; i < value.stops.length; i++) {
        const stop = value.stops[i];
        const currentStop = currentValue.stops[i];
        if (stop.percentage !== currentStop.percentage || !this.isSameRgba(stop.color, currentStop.color)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private isSameRgba(value: RGBAColor, currentValue: RGBAColor) {
    return value.r === currentValue.r && value.g === currentValue.g && value.b === currentValue.b &&
      value.a === currentValue.a;
  }
}

export class NumericOption extends ChartOption<number> {

  public override getType() {
    return OptionValueType.Numeric;
  }
}

export class EnumOption extends ChartOption<number> {

  public override getType() {
    return OptionValueType.Enum;
  }
}

export class BooleanOption extends ChartOption<boolean> {

  public override getType() {
    return OptionValueType.Boolean;
  }
}

export class StringOption extends ChartOption<string> {

  public override getType() {
    return OptionValueType.String;
  }
}

export abstract class ArrayOption<T> extends ChartOption<T[]> {

  protected override isSame(value: T[]) {
    const currentValue = this.getValue();
    return value.length === currentValue.length && value.every((v, i) => v === currentValue[i]);
  }
}

export class NumericArrayOption extends ArrayOption<number> {

  public override getType() {
    return OptionValueType.NumericArray;
  }
}

export class StringArrayOption extends ArrayOption<string> {

  public override getType() {
    return OptionValueType.StringArray;
  }
}

export class DevicePixelOption extends NumericOption {

  public override getValueObservable() {
    return super.getValueObservable().pipe(map(v => v * window.devicePixelRatio));
  }

  public override getValue() {
    const value = super.getValue();
    return value * window.devicePixelRatio;
  }

  public getPlainValue() {
    return super.getValue();
  }
}

export function applyOptions(optionProvider: OptionProvider, optionDefinitions: ChartOptionDefinition[]) {
  for (const optionDefinition of optionDefinitions) {
    applyOption(optionProvider, optionDefinition.name, optionDefinition.value);
  }
}

export function applyOption(optionProvider: OptionProvider, name: string, value: unknown) {
  const matchingOption = optionProvider.optionMap.get(name);
  if (matchingOption != null) {
    matchingOption.setValue(value);
  }
}

export enum OptionValueType {
  Numeric = 0,
  String = 1,
  Boolean = 2,
  NumericArray = 3,
  Color = 4,
  Enum = 5,
  StringArray = 6
}

export function toOptionDefinition(option: ChartOption<unknown>) {
  const value = option instanceof DevicePixelOption ? option.getPlainValue() : option.getValue();
  return new ChartOptionDefinition(option.name, option.getType(), value);
}

export function getOptionDefinitions(optionProvider: OptionProvider) {
  return Array.from(optionProvider.optionMap.values()).map(o => toOptionDefinition(o));
}

<template>
  <ColorPickerButtonTemplate ref="colorPicker" :modelValue="modelValue" :size="size" :allowSwitchType="true" @update:modelValue="onChange">
    <template #rgba="slotProps">
      <RgbaColorPicker :modelValue="slotProps.value" :alphaPresetColors="alphaPresetColors" @update:modelValue="slotProps.setValue" />
    </template>
    <template #gradient="slotProps">
      <GradientColorPicker :modelValue="slotProps.value" @update:modelValue="slotProps.setValue" />
    </template>
  </ColorPickerButtonTemplate>
</template>

<script lang="ts">
import { ColorDefinition, ColorType } from "@/anfin-chart/indicator-definition";
import ColorPickerButtonTemplate from "@/views/color-picker/ColorPickerButtonTemplate.vue";
import GradientColorPicker from "@/views/color-picker/GradientColorPicker.vue";
import RgbaColorPicker from "@/views/color-picker/RgbaColorPicker.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ColorPickerButton",

  components: { GradientColorPicker, RgbaColorPicker, ColorPickerButtonTemplate },

  props: {
    modelValue: {
      type: ColorDefinition,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    alphaPresetColors: {
      type: Boolean,
      required: false
    }
  },

  emits: {
    "update:modelValue": (color: ColorDefinition) => color && true
  },

  expose: ["checkPosition"],

  data() {
    return {
      ColorType
    };
  },

  methods: {
    onChange(color: ColorDefinition) {
      this.$emit("update:modelValue", color);
    },

    checkPosition() {
      const colorPicker = this.$refs.colorPicker as typeof ColorPickerButtonTemplate;
      colorPicker.checkPosition();
    }
  }
});
</script>

<style scoped>
</style>

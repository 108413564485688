import { StackAlignment, StackDirection, StackPanel } from "@/anfin-chart/area/stack-panel";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { Rectangle } from "@/anfin-chart/geometry";
import type { SubChart } from "@/anfin-chart/sub-chart";
import {
  AutoPriceRangeButton,
  LinkSplitChartButton,
  MoveDownButton,
  MoveToLastButton,
  MoveUpButton,
  SubChartActionButton,
  SubChartCollapseButton, SubChartMaximizeButton
} from "@/anfin-chart/area/action-button";

export abstract class SubChartActionArea extends StackPanel {

  constructor(layer: ChartLayer,
              public readonly subChart: SubChart) {
    super(layer, StackDirection.Horizontal, StackAlignment.Stretch, layer.chart.styleOptions.actionButtonSpacing);
  }

  public override initializeEvents() {
    this.subscribeOn(this.subChart.drawingArea.getPositionObservable(), () => this.resize());
    const items = this.createItems();
    for (const item of items) {
      item.initializeEvents();
    }
    this.setStackItems(items);
  }

  protected override drawInternal() {
    // nothing
  }

  protected abstract createItems(): SubChartActionButton[];
}

export class UpperActionArea extends SubChartActionArea {

  protected override resizeInternal() {
    const drawingAreaPosition = this.subChart.drawingArea.getPosition();
    const margin = this.chart.styleOptions.actionAreaMargin.getValue();
    const size = this.getSize();
    const xEnd = drawingAreaPosition.xEnd - margin;
    const xStart = xEnd - size.width;
    const yStart = drawingAreaPosition.yStart + margin;
    const yEnd = yStart + size.height;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  protected override createItems(): SubChartActionButton[] {
    return [
      new MoveDownButton(this, true),
      new MoveUpButton(this, true),
      new SubChartCollapseButton(this, true),
      new SubChartMaximizeButton(this, true),
      new LinkSplitChartButton(this, false)
    ];
  }
}

export class LowerActionArea extends SubChartActionArea {

  protected override resizeInternal() {
    const drawingAreaPosition = this.subChart.drawingArea.getPosition();
    const margin = this.chart.styleOptions.actionAreaMargin.getValue();
    const size = this.getSize();
    const xEnd = drawingAreaPosition.xEnd - margin;
    const xStart = xEnd - size.width;
    const yEnd = drawingAreaPosition.yEnd - margin;
    const yStart = yEnd - size.height;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  protected override createItems(): SubChartActionButton[] {
    return [
      new AutoPriceRangeButton(this, false),
      new MoveToLastButton(this, false)
    ];
  }
}

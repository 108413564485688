<template>
  <div ref="container" class="watchlist-table-container" @scroll="onScroll">
    <table class="watchlist-table">
      <colgroup>
        <col v-for="column in columns" :style="{ width: column.isMinimized ? 0 : '25%' }" />
      </colgroup>
      <thead class="watchlist-table-header">
        <tr>
          <!-- v-draggable="getColumnDragOptions(column)" -->
          <th
            v-for="column in columns" :key="column.key"
            :style="{ textAlign: column.alignmentCss }" @click="setSortColumn(column)"
          >
            <div class="sort-container">
              <span>
                {{ getHeaderLabel(column.key) }}
              </span>
              <TableSortMarker
                v-if="sortColumn?.key === column.key" :isAscending="isSortAscending"
                :class="{ 'ms-2': getHeaderLabel(column.key) !== '' }"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody ref="content" class="watchlist-table-content">
        <WatchlistTableItem
          v-for="item in sortedItems" :key="watchlist.id + '_' + item.symbol" v-draggable="getItemDragOptions(item)" :item="item"
          :columns="columns" :active="item === activeItem" :allowDelete="allowDelete"
          :isHighlighted="draggedItem == null && item === hoveredItem || item === draggedItem"
          :class="{ highlighted: item === draggedItem }" @removeItem="removeItem(item)"
          @changeHover="changeHover(item, $event)"
        />
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Instrument } from "@/anfin-chart/instrument";
import { type Consumer, Debouncer, simpleMapCompare } from "@/anfin-chart/utils";
import { Watchlist, WatchlistItem, WatchlistType } from "@/api/models/watchlist";
import { multiChartStore } from "@/stores/multi-chart-store";
import WatchlistTableItem from "@/views/watchlist/WatchlistTableItem.vue";
import { storeToRefs } from "pinia";
import { defineComponent, nextTick } from "vue";
import { watchlistStore } from "@/stores/watchlist-store";
import { ColumnAlignment, TableColumn } from "@/views/table/table-column";
import { translationStore } from "@/stores/translation-store";
import type { DraggableOptions } from "@/directives/draggable";
import TableSortMarker from "@/views/table/TableSortMarker.vue";
import { chartOptionStore, MultiChartOptionManager } from "@/stores/chart-option-store";
import { WatchlistController } from "@/api/watchlist-controller";
import { PriceAboHandler } from "@/handler/price-abo-handler";
import type { TickData } from "@/api/messages/tick-update";
import { userRightStore } from "@/stores/user-right-store";

export class WatchlistScrollRange {

  constructor(public readonly containerElement: HTMLElement,
              public readonly contentElement: HTMLElement,
              public readonly firstIndex: number,
              public readonly lastIndex: number) {
  }

  public get size() {
    return this.lastIndex - this.firstIndex;
  }
}

export enum WatchlistColumnKey {
  Category = "category",
  Symbol = "symbol",
  LastPrice = "lastPrice",
  DifferenceAbsolute = "differenceAbsolute",
  DifferencePercentage = "differencePercentage",
  Delete = "delete"
}

export default defineComponent({
  name: "WatchlistTable",

  components: { TableSortMarker, WatchlistTableItem },

  props: {
    watchlist: {
      type: Watchlist,
      required: true
    },
    allowDelete: Boolean
  },

  expose: [],

  data() {
    const { instrument } = storeToRefs(multiChartStore());
    const columnMap = new Map<string, TableColumn>();
    const addColumn = (column: TableColumn) => columnMap.set(column.key, column);
    addColumn(new TableColumn(WatchlistColumnKey.Category, ColumnAlignment.Center, simpleMapCompare(i => i.data.category)).minimize());
    addColumn(new TableColumn(WatchlistColumnKey.Symbol, ColumnAlignment.Left, simpleMapCompare(i => i.symbol)));
    addColumn(new TableColumn(WatchlistColumnKey.LastPrice, ColumnAlignment.Right, simpleMapCompare(i => i.data.price)));
    addColumn(new TableColumn(WatchlistColumnKey.DifferenceAbsolute, ColumnAlignment.Right, simpleMapCompare(i => i.data.diff)));
    addColumn(new TableColumn(WatchlistColumnKey.DifferencePercentage, ColumnAlignment.Right, simpleMapCompare(i => i.data.percent)));
    addColumn(new TableColumn(WatchlistColumnKey.Delete, ColumnAlignment.Center, () => 0).preventDrag().minimize());
    const { optionManager } = storeToRefs(chartOptionStore());
    return {
      translationStore: translationStore(),
      optionManager: optionManager as unknown as MultiChartOptionManager,
      columnMap: columnMap,
      draggedColumn: null as TableColumn | null,
      hoveredItem: null as WatchlistItem | null,
      draggedItem: null as WatchlistItem | null,
      dragItemDebouncer: new Debouncer(100),
      instrument: instrument as unknown as Instrument,
      aboHandler: new PriceAboHandler((symbol: string, tickData: TickData) => this.updatePrice(symbol, tickData)),
      timer: null as number | null,
      keyListener: null as Consumer<KeyboardEvent> | null
    };
  },

  computed: {
    columns(): TableColumn[] {
      const columnOrder = this.optionManager.watchlistColumnOrder.getValue();
      const remainingKeys = new Set(this.columnMap.keys());
      const columns = [];
      for (const key of columnOrder) {
        const matchingColumn = this.columnMap.get(key);
        if (matchingColumn != null) {
          remainingKeys.delete(key);
          columns.push(matchingColumn);
        }
      }
      for (const key of remainingKeys) {
        const matchingColumn = this.columnMap.get(key);
        if (matchingColumn != null) {
          columns.push(matchingColumn);
        }
      }
      return columns;
    },

    sortColumn(): TableColumn | null {
      const columnKey = this.optionManager.watchlistSortColumn.getValue();
      if (columnKey === "") {
        return null;
      }
      return this.columnMap.get(columnKey) ?? null;
    },

    isSortAscending() {
      return this.optionManager.watchlistSortAscending.getValue();
    },

    sortedItems(): WatchlistItem[] {
      const items = this.watchlist.getItems();
      if (this.sortColumn != null) {
        items.sort(this.sortColumn.comparator);
        if (!this.isSortAscending) {
          items.reverse();
        }
      }
      return items;
    },

    activeIndex(): number | null {
      const symbol = this.instrument.getSymbol();
      for (let i = 0; i < this.sortedItems.length; i++) {
        const item = this.sortedItems[i];
        if (item.symbol === symbol) {
          return i;
        }
      }
      return null;
    },

    activeItem(): WatchlistItem | null {
      return this.activeIndex == null ? null : this.sortedItems[this.activeIndex];
    },

    itemCount(): number {
      return this.sortedItems.length;
    },

    getHeaderLabel() {
      return (key: string) => this.translationStore.getTranslation("watchlist_table#header#" + key);
    },

    isItemDragAllowed() {
      const isAdmin = userRightStore().userInfo?.isAdmin ?? false;
      return this.sortColumn == null && (this.watchlist.type === WatchlistType.User || isAdmin);
    }
  },

  watch: {
    watchlist() {
      nextTick(() => this.updateScrollRange());
    },

    sortedItems() {
      nextTick(() => this.updateScrollRange());
    }
  },

  mounted() {
    this.keyListener = e => {
      if (e.key === "ArrowUp") {
        this.changeInstrument(-1);
        e.preventDefault();
      } else if (e.key === "ArrowDown") {
        this.changeInstrument(1);
        e.preventDefault();
      }
    };
    window.addEventListener("keydown", this.keyListener);
    nextTick(() => this.updateScrollRange());
  },

  unmounted() {
    if (this.keyListener != null) {
      window.removeEventListener("keydown", this.keyListener);
    }
    this.aboHandler.removeAllAbos();
  },

  methods: {
    setSortColumn(column: TableColumn) {
      const columnOption = this.optionManager.watchlistSortColumn;
      const directionOption = this.optionManager.watchlistSortAscending;
      if (this.sortColumn !== column) {
        columnOption.setValue(column.key);
        directionOption.setValue(true);
      } else if (directionOption.getValue()) {
        directionOption.setValue(!directionOption.getValue());
      } else {
        columnOption.setValue("");
      }
    },

    changeInstrument(indexDifference: number) {
      const itemCount = this.sortedItems.length;
      let newIndex: number;
      if (this.activeIndex == null) {
        newIndex = indexDifference >= 0 ? 0 : itemCount - 1;
      } else {
        newIndex = Math.max(0, Math.min(itemCount - 1, this.activeIndex + indexDifference));
      }
      this.setActiveItem(newIndex);
      nextTick(() => this.scrollIntoView());
    },

    onScroll() {
      if (this.timer != null) {
        return;
      }
      this.timer = window.setTimeout(() => {
        this.timer = null;
        this.updateScrollRange();
      }, 10);
    },

    getColumnDragOptions(column: TableColumn): DraggableOptions {
      return {
        onDragStart: () => this.onColumnDragStart(column),
        onDragEnd: () => this.onColumnDragEnd(),
        onDragEnter: () => this.onColumnDragEnter(column)
      };
    },

    onColumnDragStart(column: TableColumn) {
      if (!column.isDraggable) {
        return;
      }
      this.draggedColumn = column;
    },

    onColumnDragEnter(column: TableColumn) {
      if (this.draggedColumn == null || column === this.draggedColumn || !column.isDraggable) {
        return;
      }
      const columns = this.columns.slice();
      const draggedIndex = columns.indexOf(this.draggedColumn);
      const targetIndex = columns.indexOf(column);
      columns.splice(draggedIndex, 1);
      columns.splice(targetIndex, 0, this.draggedColumn);
      const columnOrder = columns.map(c => c.key);
      chartOptionStore().optionManager.watchlistColumnOrder.setValue(columnOrder);
    },

    onColumnDragEnd() {
      this.draggedColumn = null;
    },

    getItemDragOptions(item: WatchlistItem): DraggableOptions {
      return {
        useTargetImage: true,
        onDragStart: () => this.onItemDragStart(item),
        onDragEnd: () => this.onItemDragEnd(),
        onDragEnter: () => this.dragItemDebouncer.execute(() => this.onItemDragEnter(item))
      };
    },

    onItemDragStart(item: WatchlistItem) {
      if (!this.isItemDragAllowed) {
        return;
      }
      this.draggedItem = item;
    },

    onItemDragEnter(item: WatchlistItem) {
      if (this.draggedItem == null || item === this.draggedItem) {
        return;
      }
      const draggedItem = this.draggedItem as WatchlistItem;
      const draggedIndex = draggedItem.sortIndex;
      const targetIndex = item.sortIndex;
      if (targetIndex > draggedIndex) {
        for (let i = draggedIndex + 1; i <= targetIndex; i++) {
          this.sortedItems[i].sortIndex--;
        }
      } else {
        for (let i = draggedIndex - 1; i >= targetIndex; i--) {
          this.sortedItems[i].sortIndex++;
        }
      }
      draggedItem.sortIndex = targetIndex;
    },

    onItemDragEnd() {
      if (this.draggedItem != null) {
        WatchlistController.getInstance().saveWatchlistItems(this.watchlist.id, this.sortedItems);
      }
      this.draggedItem = null;
    },

    setActiveItem(index: number) {
      if (index === this.activeIndex) {
        return;
      }
      const item = this.sortedItems[index];
      if (item != null) {
        const instrument = Instrument.fromSymbol(item.symbol);
        multiChartStore().changeInstrument(instrument);
      }
    },

    removeItem(item: WatchlistItem) {
      watchlistStore().deleteWatchlistItem(this.watchlist, item.symbol);
    },

    getCurrentScrollRange() {
      const container = this.$refs.container as HTMLElement | null;
      const content = this.$refs.content as HTMLElement | null;
      if (container == null || content == null) {
        return null;
      }
      const contentHeight = content.scrollHeight;
      const visibleHeight = container.offsetHeight;
      const itemCount = this.sortedItems.length;
      const scrollOffset = container.scrollTop;
      let firstIndex: number;
      let lastIndex: number;
      if (contentHeight === 0) {
        firstIndex = 0;
        lastIndex = -1;
      } else {
        firstIndex = itemCount * scrollOffset / contentHeight;
        lastIndex = firstIndex + itemCount * visibleHeight / contentHeight - 1;
      }
      return new WatchlistScrollRange(container, content, firstIndex, lastIndex);
    },

    updateScrollRange() {
      const scrollRange = this.getCurrentScrollRange();
      if (scrollRange == null) {
        return;
      }
      const indexOffset = 10;
      const firstIndexOffset = Math.max(0, Math.floor(scrollRange.firstIndex) - indexOffset);
      const lastIndexOffset = Math.min(this.itemCount - 1, Math.ceil(scrollRange.lastIndex) + indexOffset);
      this.setAboRange(firstIndexOffset, lastIndexOffset);
    },

    setAboRange(start: number, end: number) {
      const isAboAllItems = this.sortColumn?.key === WatchlistColumnKey.DifferenceAbsolute ||
        this.sortColumn?.key === WatchlistColumnKey.DifferencePercentage ||
        this.sortColumn?.key === WatchlistColumnKey.LastPrice;
      const aboStart = isAboAllItems ? 0 : start;
      const aboEnd = isAboAllItems ? this.sortedItems.length : end + 1;
      const symbols = this.sortedItems.slice(aboStart, aboEnd).map(i => i.symbol);
      this.aboHandler.setAbos(new Set(symbols));
    },

    scrollIntoView() {
      const scrollRange = this.getCurrentScrollRange();
      if (this.activeIndex == null || scrollRange == null || scrollRange.lastIndex <= scrollRange.firstIndex) {
        return;
      }
      let newFirstIndex = scrollRange.firstIndex;
      if (this.activeIndex <= scrollRange.firstIndex + 1) {
        newFirstIndex = this.activeIndex - 1;
      }
      if (this.activeIndex >= scrollRange.lastIndex - 2) {
        newFirstIndex = this.activeIndex + 2 - scrollRange.size;
      }
      const contentHeight = scrollRange.contentElement.offsetHeight;
      scrollRange.containerElement.scrollTop = newFirstIndex * contentHeight / this.itemCount;
    },

    updatePrice(symbol: string, tickData: TickData) {
      const item = this.watchlist.getItem(symbol);
      if (item != null) {
        item.data.update(tickData);
      }
    },

    changeHover(item: WatchlistItem, isHovered: boolean) {
      if (isHovered) {
        this.hoveredItem = item;
      } else if (this.hoveredItem === item) {
        this.hoveredItem = null;
      }
    }
  }
});
</script>

<style>
.watchlist-table-container {
  overflow-y: auto;
}

.watchlist-table {
  width: 100%;
  background: var(--background-elevated);
  color: var(--content-primary);
  border-collapse: separate;
  border-spacing: 0;
}

.watchlist-table th, .watchlist-table td {
  padding: 0 8px;
}

.watchlist-table-header {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 33px;
}

.watchlist-table-header th {
  background: var(--background-elevated);
  border-bottom: 2px solid var(--border-neutral);
  cursor: pointer;
}

.watchlist-table-header th .sort-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.watchlist-table-header th:hover {
  background: var(--background-overlay);
}
</style>

import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ElliotWave } from "@/anfin-chart/tools/user-tools/elliot-wave";

export class ElliotABC extends ElliotWave {

  public static readonly type = "ellabc";

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(
      ElliotABC.type, instrument, timeframe, 4, ["0", "A", "B", "C"],
      new RGBAColor(0, 116, 178)
    );
  }
}

import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ElliotWave } from "@/anfin-chart/tools/user-tools/elliot-wave";

export class ElliotWXYXZ extends ElliotWave {

  public static readonly type = "ellwxyxz";

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(
      ElliotWXYXZ.type, instrument, timeframe, 6, ["0", "W", "X", "Y", "X", "Z"],
      new RGBAColor(0, 116, 178)
    );
  }
}

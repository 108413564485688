import { BooleanOption, ColorOption, NumericArrayOption } from "@/anfin-chart/options/option";
import type { ChartOptionManager } from "@/anfin-chart/options/option-manager";
import { OptionName } from "@/anfin-chart/options/option-manager";
import { AlertState } from "@/api/models/alert";

export enum AlertColorOptionName {
  AlertToolUpperLine = "alertToolUpperLine",
  AlertToolLowerLine = "alertToolLowerLine",
  AlertToolTriggeredLine = "alertToolTriggeredLine",
  AlertToolInactiveLine = "alertToolInactiveLine",
  AlertToolActiveArea = "alertToolActiveArea",
  AlertToolTriggeredArea = "alertToolTriggeredArea",
  AlertToolInactiveArea = "alertToolInactiveArea"
}

export class AlertColorOptions implements Record<AlertColorOptionName, ColorOption> {

  public readonly alertToolUpperLine: ColorOption;
  public readonly alertToolLowerLine: ColorOption;
  public readonly alertToolTriggeredLine: ColorOption;
  public readonly alertToolInactiveLine: ColorOption;
  public readonly alertToolActiveArea: ColorOption;
  public readonly alertToolTriggeredArea: ColorOption;
  public readonly alertToolInactiveArea: ColorOption;

  constructor(optionManager: ChartOptionManager) {
    this.alertToolUpperLine = new ColorOption(optionManager, AlertColorOptionName.AlertToolUpperLine);
    this.alertToolLowerLine = new ColorOption(optionManager, AlertColorOptionName.AlertToolLowerLine);
    this.alertToolTriggeredLine = new ColorOption(optionManager, AlertColorOptionName.AlertToolTriggeredLine);
    this.alertToolInactiveLine = new ColorOption(optionManager, AlertColorOptionName.AlertToolInactiveLine);
    this.alertToolActiveArea = new ColorOption(optionManager, AlertColorOptionName.AlertToolActiveArea);
    this.alertToolTriggeredArea = new ColorOption(optionManager, AlertColorOptionName.AlertToolTriggeredArea);
    this.alertToolInactiveArea = new ColorOption(optionManager, AlertColorOptionName.AlertToolInactiveArea);
  }
}

export class AlertOptions {

  public readonly shownStates: NumericArrayOption;
  public readonly showOnlyActiveInstruments: BooleanOption;

  constructor(optionManager: ChartOptionManager) {
    const defaultStates = [AlertState.New, AlertState.Pending, AlertState.Active, AlertState.Triggered];
    this.shownStates = new NumericArrayOption(optionManager, OptionName.AlertShownStates, defaultStates);
    this.showOnlyActiveInstruments = new BooleanOption(optionManager, OptionName.AlertShowOnlyActiveInstruments, false);
  }
}

import { ChartAction, ChartActionType } from "@/anfin-chart/actions/actions";
import type { Rectangle } from "@/anfin-chart/geometry";
import type { ClickData } from "@/anfin-chart/interactions";

export class ZoomAction extends ChartAction {

  public readonly key = ChartActionType.Zoom;

  private isDragging = false;

  public onClick = (data: ClickData) => {
    const stepCount = this.chart.isSecondaryActionMode() ? -2 : 2;
    this.chart.timeAxis.zoomCenter(data.position, stepCount);
    const subChart = this.chart.mouseData.getSubChart();
    if (subChart != null && !subChart.priceAxis.getIsAutoRange()) {
      subChart.priceAxis.zoomCenter(data.position, stepCount);
    }
    return true;
  };

  public onDragMove = () => {
    this.isDragging = true;
    return true;
  };

  public onMouseUp = () => {
    if (!this.isDragging) {
      return false;
    }
    const rectangle = this.chart.zoomWindowOverlay.getZoomWindow();
    this.zoomTimeRange(rectangle);
    this.zoomPriceRange(rectangle);
    this.chart.setDefaultMode();
    return true;
  };

  private zoomTimeRange(rectangle: Rectangle) {
    const chart = this.chart;
    chart.overlayLayer.requireDraw();
    let startIndex = chart.timeAxis.getIndexForX(rectangle.xStart);
    let endIndex = chart.timeAxis.getIndexForX(rectangle.xEnd);
    const size = endIndex - startIndex;
    const minBarCount = chart.styleOptions.minBarCount.getValue();
    if (size < minBarCount) {
      const offset = (minBarCount - size) / 2;
      startIndex -= offset;
      endIndex += offset;
    }
    chart.timeAxis.setRange(startIndex, endIndex);
    return true;
  }

  private zoomPriceRange(rectangle: Rectangle) {
    const subChart = this.chart.mouseData.getSubChart();
    if (subChart != null && !subChart.priceAxis.getIsAutoRange()) {
      subChart.priceAxis.zoomRange(rectangle.yStart, rectangle.yEnd);
    }
    return true;
  }
}

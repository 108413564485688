<template>
  <div>
    <div
      class="btn btn-sm chat-selector-item" :class="{ active: chat.isActive && isSelected }" @click="toggleSelected"
    >
      <div class="chat-selector-title flex-grow-1">
        {{ chat.title }}
      </div>
      <IconElement v-if="isAdmin && chat.isPublic" iconName="Plus" :size="16" @click.stop="createMessage" />
      <IconElement v-else-if="!chat.isActive" iconName="Lock" :size="12" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconElement from "@/views/icons/IconElement.vue";
import { Chat, ChatMessage } from "@/api/models/chat";
import { chartOptionStore } from "@/stores/chart-option-store";
import { storeToRefs } from "pinia";
import { userRightStore } from "@/stores/user-right-store";
import { uiStateStore } from "@/stores/ui-state-store";

export default defineComponent({
  name: "ChatHeaderItem",

  components: { IconElement },

  props: {
    chat: {
      type: Chat,
      required: true
    }
  },

  expose: [],

  data() {
    const { isAdmin } = storeToRefs(userRightStore());
    return {
      isAdmin: isAdmin as unknown as boolean
    };
  },

  computed: {
    selectedIds() {
      return new Set(chartOptionStore().optionManager.selectedChats.getValue());
    },

    isSelected() {
      return this.selectedIds.has(this.chat.id);
    }
  },

  methods: {
    toggleSelected() {
      const isRemove = this.isSelected || !this.chat.isActive;
      if (isRemove) {
        this.selectedIds.delete(this.chat.id);
      } else {
        this.selectedIds.add(this.chat.id);
      }
      const selectedIds = Array.from(this.selectedIds);
      chartOptionStore().optionManager.selectedChats.setValue(selectedIds);
    },

    createMessage() {
      const user = userRightStore().userInfo?.user;
      if (user != null) {
        uiStateStore().editedMessage = new ChatMessage(this.chat, null, user, "", "", Date.now(), []);
      }
    }
  }
});
</script>

<style scoped>
.chat-selector-item {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  color: var(--content-primary);
  border: var(--disabled-primary) solid 1px;
  min-width: 130px;
}

.chat-selector-item.active {
  background-color: var(--background-primary-overlay);
  color: var(--content-secondary-top);
  border-color: var(--content-secondary-top);
  border-width: 1px;
}

.chat-selector-title:not(:last-child) {
  margin-right: 4px;
}
</style>
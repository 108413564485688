import { defineStore } from "pinia";
import { generalStore } from "@/stores/general-store";
import { NativeAppAdapter } from "@/utils/native-app-adapter";
import { LoggingController } from "@/api/logging-controller";
import { getEnvironment } from "@/api/environment";
import { userRightStore } from "@/stores/user-right-store";

type LoggingExtension = (action: string, message: unknown) => void;

export class LoggingExtensionData {

  public readonly log: LoggingExtension[] = [];
  public readonly warn: LoggingExtension[] = [];
  public readonly error: LoggingExtension[] = [];
  public readonly info: LoggingExtension[] = [];
}

export const loggingStore = defineStore({
  id: "logging",

  state() {
    return {
      logOriginal: console.log,
      warnOriginal: console.warn,
      errorOriginal: console.error,
      infoOriginal: console.info
    };
  },

  actions: {
    setConsoleRedirect() {
      const appExtension = (action: string, data: unknown) => {
        if (generalStore().isDebugLoggingEnabled) {
          NativeAppAdapter.getInstance().sendMessage(action, data);
        }
      };
      const errorExtension = (action: string, message: unknown) => {
        LoggingController.getInstance().sendErrorLog(message);
      };
      const extensionData = new LoggingExtensionData();
      if (userRightStore().isMobile) {
        extensionData.log.push(appExtension);
        extensionData.warn.push(appExtension);
        extensionData.error.push(appExtension);
        extensionData.info.push(appExtension);
      }
      if (getEnvironment().isSendErrorLogs) {
        extensionData.error.push(errorExtension);
      }
      this.applyExtensions(extensionData);
    },

    applyExtensions(extensionData: LoggingExtensionData) {
      console.log = this.extendLog(this.logOriginal, extensionData.log);
      console.warn = this.extendLog(this.warnOriginal, extensionData.warn);
      console.error = this.extendLog(this.errorOriginal, extensionData.error);
      console.info = this.extendLog(this.infoOriginal, extensionData.info);
    },

    extendLog(action: (data: unknown) => void, extensions: LoggingExtension[]) {
      return (data: unknown) => {
        action(data);
        extensions.forEach(e => e(action.name, data));
      };
    }
  }
});

import type { Instrument } from "@/anfin-chart/instrument";
import { userRightStore } from "@/stores/user-right-store";
import { ChartServerInterface } from "./ChartServerInterface.js";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { instrumentStore } from "@/stores/instrument-store";
import { chatStore } from "@/stores/chat-store";

export class ChartHandler {

  private static instance: ChartHandler | null = null;

  public chartServerInterface: ChartServerInterface;

  constructor() {
    this.chartServerInterface = new ChartServerInterface(this);

    const userInfo = userRightStore().userInfo;
    if (userInfo != null) {
      this.setToken(userInfo.token);
    }
  }

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new ChartHandler();
    }
    return this.instance;
  }

  public static clear() {
    this.instance = null;
  }

  public setToken(token: string) {
    this.chartServerInterface.setToken(token);
  }

  public handleUserApiLogin(loginResult: any) {
    instrumentStore().requestLastChartViews();
    chatStore().requestAllChatMessages();
  }

  public handleLastViewResponse(cfg: any) {
    if (cfg.data && cfg.data.length > 0) {
      const rev = cfg.data.reverse();
      rev.forEach((view: any) => {
        instrumentStore().updateHistory(view.symbol);
      });
    }
  }

  public saveLastView(instrument: Instrument, timeframe: Timeframe) {
    const symbol = instrument.getSymbol();
    instrumentStore().updateHistory(symbol);
    this.chartServerInterface.saveLastView(symbol, timeframe.toShortNotation());
  }

  public fullScreen() {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else {
        /* only Safari Mobile */
        (document as any).webkitExitFullscreen();
      }
    } else {
      const element = document.getElementById("multi-chart");
      if (element != null) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else {
          /* only Safari Mobile */
          (element as any).webkitRequestFullscreen();
        }
      }
    }
  }

  public getTranslation(rule: string) {
    if (rule === "RULE123_2B") {
      return "Bestätigung eines Kandidaten nach 123-Regel Punkt 2b";
    }
    if (rule === "RULE123_2C") {
      return "Bestätigung eines Kandidaten nach 123-Regel Punkt 2c";
    }
    if (rule === "RULE123_3A") {
      return "Verlängerung 123-Regel Pkt 3 (Kofi-Faktor und Pullback Var.1)";
    }
    if (rule === "RULE123_3B") {
      return "Verlängerung 123-Regel Pkt 3b (Kofi-Faktor und Pullback Var.2)";
    }
    if (rule === "RULE123_3C") {
      return "Verlängerung 123-Regel Pkt 3c (Extrem-Level / Fib-Ratio)";
    }
    if (rule === "RULE123_3") {
      return "Verlängerung nach ursprünglichen Fib-Regeln";
    }
    return rule;
  }
}

import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { matchesPosition, Rectangle } from "@/anfin-chart/geometry";
import type { Deletable } from "@/anfin-chart/interactions";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { SubscriptionManager } from "@/anfin-chart/subscription";
import { BehaviorSubject, distinctUntilChanged, Observable, type Observer, startWith } from "rxjs";

export abstract class ChartArea implements Deletable {

  public static readonly defaultPosition = new Rectangle(0, 0, 0, 0);

  public readonly isVisible = new BehaviorSubject(true);
  protected readonly subscriptionManager = new SubscriptionManager();
  private readonly position = new BehaviorSubject(ChartArea.defaultPosition);

  constructor(public readonly layer: ChartLayer) {
    layer.registerArea(this);
    this.subscriptionManager.subscribe(this.getPositionObservable(), () => this.layer.requireDraw());
  }

  public get chart() {
    return this.layer.chart;
  }

  public get drawer() {
    return this.layer.drawer;
  }

  public getPosition() {
    return this.position.value;
  }

  public getPositionObservable() {
    return this.position.pipe(
      distinctUntilChanged((last, current) => matchesPosition(last, current)),
      startWith(this.position.value)
    );
  }

  public getIsVisible() {
    return this.isVisible.value;
  }

  public getIsVisibleObservable() {
    return this.isVisible.pipe(distinctUntilChanged(), startWith(this.isVisible.value));
  }

  public setIsVisible(isVisible: boolean) {
    this.isVisible.next(isVisible);
    this.layer.requireDraw();
  }

  public onDelete() {
    this.layer.unregisterArea(this);
    this.subscriptionManager.unsubscribeAll();
  }

  public resize() {
    this.position.next(this.resizeInternal());
  }

  public draw() {
    if (!this.getIsVisible()) {
      return;
    }
    this.drawer.clipped(this.getPosition(), () => {
      try {
        this.drawInternal();
      } catch (e) {
        console.error(e);
      }
    });
  }

  protected subscribeOn<T>(observable: Observable<T>, observer: Observer<T> | ((t: T) => void)) {
    this.subscriptionManager.subscribe(observable, observer);
  }

  protected setVisibleOnSubChart(subChart: SubChart) {
    subChart.getIsExpandedObservable().subscribe(e => this.setIsVisible(e));
  }

  public abstract initializeEvents(): void;

  protected abstract resizeInternal(): Rectangle;

  protected abstract drawInternal(): void;
}

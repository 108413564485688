import type { Chart } from "@/anfin-chart/chart";
import type { ChartExport } from "@/anfin-chart/export";
import { Instrument } from "@/anfin-chart/instrument";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { type Consumer, Debouncer } from "@/anfin-chart/utils";
import { LayoutController } from "@/api/layout-controller";
import type { TickData } from "@/api/messages/tick-update";
import { ChartLayout, ChartLayoutDefinition, ChartLayoutItem } from "@/api/models/chart-layout";
import { actionHistoryStore, ChartLayoutAction } from "@/stores/action-history-store";
import { chartObjectStore } from "@/stores/chart-object-store";
import { chartOptionStore } from "@/stores/chart-option-store";
import { userRightStore } from "@/stores/user-right-store";
import { ChartHandler } from "@/utils/ChartHandler";
import { defineStore } from "pinia";
import type { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import { ChartBroadcaster } from "@/utils/broadcast";

const layoutDefinitions: ChartLayoutDefinition[] = [
  new ChartLayoutDefinition(0, "ChartLayoutOne", [
    new ChartLayoutItem(1, 1, 1, 1)
  ]),
  new ChartLayoutDefinition(1, "ChartLayoutTwo", [
    new ChartLayoutItem(1, 1, 1, 1),
    new ChartLayoutItem(1, 1, 2, 2)
  ]),
  new ChartLayoutDefinition(2, "ChartLayoutThree", [
    new ChartLayoutItem(1, 1, 1, 1),
    new ChartLayoutItem(1, 1, 2, 2),
    new ChartLayoutItem(2, 2, 1, 2)
  ]),
  new ChartLayoutDefinition(3, "ChartLayoutFour", [
    new ChartLayoutItem(1, 2, 1, 2),
    new ChartLayoutItem(1, 1, 3, 3),
    new ChartLayoutItem(2, 2, 3, 3)
  ]),
  new ChartLayoutDefinition(4, "ChartLayoutFive", [
    new ChartLayoutItem(1, 3, 1, 2),
    new ChartLayoutItem(1, 1, 3, 3),
    new ChartLayoutItem(2, 2, 3, 3),
    new ChartLayoutItem(3, 3, 3, 3)
  ]),
  new ChartLayoutDefinition(5, "ChartLayoutSix", [
    new ChartLayoutItem(1, 1, 1, 1),
    new ChartLayoutItem(1, 1, 2, 2),
    new ChartLayoutItem(2, 2, 1, 1),
    new ChartLayoutItem(2, 2, 2, 2)
  ])
];

export const multiChartStore = defineStore({
  id: "multiChart",

  state() {
    setTimeout(() => initializeStore());
    return {
      idCounter: 0,
      instrument: Instrument.default,
      timeframe: Timeframe.default,
      hasInitialInstrument: false,
      hasInitialTimeframe: false,
      isExternalUpdate: false,
      layoutDefinitions: layoutDefinitions,
      currentLayoutDefinition: layoutDefinitions[0],
      cachedChartExports: [] as ChartExport[],
      saveLayoutDebouncer: new Debouncer(500),
      charts: [] as Chart[],
      activeChart: null as Chart | null,
      currentAction: ""
    };
  },

  actions: {
    generateChartId() {
      return this.idCounter++;
    },

    setInitialSymbol(symbol: string) {
      if (this.charts.length === 0) {
        this.instrument = Instrument.fromSymbol(symbol);
        this.hasInitialInstrument = true;
      } else {
        console.error("Initial symbol should always be set before chart initialisation");
      }
    },

    setInitialTimeframe(timeframe: string) {
      this.timeframe = Timeframe.fromShortNotation(timeframe);
      this.hasInitialTimeframe = true;
    },

    focusInstrumentSearch() {
      setTimeout(() => {
        const element = document.getElementById("searchbar-filter-list");
        if (element?.firstElementChild?.lastElementChild) {
          (element.firstElementChild.lastElementChild as HTMLElement).focus();
        }
      });
    },

    addChart(chart: Chart) {
      const index = this.charts.length;
      this.charts.push(chart);
      chart.setLinkButtonEnabled(this.currentLayoutDefinition.items.length > 1);
      const chartExports = this.cachedChartExports;
      if (index < chartExports.length) {
        const chartExport = chartExports[index];
        this.applyChartExport(chart, chartExport);
      } else {
        let instrument: Instrument;
        let timeframe: Timeframe;
        if (index === 0) {
          instrument = this.instrument;
          timeframe = this.timeframe;
        } else {
          const firstChart = this.charts[0];
          const instrumentData = firstChart.getMainInstrumentData();
          instrument = instrumentData.instrument;
          timeframe = instrumentData.timeframe;
        }
        chart.setInitialInstrument(instrument, timeframe);
        if (this.cachedChartExports.length > 0) {
          this.saveLayout();
        }
      }
      if (this.activeChart == null) {
        this.setActiveChart(chart);
      }
    },

    removeChart(chart: Chart) {
      const index = this.charts.indexOf(chart);
      if (index === -1) {
        return;
      }
      this.charts.splice(index, 1);
      if (this.activeChart === chart) {
        this.setActiveChart(this.charts.length > 0 ? this.charts[0] as Chart : null);
      }
      if (this.charts.length === 1) {
        this.charts[0].setIsLinkedSplitChart(true);
      }
    },

    setActiveChart(chart: Chart | null) {
      this.activeChart = chart;
      if (chart != null) {
        const instrumentData = chart.getMainInstrumentData();
        this.instrument = instrumentData.instrument;
        this.timeframe = instrumentData.timeframe;
      }
    },

    getChartExports() {
      return this.cachedChartExports.slice(0, this.charts.length);
    },

    getLinkedCharts() {
      return this.charts.filter(c => c.getIsLinkedSplitChart()) as Chart[];
    },

    asExternalUpdate(action: Consumer<void>, chart: Chart | null = null) {
      const charts = chart == null ? this.charts : [chart];
      this.isExternalUpdate = true;
      charts.forEach(c => c.setExternalUpdate(true));
      action();
      charts.forEach(c => c.setExternalUpdate(false));
      this.isExternalUpdate = false;
    },

    changeInstrument(instrument: Instrument) {
      const activeChart = this.activeChart;
      if (activeChart == null) {
        return;
      }
      activeChart.addInstrument(instrument, false);
      const isLinked = activeChart.getIsLinkedSplitChart();
      if (isLinked) {
        if (chartOptionStore().optionManager.synchronizeInstrument.getValue()) {
          ChartBroadcaster.getInstance().changeInstrument(instrument);
        }
        this.onInstrumentBroadcast(instrument);
      }
    },

    onInstrumentChange(chart: Chart) {
      if (chart === this.activeChart) {
        const instrumentData = chart.getMainInstrumentData();
        this.instrument = instrumentData.instrument;
      }
      multiChartStore().saveLastView(chart);
    },

    onInstrumentBroadcast(instrument: Instrument) {
      for (const chart of this.getLinkedCharts()) {
        chart.addInstrument(instrument, false);
      }
    },

    onTimeframeChange(chart: Chart) {
      if (chart === this.activeChart) {
        const instrumentData = chart.getMainInstrumentData();
        this.timeframe = instrumentData.timeframe;
      }
      multiChartStore().saveLastView(chart);
    },

    onMouseMove(chart: Chart, time: number | null, price: number | null) {
      const instrument = chart.getMainInstrumentData().instrument;
      ChartBroadcaster.getInstance().onMouseMove(instrument, time, price);
      this.onMouseMoveBroadcast(instrument, time, price, chart);
    },

    onMouseLeave() {
      for (const chart of this.charts) {
        chart.setIsSynchronized(false);
      }
    },

    onMouseMoveBroadcast(instrument: Instrument, time: number | null, price: number | null,
                         except: Chart | null = null) {
      this.asExternalUpdate(() => {
        for (const chart of this.charts) {
          if (chart === except) {
            continue;
          }
          const instrumentData = chart.getMainInstrumentData();
          const isSynchronized = Instrument.isSame(instrument, instrumentData.instrument);
          chart.setIsSynchronized(isSynchronized);
          if (isSynchronized) {
            chart.setMousePosition(time, price);
          }
        }
      });
    },

    saveLastView(chart: Chart) {
      const instrumentData = chart.getMainInstrumentData();
      ChartHandler.getInstance().saveLastView(instrumentData.instrument, instrumentData.timeframe);
    },

    setLayoutDefinition(layoutDefinition: ChartLayoutDefinition) {
      this.currentLayoutDefinition = layoutDefinition;
      for (const chart of this.charts) {
        chart.setLinkButtonEnabled(layoutDefinition.items.length > 1);
      }
    },

    applyLayout(layout: ChartLayout) {
      this.cachedChartExports = layout.charts;
      let count: number;
      if (userRightStore().isSmartPhone) {
        count = Math.min(this.charts.length, 1);
      } else {
        count = Math.min(this.charts.length, layout.charts.length);
        const layoutDefinition = this.layoutDefinitions.find(l => l.id === layout.id);
        if (layoutDefinition != null) {
          this.setLayoutDefinition(layoutDefinition);
        }
      }
      for (let i = 0; i < count; i++) {
        const chart = this.charts[i] as Chart;
        const chartExport = layout.charts[i];
        this.applyChartExport(chart, chartExport);
        chart.setLinkButtonEnabled(this.currentLayoutDefinition.items.length > 1);
      }
    },

    applyChartExport(chart: Chart, chartExport: ChartExport) {
      const action = () => {
        chart.setExport(chartExport);
        if (chart.getIsLinkedSplitChart()) {
          const firstLinkedChart = this.getLinkedCharts().find(c => c !== chart);
          const linkedInstrument = firstLinkedChart?.getMainInstrumentData().instrument;
          if (linkedInstrument != null && linkedInstrument.getSymbol() !== "") {
            chart.addInstrument(linkedInstrument, false);
          }
        }
      };
      chartObjectStore().onIndicatorDefinitionsLoaded(() => {
        this.asExternalUpdate(action, chart);
      });
    },

    saveLayout() {
      const chartExports = this.charts.map(c => c.getExport());
      for (let i = 0; i < chartExports.length; i++) {
        const chartExport = chartExports[i];
        if (this.cachedChartExports.length > i) {
          this.cachedChartExports[i] = chartExport;
        } else {
          this.cachedChartExports.push(chartExport);
        }
      }
      this.saveLayoutDebouncer.execute(() => {
        const layout = new ChartLayout(this.currentLayoutDefinition.id, this.cachedChartExports);
        const action = new ChartLayoutAction(layout);
        actionHistoryStore().addAction(action);
        LayoutController.getInstance().saveLayout(layout);
      });
    },

    setTimeframe(timeframe: Timeframe) {
      const chart = multiChartStore().activeChart;
      if (chart != null) {
        chart.setTimeframe(timeframe);
      }
    },

    updatePrice(symbol: string, tickData: TickData) {
      const instrument = Instrument.fromSymbol(symbol);
      for (const chart of this.charts) {
        chart.updatePrice(instrument, tickData);
      }
    },

    async loadLayout() {
      const layout = await LayoutController.getInstance().getLayout();
      if (this.hasInitialInstrument || this.hasInitialTimeframe) {
        for (const chartExport of layout.charts) {
          for (const subChartExport of chartExport.subCharts) {
            for (const instrumentExport of subChartExport.instruments) {
              if (this.hasInitialInstrument) {
                instrumentExport.instrument = this.instrument;
              }
              if (this.hasInitialTimeframe) {
                instrumentExport.timeframe = this.timeframe;
              }
            }
          }
        }
      }
      this.hasInitialInstrument = false;
      this.hasInitialTimeframe = false;
      this.applyLayout(layout);
      const action = new ChartLayoutAction(layout);
      actionHistoryStore().addBaseAction(action);
    },

    addTool(type: string, options: ChartOptionDefinition[] = [], key = type) {
      this.activeChart?.setPendingTool(type, options, key);
    }
  }
});

async function initializeStore() {
  const store = multiChartStore();
  store.loadLayout();
  chartOptionStore();
}

<template>
  <div class="left-toolbar">
    <ToolbarItem v-for="item in items" :key="item.key" :item="item" />
  </div>
</template>

<script lang="ts">
import type { ChartObjectPreset } from "@/api/models/user-settings/chart";
import { multiChartStore } from "@/stores/multi-chart-store";
import { ChartHandler } from "@/utils/ChartHandler";
import ToolbarItem from "@/views/toolbar/ToolbarItem.vue";
import { ToolbarItemData } from "@/views/toolbar/toolbar-item-data";
import { onKeyStroke } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { defineComponent, watch } from "vue";
import { UserToolController } from "@/api/user-tool-controller";
import { favoriteStore } from "@/stores/favorite-store";
import { presetStore } from "@/stores/preset-store";

export default defineComponent({
  name: "LeftToolbar",

  components: { ToolbarItem },

  expose: [],

  data() {
    const { toolPresets } = storeToRefs(presetStore());
    const userToolControllerInstance = UserToolController.getInstance();
    return {
      chartHandler: ChartHandler.getInstance(),
      items: userToolControllerInstance.createDefaultItems(this, false),
      toolPresets: toolPresets as unknown as ChartObjectPreset[]
    };
  },

  watch: {
    toolPresets(presets: ChartObjectPreset[]) {
      const userToolControllerInstance = UserToolController.getInstance();
      const remainingItems = userToolControllerInstance.createDefaultItems(this, false);
      this.items = remainingItems.slice();
      while (remainingItems.length > 0) {
        const [item] = remainingItems.splice(0, 1);
        remainingItems.push(...item.subItems);
        const itemType = item.key.split("#")[1];
        const matchingPresets = presets.filter(p => p.type === itemType);
        for (const preset of matchingPresets) {
          const presetKey = favoriteStore().presetPrefix + preset.id;
          const onSelect = () => multiChartStore().addTool(preset.type, preset.options, presetKey);
          const favoriteKey = favoriteStore().toolPrefix + preset.type + "#" + presetKey;
          const presetItem = new ToolbarItemData(presetKey, preset.name, item.icon, onSelect, null, null, favoriteKey);
          item.addSubItem(presetItem);
        }
      }
    }
  },

  created() {
    watch(
      () => multiChartStore().currentAction,
      value => this.setSelection(value)
    );
  },

  mounted() {
    onKeyStroke(["Escape"], (e) => {
      if (e.key === "Escape") {
        UserToolController.getInstance().setDefaultMode();
      }
    });
  },

  methods: {
    setSelection(key: string) {
      const items = this.items.slice();
      let index = 0;
      let matchingItem = items[0];
      while (index < items.length) {
        const item = items[index];
        if (item.key === key) {
          matchingItem = item;
        }
        item.setSelected(false);
        items.push(...item.subItems);
        index++;
      }
      matchingItem.setSelected(true);
    }
  }
});
</script>

<style scoped>
.left-toolbar {
  background-color: var(--background-screen);
  border-right: 1px solid var(--border-neutral);
  display: flex;
  flex-direction: column;
  padding-top: 3px;
}
</style>

export class LegalDocument {

  public readonly sections: LegalDocumentSection[];

  constructor(public readonly caption: string,
              ...sections: LegalDocumentSection[]) {
    this.sections = sections;
  }
}

export class LegalDocumentSection {

  constructor(public readonly caption: string,
              public readonly paragraphs: string[],
              public readonly subSections: LegalDocumentSection[] = []) {
  }
}

export const agbDocument = new LegalDocument(
  "Allgemeine Nutzungs- und Geschäftsbedingungen der Formationstrader GmbH",
  new LegalDocumentSection(
    "I. Allgemeiner Teil",
    [],
    [
      new LegalDocumentSection(
        "1. Geltungsbereich",
        [
          "Diese allgemeinen Geschäftsbedingungen gelten für alle Verträge zwischen der Formationstrader GmbH und ihren Kunden. Zusammen mit den Produktbeschreibungen, den Anmeldungen und den Bestellungen der Kunden und der Annahmeerklärung der Formationstrader GmbH geben sie den Vertragsinhalt abschließend wieder.",
          "Hierbei gehen die Anmeldung oder Bestellung des Kunden nebst Annahmeerklärung der Formationstrader GmbH abweichenden Regelungen in der Produktbeschreibung vor, die Produktbeschreibung wiederum geht etwa abweichenden Regelungen in diesen Allgemeinen Geschäftsbedingungen vor.",
          "Das Angebot der Formationstrader GmbH richtet sich ausschließlich an Kunden, die nicht professionelle Marktteilnehmer sind. Professionelle Marktteilnehmer sind insbesondere Börsenhändler und deren Mitarbeiter, Vermögensverwaltungen, Mitarbeiter von Fonds, Anlageberater, Finanzdienstleister, Investmentbanker, Finanzmarktanalysten und -journalisten, technische Analysten sowie Anbieter von Tradingkursen und / oder sonstigen Ausbildungskursen.",
          "Etwaige Anmeldungen oder Bestellungen von professionellen Marktteilnehmern werden ohne vorherige Mitteilung storniert, das gilt auch für in Unkenntnis von der Formationstrader GmbH bereits angenommene Anmeldungen oder Bestellungen."
        ]
      ),
      new LegalDocumentSection(
        "2. Vertragsschluss",
        [
          "Anmeldungen zu Veranstaltungen und Bestellungen von Dienstleistungen erfolgen ausschließlich online über www.formationstrader.de.",
          "Mit Absenden der Anmeldung bzw. Bestellung bietet der Kunde den Abschluss eines Vertrages zur Teilnahme an der jeweiligen in der Produktbeschreibung bezeichneten Veranstaltung bzw. zum Bezug einer sonstigen Leistung der Formationstrader GmbH verbindlich an.",
          "Die Formationstrader GmbH bestätigt den Eingang dieses Antrags umgehend per E-Mail. In der Eingangsbestätigung liegt noch keine Annahmeerklärung. Das Angebot bedarf der gesonderten Annahme durch die Formationstrader GmbH. Erst mit Annahme des Angebots kommt ein Vertrag zustande. Die Annahme erfolgt seitens der Formationstrader GmbH durch Erklärung per E-Mail.",
          "Mündliche Nebenabreden bestehen zum Zeitpunkt des Vertragsschlusses nicht.",
          "Nebenabreden und Vertragsänderungen, dazu zählt die Aufhebung des Vertrags, bedürfen nach Vertragsschluss der Textform. Das gilt auch für die Aufhebung des Textformerfordernisses."
        ]
      ),
      new LegalDocumentSection(
        "3. Zahlungsbedingungen, Rabatte",
        [
          "Die vertragliche Vergütung wird grundsätzlich mit Abschluss des Vertrags fällig, jedoch nach Maßgabe der folgenden Regelungen:",
          "Bei Zahlung mittels Kreditkarte erfolgt die Belastung der Kreditkarte umgehend. Die Zahlungsabwicklung erfolgt über den Dienstleister Billwerk+ Germany GmbH, Mainzer Landstraße 51, 60329 Frankfurt am Main. Es gelten dessen Nutzungsbedingungen (vgl. https://www.billwerk.plus/de/av-vertrag/).",
          "Bei Zahlung mittels SEPA-Lastschriftmandat erfolgt die Belastung des angegebenen Kontos in der Regel innerhalb von drei Bankarbeitstagen über den Dienstleister Billwerk+ Germany GmbH, Mainzer Landstraße 51, 60329 Frankfurt am Main. Es gelten dessen Nutzungsbedingungen (vgl. https://www.billwerk.plus/de/av-vertrag/).",
          "Bei Zahlung auf Rechnung ist die vertragliche Vergütung für vertragliche Leistungen der Formationstrader GmbH erst mit Zugang der Rechnung fällig und innerhalb von 10 Tagen ab Rechnungsdatum und Zugang der Rechnung zu zahlen, soweit keine Ratenzahlungsvereinbarung (s.u.) getroffen wurde.",
          "",
          "Rabattgewährung bei Eröffnung eines Kontos bei der JFD für Abonnementangebote",
          "Die Formationstrader GmbH gewährt im Rahmen einer Kooperation mit der JFD (JFD Group Ltd, Kakos Premier Tower, Kyrillou Loukareos 70, 4156 Limassol, Cyprus) Rabatte bzw. Befreiungen von der vertraglichen Vergütung für ihre Abonnementleistungen. Höhe und konkrete Details ergeben sich aus dem konkreten Angebot hierzu.",
          "Der gewährte Rabatt bzw. die Befreiung von der Vergütungspflicht entfällt rückwirkend, wenn eindeutige Täuschungsversuche des Kunden vorliegen, insbesondere bei kurzfristigem Rückbuchen eingezahlter Beträge auf das eröffnete Live-Konto. Ein bereits gewährter Rabatt entfällt ebenfalls rückwirkend, soweit bei diesem bei der JFD eröffneten Konto ein Inaktivitätszeitraum von 3 Monaten überschritten wird. In diesem Fall sind die JFD bzw. die Formationstrader GmbH berechtigt, den Betrag in Höhe des gewährten Rabatts vom Klienten einzufordern. Der Betrag wird zurückgeleistet, wenn eine Aktivität des Kontos wieder festgestellt wird."
        ]
      ),
      new LegalDocumentSection(
        "4. Storno, Rücktritt und Nichtteilnahme an Leistungen",
        [
          "Der Kunde kann seine Bestellung oder Anmeldung bis zum Eingang seiner Eingangsbestätigung durch die Formationstrader GmbH gegenüber dieser per Brief oder E-Mail stornieren, ohne dass hierfür Kosten entstehen. Erfolgt eine Stornierung nach der Übersendung der Eingangsbestätigung durch die Formationstrader GmbH und vor der Übersendung der Annahmeerklärung, ist eine Aufwandsentschädigung in Höhe von 100,00 € zu entrichten, sofern der Kunde nicht einen geringeren Aufwand nachweist. Bei einer Stornierung der Teilnahme nach der Übersendung der Annahmeerklärung aber mindestens 7 Tage vor Veranstaltungsbeginn werden 50% der vollen vertraglichen Vergütung fällig. Erfolgt die Stornierung innerhalb der 7 Tage vor Veranstaltungsbeginn, so steht der Formationstrader GmbH 100% der vertraglichen Vergütung zu.",
          "Die vorstehende Regelung gilt nicht, sofern der Kunde fristgemäß und wirksam ein etwaiges gesetzliches Widerrufsrecht gem. §§ 356 ff BGB ausübt.",
          "Kann der Kunde nach Vertragsschluss nicht die Leistung persönlich nutzen, so hat er das Recht, eine Ersatzperson zu benennen, die an seiner statt die Leistung nutzt. Die Formationstrader GmbH kann der Teilnahme der Ersatzperson widersprechen, wenn die Ersatzperson die Anforderungen für die Teilnahme nicht erfüllt. Nimmt eine Ersatzperson teil, haftet sie gemeinsam mit dem Teilnehmer für die Vergütung.",
          "Der Kunde ist auch bei Nichtteilnahme zur Zahlung des vollständigen vertraglichen Entgelts verpflichtet."
        ]
      ),
      new LegalDocumentSection(
        "5. Laufzeit und Kündigung von Abonnements",
        [
          "Die Laufzeit von Abonnements ist in den jeweiligen Produktbeschreibungen im Onlineshop angegeben.",
          "Abonnements sind von beiden Vertragspartnern jeweils entsprechend der Regelungen in den Produktbeschreibungen kündbar.",
          "Die Kündigung hat unter  Verwendung der Kündigungsfunktion im Internetauftritt („Kündigungsbutton“ gem. § 312k BGB) oder schriftlich per Brief oder E-Mail gegenüber der Formationstrader GmbH zu erfolgen. Maßgeblich für die genannte Frist ist der Zugang der Kündigung beim Kündigungsempfänger.",
          "Die gesetzlichen Möglichkeiten zur fristlosen Kündigung aus wichtigem Grund bleiben unberührt."
        ]
      ),
      new LegalDocumentSection(
        "6. Haftung und Haftungsausschluss",
        [
          "Für den Fall der fahrlässigen Verletzung von vertragswesentlichen Pflichten ist die Haftung der Formationstrader GmbH auf die vertraglich vereinbarte Vergütung beschränkt. Im Übrigen ist eine Haftung der Formationstrader GmbH ausgeschlossen.",
          "Die Formationstrader GmbH haftet in keinem Fall für nicht vorhersehbare Schäden, mangelnden wirtschaftlichen Erfolg, mittelbare Schäden und Folgeschäden – insbesondere Vermögensschäden – sowie Schäden aus Ansprüchen Dritter, soweit eine derartige Haftung gesetzlich nicht zwingend vorgeschrieben ist, also insbesondere bei Vorsatz.",
          "Vorstehende Haftungsfreizeichnung gilt nicht für Personenschäden oder wenn die Schadensursache auf grob fahrlässigem oder vorsätzlichem Verhalten beruht oder sich eine Haftung aus dem ProdHaftG, aus Garantie, oder anderen nicht abdingbaren gesetzlichen Vorschriften ergibt.",
          "Die im Rahmen aller Leistungen der Formationstrader GmbH übermittelten Informationen, Analysen, Marktkommentare, Handelssignale und Transaktionen stellen keine Beratung im Sinne des Wertpapierhandelsgesetzes dar. Sie stellen kein Angebot und keine Aufforderung zum Kauf, Halten oder Verkauf von Finanzinstrumenten dar. Sie berücksichtigen nicht die spezielle Situation des Empfängers im Hinblick auf dessen Anlageziele und Risikoneigung. Ferner stellen sie keine Finanzanalyse im Sinne des Wertpapierhandelsgesetzes dar und genügen auch nicht den gesetzlichen Anforderungen zur Gewährleistung der Unvoreingenommenheit von Finanzanalysen, sie geben ausschließlich die Ansicht der Mitarbeiter der Formationstrader GmbH wieder, dies unabhängig von dem genauen Wortlaut. Mitarbeiter der Formationstrader GmbH sind niemals für tatsächliche Transaktionen des Nutzers verantwortlich zu machen, zumal die Mitarbeiter der Formationstrader GmbH im Einzelfall bei behandelten Anlagemöglichkeiten selbst investiert sein können. Eine Haftung für Anlageentscheidungen der Kunden ist unter allen Umständen ausgeschlossen."
        ]
      ),
      new LegalDocumentSection(
        "7. Vertraulichkeit und Urheberrecht",
        [
          "Die im Rahmen aller Leistungen der Formationstrader GmbH übermittelten Informationen, Analysen und Marktkommentare sind urheberrechtlich geschützt. Sämtliche Rechte hieraus sind der Formationstrader GmbH vorbehalten. Eine Vervielfältigung, Verbreitung, Bearbeitung, gewerbliche Nutzung oder öffentliche Wiedergabe bedarf der vorherigen schriftlichen Genehmigung der Formationstrader GmbH.",
          "Den Kunden ist es nicht erlaubt, ihre Zugangsdaten Dritten, die nicht ebenfalls in demselben Umfang Vertragspartner der Formationstrader GmbH sind, zur Nutzung zu überlassen. Im Falle der Feststellung eines Verstoßes ist die Formationstrader GmbH berechtigt, den Vertrag mit dem Kunden fristlos zu kündigen und Schadensersatz geltend zu machen."
        ]
      ),
      new LegalDocumentSection(
        "8. Erfüllungsort",
        [
          "Erfüllungsort für sämtliche Leistungen der Formationstrader GmbH ist Lübeck."
        ]
      )
    ]
  ),
  new LegalDocumentSection(
    "II. Besondere Bedingungen für Angebote unter Einschluss von Präsenzveranstaltungen",
    [
      "Soweit Leistungen der Formationstrader GmbH auch Präsenzveranstaltungen umfassen, beispielsweise Seminare im Rahmen des Mentorprogramms, handelt es sich bei diesen Präsenzveranstaltungen lediglich um einzelne Bestandteile einer Gesamtleistung.",
      "Der Kunde kann mit Absenden seiner Anmeldung bzw. Bestellung eine der nachfolgend dargestellten Ratenzahlungsmöglichkeiten wählen: Dem Teilnehmer wird danach nachgelassen,",
      "a) 50 % der vertraglichen Vergütung innerhalb von 14 Tagen nach Erhalt der Rechnung und nachfolgend die verbleibende Vergütung in monatlichen Raten zu je 100,00 € zu zahlen, wobei diese jeweils spätestens am 3. Werktag eines Monats zur Zahlung fällig sind,",
      "b) 50 % der vertraglichen Vergütung innerhalb von 14 Tagen nach Erhalt der Rechnung und 50 % bis spätestens 14 Tage vor Veranstaltungsbeginn zu bezahlen oder",
      "c) die vertragliche Vergütung in drei gleichbleibenden Raten zu zahlen, wobei die erste Rate 14 Tage nach Rechnungserhalt, die zweite Rate eine Woche vor Beginn der Veranstaltung und die dritte Rate 3 Monate nach Abschluss der Veranstaltung zu zahlen ist.",
      "Der Zahlungseingang der vollständigen vertraglichen Vergütung bzw. bei Wahl der Ratenzahlungsmöglichkeit im Sinne von Ziffer 5.5. a), b) oder c) in Höhe des bis zum Leistungsbeginn fälligen Betrages ist Voraussetzung für die Erbringung von Leistungen durch die Formationstrader GmbH. Ist die Zahlung nicht fristgerecht erfolgt, kann der Kunde jederzeit von der Teilnahme ausgeschlossen werden: Der vertragliche Vergütungsanspruch bleibt jedoch bestehen.",
      "Die Vergütung für Abonnements ist entsprechend der Produktbeschreibung zu zahlen und wird jeweils von der Formationstrader GmbH über den Dienstleister Stripe (vgl. dazu oben unter Ziffer 5.2.) innerhalb von drei Bankarbeitstagen nach Beginn oder Verlängerung der Mindesvertragslaufzeit vom Konto des Kunden abgebucht.",
      "Die Formationstrader GmbH weist darauf hin, dass hinsichtlich derartiger Leistungen wegen § 312g Abs. 2 Nr. 9 BGB ein gesetzliches Widerrufsrecht des Kunden nicht besteht – unbeschadet etwa seitens der Formationstrader GmbH freiwillig eingeräumter Widerrufsmöglichkeiten.",
      "",
      "An- und Abreise der Kunden zu Präsenzveranstaltungen geschehen auf eigene Gefahr. Die Haftung der Formationstrader GmbH für Personen- und Sachschäden, die bei der An- und Abreise zu sowie am Veranstaltungsort außerhalb der Veranstaltung entstehen, ist ausgeschlossen. Ebenso ist die Haftung ausgeschlossen für Unfälle und Beschädigungen, Verlust oder Diebstahl mitgebrachter Gegenstände und Fahrzeuge am Veranstaltungsrort soweit eine derartige Haftung gesetzlich nicht zwingend vorgeschrieben ist, also insbesondere bei Vorsatz.",
      "",
      "Soweit am Ort der Präsenzveranstaltung Hotelkontingente vorgehalten werden, handelt sich hierbei um Angebote des Betreibers des Veranstaltungslokals, die nicht Bestandteil sind des Vertrags zwischen der Formationstrader GmbH und dem Kunden und für die daher keinerlei Haftung der Formationstrader GmbH in Betracht kommt.",
      "Wird es aus Gründen, die von der Formationstrader GmbH nicht zu vertreten sind, erforderlich, eine Präsenzveranstaltung zu verlegen, so berührt dies den Vergütungsanspruch der Formationstrader GmbH zunächst nicht, vielmehr ist die Formationstrader GmbH berechtigt, dem Kunden die verlängerte Nutzung der sonstigen Leistungsbestandteile kostenfrei zu ermöglichen und die Präsenzveranstaltung unverzüglich nachzuholen.",
      "Das Recht des Kunden zur Kündigung aus wichtigem Grund bleibt unberührt.",
      "Gründe, die von der Formationstrader GmbH nicht zu vertreten sind, sind beispielsweise behördliche Anordnungen nach dem Infektionsschutzgesetz oder Erkrankungen des Referenten.",
      "Änderungen des Veranstaltungsinhalts, des Veranstaltungsortes und eine Auswechslung des Referenten sind zulässig, solange der wesentliche Inhalt der Veranstaltung hierdurch nicht berührt wird und sie dem Kunden zumutbar sind."
    ]
  ),
  new LegalDocumentSection(
    "III. Bedingungen für die Asset-Analyse / Invest-Report",
    [
      "a) Produktbeschreibung",
      "Die Asset-Analyse von Aktien bzw. Invest-Report (im Folgenden \"Analyse\") ist ein Produkt, das den Kunden der Formationstrader GmbH eine detaillierte Untersuchung und Bewertung ausgewählter Aktienmärkte bietet.",
      "b) Zugang zur Analyse",
      "Nach erfolgreicher Zahlung der Einmalgebühr erhält der Kunde das nicht-ausschließliche, nicht-übertragbare Recht, auf die Analyse zuzugreifen.",
      "c) Nutzungsrechte",
      "Die Nutzung der Analyse ist ausschließlich für den persönlichen Gebrauch des Kunden gestattet.",
      "d) Zahlungsbedingungen",
      "Die Einmalzahlung für die Analyse wird mit Vertragsschluss fällig und ist gemäß den unter Abschnitt I.3 aufgeführten Zahlungsmethoden zu entrichten.",
      "e) Stornierung und Rückerstattung",
      "Aufgrund der sofortigen Verfügbarkeit und der Art der digitalen Inhalte besteht kein Widerrufsrecht.",
      "f) Haftungsausschluss",
      "Die in der Analyse bereitgestellten Informationen dienen nur zu Informationszwecken und stellen keine Anlageberatung dar.",
      "g) Änderungsvorbehalt",
      "Die Formationstrader GmbH behält sich das Recht vor, die Bedingungen für die Asst-Analyse von Aktien bzw. Invest-Report jederzeit zu ändern."
    ]
  )
);

export const imprintDocument = new LegalDocument(
  "Haftungsausschluss",
  new LegalDocumentSection(
    "1. Inhalte",
    [
      "Als Dienstanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach § 8 bis § 10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen."
    ]
  ),
  new LegalDocumentSection(
    "2. Links",
    [
      "Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen."
    ]
  ),
  new LegalDocumentSection(
    "3. Urheberrecht",
    [
      "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht für kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Darüber hinaus werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen."
    ]
  )
);

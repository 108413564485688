export class Point {

  constructor(public readonly x: number,
              public readonly y: number) {
  }
}

export class Vector {

  constructor(public x: number,
              public y: number) {
  }

  public get length() {
    return Math.sqrt(this.x ** 2 + this.y ** 2);
  }

  public static from(start: Point, end: Point) {
    return new Vector(end.x - start.x, end.y - start.y);
  }

  public asScaled(multiplier: number) {
    return new Vector(this.x * multiplier, this.y * multiplier);
  }

  public asLength(length: number) {
    const multiplier = this.length === 0 ? 1 : length / this.length;
    return this.asScaled(multiplier);
  }

  public addTo(point: Point) {
    return new Point(point.x + this.x, point.y + this.y);
  }
}

export class Size {

  constructor(public readonly width: number,
              public readonly height: number) {
  }

  public withPadding(horizontal: number, vertical: number) {
    return new Size(this.width + 2 * horizontal, this.height + 2 * vertical);
  }
}

export class Range {

  public size: number;

  constructor(public readonly start: number,
              public readonly end: number) {
    this.size = end - start;
  }
}

export class Polygon {

  public readonly points: Point[];

  constructor(...points: Point[]) {
    this.points = points;
  }
}

export class Rectangle {

  constructor(public readonly xStart: number,
              public readonly yStart: number,
              public readonly xEnd: number,
              public readonly yEnd: number) {
  }

  public get width() {
    return this.xEnd - this.xStart;
  }

  public get height() {
    return this.yEnd - this.yStart;
  }

  public asRounded() {
    return new Rectangle(
      Math.floor(this.xStart),
      Math.floor(this.yStart),
      Math.ceil(this.xEnd),
      Math.ceil(this.yEnd)
    );
  }
}

export function isWithin(rect: Rectangle, point: Point, tolerance = 0) {
  const xStart = rect.xStart - tolerance;
  const xEnd = rect.xEnd + tolerance;
  const yStart = rect.yStart - tolerance;
  const yEnd = rect.yEnd + tolerance;
  return point.x >= xStart && point.x <= xEnd && point.y >= yStart && point.y <= yEnd;
}

export function matchesPosition(first: Rectangle, second: Rectangle) {
  return first.xStart === second.xStart &&
    first.xEnd === second.xEnd &&
    first.yStart === second.yStart &&
    first.yEnd === second.yEnd;
}

export function getDistance(first: Point, second: Point) {
  return Math.sqrt((first.x - second.x) ** 2 + (first.y - second.y) ** 2);
}

export function getLineDistance(start: Point, end: Point, point: Point, useSegment = false) {
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const t = ((point.x - start.x) * dx + (point.y - start.y) * dy) / (dx * dx + dy * dy);
  let closestPoint: Point;
  if (!useSegment || t >= 0 && t <= 1) {
    closestPoint = { x: start.x + t * dx, y: start.y + t * dy };
  } else if (t < 0) {
    closestPoint = start;
  } else {
    closestPoint = end;
  }
  return getDistance(point, closestPoint);
}

export function getSlope(start: Point, end: Point) {
  return (end.y - start.y) / (end.x - start.x);
}

export function getAngle(start: Point, end: Point) {
  let angle = Math.atan2(end.y - start.y, end.x - start.x);
  if (angle < 0) {
    angle += 2 * Math.PI;
  }
  return angle;
}

export function getAngleVector(angle: number, length: number) {
  return new Vector(length * Math.cos(angle), length * Math.sin(angle));
}

export function addAngleOffset(point: Point, angle: number, distance: number) {
  const vector = getAngleVector(angle, distance);
  return vector.addTo(point);
}

export function projectLineRect(start: Point, end: Point, rect: Rectangle) {
  if (start.x < end.x) {
    return projectLineToX(start, end, rect.xEnd);
  } else if (start.x > end.x) {
    return projectLineToX(start, end, rect.xStart);
  } else if (start.y < end.y) {
    return new Point(start.x, rect.yEnd);
  }
  return new Point(start.x, rect.yStart);
}

export function projectLineToX(start: Point, end: Point, x: number) {
  const slope = getSlope(start, end);
  const y = start.y + slope * (x - start.x);
  return new Point(x, y);
}

export function getIntersection(firstStart: Point, firstEnd: Point, secondStart: Point, secondEnd: Point) {
  const m1 = getSlope(firstStart, firstEnd);
  const m2 = getSlope(secondStart, secondEnd);
  if (m1 === m2 || !isFinite(m1) || !isFinite(m2)) {
    return null;
  }
  const b1 = firstStart.y - m1 * firstStart.x;
  const b2 = secondStart.y - m2 * secondStart.x;
  const x = (b2 - b1) / (m1 - m2);
  const y = m1 * x + b1;
  return new Point(x, y);
}

export function getCenterPoint(first: Point, second: Point) {
  return new Point((first.x + second.x) / 2, (first.y + second.y) / 2)
}

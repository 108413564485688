<template>
  <button type="button" class="btn btn-primary btn-sm add-button">
    <IconElement iconName="Plus" :size="14" class="me-1" />
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconElement from "@/views/icons/IconElement.vue";

export default defineComponent({
  name: "AddButton",

  components: { IconElement },

  expose: []
});
</script>

<style scoped>
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
<template>
  <div class="landing-footer">
    <div class="landing-footer-top">
      <div class="container">
        <div class="landing-footer-container">
          <AnfinLogo :style="LogoStyle.Transparent" class="landing-footer-logo anfin-logo-xs" />
          <div class="social-media">
            <a class="link footer-link" href="https://www.youtube.com/@FormationstraderGmbH" target="_blank" rel="noopener noreferrer">
              <IconElement :size="24" iconName="Youtube" />
            </a>
            <a class="link footer-link" href="https://twitter.com/Formationstrade" target="_blank" rel="noopener noreferrer">
              <IconElement :size="24" iconName="Twitter" />
            </a>
            <a class="link footer-link" href="https://www.facebook.com/Formationstrader" target="_blank" rel="noopener noreferrer">
              <IconElement :size="24" iconName="Facebook" />
            </a>
            <a class="link footer-link" href="https://www.tiktok.com/@formationstrading" target="_blank" rel="noopener noreferrer">
              <IconElement :size="24" iconName="Tiktok" />
            </a>
          </div>
        </div>
        <hr class="landing-footer-separator mb-md-4 my-3" />
        <div class="landing-footer-container">
          <div class="footer-link-group">
            <div>
              <RouterLink to="/home" class="link footer-link">
                {{ translationStore.getTranslation("landing_page#link#home") }}
              </RouterLink>
            </div>
            <div>
              <RouterLink to="/shop" class="link footer-link">
                {{ translationStore.getTranslation("landing_page#link#products") }}
              </RouterLink>
            </div>
            <div>
              <RouterLink to="/chart" class="link footer-link" target="_blank" rel="noopener noreferrer">
                {{ translationStore.getTranslation("landing_page#link#chart") }}
              </RouterLink>
            </div>
          </div>
          <div class="footer-link-group">
            <div>
              <RouterLink to="/agb" class="link footer-link">
                AGB
              </RouterLink>
            </div>
            <div>
              <PrivacyPolicyLink class="footer-link" />
            </div>
            <div>
              <RouterLink to="/imprint" class="link footer-link">
                Impressum
              </RouterLink>
            </div>
          </div>
          <div class="footer-link-group">
            <div>
              <RouterLink to="/contact" class="link footer-link">
                Kontakt
              </RouterLink>
            </div>
            <!--
            TODO folgt zeitnah"
            <div>
              <RouterLink to="/faq" class="link footer-link">
                FAQ
              </RouterLink>
            </div>
            -->
            <div>
              <RouterLink :to="{ path: '/chart', query: { action: 'terminate' } }" class="link footer-link">
                Jetzt kündigen
              </RouterLink>
            </div>
            <div>
              <a class="link footer-link" href="#" @click="openCookieBanner">
                {{ translationStore.getTranslation("open_cookie_banner") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-footer-bottom">
      <div class="container landing-footer-container">
        <div>AnFin ist eine Marke der Formationstrader GmbH</div>
        <div>Copyright © 2024 - All rights reserved</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AnfinLogo from "@/views/components/AnfinLogo.vue";
import { LogoStyle } from "@/api/models/theme";
import IconElement from "@/views/icons/IconElement.vue";
import PrivacyPolicyLink from "@/views/pages-landing/PrivacyPolicyLink.vue";
import { translationStore } from "@/stores/translation-store";
import { cookieStore } from "@/stores/cookie-store";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "LandingFooter",

  components: { PrivacyPolicyLink, IconElement, AnfinLogo, RouterLink },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      LogoStyle
    };
  },

  methods: {
    openCookieBanner() {
      cookieStore().openCookieBanner();
    }
  }
});
</script>

<style scoped>
.landing-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: auto;
}

.social-media {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
  align-self: end;
}

.landing-footer-top {
  padding: 20px 0;
  background: var(--background-primary);
  color: var(--content-primary-top);
}

.landing-footer-bottom {
  padding: 10px 0;
  background: var(--background-overlay);
  color: var(--background-primary);
}

.landing-footer-container {
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: space-between;
}

.landing-footer-separator {
  border-width: 2px;
  border-color: var(--interactive-accent);
  opacity: 0.8;
}

.footer-link-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer-link {
  color: inherit;
}

.footer-link:hover {
  color: var(--interactive-accent-hover);
}

@media (max-width: 767px) {
  .landing-footer-top {
    padding: 15px 5px;
  }

  .landing-footer-container {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .landing-footer-logo, .social-media {
    align-self: center;
  }
}

@media (max-width: 539px) {
  .landing-footer-container {
    align-items: start;
  }
}
</style>
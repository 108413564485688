<template>
  <LandingPageWrapper>
    <LandingPageSection class="flex-grow-1 py-md-5 py-4">
      <div class="landing-header">
        {{ translationStore.getTranslation("checkout#header_cart") }}
      </div>
      <div v-if="cartData == null || cartData.items.length === 0" class="mt-4">
        <div class="landing-secondary-header">
          {{ translationStore.getTranslation("checkout#cart_empty") }}
        </div>
        <RouterLink to="/shop" class="btn btn-primary mt-4">
          {{ translationStore.getTranslation("checkout#button_back_to_shop") }}
        </RouterLink>
      </div>
      <div v-else class="mt-4">
        <div class="shopping-cart-container">
          <div>
            <div v-if="cartData.hasError" class="text-error">
              {{ translationStore.getTranslation("checkout#cart_resolve_error") }}
            </div>
            <div v-for="item in cartData.items" v-else>
              <ShoppingCartItem :key="item.product.id" :item="item" :isEditable="true" />
              <hr />
            </div>
          </div>
          <CheckoutSummary
            v-if="cartData.items.length > 0" :allowNext="true" :nextLabel="nextLabel"
            class="shopping-cart-summary" @next="goToCheckout"
          />
        </div>
        <RouterLink to="/shop" class="btn btn-secondary mt-3">
          {{ translationStore.getTranslation("checkout#button_back_to_shop") }}
        </RouterLink>
      </div>
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import { translationStore } from "@/stores/translation-store";
import { paymentStore } from "@/stores/payment-store";
import { storeToRefs } from "pinia";
import ShoppingCartItem from "@/views/checkout/ShoppingCartItem.vue";
import type { ShoppingCartResolved } from "@/api/models/checkout";
import CheckoutSummary from "@/views/checkout/CheckoutSummary.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "ShoppingCartPage",

  components: { LandingPageSection, CheckoutSummary, ShoppingCartItem, LandingPageWrapper, RouterLink },

  expose: [],

  data() {
    const { currentCartData } = storeToRefs(paymentStore());
    return {
      translationStore: translationStore(),
      cartData: currentCartData as unknown as ShoppingCartResolved | null
    };
  },

  computed: {
    nextLabel() {
      return this.translationStore.getTranslation("checkout#to_checkout");
    }
  },

  created() {
    const cartId = this.$route.query.cart;
    if (cartId != null) {
      paymentStore().loadCart(cartId as string);
    }
  },

  methods: {
    goToCheckout() {
      this.$router.push({ name: "Checkout" });
    }
  }
});
</script>

<style scoped>
.shopping-cart-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: 30px;
}
</style>
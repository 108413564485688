<template>
  <LandingPageSection class="section-green section-selling-fact">
    <div class="landing-section-split selling-fact-container">
      <div>
        <div class="selling-fact-header text-gradient-gold">20+</div>
        <div class="selling-fact-primary mt-1">Jahre Erfahrung</div>
        <div class="selling-fact-secondary mt-4">Setzen Sie auf die jahrzehntelang aufgebaute Kompetenz.</div>
      </div>
      <div>
        <div class="selling-fact-header text-gradient-gold">200.000+</div>
        <div class="selling-fact-primary mt-1">Analysierte Trades</div>
        <div class="selling-fact-secondary mt-4">Nachweislich erfolgreiche Anwendung der Charttechnik.</div>
      </div>
      <div>
        <div class="selling-fact-header text-gradient-gold">2.000+</div>
        <div class="selling-fact-primary mt-1">Zufriedene Kunden</div>
        <div class="selling-fact-secondary mt-4">Positives Feedback und Höchstnoten unserer Klienten.</div>
      </div>
      <div>
        <div class="selling-fact-header text-gradient-gold">70.000+</div>
        <div class="selling-fact-primary mt-1">YouTube-Abonnenten</div>
        <div class="selling-fact-secondary mt-4">Stetig wachsender Kanal mit kostenlosen Inhalten zu Trading und Investment.</div>
      </div>
    </div>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";

export default defineComponent({
  name: "SellingFactSection",

  components: { LandingPageSection },

  expose: []
});
</script>

<style scoped>
.section-selling-fact {
  background: linear-gradient(to bottom, #003C0E 0%, #114f29 70%, #003C0E 100%);
}

.selling-fact-header {
  font-size: 230%;
  font-weight: bold;
}

.selling-fact-primary {
  font-size: 130%;
  font-weight: bold;
}

@media (max-width: 991px) {
  .selling-fact-container {
    gap: 25px;
  }
}
</style>
<template>
  <LandingPageSection :paddingTop="0" :paddingBottom="0" class="main-section">
    <div class="landing-section-split main-section-content">
      <div class="main-section-text">
        <div class="main-section-header">
          Revolutionieren Sie Ihre Analysen
        </div>
        <div class="main-section-sub-header mt-lg-4 mt-3">
          Automatisch - Präzise - Innovativ
        </div>
        <LetsGoButton class="mt-lg-5 mt-3" />
      </div>
      <HamedPortraitImage />
    </div>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import HamedPortraitImage from "@/views/pages-landing/HamedPortraitImage.vue";
import LetsGoButton from "@/views/pages-landing/LetsGoButton.vue";

export default defineComponent({
  name: "MainSection",

  components: { LetsGoButton, HamedPortraitImage, LandingPageSection },

  expose: []
});
</script>

<style scoped>
.main-section {
  padding-top: 50px;
  background-image: url("/src/assets/chart-candlesticks.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
}

.main-section-content {
  align-items: center;
}

.main-section-header {
  font-size: 250%;
  font-weight: bold;
}

.main-section-sub-header {
  font-size: 200%;
}

.main-section-text {
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 539px) {
  .main-section {
    padding-top: 20px;
  }

  .main-section-text {
    margin-bottom: 0;
  }
}
</style>
<template>
  <PageWrapper>
    <div class="settings-mobile">
      <div class="settings-nav-sidebar">
        <div
          v-for="category in categories" :key="category.id" :class="{ selected: category.id === mode }"
          class="settings-nav-sidebar-item" @click.stop="setActiveCategory(category.id)"
        >
          <div class="hint--right" :aria-label="translationStore.getTranslation('settings_mode#' + category.id)">
            <IconElement :iconName="category.icon" :size="24" />
          </div>
        </div>
      </div>
      <SettingsWindow :allowHide="false" class="flex-grow-1" />
    </div>
  </PageWrapper>
</template>

<script lang="ts">
import PageWrapper from "@/views/PageWrapper.vue";
import SettingsWindow from "@/views/settings/SettingsWindow.vue";
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import { storeToRefs } from "pinia";
import { SettingsWindowMode, uiStateStore } from "@/stores/ui-state-store";
import IconElement from "@/views/icons/IconElement.vue";

export class SettingsCategory {

  constructor(public readonly id: SettingsWindowMode,
              public readonly icon: string) {
  }
}

export default defineComponent({
  name: "SettingsMobile",

  components: { IconElement, PageWrapper, SettingsWindow },

  expose: [],

  data() {
    const { settingsWindowMode } = storeToRefs(uiStateStore());
    uiStateStore().setSettingsWindowMode(SettingsWindowMode.Chart);
    const categories = [];
    categories.push(new SettingsCategory(SettingsWindowMode.Chart, "Templates"));
    categories.push(new SettingsCategory(SettingsWindowMode.Analysis, "Analyses"));
    categories.push(new SettingsCategory(SettingsWindowMode.Account, "Account"));
    return {
      translationStore: translationStore(),
      mode: settingsWindowMode as unknown as SettingsWindowMode,
      categories,
      SettingsWindowMode
    };
  },

  methods: {
    setActiveCategory(mode: SettingsWindowMode) {
      uiStateStore().toggleSettingsWindowMode(mode);
    }
  }
});
</script>

<style scoped>
.settings-mobile {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.settings-nav-sidebar {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-right: 1px solid var(--border-overlay);
  border-left: 1px solid var(--border-overlay);
  background: var(--background-gradient);
  color: var(--content-primary);
}

.settings-nav-sidebar-item {
  padding: 10px 8px;
}

@media screen and (max-width: 600px) {
  .settings-nav-sidebar-item {
    padding: 4px 8px;
  }
}

.settings-nav-sidebar-item.selected {
  color: var(--content-primary-top);
  background: var(--background-primary-overlay);
  border: 1px solid var(--border-overlay);
}
</style>

import {
  BooleanOption,
  ChartOption,
  NumericArrayOption,
  StringOption
} from "@/anfin-chart/options/option";
import { TrendLineType } from "@/api/models/analysis/auto-trend-line";
import { AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import { type ChartOptionManager, OptionName } from "@/anfin-chart/options/option-manager";

export class TrendLineFilter {

  constructor(private readonly id: string,
              private readonly showOnlyActive: boolean,
              private readonly types: TrendLineType[] | null) {
  }

  public apply(id: string, type: TrendLineType, deletedAt: number | null) {
    const matchesId = this.id === "" || id === "" || AnalysisType.TrendLine + "_" + this.id === id;
    const matchesActive = !this.showOnlyActive || !deletedAt;
    const matchesType = this.types == null || this.types.includes(type);
    return matchesId && matchesActive && matchesType;
  }
}

export class TrendLineOptions {

  public readonly selectedId: StringOption;
  public readonly showOnlyActive: BooleanOption;
  public readonly shownTypes: ChartOption<TrendLineType[]>;

  constructor(optionManager: ChartOptionManager) {
    this.selectedId = new StringOption(optionManager, OptionName.SelectedTrendLineId, "");
    this.showOnlyActive = new BooleanOption(optionManager, OptionName.ShowOnlyActiveTrendLines, true);
    const defaultTypes = [TrendLineType.Main, TrendLineType.SubMain, TrendLineType.Sub];
    this.shownTypes = new NumericArrayOption(optionManager, OptionName.ShownTrendLineTypes, defaultTypes);
  }

  public isVisible(id: string, type: TrendLineType, deletedAt: number | null): boolean {
    const filter = new TrendLineFilter(
      this.selectedId.getValue(),
      this.showOnlyActive.getValue(),
      this.shownTypes.getValue()
    );
    return filter.apply(id, type, deletedAt);
  }
}

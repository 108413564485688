import { ApiModelConverter } from "@/api/messages/converter";
import type { ChartExportResponse } from "@/api/messages/layout";
import { ChartExportConverter } from "@/api/messages/layout";
import { MultiWindowTemplate } from "@/api/models/user-settings/chart";
import { ChartLayout } from "@/api/models/chart-layout";

export interface MultiWindowTemplateResponse {
  id: number | null;
  name: string;
  windows: ChartWindowTemplateResponse[];
}

export interface ChartWindowTemplateResponse {
  layoutId: number;
  splitCharts: ChartExportResponse[];
}

export class MultiWindowTemplateConverter extends ApiModelConverter<MultiWindowTemplate, MultiWindowTemplateResponse> {

  private readonly windowConverter = new ChartWindowTemplateConverter();

  public override toApiObject(template: MultiWindowTemplate) {
    return {
      id: template.id,
      name: template.name,
      windows: template.windows.map(w => this.windowConverter.toApiObject(w))
    };
  }

  public override toModelObject(response: MultiWindowTemplateResponse) {
    const windows = response.windows.map(w => this.windowConverter.toModelObject(w));
    return new MultiWindowTemplate(response.id, response.name, windows);
  }
}

export class ChartWindowTemplateConverter extends ApiModelConverter<ChartLayout, ChartWindowTemplateResponse> {

  private readonly chartConverter = new ChartExportConverter();

  public override toApiObject(layout: ChartLayout) {
    const splitCharts = layout.charts.map(c => this.chartConverter.toApiObject(c));
    return {
      layoutId: layout.id,
      splitCharts
    };
  }

  public override toModelObject(response: ChartWindowTemplateResponse) {
    const splitCharts = response.splitCharts.map(c => this.chartConverter.toModelObject(c));
    return new ChartLayout(response.layoutId, splitCharts);
  }
}

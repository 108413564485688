<template>
  <PageWrapper id="chart-mobile-grid">
    <TopSearchbar class="searchbar-mobile" />
    <LeftToolbar class="left-toolbar-mobile" />
    <AnfinChart class="chart-mobile" />
    <ChartObjectPanel :container="panelContainer" />
  </PageWrapper>
</template>

<script lang="ts">
import AnfinChart from "@/views/components/AnfinChart.vue";
import PageWrapper from "@/views/PageWrapper.vue";
import LeftToolbar from "@/views/toolbar/LeftToolbar.vue";
import TopSearchbar from "@/views/toolbar/TopSearchbar.vue";
import { defineComponent } from "vue";
import { DraggablePanelContainer } from "@/views/panels/DraggablePanel.vue";
import ChartObjectPanel from "@/views/panels/ChartObjectPanel.vue";

export default defineComponent({
  name: "ChartMobile",

  components: { ChartObjectPanel, LeftToolbar, TopSearchbar, PageWrapper, AnfinChart },

  expose: [],

  data() {
    return {
      panelContainer: new DraggablePanelContainer("#chart-mobile-grid", "#chart-mobile-grid")
    };
  }
});
</script>

<style scoped>
#chart-mobile-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
}

.searchbar-mobile {
  grid-row: 1;
  grid-column: 1 / 3;
}

.left-toolbar-mobile {
  grid-row: 2;
  grid-column: 1;
}

.chart-mobile {
  position: relative;
  grid-row: 2;
  grid-column: 2;
}

@media (max-width: 600px) {
  .left-toolbar-mobile {
    display: none;
  }

  .chart-mobile {
    grid-column: 1 / 3;
  }
}
</style>

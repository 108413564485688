import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { Size } from "@/anfin-chart/geometry";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { StackItem } from "@/anfin-chart/area/stack-item";

export class SubChartMainArea extends StackItem {

  constructor(layer: ChartLayer,
              private readonly subChart: SubChart) {
    super(subChart.chart.coreLayerArea);
    this.setHeight(this.chart.styleOptions.initialSubChartHeight.getValue());
  }

  public getHeight() {
    return this.getSize().height;
  }

  public setHeight(height: number) {
    this.setSize(new Size(0, height));
    this.resize();
  }

  public override initializeEvents() {
    super.initializeEvents();
    this.subscribeOn(this.chart.coreLayerArea.getPositionObservable(), () => this.resize());
  }

  public override onDelete() {
    super.onDelete();
    this.subChart.drawingArea.onDelete();
    this.subChart.priceAxis.onDelete();
    this.subChart.captionArea.onDelete();
    this.subChart.resizeArea.onDelete();
    this.subChart.toolDrawingArea.onDelete();
    this.subChart.hintArea.onDelete();
  }

  protected override drawInternal() {
    // nothing
  }
}

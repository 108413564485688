import type { Chart } from "@/anfin-chart/chart";
import type { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { ChartDrawer, FontInfo } from "@/anfin-chart/draw/chart-drawer";
import { Rectangle, Size } from "@/anfin-chart/geometry";
import type { ChartColor } from "@/anfin-chart/draw/chart-color";

export abstract class HintItem {

  public size = new Size(0, 0);
  public position = new Rectangle(0, 0, 0, 0);

  protected constructor(protected readonly hint: ChartHint) {
  }

  public updatePosition(top: number, left: number) {
    this.position = new Rectangle(left, top, left + this.size.width, top + this.size.height);
  }

  public getSubItems(): HintItem[] {
    return [];
  }

  public abstract updateSize(drawer: ChartDrawer): void;

  public abstract draw(drawer: ChartDrawer): void;
}

export class HintText extends HintItem {

  constructor(hint: ChartHint,
              private readonly text: string,
              private readonly padding = new Size(0, 0)) {
    super(hint);
  }

  public override updateSize(drawer: ChartDrawer) {
    const fontInfo = this.hint.chart.getFontInfo(this.hint.chart.styleOptions.hintFontSize.getValue());
    const textSize = drawer.measureText(this.text, fontInfo);
    this.size = new Size(textSize.width + this.padding.width, textSize.height + this.padding.height);
  }

  public override draw(drawer: ChartDrawer) {
    const fontInfo = this.hint.chart.getFontInfo(this.hint.chart.styleOptions.hintFontSize.getValue());
    const textColor = this.getTextColor();
    const backgroundColor = this.getBackgroundColor();
    if (backgroundColor != null) {
      drawer.drawRect(this.position, null, backgroundColor);
    }
    drawer.printTextAligned(this.position, this.text, textColor, fontInfo);
  }

  protected getTextColor() {
    return this.hint.mainColor ?? this.hint.chart.optionManager.chartColor.hintText.getValue();
  }

  protected getBackgroundColor(): ChartColor | null {
    return null;
  }
}

export class HintTable extends HintItem {

  public readonly rows: HintItem[][] = [];
  public rowSizes: number[] = [];
  public columnSizes: number[] = [];

  constructor(hint: ChartHint,
              private readonly columnCount: number,
              private readonly columnSpacing: number,
              private readonly rowSpacing = 1) {
    super(hint);
  }

  public addRow(...items: HintItem[]) {
    if (items.length !== this.columnCount) {
      throw new Error("Invalid column count");
    }
    this.rows.push(items);
  }

  public override getSubItems() {
    return this.rows.flat();
  }

  public override updateSize(drawer: ChartDrawer) {
    const rowSizes = [];
    const columnSizes = Array.from({ length: this.columnCount }, () => 0);
    for (const row of this.rows) {
      let rowSize = 0;
      for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
        const cell = row[columnIndex];
        cell.updateSize(drawer);
        rowSize = Math.max(rowSize, cell.size.height);
        columnSizes[columnIndex] = Math.max(columnSizes[columnIndex], cell.size.width);
      }
      rowSizes.push(rowSize);
    }
    this.setSizes(rowSizes, columnSizes);
  }

  public override updatePosition(top: number, left: number) {
    super.updatePosition(top, left);
    const columnOffsets = [0];
    for (let i = 0; i < this.columnSizes.length - 1; i++) {
      columnOffsets.push(columnOffsets[i] + this.columnSizes[i] + this.columnSpacing);
    }
    let currentTop = this.position.yStart;
    for (let rowIndex = 0; rowIndex < this.rows.length; rowIndex++) {
      const row = this.rows[rowIndex];
      for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
        const cell = row[columnIndex];
        cell.updatePosition(currentTop, this.position.xStart + columnOffsets[columnIndex]);
      }
      currentTop += this.rowSizes[rowIndex] + this.rowSpacing;
    }
  }

  public override draw(drawer: ChartDrawer) {
    for (const item of this.getSubItems()) {
      item.draw(drawer);
    }
  }

  private setSizes(rowSizes: number[], columnSizes: number[]) {
    this.rowSizes = rowSizes;
    this.columnSizes = columnSizes;
    const width = columnSizes.reduce((a, b) => a + b) + (columnSizes.length - 1) * this.columnSpacing;
    let height = 0;
    for (let rowIndex = 0; rowIndex < this.rowSizes.length; rowIndex++) {
      const rowSize = this.rowSizes[rowIndex];
      height += rowSize;
      for (let columnIndex = 0; columnIndex < this.columnSizes.length; columnIndex++) {
        const columnSize = this.columnSizes[columnIndex];
        this.rows[rowIndex][columnIndex].size = new Size(columnSize, rowSize);
      }
    }
    this.size = new Size(width, height);
  }
}

export abstract class ChartHint {

  public isVisible = false;
  public readonly items: HintItem[] = [];
  public readonly fontInfo: FontInfo;

  protected constructor(public readonly chart: Chart,
                        public mainColor: RGBAColor | null = null) {
    this.fontInfo = this.chart.getFontInfo(this.chart.styleOptions.hintFontSize.getValue());
  }

  public show() {
    this.isVisible = true;
  }

  public hide() {
    this.isVisible = false;
  }

  public getAllItems() {
    const remainingItems = this.items.slice();
    const items: HintItem[] = [];
    while (remainingItems.length > 0) {
      const item = remainingItems.pop();
      if (item != null) {
        items.push(item);
        remainingItems.push(...item.getSubItems());
      }
    }
    return items;
  }

  public draw(drawer: ChartDrawer) {
    if (!this.isVisible) {
      return;
    }
    const rectangle = this.getPosition(drawer).asRounded();
    const borderColor = this.mainColor ?? this.chart.optionManager.chartColor.hintText.getValue();
    const backgroundColor = this.chart.optionManager.chartColor.hintBackground.getValue();
    const borderRadius = this.chart.styleOptions.hintBorderRadius.getValue();
    drawer.drawRoundRect(rectangle, borderRadius, borderColor, backgroundColor);
    this.drawContent(drawer, rectangle);
  }

  protected clear() {
    this.items.splice(0);
  }

  protected addItem(...items: HintItem[]) {
    this.items.push(...items);
  }

  protected getSize(drawer: ChartDrawer) {
    let width = 0;
    let height = 0;
    for (const item of this.items) {
      item.updateSize(drawer);
      width = Math.max(width, item.size.width);
      height += item.size.height;
    }
    const size = new Size(width, height);
    const paddingHorizontal = this.chart.styleOptions.textBoxPaddingHorizontal.getValue();
    const paddingVertical = this.chart.styleOptions.textBoxPaddingVertical.getValue();
    return size.withPadding(paddingHorizontal, paddingVertical);
  }

  private drawContent(drawer: ChartDrawer, rectangle: Rectangle) {
    let top = rectangle.yStart + this.chart.styleOptions.textBoxPaddingVertical.getValue();
    const left = rectangle.xStart + this.chart.styleOptions.textBoxPaddingHorizontal.getValue();
    for (const item of this.items) {
      item.updatePosition(top, left);
      item.draw(drawer);
      top += item.size.height;
    }
  }

  protected abstract getPosition(drawer: ChartDrawer): Rectangle;
}

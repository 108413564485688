<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";

export default defineComponent({
  name: "NotFoundPage",

  components: { LandingPageSection, LandingPageWrapper },

  expose: []
});
</script>

<template>
  <LandingPageWrapper>
    <LandingPageSection class="py-md-5 py-4">
      <div class="landing-header">
        Diese Seite existiert nicht.
      </div>
    </LandingPageSection>
  </LandingPageWrapper>
</template>

<style scoped>

</style>
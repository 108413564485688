import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import { BooleanOption, ColorOption } from "@/anfin-chart/options/option";
import { LineElement, PolygonElement } from "@/anfin-chart/drawable";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint, getLeftRight } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { Point } from "@/anfin-chart/geometry";
import { UserToolAlertHook } from "@/anfin-chart/tools/alert-hook";

export class HorizontalChannel extends UserToolDefinition {

  public static readonly type = "hchannel";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(230, 230, 40));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(140, 140, 0, 0.3));
  private readonly expandLeft = new BooleanOption(this, OptionName.ExpandLeft, false);
  private readonly expandRight = new BooleanOption(this, OptionName.ExpandRight, false);

  private readonly lowerLeft = new ChartToolPoint();
  private readonly lowerRight = new ChartToolPoint();
  private readonly lowerRightExpanded = new ChartToolPoint();
  private readonly upperLeft = new ChartToolPoint();
  private readonly upperRight = new ChartToolPoint();
  private readonly upperRightExpanded = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(HorizontalChannel.type, instrument, timeframe, 2, 2);
    new UserToolAlertHook(this, "lower", this.lowerRight, 0);
    new UserToolAlertHook(this, "upper", this.lowerRight, 1);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const lowerLine = new LineElement(tool, this.lowerLeft, this.lowerRightExpanded);
    const upperLine = new LineElement(tool, this.upperLeft, this.upperRightExpanded);
    const area = new PolygonElement(tool, [this.lowerLeft, this.upperLeft, this.upperRightExpanded, this.lowerRightExpanded]);
    area.ignoreHit();
    tool.onUpdate(() => {
      lowerLine.color = this.lineColor.getValue();
      lowerLine.options.width = tool.getLineWidth(true);
      upperLine.color = this.lineColor.getValue();
      upperLine.options.width = tool.getLineWidth(true);
      area.areaColor = this.areaColor.getValue();
    });
    new FixedSelectablePoint(tool, this.fixedPoints[1]);
  }

  public override updatePosition(tool: UserTool) {
    const [first, second] = this.fixedPoints.map(p => tool.getPosition(p));
    if (this.fixedPoints.length === 1) {
      tool.updatePoint(this.lowerLeft, first);
    }
    if (this.fixedPoints.length >= 2) {
      const [left, right] = getLeftRight([first, second]);
      const isExpandLeft = this.expandLeft.getValue();
      const isExpandRight = this.expandRight.getValue();
      const rect = tool.subChart.drawingArea.getPosition();
      const leftExpandedX = isExpandLeft ? rect.xStart : left.x;
      const rightExpandedX = isExpandRight ? rect.xEnd : right.x;
      const lowerLeft = new Point(leftExpandedX, left.y);
      const lowerRight = new Point(right.x, left.y);
      const lowerRightExpanded = new Point(rightExpandedX, left.y);
      const upperLeft = new Point(leftExpandedX, right.y);
      const upperRight = new Point(right.x, right.y);
      const upperRightExpanded = new Point(rightExpandedX, right.y);
      tool.updatePoint(this.lowerLeft, lowerLeft);
      tool.updatePoint(this.lowerRight, lowerRight);
      tool.updatePoint(this.lowerRightExpanded, lowerRightExpanded);
      tool.updatePoint(this.upperLeft, upperLeft);
      tool.updatePoint(this.upperRight, upperRight);
      tool.updatePoint(this.upperRightExpanded, upperRightExpanded);
    }
  }
}

import { ChartArea } from "@/anfin-chart/area/chart-area";
import { Point, Rectangle, Vector } from "@/anfin-chart/geometry";
import { Size } from "@/anfin-chart/geometry";
import { simpleMapCompare } from "@/anfin-chart/utils";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import { switchMap } from "rxjs";

export class SharedTimeframeArea extends ChartArea {

  private size = new Size(0, 0);
  private timeframes: Timeframe[] = [];
  private readonly textAlignment = new TextAlignment(new Vector(-1, 0));

  public override initializeEvents() {
    const actionAreaObservable = this.chart.getPrimarySubChartObservable().pipe(
      switchMap(s => s.upperActionArea.getPositionObservable())
    );
    this.subscribeOn(actionAreaObservable, () => this.resize());
    this.subscribeOn(this.chart.getInstrumentDatasObservable(), () => this.updateTimeframes());
  }

  public updateTimeframes() {
    const timeframes = [];
    const instrumentDatas = this.chart.getInstrumentDatas();
    for (const instrumentData of instrumentDatas) {
      const symbol = instrumentData.instrument.getSymbol();
      const sharedTimeframes = this.chart.sharedToolTimeframes.get(symbol) ?? [];
      for (const timeframe of sharedTimeframes) {
        timeframes.push(timeframe);
      }
    }
    this.timeframes = this.filterTimeframes(timeframes);
    this.setIsVisible(this.timeframes.length > 0);
  }

  protected override drawInternal() {
    const position = this.getPosition();
    const fontInfo = this.chart.getFontInfo();
    const translation = this.chart.callbacks.getTranslation("chart#shared_timeframes");
    const text = translation + " " + this.timeframes.map(t => t.toShortNotation()).join(", ");
    this.size = this.drawer.measureText(text, fontInfo);
    const right = new Point(position.xEnd, (position.yStart + position.yEnd) / 2);
    const rect = this.drawer.getAlignedPosition(right, this.size, this.textAlignment);
    const color = this.chart.optionManager.chartColor.sharedTimeframesText.getValue();
    this.drawer.printTextAligned(rect, text, color, fontInfo);
  }

  protected override resizeInternal() {
    const actionAreaPosition = this.chart.primarySubChart.upperActionArea.getPosition();
    const xEnd = actionAreaPosition.xEnd;
    const yStart = actionAreaPosition.yEnd + this.chart.styleOptions.sharedTimeframesMargin.getValue();
    return new Rectangle(xEnd - this.size.width, yStart, xEnd, yStart + this.size.height);
  }

  private filterTimeframes(timeframes: Timeframe[]) {
    if (timeframes.length === 0) {
      return [];
    }
    timeframes.sort(simpleMapCompare(t => t.unit, t => t.value));
    let lastTimeframe = timeframes[0];
    let i = 1;
    while (i < timeframes.length) {
      const timeframe = timeframes[i];
      if (Timeframe.isSame(lastTimeframe, timeframe)) {
        timeframes.splice(i, 1);
      } else {
        lastTimeframe = timeframe;
        i++;
      }
    }
    return timeframes;
  }
}

import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { AnalysisToolDefinition, AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { AnalysisToolAlertHook } from "@/anfin-chart/tools/alert-hook";
import { LineStyle } from "@/anfin-chart/draw/chart-drawer";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";

class AutoFibonacciLevelDefinition {

  constructor(public readonly percentage: number,
              public readonly lineStyle: LineStyle,
              public readonly isExtension = false) {
  }
}

export class AutoFibonacciLevel {

  public readonly price: number;
  public readonly start = new ChartToolPoint();
  public readonly end = new ChartToolPoint();
  public readonly hook: AnalysisToolAlertHook;

  constructor(public readonly definition: AutoFibonacciLevelDefinition,
              tool: AutoFibonacci) {
    this.price = tool.getRetracementPrice(definition.percentage);
    this.hook = new AnalysisToolAlertHook(tool, "level_" + definition.percentage, this.start);
    this.hook.percentageOffset = definition.percentage;
  }
}

export class AutoFibonacci extends AnalysisToolDefinition {

  private static readonly levelDefinitions = [
    new AutoFibonacciLevelDefinition(0, LineStyle.Solid),
    new AutoFibonacciLevelDefinition(0.382, LineStyle.Dashed),
    new AutoFibonacciLevelDefinition(0.5, LineStyle.DashedWide),
    new AutoFibonacciLevelDefinition(0.618, LineStyle.Solid),
    new AutoFibonacciLevelDefinition(0.786, LineStyle.Dashed),
    new AutoFibonacciLevelDefinition(1, LineStyle.Solid),
    new AutoFibonacciLevelDefinition(-0.382, LineStyle.Dashed, true),
    new AutoFibonacciLevelDefinition(-0.5, LineStyle.Dashed, true),
    new AutoFibonacciLevelDefinition(-0.618, LineStyle.Solid, true),
    new AutoFibonacciLevelDefinition(-1.0, LineStyle.Solid, true)
  ];

  public readonly levels: AutoFibonacciLevel[] = [];

  constructor(id: string,
              instrument: Instrument,
              timeframe: Timeframe,
              public readonly fibonacciType: AutoFibonacciType,
              public readonly caption: string,
              startTime: number,
              public readonly startPrice: number,
              public readonly endTime: number,
              public readonly endPrice: number,
              public readonly ratio: number,
              public readonly consolidationFactor: number,
              public readonly retracementPrice: number | null) {
    super(id, AnalysisType.AutoFibonacci, instrument, timeframe, startTime);
    for (const levelDefinition of AutoFibonacci.levelDefinitions) {
      const level = new AutoFibonacciLevel(levelDefinition, this);
      this.levels.push(level);
    }
  }

  public getRetracementPrice(percentage: number) {
    return this.endPrice - percentage * (this.endPrice - this.startPrice);
  }

  public getRetracementPercentage(price: number) {
    return (this.endPrice - price) / (this.endPrice - this.startPrice);
  }
}

export enum AutoFibonacciType {
  BasicX = "FibX",
  Trend = "FibT",
  SubTrend = "FibTS",
  Basic1 = "Fib1",
  Basic2 = "Fib2"
}

export function getAutoFibonacciType(type: string) {
  switch (type) {
    case "FibX":
      return AutoFibonacciType.BasicX;
    case "Fib1":
      return AutoFibonacciType.Basic1;
    case "Fib2":
      return AutoFibonacciType.Basic2;
    case "FibT":
      return AutoFibonacciType.Trend;
    case "FibTS":
      return AutoFibonacciType.SubTrend;
    default:
      return AutoFibonacciType.SubTrend;
  }
}

export function getFibonacciTypeColor(type: AutoFibonacciType) {
  switch (type) {
    case AutoFibonacciType.BasicX:
      return new RGBAColor(182, 0, 239);
    case AutoFibonacciType.Basic1:
      return new RGBAColor(13, 202, 98);
    case AutoFibonacciType.Basic2:
      return new RGBAColor(0, 173, 255);
    case AutoFibonacciType.Trend:
      return new RGBAColor(255, 155, 113);
    case AutoFibonacciType.SubTrend:
      return new RGBAColor(184, 125, 75);
    default:
      return new RGBAColor(255, 255, 255);
  }
}

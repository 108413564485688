import type { Chart } from "@/anfin-chart/chart";
import { Point } from "@/anfin-chart/geometry";
import { ClickData, DragData, KeyData, ScrollData } from "@/anfin-chart/interactions";
import { UserToolAction } from "@/anfin-chart/actions/user-tool-action";

export enum MouseButton {
  Left = 0,
  Middle = 1,
  Right = 2,
  X1 = 3,
  X2 = 4
}

export class TapEvent {

  constructor(public readonly clientX: number,
              public readonly clientY: number,
              public readonly touchCount: number,
              public readonly diff = 0) {
  }

  public static fromMouseEvent(e: MouseEvent) {
    return new TapEvent(e.clientX, e.clientY, 1);
  }

  public static fromTouchEvent(e: TouchEvent) {
    let clientX: number;
    let clientY: number;
    let diff = 0;
    if (e.touches.length > 1) {
      clientX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
      clientY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
      const diffX = e.touches[1].clientX - e.touches[0].clientX;
      const diffY = e.touches[1].clientY - e.touches[0].clientY;
      diff = Math.sqrt(diffX ** 2 + diffY ** 2);
    } else {
      clientX = e.changedTouches[0].clientX;
      clientY = e.changedTouches[0].clientY;
    }
    return new TapEvent(clientX, clientY, e.touches.length, diff);
  }
}

export class InteractionHandler {

  private preventContextMenu = false;

  constructor(private readonly chart: Chart) {
    this.initializeEvents();
  }

  private initializeEvents() {
    const container = this.chart.container;
    const mouseData = this.chart.mouseData;
    const resizeObserver = new ResizeObserver(() => {
      this.chart.resize();
    });
    resizeObserver.observe(container);
    container.addEventListener("mouseenter", () => {
      mouseData.setMouseOver(true);
    });
    container.addEventListener("mouseleave", () => {
      this.chart.handleMouseLeave();
    });

    document.addEventListener("mousemove", e => {
      const tapEvent = TapEvent.fromMouseEvent(e);
      this.mouseMove(tapEvent);
    });

    container.addEventListener("mousedown", e => {
      if (!this.chart.isMobileMode() || !(this.chart.action instanceof UserToolAction)) {
        if (e.button === MouseButton.Left) {
          const tapEvent = TapEvent.fromMouseEvent(e);
          this.mouseDown(tapEvent);
        } else if (e.button === MouseButton.Right) {
          const data = new ClickData(mouseData.getPosition());
          const isHandled = this.chart.handleRightClick(data);
          if (isHandled) {
            this.preventContextMenu = true;
          }
        }
      }
    });

    container.addEventListener("contextmenu", e => {
      if (this.preventContextMenu) {
        e.stopPropagation();
        e.preventDefault();
        this.preventContextMenu = false;
      }
    }, true);

    document.addEventListener("mouseup", e => {
      if (e.button === MouseButton.Left) {
        this.mouseUp();
      }
    });

    container.addEventListener("touchstart", e => {
      const tapEvent = TapEvent.fromTouchEvent(e);
      this.mouseDown(tapEvent);
    });

    document.addEventListener("touchmove", e => {
      const tapEvent = TapEvent.fromTouchEvent(e);
      this.mouseMove(tapEvent);
    });

    document.addEventListener("touchend", () => {
      this.mouseUp();
    });

    document.addEventListener("touchcancel", () => {
      console.log("addEventListener touchcancel");
      this.mouseUp();
    });

    container.addEventListener("dblclick", e => {
      const data = new ClickData(mouseData.getPosition());
      this.chart.handleDoubleClick(data);
      e.preventDefault();
    });
    container.addEventListener("wheel", e => {
      this.wheel(e.deltaY, false);
      e.stopPropagation();
      e.preventDefault();
    });

    document.addEventListener("keydown", e => {
      const data = new KeyData(e.key, e.code);
      if (this.isFocused()) {
        this.chart.handleKeyDown(data);
      }
    });

    document.addEventListener("keyup", e => {
      const data = new KeyData(e.key, e.code);
      this.chart.handleKeyRelease(data);
    });
  }

  private isFocused() {
    return document.activeElement == null || document.activeElement.classList.contains("anfin-chart");
  }

  private updateMousePosition(e: TapEvent) {
    const boundingRect = this.chart.container.getBoundingClientRect();
    const x = e.clientX - boundingRect.left;
    const y = e.clientY - boundingRect.top;
    const isMouseOver = x >= 0 && x <= boundingRect.width && y >= 0 && y <= boundingRect.height;
    this.chart.mouseData.setMouseOver(isMouseOver);
    if (isMouseOver) {
      this.chart.mouseData.setPosition(new Point(x * window.devicePixelRatio, y * window.devicePixelRatio));
    }
  }

  private mouseDown(e: TapEvent) {
    const mouseData = this.chart.mouseData;
    this.updateMousePosition(e);
    mouseData.setMouseDown(true);
    mouseData.setTouchDifference(e.diff);
    const data = new ClickData(mouseData.getPosition(), e.touchCount);
    // add handle MouseMove - problem Tap on Smartphone/Tablet
    this.chart.handleMouseMove(data);
    this.chart.handleMouseDown(data);
  }

  private mouseMove(e: TapEvent) {
    const mouseData = this.chart.mouseData;
    const lastPosition = mouseData.getPosition();
    this.updateMousePosition(e);
    const position = mouseData.getPosition();
    if (mouseData.getMouseDown()) {
      this.dragMove(lastPosition, position);
      const diff = mouseData.getTouchDifference() - e.diff;
      if (Math.abs(diff) > 1.0) {
        this.wheel(diff * 2, true);
        mouseData.setTouchDifference(e.diff);
      }
    }
    if (mouseData.getMouseOver()) {
      const data = new ClickData(position);
      this.chart.handleMouseMove(data);
    }
  }

  private dragMove(lastPosition: Point, position: Point) {
    const mouseData = this.chart.mouseData;
    const mouseDownPos = mouseData.getMouseDownPosition();
    const diffX = position.x - lastPosition.x;
    const diffY = position.y - lastPosition.y;
    const maxDistance = this.chart.styleOptions.maxMouseMoveDistanceClick.getValue();
    if (Math.abs(mouseDownPos.x - position.x) > maxDistance ||
      Math.abs(mouseDownPos.y - position.y) > maxDistance) {
      mouseData.setDragging(true);
      mouseData.updateMaxDraggingDifference(Math.abs(mouseDownPos.x - position.x));
    }
    const data = new DragData(mouseData.getPosition(), mouseData.getMouseDownPosition(), mouseData.getMaxDraggingDifference(), diffX, diffY);
    this.chart.handleDrag(data);
  }

  private mouseUp() {
    const mouseData = this.chart.mouseData;
    const data = new ClickData(mouseData.getPosition());
    this.chart.handleMouseUp(data);
    if (!mouseData.getDragging() && mouseData.getMouseOver()) {
      this.chart.handleClick(data);
    }
    mouseData.setMouseDown(false);
  }

  private wheel(deltaY: number, scrollMobil: boolean) {
    const delta = deltaY / 100;
    const position = this.chart.mouseData.getPosition();
    const data = new ScrollData(position, delta, scrollMobil);
    this.chart.handleScroll(data);
    this.chart.handleMouseMove(new ClickData(position));
  }
}

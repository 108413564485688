import { StackItem } from "@/anfin-chart/area/stack-item";
import type { Clickable } from "@/anfin-chart/interactions";
import { Rectangle, Size } from "@/anfin-chart/geometry";
import type { SVG } from "@/anfin-chart/icon-store";
import { combineLatest, mergeMap } from "rxjs";
import type { SubChartActionArea } from "@/anfin-chart/area/action-area";
import { simpleMapCompare } from "@/anfin-chart/utils";

export abstract class SubChartActionButton extends StackItem implements Clickable {

  protected isActive = false;

  constructor(private readonly actionArea: SubChartActionArea,
              private readonly showOnlyHovered: boolean) {
    super(actionArea);
  }

  protected get subChart() {
    return this.actionArea.subChart;
  }

  public override initializeEvents() {
    super.initializeEvents();
    this.subscribeOn(this.actionArea.getPositionObservable(), () => this.resize());
    this.subscribeOn(this.chart.getPixelRatioObservable(), () => this.updateSize());
  }

  protected override drawInternal() {
    const mouseData = this.chart.mouseData;
    if (this.showOnlyHovered && (mouseData.getSubChart() !== this.subChart || !mouseData.getMouseOver())) {
      return;
    }
    const position = this.getPosition().asRounded();
    const color = this.chart.optionManager.chartColor.actionButton.getValue();
    const backgroundColor = this.isActive ? this.chart.optionManager.chartColor.actionButtonActive.getValue() : null;
    const borderRadius = this.chart.styleOptions.actionButtonBorderRadius.getValue();
    this.drawer.drawRoundRect(position, borderRadius, color, backgroundColor);
    const icon = this.getIcon();
    const padding = this.chart.styleOptions.actionButtonPadding.getValue();
    const iconPosition = new Rectangle(
      Math.floor(position.xStart + padding),
      Math.floor(position.yStart + padding),
      Math.ceil(position.xEnd - padding),
      Math.ceil(position.yEnd - padding)
    );
    this.drawer.drawSvg(icon, iconPosition, color);
  }

  private updateSize() {
    const buttonSize = this.chart.styleOptions.actionButtonSize.getValue();
    this.setSize(new Size(buttonSize, buttonSize));
  }

  public abstract onClick(): void;

  protected abstract getIcon(): SVG;
}

export class LinkSplitChartButton extends SubChartActionButton {

  public override initializeEvents() {
    super.initializeEvents();
    const combinedObservable = combineLatest([
      this.chart.getLinkButtonEnabledObservable(),
      this.chart.getPrimarySubChartObservable()
    ]);
    this.subscribeOn(combinedObservable, () => this.updateVisible());
    this.subscribeOn(this.chart.getIsLinkedSplitChartObservable(), l => {
      this.isActive = l;
      this.layer.requireDraw();
    });
    setTimeout(() => this.updateVisible());
  }

  public override onClick() {
    const isLinked = this.chart.getIsLinkedSplitChart();
    this.chart.setIsLinkedSplitChart(!isLinked);
  }

  protected override getIcon() {
    return this.chart.iconStore.getIcon("LinkSplitChart");
  }

  private updateVisible() {
    this.setIsVisible(this.chart.getLinkButtonEnabled() && this.subChart.isPrimary);
  }
}

export class SubChartCollapseButton extends SubChartActionButton {

  public override initializeEvents() {
    super.initializeEvents();
    const subChartsObservable = this.chart.getSubChartsObservable().pipe(
      mergeMap(p => p),
      mergeMap(p => p.getIsExpandedObservable())
    );
    this.subscribeOn(subChartsObservable, () => {
      const expandedSubCharts = this.chart.getSubCharts().filter(s => s.getIsExpanded());
      const isVisible = !this.subChart.getIsExpanded() || expandedSubCharts.length > 1;
      this.setIsVisible(isVisible);
    });
  }

  public override onClick() {
    const isExpanded = this.subChart.getIsExpanded();
    this.subChart.setIsExpanded(!isExpanded);
    this.chart.redistributeHeight();
  }

  protected override getIcon() {
    const iconKey = this.subChart.getIsExpanded() ? "CollapseSubChart" : "ExpandSubChart";
    return this.chart.iconStore.getIcon(iconKey);
  }
}

export class SubChartMaximizeButton extends SubChartActionButton {

  public override initializeEvents() {
    super.initializeEvents();
    this.subscribeOn(this.chart.getSubChartsObservable(), () => {
      const subCharts = this.chart.getSubCharts();
      this.setIsVisible(subCharts.length > 1);
    });
  }

  public override onClick() {
    const subCharts = this.chart.getSubCharts();
    if (this.getIsMaximized()) {
      for (const subChart of subCharts) {
        subChart.setIsExpanded(true);
      }
    } else {
      this.subChart.setIsExpanded(true);
      for (const subChart of subCharts.reverse()) {
        if (subChart !== this.subChart) {
          subChart.setIsExpanded(false);
        }
      }
    }
    this.chart.redistributeHeight();
  }

  protected override getIcon() {
    const iconKey = this.getIsMaximized() ? "MinimizeSubChart" : "MaximizeSubChart";
    return this.chart.iconStore.getIcon(iconKey);
  }

  private getIsMaximized() {
    return this.chart.getSubCharts().every(s => s === this.subChart || !s.getIsExpanded());
  }
}

export class MoveUpButton extends SubChartActionButton {

  public override initializeEvents() {
    super.initializeEvents();
    this.subscribeOn(this.subChart.getIndexObservable(), i => {
      this.setIsVisible(i > 0);
    });
  }

  public override onClick() {
    const index = this.subChart.getIndex();
    const subCharts = this.chart.getSubCharts().slice();
    subCharts.sort(simpleMapCompare(s => s.getIndex()));
    subCharts[index] = subCharts[index - 1];
    subCharts[index - 1] = this.subChart;
    this.chart.setSubCharts(subCharts);
    this.chart.callbacks.saveExport();
  }

  protected override getIcon() {
    return this.chart.iconStore.getIcon("MoveUp");
  }
}

export class MoveDownButton extends SubChartActionButton {

  public override initializeEvents() {
    super.initializeEvents();
    const combinedObservable = combineLatest([
      this.subChart.getIndexObservable(),
      this.chart.getSubChartsObservable()
    ]);
    this.subscribeOn(combinedObservable, v => {
      this.setIsVisible(v[0] < this.chart.getSubCharts().length - 1);
    });
  }

  public override onClick() {
    const index = this.subChart.getIndex();
    const subCharts = this.chart.getSubCharts().slice();
    subCharts.sort(simpleMapCompare(s => s.getIndex()));
    subCharts[index] = subCharts[index + 1];
    subCharts[index + 1] = this.subChart;
    this.chart.setSubCharts(subCharts);
    this.chart.callbacks.saveExport();
  }

  protected override getIcon() {
    return this.chart.iconStore.getIcon("MoveDown");
  }
}

export class MoveToLastButton extends SubChartActionButton {

  public override initializeEvents() {
    super.initializeEvents();
    const timeAxis = this.chart.timeAxis;
    this.subscribeOn(timeAxis.getRangeObservable(), () => {
      this.setIsVisible(this.subChart.isPrimary && !timeAxis.isAtLast());
    });
  }

  public override onClick() {
    this.chart.timeAxis.moveToLast();
  }

  protected override getIcon() {
    return this.chart.iconStore.getIcon("MoveToLast");
  }
}

export class AutoPriceRangeButton extends SubChartActionButton {

  public override initializeEvents() {
    super.initializeEvents();
    const autoRangeObservable = this.subChart.priceAxis.getIsAutoRangeObservable();
    this.subscribeOn(autoRangeObservable, isAutoRange => this.setIsVisible(!isAutoRange));
  }

  public override onClick() {
    this.subChart.priceAxis.setAutoRange();
  }

  protected override getIcon() {
    return this.chart.iconStore.getIcon("AutoPriceRange");
  }
}

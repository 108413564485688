import { ChartArea } from "@/anfin-chart/area/chart-area";
import { FontInfo, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import type { ChartLayer, DrawData } from "@/anfin-chart/chart-layer";
import { Point, Rectangle, Vector } from "@/anfin-chart/geometry";
import { LimitSizeArray } from "@/anfin-chart/utils";

const averageCount = 25;

class LayerInfo {

  public drawCount = 0;
  public durations = new LimitSizeArray<number>(averageCount);

  constructor(public layer: ChartLayer) {
  }
}

export class DebugInfo extends ChartArea {

  private readonly textAlignment = new TextAlignment(new Vector(1, 1));
  private readonly layerInfos = new Map<ChartLayer, LayerInfo>();

  public constructor(layer: ChartLayer) {
    super(layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.chart.drawingAreaWrapper.getPositionObservable(), () => this.resize());
    for (const layer of this.chart.layers) {
      this.layerInfos.set(layer, new LayerInfo(layer));
      this.subscribeOn(layer.getDrawDataObservable(), data => this.onDrawLayer(layer, data));
    }
  }

  public onDrawLayer(layer: ChartLayer, data: DrawData) {
    let layerInfo = this.layerInfos.get(layer);
    if (layerInfo == null) {
      layerInfo = new LayerInfo(layer);
      this.layerInfos.set(layer, layerInfo);
    }
    layerInfo.drawCount++;
    layerInfo.durations.push(data.duration);
  }

  protected override drawInternal() {
    if (!this.chart.enableDebug) {
      return;
    }
    const lines = this.createLines();
    const position = this.getPosition();
    const x = Math.round(position.xStart);
    const yStart = Math.round(position.yStart);
    const fontInfo = this.chart.getFontInfo();
    const color = this.chart.optionManager.chartColor.debugInfo.getValue();
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const y = yStart + i * (11 * window.devicePixelRatio);
      const firstPosition = new Point(x, y);
      this.drawer.printText(firstPosition, line, color, this.textAlignment, fontInfo);
    }
  }

  protected override resizeInternal() {
    const drawingAreaWrapperPosition = this.chart.drawingAreaWrapper.getPosition();
    const xStart = drawingAreaWrapperPosition.xStart + 20 * window.devicePixelRatio;
    const xEnd = xStart + 150 * window.devicePixelRatio;
    const yEnd = drawingAreaWrapperPosition.yEnd - 10;
    const yStart = yEnd - 190 * window.devicePixelRatio;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }

  private createLines() {
    const lines = [];
    for (const layerInfo of this.layerInfos.values()) {
      const metrics = this.getMetrics(layerInfo);
      lines.push(
        "Layer " + layerInfo.layer.name,
        "Draw count: " + layerInfo.drawCount,
        "Time: " + metrics.min.toFixed(2) + " / " + metrics.average.toFixed(2) + " / " + metrics.max.toFixed(2)
      );
    }
    lines.push("Bars: " + this.getBarCount());
    return lines;
  }

  private getMetrics(layerInfo: LayerInfo) {
    const metrics = {
      average: 0,
      min: Infinity,
      max: -Infinity,
      last: 0
    };
    const count = layerInfo.durations.length;
    const startIndex = layerInfo.durations.startIndex;
    for (let i = startIndex; i < count; i++) {
      const duration = layerInfo.durations[i];
      metrics.average += duration / (count - startIndex);
      metrics.min = Math.min(metrics.min, duration);
      metrics.max = Math.max(metrics.max, duration);
      metrics.last = duration;
    }
    return metrics;
  }

  private getBarCount() {
    const timeAxis = this.chart.timeAxis;
    const start = timeAxis.getFirstIndex();
    const end = timeAxis.getLastIndex();
    return Math.trunc(end - start);
  }
}

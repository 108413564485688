import { ApiModelConverter } from "@/api/messages/converter";
import type { LoginResponse } from "@/api/messages/login";
import { ExchangeInquiryConverter } from "@/api/messages/exchange-inquiry";
import { UserRight, UserRightKey } from "@/api/models/user-right";
import { UserInfo } from "@/api/models/user-info";

export class LoginResult {

  constructor(public readonly userInfo: UserInfo | null,
              public readonly resetPasswordToken: string | null,
              public readonly errorMessage: string | null = null) {
  }
}

export class LoginResultConverter extends ApiModelConverter<LoginResult, LoginResponse> {

  public override toApiObject(userInfo: LoginResult): LoginResponse {
    throw new Error("Not implemented");
  }

  public override toModelObject(response: LoginResponse) {
    if (!response.success) {
      return new LoginResult(null, null, response.message);
    }
    const loginResult = response.result[0];
    const user = loginResult.NICK_NAME;
    const email = loginResult.EMAIL;
    const firstName = loginResult.FIRST_NAME;
    const lastName = loginResult.LAST_NAME;
    const exchangeState = loginResult.EXCHANGE_USER_STATE;
    const exchangeInquiry = new ExchangeInquiryConverter().toModelObject(exchangeState);
    const rights = loginResult.rights;
    const abos = loginResult.abos;
    const userInfo = new UserInfo(user, email, firstName, lastName, response.token, response.isAdmin, exchangeInquiry);
    userInfo.rights = rights.map(r => new UserRight(r.key as UserRightKey, r.details));
    userInfo.abos = abos;
    const resetPasswordToken = loginResult.USER_STATE === "RESET_PASSWORD" && loginResult.resetPasswordToken !== ""
      ? loginResult.resetPasswordToken
      : null;
    return new LoginResult(userInfo, resetPasswordToken);
  }
}
<template>
  <div class="anfin-chart" :class="styles" tabindex="0" @focus="setActive">
    <div ref="container" class="chart-container" @contextmenu.prevent="showContextMenu"></div>
  </div>
</template>

<script lang="ts">
import type { RangeCache } from "@/anfin-chart/area/time-axis";
import type { ChartCallbacks } from "@/anfin-chart/callbacks";
import { Chart } from "@/anfin-chart/chart";
import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { Point, Rectangle } from "@/anfin-chart/geometry";
import { SVG, SvgDrawMethod, SVGPath } from "@/anfin-chart/icon-store";
import { Instrument } from "@/anfin-chart/instrument";
import { ChartCursorType } from "@/anfin-chart/mouse-data";
import type { ChartOptionManager } from "@/anfin-chart/options/option-manager";
import type { ChartObject } from "@/anfin-chart/options/option";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { ExchangeController } from "@/api/exchange-controller";
import { HistoryDataController } from "@/api/history-data-controller";
import type { TickData } from "@/api/messages/tick-update";
import { getCategoryColor } from "@/api/models/symbol-category";
import { PriceAboHandler } from "@/handler/price-abo-handler";
import { analysisStore } from "@/stores/analysis-store";
import { chartObjectStore } from "@/stores/chart-object-store";
import { chartOptionStore, MultiChartOptionManager } from "@/stores/chart-option-store";
import { instrumentStore } from "@/stores/instrument-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { translationStore } from "@/stores/translation-store";
import { userSettingStore } from "@/stores/user-setting-store";
import { watchlistStore } from "@/stores/watchlist-store";
import { ChartHandler } from "@/utils/ChartHandler";
import ChartObjectPanel from "@/views/panels/ChartObjectPanel.vue";
import { storeToRefs } from "pinia";
import { defineComponent, markRaw } from "vue";
import { shortcutStore } from "@/stores/shortcut-store";
import type { ChartLayoutDefinition } from "@/api/models/chart-layout";
import { ChartObjectPanelData, uiStateStore } from "@/stores/ui-state-store";
import { alertStore } from "@/stores/alert-store";
import type { Alert } from "@/api/models/alert";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export default defineComponent({
  name: "AnfinChart",

  expose: [],

  data() {
    const { activeChart, currentLayoutDefinition } = storeToRefs(multiChartStore());
    const { optionManager } = storeToRefs(chartOptionStore());
    shortcutStore();
    return {
      chartHandler: ChartHandler.getInstance(),
      chart: null as Chart | null,
      activeChart: activeChart as unknown as Chart | null,
      currentLayoutDefinition: currentLayoutDefinition as unknown as ChartLayoutDefinition,
      aboHandler: null as PriceAboHandler | null,
      optionManager: optionManager as unknown as MultiChartOptionManager
    };
  },
  
  computed: {
    styles() {
      const cursorType = this.optionManager.cursorType.getValue();
      return {
        active: this.activeChart === this.chart,
        "single-chart": this.currentLayoutDefinition.items.length === 1,
        "cursor-point": cursorType === ChartCursorType.Point,
        "cursor-crosshair": cursorType === ChartCursorType.Crosshair,
        "cursor-dart": cursorType === ChartCursorType.Dart
      };
    }
  },

  mounted() {
    this.aboHandler = new PriceAboHandler(this.updatePrice.bind(this));
    const container = this.$refs.container as HTMLElement;
    const id = multiChartStore().generateChartId();
    const chart = new Chart(id, container, this.getCallbacks(), this.optionManager as ChartOptionManager);
    this.chart = markRaw(chart);
    // TODO only for the video
    this.chart.showFibonacciHint = false;
    // this.chart.showFibonacciHint = !userRightStore().isMobile && userRightStore().isAdmin;
    chart.addIcon(
      new SVG("ExpandCaption", [new SVGPath("M24 5L12 16L0 5Z", SvgDrawMethod.Fill, RGBAColor.black, 1, "round")], new Rectangle(0, 0, 24, 24)),
      new SVG("CollapseCaption", [new SVGPath("M24 16L12 5L0 16Z", SvgDrawMethod.Fill, RGBAColor.black, 1, "round")], new Rectangle(0, 0, 24, 24)),
      new SVG("ExpandSubChart", [new SVGPath("M17.0074 9.02681L12.1128 2.99811C12.0767 2.95369 12.0475 2.91769 12.0475 2.91769L6.99262 9.01643 M7.00053 15.0078L11.8733 21.054C11.9092 21.0986 11.9383 21.1347 11.9383 21.1347L17.0152 15.0544", SvgDrawMethod.Stroke, RGBAColor.black, 2, "round")], new Rectangle(0, 0, 24, 24)),
      new SVG("CollapseSubChart", [new SVGPath("M17.0153 21.1168L12.1207 15.0883C12.0847 15.0438 12.0554 15.0078 12.0554 15.0078L7.00053 21.1064 M6.99627 2.92127L11.8691 8.96744C11.905 9.01198 11.9341 9.04809 11.9341 9.04809L17.0109 2.96781", SvgDrawMethod.Stroke, RGBAColor.black, 2, "round")], new Rectangle(0, 0, 24, 24)),
      new SVG("MinimizeSubChart", [new SVGPath("M4 14L10 14L10 20 M4 10L10 10L10 4 M14 4L14 10L20 10 M14 20L14 14L20 14", SvgDrawMethod.Stroke, RGBAColor.black, 2, "round")], new Rectangle(0, 0, 24, 24)),
      new SVG("MaximizeSubChart", [new SVGPath("M4 14L4 20L10 20 M4 10L4 4L10 4 M14 4L20 4L20 10 M14 20L20 20L20 14", SvgDrawMethod.Stroke, RGBAColor.black, 2, "round")], new Rectangle(0, 0, 24, 24)),
      new SVG("LinkSplitChart", [new SVGPath("M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z", SvgDrawMethod.Fill, RGBAColor.black, 1, "round")], new Rectangle(0, 0, 16, 16)),
      new SVG("MoveUp", [new SVGPath("M4.53518 9.02043L8.97396 2.74242L13.2653 9.02043 M13.2653 9.02043L9 4.62808L4.64642 9.02043 M9 6.27062L9 15.2988", SvgDrawMethod.Stroke, RGBAColor.black, 2, "round")], new Rectangle(0, 0, 18, 18)),
      new SVG("MoveDown", [new SVGPath("M13.3644 8.9995L8.91505 15.27L4.63424 8.98485 M8.89171 13.6629L8.91009 2.71361 M4.65311 9.02652L8.97522 12.6533L13.3644 9", SvgDrawMethod.Stroke, RGBAColor.black, 2, "round")], new Rectangle(0, 0, 18, 18)),
      new SVG("MoveToLast", [new SVGPath("M8 5L17 12L8 19", SvgDrawMethod.Stroke, RGBAColor.black, 3, "round")], new Rectangle(0, 0, 24, 24)),
      new SVG("AutoPriceRange", [new SVGPath("M2 12L22 12 M17.0153 21.1168L12.1207 15.0883C12.0847 15.0438 12.0554 15.0078 12.0554 15.0078L7.00053 21.1064 M6.99627 2.92127L11.8691 8.96744C11.905 9.01198 11.9341 9.04809 11.9341 9.04809L17.0109 2.96781", SvgDrawMethod.Stroke, RGBAColor.black, 2, "round")], new Rectangle(0, 0, 24, 24)),
      new SVG("Bell", [new SVGPath("M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z", SvgDrawMethod.Fill, RGBAColor.black)], new Rectangle(0, 0, 16, 16)),
      new SVG("World", [new SVGPath("M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z", SvgDrawMethod.Fill, RGBAColor.black)], new Rectangle(0, 0, 16, 16))
    );
    this.aboPrices();
  },

  unmounted() {
    this.aboHandler?.removeAllAbos();
    if (this.chart == null) {
      return;
    }
    const chart = this.chart as Chart;
    chart.onDestroy();
    const store = multiChartStore();
    store.removeChart(chart);
  },

  methods: {
    getCallbacks(): ChartCallbacks {
      return {
        onInitializationFinished: this.onInitializationFinished.bind(this),
        requestExchangeInfos: this.requestExchangeInfos.bind(this),
        requestHistory: this.requestHistoryData.bind(this),
        loadUserTools: this.loadUserTools.bind(this),
        saveUserTool: this.saveUserTool.bind(this),
        deleteUserTool: this.deleteUserTool.bind(this),
        saveAlert: this.saveAlert.bind(this),
        onInstrumentChange: this.onInstrumentChange.bind(this),
        onTimeframeChange: this.onTimeframeChange.bind(this),
        onMouseMove: this.onMouseMove.bind(this),
        onMouseLeave: this.onMouseLeave.bind(this),
        saveExport: this.saveExport.bind(this),
        loadRangeCache: this.loadRangeCache.bind(this),
        saveRangeCache: this.saveRangeCache.bind(this),
        editChartObject: this.editChartObject.bind(this),
        onActionChange: this.onActionChange.bind(this),
        getTranslation: this.getTranslation.bind(this),
        getInstrumentName: this.getInstrumentName.bind(this),
        getInstrumentIcon: this.getInstrumentIcon.bind(this)
      };
    },

    setTimeframe(timeframeKey: string) {
      if (this.chart != null) {
        const timeframe = Timeframe.fromShortNotation(timeframeKey);
        this.chart.setTimeframe(timeframe);
      }
    },

    setActive() {
      if (this.chart != null) {
        multiChartStore().setActiveChart(this.chart as Chart);
      }
    },

    showContextMenu(event: MouseEvent) {
      if (this.chart == null) {
        return;
      }
      uiStateStore().contextMenuData = this.chart.getContextMenuData(event);
    },

    // Chart callbacks
    onInitializationFinished() {
      const chart = this.chart;
      if (chart != null) {
        multiChartStore().addChart(chart as Chart);
        multiChartStore().asExternalUpdate(() => {
          for (const alert of alertStore().alerts) {
            chart.addAlert(alert);
          }
        });
      }
    },

    requestExchangeInfos() {
      const controller = ExchangeController.getInstance();
      controller.requestExchangeInfos().then(exchangeInfos => this.chart?.setExchangeInfos(exchangeInfos));
    },

    requestHistoryData(instrument: Instrument, timeframe: Timeframe, count: number, beforeTime: number | null) {
      const chart = this.chart;
      if (chart == null) {
        return;
      }
      HistoryDataController.getInstance().requestHistory(instrument, timeframe, count, null, beforeTime).then(data => {
        const instrumentDetail = instrumentStore().getDetail(instrument.getSymbol());
        const pricePrecision = instrumentDetail?.pricePrecision ? instrumentDetail?.pricePrecision : data.pricePrecision;
        chart.setHistoryData(instrument, timeframe, data.items, pricePrecision);
      });
      analysisStore().requestAnalyses(instrument, timeframe, count, null, beforeTime).then(data => {
        chart.addAnalyses(instrument, timeframe, data.getAllAnalyses());
      });
      const exchangeInfo = chart.getExchangeInfo(instrument.exchange);
      if (exchangeInfo?.outsideHoursExchange != null) {
        // const preTradingInstrument = new Instrument(instrument.isin, exchangeInfo.outsideHoursExchange);
        // MarketDataWebSocketController.getInstance().aboUpdates(preTradingInstrument.getSymbol());
      }
    },

    loadUserTools(instrument: Instrument, timeframe: Timeframe) {
      chartObjectStore().requestUserTools(instrument, timeframe);
    },

    saveUserTool(tool: UserToolDefinition) {
      chartObjectStore().saveUserTool(tool);
    },

    deleteUserTool(tool: UserToolDefinition) {
      chartObjectStore().deleteUserTool(tool);
    },

    saveAlert(alert: Alert) {
      alertStore().saveAlert(alert);
    },

    onInstrumentChange() {
      if (this.chart == null) {
        return;
      }
      if (chartOptionStore().optionManager.timeRangeCache.getValue()) {
        userSettingStore().clearRangeCache();
      }
      multiChartStore().onInstrumentChange(this.chart as Chart);
      this.aboPrices();
    },

    onTimeframeChange() {
      if (this.chart == null) {
        return;
      }
      multiChartStore().onTimeframeChange(this.chart as Chart);
      this.getCurrentPrices();
    },

    onMouseMove(chart: Chart, time: number | null, price: number | null) {
      multiChartStore().onMouseMove(chart, time, price);
    },

    onMouseLeave() {
      multiChartStore().onMouseLeave();
    },

    saveExport() {
      multiChartStore().saveLayout();
    },

    aboPrices() {
      if (this.chart == null || this.aboHandler == null) {
        return;
      }
      const symbols = new Set<string>();
      const instrumentDatas = this.chart.getInstrumentDatas();
      for (const instrumentData of instrumentDatas) {
        symbols.add(instrumentData.instrument.getSymbol());
      }
      this.aboHandler.setAbos(symbols);
    },

    getCurrentPrices() {
      if (this.chart == null || this.aboHandler == null) {
        return;
      }
      const symbols = new Set<string>();
      const instrumentDatas = this.chart.getInstrumentDatas();
      for (const instrumentData of instrumentDatas) {
        symbols.add(instrumentData.instrument.getSymbol());
      }
      this.aboHandler.getCurrentPrices(symbols);
    },

    updatePrice(symbol: string, tickData: TickData) {
      if (this.chart == null) {
        return;
      }
      const instrument = Instrument.fromSymbol(symbol);
      this.chart.updatePrice(instrument, tickData);
    },

    loadRangeCache(instrument: Instrument, timeframe: Timeframe) {
      const cache = userSettingStore().getRangeCache(timeframe);
      this.chart?.timeAxis.applyRangeCache(cache);
    },

    saveRangeCache(instrument: Instrument, timeframe: Timeframe, cache: RangeCache) {
      userSettingStore().setRangeCache(timeframe, cache);
    },

    editChartObject(chartObject: ChartObject | null) {
      let panelData: ChartObjectPanelData | null;
      if (chartObject != null && this.chart != null) {
        const element = this.$el as HTMLElement;
        const mousePosition = this.chart.mouseData.getPosition();
        const position = new Point(mousePosition.x + element.offsetLeft, mousePosition.y + element.offsetTop);
        panelData = new ChartObjectPanelData(chartObject, position);
      } else {
        panelData = null;
      }
      uiStateStore().chartObjectPanelData = panelData;
    },

    onActionChange(key: string) {
      multiChartStore().currentAction = key;
    },

    getTranslation(key: string) {
      return translationStore().getTranslation(key);
    },

    getInstrumentName(instrument: Instrument) {
      const symbol = instrument.getSymbol();
      const instrumentDetail = instrumentStore().getDetail(symbol);
      return instrumentDetail?.caption ?? instrumentDetail?.printName ?? symbol;
    },

    getInstrumentIcon(instrument: Instrument) {
      const category = watchlistStore().getCategory(instrument.getSymbol());
      if (category == null) {
        return null;
      }
      const categoryColor = getCategoryColor(category);
      if (categoryColor == null || categoryColor === "") {
        return null;
      }
      const rgbaColor = RGBAColor.fromHex(categoryColor);
      const path = new SVGPath("M5 4L19 4L19 20L12 12L5 20Z", SvgDrawMethod.Fill, rgbaColor);
      return new SVG("Test", [path], new Rectangle(0, 0, 24, 24));
    }
  }
});
</script>

<style>
.anfin-chart {
  border: 1px solid var(--border-neutral);
}

.anfin-chart.active {
  border-color: var(--border-active-chart);
}

.anfin-chart.single-chart {
  border: none;
}

.cursor-crosshair.active {
  cursor: crosshair;
}

.cursor-point.active {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='15' height='15' viewport='0 0 15 15' style='fill:black;'><ellipse cx='5' cy='5' rx='3' ry='3' fill='transparent' stroke-width='1.5' stroke='white' /></svg>") 5 5,auto;
}

.cursor-dart.active {
  cursor: default;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.chart-container canvas {
  position: absolute;
  left:0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
</style>

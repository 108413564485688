<template>
  <ModalDialog
    ref="dialog" :title="translationStore.getTranslation(titleDialog)" :autoFocus="autoFocus" :labelSave="labelOk"
    @save="pressedOk"
  >
    <template #body>
      <div>
        {{ translationStore.getTranslation(question) }}
      </div>
      <slot></slot>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { ChartHandler } from "@/utils/ChartHandler";
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import ModalDialog from "@/views/components/ModalDialog.vue";

export default defineComponent({
  name: "ConfirmationModalDialog",

  components: { ModalDialog },

  props: {
    titleDialog: {
      type: String,
      required: true
    },
    question: {
      type: String,
      required: true
    },
    labelOk: {
      type: String,
      required: true
    },
    autoFocus: {
      type: String,
      default: null
    }
  },

  emits: {
    pressedOk: () => true
  },

  expose: ["show"],

  data() {
    return {
      chartHandler: ChartHandler.getInstance(),
      translationStore: translationStore()
    };
  },

  methods: {
    show() {
      const dialog = this.$refs.dialog as typeof ModalDialog;
      dialog.show();
    },

    pressedOk() {
      const dialog = this.$refs.dialog as typeof ModalDialog;
      dialog.close();
      this.$emit("pressedOk");
    }
  }
});
</script>

<style scoped>
</style>

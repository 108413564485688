<template>
  <div class="settings-window-header">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SettingsWindowHeader",

  expose: []
});
</script>

<style scoped>
.settings-window-header {
  align-self: stretch;
  padding: 7px 14px;
  border-right: 1px solid var(--border-neutral);
  border-bottom: 1px solid var(--border-neutral);
  background: var(--background-neutral);
}
</style>
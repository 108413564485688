import type {
  CartResponse,
  CountryListResponse,
  CustomerSubscriptionsListResponse,
  ProductListResponse,
  SelfServiceTokenResponse,
  ShoppingOrderResponse,
  TerminationInfoResponse,
  TerminationResponse
} from "@/api/messages/payments";
import {
  ShoppingCart,
  ShoppingCartConverter,
  ShoppingOrderResult,
  ShoppingProductConverter,
  ShoppingSubscription,
  ShoppingSubscriptionConverter
} from "@/api/models/shopping-cart";
import { RestApiController } from "@/api/rest-api-controller";
import { PaymentCountryConverter, TerminationInfoResult } from "@/api/models/checkout";
import { userRightStore } from "@/stores/user-right-store";
import type { CustomerData } from "@/api/models/customer-data";
import type { CustomerDataWrapperResponse } from "@/api/messages/customer-data";
import { CustomerDataConverter } from "@/api/messages/customer-data";

export class PaymentController extends RestApiController {

  private static instance: PaymentController | null = null;

  private readonly productConverter = new ShoppingProductConverter();
  private readonly countryConverter = new PaymentCountryConverter();
  private readonly subscriptionConverter = new ShoppingSubscriptionConverter();
  private readonly cartConverter = new ShoppingCartConverter();
  private readonly customerDataConverter = new CustomerDataConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new PaymentController();
    }
    return this.instance;
  }

  public async getProducts() {
    const response = await this.sendGetRequest<ProductListResponse>("products");
    const products = [];
    for (const productResponse of response.products) {
      const product = this.productConverter.toModelObject(productResponse);
      if (product.variants.length === 0) {
        console.error("Product needs at least one variant: " + product.id);
      } else {
        products.push(product);
      }
    }
    return products;
  }

  public async getCountries() {
    const response = await this.sendGetRequest<CountryListResponse>("countries");
    return response.countries.map(c => this.countryConverter.toModelObject(c));
  }

  public async getCart(id: string) {
    const parameterMap = new Map<string, string>();
    parameterMap.set("pToken", id);
    try {
      const response = await this.sendGetRequest<CartResponse>("cart/products", parameterMap);
      return this.cartConverter.toModelObject(response);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async createCart() {
    const response = await this.sendPostRequest<CartResponse>("cart", {});
    return response.pToken;
  }

  public async updateCart(cart: ShoppingCart) {
    const request = this.cartConverter.toApiObject(cart);
    return await this.sendPutRequest<unknown>("cart/" + cart.id, request);
  }

  public async deleteCart(cart: ShoppingCart) {
    return await this.sendDeleteRequest<unknown>("cart/" + cart.id);
  }

  public async createOrder(cartId: string) {
    const request = { pToken: cartId };
    try {
      const response = await this.sendPostRequest<ShoppingOrderResponse>("cart/order", request);
      return new ShoppingOrderResult(response, null);
    } catch (e: any) {
      return new ShoppingOrderResult(null, e.response.data);
    }
  }

  public async createOrderPreview(cartId: string) {
    const request = { pToken: cartId };
    try {
      const response = await this.sendPostRequest<ShoppingOrderResponse>("cart/preview", request);
      return new ShoppingOrderResult(response, null);
    } catch (e: any) {
      return new ShoppingOrderResult(null, e.response.data);
    }
  }

  public async getUserAddressData(cartId: string) {
    const response = await this.sendGetRequest<CustomerDataWrapperResponse>("cart/" + cartId + "/user");
    return this.customerDataConverter.toModelObject(response.address);
  }

  public async saveUserAddressData(cartId: string, customerData: CustomerData) {
    const request = {
      userdata: this.customerDataConverter.toApiObject(customerData)
    };
    try {
      const response = await this.sendPutRequest<ShoppingOrderResponse>("cart/" + cartId + "/user", request);
      return new ShoppingOrderResult(response, null);
    } catch (e: any) {
      return new ShoppingOrderResult(null, e.response.data);
    }
  }

  public async getSubscriptions(email: string) {
    const userInfo = userRightStore().userInfo;
    if (userInfo == null) {
      return [];
    }
    const parameters = new Map<string, string>();
    parameters.set("email", email);
    parameters.set("token", userInfo.token);
    const response = await this.sendGetRequest<CustomerSubscriptionsListResponse>("subscriptions", parameters);
    if (response.customerSubscriptions.length === 0) {
      return [];
    }
    return response.customerSubscriptions.map(s => this.subscriptionConverter.toModelObject(s));
  }

  public async requestSelfServiceToken(contractId: string) {
    const parameters = new Map<string, string>();
    parameters.set("contractId", contractId);
    return await this.sendGetRequest<SelfServiceTokenResponse>("selfservicetoken", parameters);
  }

  public async requestTermination(subscription: ShoppingSubscription) {
    const request = { subscription: subscription.id };
    return await this.sendPostRequest<TerminationResponse>("cancel-subscription", request);
  }

  public async requestRevocation(contractId: ShoppingSubscription) {
    const request = { subscription: contractId.id };
    return await this.sendPostRequest<TerminationResponse>("revocation-subscription", request);
  }

  public async requestTerminationInfo(subscription: ShoppingSubscription) {
    const request = { subscription: subscription.id, email: "app@anfin.com" };
    try {
      const terminationInfo = await this.sendPostRequest<TerminationInfoResponse>("cancel-subscription-info", request);
      return new TerminationInfoResult(terminationInfo.id, terminationInfo.endDate, terminationInfo.nextPossibleCancellationDate, terminationInfo.revocationUntil * 1000);
    } catch (e: any) {
      return new TerminationInfoResult("", "", "", 0);
    }
  }

  public async requestCancelTermination(subscription: ShoppingSubscription) {
    const request = { subscription: subscription.id };
    return await this.sendPostRequest<TerminationResponse>("cancel-termination-subscription", request);
  }

  protected override getBaseUrl() {
    return this.environment.paymentUrl;
  }
}

import {
  BooleanOption,
  ChartOption,
  DevicePixelOption,
  EnumOption,
  NumericOption,
  type OptionProvider,
  StringOption
} from "@/anfin-chart/options/option";
import { PlotType } from "@/anfin-chart/plot";

export enum StyleOptionName {
  FontName = "fontName",
  StandardFontSize = "standardFontSize",
  PrimaryCaptionFontSize = "primaryCaptionFontSize",
  SecondaryCaptionFontSize = "secondaryCaptionFontSize",
  CaptionButtonFontSize = "captionButtonFontSize",
  CaptionIconSize = "captionIconSize",
  CaptionExpanderPaddingHorizontal = "captionExpanderPaddingHorizontal",
  CaptionExpanderPaddingVertical = "captionExpanderPaddingVertical",
  HintFontSize = "hintFontSize",
  HintBorderRadius = "hintBorderRadius",
  PricePrecision = "pricePrecision",
  PriceAutoRangePadding = "priceAutoRangePadding",
  MinBarCount = "minBarCount",
  MaxBarCount = "maxBarCount",
  InitialPlotType = "initialPlotType",
  ShowMainInstrumentCaption = "showMainInstrumentCaption",
  ShowInstrumentCaptionPriceData = "showInstrumentCaptionPriceData",
  MinVisibleItemCount = "minVisibleItemCount",
  ToolBlurAlpha = "toolBlurAlpha",
  TimeAxisHeight = "timeAxisHeight",
  PriceAxisPaddingRight = "priceAxisPaddingRight",
  PriceAxisPaddingLeft = "priceAxisPaddingLeft",
  SubChartSpacing = "subChartSpacing",
  CaptionOffset = "captionOffset",
  MinCandleWidth = "minCandleWidth",
  MaxCandleWidth = "maxCandleWidth",
  PriceMarkerPadding = "priceMarkerPadding",
  TimeMarkerPadding = "timeMarkerPadding",
  MinTimeLabelDistance = "minTimeLabelDistance",
  MinPriceLabelDistance = "minPriceLabelDistance",
  InitialOffset = "initialOffset",
  InitialSubChartHeight = "initialSubChartHeight",
  CaptionButtonSize = "captionButtonSize",
  CaptionLineMargin = "captionLineMargin",
  CaptionItemMargin = "captionItemMargin",
  ResizeAreaSize = "resizeAreaSize",
  ActionAreaMargin = "actionAreaMargin",
  ActionButtonSpacing = "actionButtonSpacing",
  ActionButtonSize = "actionButtonSize",
  ActionButtonPadding = "actionButtonPadding",
  ActionButtonBorderRadius = "actionButtonBorderRadius",
  MinSubChartHeight = "minSubChartHeight",
  SubChartCollapsedHeight = "subChartCollapsedHeight",
  ToolPointRadius = "toolPointRadius",
  ToolPointBorder = "toolPointBorder",
  ToolSelectionTolerance = "toolSelectionTolerance",
  LineSelectionTolerance = "lineSelectionTolerance",
  TextBoxBorderRadius = "textBoxBorderRadius",
  TextBoxPaddingVertical = "textBoxPaddingVertical",
  TextBoxPaddingHorizontal = "textBoxPaddingHorizontal",
  HintColumnSpacing = "hintColumnSpacing",
  FibonacciHorizontalDistanceTolerance = "fibonacciHorizontalDistanceTolerance",
  FibonacciHorizontalDistanceShift = "fibonacciHorizontalDistanceShift",
  FibonacciLineLength = "fibonacciLineLength",
  FibonacciHintMarginLeft = "fibonacciHintMarginLeft",
  FibonacciHintMarginBottom = "fibonacciHintMarginBottom",
  MaxSnapDistance = "maxSnapDistance",
  MaxMouseMoveDistanceClick = "maxMouseMoveDistanceClick",
  SubChartSeparatorHoverLineWidth = "subChartSeparatorHoverLineWidth",
  ToolHitMobileRadius = "toolHitMobileRadius",
  AlertIconSize = "alertIconSize",
  GlobalToolIconSize = "globalToolIconSize",
  AlertIconMargin = "alertIconMargin",
  AutoToolFontSize = "autoToolFontSize",
  AutoTrendLineMarkerSize = "autoTrendLineMarkerSize",
  AutoToolFutureProjection = "autoToolFutureProjection",
  SharedTimeframesMargin = "sharedTimeframesMargin"
}

export class StyleOptions implements OptionProvider {

  public readonly optionMap = new Map<string, ChartOption<any>>();

  public readonly fontName = new StringOption(this, StyleOptionName.FontName, "Rubik, sans-serif");
  public readonly standardFontSize = new DevicePixelOption(this, StyleOptionName.StandardFontSize, 12);
  public readonly primaryCaptionFontSize = new DevicePixelOption(this, StyleOptionName.PrimaryCaptionFontSize, 16);
  public readonly secondaryCaptionFontSize = new DevicePixelOption(this, StyleOptionName.SecondaryCaptionFontSize, 14);
  public readonly captionButtonFontSize = new DevicePixelOption(this, StyleOptionName.CaptionButtonFontSize, 10);
  public readonly captionIconSize = new DevicePixelOption(this, StyleOptionName.CaptionIconSize, 24);
  public readonly captionExpanderPaddingHorizontal = new DevicePixelOption(this, StyleOptionName.CaptionExpanderPaddingHorizontal, 6);
  public readonly captionExpanderPaddingVertical = new DevicePixelOption(this, StyleOptionName.CaptionExpanderPaddingVertical, 3);
  public readonly hintFontSize = new DevicePixelOption(this, StyleOptionName.HintFontSize, 12);
  public readonly hintBorderRadius = new DevicePixelOption(this, StyleOptionName.HintBorderRadius, 3);
  public readonly pricePrecision = new NumericOption(this, StyleOptionName.PricePrecision, 2);
  public readonly priceAutoRangePadding = new NumericOption(this, StyleOptionName.PriceAutoRangePadding, 0.1);
  public readonly minBarCount = new NumericOption(this, StyleOptionName.MinBarCount, 20);
  public readonly maxBarCount = new NumericOption(this, StyleOptionName.MaxBarCount, 5000);
  public readonly initialPlotType = new EnumOption(this, StyleOptionName.InitialPlotType, PlotType.Candle);
  public readonly showMainInstrumentCaption = new BooleanOption(this, StyleOptionName.ShowMainInstrumentCaption, true);
  public readonly showInstrumentCaptionPriceData = new BooleanOption(this, StyleOptionName.ShowInstrumentCaptionPriceData, true);
  public readonly minVisibleBarCount = new NumericOption(this, StyleOptionName.MinVisibleItemCount, 10);
  public readonly toolBlurAlpha = new NumericOption(this, StyleOptionName.ToolBlurAlpha, 0.75);

  public readonly timeAxisHeight = new DevicePixelOption(this, StyleOptionName.TimeAxisHeight, 18);
  public readonly priceAxisPaddingRight = new DevicePixelOption(this, StyleOptionName.PriceAxisPaddingRight, 6);
  public readonly priceAxisPaddingLeft = new DevicePixelOption(this, StyleOptionName.PriceAxisPaddingLeft, 10);
  public readonly subChartSpacing = new DevicePixelOption(this, StyleOptionName.SubChartSpacing, 0);
  public readonly captionOffset = new DevicePixelOption(this, StyleOptionName.CaptionOffset, 10);
  public readonly minCandleWidth = new DevicePixelOption(this, StyleOptionName.MinCandleWidth, 2);
  public readonly maxCandleWidth = new DevicePixelOption(this, StyleOptionName.MaxCandleWidth, 40);
  public readonly priceMarkerPadding = new DevicePixelOption(this, StyleOptionName.PriceMarkerPadding, 1);
  public readonly timeMarkerPadding = new DevicePixelOption(this, StyleOptionName.TimeMarkerPadding, 15);
  public readonly minTimeLabelDistance = new DevicePixelOption(this, StyleOptionName.MinTimeLabelDistance, 120);
  public readonly minPriceLabelDistance = new DevicePixelOption(this, StyleOptionName.MinPriceLabelDistance, 50);
  public readonly initialOffset = new DevicePixelOption(this, StyleOptionName.InitialOffset, 40);
  public readonly initialSubChartHeight = new DevicePixelOption(this, StyleOptionName.InitialSubChartHeight, 200);
  public readonly captionButtonSize = new DevicePixelOption(this, StyleOptionName.CaptionButtonSize, 14);
  public readonly captionLineMargin = new DevicePixelOption(this, StyleOptionName.CaptionLineMargin, 6);
  public readonly captionItemMargin = new DevicePixelOption(this, StyleOptionName.CaptionItemMargin, 9);
  public readonly resizeAreaSize = new DevicePixelOption(this, StyleOptionName.ResizeAreaSize, 3);
  public readonly actionAreaMargin = new DevicePixelOption(this, StyleOptionName.ActionAreaMargin, 4);
  public readonly actionButtonSpacing = new DevicePixelOption(this, StyleOptionName.ActionButtonSpacing, 3);
  public readonly actionButtonSize = new DevicePixelOption(this, StyleOptionName.ActionButtonSize, 22);
  public readonly actionButtonPadding = new DevicePixelOption(this, StyleOptionName.ActionButtonPadding, 2);
  public readonly actionButtonBorderRadius = new DevicePixelOption(this, StyleOptionName.ActionButtonBorderRadius, 5);
  public readonly minSubChartHeight = new DevicePixelOption(this, StyleOptionName.MinSubChartHeight, 28);
  public readonly subChartCollapsedHeight = new DevicePixelOption(this, StyleOptionName.SubChartCollapsedHeight, 28);
  public readonly toolPointRadius = new DevicePixelOption(this, StyleOptionName.ToolPointRadius, 6);
  public readonly toolPointBorder = new DevicePixelOption(this, StyleOptionName.ToolPointBorder, 2);
  public readonly toolHitTolerance = new DevicePixelOption(this, StyleOptionName.ToolSelectionTolerance, 4);
  public readonly lineHitTolerance = new DevicePixelOption(this, StyleOptionName.LineSelectionTolerance, 4);
  public readonly autoFibonacciHitTolerance = new DevicePixelOption(this, StyleOptionName.ToolSelectionTolerance, 10);
  public readonly fibonacciHorizontalDistanceTolerance = new DevicePixelOption(this, StyleOptionName.FibonacciHorizontalDistanceTolerance, 35);
  public readonly fibonacciHorizontalDistanceShift = new DevicePixelOption(this, StyleOptionName.FibonacciHorizontalDistanceShift, 28);
  public readonly hitToleranceMobile = new DevicePixelOption(this, StyleOptionName.ToolHitMobileRadius, 20);
  public readonly textBoxBorderRadius = new DevicePixelOption(this, StyleOptionName.TextBoxBorderRadius, 5);
  public readonly textBoxPaddingVertical = new DevicePixelOption(this, StyleOptionName.TextBoxPaddingVertical, 5);
  public readonly textBoxPaddingHorizontal = new DevicePixelOption(this, StyleOptionName.TextBoxPaddingHorizontal, 8);
  public readonly hintColumnSpacing = new DevicePixelOption(this, StyleOptionName.HintColumnSpacing, 10);
  public readonly fibonacciLineLength = new DevicePixelOption(this, StyleOptionName.FibonacciLineLength, 150);
  public readonly fibonacciHintMarginLeft = new DevicePixelOption(this, StyleOptionName.FibonacciHintMarginLeft, 15);
  public readonly fibonacciHintMarginBottom = new DevicePixelOption(this, StyleOptionName.FibonacciHintMarginBottom, 15);
  public readonly maxSnapDistance = new DevicePixelOption(this, StyleOptionName.MaxSnapDistance, 10);
  public readonly maxMouseMoveDistanceClick = new DevicePixelOption(this, StyleOptionName.MaxMouseMoveDistanceClick, 3);
  public readonly subChartSeparatorHoverLineWidth = new DevicePixelOption(this, StyleOptionName.SubChartSeparatorHoverLineWidth, 2);
  public readonly alertIconSize = new DevicePixelOption(this, StyleOptionName.AlertIconSize, 12);
  public readonly globalToolIconSize = new DevicePixelOption(this, StyleOptionName.GlobalToolIconSize, 14);
  public readonly alertIconMargin = new DevicePixelOption(this, StyleOptionName.AlertIconMargin, 2);
  public readonly autoToolFontSize = new DevicePixelOption(this, StyleOptionName.AutoToolFontSize, 16);
  public readonly autoTrendLineMarkerSize = new DevicePixelOption(this, StyleOptionName.AutoTrendLineMarkerSize, 5);
  public readonly autoToolFutureProjection = new NumericOption(this, StyleOptionName.AutoToolFutureProjection, 50);
  public readonly sharedTimeframesMargin = new DevicePixelOption(this, StyleOptionName.SharedTimeframesMargin, 5);

  public onOptionChange(option: ChartOption<unknown>) {
    // do nothing
  }
}

import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { LineOptions, LineStyle, TextAlignment } from "@/anfin-chart/draw/chart-drawer";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { LineElement, PolygonElement, TextBox } from "@/anfin-chart/drawable";
import { getCenterPoint, Point, Vector } from "@/anfin-chart/geometry";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { formatPercentage } from "@/anfin-chart/utils";

export class PriceRangeTool extends UserToolDefinition {

  public static readonly type = "prange";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(120, 137, 152));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(103, 106, 110, 0.25));

  private readonly lowerRight = new ChartToolPoint();
  private readonly lowerCenter = new ChartToolPoint();
  private readonly upperLeft = new ChartToolPoint();
  private readonly upperCenter = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(PriceRangeTool.type, instrument, timeframe, 2, 2);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
  }

  public override updatePosition(tool: UserTool) {
    const [lower, upper] = this.fixedPoints.map(p => tool.getPosition(p));
    if (this.fixedPoints.length >= 2) {
      const lowerRight = new Point(upper.x, lower.y);
      const upperLeft = new Point(lower.x, upper.y);
      tool.updatePoint(this.lowerRight, lowerRight);
      tool.updatePoint(this.upperLeft, upperLeft);
      const lowerCenter = getCenterPoint(lower, lowerRight);
      tool.updatePoint(this.lowerCenter, lowerCenter);
      const upperCenter = getCenterPoint(upper, upperLeft);
      tool.updatePoint(this.upperCenter, upperCenter);
    }
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const [lower, upper] = this.fixedPoints;
    const upperLine = new LineElement(tool, lower, this.lowerRight);
    const lowerLine = new LineElement(tool, this.upperLeft, upper);
    const arrowLineOptions = new LineOptions(1, LineStyle.Dashed);
    arrowLineOptions.arrowEnd = 8;
    const centerLine = new LineElement(tool, this.lowerCenter, this.upperCenter, arrowLineOptions);
    const area = new PolygonElement(tool, [lower, this.lowerRight, upper, this.upperLeft]);
    area.ignoreHit();
    const alignment = new TextAlignment(new Vector(0, -1));
    const textBox = new TextBox(tool, this.upperCenter, alignment);
    tool.onUpdate(() => {
      upperLine.color = this.lineColor.getValue();
      upperLine.options.width = tool.getLineWidth(true);
      lowerLine.color = this.lineColor.getValue();
      lowerLine.options.width = tool.getLineWidth(true);
      centerLine.color = this.lineColor.getValue();
      centerLine.options.width = tool.getLineWidth(true);
      area.areaColor = this.areaColor.getValue();
      textBox.color = tool.chart.optionManager.chartColor.toolText.getValue();
      textBox.setText(this.getText(tool));
    });
    new FixedSelectablePoint(tool, upper);
  }

  private getText(tool: UserTool) {
    const [lower, upper] = this.fixedPoints;
    const difference = upper.price - lower.price;
    const differencePercentage = difference / lower.price;
    const barWidth = tool.chart.timeAxis.getBarWidth();
    const upperPosition = tool.getPosition(upper);
    const lowerPosition = tool.getPosition(lower);
    const barCount = Math.round((upperPosition.x - lowerPosition.x) / barWidth);
    const priceFormatted = tool.chart.formatPrice(difference);
    const percentageFormatted = formatPercentage(differencePercentage);
    const barLabel = tool.chart.callbacks.getTranslation("user_tool#prange#label_bars");
    return `${priceFormatted} (${percentageFormatted}), ${barCount} ${barLabel}`;
  }
}

<template>
  <AutoFilterList
    id="market-overview-search"
    ref="watchlistSearch"
    :placeholder="translationStore.getTranslation('watchlist_overview#search_list')"
    :showIconRight="true"
    :items="filteredWatchlists"
    @onInput="inputChange"
    @onFocus="clearFilter"
    @selectItem="onSelectWatchlist"
  >
    <template #default="props">
      <span style="width: 90%; max-width: 150px; overflow: hidden; text-overflow: ellipsis" class="me-1"> {{ props.item.name }}</span>
      <IconElement v-if="props.item.type === WatchlistType.User" :size="16" iconName="Account" style="width: 10%" />
      <IconElement v-else-if="props.item.type === WatchlistType.Public" :size="16" iconName="World" style="width: 10%" />
      <IconElement v-else :size="16" iconName="Lock" style="width: 10%" />
    </template>
  </AutoFilterList>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconElement from "@/views/icons/IconElement.vue";
import AutoFilterList from "@/views/components/AutoFilterList.vue";
import { Watchlist, WatchlistType } from "@/api/models/watchlist";
import { translationStore } from "@/stores/translation-store";
import { chartOptionStore, MultiChartOptionManager } from "@/stores/chart-option-store";
import { storeToRefs } from "pinia";
import { watchlistStore } from "@/stores/watchlist-store";

export default defineComponent({
  name: "WatchlistSelector",

  components: { AutoFilterList, IconElement },

  expose: [],

  data() {
    const { optionManager } = storeToRefs(chartOptionStore());
    const { watchlists } = storeToRefs(watchlistStore());
    return {
      translationStore: translationStore(),
      optionManager: optionManager as unknown as MultiChartOptionManager,
      watchlists: watchlists as unknown as Watchlist[],
      searchText: "",
      WatchlistType
    };
  },

  computed: {
    filteredWatchlists() {
      const filter = this.searchText.toLowerCase();
      return this.watchlists.filter(
        value => value.type !== WatchlistType.Virtual && (filter === "" || value.name.toLowerCase().includes(filter))
      );
    }
  },

  methods: {
    inputChange(searchText: string) {
      this.searchText = searchText;
    },

    clearFilter() {
      this.searchText = "";
    },

    onSelectWatchlist(watchlist: Watchlist) {
      this.setSelectedWatchlist(watchlist.id);
      (this.$refs.watchlistSearch as typeof AutoFilterList).clearInput();
    },

    setSelectedWatchlist(value: number) {
      this.optionManager.selectedWatchlistMainView.setValue(value);
    }
  }
});
</script>

<style>
#market-overview-search {
  width: 167px;
  flex-shrink: 0;
  z-index: 100;
  margin: 0;
}

#market-overview-search > form.nosubmit > .nosubmit {
  width: 130px;
}
</style>
<template>
  <div class="chat-window">
    <SettingsWindowHeader class="chat-selector">
      <ChatHeaderItem v-for="chat in chats" :key="chat.id" :chat="chat" />
    </SettingsWindowHeader>
    <div class="chat-content px-3 py-2">
      <div v-for="messageDay in messageDays" :key="messageDay.start">
        <div class="text-center my-2">
          {{ messageDay.isToday() ? labelToday : formatDate(messageDay.start) }}
        </div>
        <ChatMessageItem v-for="message in messageDay.messages" :key="message.id ?? 0" :message="message" />
      </div>
    </div>
    <ChatMessageEditDialog ref="editMessageDialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
import { chatStore } from "@/stores/chat-store";
import { ChatMessage } from "@/api/models/chat";
import ChatMessageItem from "@/views/chat/ChatMessageItem.vue";
import { simpleMapCompare } from "@/anfin-chart/utils";
import { DateMixin } from "@/mixins/date-mixin";
import SettingsWindowHeader from "@/views/settings/SettingsWindowHeader.vue";
import { chartOptionStore } from "@/stores/chart-option-store";
import ChatHeaderItem from "@/views/chat/ChatHeaderItem.vue";
import { translationStore } from "@/stores/translation-store";
import ChatMessageEditDialog from "@/views/chat/ChatMessageEditDialog.vue";
import { uiStateStore } from "@/stores/ui-state-store";
import { storeToRefs } from "pinia";

export class ChatMessageDay {

  public readonly messages: ChatMessage[] = [];

  constructor(public readonly start: number,
              public readonly end: number) {
  }

  public isToday() {
    const today = new Date().setHours(0, 0, 0, 0);
    return this.start >= today;
  }
}

export default defineComponent({
  name: "ChatWindow",

  components: { ChatMessageEditDialog, ChatHeaderItem, SettingsWindowHeader, ChatMessageItem },

  mixins: [DateMixin],

  props: {
    isShown: {
      type: Boolean,
      required: true
    }
  },

  expose: [],

  data() {
    const { editedMessage } = storeToRefs(uiStateStore());
    return {
      editedMessage: editedMessage as unknown as ChatMessage | null,
      translationStore: translationStore()
    };
  },

  computed: {
    chats() {
      return chatStore().chats;
    },

    selectedChats() {
      const option = chartOptionStore().optionManager.selectedChats;
      const chatIds = new Set(option.getValue());
      const selectedChats = this.chats.filter(c => chatIds.has(c.id));
      return new Set(selectedChats);
    },

    messageDays(): ChatMessageDay[] {
      const messages = [];
      for (const chat of this.selectedChats) {
        messages.push(...chat.getMessages());
      }
      messages.sort(simpleMapCompare(m => -m.time));
      const messageDays = [];
      let currentDay: ChatMessageDay | null = null;
      for (const message of messages) {
        if (currentDay == null || currentDay.start > message.time) {
          const startDate = new Date(message.time);
          const startTime = startDate.setHours(0, 0, 0, 0);
          const endTime = new Date(startDate).setDate(startDate.getDate() + 1);
          currentDay = new ChatMessageDay(startTime, endTime);
          messageDays.push(currentDay);
        }
        currentDay.messages.push(message);
      }
      return messageDays;
    },

    labelToday() {
      return this.translationStore.getTranslation("chat#label_today");
    }
  },

  watch: {
    editedMessage(message: ChatMessage | null) {
      if (message != null) {
        const dialog = this.$refs.editMessageDialog as typeof ChatMessageEditDialog;
        dialog.show(message);
      }
    }
  },

  created() {
    watch(
      () => [this.messageDays, this.isShown],
      () => {
        if (this.isShown) {
          for (const chat of this.selectedChats) {
            chatStore().markRead(chat);
          }
        }
      }
    );
  }
});
</script>

<style scoped>
.chat-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 800px;
}

.chat-selector {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, max-content));
  gap: 0.5rem;
}

.chat-content {
  flex-grow: 1;
  overflow-y: auto;
}
</style>
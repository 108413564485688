<template>
  <div>
    <div>
      <label class="form-label">
        {{ translationStore.getTranslation("indicator_definition#label_update") }}
      </label>
      <AutoSizeTextarea v-model="indicatorDefinition.updateInternal" />
    </div>
  </div>
</template>

<script lang="ts">
import { IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import { translationStore } from "@/stores/translation-store";
import AutoSizeTextarea from "@/views/components/AutoSizeTextarea.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IndicatorDefinitionUpdate",

  components: { AutoSizeTextarea },

  props: {
    indicatorDefinition: {
      type: IndicatorDefinition,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  }
});
</script>

<style scoped>

</style>

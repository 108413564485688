<template>
  <LandingPageSection>
    <div class="landing-section-split">
      <div>
        <div class="landing-header">
          AnFin - Ihre Beste Wahl
        </div>
        <div class="mt-3">
          Trotz seiner Erfolge als Arzt und Facharzt für Chirurgie ließ Hamed Esnaashari die Leidenschaft für
          das Trading und die Börse nicht los. Wie jeder Anleger machte auch er seine Erfahrungen an der Börse,
          die durchaus herausfordernd waren und anfangs in zwei Totalverlusten seines Handelskonto mündeten.
          Mit großer Begeisterung und Ehrgeiz gelang es ihm jedoch, eine profitable Handelsstrategie zu erarbeiten
          und diese zunächst über Foren und einen eigenen Blog zu kommunizieren. Daraus ergab sich so viel Zuspruch,
          dass er in der Folge die Formationstrader GmbH gründete und aufgrund der sehr großen Nachfrage auch begann,
          Seminare anzubieten. In der Folge entstand AnFin.
        </div>
        <div class="d-flex mt-4">
          <RouterLink to="/about-us" class="btn btn-primary">
            Mehr erfahren
          </RouterLink>
        </div>
      </div>
      <div>
        <LandingPageChartImage class="best-choice-chart-image" />
      </div>
    </div>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import LandingPageChartImage from "@/views/pages-landing/LandingPageChartImage.vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "BestChoiceSection",

  components: { LandingPageChartImage, LandingPageSection, RouterLink },

  expose: []
});
</script>

<style scoped>
.best-choice-chart-image {
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
</style>
<template>
  <div>
    <div class="alert-editor-definition-split">
      <div class="alert-editor-definition-type mt-2">
        <label class="form-label">
          {{ translationStore.getTranslation("alert_editor#label_rule_type") }}
        </label>
        <SingleSelect :modelValue="definition.type" :items="ruleTypeItems" @update:modelValue="changeType" />
      </div>
      <div v-if="definition instanceof AlertFixedValueDefinition" class="mt-2 alert-editor-definition-type-data">
        <label class="form-label">
          {{ translationStore.getTranslation("alert_editor#label_fixed_value") }}
        </label>
        <input v-model="definition.fixedValue" type="number" class="form-control form-control-sm" />
      </div>
      <div v-else-if="definition instanceof AlertUserToolDefinition" class="mt-2 alert-editor-definition-type-data">
        <label class="form-label">
          {{ translationStore.getTranslation("alert_editor#label_user_tool") }}
        </label>
        <SingleSelect v-model="definition.toolId" :items="userToolItems" />
      </div>
      <div v-else-if="definition instanceof AlertAutoToolDefinition" class="mt-2 alert-editor-definition-type-data">
        <label class="form-label">
          {{ translationStore.getTranslation("alert_editor#label_auto_tool") }}
        </label>
        <SingleSelect v-model="definition.toolId" :items="autoToolItems" />
      </div>
    </div>
    <div v-if="definition instanceof AlertUserToolDefinition">
      <div class="mt-2">
        <label class="form-label">
          {{ translationStore.getTranslation("alert_editor#label_user_tool_line") }}
        </label>
        <SingleSelect v-model="definition.basePointIndex" :items="userToolLineItems" />
      </div>
    </div>
    <div v-if="definition instanceof AlertUserToolDefinition || definition instanceof AlertAutoToolDefinition">
      <div class="mt-2">
        <label class="form-label">
          {{ translationStore.getTranslation("alert_editor#label_percentage_offset") }}
        </label>
        <input
          :value="percentageOffset" type="number" class="form-control form-control-sm"
          @change="setPercentageOffset(definition, processNumeric($event))"
        />
      </div>
    </div>
    <div v-else-if="definition instanceof AlertIndicatorDefinition">
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import {
  AlertAutoToolDefinition,
  AlertFixedValueDefinition,
  AlertIndicatorDefinition, AlertRule,
  type AlertRuleDefinition,
  AlertRuleType,
  AlertUserToolDefinition
} from "@/api/models/alert";
import { translationStore } from "@/stores/translation-store";
import { getEnumValues } from "@/anfin-chart/utils";
import { chartObjectStore } from "@/stores/chart-object-store";
import { Instrument } from "@/anfin-chart/instrument";
import { InputUtilsMixin } from "@/mixins/input-utils";
import { analysisStore } from "@/stores/analysis-store";

export default defineComponent({
  name: "AlertRuleDefinitionEditor",

  components: { SingleSelect },

  mixins: [InputUtilsMixin],

  props: {
    rule: {
      type: AlertRule,
      required: true
    },
    definition: {
      type: Object as PropType<AlertRuleDefinition>,
      required: true
    }
  },

  emits: {
    change: (definition: AlertRuleDefinition) => definition || true
  },

  expose: [],

  data() {
    const ruleTypeItems = [];
    for (const ruleType of getEnumValues(AlertRuleType)) {
      const item = new SingleSelectItem("alert_rule_type#" + ruleType, null, ruleType);
      item.isDisabled = ruleType === AlertRuleType.Indicator;
      ruleTypeItems.push(item);
    }
    return {
      translationStore: translationStore(),
      ruleTypeItems,
      AlertRuleType,
      AlertFixedValueDefinition,
      AlertUserToolDefinition,
      AlertAutoToolDefinition,
      AlertIndicatorDefinition
    };
  },

  computed: {
    userToolItems() {
      const items = [];
      for (const tool of chartObjectStore().userToolMap.values()) {
        if (tool.id == null || tool.hookProvider.count === 0 ||
          !Instrument.isSame(tool.instrument, this.rule.instrument)) {
          continue;
        }
        const translation = this.translationStore.getTranslation("user_tool#" + tool.type);
        const name = translation + " " + tool.id;
        items.push(new SingleSelectItem(tool.id.toString(), name, tool.id));
      }
      return items;
    },

    autoToolItems() {
      const items = [];
      for (const tool of analysisStore().analyses) {
        if (tool.hookProvider.count === 0 || !Instrument.isSame(tool.instrument, this.rule.instrument)) {
          continue;
        }
        const translation = this.translationStore.getTranslation("analysis#" + tool.type);
        const name = translation + " " + tool.id;
        items.push(new SingleSelectItem(tool.id, name, tool.id));
      }
      return items;
    },

    userToolLineItems() {
      const items: SingleSelectItem[] = [];
      if (this.definition instanceof AlertUserToolDefinition) {
        const toolDefinition = chartObjectStore().getOrRequestUserTool(this.definition.toolId);
        if (toolDefinition != null) {
          for (const hook of toolDefinition.hookProvider.alertHooks) {
            const translation = this.translationStore.getTranslation("user_tool#" + toolDefinition.type + "#" + hook.key);
            items.push(new SingleSelectItem(hook.key, translation, hook.basePointIndex));
          }
        }
      }
      return items;
    },

    percentageOffset() {
      const definition = this.definition as AlertUserToolDefinition | AlertAutoToolDefinition;
      return definition.percentageOffset == null ? "" : definition.percentageOffset * 100;
    }
  },

  methods: {
    changeType(type: AlertRuleType) {
      let definition: AlertRuleDefinition;
      switch (type) {
        case AlertRuleType.FixedValue:
          definition = new AlertFixedValueDefinition(0);
          break;
        case AlertRuleType.UserTool:
          definition = new AlertUserToolDefinition(0, 0);
          break;
        case AlertRuleType.AutoTool:
          definition = new AlertAutoToolDefinition("");
          break;
        case AlertRuleType.Indicator:
          definition = new AlertIndicatorDefinition(0, 0);
          break;
        default:
          throw new Error("Unknown alert rule definition type");
      }
      this.$emit("change", definition);
    },

    setPercentageOffset(definition: AlertUserToolDefinition | AlertAutoToolDefinition, value: number | null) {
      definition.percentageOffset = value == null ? null : value / 100;
    }
  }
});
</script>

<style scoped>
.alert-editor-definition-split {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.alert-editor-definition-type {
  min-width: 120px;
}

.alert-editor-definition-type-data {
  flex-grow: 1;
  min-width: 0;
}
</style>
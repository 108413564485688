import type { Observable, Observer, Subscription } from "rxjs";

export class SubscriptionManager {

  private readonly unboundSubscriptions: Subscription[] = [];
  private readonly boundSubscriptions = new Map<unknown, Subscription>();

  public subscribe<T>(observable: Observable<T>,
                      observer: Observer<T> | ((t: T) => void),
                      boundObject: unknown | null = null) {
    const subscription = observable.subscribe(observer);
    if (boundObject == null) {
      this.unboundSubscriptions.push(subscription);
    } else {
      this.boundSubscriptions.set(boundObject, subscription);
    }
  }

  public unsubscribe(boundObject: unknown) {
    const subscription = this.boundSubscriptions.get(boundObject);
    subscription?.unsubscribe();
    this.boundSubscriptions.delete(boundObject);
  }

  public unsubscribeAll() {
    for (const subscription of this.unboundSubscriptions) {
      subscription.unsubscribe();
    }
    this.unboundSubscriptions.splice(0);
    for (const subscription of this.boundSubscriptions.values()) {
      subscription.unsubscribe();
    }
    this.boundSubscriptions.clear();
  }
}

import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { EditableTextBox } from "@/anfin-chart/drawable";
import { BooleanOption, ColorOption, DevicePixelOption, StringOption } from "@/anfin-chart/options/option";
import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class TextTool extends UserToolDefinition {

  public static readonly type = "text";

  private readonly color = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(120, 137, 152));
  private readonly text = new StringOption(this, OptionName.TextContent, "");
  private readonly fontSize: DevicePixelOption;
  private readonly isBold = new BooleanOption(this, OptionName.TextBold, false);
  private readonly isItalic = new BooleanOption(this, OptionName.TextItalic, false);

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(TextTool.type, instrument, timeframe, 1, 1, false);
    this.fontSize = new DevicePixelOption(this, OptionName.FontSize, 12);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
  }

  public override updatePosition(tool: UserTool) {
    // do nothing
  }

  private createFirst(tool: UserTool) {
    const textBox = new EditableTextBox(tool, this.fixedPoints[0], this.text);
    tool.onUpdate(() => {
      textBox.color = this.color.getValue();
      textBox.fontSize = this.fontSize.getValue();
      textBox.isBold = this.isBold.getValue();
      textBox.isItalic = this.isItalic.getValue();
      textBox.borderColor = textBox.isEditMode ? this.color.getValue() : null;
      textBox.setText(this.getText(tool));
    });
  }

  private getText(tool: UserTool) {
    const text = this.text.getValue();
    if (text === "") {
      return tool.chart.callbacks.getTranslation("user_tool#text#placeholder");
    }
    return text;
  }
}

import type { IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { IndicatorController } from "@/api/indicator-controller";
import { UserToolController } from "@/api/user-tool-controller";
import { actionHistoryStore, UserToolDeleteAction, UserToolEditAction } from "@/stores/action-history-store";
import { chartOptionStore } from "@/stores/chart-option-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { defineStore } from "pinia";
import type { Consumer } from "@/anfin-chart/utils";
import { IndicatorRegistry } from "@/anfin-chart/registry";
import type { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";
import { AlertAutoToolDefinition, type AlertRuleDefinition } from "@/api/models/alert";
import { AlertFixedValueDefinition, AlertUserToolDefinition } from "@/api/models/alert";
import { translationStore } from "@/stores/translation-store";
import { applyOptions, getOptionDefinitions } from "@/anfin-chart/options/option";
import { formatPercentage } from "@/anfin-chart/utils";

const toolIconMap = new Map<string, string>();
toolIconMap.set("line", "LinesToolGroup");
toolIconMap.set("hline", "HorizontalLineTool");
toolIconMap.set("hsegment", "HorizontalSegmentTool");
toolIconMap.set("vline", "VerticalLineTool");
toolIconMap.set("trchannel", "TrendChannelTool");
toolIconMap.set("hchannel", "HorizontalChannelTool");
toolIconMap.set("pitch", "PitchforkChannelTool");
toolIconMap.set("abcd", "AbcdTool");
toolIconMap.set("xabcd", "XabcdTool");
toolIconMap.set("has", "HeadAndShouldersTool");
toolIconMap.set("ell12345", "Elliot12345Tool");
toolIconMap.set("ellabc", "ElliotABCTool");
toolIconMap.set("ellabcde", "ElliotABCDETool");
toolIconMap.set("ellwxy", "ElliotWXYTool");
toolIconMap.set("ellwxyxz", "ElliotWXYXZTool");
toolIconMap.set("fhdraw", "DrawTool");
toolIconMap.set("arrow", "ArrowTool");
toolIconMap.set("lstrip", "LineStripTool");
toolIconMap.set("rect", "RectangleTool");
toolIconMap.set("trect", "TiltedRectangleTool");
toolIconMap.set("ellipse", "EllipseTool");
toolIconMap.set("triangle", "TriangleTool");
toolIconMap.set("per", "PriceEarningsTool");
toolIconMap.set("text", "TextTool");
toolIconMap.set("prange", "PriceRangeTool");
toolIconMap.set("fibo", "FiboTool");

export function getAlertDefinitionLabel(definition: AlertRuleDefinition) {
  if (definition instanceof AlertFixedValueDefinition) {
    return definition.fixedValue.toString();
  }
  if (definition instanceof AlertUserToolDefinition) {
    if (definition.percentageOffset != null) {
      return formatPercentage(definition.percentageOffset);
    }
    const toolDefinition = chartObjectStore().getOrRequestUserTool(definition.toolId);
    const alertHook = toolDefinition?.hookProvider.getHook(definition);
    if (toolDefinition != null && alertHook != null) {
      return translationStore().getTranslation("user_tool#" + toolDefinition.type + "#" + alertHook.key);
    }
    return translationStore().getTranslation("not_available");
  }
  if (definition instanceof AlertAutoToolDefinition) {
    const offset = definition.percentageOffset ?? 0;
    return formatPercentage(offset);
  }
  return "";
}

export const chartObjectStore = defineStore({
  id: "chart-object",

  state() {
    setTimeout(() => initializeStore());
    return {
      indicatorDefinitionMap: new Map<string, IndicatorDefinition>(),
      isIndicatorDefinitionsLoaded: false,
      userToolMap: new Map<number, UserToolDefinition>(),
      pendingUserTools: new Set<UserToolDefinition>(),
      pendingTimeout: null as number | null,
      pendingIndicatorActions: [] as Consumer<void>[],
      pendingUserToolRequests: new Set<number>(),
      toolIconMap
    };
  },

  getters: {
    indicatorDefinitions(): IndicatorDefinition[] {
      return Array.from(this.indicatorDefinitionMap.values());
    },

    getToolIcon() {
      return (key: string) => this.toolIconMap.get(key) ?? "";
    }
  },

  actions: {
    async requestIndicatorDefinitions() {
      const indicatorDefinitions = await IndicatorController.getInstance().requestIndicatorDefinitions();
      for (const indicatorDefinition of indicatorDefinitions) {
        this.indicatorDefinitionMap.set(indicatorDefinition.type, indicatorDefinition);
        IndicatorRegistry.importDefinition(indicatorDefinition);
      }
      this.isIndicatorDefinitionsLoaded = true;
      this.pendingIndicatorActions.forEach(a => a());
      this.pendingIndicatorActions = [];
    },

    saveIndicatorDefinition(indicatorDefinition: IndicatorDefinition) {
      IndicatorController.getInstance().saveIndicatorDefinition(indicatorDefinition);
      this.indicatorDefinitionMap.set(indicatorDefinition.type, indicatorDefinition);
    },

    onIndicatorDefinitionsLoaded(action: Consumer<void>) {
      if (this.isIndicatorDefinitionsLoaded) {
        action();
      } else {
        this.pendingIndicatorActions.push(action);
      }
    },

    async requestUserTools(instrument: Instrument, timeframe: Timeframe) {
      const toolSynchronization = chartOptionStore().optionManager.toolSynchronization.getValue();
      const toolList = await UserToolController.getInstance().getUserTools(instrument, timeframe, toolSynchronization);
      this.updateUserTool(toolList.tools);
      multiChartStore().asExternalUpdate(() => {
        for (const chart of multiChartStore().charts) {
          chart.setSharedToolTimeframes(instrument, toolList.sharedTimeframes);
        }
      });
    },

    async requestUserTool(id: number) {
      if (this.pendingUserToolRequests.has(id)) {
        return;
      }
      this.pendingUserToolRequests.add(id);
      const tool = await UserToolController.getInstance().getUserTool(id);
      if (tool != null) {
        this.userToolMap.set(id, tool);
        this.pendingUserToolRequests.delete(id);
      }
    },

    getOrRequestUserTool(id: number) {
      const tool = this.userToolMap.get(id);
      if (tool == null) {
        this.requestUserTool(id);
      }
      return tool;
    },

    updateUserTool(tools: UserToolDefinition[]) {
      multiChartStore().asExternalUpdate(() => {
        for (const tool of tools) {
          if (tool.id == null) {
            continue;
          }
          const existingTool = this.userToolMap.get(tool.id);
          if (existingTool != null && existingTool.changeDate > tool.changeDate) {
            continue;
          }
          this.loadTool(tool);
          const action = new UserToolEditAction(tool);
          actionHistoryStore().addBaseAction(action);
        }
      });
    },

    loadTool(tool: UserToolDefinition) {
      if (tool.id == null) {
        return;
      }
      let existingTool = this.userToolMap.get(tool.id);
      if (existingTool == null) {
        this.userToolMap.set(tool.id, tool);
        existingTool = tool;
      } else if (tool !== existingTool) {
        existingTool.fixedPoints = tool.fixedPoints;
        applyOptions(existingTool, getOptionDefinitions(tool));
      }
      for (const chart of multiChartStore().charts) {
        chart.importTool(existingTool);
      }
    },

    async saveUserTool(tool: UserToolDefinition) {
      if (tool.id != null) {
        this.pendingUserTools.add(tool);
        this.pendingTimeout = this.pendingTimeout ?? window.setTimeout(() => this.executeSaveUserTool(), 50);
        return;
      }
      tool.id = await this.requestSaveTool(tool);
      const deleteAction = new UserToolDeleteAction(tool);
      actionHistoryStore().addBaseAction(deleteAction);
      multiChartStore().asExternalUpdate(() => this.loadTool(tool));
      const userTool = multiChartStore().activeChart?.getUserTool(tool.id) ?? null;
      multiChartStore().activeChart?.editChartObject(userTool);
      const action = new UserToolEditAction(tool);
      actionHistoryStore().addAction(action);
    },

    async executeSaveUserTool() {
      const pendingTools = Array.from(this.pendingUserTools);
      this.pendingTimeout = null;
      this.pendingUserTools.clear();
      multiChartStore().asExternalUpdate(() => {
        for (const tool of pendingTools) {
          this.requestSaveTool(tool);
          this.loadTool(tool);
          const action = new UserToolEditAction(tool);
          actionHistoryStore().addAction(action);
        }
      });
    },

    async requestSaveTool(tool: UserToolDefinition) {
      return await UserToolController.getInstance().saveUserTool(tool);
    },

    deleteUserTool(tool: UserToolDefinition) {
      if (tool.id == null) {
        return;
      }
      this.requestDeleteTool(tool);
      const action = new UserToolDeleteAction(tool);
      actionHistoryStore().addAction(action);
    },

    async requestDeleteTool(tool: UserToolDefinition) {
      await UserToolController.getInstance().deleteUserTool(tool);
    },

    onUserToolDelete(id: number) {
      this.userToolMap.delete(id);
      multiChartStore().asExternalUpdate(() => {
        for (const chart of multiChartStore().charts) {
          chart.deleteUserTool(id);
        }
      });
    }
  }
});

async function initializeStore() {
  const store = chartObjectStore();
  store.requestIndicatorDefinitions();
}

<template>
  <div class="product-card-wrapper mb-4">
    <div class="product-card" :class="{ 'product-card-primary': isBestOffer }">
      <div class="product-card-header">
        <div class="product-card-best-offer">
          Bestes Angebot
        </div>
        <div class="product-card-name" :class="{ 'product-card-best-offer-name': isBestOffer }">
          {{ product.name }}
        </div>
      </div>
      <div v-if="variant == null" class="my-4">
        Nicht verfügbar
      </div>
      <template v-else>
        <div class="product-card-body">
          <div v-if="showOffer" class="product-card-price-secondary mt-4">
            <div v-if="nextVariant != null" class="product-card-price-pre-discount">
              {{ formatAboPrice(nextVariant.recurringPrice, nextVariant.feePeriod) }}
            </div>
            <div v-else>
               
            </div>
          </div>
          <div class="mt-3">
            <span class="product-card-price-primary me-2">
              {{ formatPrice(variant.recurringPrice) }}
            </span>
            <span class="product-card-price-primary-period">
              {{ formatAboPeriod(variant.feePeriod) }}
            </span>
            <span v-if="isOfferAutoNextStep" class="product-card-price-primary-period">
              *
            </span>
          </div>
          <div v-if="showDiscount || showOffer" class="mt-4">
            <div v-if="showOffer && offerPercentage != null" class="font-bold">
              <span>Sie sparen </span>
              <span class="product-card-offer-percentage">{{ offerPercentage }}</span>
            </div>
            <div v-else-if="yearlyDiscount != null" class="product-card-price-secondary">
              {{ formatAboPrice(yearlyDiscount, Timeframe.Y1) }} sparen
            </div>
            <div v-else>
               
            </div>
          </div>
          <div v-if="showOffer" class="mt-4">
            <ProductOfferValidUntil v-if="variant.offerData != null" :offerData="variant.offerData" />
            <div v-else>
               
            </div>
          </div>
        </div>
        <div class="product-card-footer mt-2">
          <AddToCartButton :product="product" :variant="variant" :isOutline="isBestOffer" class="product-card-add-button px-4" />
          <div class="product-card-abo-hint my-2">
            Abo-Produkt, monatliche Abbuchung inkl. MwSt.
          </div>
          <div v-if="isOfferAutoNextStep && nextVariant" class="product-card-abo-hint my-2">
            * Mindestlaufzeit {{ formatContractPeriod(variant.contractPeriod, "long") }}. Ab {{ formatContractPeriod(variant.contractPeriod, "point", 1) }} {{ formatAboPrice(nextVariant.recurringPrice, nextVariant.feePeriod) }}
          </div>
          <div v-else class="product-card-abo-hint my-2">
            .
          </div>
        </div>
        <div v-if="isJfdOffer && showJfd" class="product-card-jfd-offer">
          <div class="mt-1">
            Dieses Angebot kostenlos mit der Eröffnung eines Kontos bei JFD erhalten.
          </div>
          <div class="mt-1">
            <a href="https://www.jfdbrokers.com/de/partner/plus-paket" class="link product-card-jfd-link">Zur Kontoeröffnung</a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { translationStore } from "@/stores/translation-store";
import { ShoppingProduct, ShoppingProductVariant } from "@/api/models/shopping-cart";
import { MonetaryMixin } from "@/mixins/monetary-mixin";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import ProductOfferValidUntil from "@/views/pages-landing/product/ProductOfferValidUntil.vue";
import AddToCartButton from "@/views/pages-landing/AddToCartButton.vue";

export default defineComponent({
  name: "ProductCard",

  components: { AddToCartButton, ProductOfferValidUntil },

  mixins: [MonetaryMixin],

  props: {
    product: {
      type: ShoppingProduct,
      required: true
    },
    variant: {
      type: Object as PropType<ShoppingProductVariant | null>,
      required: true
    },
    showOffer: {
      type: Boolean
    },
    showDiscount: {
      type: Boolean
    },
    showJfd: Boolean
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore(),
      Timeframe
    };
  },

  computed: {
    nextVariant() {
      return this.variant?.offerData?.nextVariant ?? null;
    },

    yearlyDiscount() {
      const monthlyVariant = this.getPeriodVariant(Timeframe.MN1);
      if (this.variant == null || monthlyVariant == null || this.variant === monthlyVariant) {
        return null;
      }
      return this.getYearlyPrice(monthlyVariant) - this.getYearlyPrice(this.variant);
    },

    isBestOffer() {
      return this.product.key === ShoppingProduct.anfinUltra;
    },

    isJfdOffer() {
      return this.product.key === ShoppingProduct.anfinUltra;
    },

    isOfferAutoNextStep() {
      return this.showOffer && this.showDiscount && this.variant?.offerData?.autoNextStep;
    },

    offerPercentage() {
      if (this.variant == null || this.nextVariant == null) {
        return null;
      }
      const nextPrice = this.nextVariant.recurringPrice;
      const price = this.variant.recurringPrice;
      const percentage = (nextPrice - price) / nextPrice;
      return Math.round(percentage * 100).toFixed(0) + "%";
    }
  },

  methods: {
    getPeriodVariant(period: Timeframe) {
      return this.product.variants.find(v => v.isActive && Timeframe.isSame(v.contractPeriod, period)) ?? null;
    },

    getYearlyPrice(variant: ShoppingProductVariant) {
      if (Timeframe.isSame(variant.feePeriod, Timeframe.Y1)) {
        return variant.recurringPrice;
      }
      return variant.recurringPrice * 12;
    }
  }
});
</script>

<style scoped>
.product-card-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 280px;
  overflow: hidden;
  border: 3px solid var(--interactive-accent);
  border-radius: var(--border-radius-double);
  color: var(--background-primary);
  background: #fafafa;
}

.product-card-primary {
  background: linear-gradient(to bottom, #003C0E 0%, #003C0E 30%, #114f29 70%, #003C0E 100%);
  color: white;
}

.product-card-header {
  width: 100%;
  overflow: hidden;
}

.product-card-body {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-card-primary .product-card-header, .product-card-primary .product-card-jfd-offer {
  background: var(--interactive-accent);
}

.product-card-best-offer {
  visibility: hidden;
  font-size: 80%;
}

.product-card-best-offer, .product-card-jfd-offer {
  font-weight: 500;
  padding: 2px;
  color: white;
}

.product-card-primary .product-card-best-offer {
  visibility: visible;
}

.product-card-name {
  font-size: 170%;
  color: white;
  background-color: var(--interactive-primary);
  padding: 6px 20px;
}

.product-card-primary .product-card-name {
  border-bottom: 1px solid color-mix(in srgb, var(--interactive-accent) 50%, transparent);
}

.product-card-price-primary {
  font-size: 260%;
  font-weight: 500;
}

.product-card-primary .product-card-price-primary {
  color: var(--interactive-accent);
}

.product-card-price-primary-period {
  font-size: 80%;
  color: var(--content-tertiary);
}

.product-card-price-secondary {
  color: var(--content-tertiary);
}

.product-card-offer-percentage {
  font-size: 120%;
}

.product-card-primary .product-card-price-secondary, .product-card-primary .product-card-price-primary-period {
  color: inherit;
}

.product-card-price-pre-discount {
  text-decoration: line-through;
}

.product-card-add-button {
  align-self: center;
}

.product-card-abo-hint {
  color: var(--content-tertiary);
  font-size: 60%;
}

.product-card-primary .product-card-abo-hint {
  color: inherit;
}

.product-card-jfd-offer {
  font-size: 90%;
  background-color: var(--interactive-primary);
  color: white;
}

.product-card-jfd-link {
  color: var(--background-primary);
  font-weight: bold;
  text-decoration: underline;
}
</style>
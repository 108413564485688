<template>
  <div class="product-offer-valid-until">
    {{ validUntilFormatted.toUpperCase() }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ShoppingProductOffer } from "@/api/models/shopping-cart";
import { translationStore } from "@/stores/translation-store";
import { Timeframe, TimeUnit } from "@/anfin-chart/time/timeframe";

export default defineComponent({
  name: "ProductOfferValidUntil",

  props: {
    offerData: {
      type: ShoppingProductOffer,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    validUntilFormatted() {
      const difference = this.offerData.validUntil.getTime() - Date.now();
      if (difference < 0) {
        return this.translationStore.getTranslation("product#offer_expired");
      }
      const timeRemaining = this.getTimeRemaining(difference);
      const timeframe = this.translationStore.getTimeframe(timeRemaining, "long");
      const translation = this.translationStore.getTranslation("product#offer_remaining");
      return translation.replace("#DURATION#", timeframe);
    }
  },

  methods: {
    getTimeRemaining(difference: number) {
      const days = Math.ceil(difference / 86400000);
      if (days > 0) {
        return new Timeframe(TimeUnit.Day, days, false);
      }
      const hours = Math.floor(difference / 3600000);
      if (hours > 0) {
        return new Timeframe(TimeUnit.Hour, hours, false);
      }
      const minutes = Math.ceil(difference / 60000);
      return new Timeframe(TimeUnit.Minute, minutes);
    }
  }
});
</script>

<style scoped>
.product-offer-valid-until {
  display: inline-block;
  padding: 5px 25px;
  border-radius: 50vh;
  font-size: 80%;
  font-weight: 500;
  background-color: var(--interactive-primary);
  color: var(--interactive-control);
}
</style>
<template>
  <div class="main-content container">
    <div class="exchange-inquiry-main">
      {{ translationStore.getTranslation("exchange_inquiry#realtime_data") }}
    </div>
    <hr class="my-4" />
    <div class="font-bold exchange-inquiry-caption">
      {{ translationStore.getTranslation("exchange_inquiry#us#header") }}
    </div>
    <div class="mt-3">
      {{ translationStore.getTranslation("exchange_inquiry#us#description") }}
    </div>
    <div class="mt-2 mb-4">
      {{ translationStore.getTranslation("exchange_inquiry#us#answer_header") }}
    </div>
    <ExchangeInquiryQuestion
      v-for="(question, index) in usQuestions" :key="question.id" :question="question" :index="index" class="mt-3"
    />
    <hr class="my-4" />
    <div class="font-bold exchange-inquiry-caption">
      {{ translationStore.getTranslation("exchange_inquiry#eu#header") }}
    </div>
    <div class="mt-3">
      {{ translationStore.getTranslation("exchange_inquiry#eu#description") }}
    </div>
    <ul class="mt-2">
      <li>{{ translationStore.getTranslation("exchange_inquiry#eu#professional_criteria#0") }}</li>
      <li>{{ translationStore.getTranslation("exchange_inquiry#eu#professional_criteria#1") }}</li>
      <li>{{ translationStore.getTranslation("exchange_inquiry#eu#professional_criteria#2") }}</li>
    </ul>
    <ExchangeInquiryQuestion :index="0" :question="euMainQuestion" :isWithoutText="true" class="mt-2" />
    <ul class="mt-4">
      <li>{{ translationStore.getTranslation("exchange_inquiry#eu#professional_criteria#3") }}</li>
    </ul>
    <div class="mt-3">
      {{ translationStore.getTranslation("exchange_inquiry#eu#company_description") }}
    </div>
    <ExchangeInquiryQuestion
      v-for="(question, index) in euQuestions" :key="question.id" :index="index" :question="question" class="mt-3"
    />
    <div v-if="isProUser" class="mt-3 text-error">
      {{ translationStore.getTranslation("exchange_inquiry#warning_professional_user") }}
      <a href="mailto:kontakt@anfin.com" class="link fw-bold">kontakt@anfin.com</a>
    </div>
    <button type="button" class="btn btn-primary mt-3 mb-5 col-sm-3" @click="confirm">
      {{ translationStore.getTranslation("exchange_inquiry#button_confirm") }}
    </button>
  </div>
</template>

<script lang="ts">
import { ExchangeInquiry, ExchangeInquiryItem, UserInfo } from "@/api/models/user-info";
import { toastStore, ToastType } from "@/stores/toast-store";
import { translationStore } from "@/stores/translation-store";
import { userRightStore } from "@/stores/user-right-store";
import ExchangeInquiryQuestion, { ExchangeInquiryQuestionData } from "@/views/components/ExchangeInquiryQuestion.vue";
import { storeToRefs } from "pinia";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "ExchangeInquiry",

  components: { ExchangeInquiryQuestion },

  expose: [],

  data() {
    const { userInfo } = storeToRefs(userRightStore());
    return {
      translationStore: translationStore(),
      userInfo: userInfo as unknown as UserInfo
    };
  },

  computed: {
    items(): ExchangeInquiryItem[] {
      if (this.userInfo == null) {
        return [];
      }
      const items = this.userInfo.exchangeInquiry.items;
      return [...items.slice(0, 4), ...items.slice(15, 19)];
    },

    questions(): ExchangeInquiryQuestionData[] {
      if (this.userInfo == null) {
        return [];
      }
      const isInitial = !this.userInfo.exchangeInquiry.hasBeenAnswered;
      return this.userInfo.exchangeInquiry.items.map(
        i => reactive(new ExchangeInquiryQuestionData(i.bitIndex, isInitial ? null : i.value))
      );
    },

    usQuestions() {
      return this.questions.slice(0, 4);
    },

    euMainQuestion() {
      return this.questions[15];
    },

    euQuestions() {
      return this.questions.slice(16, 19);
    },

    isProUser() {
      return this.usQuestions.some(q => q.isChecked) || this.euMainQuestion.isChecked ||
        this.euQuestions.filter(q => q.isChecked).length >= 2;
    }
  },

  methods: {
    async confirm() {
      if (!this.isAnswered()) {
        toastStore().addToast(ToastType.Error, "toast#exchange_inquiry_not_answered");
        return;
      }
      const items = this.questions.map(q => new ExchangeInquiryItem(q.id, q.isChecked ?? false));
      const exchangeInquiry = new ExchangeInquiry(true, true, items);
      await userRightStore().saveExchangeInquiry(exchangeInquiry);
      this.$router.push({ name: "Multichart" });
    },

    isAnswered() {
      const relevantQuestions = [...this.usQuestions, this.euMainQuestion, ...this.euQuestions];
      return relevantQuestions.every(q => q.isChecked != null);
    }
  }
});
</script>

<style scoped>
.exchange-inquiry-main {
  font-size: 115%;
}

.exchange-inquiry-caption {
  font-size: 125%;
}
</style>

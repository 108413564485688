import { ChartArea } from "@/anfin-chart/area/chart-area";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import { Rectangle } from "@/anfin-chart/geometry";

export class OverlayLayerArea extends ChartArea {

  public constructor(layer: ChartLayer) {
    super(layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.chart.coreLayerArea.getPositionObservable(), () => this.resize());
  }

  protected override drawInternal() {
    this.drawer.clearRect(this.getPosition());
  }

  protected override resizeInternal() {
    const generalAreaPosition = this.chart.coreLayerArea.getPosition();
    const xStart = generalAreaPosition.xStart;
    const xEnd = generalAreaPosition.xEnd;
    const yStart = generalAreaPosition.yStart;
    const yEnd = generalAreaPosition.yEnd;
    return new Rectangle(xStart, yStart, xEnd, yEnd);
  }
}

import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { PolygonElement } from "@/anfin-chart/drawable";
import { FixedSelectablePoint, ValueSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { Point } from "@/anfin-chart/geometry";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class RectangleTool extends UserToolDefinition {

  public static readonly type = "rect";

  private readonly lineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(18, 173, 196));
  private readonly areaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(69, 103, 138, 0.25));

  private readonly firstRight = new ChartToolPoint();
  private readonly secondLeft = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(RectangleTool.type, instrument, timeframe, 2, 2);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
  }

  public override updatePosition(tool: UserTool) {
    const [first, second] = this.fixedPoints.map(p => tool.getPosition(p));
    if (this.fixedPoints.length >= 2) {
      const firstRight = new Point(second.x, first.y);
      tool.updatePoint(this.firstRight, firstRight);
      const secondLeft = new Point(first.x, second.y);
      tool.updatePoint(this.secondLeft, secondLeft);
    }
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const [first, second] = this.fixedPoints;
    const area = new PolygonElement(tool, [first, this.firstRight, second, this.secondLeft]);
    tool.onUpdate(() => {
      area.areaColor = this.areaColor.getValue();
      area.lineColor = this.lineColor.getValue();
      area.options.width = tool.getLineWidth(true);
    });
    new FixedSelectablePoint(tool, second);
    new ValueSelectablePoint(tool, this.firstRight, (time, price) => {
      first.update(first.time, price);
      second.update(time, second.price);
    });
    new ValueSelectablePoint(tool, this.secondLeft, (time, price) => {
      first.update(time, first.price);
      second.update(second.time, price);
    });
  }
}

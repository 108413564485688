import { getUserInfo } from "@/api/auth";
import { UserDeviceMode, UserInfo } from "@/api/models/user-info";
import { chartOptionStore } from "@/stores/chart-option-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { userRightStore } from "@/stores/user-right-store";
import AlertOverviewMobile from "@/views/pages/AlertOverviewMobile.vue";
import ChartMobile from "@/views/pages/ChartMobile.vue";
import CheckoutPage from "@/views/checkout/CheckoutPage.vue";
import ExchangeInquiryPage from "@/views/pages/ExchangeInquiryPage.vue";
import MarketOverviewMobile from "@/views/pages/MarketOverviewMobile.vue";
import ScreenerPage from "@/views/pages/ScreenerPage.vue";
import SettingsMobile from "@/views/pages/SettingsMobile.vue";
import {
  createRouter,
  createWebHistory,
  type LocationQuery,
  type NavigationGuardNext,
  type RouteLocationNormalized
} from "vue-router";
import LoginPage from "../views/pages/LoginPage.vue";
import MarketOverviewPage from "../views/pages/MarketOverviewPage.vue";
import ChartPage from "../views/pages/ChartPage.vue";
import HomePage from "@/views/pages-landing/HomePage.vue";
import ProductPage from "@/views/pages-landing/ProductPage.vue";
import ShoppingCartPage from "@/views/checkout/ShoppingCartPage.vue";
import ShopPage from "@/views/pages-landing/ShopPage.vue";
import AboutUsPage from "@/views/pages-landing/AboutUsPage.vue";
import FAQPage from "@/views/pages-landing/FAQPage.vue";
import ContactPage from "@/views/pages-landing/ContactPage.vue";
import NotFoundPage from "@/views/pages-landing/NotFoundPage.vue";
import AGBPage from "@/views/pages-landing/AGBPage.vue";
import PrivacyPolicyPage from "@/views/pages-landing/PrivacyPolicyPage.vue";
import ImprintPage from "@/views/pages-landing/ImprintPage.vue";
import CancellationPolicyPage from "@/views/pages-landing/CancellationPolicyPage.vue";
import { NativeAppAdapter } from "@/utils/native-app-adapter";
import { versionStore } from "@/stores/version-store";
import ChartAnalysisPage from "@/views/pages-landing-test/ChartAnalysisPage.vue";
import ChatMobile from "@/views/pages/ChatMobile.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/login",
      name: "Login",
      component: LoginPage
    },
    {
      path: "/chart",
      name: "Multichart",
      component: ChartPage
    },
    {
      path: "/marketoverview",
      name: "Marketoverview",
      component: MarketOverviewPage
    },
    {
      path: "/reset",
      name: "LoginReset",
      component: LoginPage
    },
    // TODO: reenable
    /* {
      path: "/register",
      name: "LoginRegister",
      component: LoginView
    }, */
    {
      path: "/screeners",
      name: "Screener",
      component: ScreenerPage
    },
    {
      path: "/exchangeinquiry",
      name: "ExchangeInquiry",
      component: ExchangeInquiryPage
    },
    {
      path: "/mobile/chart",
      name: "ChartMobile",
      component: ChartMobile
    },
    {
      path: "/mobile/marketoverview",
      name: "MarketOverviewMobile",
      component: MarketOverviewMobile
    },
    {
      path: "/mobile/alertoverview",
      name: "AlertOverviewMobile",
      component: AlertOverviewMobile
    },
    {
      path: "/mobile/settings",
      name: "SettingsMobile",
      component: SettingsMobile
    },
    {
      path: "/mobile/chat",
      name: "ChatMobile",
      component: ChatMobile
    },

    // Payment / Checkout
    {
      path: "/checkout",
      name: "Checkout",
      component: CheckoutPage
    },
    {
      path: "/cart",
      name: "Cart",
      component: ShoppingCartPage
    },

    // Landing pages
    {
      path: "/home",
      name: "Home",
      component: HomePage
    },
    {
      path: "/shop",
      name: "Shop",
      component: ShopPage
    },
    {
      path: "/products/:id",
      name: "Product",
      component: ProductPage
    },
    {
      path: "/about-us",
      name: "AboutUs",
      component: AboutUsPage
    },
    {
      path: "/faq",
      name: "FAQ",
      component: FAQPage
    },
    {
      path: "/contact",
      name: "Contact",
      component: ContactPage
    },
    {
      path: "/agb",
      name: "AGB",
      component: AGBPage
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: PrivacyPolicyPage
    },
    {
      path: "/cancellation-policy",
      name: "CancellationPolicy",
      component: CancellationPolicyPage
    },
    {
      path: "/imprint",
      name: "Imprint",
      component: ImprintPage
    },
    {
      path: "/chart-analyse",
      name: "ChartAnalysis",
      component: ChartAnalysisPage
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFoundPage
    }
  ]
});

const landingPages = [
  "/checkout", "/home", "/cart", "/shop", "/products", "/about-us", "/faq", "/contact", "/agb", "/privacy-policy",
  "/cancellation-policy", "/imprint", "/chart-analyse"
];
const publicPages = [
  "/login", "/health", "/register", "/reset", ...landingPages
];

router.beforeEach((to, from, next) => {
  const urlPath = to.path;
  if (urlPath === "/") {
    next("/home");
    return;
  }
  if (urlPath === "/directchart") {
    next({
      path: "/chart",
      query: {
        email: to.query.email,
        token: to.query.token,
        symbol: to.query.symbol,
        theme: to.query.theme,
        mode: to.query.mode
      }
    });
    return;
  }

  if (checkPreservedParameters(from, to)) {
    next(to);
    return;
  }

  // initialize background version check
  versionStore();

  loadUserInfo(to.query).then(userInfo => {
    if (userInfo == null) {
      NativeAppAdapter.getInstance().sendMessage("loginFailed");
    }
    checkRedirects(urlPath, userInfo, next);
  });

  handleQueryParameters(to);
});

const preservedParameters = ["fpr", "test_mode"];

function checkPreservedParameters(from: RouteLocationNormalized, to: RouteLocationNormalized) {
  let hasAddedParameter = false;
  for (const parameter of preservedParameters) {
    const parameterValue = from.query[parameter];
    if (parameterValue != null && to.query[parameter] == null) {
      to.query[parameter] = parameterValue;
      hasAddedParameter = true;
    }
  }
  return hasAddedParameter;
}

async function loadUserInfo(query: LocationQuery) {
  const store = userRightStore();
  if (query.token != null && query.email != null && query.mode != null) {
    const email = query.email.toString();
    const token = query.token.toString();
    const isAdmin = false;
    const mode = query.mode.toString();
    const userInfo = new UserInfo("", email, "", "", token, isAdmin);
    userInfo.mode = mode as UserDeviceMode;
    store.setUserInfo(userInfo);
  } else {
    const currentUserInfo = getUserInfo();
    if (currentUserInfo.token !== "") {
      store.setUserInfo(currentUserInfo);
    }
  }
  await store.validateUserInfo();
  return store.userInfo;
}

function checkRedirects(urlPath: string, userInfo: UserInfo | null, next: NavigationGuardNext) {
  if (publicPages.some(p => urlPath.startsWith(p))) {
    next();
    return;
  }
  if (userInfo == null || userInfo.token === "") {
    next("/login");
    return;
  }
  const needsInquiry = !userInfo.exchangeInquiry.hasBeenAnswered || !userInfo.exchangeInquiry.isValid;
  if (urlPath !== "/exchangeinquiry" && needsInquiry) {
    next("/exchangeinquiry");
    return;
  }
  next();
}

function handleQueryParameters(to: RouteLocationNormalized) {
  if (to.query.theme != null) {
    chartOptionStore().setFixedTheme(Number(to.query.theme));
  }
  if (to.query.symbol != null && to.query.symbol !== "") {
    multiChartStore().setInitialSymbol(String(to.query.symbol));
  }
  if (to.query.timeframe != null && to.query.timeframe !== "") {
    multiChartStore().setInitialTimeframe(String(to.query.timeframe).toUpperCase());
  }
}

export default router;

import { RestApiController } from "@/api/rest-api-controller";
import type { AlertListResponse } from "@/api/messages/alert";
import { Alert, AlertConverter } from "@/api/models/alert";

export class AlertController extends RestApiController {

  private static instance: AlertController | null = null;

  private readonly alertConverter = new AlertConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new AlertController();
    }
    return this.instance;
  }

  public async requestAlerts() {
    const response = await this.sendGetRequest<AlertListResponse>("alerts");
    return response.configs.map(a => this.alertConverter.toModelObject(a));
  }

  public async saveAlert(alert: Alert) {
    const request = this.alertConverter.toApiObject(alert);
    if (alert.id == null) {
      return await this.sendPostRequest<string>("alerts", request);
    }
    return await this.sendPutRequest<string>(`alerts/${alert.id}`, request);
  }

  public async deleteAlert(alert: Alert) {
    return await this.sendDeleteRequest<number>(`alerts/${alert.id}`);
  }

  public async saveAlertState(alert: Alert) {
    if (alert.id != null) {
      const request = { state: alert.state };
      await this.sendPutRequest<string>(`alerts/${alert.id}/state`, request);
    }
  }

  protected getBaseUrl() {
    return this.environment.configurationUrl;
  }
}

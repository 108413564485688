import { ChartArea } from "@/anfin-chart/area/chart-area";
import type { ChartLayer } from "@/anfin-chart/chart-layer";
import type { SubChart } from "@/anfin-chart/sub-chart";

export class SubChartHintArea extends ChartArea {

  public constructor(layer: ChartLayer,
                     private readonly subChart: SubChart) {
    super(layer);
    this.setVisibleOnSubChart(subChart);
  }

  public override initializeEvents() {
    this.subscribeOn(this.subChart.drawingArea.getPositionObservable(), () => this.resize());
  }

  protected override drawInternal() {
    const hints = this.subChart.getHints();
    if (hints.length > 0) {
      hints[0].draw(this.drawer);
    }
  }

  protected override resizeInternal() {
    return this.subChart.drawingArea.getPosition();
  }
}

export class ChartHintArea extends ChartArea {

  public constructor(layer: ChartLayer) {
    super(layer);
  }

  public override initializeEvents() {
    this.subscribeOn(this.chart.drawingAreaWrapper.getPositionObservable(), () => this.resize());
  }

  protected override drawInternal() {
    this.chart.fibonacciHint.draw(this.drawer);
  }

  protected override resizeInternal() {
    return this.chart.drawingAreaWrapper.getPosition();
  }
}

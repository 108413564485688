<template>
  <div>
    <SingleSelect v-model="type" :items="typeItems" />
    <div v-if="type === ColorProviderType.Threshold" class="mt-2">
      <div>
        <span class="form-label">
          {{ translationStore.getTranslation("indicator_definition#label_color_provider_threshold") }}
        </span>
        <input v-model="threshold" class="form-control form-control-sm input-w-medium" />
      </div>
    </div>
    <div v-if="type === ColorProviderType.MultiKey" class="mt-2">
      <span class="form-label">
        {{ translationStore.getTranslation("indicator_definition#label_color_provider_keys") }}
      </span>
      <div class="multi-input-container">
        <input
          v-for="(key, index) in keys" v-model="keys[index]" class="form-control form-control-sm"
          @input="updateProvider"
        />
        <button class="btn btn-sm btn-primary text-center text-nowrap" type="button" @click="addKey">
          <span>
            <IconElement :size="14" iconName="Plus" />
          </span>
          {{ translationStore.getTranslation("indicator_definition#button_create_color_provider_key") }}
        </button>
      </div>
    </div>
    <div v-if="type === ColorProviderType.Conditional" class="mt-2">
      <span class="form-label">
        {{ translationStore.getTranslation("indicator_definition#label_color_provider_conditions") }}
      </span>
      <div class="multi-input-container">
        <input
          v-for="(condition, index) in conditions" :key="index" v-model="conditions[index]"
          class="form-control form-control-sm" @input="updateProvider"
        />
        <button class="btn btn-sm btn-primary text-center text-nowrap" type="button" @click="addCondition">
          <span>
            <IconElement :size="14" iconName="Plus" />
          </span>
          {{ translationStore.getTranslation("indicator_definition#button_create_color_provider_condition") }}
        </button>
      </div>
    </div>
    <div v-for="colorSet in colorSets" class="color-set mt-2">
      <div v-for="(color, colorIndex) in colorSet" class="color-set-picker">
        <ColorPickerButton :modelValue="colorSet[colorIndex]" :alphaPresetColors="false" :size="20" @update:modelValue="setColor(colorSet, colorIndex, $event)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { ColorProviderDefinition } from "@/anfin-chart/indicator-definition";
import { ColorDefinition, ColorProviderType, ColorType } from "@/anfin-chart/indicator-definition";
import { PlotType } from "@/anfin-chart/plot";
import { adjustArraySize, getEnumValues } from "@/anfin-chart/utils";
import { translationStore } from "@/stores/translation-store";
import ColorPickerButton from "@/views/color-picker/ColorPickerButton.vue";
import SingleSelect, { SingleSelectItem } from "@/views/components/SingleSelect.vue";
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "ColorProviderEditor",

  components: { SingleSelect, ColorPickerButton, IconElement },

  props: {
    modelValue: {
      type: Object as PropType<ColorProviderDefinition>,
      required: true
    },

    plotType: {
      type: Number as PropType<PlotType>,
      required: true
    }
  },

  emits: {
    "update:modelValue": (colorProvider: ColorProviderDefinition) => colorProvider != null
  },

  expose: [],

  data() {
    const typeItems = [];
    for (const type of getEnumValues(ColorProviderType)) {
      const key = "indicator_definition#color_provider_type#" + type;
      typeItems.push(new SingleSelectItem(key, null, type));
    }
    return {
      type: ColorProviderType.Simple as ColorProviderType,
      threshold: 0,
      keys: [""] as string[],
      conditions: [] as string[],
      colorSets: [] as ColorDefinition[][],
      translationStore: translationStore(),
      typeItems,
      ColorProviderType,
      ColorType
    };
  },

  computed: {
    colorCount() {
      switch (this.plotType) {
        case PlotType.Line:
          return 1;
        case PlotType.Mountain:
          return 2;
        case PlotType.Bar:
          return 3;
        case PlotType.SimpleBar:
          return 2;
        case PlotType.Candle:
          return 4;
        default:
          throw new Error("Unknown plot type");
      }
    },

    colorSetCount() {
      switch (this.modelValue.type) {
        case ColorProviderType.Simple:
          return 1;
        case ColorProviderType.Threshold:
          return 2;
        case ColorProviderType.MultiKey:
          return this.keys.length;
        case ColorProviderType.Conditional:
          return this.conditions.length + 1;
        default:
          throw new Error("Unknown color provider type");
      }
    }
  },

  watch: {
    modelValue: {
      handler(provider: ColorProviderDefinition) {
        this.type = provider.type;
        this.colorSets = provider.colorSets;
        this.threshold = provider.threshold == null ? 0 : provider.threshold;
        this.keys = provider.keys == null ? [""] : provider.keys;
        this.conditions = provider.conditions == null ? [""] : provider.conditions;
      },
      immediate: true
    },

    colorSetCount(count: number) {
      adjustArraySize(this.colorSets, count, () => this.createColorSet());
    },

    colorCount(count: number) {
      for (const colorSet of this.colorSets) {
        adjustArraySize(colorSet, count, () => this.getDefaultColor());
      }
    }
  },

  methods: {
    setType(event: Event) {
      const target = event.target as HTMLSelectElement;
      this.type = Number(target.value);
      this.updateProvider();
    },

    updateProvider() {
      const colorProvider = {
        type: this.type,
        colorSets: this.colorSets,
        threshold: this.threshold,
        keys: this.keys,
        conditions: this.conditions
      };
      this.$emit("update:modelValue", colorProvider);
    },

    setColor(colorSet: ColorDefinition[], colorIndex: number, color: ColorDefinition) {
      colorSet[colorIndex] = color;
    },

    addKey() {
      this.keys.push("");
      this.updateProvider();
    },

    addCondition() {
      this.conditions.push("");
      this.updateProvider();
    },

    createColorSet() {
      const colors = [];
      for (let i = 0; i < this.colorCount; i++) {
        colors.push(this.getDefaultColor());
      }
      return colors;
    },

    getDefaultColor() {
      return { type: 0, r: 200, g: 200, b: 200, a: 1 } as ColorDefinition;
    }
  }
});
</script>

<style scoped>
.multi-input-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
}

.multi-input-container .btn, .multi-input-container .form-control {
  border-radius: 0;
}

.color-set {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.color-set-picker {
  border: 1px solid var(--border-neutral);
}
</style>

import { ABCDPattern } from "@/anfin-chart/tools/user-tools/abcd";
import { ArrowTool } from "@/anfin-chart/tools/user-tools/arrow";
import { Elliot12345 } from "@/anfin-chart/tools/user-tools/ell12345";
import { ElliotABC } from "@/anfin-chart/tools/user-tools/ellabc";
import { ElliotABCDE } from "@/anfin-chart/tools/user-tools/ellabcde";
import { EllipseTool } from "@/anfin-chart/tools/user-tools/ellipse";
import { ElliotWXY } from "@/anfin-chart/tools/user-tools/ellwxy";
import { ElliotWXYXZ } from "@/anfin-chart/tools/user-tools/ellwxyxz";
import { FreehandDrawTool } from "@/anfin-chart/tools/user-tools/fhdraw";
import { FibonacciRetracement } from "@/anfin-chart/tools/user-tools/fibo";
import { HeadAndShoulders } from "@/anfin-chart/tools/user-tools/has";
import { HorizontalChannel } from "@/anfin-chart/tools/user-tools/hchannel";
import { HorizontalLine } from "@/anfin-chart/tools/user-tools/hline";
import { HorizontalSegment } from "@/anfin-chart/tools/user-tools/hsegment";
import { LineTool } from "@/anfin-chart/tools/user-tools/line";
import { LineStripTool } from "@/anfin-chart/tools/user-tools/lstrip";
import { PriceEarningsRatio } from "@/anfin-chart/tools/user-tools/per";
import { PitchforkChannel } from "@/anfin-chart/tools/user-tools/pitch";
import { PriceRangeTool } from "@/anfin-chart/tools/user-tools/prange";
import { RectangleTool } from "@/anfin-chart/tools/user-tools/rect";
import { TextTool } from "@/anfin-chart/tools/user-tools/text";
import { TrendChannel } from "@/anfin-chart/tools/user-tools/trchannel";
import { TiltedRectangleTool } from "@/anfin-chart/tools/user-tools/trect";
import { TriangleTool } from "@/anfin-chart/tools/user-tools/triangle";
import { VerticalLine } from "@/anfin-chart/tools/user-tools/vline";
import { XABCDPattern } from "@/anfin-chart/tools/user-tools/xabcd";
import type { Consumer } from "@/anfin-chart/utils";
import { convertKeyCode, KeyInfo, Shortcut, ShortcutCategory, ShortcutGroup } from "@/api/models/shortcut";
import { ShortcutsController } from "@/api/shortcuts-controller";
import { actionHistoryStore } from "@/stores/action-history-store";
import { chartObjectStore } from "@/stores/chart-object-store";
import { multiChartStore } from "@/stores/multi-chart-store";
import { defineStore } from "pinia";
import { favoriteStore } from "@/stores/favorite-store";
import { presetStore } from "@/stores/preset-store";

const forbiddenKeys = new Set([
  "MetaLeft", "MetaRight", "OSLeft", "OSRight", "ContextMenu"
]);
const systemKeys = new Set([
  "AltLeft", "AltRight", "ControlLeft", "ControlRight", "ShiftLeft", "ShiftRight", "F5", "Delete", "Backspace", "Tab"
]);

export class ShortcutConflict {

  constructor(public readonly currentShortcut: Shortcut,
              public readonly previousShortcut: Shortcut) {
  }
}

export const shortcutStore = defineStore({
  id: "shortcut",

  state() {
    setTimeout(() => initializeStore());
    return {
      controller: ShortcutsController.getInstance(),
      groups: [] as ShortcutGroup[],
      keyMap: new Map<string, Shortcut>(),
      nameMap: new Map<string, Shortcut>(),
      recordShortcut: null as Shortcut | null,
      conflict: null as ShortcutConflict | null,
      forbiddenKeyShortcut: null as Shortcut | null,
      idCounter: 0
    };
  },

  actions: {
    reset() {
      this.nameMap.clear();
      this.keyMap.clear();
      this.groups = [
        new ShortcutGroup(ShortcutCategory.General),
        new ShortcutGroup(ShortcutCategory.Tool),
        new ShortcutGroup(ShortcutCategory.Indicator)
      ];
    },

    async requestShortcuts() {
      const shortcuts = await this.controller.requestShortcuts();
      for (const responseShortcut of shortcuts) {
        const shortcut = this.nameMap.get(responseShortcut.name);
        if (shortcut == null) {
          continue;
        }
        const keyInfo = this.resolveKeyInfo(responseShortcut.keys);
        if (keyInfo == null) {
          this.clearShortcut(shortcut, true);
        } else {
          this.updateShortcut(shortcut, keyInfo, true);
        }
      }
      this.conflict = null;
    },

    getKey(keyInfo: KeyInfo | null) {
      if (keyInfo == null) {
        return null;
      }
      const convertedFlags = [keyInfo.isAlt, keyInfo.isControl, keyInfo.isShift].map(f => (f ? 1 : 0));
      return [keyInfo.key, ...convertedFlags].join("|");
    },

    resolveKeyInfo(key: string) {
      if (key === "") {
        return null;
      }
      const split = key.split("|");
      return new KeyInfo(split[0], split[1] === "1", split[2] === "1", split[3] === "1");
    },

    addShortcut(category: ShortcutCategory, name: string, defaultKeyInfo: KeyInfo | null, action: Consumer<void>,
                customName: string | null = null) {
      const id = this.idCounter++;
      const group = this.groups.find(g => g.category === category);
      if (group == null) {
        console.error("Could not find shortcut group: " + category);
        return;
      }
      if (this.nameMap.has(name)) {
        console.error("Duplicate shortcut name: " + name);
        return;
      }
      const shortcut = new Shortcut(group, id, name, defaultKeyInfo, action, customName);
      this.nameMap.set(name, shortcut);
      const key = this.getKey(defaultKeyInfo);
      if (key != null) {
        this.keyMap.set(key, shortcut);
      }
    },

    setRecordShortcut(shortcut: Shortcut | null) {
      this.recordShortcut = shortcut;
      if (shortcut != null) {
        this.conflict = null;
        this.forbiddenKeyShortcut = null;
      }
    },

    updateShortcut(shortcut: Shortcut, keyInfo: KeyInfo, isInitialization: boolean) {
      const key = this.getKey(keyInfo);
      if (key == null) {
        return;
      }
      const currentShortcut = this.keyMap.get(key);
      if (shortcut === currentShortcut) {
        return;
      }
      if (currentShortcut != null) {
        this.clearShortcut(currentShortcut, isInitialization);
        this.conflict = new ShortcutConflict(shortcut, currentShortcut);
      }
      const currentKey = this.getKey(shortcut.keyInfo);
      if (currentKey != null) {
        this.keyMap.delete(currentKey);
      }
      shortcut.keyInfo = keyInfo;
      this.keyMap.set(key, shortcut);
      if (!isInitialization) {
        this.controller.saveShortcut(shortcut.name, key);
      }
    },

    clearShortcut(shortcut: Shortcut, isInitialization: boolean) {
      const key = this.getKey(shortcut.keyInfo);
      if (key != null) {
        this.keyMap.delete(key);
      }
      shortcut.keyInfo = null;
      if (!isInitialization) {
        this.controller.saveShortcut(shortcut.name, "");
      }
    },

    keydown(keyInfo: KeyInfo) {
      const key = keyInfo.key;
      if (key === "Escape") {
        if (this.recordShortcut == null) {
          return false;
        }
        this.setRecordShortcut(null);
        return true;
      }
      if (forbiddenKeys.has(key)) {
        this.forbiddenKeyShortcut = this.recordShortcut;
        this.setRecordShortcut(null);
        return false;
      }
      if (systemKeys.has(key)) {
        return false;
      }
      return this.handleKeyInput(keyInfo);
    },

    handleKeyInput(keyInfo: KeyInfo) {
      const convertedKey = convertKeyCode(keyInfo.key);
      if (convertedKey) {
        const convertedKeyUpper = convertedKey.toUpperCase();
        const convertedKeyInfo = new KeyInfo(convertedKeyUpper, keyInfo.isAlt, keyInfo.isControl, keyInfo.isShift);
        if (this.recordShortcut != null) {
          this.updateShortcut(this.recordShortcut, convertedKeyInfo, false);
          this.setRecordShortcut(null);
          return true;
        }
        const key = this.getKey(convertedKeyInfo);
        if (key != null) {
          const currentShortcut = this.keyMap.get(key);
          const hasAction = currentShortcut != null;
          if (hasAction) {
            currentShortcut.action();
          }
          return hasAction;
        }
      }
      return false;
    }
  }
});

async function initializeStore() {
  const chartObjects = chartObjectStore();
  await chartObjects.requestIndicatorDefinitions();
  const presets = presetStore();
  await presets.fetchPresets();
  const store = shortcutStore();
  store.reset();
  initializeGeneral();
  initializeTools();
  initializeIndicators();
  store.requestShortcuts();
  document.addEventListener("keydown", e => {
    const activeTagName = document.activeElement?.tagName.toLowerCase();
    if (activeTagName !== "input" && activeTagName !== "textarea") {
      const keyInfo = new KeyInfo(e.code, e.altKey, e.ctrlKey, e.shiftKey);
      const isConsumed = store.keydown(keyInfo);
      if (isConsumed) {
        e.stopPropagation();
        e.preventDefault();
      }
    }
  });
}

function initializeGeneral() {
  const store = shortcutStore();
  store.addShortcut(
    ShortcutCategory.General,
    "config#shortcut#general#zoom",
    new KeyInfo("Z"),
    () => multiChartStore().activeChart?.setZoomMode()
  );
  // TODO: what should happen with the search shortcut?
  store.addShortcut(
    ShortcutCategory.General,
    "config#shortcut#general#search",
    new KeyInfo("F", false, true),
    () => multiChartStore().focusInstrumentSearch()
  );
  store.addShortcut(
    ShortcutCategory.General,
    "config#shortcut#general#undo",
    new KeyInfo("Z", false, true),
    () => actionHistoryStore().undo()
  );
  store.addShortcut(
    ShortcutCategory.General,
    "config#shortcut#general#redo",
    new KeyInfo("Y", false, true),
    () => actionHistoryStore().redo()
  );
}

function initializeTools() {
  const store = shortcutStore();
  const defaultKeyMap = new Map<string, KeyInfo>();
  defaultKeyMap.set(HorizontalLine.type, new KeyInfo("H"));
  defaultKeyMap.set(HorizontalSegment.type, new KeyInfo("H", true));
  defaultKeyMap.set(VerticalLine.type, new KeyInfo("V"));
  defaultKeyMap.set(LineTool.type, new KeyInfo("L"));
  defaultKeyMap.set(ArrowTool.type, new KeyInfo("L", true));
  defaultKeyMap.set(LineStripTool.type, new KeyInfo("L", false, true));
  defaultKeyMap.set(ABCDPattern.type, new KeyInfo("A"));
  defaultKeyMap.set(XABCDPattern.type, new KeyInfo("X"));
  defaultKeyMap.set(HeadAndShoulders.type, new KeyInfo("S"));
  defaultKeyMap.set(RectangleTool.type, new KeyInfo("R"));
  defaultKeyMap.set(TiltedRectangleTool.type, new KeyInfo("R", true));
  defaultKeyMap.set(TrendChannel.type, new KeyInfo("C"));
  defaultKeyMap.set(HorizontalChannel.type, new KeyInfo("C", true));
  defaultKeyMap.set(PitchforkChannel.type, new KeyInfo("C", true, false, true));
  defaultKeyMap.set(PriceEarningsRatio.type, new KeyInfo("P"));
  defaultKeyMap.set(PriceRangeTool.type, new KeyInfo("P", true));
  defaultKeyMap.set(TextTool.type, new KeyInfo("T"));
  defaultKeyMap.set(FibonacciRetracement.type, new KeyInfo("F"));
  defaultKeyMap.set(FreehandDrawTool.type, new KeyInfo("D"));
  defaultKeyMap.set(EllipseTool.type, new KeyInfo("E"));
  defaultKeyMap.set(TriangleTool.type, new KeyInfo("E"));
  const tools = [
    HorizontalLine, HorizontalSegment, VerticalLine, LineTool, ArrowTool, LineStripTool, ABCDPattern, XABCDPattern,
    HeadAndShoulders, Elliot12345, ElliotABC, ElliotABCDE, ElliotWXY, ElliotWXYXZ, RectangleTool, TiltedRectangleTool,
    TrendChannel, HorizontalChannel, PitchforkChannel, PriceEarningsRatio, PriceRangeTool, TextTool,
    FibonacciRetracement, FreehandDrawTool, EllipseTool, TriangleTool
  ];
  const toolPresets = presetStore().toolPresets;
  for (const tool of tools) {
    const type = tool.type;
    const action = () => multiChartStore().addTool(type);
    const defaultKeyInfo = defaultKeyMap.get(type) ?? null;
    store.addShortcut(ShortcutCategory.Tool, "user_tool#" + type, defaultKeyInfo, action);
    for (const preset of toolPresets) {
      if (preset.type === type) {
        const name = "user_tool#" + type + "#" + preset.id;
        const presetAction = () => {
          multiChartStore().addTool(type, preset.options, favoriteStore().presetPrefix + preset.id);
        };
        store.addShortcut(ShortcutCategory.Tool, name, null, presetAction, preset.name);
      }
    }
  }
}

function initializeIndicators() {
  const store = shortcutStore();
  const defaultKeyMap = new Map<string, KeyInfo>();
  const indicators = chartObjectStore().indicatorDefinitions;
  const indicatorPresets = presetStore().indicatorPresets;
  for (const indicator of indicators) {
    const type = indicator.type;
    const action = () => multiChartStore().activeChart?.addIndicator(type);
    const defaultKeyInfo = defaultKeyMap.get(type) ?? null;
    store.addShortcut(ShortcutCategory.Indicator, "indicator#" + type, defaultKeyInfo, action);
    for (const preset of indicatorPresets) {
      if (preset.type === type) {
        const name = "indicator#" + type + "#" + preset.id;
        const presetAction = () => {
          const chart = multiChartStore().activeChart;
          chart?.addIndicator(preset.type, preset.options);
        };
        store.addShortcut(ShortcutCategory.Indicator, name, null, presetAction, preset.name);
      }
    }
  }
}

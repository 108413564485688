<template>
  <div
    v-show="isVisible" v-draggable="draggableOptions" class="draggable-panel"
    :style="{ left: position.x + 'px', top: position.y + 'px' }"
  >
    <div class="panel-container">
      <div>
        <IconElement id="drag-handler" iconName="DragHandle" :size="18" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import IconElement from "@/views/icons/IconElement.vue";
import { defineComponent } from "vue";
import { Point, Rectangle } from "@/anfin-chart/geometry";
import type { DraggableOptions } from "@/directives/draggable";

export class DraggablePanelContainer {

  constructor(public readonly main: string,
              public readonly movement: string) {
  }
}

export default defineComponent({
  name: "DraggablePanel",

  components: { IconElement },

  props: {
    position: {
      type: Point,
      required: true
    },
    isVisible: {
      type: Boolean
    },
    container: {
      type: DraggablePanelContainer,
      required: true
    }
  },

  emits: {
    changePosition: (p: Point) => p || true
  },

  expose: ["setPosition"],

  computed: {
    draggableOptions(): DraggableOptions {
      return {
        handler: "#drag-handler",
        onInitialize: () => this.onDrag(this.position),
        onDragMove: (position: Point) => this.onDrag(position)
      };
    }
  },

  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      this.setPosition(this.position);
    });
    const container = this.$el.closest(this.container.main);
    if (container != null) {
      resizeObserver.observe(container);
    }
  },

  methods: {
    onDrag(position: Point) {
      const containerRect = this.getContainerRect();
      const adjustedPosition = new Point(position.x - containerRect.left, position.y - containerRect.top);
      const correctedPosition = this.correctPosition(adjustedPosition);
      this.onPositionChanged(correctedPosition);
    },

    setPosition(position: Point) {
      const correctedPosition = this.correctPosition(position);
      this.onPositionChanged(correctedPosition);
    },

    onPositionChanged(position: Point) {
      this.$emit("changePosition", position);
    },

    getContainerRect() {
      const container = this.$el.closest(this.container.main);
      return container?.getBoundingClientRect() ?? new Rectangle(0, 0, 0, 0);
    },

    getMovementContainerRect() {
      const movementContainer = this.$el.closest(this.container.movement);
      return movementContainer?.getBoundingClientRect() ?? new Rectangle(0, 0, 0, 0);
    },

    correctPosition(position: Point) {
      const movementRect = this.getMovementContainerRect();
      const containerRect = this.getContainerRect();
      const elementRect = this.$el.getBoundingClientRect();
      const xMax = movementRect.width - elementRect.width - containerRect.left;
      const yMax = movementRect.height - elementRect.height - containerRect.top;
      const xRect = Math.min(xMax, Math.max(-containerRect.left, position.x));
      const yRect = Math.min(yMax, Math.max(-containerRect.top, position.y));
      return new Point(xRect, yRect);
    }
  }
});
</script>

<style scoped>
#drag-handler {
  padding: 5px 5px;
  cursor: grab;
}

.draggable-panel {
  display: block;
  position: absolute;
  z-index: 1500;
  color: var(--content-secondary);
  stroke: var(--content-secondary);
  background: var(--background-elevated);
  border: 1px solid var(--border-neutral);
  border-radius: 4px;
  box-shadow: 2px 2px 4px 1px var(--background-shadow);
}

.panel-container {
  display: flex;
  align-items: center;
  padding: 2px 5px;
}
</style>

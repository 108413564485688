<template>
  <div class="landing-section">
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LandingPageSection",

  expose: []
});
</script>

<style scoped>
.landing-section > .container {
  position: relative;
}
</style>
<template>
  <div v-click-outside="onClickOutside" class="color-selector-container">
    <div
      class="color-selector-button" :style="{ width: sizeCss, height: sizeCss, background: colorCss }"
      @click="toggle"
    ></div>
    <div
      v-show="isExpanded" ref="colorSelector" class="color-selector"
      :class="{ 'color-selector-up': isAbove, 'color-selector-left': isLeft }"
    >
      <div v-if="allowSwitchType" class="button-group">
        <button
          v-for="colorType in colorTypes" :key="colorType" type="button" class="btn btn-sm toggle-button"
          :class="{ active: type === colorType }" @click="setType(colorType)"
        >
          {{ translationStore.getTranslation("color_type#" + colorType) }}
        </button>
      </div>
      <div v-show="type === ColorType.Rgba" class="mt-2">
        <slot name="rgba" :value="modelValue" :setValue="setColor"></slot>
      </div>
      <div v-show="type === ColorType.Gradient" class="mt-2">
        <slot name="gradient" :value="modelValue" :setValue="setColor"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { convertToRgbaCss, type RGBA } from "@/anfin-chart/draw/chart-color";
import { ColorDefinition, ColorType, GradientStopDefinition } from "@/anfin-chart/indicator-definition";
import { getEnumValues } from "@/anfin-chart/utils";
import { ClickOutside } from "@/directives/click-outside";
import { translationStore } from "@/stores/translation-store";
import { defineComponent, nextTick } from "vue";

export default defineComponent({
  name: "ColorPickerButtonTemplate",

  directives: { ClickOutside },

  props: {
    modelValue: {
      type: ColorDefinition,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    allowSwitchType: {
      type: Boolean
    }
  },

  emits: {
    "update:modelValue": (color: ColorDefinition) => color && true
  },

  expose: ["checkPosition"],

  data() {
    return {
      isExpanded: false,
      isAbove: false,
      isLeft: false,
      translationStore: translationStore(),
      colorTypes: getEnumValues(ColorType),
      ColorType
    };
  },

  computed: {
    type() {
      return this.modelValue.type;
    },

    sizeCss(): string {
      return this.size + "px";
    },

    colorCss(): string {
      switch (this.modelValue.type) {
        case ColorType.Rgba:
          return convertToRgbaCss(this.modelValue as RGBA);
        case ColorType.Gradient:
          const stopsCss = [];
          for (const stop of this.modelValue.stops) {
            const stopColor = convertToRgbaCss(stop.color as RGBA);
            const stopPercentage = Math.round(stop.percentage * 100) + "%";
            stopsCss.push(stopColor + " " + stopPercentage);
          }
          return `linear-gradient(135deg,${stopsCss.join(",")})`;
        default:
          return "#00000000";
      }
    }
  },

  mounted() {
    const colorSelector = this.$refs.colorSelector as HTMLElement | null;
    if (colorSelector == null) {
      console.error("Could not find color selector reference");
      return;
    }
    const observer = new ResizeObserver(() => this.checkPosition());
    observer.observe(colorSelector);
  },

  methods: {
    setColor(colorDefinition: ColorDefinition) {
      this.$emit("update:modelValue", colorDefinition);
    },

    setType(type: ColorType) {
      this.modelValue.type = type;
      if (type === ColorType.Rgba) {
        const fallbackColor = this.modelValue.stops.length > 0 ? this.modelValue.stops[0].color : new ColorDefinition(ColorType.Rgba);
        this.modelValue.r = fallbackColor.r;
        this.modelValue.g = fallbackColor.g;
        this.modelValue.b = fallbackColor.b;
        this.modelValue.a = fallbackColor.a;
      } else if (type === ColorType.Gradient && this.modelValue.stops.length < 2) {
        const fallbackRgba = this.modelValue.stops.length === 0 ? this.modelValue : this.modelValue.stops[0].color;
        const createFallback = () => new ColorDefinition(ColorType.Rgba, fallbackRgba.r, fallbackRgba.g, fallbackRgba.b, fallbackRgba.a);
        const firstStop = new GradientStopDefinition(0, createFallback());
        const secondStop = new GradientStopDefinition(1, createFallback());
        this.modelValue.stops.push(firstStop, secondStop);
      }
      this.$emit("update:modelValue", this.modelValue);
    },

    toggle() {
      this.isExpanded = !this.isExpanded;
      this.checkPosition();
    },

    onClickOutside() {
      if (!this.isExpanded) {
        return false;
      }
      this.isExpanded = false;
      return true;
    },

    checkPosition() {
      this.isAbove = false;
      this.isLeft = false;
      nextTick(() => {
        if (!this.isExpanded) {
          return;
        }
        const colorSelector = this.$refs.colorSelector as HTMLElement | null;
        if (colorSelector == null) {
          console.error("Could not find color selector reference");
          return;
        }
        const colorSelectorRect = colorSelector.getBoundingClientRect();
        this.isAbove = colorSelectorRect.bottom >= window.innerHeight;
        this.isLeft = colorSelectorRect.right >= window.innerWidth;
      });
    }
  }
});
</script>

<style scoped>
.color-selector-container {
  position: relative;
  border: 1px solid var(--border-neutral);
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADBJREFUOE9jfPbs2X8GPEBSUhKfNAPjqAHDIgz+//+PNx08f/4cfzoYNYCBceiHAQC5flV5JzgrxQAAAABJRU5ErkJggg==");
}

.color-selector {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 9999;
  background-color: var(--background-elevated);
  color: var(--content-secondary);
  border: 1px solid var(--border-neutral);
  box-shadow: 4px 4px 4px var(--background-shadow);
  border-radius: var(--border-radius-single);
  padding: 8px 10px;
}

.color-selector.color-selector-up {
  top: auto;
  bottom: calc(100% + 5px);
}

.color-selector.color-selector-left {
  left: auto;
  right: 0;
}
</style>

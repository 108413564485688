<template>
  <div class="product-period-selector">
    <SwitchButton
      :modelValue="isYearly" :labelLeft="labelMonthly" :labelRight="labelYearly"
      @update:modelValue="onChange"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SwitchButton from "@/views/components/SwitchButton.vue";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { translationStore } from "@/stores/translation-store";

export default defineComponent({
  name: "ProductPeriodSelector",

  components: { SwitchButton },

  props: {
    modelValue: {
      type: Timeframe,
      required: true
    }
  },

  emits: {
    "update:modelValue": (period: Timeframe) => period || true
  },

  expose: [],

  data() {
    return {
      translationStore: translationStore()
    };
  },

  computed: {
    isYearly() {
      return Timeframe.isSame(this.modelValue, Timeframe.Y1);
    },

    labelMonthly() {
      return this.translationStore.getTranslation("product#period_selector#1M");
    },

    labelYearly() {
      return this.translationStore.getTranslation("product#period_selector#1Y");
    }
  },

  methods: {
    onChange(isYearly: boolean) {
      const period = isYearly ? Timeframe.Y1 : Timeframe.MN1;
      this.$emit("update:modelValue", period);
    }
  }
});
</script>

<style scoped>
.product-period-selector {
  font-size: 120%;
  font-weight: 500;
  text-align: center;
}
</style>
import { simpleMapCompare } from "@/anfin-chart/utils";
import type { AnalysisDataResponse } from "@/api/messages/analysis-data";
import { ApiModelConverter } from "@/api/messages/converter";
import type { AnalysisToolDefinition } from "@/api/models/analysis/analysis-tool-definition";
import { AutoChannel, AutoChannelLine } from "@/api/models/analysis/auto-channel";
import { AutoDoubleExtreme, getAutoDoubleExtremeType } from "@/api/models/analysis/auto-double-extreme";
import { AutoFibonacci, getAutoFibonacciType } from "@/api/models/analysis/auto-fibonacci";
import { AutoHeadAndShoulders } from "@/api/models/analysis/auto-head-and-shoulders";
import { AutoHorizontal, getAutoHorizontalType } from "@/api/models/analysis/auto-horizontal";
import { AutoExtreme } from "@/api/models/analysis/auto-extreme";
import { AutoPriceGap } from "@/api/models/analysis/auto-price-gap";
import { getTrendType, TrendExtension, AutoTrendLine } from "@/api/models/analysis/auto-trend-line";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { Instrument } from "@/anfin-chart/instrument";

export class AnalysisData {

  private readonly extremes: AutoExtreme[] = [];
  private readonly autoFibonaccis: AutoFibonacci[] = [];
  private readonly autoHorizontals: AutoHorizontal[] = [];
  private readonly priceGaps: AutoPriceGap[] = [];
  private readonly trendLines: AutoTrendLine[] = [];
  private readonly doubleExtremes: AutoDoubleExtreme[] = [];
  private readonly channels: AutoChannel[] = [];
  private readonly headAndShoulders: AutoHeadAndShoulders[] = [];

  constructor(public readonly instrument: Instrument,
              public readonly timeframe: Timeframe) {
  }

  public getAllAnalyses() {
    return [...this.extremes, ...this.autoFibonaccis, ...this.autoHorizontals, ...this.priceGaps, ...this.trendLines,
      ...this.doubleExtremes, ...this.channels, ...this.headAndShoulders];
  }

  public getExtremes() {
    return this.extremes;
  }

  public getAutoFibonaccis() {
    return this.autoFibonaccis;
  }

  public getAutoHorizontals() {
    return this.autoHorizontals;
  }

  public getPriceGaps() {
    return this.priceGaps;
  }

  public getTrendLines() {
    return this.trendLines;
  }

  public getDoubleExtremes() {
    return this.doubleExtremes;
  }

  public getChannels() {
    return this.channels;
  }

  public getHeadAndShoulders() {
    return this.headAndShoulders;
  }

  public include(other: AnalysisData) {
    this.addExtremes(other.extremes);
    this.addAutoFibonaccis(other.autoFibonaccis);
    this.addAutoHorizontals(other.autoHorizontals);
    this.addPriceGaps(other.priceGaps);
    this.addTrendLines(other.trendLines);
    this.addDoubleExtremes(other.doubleExtremes);
    this.addChannels(other.channels);
  }

  public addExtremes(extremes: AutoExtreme[]) {
    this.mergeAnalyses(this.extremes, extremes);
  }

  public addAutoFibonaccis(autoFibonaccis: AutoFibonacci[]) {
    this.mergeAnalyses(this.autoFibonaccis, autoFibonaccis);
  }

  public addAutoHorizontals(autoHorizontals: AutoHorizontal[]) {
    this.mergeAnalyses(this.autoHorizontals, autoHorizontals);
  }

  public addPriceGaps(priceGaps: AutoPriceGap[]) {
    this.mergeAnalyses(this.priceGaps, priceGaps);
  }

  public addTrendLines(trendLines: AutoTrendLine[]) {
    this.mergeAnalyses(this.trendLines, trendLines);
    for (let i = 0; i < trendLines.length; i++) {
      const trendLine = trendLines[i];
      trendLine.sequenceIndex = i + 1;
    }
  }

  public addDoubleExtremes(doubleExtremes: AutoDoubleExtreme[]) {
    this.mergeAnalyses(this.doubleExtremes, doubleExtremes);
  }

  public addChannels(channels: AutoChannel[]) {
    this.mergeAnalyses(this.channels, channels);
  }

  public addHeadAndShoulders(headAndShoulders: AutoHeadAndShoulders[]) {
    this.mergeAnalyses(this.headAndShoulders, headAndShoulders);
  }

  private mergeAnalyses<T extends AnalysisToolDefinition>(target: T[], source: T[]) {
    const existingIds = new Set(target.map(i => i.id));
    for (const newItem of source) {
      if (!existingIds.has(newItem.id)) {
        target.push(newItem);
        existingIds.add(newItem.id);
      }
    }
    target.sort(simpleMapCompare(i => i.startTime));
  }
}

export class AnalysisDataConverter extends ApiModelConverter<AnalysisData, AnalysisDataResponse> {

  public override toApiObject(analysisData: AnalysisData): AnalysisDataResponse {
    throw new Error("Not implemented");
  }

  public override toModelObject(response: AnalysisDataResponse): AnalysisData {
    const instrument = Instrument.fromSymbol(response.name);
    const timeframe = Timeframe.fromShortNotation(response.timeBase);
    const analysisData = new AnalysisData(instrument, timeframe);
    this.extractExtremes(analysisData, response);
    this.extractAutoFibonaccis(analysisData, response);
    this.extractAutoHorizontals(analysisData, response);
    this.extractPriceGaps(analysisData, response);
    this.extractTrendLines(analysisData, response);
    this.extractDoubleExtremes(analysisData, response);
    this.extractChannels(analysisData, response);
    this.extractHeadAndShoulders(analysisData, response);
    return analysisData;
  }

  private extractExtremes(analysisData: AnalysisData, response: AnalysisDataResponse) {
    const extremes = response.extremes ?? [];
    const analyses = [];
    for (const extreme of extremes) {
      // TODO: use real id when available
      const id = "EXT_" + extreme.when.toString();
      const isHigh = extreme.what === "HIGH";
      const strength = this.getExtremeStrength(extreme.strength);
      const analysis = new AutoExtreme(id, analysisData.instrument, analysisData.timeframe, extreme.when,
        extreme.where, isHigh, strength, extreme.strength);
      analyses.push(analysis);
    }
    analysisData.addExtremes(analyses);
  }

  private extractAutoFibonaccis(analysisData: AnalysisData, response: AnalysisDataResponse) {
    const fibonaccis = response.results ?? [];
    const analyses = [];
    for (const fibonacci of fibonaccis) {
      // TODO Rückfrage Peter / Patrick wegen der Massenfibos
      const type = getAutoFibonacciType(fibonacci.type);
      if (fibonacci.deletedAt == null) {
        const analysis = new AutoFibonacci(fibonacci.id, analysisData.instrument, analysisData.timeframe, type,
          fibonacci.type, fibonacci.startDate, fibonacci.startPrice, fibonacci.endDate, fibonacci.endPrice,
          fibonacci.fibRatio, fibonacci.consolidationFactor, fibonacci.retracementPrice);
        analyses.push(analysis);
      }
    }
    analysisData.addAutoFibonaccis(analyses);
  }

  private extractAutoHorizontals(analysisData: AnalysisData, response: AnalysisDataResponse) {
    const horizontals = response.horizontals ?? [];
    const analyses = [];
    for (const horizontal of horizontals) {
      // not finished in Peters server
      // if (horizontal.visible == null || horizontal.visible) {
        const type = getAutoHorizontalType(horizontal.type);
        const analysis = new AutoHorizontal(horizontal.id, analysisData.instrument, analysisData.timeframe,
          horizontal.startDate, horizontal.price, horizontal.breakoutAt, type, horizontal.deletedAt);
        analyses.push(analysis);
      // }
    }
    analysisData.addAutoHorizontals(analyses);
  }

  private extractPriceGaps(analysisData: AnalysisData, response: AnalysisDataResponse) {
    const gaps = response.gaps ?? [];
    const analyses = [];
    for (const gap of gaps) {
      if (gap.filledHigh == null || gap.filledLow == null || Math.abs(gap.filledHigh - gap.filledLow) > 0.0000001) {
        // TODO: use real id when available
        const id = "GAP_" + gap.after.toString();
        // TODO: currently "until" is nulled, so that each is drawn until the last bar. This might change in the future
        const analysis = new AutoPriceGap(id, analysisData.instrument, analysisData.timeframe, gap.after,
          null, gap.filledHigh ?? gap.high, gap.filledLow ?? gap.low);
        analyses.push(analysis);
      }
    }
    analysisData.addPriceGaps(analyses);
  }

  private extractTrendLines(analysisData: AnalysisData, response: AnalysisDataResponse) {
    const trends = response.trends ?? [];
    const analyses = [];
    let sequenceIndex = 0;
    for (const trend of trends) {
      sequenceIndex++;
      if (trend.startDate === trend.endDate) {
        continue;
      }
      const type = getTrendType(trend.type);
      const extensions: TrendExtension[] = [];
      for (const extension of trend.extensions) {
        const rule = extension.rule;
        if (rule === "NO_EXTREME_AT_END") {
          continue;
        }
        const lineExtension = new TrendExtension(
          extension.when, rule, extension.ruleDate, extension.validBreakout, extension.consoPrice
        );
        extensions.push(lineExtension);
      }
      const analysis = new AutoTrendLine(trend.id, analysisData.instrument, analysisData.timeframe, sequenceIndex,
        trend.startDate, trend.startPrice, trend.endDate, trend.endPrice, type, trend.deletedAt, trend.parent,
        extensions);
      analyses.push(analysis);
    }
    analysisData.addTrendLines(analyses);
  }

  private extractDoubleExtremes(analysisData: AnalysisData, response: AnalysisDataResponse) {
    const doubleExtremes = response.doubles ?? [];
    const analyses = [];
    for (const doubleExtreme of doubleExtremes) {
      const type = getAutoDoubleExtremeType(doubleExtreme.type);
      const analysis = new AutoDoubleExtreme(doubleExtreme.id, analysisData.instrument, analysisData.timeframe,
        doubleExtreme.price, doubleExtreme.target, doubleExtreme.exEst, doubleExtreme.exEko, doubleExtreme.exH2,
        doubleExtreme.broken, doubleExtreme.brokenByExtreme, type);
      analyses.push(analysis);
    }
    analysisData.addDoubleExtremes(analyses);
  }

  private extractChannels(analysisData: AnalysisData, response: AnalysisDataResponse) {
    const channels = response.channels ?? [];
    const analyses = [];
    for (const channel of channels) {
      const mainLine = new AutoChannelLine(
        channel.mainLine.pointDate, channel.mainLine.pointPrice,
        channel.mainLine.otherDate, channel.mainLine.otherPrice,
        channel.mainLine.exactHitDates, channel.mainLine.crossingShadowDates, channel.mainLine.nearShadowDates
      );
      const copyLine = new AutoChannelLine(
        channel.copyLine.pointDate, channel.copyLine.pointPrice,
        channel.copyLine.otherDate, channel.copyLine.otherPrice,
        channel.copyLine.exactHitDates, channel.copyLine.crossingShadowDates, channel.copyLine.nearShadowDates
      );
      const analysis = new AutoChannel(channel.id, analysisData.instrument, analysisData.timeframe, channel.start,
        mainLine, copyLine, channel.brokenAt, channel.targetPrice, channel.deleted);
      analyses.push(analysis);
    }
    analysisData.addChannels(analyses);
  }

  private extractHeadAndShoulders(analysisData: AnalysisData, response: AnalysisDataResponse) {
    const sksFormations = response.sks ?? [];
    const analyses = [];
    for (const sks of sksFormations) {
      const id = "SKS_" + sks.neckLine.pointDate.toString();
      const analysis = new AutoHeadAndShoulders(id, analysisData.instrument, analysisData.timeframe,
        sks.leftShoulderDate, sks.headDate, sks.rightShoulderDate, sks.neckLine.pointDate, sks.neckLine.pointPrice,
        sks.neckLine.otherDate, sks.neckLine.otherPrice);
      analyses.push(analysis);
    }
    analysisData.addHeadAndShoulders(analyses);
  }

  private getExtremeStrength(strength: string) {
    if (strength.startsWith("LEVEL_")) {
      return Math.max(parseInt(strength.substring(6)), 1) + 1;
    }
    return 2;
  }
}

import type { IndicatorDefinition } from "@/anfin-chart/indicator-definition";
import { RestApiController } from "@/api/rest-api-controller";
import { IndicatorDefinitionConverter } from "@/api/messages/indicator-definition";
import type { IndicatorDefinitionListResponse } from "@/api/messages/indicator-definition";

export class IndicatorController extends RestApiController {

  private static instance: IndicatorController | null = null;

  private readonly converter = new IndicatorDefinitionConverter();

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new IndicatorController();
    }
    return this.instance;
  }

  public async requestIndicatorDefinitions() {
    const response = await this.sendGetRequest<IndicatorDefinitionListResponse>("indicator-definitions");
    return response.definitions.map(d => this.converter.toModelObject(d));
  }

  public async saveIndicatorDefinition(indicatorDefinition: IndicatorDefinition) {
    const request = this.converter.toApiObject(indicatorDefinition);
    return await this.sendPostRequest<number>("indicator-definitions", request);
  }

  public async deleteIndicatorDefinition(indicatorDefinition: IndicatorDefinition) {
    return await this.sendDeleteRequest<number>(`indicator-definitions/${indicatorDefinition.type}`);
  }

  protected getBaseUrl(): string {
    return this.environment.configurationUrl;
  }
}

import type { Instrument } from "@/anfin-chart/instrument";
import { addTimeframe, getTimezoneOffset, truncateDate, WeekDay } from "@/anfin-chart/time/time-utils";
import { Timeframe, TimeUnit } from "@/anfin-chart/time/timeframe";
import moment from "moment";
import "moment-timezone";

export class DailyTradingTime {

  constructor(public readonly start: number,
              public readonly end: number) {
  }
}

export class TradingHours {

  private readonly days: DailyTradingTime[][] = Array.from({ length: 7 }, () => []);

  private readonly timezoneOffset: number;

  constructor(private readonly timezone: string) {
    this.timezoneOffset = getTimezoneOffset(this.timezone);
  }

  public addDailyTime(weekDay: WeekDay, tradingTime: DailyTradingTime) {
    const tradingTimes = this.days[weekDay];
    tradingTimes.push(tradingTime);
    tradingTimes.sort(t => t.start);
    return this;
  }

  public getNext(time: number, timeframe: Timeframe) {
    if (this.days.every(d => d.length === 0)) {
      return null;
    }
    const date = new Date(time);
    let next = addTimeframe(date, timeframe);
    if (timeframe.unit === TimeUnit.Day && timeframe.value === 1) {
      while (this.days[next.getDay()].length === 0) {
        next = addTimeframe(next, timeframe);
      }
    } else if (timeframe.unit < TimeUnit.Day) {
      const nextPossible = timeframe.value > 0 ? this.resolveNextPossible(next) : this.resolvePreviousPossible(next);
      next = truncateDate(nextPossible, timeframe, this.timezoneOffset);
    }
    return next.getTime();
  }

  private resolveNextPossible(date: Date) {
    const zonedDate = moment(date).tz(this.timezone);
    const startOfDay = zonedDate.clone().startOf("day");
    const dailyTimes = this.days[startOfDay.weekday()];
    if (dailyTimes.length > 0) {
      const seconds = zonedDate.diff(startOfDay) / 1000;
      for (const dailyTime of dailyTimes) {
        if (seconds >= dailyTime.start && seconds <= dailyTime.end) {
          return zonedDate.toDate();
        }
        if (seconds < dailyTime.start) {
          startOfDay.add(dailyTime.start, "s");
          return startOfDay.toDate();
        }
      }
    }
    startOfDay.add(1, "d");
    while (this.days[startOfDay.weekday()].length === 0) {
      startOfDay.add(1, "d");
    }
    const matchingDailyTimes = this.days[startOfDay.weekday()];
    startOfDay.add(matchingDailyTimes[0].start, "s");
    return startOfDay.toDate();
  }

  private resolvePreviousPossible(date: Date) {
    const zonedDate = moment(date).tz(this.timezone);
    const startOfDay = zonedDate.clone().startOf("day");
    const dailyTimes = this.days[startOfDay.weekday()];
    if (dailyTimes.length > 0) {
      const seconds = zonedDate.diff(startOfDay) / 1000;
      for (let i = dailyTimes.length - 1; i >= 0; i--) {
        const dailyTime = dailyTimes[i];
        if (seconds >= dailyTime.start && seconds <= dailyTime.end) {
          return zonedDate.toDate();
        }
        if (seconds > dailyTime.end) {
          startOfDay.add(dailyTime.end, "s");
          return startOfDay.toDate();
        }
      }
    }
    startOfDay.subtract(1, "d");
    while (this.days[startOfDay.weekday()].length === 0) {
      startOfDay.subtract(1, "d");
    }
    const matchingDailyTimes = this.days[startOfDay.weekday()];
    const previousDailyTime = matchingDailyTimes[matchingDailyTimes.length - 1];
    startOfDay.add(previousDailyTime.end, "s");
    return startOfDay.toDate();
  }
}

export class TradingHoursProvider {

  private readonly tradingHoursMap = new Map<string, TradingHours>();
  private readonly defaultTradingHours = new TradingHours("Europe/Berlin")
    .addDailyTime(WeekDay.Sunday, new DailyTradingTime(82800, 86400))
    .addDailyTime(WeekDay.Monday, new DailyTradingTime(0, 86400))
    .addDailyTime(WeekDay.Tuesday, new DailyTradingTime(0, 86400))
    .addDailyTime(WeekDay.Wednesday, new DailyTradingTime(0, 86400))
    .addDailyTime(WeekDay.Thursday, new DailyTradingTime(0, 86400))
    .addDailyTime(WeekDay.Friday, new DailyTradingTime(0, 86400));

  constructor() {
    this.initStatic();
  }

  public getTradingHours(instrument: Instrument) {
    const symbol = instrument.getSymbol();
    const map = this.tradingHoursMap;
    return map.get(symbol) ?? map.get(instrument.exchange) ?? this.defaultTradingHours;
  }

  private initStatic() {
    const binance = new TradingHours("UTC")
      .addDailyTime(WeekDay.Sunday, new DailyTradingTime(0, 24 * 3600))
      .addDailyTime(WeekDay.Monday, new DailyTradingTime(0, 24 * 3600))
      .addDailyTime(WeekDay.Tuesday, new DailyTradingTime(0, 24 * 3600))
      .addDailyTime(WeekDay.Wednesday, new DailyTradingTime(0, 24 * 3600))
      .addDailyTime(WeekDay.Thursday, new DailyTradingTime(0, 24 * 3600))
      .addDailyTime(WeekDay.Friday, new DailyTradingTime(0, 24 * 3600))
      .addDailyTime(WeekDay.Saturday, new DailyTradingTime(0, 24 * 3600));
    this.tradingHoursMap.set("BINANCE", binance);
    const edgx = new TradingHours("America/New_York")
      .addDailyTime(WeekDay.Monday, new DailyTradingTime(9.5 * 3600, 16 * 3600))
      .addDailyTime(WeekDay.Tuesday, new DailyTradingTime(9.5 * 3600, 16 * 3600))
      .addDailyTime(WeekDay.Wednesday, new DailyTradingTime(9.5 * 3600, 16 * 3600))
      .addDailyTime(WeekDay.Thursday, new DailyTradingTime(9.5 * 3600, 16 * 3600))
      .addDailyTime(WeekDay.Friday, new DailyTradingTime(9.5 * 3600, 16 * 3600));
    this.tradingHoursMap.set("EDGX", edgx);
    const ceux = new TradingHours("Europe/London")
      .addDailyTime(WeekDay.Monday, new DailyTradingTime(8 * 3600, 17 * 3600))
      .addDailyTime(WeekDay.Tuesday, new DailyTradingTime(8 * 3600, 17 * 3600))
      .addDailyTime(WeekDay.Wednesday, new DailyTradingTime(8 * 3600, 17 * 3600))
      .addDailyTime(WeekDay.Thursday, new DailyTradingTime(8 * 3600, 17 * 3600))
      .addDailyTime(WeekDay.Friday, new DailyTradingTime(8 * 3600, 17 * 3600));
    this.tradingHoursMap.set("CEUX", ceux);
  }
}

<template>
  <div v-click-outside="hide" class="expandable-list user-select-none">
    <div tabindex="0" @click.stop="toggle">
      <slot name="header"></slot>
    </div>
    <div v-if="isOpened" class="expandable-list-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExpandableList",

  props: {
    disabled: {
      type: Boolean
    }
  },

  expose: ["hide"],

  data() {
    return {
      isOpened: false
    };
  },

  methods: {
    hide() {
      this.isOpened = false;
    },

    toggle() {
      this.isOpened = !this.disabled && !this.isOpened;
    }
  }
});
</script>

<style scoped>
.expandable-list {
  position: relative;
}

.expandable-list-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
  font-size: inherit;
  padding: 0;
  border: 1px solid var(--border-neutral);
  background-color: var(--background-elevated);
  border-radius: var(--border-radius-single);
  z-index: 10001;
  max-height: 400px;
  min-width: 100%;
}
</style>

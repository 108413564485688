import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { RestApiController } from "@/api/rest-api-controller";
import type {
  ScreenerDefinitionListResponse,
  ScreenerFilter,
  ScreenerResultListResponse
} from "@/api/messages/screener";

export class ScreenerController extends RestApiController {

  private static instance: ScreenerController | null = null;

  public static getInstance() {
    if (this.instance == null) {
      this.instance = new ScreenerController();
    }
    return this.instance;
  }

  public async getScreenerDefinitions() {
    const response = await this.sendGetRequest<ScreenerDefinitionListResponse>("definitions");
    return response.data;
  }

  public async getScreenerResults(filters: ScreenerFilter[], timeframes: Timeframe[]) {
    const requestData = {
      filters: filters,
      timeframes: timeframes.map(t => t.toShortNotation())
    };
    const response = await this.sendPostRequest<ScreenerResultListResponse>("monitoring", requestData);
    return response.data;
  }

  protected override getBaseUrl() {
    return this.environment.screenerUrl;
  }
}

<template>
  <div>
    <ProductCard
      v-for="item in productCardItems" :key="item.product.id" :product="item.product" :variant="item.variant"
      :showOffer="showOffer" :showDiscount="showDiscount" :showJfd="showJfd"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import type { ShoppingProduct, ShoppingProductVariant } from "@/api/models/shopping-cart";
import { paymentStore } from "@/stores/payment-store";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import ProductCard from "@/views/pages-landing/product/ProductCard.vue";

export interface ProductCardItem {
  product: ShoppingProduct;
  variant: ShoppingProductVariant | null;
}

export default defineComponent({
  name: "ProductCardGroup",

  components: { ProductCard },

  props: {
    period: {
      type: Timeframe,
      required: true
    },
    showJfd: Boolean
  },

  expose: [],

  data() {
    const { activeProducts } = storeToRefs(paymentStore());
    return {
      products: activeProducts as unknown as ShoppingProduct[]
    };
  },

  computed: {
    productCardItems(): ProductCardItem[] {
      const items = [];
      for (const product of this.products) {
        const variant = this.getPeriodVariant(product, this.period);
        items.push({ product, variant });
      }
      return items;
    },

    showOffer() {
      return this.productCardItems.some(i => i.variant?.offerData != null);
    },

    showDiscount() {
      return Timeframe.isSame(this.period, Timeframe.Y1);
    }
  },

  methods: {
    getPeriodVariant(product: ShoppingProduct, period: Timeframe) {
      if (Timeframe.isSame(this.period, product.defaultVariant.contractPeriod)) {
        return product.defaultVariant;
      }
      return product.variants.find(v => v.isActive && Timeframe.isSame(v.contractPeriod, period)) ?? null;
    }
  }
});
</script>

<style scoped>
</style>
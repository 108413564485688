<template>
  <div class="hamed-portrait">
    <img
      class="mw-100"
      src="/src/assets/hamed-portrait-w1098.png"
      alt=""
      srcset="/src/assets/hamed-portrait-w200.png 200w, /src/assets/hamed-portrait-w300.png 300w, /src/assets/hamed-portrait-w600.png 600w, /src/assets/hamed-portrait-w768.png 768w, /src/assets/hamed-portrait-w1098.png 1098w"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HamedPortraitImage",

  expose: []
});
</script>

<style scoped>
.hamed-portrait {
  position: relative;
  mask-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.009607359798384785) 0.3125%, rgba(0,0,0,0.03806023374435663) 0.625%, rgba(0,0,0,0.08426519384872738) 0.9375%, rgba(0,0,0,0.1464466094067262) 1.25%, rgba(0,0,0,0.22221488349019886) 1.5625%, rgba(0,0,0,0.3086582838174551) 1.875%, rgb(0,0,0,0.40245483899193585) 2.1875%, rgba(0,0,0,0.49999999999999994) 2.5%, rgba(0,0,0,0.5975451610080641) 2.8125%, rgba(0,0,0,0.6913417161825448) 3.125%, rgba(0,0,0,0.777785116509801) 3.4375%, rgba(0,0,0,0.8535533905932737) 3.75%, rgba(0,0,0,0.9157348061512727) 4.0625%, rgba(0,0,0,0.9619397662556434) 4.375%, rgba(0,0,0,0.9903926402016152) 4.6875%, rgba(0,0,0,1) 5%, rgba(0,0,0,1) 95%, rgba(0,0,0,0.9903926402016152) 95.3125%, rgba(0,0,0,0.9619397662556434) 95.625%, rgba(0,0,0,0.9157348061512727) 95.9375%, rgba(0,0,0,0.8535533905932737) 96.25%, rgba(0,0,0,0.777785116509801) 96.5625%, rgba(0,0,0,0.6913417161825448) 96.875%, rgba(0,0,0,0.5975451610080641) 97.1875%, rgba(0,0,0,0.49999999999999994) 97.5%, rgba(0,0,0,0.40245483899193585) 97.8125%, rgba(0,0,0,0.3086582838174551) 98.125%, rgba(0,0,0,0.22221488349019886) 98.4375%, rgba(0,0,0,0.1464466094067262) 98.75%, rgba(0,0,0,0.08426519384872738) 99.0625%, rgba(0,0,0,0.03806023374435663) 99.375%, rgba(0,0,0,0.009607359798384785) 99.6875%, rgba(0,0,0,0) 100%);
  mask-mode: alpha;
}

@media (min-width: 540px) {
  .hamed-portrait {
    align-self: end;
  }
}
</style>
import { ApiModelConverter } from "@/api/messages/converter";
import type {
  ChatMessageAttachmentResponse,
  ChatMessageResponse,
  ChatResponse,
  ChatVideoChapterResponse,
  ChatVideoResponse
} from "@/api/messages/chat";
import { TimeStore } from "@/anfin-chart/time/time-store";

export class ChatMessageStore extends TimeStore<ChatMessage> {

  private readonly idMap = new Map<number, ChatMessage>();

  public override clear() {
    super.clear();
    this.idMap.clear();
  }

  public override insert(...items: ChatMessage[]) {
    for (const item of items) {
      if (item.id == null) {
        continue;
      }
      const existingItem = this.idMap.get(item.id);
      if (existingItem != null) {
        const index = this.indexOf(existingItem.time);
        if (index != null) {
          this.removeItem(index);
        }
      }
      this.idMap.set(item.id, item);
    }
    super.insert(...items);
  }

  public override removeItem(index: number, count = 1) {
    const items = super.removeItem(index, count);
    for (const item of items) {
      if (item.id != null) {
        this.idMap.delete(item.id);
      }
    }
    return items;
  }

  public getById(id: number) {
    return this.idMap.get(id) ?? null;
  }
}

export class Chat {

  private readonly messageStore = new ChatMessageStore();
  private readonly messageMap = new Map<number, ChatMessage>();
  private readonly seenMessageHistory: ChatSeenMessageTime[] = [];

  constructor(public readonly id: number,
              public readonly title: string,
              public readonly prio: number,
              private lastSeenMessageId: number,
              public readonly isActive: boolean,
              public readonly isPublic: boolean,
              public readonly memberCount: number) {
    const seenMessageTime = new ChatSeenMessageTime(lastSeenMessageId, 0);
    this.seenMessageHistory.push(seenMessageTime);
  }

  public clear() {
    this.messageStore.clear();
    this.messageMap.clear();
  }

  public getMessages() {
    return this.messageStore.getItems();
  }

  public getMessage(id: number) {
    return this.messageStore.getById(id);
  }

  public getLastMessage() {
    return this.messageStore.getLast();
  }

  public addMessage(...messages: ChatMessage[]) {
    for (const message of messages) {
      if (message.id != null) {
        this.messageStore.insert(message);
        this.messageMap.set(message.id, message);
      }
    }
  }

  public removeMessage(message: ChatMessage) {
    const index = this.messageStore.indexOf(message.time);
    if (index != null) {
      this.messageStore.removeItem(index);
    }
  }

  public getSeenAt(message: ChatMessage) {
    if (message.id == null) {
      return 0;
    }
    for (const seenMessageTime of this.seenMessageHistory) {
      if (seenMessageTime.messageId >= message.id) {
        return seenMessageTime.time;
      }
    }
    return Date.now();
  }

  public getLastSeenMessageId() {
    return this.lastSeenMessageId;
  }

  public setLastSeenMessageId(id: number) {
    if (id < this.lastSeenMessageId) {
      return;
    }
    this.lastSeenMessageId = id;
    const seenMessageTime = new ChatSeenMessageTime(id, Date.now());
    this.seenMessageHistory.push(seenMessageTime);
  }

  public getUnseenMessageCount() {
    const lastMessage = this.getLastMessage();
    if (lastMessage != null && lastMessage.id != null && lastMessage.id <= this.lastSeenMessageId) {
      return 0;
    }
    const lastSeenMessage = this.messageMap.get(this.lastSeenMessageId);
    if (lastSeenMessage == null) {
      return this.messageStore.length;
    }
    const lastSeenIndex = this.messageStore.indexOf(lastSeenMessage.time) ?? -1;
    return this.messageStore.length - lastSeenIndex - 1;
  }
}

export class ChatMessage {

  constructor(public readonly chat: Chat,
              public readonly id: number | null,
              public readonly user: string,
              public title: string,
              public text: string,
              public readonly time: number,
              public attachments: ChatMessageAttachment[],
              public video: ChatVideo | null = null) {
  }
}

export class ChatSeenMessageTime {

  constructor(public readonly messageId: number,
              public readonly time: number) {
  }
}

export class ChatVideo {

  constructor(public readonly id: string,
              public readonly appId: string,
              public readonly ref: string,
              public chapters: ChatVideoChapter[]) {
  }

  public getUrl() {
    const baseUrl = "https://player.vimeo.com/video/";
    const parameterMap = new Map<string, string>();
    parameterMap.set("h", this.ref);
    parameterMap.set("badge", "0");
    parameterMap.set("player_id", "0");
    parameterMap.set("app_id", this.appId);
    const parameters = Array.from(parameterMap.entries()).map(([key, value]) => key + "=" + value);
    return baseUrl + this.id + "?" + parameters.join("&");
  }
}

export class ChatVideoChapter {

  constructor(public time: number,
              public title: string,
              public header: string | null) {
  }
}

export class ChatMessageAttachment {

  constructor(public readonly id: number | null,
              public readonly type: string,
              public readonly filename: string,
              public readonly data: string | null) {
  }
}

export class ChatConverter extends ApiModelConverter<Chat, ChatResponse> {

  public override toApiObject(chat: Chat) {
    return {
      id: chat.id,
      prio: chat.prio,
      title: chat.title,
      lastSeenMessageId: chat.getLastSeenMessageId(),
      active: chat.isActive,
      publicChat: chat.isPublic,
      memberCount: chat.memberCount
    };
  }

  public override toModelObject(response: ChatResponse) {
    return new Chat(response.id, response.title, response.prio, response.lastSeenMessageId, response.active,
      response.publicChat, response.memberCount);
  }
}

export class ChatMessageConverter extends ApiModelConverter<ChatMessage, ChatMessageResponse> {

  private readonly videoConverter = new ChatVideoConverter();
  private readonly attachmentConverter = new ChatMessageAttachmentConverter();

  constructor(private readonly chat: Chat) {
    super();
  }

  public override toApiObject(message: ChatMessage) {
    return {
      id: message.id,
      username: message.user,
      title: message.title,
      text: message.text,
      time: message.time,
      video: message.video == null ? null : this.videoConverter.toApiObject(message.video),
      attachments: message.attachments.map(a => this.attachmentConverter.toApiObject(a))
    };
  }

  public override toModelObject(response: ChatMessageResponse) {
    const video = response.video == null ? null : this.videoConverter.toModelObject(response.video);
    const attachments = response.attachments.map(a => this.attachmentConverter.toModelObject(a));
    return new ChatMessage(this.chat, response.id, response.username, response.title, response.text, response.time,
      attachments, video);
  }
}

export class ChatVideoConverter extends ApiModelConverter<ChatVideo, ChatVideoResponse> {

  private readonly chapterConverter = new ChatVideoChapterConverter();

  public override toApiObject(obj: ChatVideo) {
    return {
      id: obj.id,
      appId: obj.appId,
      ref: obj.ref,
      chapter: obj.chapters.map(c => this.chapterConverter.toApiObject(c))
    };
  }

  public override toModelObject(response: ChatVideoResponse) {
    const chapters = response.chapter.map(c => this.chapterConverter.toModelObject(c));
    return new ChatVideo(response.id, response.appId, response.ref, chapters);
  }
}

export class ChatVideoChapterConverter extends ApiModelConverter<ChatVideoChapter, ChatVideoChapterResponse> {

  public override toApiObject(chapter: ChatVideoChapter) {
    return {
      time: chapter.time,
      header: chapter.header,
      title: chapter.title
    };
  }

  public override toModelObject(response: ChatVideoChapterResponse) {
    return new ChatVideoChapter(response.time, response.title, response.header);
  }
}

export class ChatMessageAttachmentConverter extends ApiModelConverter<ChatMessageAttachment, ChatMessageAttachmentResponse> {

  public override toApiObject(attachment: ChatMessageAttachment) {
    return {
      id: attachment.id,
      type: attachment.type,
      filename: attachment.filename,
      data: attachment.data
    };
  }

  public override toModelObject(response: ChatMessageAttachmentResponse) {
    return new ChatMessageAttachment(response.id, response.type, response.filename ?? "", null);
  }
}

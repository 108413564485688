import { RGBAColor } from "@/anfin-chart/draw/chart-color";
import { LineElement, PolygonElement } from "@/anfin-chart/drawable";
import { getCenterPoint, projectLineRect, Vector } from "@/anfin-chart/geometry";
import type { Instrument } from "@/anfin-chart/instrument";
import { ColorOption } from "@/anfin-chart/options/option";
import { FixedSelectablePoint } from "@/anfin-chart/selectable-point";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { OptionName } from "@/anfin-chart/options/option-manager";
import type { UserTool } from "@/anfin-chart/tools/user-tool";
import { UserToolDefinition } from "@/anfin-chart/tools/user-tool-definition";

export class PitchforkChannel extends UserToolDefinition {

  public static readonly type = "pitch";

  private readonly centerLineColor = new ColorOption(this, OptionName.Color + "_0", new RGBAColor(230, 40, 40));
  private readonly halfLineColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(40, 230, 40));
  private readonly halfAreaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(40, 230, 40, 0.2));
  private readonly fullLineColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(40, 40, 230));
  private readonly fullAreaColor = new ColorOption(this, OptionName.Color + "_1", new RGBAColor(40, 40, 230, 0.2));

  private readonly middleStart = new ChartToolPoint();
  private readonly middleEnd = new ChartToolPoint();
  private readonly upperHalfStart = new ChartToolPoint();
  private readonly upperHalfEnd = new ChartToolPoint();
  private readonly lowerHalfStart = new ChartToolPoint();
  private readonly lowerHalfEnd = new ChartToolPoint();
  private readonly upperEnd = new ChartToolPoint();
  private readonly lowerEnd = new ChartToolPoint();

  constructor(instrument: Instrument, timeframe: Timeframe) {
    super(PitchforkChannel.type, instrument, timeframe, 3, 3);
  }

  public override createDrawables(tool: UserTool) {
    tool.withAtLeastPoints(1, () => this.createFirst(tool));
    tool.withAtLeastPoints(2, () => this.createSecond(tool));
    tool.withAtLeastPoints(3, () => this.createThird(tool));
  }

  public override updatePosition(tool: UserTool) {
    const [first, second, third] = this.fixedPoints.map(p => tool.getPosition(p));
    const rect = tool.subChart.drawingArea.getPosition();
    if (this.fixedPoints.length === 2) {
      const middleExpanded = projectLineRect(first, second, rect);
      tool.updatePoint(this.middleEnd, middleExpanded);
    }
    if (this.fixedPoints.length >= 3) {
      const middleStart = getCenterPoint(second, third);
      tool.updatePoint(this.middleStart, middleStart);
      const middleExpanded = projectLineRect(first, middleStart, rect);
      tool.updatePoint(this.middleEnd, middleExpanded);
      const mainVector = Vector.from(first, middleStart);
      const crossVector = Vector.from(middleStart, second);
      const upperHalfStart = crossVector.asScaled(0.5).addTo(middleStart);
      tool.updatePoint(this.upperHalfStart, upperHalfStart);
      const lowerHalfStart = crossVector.asScaled(-0.5).addTo(middleStart);
      tool.updatePoint(this.lowerHalfStart, lowerHalfStart);
      const upperHalfExtended = mainVector.addTo(upperHalfStart);
      const upperHalfEnd = projectLineRect(upperHalfStart, upperHalfExtended, rect);
      tool.updatePoint(this.upperHalfEnd, upperHalfEnd);
      const lowerHalfExtended = mainVector.addTo(lowerHalfStart);
      const lowerHalfEnd = projectLineRect(lowerHalfStart, lowerHalfExtended, rect);
      tool.updatePoint(this.lowerHalfEnd, lowerHalfEnd);
      const upperExtended = mainVector.addTo(second);
      const upperEnd = projectLineRect(second, upperExtended, rect);
      tool.updatePoint(this.upperEnd, upperEnd);
      const lowerExtended = mainVector.addTo(third);
      const lowerEnd = projectLineRect(third, lowerExtended, rect);
      tool.updatePoint(this.lowerEnd, lowerEnd);
    }
  }

  private createFirst(tool: UserTool) {
    new FixedSelectablePoint(tool, this.fixedPoints[0]);
  }

  private createSecond(tool: UserTool) {
    const [first, upper] = this.fixedPoints;
    const line = new LineElement(tool, first, this.middleEnd);
    tool.onUpdate(() => {
      line.color = this.centerLineColor.getValue();
      line.options.width = tool.getLineWidth(false);
    });
    new FixedSelectablePoint(tool, upper);
  }

  private createThird(tool: UserTool) {
    const [first, upper, lower] = this.fixedPoints;
    const crossLine = new LineElement(tool, upper, lower);
    tool.onUpdate(() => {
      crossLine.color = this.centerLineColor.getValue();
      crossLine.options.width = tool.getLineWidth(false);
    });
    const upperHalfLine = new LineElement(tool, this.upperHalfStart, this.upperHalfEnd);
    const lowerHalfLine = new LineElement(tool, this.lowerHalfStart, this.lowerHalfEnd);
    const upperLine = new LineElement(tool, upper, this.upperEnd);
    const lowerLine = new LineElement(tool, lower, this.lowerEnd);
    const upperHalfPolygon = new PolygonElement(tool, [this.middleStart, this.middleEnd, this.upperHalfEnd, this.upperHalfStart]);
    upperHalfPolygon.ignoreHit();
    const lowerHalfPolygon = new PolygonElement(tool, [this.middleStart, this.middleEnd, this.lowerHalfEnd, this.lowerHalfStart]);
    lowerHalfPolygon.ignoreHit();
    const upperPolygon = new PolygonElement(tool, [this.upperHalfStart, this.upperHalfEnd, this.upperEnd, upper]);
    upperPolygon.ignoreHit();
    const lowerPolygon = new PolygonElement(tool, [this.lowerHalfStart, this.lowerHalfEnd, this.lowerEnd, lower]);
    lowerPolygon.ignoreHit();
    tool.onUpdate(() => {
      const lineWidth = tool.getLineWidth(false);
      const halfLineColor = this.halfLineColor.getValue();
      const halfAreaColor = this.halfAreaColor.getValue();
      const fullLineColor = this.fullLineColor.getValue();
      const fullAreaColor = this.fullAreaColor.getValue();
      upperHalfLine.color = halfLineColor;
      upperHalfLine.options.width = lineWidth;
      lowerHalfLine.color = halfLineColor;
      lowerHalfLine.options.width = lineWidth;
      upperLine.color = fullLineColor;
      upperLine.options.width = lineWidth;
      lowerLine.color = fullLineColor;
      lowerLine.options.width = lineWidth;
      upperHalfPolygon.areaColor = halfAreaColor;
      lowerHalfPolygon.areaColor = halfAreaColor;
      upperPolygon.areaColor = fullAreaColor;
      lowerPolygon.areaColor = fullAreaColor;
    });
    new FixedSelectablePoint(tool, upper);
    new FixedSelectablePoint(tool, lower);
  }
}

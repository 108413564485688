const maxSearchPrioIndex = 99;

export class InstrumentDetail {

  constructor(public readonly symbol: string,
              public readonly name: string,
              public readonly printName: string,
              public readonly exchange: string,
              public readonly caption: string,
              public readonly currency: string,
              public readonly asset: string,
              public readonly search: string,
              public readonly prio: number,
              public readonly pricePrecision: number | null) {
  }

  public checkSearch(searchText: string, onlyStartWith: boolean): boolean {
    const isSearchInfo = this.search.toUpperCase().includes(searchText);
    return this.prio < maxSearchPrioIndex && isSearchInfo ||
      this.printName.toUpperCase().startsWith(searchText) ||
      this.printName.toUpperCase().startsWith("^" + searchText) ||
      !onlyStartWith && (isSearchInfo || this.caption.toUpperCase().includes(searchText));
  }
}

import { LineOptions, LineStyle } from "@/anfin-chart/draw/chart-drawer";
import { LineElement } from "@/anfin-chart/drawable";
import { ChartError } from "@/anfin-chart/error";
import type { InstrumentData } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { AutoHorizontal, AutoHorizontalType } from "@/api/models/analysis/auto-horizontal";
import { AnalysisTool } from "@/anfin-chart/tools/analysis-tool";
import { Point } from "@/anfin-chart/geometry";

export class AutoHorizontalTool extends AnalysisTool {

  constructor(public readonly definition: AutoHorizontal,
              instrumentData: InstrumentData,
              subChart: SubChart) {
    super(definition, instrumentData, subChart);
  }

  public override getIsVisible() {
    return super.getIsVisible() && this.definition.deletedAt == null && this.optionManager.showHorizontals.getValue();
  }

  protected override createDrawables() {
    const mainOptions = new LineOptions(1);
    if (this.definition.breakoutTime == null) {
      const line = new LineElement(this, this.definition.start, this.definition.end, mainOptions);
      this.onUpdate(() => {
        line.color = this.getColorOption().getValue();
      });
    } else {
      const mainLine = new LineElement(this, this.definition.start, this.definition.mainEnd, mainOptions);
      const extensionOptions = new LineOptions(1, LineStyle.Dotted);
      const extensionLine = new LineElement(this, this.definition.mainEnd, this.definition.end, extensionOptions);
      this.onUpdate(() => {
        const color = this.getColorOption().getValue();
        mainLine.color = color;
        extensionLine.color = color;
      });
    }
  }

  protected override updatePositionInternal() {
    this.updateFixedPoint(this.definition.start, this.definition.mainEnd);
    const endX = this.getFutureProjectionX();
    const endY = this.subChart.priceAxis.getY(this.definition.price);
    const end = new Point(endX, endY);
    this.updatePoint(this.definition.end, end);
  }

  private getColorOption() {
    const lastPrice = this.chart.getMainInstrumentData().mainPlot.getLast();
    const type = this.definition.price > lastPrice ? AutoHorizontalType.Resistance : AutoHorizontalType.Support;
    switch (type) {
      case AutoHorizontalType.Support:
        return this.colorOptions.autoHorizontalSupport;
      case AutoHorizontalType.Resistance:
        return this.colorOptions.autoHorizontalResistance;
      default:
        throw new ChartError("Unknown auto horizontal type");
    }
  }
}

<template>
  <LandingPageSection class="faq-section" :paddingTop="30">
    <div class="landing-header">
      {{ category.caption }}
    </div>
    <div v-for="question in category.questions" class="mt-4">
      <div class="faq-question">
        {{ question.text }}
      </div>
      <div class="faq-answer mt-2">
        <div v-for="answerLine in question.answerLines">
          <span v-for="part in answerLine.parts">
            <span v-if="part.type === FAQContentType.PlainText">
              {{ part.content }}
            </span>
            <span v-else-if="part.type === FAQContentType.Link">
              <RouterLink :to="part.contentSecondary ?? '#'" class="link" target="_blank" rel="noopener noreferrer">
                {{ part.content }}
              </RouterLink>
            </span>
          </span>
        </div>
      </div>
    </div>
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import { FAQCategory, FAQContentType } from "@/api/models/faq";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "FAQSection",

  components: { LandingPageSection, RouterLink },

  props: {
    category: {
      type: FAQCategory,
      required: true
    }
  },

  expose: [],

  data() {
    return {
      FAQContentType
    };
  }
});
</script>

<style scoped>
.faq-question {
  font-weight: bold;
}

.faq-answer {
  line-height: 1.4;
}
</style>
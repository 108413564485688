import { AnalysisToolDefinition, AnalysisType } from "@/api/models/analysis/analysis-tool-definition";
import type { Instrument } from "@/anfin-chart/instrument";
import type { Timeframe } from "@/anfin-chart/time/timeframe";

export class AutoPriceGap extends AnalysisToolDefinition {

  constructor(id: string,
              instrument: Instrument,
              timeframe: Timeframe,
              startTime: number,
              public readonly until: number | null,
              public readonly high: number,
              public readonly low: number) {
    super(id, AnalysisType.PriceGap, instrument, timeframe, startTime);
  }
}

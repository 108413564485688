import type { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import { ChartOptionManager, OptionName } from "@/anfin-chart/options/option-manager";
import {
  applyOption,
  BooleanOption,
  ChartOption,
  OptionValueType,
  toOptionDefinition
} from "@/anfin-chart/options/option";
import { Debouncer, getEnumValues } from "@/anfin-chart/utils";
import { neutralLightTheme, Theme, themes } from "@/api/models/theme";
import { AutoToolRightKey, UserRightKey } from "@/api/models/user-right";
import { UserSettingsController } from "@/api/user-settings-controller";
import { multiChartStore } from "@/stores/multi-chart-store";
import { userRightStore } from "@/stores/user-right-store";
import { defineStore } from "pinia";
import { watch } from "vue";
import { PlotColorOptionName } from "@/anfin-chart/options/plot-color-options";
import { ChartColorOptionName } from "@/anfin-chart/options/chart-color-options";
import { AnalysisToolColorOptionName } from "@/anfin-chart/options/analysis-tool-options";
import type { NotificationSoundType } from "@/api/models/notification";
import { AlertColorOptionName } from "@/anfin-chart/options/alert-options";
import { notificationStore } from "@/stores/notification-store";

export class MultiChartOptionManager extends ChartOptionManager {

  public override onOptionChange(option: ChartOption<unknown>) {
    if (!multiChartStore().isExternalUpdate) {
      const optionDefinition = toOptionDefinition(option);
      chartOptionStore().setOption(optionDefinition);
    }
    const value = option.getValue();
    if (option.name === OptionName.IsEmailNotificationEnabled) {
      notificationStore().setEmailEnabled(value as boolean);
    } else if (option.name === OptionName.IsDesktopNotificationEnabled) {
      notificationStore().setDesktopEnabled(value as boolean);
    } else if (option.name === OptionName.NotificationSound) {
      notificationStore().setSelectedSound(value as NotificationSoundType);
    }
  }
}

export const chartOptionStore = defineStore({
  id: "chart-options",

  state() {
    setTimeout(() => initializeStore());
    return {
      optionManager: new MultiChartOptionManager(),
      saveOptionsDebouncer: new Debouncer(500),
      cachedOptions: new Map<string, ChartOptionDefinition>(),
      cachedRights: new Set<keyof ChartOptionManager>(),
      pendingOptions: new Map<string, ChartOptionDefinition>(),
      themes,
      defaultTheme: neutralLightTheme,
      fixedThemeId: null as number | null
    };
  },

  getters: {
    currentTheme(): Theme {
      const themeId = this.optionManager.theme.getValue();
      return this.themes.find(t => t.id === themeId) ?? this.defaultTheme;
    }
  },

  actions: {
    async loadSettings() {
      const options = await UserSettingsController.getInstance().getChartSettings();
      this.loadChartOptions(options);
      this.applyAutoToolRight(AutoToolRightKey.Fibonacci, "showFibonaccis");
      this.applyAutoToolRight(AutoToolRightKey.Fibonacci, "showFibonacciTrends");
      this.applyAutoToolRight(AutoToolRightKey.Trend, "showTrends");
      this.applyAutoToolRight(AutoToolRightKey.Horizontal, "showHorizontals");
      this.applyAutoToolRight(AutoToolRightKey.DoubleExtreme, "showDoubleExtreme");
      this.applyAutoToolRight(AutoToolRightKey.Channels, "showChannels");
      this.applyAutoToolRight(AutoToolRightKey.Extreme, "showExtremes");
      this.applyAutoToolRight(AutoToolRightKey.PriceGap, "showPriceGaps");
    },

    loadChartOptions(options: ChartOptionDefinition[]) {
      for (const option of options) {
        this.cachedOptions.set(option.name, option);
      }
      multiChartStore().asExternalUpdate(() => {
        this.applyChartOptions(options);
      });
    },

    applyChartOptions(options: ChartOptionDefinition[]) {
      for (const option of options) {
        if (option.type === OptionValueType.Color) {
          this.applyThemedOption(option);
        } else if (option.name !== OptionName.Theme || this.fixedThemeId == null) {
          this.applyOption(option.name, option.value);
        }
      }
      this.applyThemeColors();
    },

    applyOption(name: string, value: unknown) {
      applyOption(this.optionManager, name, value);
      this.onOptionChange(name, value);
    },

    applyThemedOption(option: ChartOptionDefinition) {
      const currentThemeId = this.optionManager.theme.getValue();
      const [resolvedName, themeId] = this.resolveThemedOptionName(option.name);
      if (themeId == null || currentThemeId !== Number(themeId)) {
        return;
      }
      this.applyOption(resolvedName, option.value);
    },

    setOption(option: ChartOptionDefinition) {
      if (option.type === OptionValueType.Color) {
        option.name = this.getThemedOptionName(option.name);
      } else if (option.name === OptionName.Theme && this.fixedThemeId == null) {
        const currentTheme = this.optionManager.theme.getValue();
        localStorage.setItem("theme", currentTheme.toString());
        this.applyThemeColors();
      }
      this.onOptionChange(option.name, option.value);
      this.pendingOptions.set(option.name, option);
      this.cachedOptions.set(option.name, option);
      this.saveOptionsDebouncer.execute(() => {
        this.saveChartOptions();
      });
    },

    onOptionChange(name: string, value: unknown) {
      for (const chart of multiChartStore().charts) {
        chart.coreLayer.requireDraw();
        if (name === OptionName.ToolSynchronization) {
          chart.reloadUserTools();
        }
      }
    },

    saveChartOptions() {
      const options = Array.from(this.pendingOptions.values());
      UserSettingsController.getInstance().saveChartSettings(options);
      if (this.pendingOptions.has(OptionName.Theme)) {
        for (const option of this.optionManager.optionMap.values()) {
          const themedName = this.getThemedOptionName(option.name);
          const cachedOption = this.cachedOptions.get(themedName);
          if (cachedOption != null) {
            option.setValue(cachedOption.value, false);
          }
        }
      }
      this.pendingOptions.clear();
      for (const chart of multiChartStore().charts) {
        chart.coreLayer.requireDraw();
      }
    },

    applyAutoToolRight(key: AutoToolRightKey, property: keyof ChartOptionManager) {
      const rightsStore = userRightStore();
      watch(
        () => rightsStore.hasRight(UserRightKey.AutoTools, key),
        hasRight => {
          const option = this.optionManager[property] as BooleanOption;
          if (!hasRight && option.getValue()) {
            option.setValue(false, false);
            this.cachedRights.add(property);
          } else if (hasRight && this.cachedRights.has(property)) {
            option.setValue(true);
          }
        },
        { immediate: true }
      );
    },

    setFixedTheme(id: number) {
      this.fixedThemeId = id;
      this.optionManager.theme.setValue(id);
    },

    loadCachedTheme() {
      const cachedThemeId = localStorage.getItem("theme");
      if (cachedThemeId != null && this.fixedThemeId == null) {
        const parsedId = Number(cachedThemeId);
        this.optionManager.theme.setValue(parsedId);
      }
    },

    getThemedOptionName(optionName: string) {
      return optionName + "#" + this.optionManager.theme.getValue();
    },

    resolveThemedOptionName(optionName: string) {
      return optionName.split("#");
    },

    applyThemeColors() {
      const optionNames = [
        ...getEnumValues(ChartColorOptionName),
        ...getEnumValues(PlotColorOptionName),
        ...getEnumValues(AnalysisToolColorOptionName),
        ...getEnumValues(AlertColorOptionName)
      ];
      for (const optionName of optionNames) {
        const matchingOption = this.optionManager.optionMap.get(optionName);
        if (matchingOption == null) {
          console.error("Missing theme color option: " + optionName);
          continue;
        }
        const themedName = this.getThemedOptionName(optionName);
        const cachedOption = this.cachedOptions.get(themedName);
        if (cachedOption == null) {
          matchingOption.setValue(this.currentTheme.chartStyle[optionName]);
        } else {
          matchingOption.setValue(cachedOption.value);
        }
      }
      multiChartStore().charts.forEach(c => c.onStyleChange());
    }
  }
});

function initializeStore() {
  const store = chartOptionStore();
  store.loadCachedTheme();
  store.loadSettings();
}

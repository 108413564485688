import { ChartTool } from "@/anfin-chart/tools/chart-tool";
import {
  Alert,
  AlertFixedValueDefinition,
  type AlertRule,
  AlertRuleDefinition,
  AlertRuleDirection,
  AlertState
} from "@/api/models/alert";
import type { SubChart } from "@/anfin-chart/sub-chart";
import { IconElement, LineElement, PolygonElement, TextBox } from "@/anfin-chart/drawable";
import { ChartToolPoint } from "@/anfin-chart/tools/tool-point";
import { Instrument } from "@/anfin-chart/instrument";
import type { DragData, Draggable } from "@/anfin-chart/interactions";
import { Point } from "@/anfin-chart/geometry";
import { PriceAxisMarker } from "@/anfin-chart/area/price-axis-marker";
import type { TextAlignment } from "@/anfin-chart/draw/chart-drawer";

export class AlertLineElement extends LineElement implements Draggable {

  constructor(public readonly alertData: AlertToolData,
              start: ChartToolPoint,
              end: ChartToolPoint) {
    super(alertData.tool, start, end);
  }

  public override getIsVisible() {
    return super.getIsVisible() && this.chart.optionManager.showAlertTools.getValue();
  }

  public onDrag(data: DragData) {
    const price = this.subChart.priceAxis.getPrice(data.position.y);
    this.alertData.onPriceChange(price);
    this.tool.updateDrawables();
  }

  public onDragEnd() {
    this.chart.callbacks.saveAlert(this.alertData.data.alert);
  }
}

export class AlertIconElement extends IconElement {

  constructor(public readonly alertData: AlertToolData, center: ChartToolPoint) {
    super(alertData.tool, center, "Bell");
  }

  public override getIsVisible() {
    return super.getIsVisible() && this.chart.optionManager.showAlertTools.getValue();
  }
}

export class AlertTextBox extends TextBox {

  constructor(public readonly alertData: AlertToolData,
              point: ChartToolPoint,
              alignment: TextAlignment) {
    super(alertData.tool, point, alignment);
  }

  public override getIsVisible() {
    return super.getIsVisible() && this.chart.optionManager.showAlertTools.getValue();
  }
}

export type PriceChangeConsumer = (price: number) => void;

export class AlertDefinitionData {

  constructor(public readonly alert: Alert,
              public readonly rule: AlertRule,
              public readonly definition: AlertRuleDefinition) {
  }
}

export class AlertToolData {

  public readonly definitionIndex: number;

  public readonly topLeft = new ChartToolPoint();
  public readonly topRight = new ChartToolPoint();
  public readonly bottomLeft = new ChartToolPoint();
  public readonly bottomRight = new ChartToolPoint();

  public readonly start = new ChartToolPoint();
  public readonly end = new ChartToolPoint();
  public readonly lineStart = new ChartToolPoint();
  public readonly lineEnd = new ChartToolPoint();
  public readonly iconCenter = new ChartToolPoint();
  public readonly textPoint = new ChartToolPoint();

  public mainLine: AlertLineElement | null = null;
  public icon: AlertIconElement | null = null;
  public textBox: AlertTextBox | null = null;
  public marker: PriceAxisMarker | null = null;
  public polygons: PolygonElement[] = [];

  public priceChangeConsumer: PriceChangeConsumer | null = null;

  constructor(public readonly tool: ChartTool,
              public readonly data: AlertDefinitionData) {
    this.definitionIndex = data.rule.definitions.indexOf(data.definition);
  }

  public isHovered() {
    return this.mainLine != null && this.mainLine.isHovered ||
      this.icon != null && this.icon.isHovered ||
      this.textBox != null && this.textBox.isHovered;
  }

  public onPriceChange(price: number) {
    this.priceChangeConsumer?.(price);
  }

  public createLine(next: AlertToolData | null) {
    const line = new AlertLineElement(this, this.lineStart, this.lineEnd);
    this.mainLine = line;
    const colorOption = this.getColorOption();
    this.tool.onUpdate(() => {
      line.color = colorOption.getValue();
    });
    this.createIcon();
    if (next?.data.rule === this.data.rule) {
      this.createAreas(next);
    } else {
      this.createAreas(null);
    }
  }

  public createText(alignment: TextAlignment) {
    const textBox = new AlertTextBox(this, this.textPoint, alignment);
    this.textBox = textBox;
    const colorOption = this.getColorOption();
    this.tool.onUpdate(() => {
      textBox.color = colorOption.getValue();
    });
    return textBox;
  }

  public updatePosition(xStart: number, xEnd: number, price: number) {
    const y = this.tool.subChart.priceAxis.getY(price);
    const start = new Point(xStart, y);
    const end = new Point(xEnd, y);
    this.tool.updatePoint(this.start, start);
    this.tool.updatePoint(this.end, end);
    this.tool.updatePoint(this.lineStart, start);
    this.tool.updatePoint(this.lineEnd, end);
    this.marker?.setPrice(price);
    if (this.textBox != null) {
      this.tool.updatePoint(this.textPoint, start);
    }
    this.updateAreas(xStart, xEnd);
  }

  public getColorOption() {
    const options = this.tool.chart.optionManager.alertToolColor;
    const state = this.data.alert.state;
    if (state === AlertState.Triggered) {
      return options.alertToolTriggeredLine;
    }
    if (state === AlertState.Inactive) {
      return options.alertToolInactiveLine;
    }
    const direction = this.data.rule.direction;
    switch (direction) {
      case AlertRuleDirection.Above:
        return options.alertToolUpperLine;
      case AlertRuleDirection.Below:
        return options.alertToolLowerLine;
      case AlertRuleDirection.Between:
      case AlertRuleDirection.Outside:
        return this.definitionIndex === 0 ? options.alertToolLowerLine : options.alertToolUpperLine;
      default:
        console.error("Unknown direction: " + direction);
        return options.alertToolInactiveLine;
    }
  }

  private createIcon() {
    const icon = new AlertIconElement(this, this.iconCenter);
    this.icon = icon;
    const colorOption = this.getColorOption();
    this.tool.onUpdate(() => {
      icon.color = colorOption.getValue();
      icon.size = this.tool.chart.styleOptions.alertIconSize.getValue();
    });
  }

  private createAreas(otherData: AlertToolData | null) {
    this.polygons.splice(0);
    const direction = this.data.rule.direction;
    if (direction === AlertRuleDirection.Between && otherData != null) {
      this.createAreaBetween(otherData);
    } else if (direction === AlertRuleDirection.Above) {
      this.createAreaAbove();
    } else if (direction === AlertRuleDirection.Below) {
      this.createAreaBelow();
    } else if (direction === AlertRuleDirection.Outside && otherData != null) {
      this.createAreaOutside(otherData);
    }
    for (const polygon of this.polygons) {
      polygon.preventHover();
    }
    this.tool.onUpdate(() => {
      const areaColor = getAreaColorOption(this.tool, this.data.alert).getValue();
      for (const polygon of this.polygons) {
        polygon.areaColor = areaColor;
      }
    });
  }

  private createAreaAbove() {
    const polygon = new PolygonElement(this.tool, [this.start, this.end, this.topRight, this.topLeft]);
    this.polygons.push(polygon);
  }

  private createAreaBelow() {
    const polygon = new PolygonElement(this.tool, [this.start, this.end, this.bottomRight, this.bottomLeft]);
    this.polygons.push(polygon);
  }

  private createAreaBetween(otherData: AlertToolData) {
    const polygon = new PolygonElement(this.tool, [this.start, this.end, otherData.end, otherData.start]);
    this.polygons.push(polygon);
  }

  private createAreaOutside(otherData: AlertToolData) {
    const firstPolygon = new PolygonElement(this.tool, [this.start, this.end, this.bottomRight, this.bottomLeft]);
    const secondPolygon = new PolygonElement(this.tool, [otherData.start, otherData.end, this.topRight, this.topLeft]);
    this.polygons.push(firstPolygon, secondPolygon);
  }

  private updateAreas(xStart: number, xEnd: number) {
    const rect = this.tool.subChart.alertToolArea.getPosition();
    const topLeft = new Point(xStart, rect.yStart);
    const topRight = new Point(xEnd, rect.yStart);
    const bottomLeft = new Point(xStart, rect.yEnd);
    const bottomRight = new Point(xEnd, rect.yEnd);
    this.tool.updatePoint(this.topLeft, topLeft);
    this.tool.updatePoint(this.topRight, topRight);
    this.tool.updatePoint(this.bottomLeft, bottomLeft);
    this.tool.updatePoint(this.bottomRight, bottomRight);
  }
}

function getAreaColorOption(tool: ChartTool, alert: Alert) {
  const options = tool.chart.optionManager.alertToolColor;
  switch (alert.state) {
    case AlertState.New:
    case AlertState.Pending:
    case AlertState.Active:
      return options.alertToolActiveArea;
    case AlertState.Triggered:
      return options.alertToolTriggeredArea;
    case AlertState.Inactive:
    default:
      return options.alertToolInactiveArea;
  }
}

export class AlertTool extends ChartTool {

  private static readonly hoveredTools = new Set<AlertTool>();

  constructor(public readonly alert: Alert,
              public readonly alertRule: AlertRule,
              subChart: SubChart) {
    super(alertRule.instrument, alertRule.timeframe, subChart);
    this.resetDrawables();
  }

  public override getIsVisible() {
    if (!this.chart.optionManager.showAlertTools.getValue()) {
      return false;
    }
    const instrumentData = this.chart.getMainInstrumentData();
    if (!Instrument.isSame(instrumentData.instrument, this.instrument)) {
      return false;
    }
    const shownStates = this.chart.optionManager.alert.shownStates.getValue();
    return shownStates.includes(this.alert.state);
  }

  protected override createDrawables() {
    this.onUpdate(() => {
      if (this.isHovered) {
        AlertTool.hoveredTools.add(this);
      } else {
        AlertTool.hoveredTools.delete(this);
      }
      this.chart.mousePriceMarker.setIsVisible(AlertTool.hoveredTools.size === 0);
    });
  }

  protected override updatePositionInternal() {
    // do nothing
  }

  protected override createAlertData(alert: Alert, rule: AlertRule, definition: AlertRuleDefinition) {
    if (definition instanceof AlertFixedValueDefinition && alert === this.alert && rule === this.alertRule) {
      const definitionData = new AlertDefinitionData(alert, rule, definition);
      this.alertDatas.push(new AlertToolData(this, definitionData));
    }
  }

  protected override createAlertDrawables(data: AlertToolData, index: number) {
    const definition = data.data.definition as AlertFixedValueDefinition;
    data.priceChangeConsumer = (price: number) => {
      definition.fixedValue = price;
    };
    const nextData = this.alertDatas[index + 1];
    data.createLine(nextData);
    const marker = new PriceAxisMarker(this.subChart.priceAxis, data.getColorOption());
    data.marker = marker;
    this.onUpdate(() => {
      marker.isVisible = this.getIsHovered();
      this.chart.coreLayer.requireDraw();
      for (const polygon of data.polygons) {
        polygon.setIsVisible(this.isHovered);
      }
    });
  }

  protected override updateAlertPosition(data: AlertToolData) {
    const definition = data.data.definition as AlertFixedValueDefinition;
    this.updatePriceLine(data, definition.fixedValue);
  }

  private updatePriceLine(data: AlertToolData, price: number) {
    const rect = this.subChart.alertToolArea.getPosition();
    data.updatePosition(rect.xStart, rect.xEnd, price);
    const iconSize = this.chart.styleOptions.alertIconSize.getValue();
    const iconMargin = this.chart.styleOptions.alertIconMargin.getValue();
    const xLineEnd = rect.xEnd - iconSize - 2 * iconMargin;
    const y = this.getPosition(data.start).y;
    const lineEnd = new Point(xLineEnd, y);
    this.updatePoint(data.lineEnd, lineEnd);
    const iconCenter = new Point(rect.xEnd - iconSize / 2 - iconMargin, y);
    this.updatePoint(data.iconCenter, iconCenter);
  }

  private getIsHovered() {
    return AlertTool.hoveredTools.values().next().value === this;
  }
}

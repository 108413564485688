<template>
  <LandingPageWrapper>
    <MainSection />
    <LandingPageSection class="py-md-5 py-4 section-gray">
      <LandingVideo class="landing-video-home" />
    </LandingPageSection>
    <SellingFactSection class="py-md-5 py-4" />
    <ProductSection class="py-md-5 py-4" />
    <AssetCategoriesSection class="py-lg-5 py-4" />
    <ContactFormSection class="section-gray py-md-5 py-4" />
  </LandingPageWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingPageWrapper from "@/views/pages-landing/LandingPageWrapper.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import SellingFactSection from "@/views/pages-landing/sections/SellingFactSection.vue";
import ContactFormSection from "@/views/pages-landing/sections/ContactFormSection.vue";
import MainSection from "@/views/pages-landing/sections/MainSection.vue";
import ProductSection from "@/views/pages-landing/sections/ProductSection.vue";
import AssetCategoriesSection from "@/views/pages-landing/sections/AssetCategoriesSection.vue";
import LandingVideo from "@/views/pages-landing/LandingVideo.vue";

export default defineComponent({
  name: "HomePage",

  components: {
    LandingVideo, AssetCategoriesSection, ProductSection, MainSection, ContactFormSection, SellingFactSection,
    LandingPageSection, LandingPageWrapper
  },

  expose: []
});
</script>

<style scoped>
.landing-video-home {
  border-radius: var(--border-radius-double);
  max-height: 600px;
}
</style>
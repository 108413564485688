import type { ChartColor } from "@/anfin-chart/draw/chart-color";
import { ChartOptionDefinition } from "@/anfin-chart/indicator-definition";
import { OptionValueType } from "@/anfin-chart/options/option";
import { ChartColorConverter } from "@/api/messages/color";
import { ApiModelConverter } from "@/api/messages/converter";

export interface ChartOptionResponse {
  name: string;
  value: string;
  type: OptionValueType;
}

function stringifyValue(value: unknown, type: OptionValueType) {
  if (type === OptionValueType.Color) {
    const converted = new ChartColorConverter().toApiObject(value as ChartColor);
    return JSON.stringify(converted);
  }
  if (type === OptionValueType.NumericArray || type === OptionValueType.StringArray) {
    return JSON.stringify(value);
  }
  return String(value);
}

export function resolveValue(value: string, type: OptionValueType) {
  switch (type) {
    case OptionValueType.String:
      return value;
    case OptionValueType.Numeric:
    case OptionValueType.Enum:
      return Number(value);
    case OptionValueType.Boolean:
      return value === "true";
    case OptionValueType.NumericArray:
    case OptionValueType.StringArray:
      const arrayValue = value.startsWith("[") ? value : "[" + value + "]";
      return JSON.parse(arrayValue);
    case OptionValueType.Color:
      const obj = JSON.parse(value);
      return new ChartColorConverter().toModelObject(obj);
    default:
      console.error("Unknown option type");
      return value;
  }
}

export class ChartOptionConverter extends ApiModelConverter<ChartOptionDefinition, ChartOptionResponse> {

  public override toApiObject(option: ChartOptionDefinition): ChartOptionResponse {
    return {
      name: option.name,
      value: stringifyValue(option.value, option.type),
      type: option.type
    };
  }

  public override toModelObject(option: ChartOptionResponse): ChartOptionDefinition {
    const value = resolveValue(option.value, option.type);
    return new ChartOptionDefinition(option.name, option.type, value);
  }
}

<template>
  <div class="topbar">
    <AnfinLogo class="topbar-logo" />
    <div class="topbar-group topbar-main ms-auto">
      <slot name="content-main"></slot>
    </div>
    <div class="topbar-group topbar-right">
      <slot name="content-right"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { ClickOutside } from "@/directives/click-outside";
import { userRightStore } from "@/stores/user-right-store";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import AnfinLogo from "@/views/components/AnfinLogo.vue";

export default defineComponent({
  name: "TopToolbar",

  components: { AnfinLogo },

  directives: { ClickOutside },

  expose: [],

  data() {
    const { isMobile } = storeToRefs(userRightStore());
    return {
      isMobile: isMobile as unknown as boolean
    };
  }
});
</script>

<style>
.topbar {
  display: flex;
  height: 50px;
  padding: 3px 6px 3px 13px;
  align-items: center;
  justify-content: flex-end;
  gap: 64px;
  border-bottom: 1px solid var(--border-neutral);
  background-color: var(--background-primary);
  color: var(--content-secondary-top);
}

.topbar-logo {
  height: 40px;
  width: 55px;
}

.app-content .topbar-logo {
  height: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: auto;
}

.topbar-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
}

.topbar-group {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 940px) {
  .topbar-main {
    gap: 15px;
  }

  .topbar {
    gap: 15px;
  }
}

@media screen and (max-width: 600px) {
  .topbar-main {
    display: none;
  }

  .topbar-right {
    margin-left: auto;
  }
}
</style>
